.hybrid{
    .hybrid-offer{
        .o-skills-benefits__contentholder{
            padding: 50px 30px;
            .content-panel{
                &:before{
                    display: none;
                }
                img{
                    height: 60px;
                }
                h3{
                    padding: 20px 0 10px;
                }
            }
        }
    }
    .t-list{
        text-align: center;
        li{
            display: inline-block;
            text-align: center;
            margin: 10px 20px;
            font-family: $main-font;
            font-size: 19px;

            img{
                display: block;
                margin: 0 auto 10px;
            }
        }
    }
    .hybrid-list{
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
        &.color-black{
            li{
                color: $black;
            }
        }
        li{
            color: $white-color;
            font-family: $main-font;
            font-size: 18px;
            text-align: left;
            background: url(/images/common/tick.png) no-repeat 0 0;
            padding-left: 30px;
            margin-bottom: 10px;

        }
    }
    .hybrid-list-1{
        width: 100%;
        @include responsive(desktop){
            width: 15%;
        }
    }
    .hybrid-list-2{
        width: 100%;
        @include responsive(desktop){
            width: 20%;
        }

    }
    .hybrid-list-3{
        width: 100%;
        @include responsive(desktop){
            width: 30%;
        }
    }
    .hybrid-list-4{
        width: 100%;
        @include responsive(desktop){
            width: 20%;
        }
    }

    .masonry{
        .body-text-medium{
            font-size: 19px;
            line-height: 32px;
        }
    }
}

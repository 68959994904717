
	.gradient-blue-green {
		.container {
			position: relative;
			z-index: 9;
		}
	}
	.o-services-hero__icons {
		span {
			display: block;
			color: #fff;
			font-size: 14px;
			font-family: $main-font;
			margin-top: 15px;
			font-weight: 600;
				font-size: 18px;
		}
	}
	.green-bg-headine {
		position: relative;
		font-size: 30px;
		padding: 7px 30px;

			font-family: $secondary-font;
			line-height: 24px;
			font-size: 18px;
		&:before {
			content: '';
			position: absolute;
			// background: url(../images/common/heading-left-bg2.png) no-repeat 0 0;
			width: 41px;
			height: 19px;
			top: -17px;
			left: 0;
		}
	}
	.gradient-blue-green {
		position: relative;
		background: linear-gradient(285deg, #07B260 0%, #00517F 20%, #023E66 75%, #051B39 100%);
		@include responsive(desktop){
			background: linear-gradient(285deg, #07B260 0%, #00517F 30%, #023E66 75%, #051B39 100%);
		}
		// &:after {
		// 	background: url(images/data-science/top-right-pt.png) no-repeat top right;
		// 	top: 0;
		// 	right: 0;
		// 	left: inherit;
		// 	bottom: inherit;
		// 	-webkit-transform: rotate(0deg);
		// 		display: block;
		// 	display: none;
		// }
	}
	.o-services-hero__title span {
		&:before{
			content: '';
			position: absolute;
			// background: url(../../../images/common/heading-left-bg2) no-repeat 0 0;
			width: 41px;
			height: 19px;
			top: -17px;
			left: 0;
			border: none;
		}
	}
	.o-industrial-experience-mod {
		.data-science-tech {
				width: auto;
				margin: 0 10px 45px;
			margin: 0 auto;
			width: 96%;
			span {
				background-color: #349EE6;
				padding: 23px 20px;
				&:after {
					border-left: 10px solid #349EE6;
				}
			}
			img {
				position: relative;

					top: 5px;
			}
		}
	}
.data-science {
	.o-skills-hero {
		.container {
			padding-top: 90px;
		}
	}
	.dc-white-pan {
		min-height: inherit;
			min-height: 596px;
	}
}
.margin-top-50 {
	margin-top: 50px;
}
.padding-bottom-40 {
	padding-bottom: 40px;
}


.o-skills-hero__data-science {
	h1 {
		// @media all and (max-width: 767px) {
		// 	font-size: 24px;
    	// 	line-height: 36px;
		// }
	}
}

.skill-service.gradient-blue-green {
// 	background: linear-gradient(
// 270deg
// ,#00b35d 0,#0b8a8a 45%,#004475 100%);
	// background: #f5f5f5;
	@include responsive(desktop){
		background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%);
	}
	&:before {
		display: none;
		@include responsive(desktop){
			display: block;
		}
	}
}

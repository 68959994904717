.banner-technologies{
  padding: 40px 0;
  background-image: url(/images/new-home-banner/banner-gradient.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-color: #0d2642;
  &__subtitle{
    font-family: "Poppins";
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    color: #3CC065;
    @media(min-width:767px){
      font-size: 25px;
      line-height: 40px;
      font-weight: 700;
    }
    @media(min-width:1024px){
      font-size: 31px;
      line-height: 47px;
      
    }
  }
  &__header-br{
    display: none;
    @media(min-width:1024px){
      display: block;
    }
  }
  @media(min-width:1024px){
    padding: 60px 0;
  }
  @media(min-width:1024px){
    padding: 80px 0;
  }
  &__heading{
    font-size: 31px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    font-family: 'Poppins' !important;
    @media(min-width:767px){
      font-size: 41px;
      line-height: 46px;
      margin-bottom: 30px;
    }
    @media(min-width:1024px){
      font-size: 51px;
      line-height: 56px;
      margin-bottom: 40px;
    }
  }
  &__list-items{
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 5px !important;
    border: 1px solid transparent !important;
    @media(min-width:767px){
      gap: 10px !important;
    }
  }
  &__list-item{
    flex: 0 0 auto; // Set flex-grow, flex-shrink, and flex-basis to 'auto'
    white-space: nowrap; 
    width: auto;
    padding: 6px 16px;
    color: #fff;
    border: none !important;
    border-radius: 50px;
    font-family: 'Poppins' !important;
    &:before{
      width: 500px!important;
      height: 500px!important;
      top: -250px !important;
      left: -20% !important;
      right: inherit;
      bottom: inherit;
    }
    @media(min-width:767px){
      padding: 6px 20px;
    }
    @media(min-width:992px){
      flex-direction: row !important;
      width: auto !important;
    }
    @media(min-width:1024px){
      padding: 8px 24px;
    }
  }
  &__list-link{
    color: #fff;
    font-size: 15px !important;
    font-weight: 500 !important;
    @media(min-width:767px){
      font-size: 18px !important;
      font-weight: 500 !important;
    }
    @media(min-width:1024px){
      font-size: 21px !important;
      font-weight: 500 !important;
    }
  }
  .no-href{
    font-family: 'Poppins' !important;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0 5px;
    background: #0d2642;
    border-radius: 30px;
    padding: 5px 15px;
    position: relative;
    z-index: 9;
    -webkit-appearance:none;
    appearance: none;
    @include media-query(992px){
        font-size: 26px;
        padding: 5px 25px;
    }
    svg {
        transition: .3s all ease-in-out;
    }
  }
  .green-blue{
    &:hover{
      &:before{
        background: -moz-linear-gradient(180deg, rgba(6,87,181,0.62) 0%, rgba(35,116,212,0.62) 20%, rgba(26,184,100,1) 52%, rgba(136,222,177,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(6,87,181,0.62) 0%, rgba(35,116,212,0.62) 20%, rgba(26,184,100,1) 52%, rgba(136,222,177,1) 100%);
        background: linear-gradient(180deg, rgba(6,87,181,0.62) 0%, rgba(35,116,212,0.62) 20%, rgba(26,184,100,1) 52%, rgba(136,222,177,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0657b5",endColorstr="#88deb1",GradientType=1);
      }
    }
  }
}

.technologies-tab{
  position: relative;
  overflow: hidden;
  padding: 30px 0px;
  @media(min-width:992px){
    padding: 60px 0;
    padding-bottom: 0px;
  }
  &--accorbtn{
    .o-accordion__tab{
      font-size: 22px !important;
    }
  }
  &__heading{
    font-size: 31px;
    line-height: 45px;
    font-family: 'Poppins' !important;
    color: #000;
    text-align: center;
    // margin-bottom:30px;
    @media(min-width:767px){
      font-size: 41px;
      line-height: 45px;
    }
    @media(min-width:1024px){
      font-size: 51px;
      line-height: 55px;
      // margin-bottom:40px;
    }
  }
  &__heading-br{
    // display: none;
    @media(min-width:1024px){
      display: block;
    }
  }
  &__area{
    display: grid;
    grid-template-columns:1fr;
    padding-top: 30px;
    @media(min-width:770px){
      display: flex;
    }
    @media(min-width:1024px){
      display: grid;
      grid-template-columns: 3fr 4fr;
      padding-top: 70px;
    }
  }
  &__area-left{
    width: 100%;
    @media(min-width:770px){
      width: 50%;
    }
    @media(min-width:992px){
      width: auto;
    }
  }
  &__area-right{
    position: relative;
    display: none;
    @media(min-width:770px){
      display: block;
      width: 50%;
    }
    @media(min-width:992px){
      width: auto;
    }
  }
}

.o-accordion{
  $o-accordion : &;
  margin-bottom: 0px;
  @media(min-width:1024px){
    margin-bottom: 80px;
  }
  &__header{
    line-height: normal !important;
  }
  &__button{
    background-color: transparent !important;
    padding: 0px 26px 0px;
    background-color: transparent!important;
    padding-left: 20px;
    color: #000 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: auto;
    font-family: 'Poppins' !important;
    text-transform: capitalize;
    box-shadow: none;
    margin-top: 10px;
    //border-top: 2px dashed #f9f9f9;
    padding-top: 10px;
    @media(min-width:767px){
      padding-left: 26px;
      font-size: 24px;
      font-weight: 700;
    }
    &:focus{
      outline: none;
      box-shadow: none;
    }
    &:after{
      position: absolute;
      left: 0;
      color: #000;
      @media(max-width:767px){
        width: 14px;
        height: 14px;
        background-size: 14px;
      }
    }
    &:not(.collapsed)::after {
      color: #000;
    }
  }
  &__item{
    border: none;
  }
  &__body{
    padding: 0px 5px 0px;
    margin-top: 10px;
    @media(min-width:767px){
      padding: 0px 28px 0px;
    }
  }
  &__tab-icon{
    opacity: 0;
    margin-right: 10px;
    transition: .3s all ease-in-out;
    @media(max-width:767px){
      transform: rotate(90deg);
      width: 20px;
      margin-right: 0px;
    }
  }
  &__tab{
    text-align: left;
    text-transform: inherit;
    font-size: 16px;
    line-height: 35px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .3s all ease-in-out;
    @media(min-width:767px){
      font-size: 19px;
      line-height: 45px;
    }
    &:hover{
      border-bottom: 1px solid #4492dc;
      color: #4492dc;
      #{$o-accordion}__tab-icon{
        opacity: 1;
        margin-right: 0px;
      }
    }
  }
  &__btn{
    max-width: fit-content;
    padding: 15px 30px;
    margin: 10px auto 0;
    text-transform: capitalize;
    width: 100%;
    @media(min-width:767px){
      padding: 15px 30px;
      margin: 50px 0 0;
    }
  }
  &__nav{
    width: 100%;
    padding-left: 14px;
    .active{
      border-bottom: 1px solid #4492dc;
      color: #4492dc;
      #{$o-accordion}__tab-icon{
        opacity: 1;
        margin-right: 0px;
      }
    }
  }
  &__content-area{
    // display: none;
    // @media(min-width:1024px){
    //   display: block;
    // }
  }
  &__content-background{
    display: none;
    position: absolute;
    background: #F9F9F9;
    width: 200%;
    height: 100%;
    padding: 40px;
    @media(min-width:767px){
      display: block;
    }
  }
  &__content{
    background: #F9F9F9;
    padding: 30px 15px;
    z-index: 1;
    position: relative;
    @media(min-width:991px){
      padding: 40px 25px;
    }
    @media(min-width:1024px){
      padding: 40px 35px;
    }
    @media(min-width:1200px){
      padding: 40px 0 55px 55px;
    }
  }
  &__content-title{
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    font-family: 'Poppins' !important;
    margin-bottom: 10px;
    padding-top: 15px;
    color: #333;
    @media(min-width:767px){
      margin-bottom: 15px;
      margin-top: 0px;
      font-size: 26px;
      line-height: 32px;
    }
    @media(min-width:1024px){
      font-size: 36px;
      line-height: 42px;
    }
  }
  &__content-description{
    font-size: 14px;
    line-height: 25px;
    color: #333;
    font-family: 'Poppins' !important;
    margin-bottom: 15px !important;
    @media(min-width:767px){
      font-size: 16px;
      line-height: 27px;
    }
  }
  &__pool-section{
    padding: 0px;
    @media(max-width:767px){
      padding-top: 0px !important;
      border:none !important;
    }
    @media(min-width:992px){
      padding: 0px !important;
    }
  }
  &__pool-box{
    justify-content: left !important;
    gap: 0 10px;
    @media(max-width:767px){
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      gap: 0;
    }
  }
  &__pool-box-list{
    &:before{
      width: 600px !important;
      height: 600px !important;
      top: -300px !important;
      left: -30% !important;
    }
    @media(max-width:767px){
      height: 65px !important;
    }
    a{
      small{
        @media(max-width:767px){
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          text-align: left;
          margin-left: 10px !important;
        }
      }
    }
  }
  // toggle issue fixed
  .accordion-collapse{
    transition: height .35s ease;
  }
  .collapse {
    &.collapsing{
      height: 0;
      overflow: hidden;
      transition: height .35s ease;
    }
  }
  .show {
    &.collapsing{
      height: auto;
      overflow: hidden;
      transition: height 0s ease;
    }
  }
  // end toggle issue fixed
}
.accordion-button{
  box-shadow: none !important;
}
.hidden{
  display: none;
}
.hidden-tab{
  display: none;
}

.technology-faq{
  // h2{
  //   margin-bottom:20px;
  //   @media(min-width:992px){
  //     margin-bottom:0px !important;
  //   }
  // }
  &__subtitle{
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    margin-top: -15px;
    @media(min-width:767px){
      font-size: 20px;
      line-height: 30px;
    }
    @media(min-width:1024px){
      font-size: 25px;
      line-height: 34px;
    }
  }
  &__subtitle-br{
    display: none;
    @media(min-width:1024px){
      display: block;
    }
  }
}
//AGILE PODS STYEL START
.augmentation-banner{
  $augmentation-banner: &;
  padding: 40px 0;
  background-image: url(/images/new-home-banner/agile-pod-banner.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-size: cover;
  background-color: #070550;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixedcost{
    background:#070550 url(/images/new-home-banner/agile-fixed-cost-banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media(min-width:992px){
      padding: 60px 0;
    }
  }
  &--stuff-augmentation{
    background: #070550 url(/images/staff-augmentation/banner-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media(min-width:992px){
      padding: 110px 0;
    }
    #{$augmentation-banner}__heading{
      color: #3CC065;
    }
    #{$augmentation-banner}__btn {
      background: #ff5800;
      &:hover {
          background: none;
      }
    }
    #{$augmentation-banner}__bannertitle {
      @include media-query(992px) {
          font-size: 28px;
          line-height: 40px;
          font-weight: 500;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    color: #20DEA5;
    font-size: 17px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    @media(min-width:767px){
      text-align: left;
      font-size: 21px;
    }
  }
  &__subheading{
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin: 12px 0 18px;
    @media(min-width:767px){
      text-align: left;
      font-size: 50px;
      line-height: 60px;
    }
  }
  &__bannertitle{
    font-family: "Poppins";
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 15px;
    color: #fff;
    @media(min-width:767px){
      text-align: left;
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__bannercontent{
    font-family: "Poppins";
    font-size: 17px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    margin: 0;
    @media(min-width:767px){
      text-align: left;
      font-size: 18px;
    }
  }
  &__btn{
    border: 2px solid #FF5800;
    margin: 20px auto 0;
    font-family: "Poppins";
    color: #fff;
    max-width: 100%;
    font-size: 17px;
    font-weight: 600;
    text-transform: inherit;
    padding: 17.5px;
    width: 100%;
    @media(min-width:767px){
      width: 340px;
    }
    svg{
      margin-left: 3px;
    }
    &:hover{
      background-color: #FF5800;
      color: #fff;
    }
    @media(min-width:767px){
      max-width: 340px;
      margin: 35px 0 0;
    }
  }
}
.development-company{
  background-color: #F9F9F9;
  padding: 35px 0;
  &__content{
    justify-content: center;
    @media(min-width:767px){
      justify-content: flex-start;
    }
    svg{
      display: none;
      @media(min-width:992px){
        display: block;
      }
    }
  }
  &__title{
    font-family: "Poppins";
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    color: #333;
    margin: 0 0 15px;
    width: 100%;
    text-align: center;
    @media(min-width:992px){
      width: 90%;
      text-align: left;
      margin: 0;
    }
  }
  &__list{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media(min-width:1024px){
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
    li{
      width: 47%;
      @media(min-width:767px){
        width: auto;
      }
    }
  }
}
.what-are{
  border-top: 1px solid #DDDADA;
  border-bottom: 1px solid #DDDADA;
  padding: 40px 0;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &__title{
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin: 0 0 20px;
    font-weight: 500;
    @media(min-width:767px){
      text-align: left;
      margin: 0;
      font-size: 50px;
      line-height: 60px;
    }
  }
  &__content{
    font-family: "Poppins";
    color: #262E36;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin: 0;
    @media(min-width:767px){
      text-align: left;
      font-size: 21px;
      line-height: 35px;
    }
  }
}
.why-choose{
  $why-choose : &;
  background-color: #F9F9F9;
  padding: 40px 0;
  border-bottom: 1px solid #DDDADA;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$why-choose}__list{
      flex-wrap: wrap;
    }
    #{$why-choose}__item{
      width: 100%;
      border: 1px solid #D0E3F5;
      background-color: #fff;
      &:before{
        display: none;
      }
      @media(min-width:767px){
        max-width: 370px;
        height: 300px;
      }
    }
    #{$why-choose}__btn{
      text-transform: inherit;
      width: 100%;
      max-width: 100%;
      margin: 30px auto 0;
      @media(min-width:767px){
        max-width: 310px;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center;
    @media(min-width:767px){
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 50px;
    }
  }
  &__list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    flex-wrap: wrap;
    @media(min-width:992px){
      flex-wrap: nowrap;
    }
  }
  &__item{
    position: relative;
    cursor: default;
    overflow: hidden;
    padding: 2px 1.4px 1px;
    border: 0;
    max-width: 415px;
    width: 100%;
    height: auto;
    @media(min-width:992px){
      height: 377px;
    }
    &:before{
      content: '';
      position: absolute;
      top: -120px;
      right: 0;
      bottom: 0;
      left: -120px;
      background: #898989;
      width: 600px;
      height: 600px;
      -webkit-transition: .3s all ease;
      transition: .3s all ease;
    }
    &:hover{
      &:before{
        animation: rotation2 3s linear infinite;
        background: -webkit-gradient(linear, left top, left bottom, from(#88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, .62)), to(rgba(6, 87, 181, .62)));
        background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, .62) 52%, rgba(6, 87, 181, .62) 100%);
      }
    }
  }
  &__items{
    position: relative;
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    display: block;
    padding: 30px 28px;
    &--stuff-aug{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      @media(min-width:992px){
        flex-wrap: nowrap;
        padding: 45px 30px;
      }
    }
  }
  &__itemscontent{
    padding-top: 20px;
    @media(min-width:992px){
      padding-top: 0;
      padding-left: 40px;
    }
    @media(min-width:1200px){
      padding-right: 40px;
    }
  }
  &__title{
    font-family: "Poppins";
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0 15px;
    font-weight: 600;
    color: #262E36;
    @media(min-width:992px){
      font-size: 24px;
      line-height: 27px;
      margin: 30px 0 20px;
    }
    &--stuff-aug{
      margin: 0 0 10px;
      @media(min-width:992px){
        margin: 0 0 5px;
      }
    }
  }
  &__content{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    margin: 0;
    color: #262E36;
  }
}
.sample-pods{
  padding: 40px 0;
  background-color: #fff;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &__heading{
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 50px;
    }
  }
  &__list{
    display: flex;
    max-width: 1167px;
    flex-direction: column;
  }
  &__item{
    width: 100%;
    height: auto;
    border: 1px solid #D7D6D6;
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    @media(min-width:767px){
      flex-wrap: nowrap;
    }
    @media(min-width:992px){
      height: 328px;
      margin-bottom: 45px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__itemimage{
    max-width: 359px;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__itemcontent{
    width: auto;
    padding:25px;
    flex-grow: 1;
    @media(min-width:992px){
      padding:50px;
    }
  }
  &__itemtitle{
    font-family: "Poppins";
    font-size: 20px;
    color: #0C2848;
    margin: 0 0 10px;
    font-weight: 600;
    @media(min-width:992px){
      font-size: 30px;
    }
  }
  &__itemparagraph{
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    margin: 0 0 20px;
    color: #0C2848;
  }
  &__ulheading{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    font-weight: 600;
    margin: 0 0 10px;
  }
  &__ul{
    display: flex;
    gap: 8px 5px;
    flex-wrap: wrap;
  }
  &__li{
    display: inline-flex;
    span{
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      padding: 8px 19px 6px;
      border-radius: 20px;
      border: 1px solid;
      transition: .3s all ease-in-out;
      cursor: default;
      &.blue{
        border-color: #0A9DEF;
        &:hover{
          background-color: #0A9DEF;
          color: #fff;
        }
      }
      &.green{
        border-color: #20DEA5;
        &:hover{
          background-color: #20DEA5;
          color: #fff;
        }
      }
      &.yellow{
        border-color: #FFC327;
        &:hover{
          background-color: #FFC327;
          color: #fff;
        }
      }
      &.bergendy{
        border-color: #A66BD4;
        &:hover{
          background-color: #A66BD4;
          color: #fff;
        }
      }
      &.deepgreen{
        border-color: #4FA941;
        &:hover{
          background-color: #4FA941;
          color: #fff;
        }
      }
    }
  }
  &__btn{
    text-transform: inherit;
    max-width: 100%;
    font-size: 17px;
    padding: 17px;
    width: 100%;
    margin: 45px auto 0;
    @media(min-width:767px){
      max-width: 340px;
    }
  }
}
.pods-billing{
  $pods-billing : &;
  padding: 40px 0;
  background-image: url(/images/new-home-banner/agile-prods/billing-background.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  background-size: cover;
  background-color: #052C58;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$pods-billing}__item{
      flex-wrap: wrap;
      @media(min-width:992px){
        min-height: 660px;
      }
    }
  }
  &--staff-augmentation{
    #{$pods-billing}__sub-heading{
      font-family: "Poppins";
      color: #fff;
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 60px;
    }
    #{$pods-billing}__big-card{
      background-color: #fff;
      padding: 25px;
      border-radius: 20px;
      margin-bottom: 70px;
      @media(min-width:1024px){
        padding: 50px;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 25px;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
      margin: 0 0 40px;
    }
  }
  &__list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__item{
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 25px 15px;
    border-bottom: 8px solid;
    background-color: #fff;
    text-align: center;
    margin: 0 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media(min-width:767px){
      max-width: 494px;
    }
    @media(min-width:992px){
      margin: 0 15px;
      min-height: 732px;
      padding: 40px 30px;
    }
    @media(min-width:1100px){
      margin: 0 30px;
    }
    &--blue{
      border-color: #5250A7;
      #{$pods-billing }__listheading{
        color: #355BBF;
      }
      #{$pods-billing }__itemworks{
        border-color: #0A9DEF;
        h5{
          background-color: #355BBF;
        }
      }
    }
    &--green{
      border-color: #0EB885;
      #{$pods-billing }__listheading{
        color: #0EB885;
      }
      #{$pods-billing }__itemworks{
        border-color: #0EB885;
        h5{
          background-color: #0EB885;
        }
      }
    }
  }
  &__listheading{
    font-family: "Poppins";
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    margin: 0 0 20px;
    @media(min-width:992px){
      font-size: 36px;
      line-height: 42px;
      margin: 0 0 30px;
    }
  }
  &__itemworks{
    position: relative;
    width: 100%;
    border-radius: 6px;
    border: 1px solid;
    padding: 20px 15px 15px;
    text-align: center;
    margin-bottom: 25px;
    @media(min-width:992px){
      padding: 25px 23px 20px;
      margin-bottom: 35px;
    }
    h5{
      font-family: "Poppins";
      font-size: 14px;
      background-color: #ccc;
      color: #fff;
      padding: 3px 10px;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: -10px;
      margin: auto;
      width: 139px;
    }
    p{
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #0C2848;
      margin: 0 !important;
    }
  }
  &__listbartitle{
    position: relative;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #0C2848;
    margin-bottom: 18px;
    &::after{
      position: absolute;
      content: "";
      width: 49px;
      height: 3px;
      border-radius: 4px;
      background-color: #0C2848;
      left: 0;
      right: 0;
      bottom: -8px;
      margin: auto;
    }
  }
  p{
    font-family: "Poppins";
    color: #0C2848;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__listitle{
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #0C2848;
    margin: 0 0 7px !important;
  }
  &__btn{
    text-transform: inherit;
    max-width: 100%;
    width: 100%;
    margin-top: auto;
    font-size: 17px;
    padding: 17px;
    svg{
      margin-left: 5px;
    }
  }
  &__big-card-list{
    display: flex;
    flex-wrap: wrap;
  }
  &__big-card-item{
    width: 100%;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px dashed #D9D9D9;
    @media(min-width:1024px){
      width: 50%;
      border-right: 1px dashed #D9D9D9;
      min-height: 250px;
      padding-right: 70px;
    }
    &:nth-child(1){
      justify-content: flex-start;
    }
    &:nth-child(2){
      justify-content: flex-start;
    }
    &:nth-child(5){
      justify-content: flex-start;
      @media(min-width:1024px){
        padding-top: 70px;
        border-bottom: 0px;
      }
    }
    &:nth-child(6){
      justify-content: flex-start;
      padding-top: 0px;
      border-bottom: 0px;
      @media(min-width:1024px){
        padding-top: 70px;
      }
    }
    &:nth-child(2n+2){
      padding-left: 0px;
      border-right: 0px;
      @media(min-width:1024px){
        padding-left: 50px;
      }
    }
    li{
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #262E36;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start;
      svg{
        margin-right: 12px;
        margin-top: 6px;
        flex: 0 0 18px;
      }
    }
  }
  &__big-card-item-top{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i{
      margin-right: 12px;
    }
    h3{
      font-family: "Poppins";
      color: #0C2848;
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
    }
  }
  &__ready-bottom-text{
    font-family: "Poppins";
    font-size: 35px;
    line-height: 46px;
    color: #fff;
    margin-left: 14px;
    text-align: center;
    font-weight: 600;
  }
  &__ready-bottom-sub-text{
    font-family: "Poppins";
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin-bottom: 35px;
  }
  &__ready-bottom-ul{
    max-width: 562px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li{
      font-family: "Poppins";
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      margin: 5px 0;
      width: 100%;
      @media(min-width:992px){
        width: 50%;
      }
    }
  }
}
.compare-agile{
  $compare-agile : &;
  padding: 40px 0;
  background-color: #fff;
  border-bottom: 1px solid #DDDADA;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$compare-agile}__heading{
      margin-bottom: 15px;
    }
    #{$compare-agile}__tableitems{
      li{
        @media(min-width:992px){
          height: 120px;
        }
        &:first-child{
          display: flex;
        }
      }
      &:last-child{
        li{
          &:nth-child(3){
            @media(min-width:992px){
              height: 150px;
            }
          }
        }
      }
    }
    #{$compare-agile}__btn{
      text-transform: inherit;
      width: 100%;
      padding: 17px;
      margin: 45px auto 0;
      @media(min-width:992px){
        max-width: 431px;
      }
    }
  }
  &--stuff-aug{
    #{$compare-agile}__heading{
      margin-bottom: 20px;
    }
    #{$compare-agile}__tableitems{
      li{
        @media(min-width:992px){
          height: 150px;
        }
        &:first-child{
          display: flex;
        }
      }
      &:last-child{
        li{
          &:nth-child(3){
            @media(min-width:992px){
              height: 165px;
            }
          }
        }
      }
    }
    #{$compare-agile}__btn{
      text-transform: inherit;
      width: 100%;
      padding: 17px;
      margin: 45px auto 0;
      @media(min-width:992px){
        max-width: 431px;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 500;
    text-align: center;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 50px;
    }
  }
  &__subheading{
    font-family: "Poppins";
    color: #474747;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center;
    @media(min-width:992px){
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 50px;
    }
  }
  &__tablewrapper{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &__topheading{
    display: block;
    font-family: "Poppins";
    color: #262E36;
    font-size: 18px;
    margin-bottom: 10px;
    @media(min-width:992px){
      display: none;
    }
  }
  &__tabletitle{
    display: none;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    @media(min-width:992px){
      display: flex;
    }
    li{
      font-family: "Poppins";
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      line-height: 30px;
      font-weight: 600;
      color: #262E36;
      background-color: #F7F9FB;
      width: 100%;
      height: 110px;
      border-top: 1px solid #B6BECD;
      border-bottom: 1px solid #B6BECD;
      border-left: 1px solid #B6BECD;
      @media(min-width:992px){
        width: 25%;
      }
      &:first-child{
        justify-content: flex-start;
        padding: 20px 55px;
      }
      &:last-child{
        border-right: 1px solid #B6BECD;
        &::after{
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #A66BD4;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &:nth-child(2){
        height: 134px;
        border-radius: 9px 9px 0 0;
        border-top: 3px solid #A5B8DC;
        border-left: 3px solid #A5B8DC;
        border-right: 3px solid #A5B8DC;
        &::after{
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #20DEA5;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &:nth-child(3){
        background-color: #EAEFF5;
        border-left: 0px;
        &::after{
          position: absolute;
          content: "";
          width: 41px;
          height: 6px;
          background-color: #FFC327;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  &__tableitems{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px;
    border-radius: 0 0 4px 4px;
    @media(min-width:992px){
      margin: 0;
      border-radius: 0;
    }
    li{
      font-family: "Poppins";
      position: relative;
      color: #333333;
      padding: 20px 25px;
      text-align: left;
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
      border-bottom: 1px solid #B6BECD;
      border-left: 1px solid #B6BECD;
      border-right: 1px solid #B6BECD;
      width: 100%;
      display: flex;
      align-items: center;
      &.text-center{
        background-color: #EAEFF5;
      }
      h6{
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600;
      }
      @media(min-width:992px){
        width: 25%;
        height: 100px;
        border-right: 0;
        border-bottom: 1px solid #B6BECD;
        border-left: 1px solid #B6BECD;
      }
      b{
        font-weight: 600;
      }
      &:last-child{
        border-right: 1px solid #B6BECD;
      }
      &:first-child{
        background-color: #355BBF;
        font-size: 19px;
        font-weight: 600;
        color: #fff;
        border-left: 0px;
        border-bottom: 1px solid #1D3D70;
        svg{
          margin-right: 12px;
        }
      }
      &:nth-child(3){
        @media(min-width:992px){
          border-left: 3px solid #A5B8DC;
          border-right: 3px solid #A5B8DC;
        }
      }
      &:nth-child(5){
        @media(min-width:992px){
          border-left: 0px;
          background-color: #EAEFF5;
        }
      }
    }
    &:last-child{
      li{
        &:first-child{
          border-bottom: 0px;
        }
        &:nth-child(3){
          @media(min-width:992px){
            height: 124px;
            border-radius: 0 0 9px 9px;
            border-bottom: 3px solid #A5B8DC;
          }
        }
      }
    }
  }
}
.our-approach{
  background-color: #F9F9F9;
  .o-development-process__spacing{
    padding: 40px 0;
    @media(min-width:992px){
      padding: 80px 0;
    }
  }
  &__heading{
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin:0;
    text-align: center;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
      margin: 0 0 30px;
    }
  }
  .o-development-process__items{
    border: 1px solid #D0E3F5;
    box-shadow: 0px 15px 20px 0px rgba(12,40,72,0.07);
    padding: 0 15px 20px;
    margin:60px 0 0;
    @media(min-width:992px){
      min-height: 335px;
      margin:52px 0 0;
      padding: 0 28px 15px;
    }
    &.blue{
      border-bottom: 5px solid #64B9F4;
    }
    &.lgreen{
      border-bottom: 5px solid #20DEA5;
    }
    &.dyellow{
      border-bottom: 5px solid #F29906;
    }
    &.lyellow{
      border-bottom: 5px solid #FFC327;
    }
    &.purple{
      border-bottom: 5px solid #A15FAE;
    }
    &.dgreen{
      border-bottom: 5px solid #4FA941;
    }
    &::after{
      display: none;
    }
  }
  .o-development-process__itemtitle{
    font-size: 24px;
    line-height: 30px;
    color: #0C2848;
    margin: 0 0 5px;
  }
  .o-development-process__itemcontent{
    font-size: 16px;
    line-height: 27px;
    color: #262E36;
  }
  &__btn{
    max-width: 340px !important;
    padding: 17px !important;
  }
}
.get-fortress{
  $get-fortress: &;
  border-top: 1px solid #D8D8D8;
  background-color: #fff;
  padding: 40px 0;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$get-fortress}__btn{
      width: 100%;
      max-width: 100%;
      margin: 35px auto 0;
      @media(min-width:767px){
        max-width: 366px;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin: 0 0 10px;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
    }
  }
  &__content{
    font-family: "Poppins";
    font-size: 18px;
    line-height: 30px;
    color: #262E36;
    margin-bottom: 15px;
    @media(min-width:992px){
      font-size: 21px;
      line-height: 35px;
      margin-bottom: 25px;
    }
    &--mb50{
      @media(min-width:992px){
        margin-bottom: 50px;
      }
    }
    &.medium-0{
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
  &__robust{
    padding: 25px 0;
    background: #052C58 url(/images/new-home-banner/agile-prods/get-fortress.jpg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    margin-bottom: 40px;
    @media(min-width:992px){
      padding: 45px 0;
      margin-bottom: 80px;
    }
  }
  &__robustul{
    display: flex;
    flex-direction: column;
    li{
      background-color: #fff;
      padding: 25px;
      border-top: 5px solid;
      margin-bottom: 9px;
      @media(min-width:992px){
        margin-bottom: 9px;
        padding: 38px 42px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &.blue{
        border-color: #0A9DEF;
      }
      &.green{
        border-color: #20DEA5;
      }
      &.yellow{
        border-color: #FFC327;
      }
      h4{
        font-family: "Poppins";
        font-size: 20px;
        line-height: 24px;
        color: #0C2848;
        margin-bottom: 5px;
        font-weight: 600;
        @media(min-width:992px){
          font-size: 24px;
          line-height: 27px;
        }
      }
      p{
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25px;
        margin: 0;
      }
    }
  }
  &__btn{
    text-transform: inherit;
    max-width: 100%;
    padding: 17px;
    margin: auto;
    @media(min-width:767px){
      max-width: 284px;
    }
  }
}
.pods-benefits{
  $pods-benefits : &;
  border-top: 1px solid #D8D8D8;
  background-color: #F9F9F9;
  padding: 40px 0;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$pods-benefits}__item{
      width: 100%;
      padding: 20px;
      @media(min-width:767px){
        width: 240px;
        padding: 30px 25px;
      }
      @media(min-width:992px){
        height: 176px;
      }
      &::before{
        position: absolute;
        content: "";
        width: 41px;
        height: 4px;
        left: 25px;
        top: 0;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    margin: 0 0 30px;
    text-align: center;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 60px;
      margin: 0 0 50px;
    }
    
  }
  &__list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    @media(min-width:992px){
      gap: 25px;
      flex-wrap: nowrap;
    }
  }
  &__item{
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 30px;
    border: 1px solid #DDE2EA;
    @media(min-width:767px){
      width: 300px;
      height: 275px;
    }
    &::before{
      position: absolute;
      content: "";
      width: 4px;
      height: 41px;
      left: 0;
      top: 40px;
    }
    &.blue{
      &:before{
        background-color: #0A9DEF;
      }
    }
    &.purple{
      &:before{
        background-color: #A66BD4;
      }
    }
    &.green{
      &:before{
        background-color: #20DEA5;
      }
    }
    &.yellow{
      &:before{
        background-color: #FFC327;
      }
    }
    &.lgreen{
      &:before{
        background-color: #4FA941;
      }
    }
  }
  &__itemheading{
    font-family: "Poppins";
    font-size: 18px;
    line-height: 24px;
    color: #0C2848;
    font-weight: 600;
    margin: 0 0 5px;
    @media(min-width:992px){
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__itemcontent{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 26px;
    color: #0C2848;
    margin: 0;
  }
}
.get-experience{
  $get-experience : &;
  background: #052C58 url(/images/new-home-banner/agile-prods/ready-experience-background.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  padding: 40px 0;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &--fixed-cost{
    #{$get-experience}__btn{
      @media(min-width:767px){
        max-width: 408px;
      }
    }
  }
  &--staff-augmentation{
    #{$get-experience}__content{
      font-family: "Poppins";
      color: #fff;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 20px;
      font-weight: 500;
    }
    #{$get-experience}__btn{
      margin: 0;
      @media(min-width:767px){
        max-width: 259px;
      }
    }
    #{$get-experience}__btn1{
      margin: 0;
      text-transform: inherit;
      padding: 17px;
      color: #fff;
      border-color: #fff;
      max-width: 100%;
      width: 100%;
      @media(min-width:767px){
        max-width: 259px;
      }
      &:hover{
        background-color: #fff;
        color: #ff5800;
      }
    }
  }
  &__heading{
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin: 0 0 10px;
    @media(min-width:992px){
      font-size: 40px;
      line-height: 46px;
    }
  }
  &__content{
    font-family: "Poppins";
    color: #fff;
    font-size: 16px;
    line-height: 28px;
  }
  &__list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-bottom: 25px;
  }
  &__items{
    font-family: "Poppins";
    color: #B0C0E9;
    font-size: 14px;
    font-weight: 600;
  }
  &__btn{
    text-transform: inherit;
    font-size: 17px;
    padding: 17px;
    max-width: 100%;
    margin: 40px auto 0;
    width: 100%;
    @media(min-width:767px){
      max-width: 299px;
    }
  }
  &__bottomnote{
    font-family: "Poppins";
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    margin: 20px 0 0;
  }
  &__pair{
    max-width: 684px;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: auto;
    margin-bottom: 25px;
    flex-wrap: wrap;
    @media(min-width:992px){
      flex-wrap: nowrap;
    }
  }
  &__infomail{
    font-family: "Poppins";
    text-align: center;
    font-size: 17px;
    color: #B0C0E9;
    a{
      display: block;
      font-family: "Poppins";
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      &:hover{
        color: #ff5800;
      }
    }
  }
}
.how-it-agile{
  $how-it-agile : &;
  background-color: #F9F9F9;
  padding: 40px 0;
  border-bottom: 1px solid #DDDADA;
  @media(min-width:992px){
    padding: 80px 0;
  }
  &__heading{
    font-family: "Poppins";
    color: #262E36;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 35px;
    font-weight: 500;
    text-align: center;
    @media(min-width:767px){
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 43px;
    }
  }
  &__list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    @media(min-width:1200px){
      gap: 0px;
      justify-content: space-between;
      flex-direction: column;
      height: 613px;
      align-items: center;
    }
  }
  &__items{
    position: relative;
    max-width: 305px;
    width: 100%;
    height: 284px;
    border-radius: 6px;
    border: 1px solid #D0E3F5;
    background-color: #fff;
    box-shadow: 0px 20px 30px 0 rgba(0,0,0,0.06);
    padding: 40px 20px 30px;
    &:nth-child(2){
        @media(min-width:1200px){
          left: 190px;
        }
    }
    &:nth-child(4){
      @media(min-width:1200px){
        left: 190px;
      }
    }
    &.blue{
      &:after{
        position: absolute;
        content: "";
        width: 52px;
        height: 253px;
        background-image: url(/images/new-home-banner/agile-fixed-cost/blue-arrow.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        left: 0;
        right: 0;
        bottom: -253px;
        margin: auto;
        display: none;
        @media(min-width:1200px){
          display: block;
        }
        @media(min-width:1230px){
          left: 25px;
        }
      }
    }
    &.green{
      &:after{
        position: absolute;
        content: "";
        width: 52px;
        height: 253px;
        background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-green.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        left: 0;
        right: 0;
        top: -253px;
        margin: auto;
        display: none;
        @media(min-width:1200px){
          display: block;
        }
        @media(min-width:1230px){
          left: 25px;
        }
      }
    }
    &.purple{
      &:after{
        position: absolute;
        content: "";
        width: 52px;
        height: 173px;
        background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-purple.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        left: 0;
        right: 0;
        bottom: -173px;
        margin: auto;
        display: none;
        @media(min-width:1200px){
          display: block;
        }
        @media(min-width:1230px){
          left: 25px;
        }
      }
    }
    &.yellow{
      &:after{
        position: absolute;
        content: "";
        width: 52px;
        height: 173px;
        background-image: url(/images/new-home-banner/agile-fixed-cost/arrow-yellow.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        left: 0;
        right: 0;
        top: -173px;
        margin: auto;
        display: none;
        @media(min-width:1200px){
          display: block;
        }
        @media(min-width:1230px){
          left: 25px;
        }
      }
    }
  }
  &__titlearea{
    margin-bottom: 12px;
    text-align: center;
    @media(min-width:992px){
      text-align: left;
    }
  }
  &__itemtitle{
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    margin: 20px 0 0;
    color: #0C2848;
  }
  &__itemcontent{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262E36;
    font-weight: 400;
    margin: 0;
  }
  &__number{
    position: absolute;
    font-family: "Poppins";
    font-size: 54px;
    color: #f2f2f2;
    right: 10px;
    top: 10px;
  }
  &__btn{
    text-transform: inherit;
    padding: 17px;
    max-width: 100%;
    width: 100%;
    margin: 30px auto 0;
    @media(min-width:767px){
      margin: 50px auto 0;
      max-width: 341px;
    }
  }
}
.how-stuff-augmentation{
  background-color: #fff;
  .o-development-process__spacing{
    padding: 40px 0;
    @media(min-width:992px){
      padding: 80px 0;
    }
  }
  .o-development-process__items{
    width: 100%;
    border: 1px solid #D0E3F5;
    box-shadow: 0px 15px 20px 0 rgba(12,40,72,0.07);
    background-color: #fff;
    margin: 50px 0 0;
    @media(min-width:767px){
      width: 260px;
      height: 280px;
    }
    &.blue{
      &:after{
        background-image: url(/images/new-home-banner/staff-augmentation/blue-arrow.svg);
        width: 26px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        right: -28px;
        display: none;
        @media(min-width:992px){
          display: block
        }
      }
    }
    &.green{
      &:after{
        background-image: url(/images/new-home-banner/staff-augmentation/green-arrow.svg);
        width: 26px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        right: -28px;
        display: none;
        @media(min-width:992px){
          display: block
        }
      }
    }
    &.yellow{
      &:after{
        background-image: url(/images/new-home-banner/staff-augmentation/yellow-arrow.svg) !important;
        width: 26px !important;
        height: 18px !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        right: -28px;
        bottom: inherit;
        top: 50%;
        display: none;
        @media(min-width:992px){
          display: block
        }
      }
    }
  }
  .o-development-process__itemtitle{
    font-family: "Poppins";
    font-size: 24px;
    line-height: 30px;
    color: #0C2848;
    font-weight: 600;
    margin: 0 0 10px;
  }
  .o-development-process__itemcontent{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262E36;
  }
  .o-development-process__bottom-text{
    font-family: "Poppins";
    font-size: 30px;
    line-height: 50px;
    margin: 50px 0 15px;
    font-weight: 500;
  }
  .o-development-process__list{
    justify-content: center;
    gap: 20px;
    @media(min-width:1024px){
      gap: 0px;
      justify-content: space-between;
    }
  }
  &__btn{
    text-transform: inherit;
    max-width: 100%;
    width: 100%;
    @media(min-width:767px){
      max-width: 263px;
    }
  }
}
.stuff-augmentation-technologies{
  padding: 40px 0;
  padding-bottom: 0;
  @media(min-width:992px){
    padding: 80px 0;
  }
  .technologies-tab__heading{
    font-weight: 500;
  }
  .o-accordion__button{
    font-weight: 600;
  }
  .o-accordion__content-title{
    font-weight: 600;
  }
}
//AGILE PODS STYEL ENDS

.custom-dev-tech {
  .technologies-tab__heading {
    font-weight: 500;
  }
  .custom-solution__sub-heading {
    font-weight: 400 !important;
  }
}
.emerging {
    padding: 50px 0 30px;
    @include media-query(992px) {
        padding: 60px 0 80px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 40px;
        }
    }
    &-box {
        text-align: center;
        background: #fff;
        padding: 30px 20px 5px;
        border-radius: 8px;
        margin-bottom: 20px;
        border: 1px solid #D9D9D9;
        @include media-query(992px) {
            padding: 50px 20px 30px;
            margin-bottom: 25px;
        }
        h3 {
            font-weight: 500;
            margin-bottom: 15px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            @include media-query(992px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
        .emerge-img {
            margin-bottom: 20px;
            @include media-query(992px) {
                height: 66px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.design-studio-uiux {
    .o-key-diff-box {
        @include media-query(992px) {
            min-height: 230px;
        }
    }
    .o-key-diff-left {
        h3 {
            @include media-query(992px) {
                font-size: 22px;
            }
            @include media-query(1280px) {
                font-size: 30px;
                line-height: 40px;
            }
        }
    }
    .col-lg-4 {
        display: block;
    }
}


.sample-work {
    padding: 50px 0 30px;
    @include media-query(992px) {
        padding: 60px 0 80px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 40px;
        }
    }
    &-box {
        margin-bottom: 0;
        @include media-query(992px) {
            margin-bottom: 0;
        }
        &-wrapper {
            position: relative;
            .sample-work-box-img {
                width: 100%;
                height: 247px;
                border-radius: 6px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .play-icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,0.2);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                cursor: pointer;
                transition: .3s all ease-in-out;
                &:hover {
                    background: rgba(0,0,0,0.25);
                    img {
                        transform: scale(1.05);
                    }
                }
                img {
                    transition: .3s all ease-in-out;
                }
            }
        }
        h5 {
            margin-top: 15px;
            text-align: center;
            @include media-query(992px) {
                text-align: left;
                margin-top: 30px;
            }
        }
    }
    .btn_wrapper {
        margin-top: 0;
        @include media-query(992px) {
            margin-top: 60px;
        }
    }
    .slick-slide {
        margin: 0 15px;
    }
    ul.slick-dots {
        margin-top: 0;
        @include media-query(992px) {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    .splide__pagination {
        @include media-query(992px) {
            margin-top: 35px;
        }
    }
}

.sample-work-clip {
    video {
        width: 100%;
        margin-top: 10px;
        border-radius: 12px;
    }
    .modal-content {
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }
    .modal-header {
        padding: 0;
        border-bottom: 0;
        .btn-close {
            padding: 0;
            margin: 0;
            position: absolute;
            // right: -12px;
            // top: -15px;
            right: 7px;
            top: 0;
            @include media-query(992px) {
                right: -12px;
                top: -10px;
            }
            width: 26px;
            height: 26px;
            background-color: #fff;
            opacity: 1;
            border-radius: 50%;
            display: flex;
            align-items: center;
            background-position: 7px center;
            border: 1px solid #5B5B5B;
            background-image: url(/images/design-studio/modal-close-icon.svg);
        }
    }
    .modal-body {
        padding: 0;
        // padding: 8px 12px 2px 12px;
        // background: #fff;
        font-size: 18px;
        font-weight: 600;
        // border-radius: 12px;
        @include media-query(992px) {
            font-size: 24px;
            background: #fff;
            padding: 10px 10px 0 10px;
            border-radius: 12px;
            // background-image: url(../images/design-studio/modal-loader.gif);
            // background-repeat: no-repeat;
            // background-position: center center;
        }
        @include media-query(1280px) {
            padding: 10px 12px 0 12px;
        }
        #videoTitle {
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        
    }
    .modal-dialog {
        margin: 0 auto;
        @include media-query(992px) {
            max-width: 800px !important;
        }
        iframe {
            height: 470px;
            @include media-query(992px) {
                border-radius: 12px;
                margin-top: 10px;
                height: 435px;
            }
            @include media-query(1280px) {
                height: 463px;
            }
            @include media-query(1500px) {
                height: 520px;
            }
            @include media-query(1700px) {
                height: 615px;
            }
            @include media-query(1800px) {
                height: 665px;
            }
            .vp-spin {
                display: none !important;
            }
        }
        @include media-query(1280px) {
            max-width: 845px !important;
        }
        @include media-query(1500px) {
            max-width: 945px !important;
        }
        @include media-query(1700px) {
            max-width: 1115px !important;
        }
        @include media-query(1800px) {
            max-width: 1200px !important;
        }
    }
    z-index: 9999999;
    background: rgba(0,0,0,.95);
    .modal-dialog-centered {
        min-height: calc(100% - .5rem);
    }
}


.new-tech-stack-heading-top {
    margin-bottom: 25px;
    @include media-query(992px) {
        margin-bottom: 40px;
    }
}

.sample-work-slider {
    flex-wrap: nowrap;
    .col-lg-4 {
        width: 100%;
    }
    .owl-dots {
       margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 0;
            margin-top: 30px;
        }
    }
}


.new-service-section {
    padding: 40px 0 10px;
    @include media-query(992px) {
        padding: 70px 0 60px;
    }
    h2 {
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 70px;
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        @include media-query(992px) {
            display: inherit;
            column-count: 2;
            column-gap: 30px;
        }
    }
    &-box {
        margin-bottom: 35px;
        text-align: center;
        @include media-query(992px) {
            margin-bottom: 50px;
            break-inside: avoid;
            page-break-inside: avoid;
            display: flex;
        }
        i {
            display: block;
            width: 50px;
            margin: 0 auto 20px;
            @include media-query(992px) {
                display: inline-block;
                margin: 0;
            }
        }
        &-right {
            flex: 1;
            text-align: center;
            @include media-query(992px) {
                padding-left: 20px;
                margin-left: 10px;
                text-align: left;
            }
            h3 {
                font-size: 20px;
                @include media-query(992px) {
                    font-size: 26px;
                }
                font-weight: 500;
                margin-bottom: 15px;
            }
            p {
                font-size: 16px;
                @include media-query(992px) {
                    line-height: 28px;
                }
                @include media-query(1200px) {
                    font-size: 17px;
                }
                @include media-query(1280px) {
                    font-size: 18px;
                }
                margin-bottom: 0;
                a {
                    display: inline;
                    margin: 0;
                    font-size: 18px;
                    width: inherit;
                    position: inherit;
                    color: #333;
                    text-decoration: underline;
                    &:before {
                        display: none;
                    }
                }
                .inner-link {
                    color: #0d6efd;
                    &:hover {
                        color: darken(#0d6efd, 20%);
                    }
                }
            }
             a {
                font-size: 16px;
                display: block;
                margin: 5px auto 0;
                position: relative;
                width: 107px;
                transition: .3s all ease-in-out;
                &:before {
                        content: "➞";
                        position: absolute;
                        right: -17px;
                        top: 0;
                        transition: .3s all ease-in-out;
                }
                @include media-query(1200px) {
                    font-size: 17px;
                    margin-top: 5px;
                    margin-left: 0;
                    margin-right: 0;
                }
                @include media-query(1280px){
                    font-size: 18px;
                }
                &:hover {
                    text-decoration: underline;
                    color: darken(#0d6efd, 10%);
                    &:before {
                        right: -22px;
                    }
                }
             }
        }
    }
    .ns-order-1 {
        order: 1;
    }
    .ns-order-2 {
        order: 2;
    }
    .ns-order-3 {
        order: 3;
    }
    .ns-order-4 {
        order: 4;
    }
    .ns-order-5 {
        order: 5;
    }
    .ns-order-6 {
        order: 6;
    }
    .ns-order-7 {
        order: 7;
    }
    .ns-order-8 {
        order: 8;
    }
    .ns-order-9 {
        order: 9;
    }
    .ns-order-10 {
        order: 10;
    }
    .ns-order-11 {
        order: 11;
    }
    .ns-order-12 {
        order: 12;
    }
    .ns-order-13 {
        order: 13;
    }
    .ns-order-14 {
        order: 14;
    }
    .ns-order-15 {
        order: 15;
    }
}
.margin-top-60-modify{
    margin-top: 20px;
    @include media-query(992px){
        margin-top: 60px;
    }
}

.blue-bnr-section {
    background-color: #04254a;
    padding: 40px 0 50px;
    @include media-query(992px) {
        background-size: contain;
        padding: 60px 0 75px;
    }
    text-align: center;
    h2 {
        font-size: 28px;
        line-height: 38px;
        @include media-query(992px) {
            font-size: 36px;
            line-height: 50px;
        }
        color: #fff;
    }
    p {
        color: #fff;
        font-size: 21px;
        line-height: 28px;
        max-width: 720px;
        margin: 0 auto;
        margin-bottom: 20px;
        font-family: Poppins;

        .anchor-link {
            font-size: 21px;
            line-height: 28px;
        }
    }
}
.white-bnr-mod {
    background-color: #F7F8FA;
    background-image: url(/images/digital-transformation/grad-white-bg.svg);
    h2 {
        color: #333;
    }
    p {
        color: #333;
    }
}



/*Transformation process*/
.d-trans-pro {
    padding: 40px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 60px;
    }
    h2 {
        text-align: center;
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
    }
    &-wrapper {
        display: flex;
        justify-content: center;
        @include media-query(992px) {
            flex-direction: row-reverse;
        }
        .d-trans-pro-tab {
            display: none;
            @include media-query(992px) {
                display: block;
            }
            &-btn {
                position: relative;
                font-size: 18px;
                line-height: 27px;
                color: #333;
                margin-bottom: 35px;
                width: 352px;
                padding: 15px 30px;
                border: 1px solid #fff;
                cursor: pointer;
                &.active {
                    font-weight: 500;
                    background: #fff;
                    box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
                    border: 1px solid #E3E3E3;
                    border-radius: 8px;
                }
                .triangle-class {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    top: 15px;
                    bottom: 0;
                    left: -13px;
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 9;
                        width: 0;
                        height: 0;
                        border-top: 13px solid transparent;
                        border-right: 13px solid #fff;
                        border-bottom: 13px solid transparent;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 15px solid transparent;
                        border-right: 15px solid #E3E3E3;
                        border-bottom: 15px solid transparent;
                        left: -2px;
                        top:-2px;
                    }
                }
                &.active {
                    .triangle-class {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                &:before {
                    position: absolute;
                    top: 7px;
                    left: -89px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 17px;
                    z-index: 9;
                    display: none;
                    @include media-query(992px) {
                        display: flex;
                        left: -71px;
                    }
                    @include media-query(1200px) {
                        left: -96px;
                    }
                }
            }
            .step1 {
                &:before {
                    content: '01';
                    background: #091B38;
                }
            }
            .step2 {
                &:before {
                    content: '02';
                    background: #025683;
                }
            }
            .step3 {
                &:before {
                    content: '03';
                    background: #036497;
                }
            }
            .step4 {
                &:before {
                    content: '04';
                    background: #1B89AF;
                }
            }
            .step5 {
                &:before {
                    content: '05';
                    background: #2BBDC3;
                }
            }
            .step6 {
                &:before {
                    content: '06';
                    background: #28C89A;
                }
            }
            .step7 {
                &:before {
                    content: '07';
                    background: #3BE06E;
                }
            }
            .step8 {
                &:before {
                    content: '08';
                    background: #4CE369;
                }
            }
            .step9 {
                &:before {
                    content: '09';
                    background: #75E34C;
                }
            }
        }
        .d-trans-pro-content {
            align-self: flex-start;
            &-box {
                background: #F7F8FA;
                border-radius: 8px;
                padding: 25px 15px 5px 20px;
                width: 100%;
                margin-bottom: 30px;
                position: relative;
                @include media-query(992px) {
                    display: none;
                    margin-bottom: 0;
                    padding: 30px 30px 5px 30px;
                    width: 400px;
                }
                &::before {
                    position: absolute;
                    top: 7px;
                    left: -53px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 17px;
                    z-index: 9;
                    display: flex;
                    @include media-query(992px) {
                        display: none;
                    }
                }
                i {
                    display: inline-block;
                    height: 45px;
                    width: 45px;
                    @include media-query(992px){
                        height: 60px;
                        width: 60px;
                    }
                    img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                    }
                }
                h3 {
                    font-size: 20px;
                    line-height: 30px;
                    @include media-query(992px) {
                        font-size: 26px;
                        line-height: 36px;
                    }
                    padding: 15px 0 0;
                    font-weight: 500;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                    @include media-query(992px) {
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
                &1 {
                    &:before {
                        content: '01';
                        background: #091B38;
                    }
                }
                &2 {
                    @include media-query(992px) {
                        margin-top: 80px;
                    }
                    &:before {
                        content: '02';
                        background: #025683;
                    }
                }
                &3 {
                    @include media-query(992px) {
                        margin-top: 180px;
                    }
                    &:before {
                        content: '03';
                        background: #036497;
                    }
                }
                &4 {
                    @include media-query(992px) {
                        margin-top: 280px;
                    }
                    &:before {
                        content: '04';
                        background: #1B89AF;
                    }
                }
                &5 {
                    @include media-query(992px) {
                        margin-top: 390px;
                    }
                    &:before {
                        content: '05';
                        background: #2BBDC3;
                    }
                }
                &6 {
                    @include media-query(992px) {
                        margin-top: 390px;
                    }
                    &:before {
                        content: '06';
                        background: #28C89A;
                    }
                }
                &7 {
                    @include media-query(992px) {
                        margin-top: 390px;
                    }
                    &:before {
                        content: '07';
                        background: #3BE06E;
                    }
                }
                &8 {
                    @include media-query(992px) {
                        margin-top: 422px;
                    }
                    &:before {
                        content: '08';
                        background: #3BE06E;
                    }
                }
                &9 {
                    @include media-query(992px) {
                        margin-top: 532px;
                    }
                    &:before {
                        content: '09';
                        background: #75E34C;
                    }
                }
            }
        }
        .d-trans-pro-steps {
            margin-right: 40px;
            @include media-query(992px) {
                flex: 0 0 100px;
                margin-right: 0px;
            }
            @include media-query(1200px) {
                flex: 0 0 150px;
            }
            position: relative;
            
            &:before {
                content: '';
                position: absolute;
                background: #DFDFDF;
                width: 4px;
                height: 98%;
                top: 7px;
                right: -10px;
                @include media-query(992px){
                    height: 92%;
                    right: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}








.new-btn {
    max-width: 386px;
    padding: 14px 20px;
    &.new-btn-modify{
        max-width: 450px;
        margin-top: 30px;
    }
}

.block-chain-pb-mod {
    margin-bottom: 25px;
}

.blue-bnr-section-mod {
    @include media-query(992px){
        padding: 60px 0 70px;
        p {
            margin-bottom: 0;
        }
    }
}
.d-trans-pro-wrapper{
    .d-trans-pro-tab-btn{
        &.d-trans-pro-wrapper-new-modify{
            border: 1px solid transparent;
            &.active {
                border: 1px solid #e3e3e3;
            }
        }
    }
    .d-trans-pro-content-box-border-new{
        border: 1px solid #C6C6C6;
    }
}

.web-dev-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-tab-btn {
            @include media-query(992px){
                font-size: 17px;
                padding: 15px 20px;
            }
        }
    }
}

.service-benifit{
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    padding: 0 0 20px;
    @include media-query(767px){
        padding: 80px 0;
    }
    .margin-bottom-25{
        margin-bottom: 25px;
    }
    &__light{
        background: #fff;
        h2{
            color: #333333;
        }
        .service-card{
            border:1px solid #E1DFE6;
            box-shadow: 5px 10px 20px 0 rgba(0,0,0,0.05);
            height: auto;
            padding: 40px 28px 40px !important;
            .details{
                width: 80%;
                @include media-query(767px){
                    width:76%;
                }
                @include media-query(1024px){
                    width:82% !important;
                }
            }
        }
        .btn{
            text-transform: capitalize;
        }
    }

    &--btnleft{
        margin:15px 0 0;
        font-size: 15px;
        font-weight: 600;
        color: #333;
        &:hover{
            color: #ff5800;
            path{
                fill: #ff5800;
            }
        }
    }

    h2{
        font-weight: 600;
        margin-bottom: 0;
        padding: 40px 40px 30px;
        @include media-query(767px){
            margin-bottom: 50px;
            padding: 0;
        }
    }
    .service-card{
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 50px 34px;
        width: 100%;
        height: 100%;

        .icon{
            width: 16%;
            margin-right: 10px;
            @include media-query(767px){
                width:20%;
            }
            @include media-query(1024px){
                width:15%;
            }
            @include media-query(1200px){
                width: 16%;
                margin-right: 0;
            }
        }
        .service-card-join {
            @include media-query(992px){
                height: 110px;
            }
        }
        .details{
            width: 80%;
            @include media-query(767px){
                width:76%;
            }
            @include media-query(1024px){
                width:80%;
            }
            h4{
                font-size: 20px;
                line-height: 24px;
                color: #333;
                margin-bottom: 15px;
                @include media-query(767px){
                    font-size: 24px;
                    line-height: 36px;
                }
            }
            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                margin: 0;
                @include media-query(767px){
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
    .btn_wrapper{
        margin:0 auto;
        @include media-query(767px){
            margin-top: 35px;
        }
    }
    
    &--django {
        &:before {
            display: none;
        }
        .heading {
            font-size: 30px;
            line-height: 35px;
            color: #262E36;
            font-family: Poppins;
            font-weight: 700;
            margin-bottom: 15px;
            @include media-query(767px) {
                font-size: 38px;
                line-height: 46px;
                margin-bottom: 35px;
            }
            @include media-query(992px){
                font-size: 51px;
                line-height: 65px;
                margin-bottom: 55px;
            }
        }
        .sub-heading {
            font-weight: 500;
            text-align: center;
            @include media-query(992px){
                font-size: 21px;
                line-height: 31px;
                margin-bottom: 30px;
            }
        }
        .custom-heading{
            font-size: 30px;
            line-height: 35px;
            color: #262E36;
            font-family: Poppins;
            font-weight: 700;
            margin-bottom: 15px;
            @include media-query(767px) {
                font-size: 38px;
                line-height: 46px;
                margin-bottom: 35px;
            }
            @include media-query(992px){
                font-size: 48px;
                line-height: 61px;
                margin-bottom: 40px;
            }
        }
        .service-card {
            border-radius: 5px;
            border: 1px solid #DDDDDD;
            padding: 40px !important;
            flex-direction: column;
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);

            .icon {
                width: 78%;
                margin-bottom: 25px;
                @include media-query(767px){
                    width: 66%; 
                }

                img {
                    width: 100%;
                }

                &-md {
                    width: 70%;
                    @include media-query(767px){
                        width: 51%; 
                    }
                }
                &-sm {
                    width: 45%;
                    @include media-query(767px){
                        width: 33%; 
                    }
                }
                &-lg {
                    width: 66%;
                    @include media-query(767px){
                        width: 47%; 
                    }
                }
                &-width{
                    max-width: 135px;
                    width: 100%;
                }
                &-widthm{
                    max-width: 170px;
                    width: 100%;
                }
                &-widthl{
                    max-width: 195px;
                    width: 100%;
                }
            }

            .details {
                width: 100% !important;
            }
        }
    }

    &--mobileapp {
        .service-card {
            .icon {
                img {
                    width: 70px;
                }
                &--large{
                    img{
                        width: 170px;
                    }
                }
                &--low{
                    img{
                        width: 55px;
                    }
                }
            }
        } 
    }

    &--blockchain{
        background: #f5f5f5;
        .service-card{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .icon{
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            img{
                width: auto !important;
            }
        }
        .details{
            width: 100% !important;
            text-align: center;
        }
    }
}
.width-255{
    max-width: 255px;
}
.industry-serve{
    padding: 40px 10px 20px;
    @include media-query(767px){
        padding: 80px 0;
    }
    h2{
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 600;
        @include media-query(1024px){
            font-size: 48px;
            line-height: 58px;
            margin-bottom: 50px;
        }

    }
    .card{
        background: #fff;
        border:1px solid #E1DFE6;
        border-radius: 10px;
        box-shadow: 5px 10px 20px 0px rgba(0,0,0,0.05);
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 25px;
        @include media-query(767px){
            height: 224px;
            padding: 30px;
        }
        i{
            margin-bottom: 10px;
            @include media-query(767px){
                margin-bottom: 20px;
            }
        }
        p{
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            margin:0;
            color: #333;
            text-align: center;
        }
        
    }
    .btn_wrapper{
        margin-top: 0px;
        @include media-query(767px){
            margin-top: 35px;
        }
    }    
}

.service-now-element{
    padding: 40px 0;
    @include media-query(767px){
        padding: 80px 0;
    }

    .process-line{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 15px 30px;
        border-radius: 10px;
        border:1px dashed #626262;
        @include media-query(1024px){
            border:none
        }
        &:before{
            // display: block;
            // content: "";
            // position: absolute;
            // left: 0;
            // width: 100%;
            // height: 100%;
            // border-radius: 15px;
            // right: 0;
            // margin: auto;
            // top: 50%;
            // transform: translateY(-50%);
            // background-image: repeating-linear-gradient(0deg,#626262,#626262 3px,transparent 0,transparent 12px,#626262 0),repeating-linear-gradient(90deg,#626262,#626262 3px,transparent 0,transparent 12px,#626262 0),repeating-linear-gradient(180deg,#626262,#626262 3px,transparent 0,transparent 12px,#626262 0),repeating-linear-gradient(270deg,#626262,#626262 3px,transparent 0,transparent 12px,#626262 0);
            // background-size: 1px 100%,100% 1px,1px 100%,100% 1px;
            // background-position: 0 0,0 0,100% 0,0 100%;
            // background-repeat: no-repeat;
            // @include media-query(1024px){
            //     display: none;
            // }
        }
        @include media-query(1024px){
            padding: 0;
            border: none;
        }
        li{
            position: relative;
            max-width: 100%;
            width: 100%;
            height: auto;
            background: #fff;
            border:1px solid #4492DC;
            border-radius: 10px;
            box-shadow: 5px 10px 20px 0px rgba(0,0,0,0.05);
            display: flex;
            align-items: center;
            text-align: center;
            padding: 15px;
            justify-content: center;
            font-size: 16px;
            line-height: 27px;
            color: #333;
            @include media-query(1024px){
                max-width: 270px;
                width: 22%;
                height: 103px;
            }
            &.ipad-view{
                display: none;
                @include media-query(992px){
                    display: flex;
                }
            }
            &:before{
                display: none;
                position: absolute;
                content: "";
                width: 67px;
                height: 63px;
                background: url(/images/service-now/arrow-line.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                @include media-query(1024px){
                    display: block;
                }
            }
        }
        &.intake-process{
            justify-content: center;
            height: auto;
            margin-bottom: 120px;
            @include media-query(1024px){
                height: 400px;
                margin-bottom: 0;
            }
            li{
                margin:15px 0;
                @include media-query(1024px){
                    margin:0 15px;
                }
                &:nth-child(1){
                    position: relative;
                    left: inherit;
                    bottom: inherit;
                    @include media-query(1024px){
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    &:before{
                        top:0;
                        right: -100px;
                        transform: rotate(290deg);
                    }
                }
                &:nth-child(2){
                    position: relative;
                    left: inherit;
                    top:inherit;
                    @include media-query(1024px){
                        position: absolute;
                        left: 0;
                        top:35%;
                    }
                    &:before{
                        bottom: 0;
                        right: -100px;
                        transform: rotate(342deg);
                    }
                }
                &:nth-child(3){
                    &:before{
                        right: 0;
                        bottom: -80px;
                        left: 60px;
                        margin: auto;
                    }
                }
                &:nth-child(4){
                    &:before{
                        right: 80px;
                        bottom: -80px;
                        left: 0;
                        margin: auto;
                        transform: rotate(269deg);
                    }
                }
                &:nth-child(5){
                    position: relative;
                    right: inherit;
                    top:inherit;
                    @include media-query(1024px){
                        position: absolute;
                        right: 0;
                        top:35%;
                    }
                    &:before{
                        top: 40px;
                        left: -100px;
                        transform: rotate(107deg);
                    }
                }
                &:nth-child(6){
                    position: relative;
                    right: inherit;
                    bottom: inherit;
                    @include media-query(1024px){
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    &:before{
                        bottom: 45%;
                        left: -100px;
                        transform: rotate(160deg);
                    }
                }
            }
        }
        &.outgoing-process{
            justify-content: center;
            margin-top: 120px;
            @include media-query(1024px){
                margin-top: -70px;
            }
            li{
                margin:15px 0;
                @include media-query(1024px){
                    margin:0 15px;
                }
                &:nth-child(1){
                    &:before{
                        top:-80px;
                        right: 0;
                        left: 50px;
                        margin: auto;
                        transform: rotate(90deg);
                    }
                }
                &:nth-child(2){
                    &:before{
                        top:-80px;
                        right: 0;
                        left: -50px;
                        margin: auto;
                    }
                }
            }
        }
    }
    picture{
        position: relative;
        .service-logo{
            position: relative;
            display: block;
            margin: auto;
            top:inherit;
            max-width: 240px;
            @include media-query(1024px){
                top:-34%;
            }
            @include media-query(1200px){
                max-width: 100%;
            }
        }
        &:before{
            display: block;
            position: absolute;
            content: "";
            width: 67px;
            height: 63px;
            background: url(/images/service-now/arrow-line.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            left: 0;
            right: 0;
            top: -90px;
            margin: auto;
            transform: rotate(48deg);
            @include media-query(1024px){
                display: none;
            }
        }
        &:after{
            display: block;
            position: absolute;
            content: "";
            width: 67px;
            height: 63px;
            background: url(/images/service-now/arrow-line.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            left: 0;
            right: 0;
            bottom: -90px;
            margin: auto;
            transform: rotate(48deg);
            @include media-query(1024px){
                display: none;
            }
        }
    }
}

.view-chart-area{
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    padding: 40px 0;
    @include media-query(767px){
        padding: 80px 0;
    }
    .chart-wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        @include media-query(992px){
            flex-direction: row;
        }
        .data-flow{
            width: 100%;
            padding: 20px 8px;
            border:1px dashed #fff;
            border-radius: 10px;
            @include media-query(992px){
                width: 20%;
                padding: 0;
                border:none;
            }
            h2{
                color: #fff;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 15px;
                @include media-query(1181px){
                    font-size: 36px;
                    line-height: 43px;
                    margin-bottom: 30px;
                }
            }
            ul{
                position: relative;
                display: flex;
                min-height: auto;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;
                @include media-query(992px){
                    min-height: 475px;
                    display: inline-flex;
                    flex-direction: column;
                }
                li{
                    position: relative;
                    max-width: 100%;
                    width: 100%;
                    height: 79px;
                    display: block;
                    border: 1px solid;
                    box-shadow: 5px 10px 20px 0 rgba(0,0,0,0.05);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 12px;
                    color: #fff;
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 20px;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-weight: 600;
                    @include media-query(767px){
                        max-width: 170px;
                    }
                    @include media-query(992px){
                        width: 70%;
                        margin:0;
                        margin-bottom: 20px;
                    }
                    @include media-query(1181px){
                        width: 100%;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &:after{
                        display: none;
                        position: absolute;
                        content: "";
                        width: 37px;
                        height: 11px;
                        background: url(/images/snowflake/line.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        @include media-query(1024px){
                            display: block;
                        }
                    }
                }
            }
            &__input{
                text-align: center;
                position: relative;
                margin-bottom: 100px;
                &:after{
                    display: block;
                    position: absolute;
                    content: "";
                    width: 37px;
                    height: 11px;
                    background: url(/images/snowflake/line.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    transform: rotate(90deg);
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: -50px;
                    @include media-query(992px){
                        display: none;
                    }
                }
                @include media-query(992px){
                    text-align: left;
                    margin-bottom: 0;
                }
                li{
                    border-color: #82FFA9 !important;
                    &:after{
                        right: -40%;
                        top:50%;
                        transform: translateY(-50%);
                        @include media-query(992px){
                            right: -40%;
                        }
                        @include media-query(1200px){
                            right: -30%;
                        }
                    }
                }
            }
            &__output{
                text-align: center;
                position: relative;
                &:after{
                    display: block;
                    position: absolute;
                    content: "";
                    width: 37px;
                    height: 11px;
                    background: url(/images/snowflake/line.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    transform: rotate(90deg);
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: -50px;
                    @include media-query(992px){
                        display: none;
                    }
                }
                @include media-query(992px){
                    text-align: right;
                    margin-top:0;
                }
                ul{
                    align-items: flex-end;
                    @include media-query(1200px){

                    }
                    li{
                        border-color: #4492DC !important;
                        &:after{
                            left: -40%;
                            top:50%;
                            transform: translateY(-50%);
                            @include media-query(992px){
                                right: -40%;
                            }
                            @include media-query(1200px){
                                right: -30%;
                            }
                        }
                    } 
                }
            }
        }
        .stramtext{
            position: relative;
            width: 100%;
            @include media-query(992px){
                width: 10%;
            }
            &:after{
                display: none;
                position: absolute;
                content: "";
                width: 37px;
                height: 111px;
                background: url(/images/snowflake/line-group.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                top: 45%;
                right: 0;
                @include media-query(1024px){
                    display: block;
                }
            }
            h4{
                position: relative;
                color: #fff;
                font-size: 24px;
                font-weight: 600;
                line-height: 59px;
                background-color: rgba(0,0,0,.1);
                border-radius: 30px;
                text-align: center;
                padding: 0 20px;
                &:after{
                    display: block;
                    position: absolute;
                    content: "";
                    width: 37px;
                    height: 11px;
                    background: url(/images/snowflake/line.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    transform: rotate(90deg);
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: -50px;
                    @include media-query(992px){
                        display: none;
                    }
                }
                @include media-query(992px){
                    transform: rotate(270deg);
                    margin: 0;
                    position: absolute;
                    width: 256px;
                    left: -120px;
                    right: 0;
                    text-align: center;
                    margin: auto;
                    top: 49%;
                }
                @include media-query(1200px){
                    left: -97px;
                }
            }
        }
        .showflake-main{
            width: 100%;
            position: relative;
            margin-bottom: 100px;
            @include media-query(992px){
                width: 45%;
                margin-bottom: 0;
            }
            @include media-query(1024px){
                height: 567px;
                overflow: hidden;
            }
            @include media-query(1181px){
                height: auto;
            }
            .snow-block{
                background: rgba(255,255,255,0.05);
                border-radius: 30px;
                padding: 20px;
                @include media-query(767px){
                    padding: 40px;
                }
                &.showfirst{
                    padding: 20px;
                    margin-top: 80px;
                    margin-bottom: 22px;
                    @include media-query(767px){
                        padding: 40px 40px 30px;
                    }
                    @include media-query(992px){
                        margin-top: 20px;
                    }
                    @include media-query(1181px){
                        margin-top: 80px;
                    }
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        @include media-query(992px){
                            justify-content: space-between;
                        }
                        li{
                            max-width: 45%;
                            width: 100%;
                            height: auto;
                            border-radius: 10px;
                            background: #fff;
                            padding: 10px;
                            box-shadow: 5px 10px 20px 0 rgba(0,0,0,0.05);
                            margin-bottom: 10px;
                            margin-left: 5px;
                            margin-right: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            @include media-query(767px){
                                max-width: 133px;
                                width: 32%;
                                height: 113px;
                                margin-left: 10px;
                                margin-right: 10px;
                                margin-bottom: 20px;
                            }
                            @include media-query(992px){
                                margin-left: 0;
                                margin-right: 0;
                            }
                            p{
                                font-size: 15px;
                                line-height: 21px;
                                font-weight: 500;
                                color: #333;
                                margin: 10px 0 0;
                                text-align: center;
                            }
                        }
                    }
                    .tag-logo{
                        display: block;
                        margin: 16px auto 0;
                        text-align: center;
                        width: 70%;
                        height: auto;
                        @include media-query(1200px){
                            width: auto;
                            height: 70px;
                            overflow: hidden;
                        }
                    }
                }
                &.showsecond{
                    ul{
                        text-align: center;
                        height: auto;
                        @include media-query(1200px){
                            height: 37px;
                            overflow: hidden;
                        }
                        li{
                            display: inline-block;
                            width: 35%;
                            margin:0 7px;
                            @include media-query(767px){
                                width: 29%;
                                margin:0 5px;
                            }
                            @include media-query(1200px){
                                width: auto;
                                margin: 0 15px;
                            }
                            &:nth-child(2){
                                width: 14%;
                                @include media-query(767px){
                                    width: 29%;
                                }
                                @include media-query(1200px){
                                    width: auto;
                                    margin: 0 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.snowflake-process{
    .d-trans-pro-content-box{
        box-shadow: none !important;
        background: #f7f8fa !important;
    }
    .d-trans-pro-steps{
        &:before{
            @include media-query(992px){
                height: 84% !important;
            }
        }
    }
}

.service-benifit-ecom {
    h2.heading {
        margin-bottom: 20px;
    }
    p {
        font-family: "Poppins";
        color: #333;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
        @include media-query(992px){
            font-size: 21px;
            line-height: 31px;
            margin-bottom: 30px;
        }
    }
}
//EXCELLENCE MEETS
.excellence-meets{
  $excellence-meet : &;
  position: relative;
  // border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  padding: 25px 0 0;
  @include media-query(992px) {
    padding: 0;
  }
  &--toppadding{
    padding-top: 30px;
    @include media-query(992px) {
      padding-top: 60px;
    }
  }
  &__tittle{
    font-family: 'Poppins';
    font-size: 22px;
    color: $black-color;
    line-height: 30px;
    margin-bottom: 25px;
    font-weight: 700;
    text-align: center;
    position: relative;
    @include media-query(992px) {
      font-size: 22px;
      line-height: 30px;
      text-align: left;
      margin-bottom: 16px;
      padding-bottom: 15px;
    }
    &:after {
      content: "";
      width: 52px;
      height: 3px;
      position: absolute;
      background-color: #3CC065;
      position: absolute;
      left: 0;
      bottom: 0;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  &__subtittle{
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
    color: $black-color;
    margin-bottom: 25px;
    font-weight: 500;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__clientlists-mb {
    margin-bottom: 25px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
    .owl-dots {
      top: 30px;
      height: 73px;
    }
  }
  &__clientlist{
    display: inherit;
    @include media-query(768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include media-query(992px) {
      justify-content: flex-start;
      // gap: 20px 20px;
    }
    .owl-stage{
      display: flex;
      align-items: center;
    }
  }
  &__clientitem{
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(992px) {
      flex: 0 0 47%;
      display: flex;
      justify-content: flex-start;
      margin:0 5px 20px !important;
    }
  }
  .owl-stage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 10px;
    @include media-query(992px) {
      gap: 0;
    }
}
  &__clienttext{
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 26px;
    color: $black-color;
    font-weight: 500;
    margin-top: 0;
    display: block;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    top: -20px;
    @include media-query(770px) {
      top: inherit;
    }
    @include media-query(992px) {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0;
      text-align: left;
      position: static;
      margin-top: 20px;
      margin-bottom: 30px;
      top: -55px;
    }
  }
  &__meetsarea{
    position: relative;
    background-color: #355BBF;
    width: 100%;
    height: 100%;
    padding: 45px 20px 25px;
    @include media-query(992px) {
      padding: 40px 0 40px 40px;
    }
    &:before{
      position: absolute;
      content: "";
      width: 1000%;
      height: 100%;
      background-color: #355BBF;
      right: -1000%;
      top: 0;
      bottom: 0;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
    &:after{
      position: absolute;
      content: "";
      width: 1000%;
      height: 2px;
      background-color: #355BBF;
      bottom: -1px;
      left: 0;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
    .splide__pagination{
      bottom: -15px !important;
    }
    .owl-stage-outer {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: -16px;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background: #5B81E1;
      }
    }
    .owl-dots {
      top: 10px;
      height: 73px;
    }
  }
  .splide__pagination {
    position: absolute;
    bottom: -55px;
  }
  .splide__pagination__page {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #e1e1e1;
  &.is-active {
      width: 13px;
      height: 13px;
      background: #ff5800;
      transform: scale(1);
  }
}

&__meetslist {
  .splide__track {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: -16px;
      margin: 0 auto;
      width: 1px;
      height: 100%;
      background: #5B81E1;
    }
  }
}
  &__meetstittle{
    font-family: 'Poppins';
    font-size: 34px;
    line-height: 36px;
    color: #84A5F9;
    font-weight: 900;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 60px;
      line-height: 66px;
    }
    svg{
      margin-top: -33px;
      width: 40px;
      position: relative;
      top: 2px;
      right: -6px;
      @include media-query(992px) {
        width: inherit;
        position: static;
      }
    }
  }
  &__meetslist{
    display: flex;
    flex-wrap: wrap;
    .splide__pagination{
      margin-top: 15px;
    }
    .splide__pagination__page{
      background: #9b9b9b;
    }
  }
  &__meetsitem{
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    @include media-query(770px) {
      width: 45%;
      margin: 10px;
      justify-content: flex-start;
    }
    @include media-query(992px) {
      width: 33.33%;
      padding-bottom: 30px;
      align-items: flex-start;
      flex-direction: row;
      border-right: 1px solid #5B81E1;
      border-bottom: 1px solid #5B81E1;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0;
    }
    &:after{
      display: none;
      @include media-query(992px) {
        display: block;
        position: absolute;
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #355BBF;
        bottom: -7.5px;
        right: -7.5px;
        z-index: 1;
      }
    }
    
    &:nth-child(2n+2){
      border-right: none;
      &:after{
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
      @include media-query(992px) {
        border-right: 1px solid #5B81E1;
      }
    }
    &:nth-child(3n+3){
      @include media-query(992px) {
        border-right: none;
        padding-left: 10px;
        &:after{
          display: none;
        }
      }
    }
    &:nth-last-child(-n+3){
      @include media-query(992px) {
        border-bottom: none;
        // padding-top: 30px;
        padding-bottom: 5px;
        &:after{
          display: none;
        }
      }
    }
    &:nth-last-child(-n+2){
      border-bottom: none;
      @include media-query(992px) {
        padding-bottom: 5px;
      }
    }
    &:nth-child(2){
      @include media-query(992px) {
        // justify-content: center;
      }
      .excellence-meets__listcontent{
        // max-width: 100px;
      }
    }
    &:nth-child(5){
      justify-content: center;
      @include media-query(770px) {
        justify-content: flex-start;
      }
      @include media-query(992px) {
        justify-content: center;
      }
      .excellence-meets__listcontent{
        // margin: 0;
        // max-width: 120px;
      }
    }
    img {
      margin-right: 10px;
      @include media-query(992px) {
        margin-right: 0;
      }
    }
  }
  &__listcontent{
    margin-top: 5px;
    margin-left: 0;
    max-width: 100%;
    text-align: left;
    @include media-query(992px) {
      margin-top: 0;
      margin-left: 15px;
      max-width: 162px;
    }
  }
  &__contenttittle{
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 26px;
    }
  }
  &__contenttext{
    font-family: 'Poppins';
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin:0;
  }
  &__officetittle{
    margin: 30px 0 15px;
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #84A5F9;
    text-align: center;
    @include media-query(992px) {
      margin: 28px 0 15px;
      text-align: left;
    }
  }
  &__officelist{
    width: 100%;
    display: flex;
    padding: 6px 8px;
    border-radius: 5px;
    border:1px solid #84A5F9;
  }
  &__officeitem{
    // flex: 0 0 169.5px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
  }
  &__officeitems{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,0.1);
    }
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:hover{
      &:before{
        background-color: rgba(0,0,0,0.3);
      }
      &:after{
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background: url(/images/two-for-one/pop-up-icon.svg) no-repeat center;
        background-size: 100%;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
      }
    }
  }
  .office-wrapper {
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #84a5f9;
  }
  &__officebuttonarea{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__officebtn{
    font-family: 'Poppins';
    margin: 0 0 15px 0;
    max-width: 100%;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(992px) {
      max-width: 215px;
      margin: 0 13px 0 0;
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      g{
        fill: #ff5800;
      }
    }
  }
  &__takebtn{
    margin:0;
    max-width: 100%;
    width: 100%;
    border:2px solid $white-color;
    color: $white-color;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(992px) {
      max-width: 307px;
    }
    img{
      max-width: 20px;
      margin-right: 10px;
      @include media-query(992px) {
        max-width: 30px;
        margin-right: 15px;
      }
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      background-color: $white-color;
      color: #ff5800;
      g{
        fill: #ff5800;
      }
    }
  }
  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 25px;
    position: relative;
    gap: 0 10px;
    height: 45px;
    margin: 0;
    @include media-query(992px) {
      padding: 30px 0;
      top: 35px;
      height: 73px;
    }
    .owl-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #E1E1E1;
      &.active {
        width: 13px;
        height: 13px;
        background: #FF5800;
      }
    }
  }
  &__asterisktext{
    color: #fff;
    margin: 5px 0 0;
    line-height: normal;
  }
}

.slbOverlay {
  z-index: 999999 !important;
  opacity: 0.9 !important;
}
.slbWrapOuter {
  z-index: 999999 !important;
  }

.home-excellence{
  @include media-query(767px) {
    border-top: 1px solid #cbcbcb;
  }
  .col-lg-4{
    @include media-query(767px) {
      padding-top: 30px;
    }
  }
}
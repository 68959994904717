//NEW BANNER AREA
.new-banner-area{
  $new-banner-area : &;

  position: relative;
  background: #081C36;
  width: 100%;
  overflow: hidden;
  @include media-query(992px) {
    overflow: visible;
  }
  &:before{
    position: absolute;
    content: "";
    width: 410px;
    height: 245px;
    background: url(/images/two-for-one/banner-top-gradient.png) no-repeat top left;
    background-size: 100%;
    top: 65px;
    left: 0;
  }
  &:after{
    position: absolute;
    content: "";
    width: 500px;
    height: 160px;
    background: url(/images/two-for-one/banner-bottom-blue.svg) no-repeat bottom center;
    background-size: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
  }
  &__spacing{
    padding: 115px 20px 0;
  }
  &__lefttop-area{
    margin-top: 35px;
  }
  &__contentarea{
    max-width: 554px;
    @include media-query(992px) {
      padding-top: 40px;
    }
  }
  &__subheading{
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: #84A5F9;
    margin-bottom: 35px;
    width: 50%;
    @include media-query(370px) {
      width: 60%;
    }
    @include media-query(992px) {
      font-size: 40px;
      line-height: 46px;
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &__heading{
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    color: $white-color;
    margin-bottom: 15px;
    max-width: 400px;
    width: 55%;
    @include media-query(992px) {
      font-size: 38px;
      line-height: 51px;
      margin-bottom: 12px;
      width: 100%;
    }
  }
  &__headingbold{
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #3CC065;
    margin-bottom: 15px;
    max-width: 389px;
    @include media-query(992px) {
      font-size: 27px;
      line-height: 36px;
      margin-bottom: 26px;
    }
  }
  &__headingcontent{
    font-family: 'Poppins';
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    color: $white-color;
    @include media-query(992px) {
      font-size: 23px;
      line-height: 33px;
    }
  }
  &__twologo{
    max-width: 170px;
    position: absolute;
    right: -20px;
    top: -55px;
    @include media-query(420px) {
      max-width: 200px;
    }
    @include media-query(992px) {
      right: -35px;
      top: 50px;
    }
    @include media-query(1180px) {
      max-width: 320px;
      right: -60px;
      top: 0;
    }
  }
  &__formarea{
    position: relative;
    text-align: center;
    background: none !important;
    top: 7px;
    @include media-query(560px) {
      top: inherit;
    }
    @include media-query(1025px) {
      text-align: right;
    }
    &:before{
      position: absolute;
      content: "";
      width: 750px;
      height: 528px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: url(/images/two-for-one/new-banner-form-before.png) no-repeat bottom center;
      background-size: 100%;
      @include media-query(1024px) {
        left: -35%;
        right: inherit;
      }
    }
  }
  &__formpart{
    position: relative;
    max-width: 460px;
    text-align: left;
    background-color: $white-color;
    padding: 30px 20px;
    display: inline-block;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
    z-index: 1111;
    @include media-query(992px) {
      padding: 35px 27px;
    }
    &:before{
      content: '';
      position: absolute;
      background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
      height: 5px;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  &__formwrapperaction{
    background: none !important;
    padding: 0;
    #django-o-contact-form1{
      padding: 0;
      display: block;
      .new-bottom-form__btn-wrapper{
        margin: 0 !important;
        display: flex !important;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
      }
    }
    .new-bottom-form__wrapper{
      position: relative;
      max-width: 100%;
      text-align: left;
      background-color: #fff;
      display: inline-block;
    }
    .attach{
      margin: 5px 0 20px !important;
      background-position: 0 9px;
      padding: 0 0 0 35px;
      .o-contact-form__attachement-text{
        color: $black-color !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        label{
          color: #3cc065 !important;
          font-weight: 500 !important;
        }
      }
    }
    .new-banner-area__confidential{
      margin: 30px 0 0 !important;
      text-align: center;
      width: 100%;
    }
    .confidential-text-wrap ul{
      justify-content: center !important;
      align-items: center;
      li{
        width: auto;
        padding: 0 10px 4px !important;
      }
    } 
  }
  &__formtittle{
    font-family: 'Poppins';
    font-size: 30px;
    line-height: 30px;
    color: $black-color;
    font-weight: 700;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  &__forminput{
    font-family: 'Poppins' !important;
    width: 100%;
    border:1px solid #DBDBDB !important;
    color: $black-color !important;
    line-height: 52px !important;
    font-size: 14px !important;
    margin-bottom: 12px !important;
    padding: 0 19px !important;
    font-weight: 400 !important;
    @include media-query(992px) {
      font-size: 15px !important;
    }
    &::-webkit-input-placeholder {
      font-family: 'Poppins' !important;
      color: $black-color !important;
    }
    &::-moz-placeholder {
      font-family: 'Poppins' !important;
      color: $black-color !important;
    }
    &:-ms-input-placeholder {
      font-family: 'Poppins' !important;
      color: $black-color !important;
    }
    &:-moz-placeholder {
      font-family: 'Poppins' !important;
      color: $black-color !important;
    }
    &--textarea{
      resize: none !important;
      height: 120px !important;
      padding: 0 19px !important;
      &::-webkit-input-placeholder {
        font-family: 'Poppins' !important;
        color: $black-color !important;
      }
      &::-moz-placeholder {
        font-family: 'Poppins' !important;
        color: $black-color !important;
      }
      &:-ms-input-placeholder {
        font-family: 'Poppins' !important;
        color: $black-color !important;
      }
      &:-moz-placeholder {
        font-family: 'Poppins' !important;
        color: $black-color !important;
      }
    }
  }
  &__confidential{
    
  }
  &__confidential-list{
    font-family: 'Poppins';
    color: $black-color !important;
    font-size: 14px !important;
    padding: 0 7px !important;
    line-height: 15px !important;
    @include media-query(992px) {
      font-size: 16px !important;
      line-height: 17px !important;
    }
    &:after{
      background-color:#E1E1E1 !important;
    }
  }
  &__btn{
    font-family: 'Poppins' !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 17px 25px !important;
    text-transform: capitalize !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    border-radius: 5px;
    @include media-query(767px) {
      max-width: 255px !important;
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      g{
        fill: #ff5800;
      }
    }
  }
}
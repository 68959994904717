@use "sass:map";

$values: (
  15: 15px,
  18: 18px,
  21: 21px,
  36: 36px,
  65: 65px,
);

$font-families: (
  poppins: "Poppins",
);

.padding-top-100 {
  padding-top: 80px;
}
.font-family-pro {
  font-family: $main-font;
}
.padding-top-40 {
  padding-top: 40px;
}
.padding-80 {
  padding-top: 40px;
}
.padding-bottom-30 {
  padding-bottom: 30px;
}
.white {
  color: #fff !important;
}
h1 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  @include responsive(desktop) {
    font-size: 62px;
    line-height: 75px;
  }
}
h2 {
  font-size: 24px;
  line-height: 32px;
  font-family: $main-font;
  font-weight: 700;
  @include responsive(desktop) {
    font-size: 48px;
    line-height: 58px;
  }
}
.cmn-heading-para {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  text-align: center;
  font-family: $main-font;
  @include responsive(desktop) {
    font-size: 20px;
    line-height: 32px;
    font-family: $main-font;
  }
}
.font-weight-600 {
  font-weight: 600;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.cmn-bottom-border {
  border-bottom: 1px solid #dedede;
}
.cmn-top-border {
  border-top: 1px solid #dedede;
}
.mobile-off {
  display: none;
  @include responsive(desktop) {
    display: block;
  }
}
.bg-grey {
  background-color: #f5f5f5 !important;
}
.font-size-30 {
  font-size: 28px;
}
.btn-center-block {
  margin: 0px auto;
  width: 310px;
  text-transform: uppercase;
  @include responsive(desktop) {
    width: inherit;
  }
}
p {
  margin: 0px 0px 25px 0px;
  padding: 0px 0px 0px 0px;
  line-height: 24px;
  .anchor-link {
    font-size: 13px;
    line-height: 19px;
    color: #cbe3ff;
    text-transform: none;
    // margin-top: 0;
    @include responsive(desktop) {
      max-width: 340px;
      margin-top: 10px;
    }
  }
}
.o-home-service {
  .anchor-link {
    margin-top: 0;
    @include responsive(desktop) {
      margin-top: 15px;
    }
  }
  .n-gradient-green-blue {
    background: linear-gradient(
      172deg,
      rgba(3, 119, 115, 1) 7%,
      rgba(0, 81, 127, 1) 42%,
      rgba(2, 62, 102, 1) 67%,
      rgba(5, 27, 57, 1) 100%
    );
    @include responsive(desktop) {
      background: linear-gradient(350deg, #037c71 0, #042b4e 100%);
    }
  }
}
.wb-bnr {
  background: #165b5e;
  background: -moz-linear-gradient(
    138deg,
    #165b5e 0%,
    #0f3a4a 17%,
    #091b38 50%
  );
  background: -webkit-linear-gradient(
    138deg,
    #165b5e 0%,
    #0f3a4a 17%,
    #091b38 50%
  );
  background: linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#165b5e",endColorstr="#091b38",GradientType=1);
}
.o-skills-hero {
  padding-bottom: 15px;
  padding-top: 102px;
  @include responsive(desktop) {
    padding-top: 100px;
  }
  position: relative;
  background: linear-gradient(138deg, #165b5e 0%, #0f3a4a 17%, #091b38 50%);
  // @include responsive(desktop){
  //     padding-top: 100px;
  // }
  br {
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
  .container {
    position: relative;
    z-index: 9;
  }
}
.wb-bnr {
  padding-top: 60px;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.small-h1 {
  text-align: center;
  h1 {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    // font-weight: 700;
    margin-top: 15px;
    @include responsive(desktop) {
      padding: 0 80px;
      font-size: 36px;
      line-height: 51px;
      margin-top: 0;
    }
  }
  h1.padding-0 {
    padding: 0;
  }
  h2 {
    font-size: 22px;
    color: #14e07e;
    // color: #92b3ff;
    margin-top: 25px;
    font-weight: 900;
    font-family: $main-font;
    padding-bottom: 0;
    @include responsive(desktop) {
      font-size: 26px;
      margin-top: 25px;
      font-weight: 700;
      margin-bottom: 0;
      padding: 0 0 0;
    }
  }
  .bnr-ofr-text {
    // color: #14E07E;
    color: #b7b7b7;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: bold;
    font-family: $main-font;
    @include responsive(desktop) {
      padding: 0 280px;
      line-height: 36px;
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
    }
    a {
      text-decoration: underline;
      // color: #14E07E;
      color: #b7b7b7;
    }
    br {
      display: none;
      @include responsive(desktop) {
        display: block;
      }
    }
  }
}
.o-skills-hero__schedule {
  font-family: $main-font;
  position: relative;
  .container {
    position: relative;
    z-index: 9;
  }

  .header_right_pan {
    // @include prefixed-border-radius(10px);
    background-color: #1d2942a3;
    padding: 6px;
    margin-top: 20px;

    .regular.slider {
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .holder {
      // @include prefixed-border-radius(6px);
      padding: 40px 50px 15px 50px;
    }
    .sub-text {
      font-size: 19px;
      // border-bottom:2px dotted $green;
      padding: 0 40px 20px;
    }
    ul {
      li {
        margin: 0 6px;
        width: 19%;
        background-color: $white-color;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $white-color;
        img {
          width: 100%;
        }
      }
    }
    .anchor-link {
      &:hover {
        color: $white-color;
      }
    }
  }
}
.bnr-ofr-text {
  // padding: 0 15px;
  // @include responsive(desktop){
  //     padding: 0;
  // }
  br {
    display: block;
  }
}
.gray-text {
  color: #b7b7b7 !important;
}
.green-head-ribbon {
  position: relative;
  span {
    font-family: $main-font !important;
    font-size: 20px;
    padding: 7px 35px;
    line-height: 24px;
    margin: 0 0 26px;
    width: auto;
    @include responsive(desktop) {
      padding: 0 30px;
      margin: 0 0 40px;
      font-size: 28px;
      line-height: 54px;
      font-family: $main-font !important;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -18px;
      left: 0;
      border-color: transparent transparent transparent #148d52;
      width: 0;
      height: 0;
      border-style: dashed solid dashed solid;
      border-width: 0 0 18px 40px;
      transform: rotate(-180deg);
    }
  }
}
.o-services-hero__title {
  span {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: auto;
      right: 0;
      border-color: transparent transparent transparent #148d52;
      width: 0;
      height: 0;
      border-style: dashed solid dashed solid;
      border-width: 0 0 18px 40px;
    }
  }
}
.service-details {
  @include responsive(desktop) {
    padding: 80px 0;
  }
  h2 {
    margin-bottom: 5px;
    // font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 25px;
    }
  }
  .cmn-heading-para {
    display: none;
    @include responsive(desktop) {
      display: block;
      font-size: 20px;
      line-height: 32px;
      color: #333;
      max-width: 1024px;
      margin: 0px auto;
      margin-bottom: 0;
      font-weight: 400;
      font-family: $main-font;
    }
  }
  h3 {
    font-size: 20px;
    text-align: center;
    @include responsive(desktop) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  p {
    font-size: 16px !important;
    line-height: inherit !important;
    text-align: center;
    @include responsive(desktop) {
      font-size: 18px !important;
      line-height: 28px !important;
    }
  }
  .service-wrap {
    .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 10px;
      position: relative;
      @include responsive(desktop) {
        padding: 50px 30px 50px;
      }
      a {
        @include responsive(desktop) {
          width: 134px;
          height: 46px;
          line-height: 42px;
          border: 2px solid #333333;
          border-radius: 5px;
          font-size: 18px;
          color: #333;
          text-decoration: none;
          font-weight: 400;
          transition: 0.3s all ease-in;
          display: block;
          background: transparent;
          padding: 0;
          text-transform: capitalize;
          &:after {
            display: none;
          }
        }
        &:hover {
          border-color: #ff5800;
          color: #ff5800;
        }
      }
    }
    &:before {
      transform: translate(0);
    }
  }
}
/*services section*/
.web-app-service {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  @include responsive(desktop) {
    padding-top: 65px;
    padding-bottom: 45px;
  }
  .service-wrap {
    text-align: center;
    margin-bottom: 35px;
    .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 10px;
      position: relative;

      @include media-query(992px) {
        // height: 569px;
        width: 46%;
      }
      @include media-query(1200px) {
        width: 30%;
        padding: 50px 30px 30px;
      }

      // @include media-query(991px) {
      //     width: 46%;
      // }
      &:before {
        // content: "";
        // background: url(../images/web-application/services-group-icon.png) no-repeat;
        // width: 61px;
        // height: 56px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 9%;
        // // display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     display: block;
        // }
        display: none;
      }
      h3 {
        font-size: 20px;
        position: relative;
        // padding-bottom: 10px;
        @include responsive(desktop) {
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 0;
        }
        // &:before {
        //     display: block;
        //     content: '';
        //     background: #14e07e;
        //     width: 60px;
        //     height: 3px;
        //     margin: 0 auto;
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     @include responsive(desktop){
        //         display: none;
        //     }
        // }
      }
      p {
        font-size: 16px;
        line-height: 23px;
        @include media-query(992px) {
          font-size: 17px;
          line-height: 28px;
        }
        @include media-query(1280px) {
          font-size: 18px;
          line-height: 30px;
        }
      }
      a {
        // display: none;
        margin-bottom: 30px;
        display: block;
        @include responsive(desktop) {
          margin-top: auto;
          margin-bottom: 0;
        }
      }
      i {
        display: block;
        height: 55px;
        img {
          height: 100%;
        }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -62px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -123px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: -183px 0;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: 0 -57px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -62px -55px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: -123px -57px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -183px -57px;
      }
    }
  }
}
.lighter-blue {
  color: #4a90e2 !important;
}
.o-industrial-experience {
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  // h2 {
  //     font-weight: 700;
  // }
}
.o-case-studies-slider {
  h2 {
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 20px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 30px;
    }
  }
  .row {
    margin: 0;
    @include responsive(desktop) {
      margin: 0 -15px;
    }
  }
}
.experience-list {
  padding: 0 15px;
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: $main-font;
  }
  ul {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      flex: 0 0 43%;
      margin: 10px 10px;
      background: #fff;
      border-radius: 5px;
      vertical-align: top;
      border: 1px solid #ededed;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
      @include responsive(desktop) {
        flex: 0 0 30%;
        border: 1px solid #c8c7cc;
      }
      a {
        @include responsive(desktop) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 30px 15px;
          position: relative;
          height: 115px;
        }
        &:hover {
          box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
        }
        &:before {
          content: "";
          width: 23px;
          height: 23px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translate(0, -50%);
          border: 1px solid #c8c7cc;
          border-radius: 50%;
          background: url(/images/common/arrow-black.png) no-repeat 50% 50%;
          display: none;
          @include responsive(desktop) {
            display: block;
          }
        }
      }
    }
  }
  .new-stack {
    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 15px;
        height: auto;
        position: relative;
        width: auto;
        height: 100%;
        @include responsive(desktop) {
          align-items: center;
        }
        .php-logo {
          width: 48px;
          @include responsive(desktop) {
            width: inherit;
          }
        }
        &:after {
          content: "";
          position: absolute;
          background: url(/images/mobile-all/circle-arrow.png) no-repeat 0 0;
          width: 21px;
          height: 21px;
          right: 0;
          left: 0;
          margin: 0 auto;
          bottom: 8px;
          display: none;
        }
      }
    }
  }
}
.stack-section {
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    font-size: 30px;
    line-height: 43px;
    position: relative;
    padding-bottom: 15px;
    // font-weight: 700;
    @include responsive(desktop) {
      padding-bottom: 30px;
    }
    &:before {
      content: "";
      background: #49c684;
      height: 2px;
      width: 50px;
      bottom: 15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      display: none;
      @include responsive(desktop) {
        display: none;
      }
    }
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 10px;
    }
  }
}
.price-wrap {
  @include responsive(desktop) {
    display: flex;
  }
}
.o-engagement-model {
  padding-top: 40px;
  padding-bottom: 45px;
  background: linear-gradient(
    166deg,
    rgba(0, 68, 117, 1) 20%,
    rgba(11, 138, 138, 1) 69%,
    rgba(8, 150, 126, 1) 100%
  );
  @include responsive(desktop) {
    padding: 70px 0 40px;
    background: linear-gradient(
      285deg,
      #07b260 0,
      #00517f 20%,
      #023e66 75%,
      #051b39 100%
    );
  }
  h2 {
    color: #fff;
    margin: 0 0 25px;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin: 0 0 40px;
    }
  }
  .o-engagement-model__panel--leftnew {
    width: 100%;
    position: relative;
    @include responsive(desktop) {
      margin-left: 10px;
    }
    .most-popular {
      position: absolute;
      @include responsive(desktop) {
        height: 151px;
        width: 152px;
        top: -10px;
      }
      height: 125px;
      width: 120px;
      top: -9px;
      left: -10px;
      display: block;
      // background: url(../images/common/most-popular-green.png) no-repeat 0 0;
      background-size: cover;
    }
    .o-engagement-model__cards {
      // padding: 100px 15px 15px;
      padding: 25px 15px 15px;
      background: #fff;
      margin-bottom: 30px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      @include media-query(992px) {
        padding: 30px 30px 30px;
        min-height: 392px;
      }
      @include media-query(1200px) {
        min-height: 368px;
      }
    }
    .price-text {
      text-align: center;
      position: relative;
      padding-top: 10px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500;
      span {
        font-size: 32px;
        color: #ff5800;
        display: block;
        text-transform: none;
        padding-top: 15px;
        font-weight: 700;
        @include responsive(desktop) {
          font-size: 48px;
          font-weight: 600;
          padding-top: 25px;
        }
        strong {
          font-size: 20px;
          text-transform: capitalize;
          @include responsive(desktop) {
            font-size: 26px;
          }
        }
      }
    }
    .font-family-mot {
      font-size: 16px;
      line-height: 23px;
      font-family: $main-font;
      padding: 0 30px;
      font-weight: 500;
      @include responsive(desktop) {
        font-size: 18px;
        margin-top: 35px;
        padding: 0;
      }
    }
  }
  .o-engagement-model__panel--rightnew {
    width: 100%;
    @include responsive(desktop) {
      margin-right: 10px;
    }
    .o-engagement-model__cards {
      // padding: 100px 15px 15px;
      padding: 25px 15px 15px;
      background: #fff;
      margin-bottom: 30px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      @include media-query(992px) {
        padding: 30px 30px 30px;
        min-height: 392px;
      }
      @include media-query(1200px) {
        min-height: 368px;
      }
    }
    .price-text {
      text-align: center;
      position: relative;
      padding-top: 10px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500;
      span {
        font-size: 32px;
        color: #ff5800;
        // color: #084975;
        display: block;
        text-transform: none;
        padding-top: 15px;
        font-weight: 700;
        @include responsive(desktop) {
          font-size: 48px;
          padding-top: 25px;
          font-weight: 600;
        }
        strong {
          font-size: 20px;
          text-transform: capitalize;
          @include responsive(desktop) {
            font-size: 26px;
          }
        }
      }
    }
    .font-family-mot {
      font-size: 16px;
      line-height: 23px;
      font-family: $main-font;
      padding: 0 30px;
      font-weight: 500;
      @include responsive(desktop) {
        font-size: 18px;
        margin-top: 30px;
        padding: 0;
      }
    }
  }
  .btn_wrapper {
    margin-top: 35px;
    @include responsive(desktop) {
      margin-top: 15px;
    }
  }
}

/*pricing section*/
.no-webp {
  .o-engagement-model__cards__top:before {
    // background-image: url(../images/common/common-sprite.png);
  }
}
.webp {
  .o-engagement-model__cards__top:before {
    // background-image: url(../images/webp/common/common-sprite.webp);
  }
}
.o-engagement-model__cards__top {
  position: relative;
  text-align: center;
  padding-top: 15px;
  @include responsive(desktop) {
    padding-top: 10px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 52px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -60px;
    z-index: 0;
    // display: none;
    // @include responsive(desktop){
    //     display: block;
    // }
  }
  &__inner {
    h3 {
      font-size: 20px;
      line-height: 24px;
      @include media-query(992px) {
        font-size: 26px;
      }
      @include media-query(1200px) {
        margin: 25px 0 35px 0;
        font-size: 32px;
      }
    }
  }
}
.o-engagement-model__cards__01 {
  &:before {
    background-position: -5px -200px;
  }
}
.o-engagement-model__cards__02 {
  &:before {
    background-position: -63px -200px;
  }
}
.o-engagement-model__cards__bottom {
  margin-bottom: 25px;
}

/*why section*/
.c-icon-desc-3-col-box {
  position: relative;
  text-align: center;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
    padding-bottom: 0;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 30px;
    }
  }
}
.c-icon-desc-3-col-ccn .row > div {
  padding: 0 15px 0 90px;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
  @include responsive(desktop) {
    padding: 0 40px 0 95px;
    margin: 0 0 40px;
    border-bottom: 0;
  }
  p {
    text-align: left;
    font-size: 16px;
    line-height: 23px;
    font-family: $main-font;
    @include responsive(desktop) {
      font-size: 19px;
      text-align: left;
      line-height: 30px;
      font-family: $main-font;
    }
    strong {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      padding-bottom: 5px;
      display: block;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 24px;
      }
    }
  }
}

/*faq section*/
.o-case-studies-new {
  padding-top: 40px;
  @include responsive(desktop) {
    padding: 80px 0;
  }
  h2 {
    font-family: Poppins;
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 15px;
    text-align: center;
    color: #333;
    font-weight: 600;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 30px;
    }
  }
  .margin-bottom-20 {
    margin-bottom: 10px;
  }
  .faq-pan {
    margin-bottom: 20px;
  }
  .faq-holder {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    .accordion1 {
      font-size: 16px;
      line-height: 22px;
      padding: 15px 35px 15px 15px;
      color: #333;
      cursor: pointer;
      width: 100%;
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      position: relative;
      text-transform: none;
      display: flex;
      align-items: center;
      padding-right: 45px;
      @include responsive(desktop) {
        background-color: transparent;
        padding: 18px;
        border: none;
        outline: none;
        font-size: 19px;
        line-height: 25px;
        transition: 0.4s;
        padding-right: 18px;
      }
      &:after {
        content: "\002B";
        color: #27c070;
        font-weight: normal;
        float: right;
        margin-left: 5px;
        font-size: 30px;
        position: absolute;
        right: 18px;
        // top: 18px;
      }
    }
    .active1:after {
      content: "\2212";
    }
    .panel1 {
      padding: 0 18px;
      background-color: white;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      display: none;
      font-size: 19px;
      line-height: 30px;
      font-family: $secondary-font;
      font-weight: 500;
      p {
        font-size: 16px;
        line-height: 30px;
        font-family: $main-font;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          font-family: $secondary-font;
          font-weight: 500;
        }
      }
    }
  }
}

/*Feature section*/
.insights-section {
  padding-top: 40px;
  padding-bottom: 25px;
  @include responsive(desktop) {
    padding: 75px 0;
  }
  h2 {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 10px;
    text-align: center;
    color: #333;
    font-weight: 600;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 15px;
    }
  }
  .row {
    margin: 0;
  }
  .fi-pan {
    margin: 15px 0;
    box-shadow: none;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    width: 100%;
    padding: 0;
    @include responsive(desktop) {
      display: inline-block;
      vertical-align: top;
      margin: 15px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      width: 30%;
    }
    a {
      display: block;
      padding: 40px 25px;
      position: relative;
      min-height: inherit;
      border-bottom: 5px solid #4a90e2;
      @include responsive(desktop) {
        min-height: 340px;
      }
    }
    .odd {
      border-bottom: 5px solid #009c52;
    }
    h3 {
      text-align: left;
      text-transform: none;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
      font-size: 20px;
      color: #333;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      @include responsive(desktop) {
        text-align: left;
        font-size: 24px;
        line-height: 30px;
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 19px !important;
        text-align: left;
        line-height: 30px;
        color: #333;
        font-weight: 500;
        font-family: $main-font;
      }
    }
    span {
      text-transform: uppercase;
      color: #27c070;
      font-size: 15px;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
      background: url(/images/common/arrow-green.png) no-repeat 100% 50%;
      display: inline-block;
      padding-right: 30px;
      @include responsive(desktop) {
        float: left;
      }
    }
  }
}

/*Why section*/
.c-icon-desc-3-col-ccn {
  .row {
    margin-left: 0;
    margin-right: 0;
    @include responsive(desktop) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}
.no-webp {
  .c-icon-desc-3-col-ccn .row > div div:before {
    // background-image: url(../images/common/common-sprite.png);
  }
}
.webp {
  .c-icon-desc-3-col-ccn .row > div div:before {
    // background-image: url(../images/webp/common/common-sprite.webp);
  }
}
.c-icon-desc-3-col-ccn .row > div div:before {
  content: "";
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  right: inherit;
  margin: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  @include responsive(desktop) {
    left: 0;
    right: inherit;
    margin: 0 auto;
  }
}
.reasons-capital__blocks__choose-01 {
  &:before {
    // background-position: -112px -200px;
    background-image: url(/images/common/why-choose-cn-1.svg) !important;
  }
}
.reasons-capital__blocks__choose-02 {
  &:before {
    // background-position: -200px -200px;
    background-image: url(/images/common/why-choose-cn-2.svg) !important;
  }
}
.reasons-capital__blocks__choose-03 {
  &:before {
    background-image: url(/images/common/why-choose-cn-3.svg) !important;
    background-size: 95%;
  }
}
.reasons-capital__blocks__choose-04 {
  &:before {
    // background-position: -118px -270px;
    background-image: url(/images/common/why-choose-cn-4.svg) !important;
  }
}
.reasons-capital__blocks__choose-05 {
  &:before {
    // background-position: -200px -270px;
    background-image: url(/images/common/why-choose-cn-5.svg) !important;
  }
}
.reasons-capital__blocks__choose-06 {
  &:before {
    // background-position: -272px -270px;
    background-image: url(/images/common/why-choose-cn-6.svg) !important;
  }
}

.o-bottom-form {
  padding: 0;
  @include responsive(desktop) {
    padding-right: 15px;
    padding-left: 15px;
    padding: 80px 0 90px;
  }
  .form-text {
    h2 {
      padding-bottom: 20px;
      &:before {
        content: "";
        position: absolute;
        background: #2ebf72;
        width: 50px;
        height: 1px;
        bottom: 7px;
        left: 0;
      }
    }
  }
  .container {
    padding-left: 0;
    padding-right: 0;
    @include responsive(desktop) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.home-pattern-tl {
  position: relative;
  // &:after {
  //     content: "";
  //     position: absolute;
  //     height: 85px;
  //     width: 200px;
  //     z-index: 1;
  //     background: url(../images/common/left-top-pattern.png) no-repeat 0 0;
  //     left: 0px;
  //     top: 0;
  //     display: none;
  //     @include responsive(desktop){
  //         display: block;
  //         background-size: 75%;
  //         height: 250px;
  //         width: 604px;
  //         display: block;
  //     }
  // }
}

/*let's talk*/
.lets-talk {
  background: url(/images/common/schedule-talk-bg.jpg);
  background-size: cover;
  padding: 30px 0 45px;
  margin-top: 0;
  @include responsive(desktop) {
    margin-top: 25px;
  }
  h2 {
    color: #fff;
    padding-bottom: 5px;
    font-size: 22px;
    line-height: 30px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 25px;
    }
    span {
      display: block;
      @include responsive(desktop) {
        display: inline;
      }
    }
  }
}

/*Custom software services*/
.mobile-on-cs {
  display: block;
  @include responsive(desktop) {
    display: none !important;
  }
}
.mobile-on-flex {
  display: flex !important;
  @include responsive(desktop) {
    display: none !important;
  }
}
.mobile-off {
  display: none;
  @include responsive(desktop) {
    display: block;
  }
}
.mobile-off-flex {
  display: none;
  @include responsive(desktop) {
    display: flex;
  }
}

.masonry.service-wrap {
  @include responsive(desktop) {
    column-count: 3;
    column-gap: 27px;
    margin-bottom: 30px;
  }
}

.custom-software-development {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // background: url(../images/custom-software-development/service-group-icon.png) no-repeat;
        // width: 64px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 12%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -64px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -128px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -63px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -64px -63px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -128px -63px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: 0 -126px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -65px -126px;
      }
    }
  }
}
.cs-bnr-icon {
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  padding: 20px 0;
  @include responsive(desktop) {
    display: none;
  }
  li {
    flex: 1;
    text-align: center;
    &:nth-child(1) {
      flex: 0 0 64px;
    }
  }
}

.mobile-talk-bx {
  background: linear-gradient(160deg, #11a984 0%, #0d7d88 17%, #0a4f8e 50%);
  border-radius: 7px;
  padding: 25px 10px;
  margin-bottom: 20px;
  h2 {
    font-size: 32px;
    color: #fff;
    position: relative;
    display: inline-block;
    margin-top: 25px;
    font-weight: 900;
    &:before {
      content: "";
      position: absolute;
      background: url(/images/mobile-all/talk-bg.png) no-repeat 0 0;
      width: 100px;
      height: 55px;
      right: -25px;
      top: 15px;
      @include responsive(desktop) {
        top: 0;
      }
    }
    span {
      position: relative;
    }
  }
  .bold-p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px 10px;
    margin-bottom: 0;
    border-bottom: 1px dashed #ccc;
    color: #fff;
    font-family: $main-font;
  }
  h3 {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    margin-top: 15px;
    font-weight: 700;
    span {
      color: #36e079;
      margin-top: 15px;
      font-weight: 700;
    }
  }
  .btn_wrapper {
    width: 100%;
    padding-top: 40px;
  }
}

/*banner two logo*/
.new-two-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
  @include responsive(desktop) {
    padding: 30px 0 30px 0;
  }
  li {
    margin: 0 10px;
    @include responsive(desktop) {
      margin: 0 30px;
    }
  }
}
.o-skills-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none;
  @include responsive(desktop) {
    display: block;
  }
}
.case-studies-details {
  .o-skills-hero__background {
    @include responsive(desktop) {
      display: block;
    }
  }
}

/*Out source product*/

.outsource-product {
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // background: url(../images/outsource-product-development/service-group-icon.png) no-repeat;
        // width: 73px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 12%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -73px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -146px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -63px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -73px -63px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -146px -63px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: 0 -126px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -65px -126px;
      }
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
}

/*testing QA*/
.testing-qa {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // background: url(../images/testing-qa/service-group-icon.png) no-repeat;
        // width: 63px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 12%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
      i {
        display: block;
        height: 55px;
        img {
          height: 100%;
        }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -63px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -126px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -63px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -63px -63px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -126px -63px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: 0 -126px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -63px -126px;
      }
    }
    .brick.services-pan__php-service-09 {
      &:before {
        background-position: -126px -126px;
      }
    }
  }
}

/*dedicated development team*/
.dedicated-teams {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // background: url(../images/software-development-team/service-group-icon.png) no-repeat;
        // width: 63px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 12%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -63px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -126px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -63px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -63px -63px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -126px -63px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: 0 -126px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -63px -126px;
      }
    }
    .brick.services-pan__php-service-09 {
      &:before {
        background-position: -126px -126px;
      }
    }
  }
  .masonry.service-wrap {
    margin-bottom: 0;
  }
}

/*devops*/
.devops {
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // background: url(../images/devopps/service-group-icon.png) no-repeat;
        // width: 70px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 9%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -70px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -140px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -63px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -70px -63px;
      }
    }
    .brick.services-pan__php-service-06 {
      &:before {
        background-position: -140px -63px;
      }
    }
    .brick.services-pan__php-service-07 {
      &:before {
        background-position: 0 -126px;
      }
    }
    .brick.services-pan__php-service-08 {
      &:before {
        background-position: -70px -126px;
      }
    }
  }
}

.devops-stack {
  padding: 40px 0 30px;
  @include responsive(desktop) {
    padding: 70px 0 20px;
  }
  background: #05547d;
  position: relative;
  &:after {
    display: none;
  }
  .container {
    position: relative;
    z-index: 9;
  }
  h2 {
    font-size: 30px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 15px;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 25px;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-family: $main-font;
    margin-bottom: 0;
    padding-bottom: 15px;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
      font-weight: 500;
      padding-bottom: 30px;
    }
  }
  .service-wrap-mod {
    .services-pan {
      display: inline-block;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background: #fff;
      vertical-align: top;
      margin: 15px 0 15px;
      padding: 120px 30px 30px;
      padding-top: 40px;
      padding-bottom: 40px;
      &:before {
        display: none;
      }
      h3 {
        font-weight: 600;
        padding-right: 0;
        position: relative;
        padding-bottom: 30px;
        text-align: left;
        font-size: 22px;
        @include responsive(desktop) {
          padding-right: 25px;
        }
        &:before {
          content: "";
          position: absolute;
          background: #24c06d;
          height: 3px;
          bottom: 5px;
          left: 0;
          margin: 0;
          width: 35px;
          @include responsive(desktop) {
            right: 0;
            margin: 0;
          }
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 500;
        color: #333;
        text-align: left;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 0;
        }
      }
      h4 {
        font-size: 19px;
        line-height: 25px;
        text-align: left;
        font-family: $main-font;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 25px;
          font-family: $main-font;
        }
      }
      ul {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        li {
          display: flex;
          align-items: center;
          padding: 0 0 20px;
          flex: 0 0 100%;
          @include responsive(desktop) {
            flex: 0 0 50%;
            align-items: flex-start;
          }
          &:nth-child(2n + 1) {
            @include responsive(desktop) {
              border-right: 1px solid #dedede;
              padding-right: 10px;
            }
          }
          &:nth-child(2n) {
            @include responsive(desktop) {
              padding-left: 10px;
            }
          }
          &:last-child {
            padding-bottom: 0;
          }
          span {
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            margin-right: 15px;
            flex: 0 0 30px;
            @include responsive(desktop) {
              flex: initial;
            }
          }
          h5 {
            color: #333;
            font-family: $main-font;
            margin-bottom: 0;
            @include responsive(desktop) {
              font-family: $main-font;
              margin-bottom: 0.5rem;
              font-size: 14px;
              line-height: 18px;
            }
            font-size: 14px;
            line-height: 18px;
            flex: 1;
            text-align: left;
          }
        }
      }
    }
  }
  .bottom-left-pt {
    position: absolute;
    background: url(/images/common/bottom-left-pt.png) bottom left;
    background-repeat: no-repeat;
    z-index: 9;
    width: 615px;
    height: 325px;
    bottom: 0;
    left: 0;
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
}

/*Ecommerce*/
.o-skills-hero__e-commerce {
  position: relative;
  background: linear-gradient(138deg, #165b5e 0, #0f3a4a 17%, #091b38 50%);
  .container {
    position: relative;
    z-index: 9;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-weight: 700;
    @include responsive(desktop) {
      font-size: 62px;
      line-height: 75px;
    }
  }
  .o-services-hero__icons {
    text-align: center;
    //   display: flex;
    margin-bottom: 20px;
    @include responsive(desktop) {
      margin: 30px 0 60px;
      display: block;
    }
    a {
      display: inline-block;
      cursor: pointer;
      vertical-align: bottom;
      flex: 1;
      padding: 0;
      width: 30%;
      @include responsive(desktop) {
        width: auto;
      }
      &:hover {
        transform: scale(0.9);
      }
      img {
        margin: 0;
        height: 100px;
        width: 55%;
        height: auto;
        @include responsive(desktop) {
          margin: 0 30px;
          width: auto;
        }
      }
    }
    span {
      display: block;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      margin-top: 15px;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      @include responsive(desktop) {
        font-size: 18px;
      }
    }
  }
}

.correct-section {
  padding: 45px 0 45px;
  position: relative;
  background: #f5f5f5;
  @include responsive(desktop) {
    padding: 70px 0 75px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    // font-weight: 700;
    text-align: center;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 30px;
    }
  }
  p {
    font-family: $main-font;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    @include responsive(desktop) {
      margin-bottom: 35px;
      font-size: 19px;
      line-height: 29px;
      font-family: $main-font;
      padding: 0 90px;
    }
  }
  .correct-img {
    position: relative;
    text-align: center;
    @include responsive(desktop) {
      text-align: left;
    }
    img {
      width: 300px;
      margin: 0 auto;
      @include responsive(desktop) {
        position: absolute;
        // top: 44px;
        top: 0;
        width: 100%;
        margin: 0;
      }
    }
  }
  .cstm-link-p {
    margin-bottom: 0;
  }
}
.number-section {
  padding-bottom: 10px;
  position: relative;
  @include responsive(desktop) {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    padding-bottom: 30px;
    @include responsive(desktop) {
      padding: 0 50px;
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 50px;
    }
  }
  .dc-white-pan {
    text-align: left;
    position: relative;
    border-radius: 6px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    height: auto;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    @include responsive(desktop) {
      height: 295px;
      padding: 30px;
    }
    div {
      overflow: hidden;
      position: relative;
      @include responsive(desktop) {
        margin-bottom: 30px;
      }
      span {
        font-size: 36px;
        font-weight: 500;
        float: left;
        color: #14df7d;
        font-family: $main-font;
        line-height: 71px;
        vertical-align: middle;
      }
      .body-text-medium {
        float: right;
        width: 78%;
        padding-top: 20px;
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        font-family: $main-font;
        @include media-query(1200px) {
          font-size: 18px;
          line-height: 30px;
          font-family: $main-font;
        }
      }
    }
  }
  h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 0;
    text-align: center;
    width: 100%;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      margin-top: 30px;
    }
  }
  .section-para {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-family: $main-font;
    text-align: center;
    margin-bottom: 25px;
    padding: 0 15px;
    @include responsive(desktop) {
      padding: 0 60px;
      font-size: 19px;
      line-height: 29px;
      font-family: $main-font;
    }
  }
  &-bnt {
    text-align: center;
    margin-bottom: 25px;
    a {
      display: inline-block;
      padding: 10px 50px 10px 15px;
      line-height: 46px;
      background: transparent;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 13px;
      font-family: $main-font;
      border-radius: 6px;
      border: 3px solid #14df7d;
      margin-bottom: 15px;
      transition: 0.3s all ease-in;
      width: 280px;
      @include responsive(desktop) {
        width: 470px;
        height: 72px;
        margin: 0 15px 15px;
        font-size: 24px;
        font-weight: 500;
      }
      &:before {
        content: "";
        position: absolute;
        background: url(/images/common/right-circle-icon.png) no-repeat right
          center;
        height: 35px;
        width: 35px;
        top: 15px;
        right: 10px;
        @include responsive(desktop) {
          right: 30px;
        }
      }
      &:hover {
        background: #fff;
        color: #ff5800;
        border: 3px solid #ff5800;
      }
    }
  }
  .row.padding-bottom-30 {
    padding-bottom: 0;
    @include responsive(desktop) {
      padding-bottom: 30px;
    }
  }
}
#aes {
  @include responsive(desktop) {
    padding: 70px 0 80px;
  }
  .btn_wrapper {
    a {
      margin: 20px auto 0;
      padding: 15px 0;
      width: 240px;
      max-width: none;
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
}
#solution-container {
  .cstm-link-p {
    text-align: center !important;
    @include responsive(desktop) {
      text-align: left !important;
    }
  }
}
.solution-section {
  position: relative;
  padding: 40px 0 30px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  &:before {
    content: "";
    background: url(/images/e-commerce/top-right-pt.png) no-repeat top right;
    position: absolute;
    top: 0;
    right: 0;
    width: 615px;
    height: 326px;
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
  h2 {
    margin-bottom: 15px;
    // font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 25px;
      text-align: left;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: $main-font;
    text-align: center;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      // text-align: left;
      font-family: $main-font;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  .solution-left {
    h3 {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 28px;
      margin-top: 30px;
      text-align: center;
      @include responsive(desktop) {
        font-size: 24px;
        line-height: 32px;
        margin-top: 20px;
        text-align: left;
      }
    }
    .add-more {
      font-family: "Montserrat", sans-serif;
      color: #333;
      font-size: 16px;
      margin: 20px auto 30px;
      width: 150px;
      display: block;
      @include responsive(desktop) {
        display: inline-block;
        width: auto;
        margin: 20px 0 30px;
      }
    }
    .anchor-link {
      @include responsive(desktop) {
        text-align: left;
      }
    }
  }
  ul {
    padding-left: 15px;
    @include responsive(desktop) {
      padding-left: 0;
    }
    li {
      position: relative;
      font-family: $main-font;
      font-size: 16px;
      line-height: 28px;
      padding-left: 40px;
      margin-bottom: 10px;
      @include responsive(desktop) {
        font-size: 19px;
        line-height: 24px;
        font-family: $main-font;
      }
      &:before {
        content: "";
        position: absolute;
        background: url(/images/e-commerce/black-circle-icon.png) no-repeat 0
          2px;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
      }
    }
  }
  .btn_wrapper {
    a {
      margin: 0 auto;
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
  .body-img2 {
    position: relative;
    img {
      // @include responsive(desktop){
      //     position: absolute;
      //     top: -40px;
      // }
    }
  }
  .solution-white-box  {
    background: #fff;
    padding: 30px 15px 30px 15px;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 15px;
    border: 1px solid #e0e0e0;
    @include responsive(desktop) {
      // height: 750px;
      padding: 50px 25px 50px 40px;
      margin-bottom: 0;
      margin-top: 0;
    }
    span {
      width: auto;
      text-align: center;
      display: block;
      flex: 0 0 100%;
      @include responsive(desktop) {
        display: inline;
        width: auto;
        text-align: left;
        width: auto;
        flex: inherit;
      }
    }
    img {
      margin-right: 0;
      margin-bottom: 15px;
      @include responsive(desktop) {
        margin-right: 30px;
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 20px;
      text-align: center;
      flex: 0 0 100%;
      @include responsive(desktop) {
        float: left;
        position: relative;
        top: 5px;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        text-align: left;
        flex: inherit;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      text-align: center;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 19px;
        line-height: 29px;
        text-align: left;
        font-family: $main-font;
      }
    }
    h5 {
      margin-bottom: 25px;
      font-size: 17px;
      line-height: 25px;
      font-weight: 600;
      @include responsive(desktop) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    ul {
      padding-left: 15px;
      @include responsive(desktop) {
        margin-bottom: 40px;
        padding-left: 0;
      }
      li {
        position: relative;
        font-family: $main-font;
        @include responsive(desktop) {
          font-family: $main-font;
          font-size: 19px;
          line-height: 24px;
        }
        font-size: 16px;
        line-height: 24px;
        padding-left: 40px;
        margin-bottom: 10px;
      }
    }
  }
  &-right {
    img {
      @include responsive(desktop) {
        margin-bottom: 80px;
      }
    }
  }
}
.bg-grey {
  background-color: #f5f5f5;
}
.solution-white-box-head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  @include responsive(desktop) {
    height: 70px;
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }
}

.e-commerce-why {
  position: relative;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  // &:before {
  //     content: '';
  //     position: absolute;
  //     background: url(../images/common/bottom-left-pt.png) no-repeat bottom left;
  //     bottom: 0;
  //     left: 0;
  //     width: 615px;
  //     height: 326px;
  //     display: none;
  //     @include responsive(desktop){
  //         display: block;
  //     }
  // }
  h2 {
    color: #333;
    margin-bottom: 5px;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 25px;
      color: #333;
    }
  }
  .main-span {
    color: #333;
    text-align: center;
    display: block;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    font-family: $main-font;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 20px;
      color: #fff;
    }
  }
  .service-wrap {
    text-align: center;
    margin-bottom: 15px;
    .services-pan {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0 !important;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      text-align: center;
      background: #fff;
      @include responsive(desktop) {
        width: 30%;
        // height: 515px;
        padding: 120px 25px 30px;
        margin: 17px 15px !important;
      }
      &:before {
        // content: "";
        // background: url(../images/e-commerce/why-group-icon.png) no-repeat;
        // width: 67px;
        // height: 71px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 8%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -68px 0;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -135px 0;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: 0 -71px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -68px -71px;
      }
    }
  }
}

/*cms*/
.cms-bnr {
  position: relative;
  background: linear-gradient(138deg, #165b5e 0, #0f3a4a 17%, #091b38 50%);
  .container {
    position: relative;
    z-index: 9;
  }
  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      font-size: 62px;
      line-height: 75px;
      font-weight: 700;
    }
  }
  .o-services-hero__icons {
    margin: 0 0 40px;
    @include responsive(desktop) {
      margin: 0 0 60px;
    }
    a {
      display: inline-block;
      cursor: pointer;
      vertical-align: bottom;
      padding: 0 5px;
      width: 23%;
      @include responsive(desktop) {
        padding: 0 30px;
        width: auto;
      }
      &:hover {
        transform: scale(0.9);
      }
      span {
        font-size: 13px;
        @include responsive(desktop) {
          font-size: 18px;
        }
      }
    }
    img {
      max-width: 65%;
      @include responsive(desktop) {
        max-width: 100%;
      }
    }
  }
}

.cms-team {
  padding: 40px 0 25px;
  text-align: center;
  @include responsive(desktop) {
    padding: 75px 0 50px;
    text-align: left;
  }
  h2 {
    margin-bottom: 20px;
    @include responsive(desktop) {
      margin-bottom: 15px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-family: $main-font;
    margin-bottom: 15px;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 25px;
      font-family: $main-font;
    }
  }
}
.cms-dev-service {
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  .service-wrap {
    text-align: center;
    margin-bottom: 15px;
    @include responsive(desktop) {
      margin-bottom: 35px;
    }
    .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 50px 15px 35px;
      position: relative;
      @include media-query(768px) {
        width: 45%;
        height: 680px;
        padding: 50px 30px 30px;
        margin: 15px;
      }
      @include media-query(992px) {
        width: 46%;
      }
      @include media-query(1200px) {
        width: 30%;
      }
      &:before {
        // content: "";
        // background: url(../images/cms-developer/services-group-icon.png) no-repeat;
        // width: 83px;
        // height: 68px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 9%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     display: block;
        // }
      }
      i {
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: initial;
        @include responsive(desktop) {
          align-items: flex-end;
        }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: 0px -10px;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -95px -5px;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -195px 0px;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: -289px -11px;
      }
    }
    .brick.services-pan__php-service-05 {
      &:before {
        background-position: -382px -10px;
      }
    }
  }
}
.cms-why {
  padding: 50px 0;
  @include responsive(desktop) {
    padding: 70px 0 52px;
  }
  h2 {
    color: #fff;
    margin-bottom: 15px;
    // font-weight: 700;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-family: $main-font;
    @include responsive(desktop) {
      padding: 0px 40px;
      font-size: 28px;
      line-height: 40px;
    }
  }
  .cstm-link-p {
    line-height: 0;
  }
  .service-wrap {
    text-align: center;
    margin-bottom: 35px;
    .brick.services-pan {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 5px 15px 30px;
      position: relative;
      @include responsive(desktop) {
        height: 410px;
        width: 46%;
        padding: 50px 30px 30px;
        margin: 15px;
      }
      &:before {
        // content: "";
        // background: url(../images/cms-developer/services-group-icon.png) no-repeat;
        // width: 100px;
        // height: 100px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 9%;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     display: block;
        // }
      }
      i {
        height: 55px;
        display: block;
        img {
          height: 100%;
        }
      }
    }
    .brick.services-pan__php-service-01 {
      &:before {
        background-position: -10px -90px;
      }
    }
    .brick.services-pan__php-service-02 {
      &:before {
        background-position: -114px -90px;
      }
    }
    .brick.services-pan__php-service-03 {
      &:before {
        background-position: -220px -90px;
      }
    }
    .brick.services-pan__php-service-04 {
      &:before {
        background-position: -325px -90px;
      }
    }
    .brick.services-pan.agencies-services {
      @include responsive(desktop) {
        height: 415px;
      }
      &:before {
        // content: "";
        // background: url(../images/agencies/agencies-sprite.png);
        // width: 77px;
        // height: 77px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 9%;
        display: none;
        // @include responsive(desktop){
        //     display: block;
        // }
      }
    }
    .brick.agencies-services.services-pan__php-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .brick.agencies-services.services-pan__php-service-02 {
      &:before {
        background-position: -87px 0px;
      }
    }
    .brick.agencies-services.services-pan__php-service-03 {
      &:before {
        background-position: -174px 0px;
      }
    }
    .brick.agencies-services.services-pan__php-service-04 {
      &:before {
        background-position: -261px 0px;
      }
    }
    h3 {
      font-size: 20px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 24px;
        font-family: $main-font;
      }
    }
    p {
      color: #333;
      font-size: 16px;
      line-height: 28px;
      padding: 0;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.dedicated-team {
  .row.margin-top-30 {
    margin-top: 10px;
    @include responsive(desktop) {
      margin-top: 30px;
    }
  }
  .margin-top-30 {
    @include responsive(desktop) {
      margin-top: 30px;
    }
  }
  padding: 45px 0 20px;
  background-color: #f8f8f8;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    padding-bottom: 10px;
    font-weight: 700;
    @include responsive(desktop) {
      padding-bottom: 30px;
    }
  }
  .cms-blocks {
    position: relative;
    @include responsive(desktop) {
      padding: 0 40px 0 70px;
    }
    p {
      font-size: 16px;
      text-align: center;
      line-height: 28px;
      font-family: $main-font;
      margin-bottom: 15px;
      @include responsive(desktop) {
        font-size: 19px;
        text-align: left;
        font-family: $main-font;
        margin-bottom: 25px;
      }
    }
    div {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url(/images/cms-developer/cms-team.png);
        width: 53px;
        height: 57px;
        display: none;
        @include responsive(desktop) {
          display: block;
        }
      }
    }
    .cms-blocks__choose-01 {
      &:before {
        background-position: 0px 0px;
      }
    }
    .cms-blocks__choose-02 {
      &:before {
        background-position: -185px 0px;
      }
    }
    .cms-blocks__choose-03 {
      &:before {
        background-position: -62px -67px;
      }
    }
    .cms-blocks__choose-04 {
      &:before {
        background-position: -63px 0px;
      }
    }
    .cms-blocks__choose-05 {
      &:before {
        background-position: -252px 0px;
      }
    }
    .cms-blocks__choose-06 {
      &:before {
        background-position: -126px -67px;
      }
    }
    .cms-blocks__choose-07 {
      &:before {
        background-position: -125px 0px;
      }
    }
    .cms-blocks__choose-08 {
      &:before {
        background-position: 0px -67px;
      }
    }
    .cms-blocks__choose-09 {
      &:before {
        background-position: -185px -67px;
      }
    }
  }
}
.o-services-hero__icons {
  margin: 0 0 40px;
  @include responsive(desktop) {
    margin: 0 0 60px;
  }
  a {
    display: inline-block;
    cursor: pointer;
    vertical-align: bottom;
    padding: 0 5px;
    width: 23%;
    @include responsive(desktop) {
      padding: 0 30px;
      width: auto;
    }
    &:hover {
      transform: scale(0.9);
    }
    span {
      font-size: 13px;
      @include responsive(desktop) {
        font-size: 18px;
      }
    }
  }
}

/*Mobile app*/

.o-skills-hero__mobile-app {
  padding-bottom: 20px;
  h1 {
    text-align: center;
    padding-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      padding: 0 120px;
      padding-bottom: 30px;
      font-size: 62px;
      line-height: 75px;
    }
  }
  h2 {
    font-size: 20px;
    text-align: center;
    color: #fff;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 15px !important;
    @include responsive(desktop) {
      font-size: 26px;
      line-height: 36px;
      margin-top: 20px;
      margin-bottom: 35px;
    }
  }
  .o-services-hero__icons {
    img {
      max-width: 60%;
      @include responsive(desktop) {
        max-width: 100%;
      }
    }
  }
}

.beauty-section {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    margin-bottom: 15px;
    text-align: center;
    line-height: 38px;
    @include responsive(desktop) {
      padding: 0 210px;
      line-height: 61px;
    }
  }
  p.cmn-heading-para {
    @include responsive(desktop) {
      padding: 0 40px;
      margin-bottom: 40px;
      font-family: $main-font;
    }
  }
  .beauty-list {
    @include responsive(desktop) {
      padding: 0 50px;
    }
    span {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      margin-bottom: 20px;
      padding-left: 30px;
      font-family: $main-font;
      font-weight: 500;
      @include responsive(desktop) {
        margin-bottom: 30px;
        font-size: 19px;
        line-height: 29px;
        font-family: $main-font;
        padding-left: 40px;
      }
      &:before {
        content: "";
        position: absolute;
        background: url(/images/mobile-app/circle-tick.svg) no-repeat 0 0;
        top: 4px;
        left: 0;
        height: 35px;
        width: 35px;
        @include responsive(desktop) {
          background: url(/images/mobile-app/right-arrow.png) no-repeat 0 0;
          top: 0;
        }
      }
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
}

.scroll-section {
  .js-mobileapp-feature-1-parallax-1 {
    @include responsive(desktop) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      bottom: 0px;
      left: 0;
      background: url(/images/mobile-app/consumer-apps-bg.png) 100% 100%
        no-repeat #115687;
      background-size: 70%;
    }
  }
  .js-mobileapp-feature-2-parallax-1 {
    @include responsive(desktop) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      bottom: 0px;
      left: 0;
      background: url(/images/mobile-app/enterprise-apps-bg.png) 100% 100%
        no-repeat #43ae76;
      background-size: 84%;
    }
  }
  .mobile-holder-clip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(auto, auto, auto, auto);
    display: none;
    @include responsive(desktop) {
      display: block;
    }
    .mobile-holder-phone {
      width: 33vw;
      height: 50vh;
      position: fixed;
      bottom: -20px;
      right: 60px;
      .mobile-in-hand {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: auto;
        display: none;
        @include responsive(desktop) {
          display: block;
        }
      }
    }
  }
  .container {
    @include responsive(desktop) {
      height: 100%;
    }
  }
  .js-mobileapp-features-content-1 {
    h2 {
      font-size: 24px;
      padding: 25px 0 10px 0;
      pointer-events: inherit;
      @include responsive(desktop) {
        font-size: 34px;
        text-align: left;
        padding: 0;
        margin-bottom: 50px;
        pointer-events: none;
      }
    }
  }
  p {
    color: #fff;
    font-family: $main-font;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 29px;
      font-family: $main-font;
    }
  }
  .btn_wrapper {
    margin: 30px 0 0;
    a {
      margin: 0;
    }
  }
  .anchor-link {
    margin: 10px 0 0;
    @include responsive(desktop) {
      margin: 10px 0 0;
    }
    text-align: left;
  }
}
// .o-mobile-app-features__content--bgblue {
//     background: url(../images/m-mobileapp-features/consumer-apps-bg.png) 50% 100% no-repeat;
//     background-size: 100%;
//     @include responsive(desktop){
//         background: none;
//         display: block;
//         padding: 0;
//     }
// }
.js-mobileapp-features-content-2 {
  h2 {
    padding: 25px 0 10px 0;
    font-size: 24px;
    @include responsive(desktop) {
      padding: 0;
      font-size: 48px;
    }
  }
}
/*.o-mobile-app-features__content {
    display: none;
    @include responsive(desktop){
        display: block;
    }
}*/
.o-mobile-app-features {
  color: #fff;
  position: relative;
  padding: 120px 0 20px;
  overflow: hidden;
  pointer-events: inherit;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
  }
  h2.is-active {
  }
}
.o-mobile-app-features--blue {
  background: none #115687;
}
.h3-24 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.c-ticked-list--light {
  li {
    position: relative;
    padding: 0 0 5px 30px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    font-family: $main-font;
    @include responsive(desktop) {
      font-family: $main-font;
      font-weight: 500;
      line-height: 29px;
      font-size: 19px;
      padding: 0 0 15px 35px;
    }
    &:before {
      content: "";
      position: absolute;
      background: url(/images/mobile-app/circle-tick.svg) 50% 50% no-repeat;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 4px;
      left: 0px;
      @include responsive(desktop) {
        background: url(/images/common/icon-bullet.svg) 50% 50% no-repeat;
        top: 5px;
      }
    }
  }
}
.o-mobile-app-features--green {
  background: #43ae76;
}
/* Mobile Tab */
.o-mobile-app-features-parent {
  position: relative;
}
.o-mobile-app-features-tab {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}

.o-mobile-app-features-tab-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: none;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
  &:before,
  &:after {
    content: "";
    border: 9px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translate(-50%, 0);
    display: none;
  }
  &:after {
    border: 8px solid transparent;
    border-top-color: #115687;
    border-bottom: 0;
    bottom: -7px;
    z-index: 1;
  }
  &.active {
    &:before,
    &:after {
      display: block;
    }
  }
  &--bg-blue {
    background: #115687;
  }
  &--bg-green {
    background: #43ae76;
    &:after {
      border-top-color: #43ae76;
    }
  }
}

/* Mobile Tab */

.service-process {
  padding: 45px 0 20px;
  // background: #f5f5f5;
  @include responsive(desktop) {
    padding: 70px 0 50px;
    // background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%);
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: #333;
    padding-bottom: 20px;
    @include responsive(desktop) {
      padding-bottom: 30px;
      font-size: 48px;
      color: #333;
    }
  }
  .dc-white-pan {
    position: relative;
    height: auto;
    min-height: inherit;
    text-align: left;
    padding: 50px 30px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    @include responsive(desktop) {
      min-height: 495px;
      padding: 30px;
      border: none;
    }
    &:before {
      content: "";
      background: url(/images/mobile-app/process-group-icon.png) no-repeat 0 0;
      width: 66px;
      height: 65px;
      position: absolute;
      right: 10px;
      top: 30px;
      @include responsive(desktop) {
        right: 25px;
      }
    }
    div {
      margin-bottom: 15px;
      overflow: hidden;
      position: relative;
      span {
        float: left;
        color: #14df7d;
        font-family: $main-font;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        @include responsive(desktop) {
          font-size: 58px;
          line-height: 71px;
          font-weight: 300;
        }
        vertical-align: middle;
      }
      h3 {
        vertical-align: middle;
        font-size: 20px;
        line-height: 28px;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translate(0, -50%);
        @include responsive(desktop) {
          font-size: 22px;
          line-height: 32px;
          left: 90px;
        }
      }
    }
    .body-text-medium {
      width: 100%;
      font-size: 16px;
      line-height: 28px;
      color: #333;
      font-family: $main-font;
      font-weight: 500;
      text-align: left;
      margin-bottom: 0;
      @include responsive(desktop) {
        font-size: 19px;
        line-height: 32px;
        text-align: left;
        font-family: $main-font;
        margin-bottom: 25px;
      }
    }
  }
  .process-box1 {
    &:before {
      background-position: 0 0;
    }
  }
  .process-box2 {
    &:before {
      background-position: -67px 0;
    }
  }
  .process-box3 {
    &:before {
      background-position: -132px 0;
    }
  }
  .process-box4 {
    &:before {
      background-position: -198px 0;
    }
  }
  .slick-dots {
    margin-top: 0;
  }
}

/*AI chatbot*/
.ai-chatbots-hero {
  padding-bottom: 0;
  @include responsive(desktop) {
    padding-bottom: 0;
  }
  .btn_wrapper {
    padding-top: 0;
    @include responsive(desktop) {
      padding-top: 40px;
    }
  }
  .anchor-link {
    text-align: center;
    display: block;
    margin: 10px auto 0;
    @include responsive(desktop) {
      text-align: left;
      display: inline;
      margin-top: 10px;
    }
  }
  .green-head-ribbon {
    text-align: center !important;
    padding: 0;
    @include responsive(desktop) {
      text-align: left !important;
    }
  }
  h1 {
    // column-gap: ;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-top: 15px;
    @include responsive(desktop) {
      padding-right: 80px;
      text-align: left;
      font-size: 62px;
      line-height: 75px;
      margin-top: 0;
    }
  }
  h2 {
    color: #b7b7b7;
    margin-top: 20px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    @include responsive(desktop) {
      text-align: left;
      font-size: 24px;
      color: #14de7d;
    }
  }
  .btn_wrapper {
    a {
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
  .anchor-link {
    @include responsive(desktop) {
      text-align: left;
    }
  }
  .chatbot-gif {
    position: static;
    @include responsive(desktop) {
      position: absolute;
      top: 200px;
    }
  }
}
.custom-para {
  padding: 45px 0 30px;
  position: relative;
  @include responsive(desktop) {
    padding: 70px 0 20px;
  }
  h2 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
    // font-weight: 700;
    padding-bottom: 0;
    @include responsive(desktop) {
      font-size: 36px;
      line-height: 53px;
      margin-bottom: 25px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 0;
    font-family: $main-font;
    margin-bottom: 10px;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 30px;
      padding: 0 80px 0;
      padding-bottom: 30px;
      font-family: $main-font;
      margin-bottom: 25px;
    }
  }
}
.ai-chatbots {
  padding: 45px 0 20px;
  position: relative;
  background-color: #f8f8f8;
  @include responsive(desktop) {
    padding: 70px 0 20px 50px;
  }
  .margin-top-30 {
    margin-top: 0;
    @include responsive(desktop) {
      margin-top: 30px;
    }
  }
  h2 {
    @include responsive(desktop) {
      padding: 0 50px;
      margin-bottom: 50px;
    }
  }
  .dc-white-pan {
    background: #fff;
    box-shadow: 0px 0px 34px 0px #0000001c;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
    position: relative;
    border: 1px solid #e0e0e0;
    @include responsive(desktop) {
      padding: 30px;
    }
    div {
      position: relative;
      span {
        position: absolute;
        width: 88px;
        height: 88px;
        left: 0;
        top: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        @include responsive(desktop) {
          top: 12%;
          right: inherit;
          margin: 0;
        }
        img {
          margin: 0;
          @include responsive(desktop) {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .col-sm-offset-2 {
    @include responsive(desktop) {
      margin: 30px auto;
    }
    h3 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 25px;
      margin-top: 10px;
      font-weight: 700;
      @include responsive(desktop) {
        font-size: 36px;
        margin-top: 0;
        font-weight: 600;
      }
    }
    .section-para {
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 25px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 19px;
        line-height: 29px;
        font-family: $main-font;
      }
    }
    .btn_wrapper {
      margin-top: 40px;
    }
  }
}

.build {
  padding: 50px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  &__wrap {
    margin: 30px 0 0;
    @include responsive(desktop) {
      margin: 30px 0;
    }
    p {
      margin: 25px 0 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #fff;
      font-family: $main-font;
      @include responsive(desktop) {
        padding: 0 30px;
        font-family: $main-font;
        font-weight: 500;
        margin: 45px 0 0;
      }
    }
  }
  h3 {
    margin: 40px 0;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      padding: 0 80px;
      font-size: 36px;
      line-height: 49px;
      margin: 20px 0 40px;
    }
  }
  .chat-app-group {
    // margin-bottom: 65px;
    margin-bottom: 40px;
  }
}

.o-how-we-create-your-bot {
  position: relative;
  padding: 40px 0 25px;
  overflow: hidden;
  z-index: 106;
  text-align: center;
  @include responsive(desktop) {
    padding: 65px 0 0;
    background: url(/images/ai-chatbots/slider-bg.png) 100% 100% no-repeat;
    background-size: 55%;
  }
  .anchor-link {
    margin: 10px auto;
    display: block;
    @include responsive(desktop) {
      margin: 10px 0;
      display: inline;
    }
  }
  p {
    @include responsive(desktop) {
      padding: 0 85px;
    }
  }
  .inner {
    .slider-container {
      padding: 10px 0 0;
      @include responsive(desktop) {
        padding: 40px 0 0;
      }
      margin: 0 auto;
      position: relative;
      .left {
        position: relative;
        @include responsive(desktop) {
          max-width: none;
        }
        .icon-phone {
          max-width: 100%;
          display: block;
          @include responsive(desktop) {
            max-width: none;
          }
        }
        .icon-phone {
          @include media-query(992px) {
            max-width: 280px;
          }
          margin: 0 auto;
          @include responsive(desktop) {
            max-width: 100%;
          }
        }
      }
      .numbers {
        position: absolute;
        top: 5px;
        width: 100px;
        height: 100px;
        background: #fff;
        font-weight: 700;
        font-size: 62px;
        line-height: 100px;
        color: #14df7d;
        text-align: center;
        border: 1px solid #e3e3e3;
        border-radius: 50%;
        right: 0;
        @include responsive(desktop) {
          right: 5px;
        }
      }
      .icons {
        position: absolute;
        width: 150px;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translate(0);
        @include responsive(desktop) {
          top: 40%;
          width: 280px;
          // transform: translate(-50%, 0%);
        }
        .slick-slide {
          img {
            margin: 0 auto;
          }
        }
      }
      .right {
        padding-top: 0;
        text-align: left;
        position: relative;
        left: 0;
        @include responsive(desktop) {
          left: 65px;
          padding-top: 50px;
        }
        .text-rotator {
          h3 {
            margin-bottom: 15px;
            line-height: 34px;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            min-height: 150px;
            margin-top: 25px;
            @include responsive(desktop) {
              width: 460px;
              font-size: 26px;
              line-height: 34px;
              min-height: 180px;
              text-align: left;
              margin-top: 0;
            }
            strong {
              color: #14df7d;
            }
          }
        }
      }
    }
  }
  .btn_wrapper {
    a {
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
  .anchor-p {
    @include responsive(desktop) {
      padding: 0;
      text-align: left;
    }
    .anchor-link {
      @include responsive(desktop) {
        text-align: left;
      }
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .pagers {
    position: relative;
    display: block;
    @include responsive(desktop) {
      display: inline-block;
    }
    .slick-dots {
      margin-bottom: 30px;
      text-align: center;
      margin-top: 0;
      @include responsive(desktop) {
        text-align: left;
        margin-top: 30px;
      }
      li {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin: 0 7px;
        border-radius: 50%;
        background: #9b9b9b;
        text-indent: -10000px;
        cursor: pointer;
        font-size: 0;
        &.slick-active {
          background: #49c684;
          width: 12px;
          height: 12px;
          position: relative;
          top: -1px;
          @include responsive(desktop) {
            top: 0;
          }
        }
      }
    }
  }
}
.insight-slider {
  .slick-dots {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 0;
    @include responsive(desktop) {
      text-align: left;
    }
    li {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: 0 7px;
      border-radius: 50%;
      background: #9b9b9b;
      text-indent: -10000px;
      cursor: pointer;
      font-size: 0;
      vertical-align: sub;
      &.slick-active {
        background: #27c070;
        width: 12px;
        height: 12px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.customer-want {
  padding: 40px 0 20px;
  background-color: #f5f5f5;
  @include responsive(desktop) {
    padding: 65px 0 50px;
  }
  h2 {
    margin-bottom: 25px;
    @include responsive(desktop) {
      margin-bottom: 50px;
    }
  }
  &__box {
    position: relative;
    background: #fff;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 15px;
    padding: 120px 15px 30px;
    @include responsive(desktop) {
      padding: 150px 30px 30px;
      min-height: 419px;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 18px;
        line-height: 28px;
        font-family: $main-font;
      }
    }
    &:before {
      content: "";
      position: absolute;
      background: url(/images/ai-chatbots/want-group-icon.png) no-repeat 0 0;
      top: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 76px;
      height: 65px;
    }
  }
  .customer-want__box1 {
    &:before {
      background-position: 0 0;
    }
  }
  .customer-want__box2 {
    &:before {
      background-position: -76px 0;
    }
  }
  .customer-want__box3 {
    &:before {
      background-position: -152px 0;
    }
  }
  .btn_wrapper {
    margin-top: 25px;
    @include responsive(desktop) {
      margin-top: 50px;
    }
  }
}

.service-details.daas-service .service-wrap .brick.services-pan {
  display: block;
  @include responsive(desktop) {
    display: inline-block;
  }
}

/*daas*/
.daas-service {
  padding: 45px 0 20px;
  position: relative;
  @include responsive(desktop) {
    padding: 80px 0 45px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .service-wrap {
    .brick.services-pan {
      &:before {
        // content: "";
        // width: 63px;
        // height: 63px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 12%;
        // background-position: 0 0;
        display: none;
        // transform: translate(0);
        // @include responsive(desktop){
        //     top: 9%;
        // }
      }
    }
    .brick.services-pan__daas-service-01 {
      &:before {
        background: url(/images/daas/daas-service-1.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-02 {
      &:before {
        background: url(/images/daas/daas-service-2.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-03 {
      &:before {
        background: url(/images/daas/daas-service-3.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-04 {
      &:before {
        background: url(/images/daas/daas-service-4.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-05 {
      &:before {
        background: url(/images/daas/daas-service-5.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-06 {
      &:before {
        background: url(/images/daas/daas-service-6.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-07 {
      &:before {
        background: url(/images/daas/daas-service-7.png) no-repeat;
      }
    }
    .brick.services-pan__daas-service-08 {
      &:before {
        background: url(/images/daas/daas-service-8.png) no-repeat;
      }
    }
  }
}

/*Agencies*/
.o-two-col-section {
  padding: 50px 0;
  position: relative;
  @include responsive(desktop) {
    padding: 100px 0 60px;
  }
  h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 36px;
      line-height: 49px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-family: $main-font;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 30px;
      padding-top: 20px;
    }
  }
  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.o-two-col-section {
  padding: 45px 0 20px;
  background-size: 50% auto;

  /* media query for tablet potrait */
  @include responsive(desktop) {
    padding: 100px 0 60px;
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
    br {
      display: none;
    }
  }

  h4 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    @include responsive(desktop) {
      font-size: 24px;
      margin-bottom: 50px;
    }
    font-weight: 600;
  }

  &__left-panel {
    padding-bottom: 40px;
    text-align: center;
    overflow: hidden;
    padding: 0 30px;
    @include responsive(desktop) {
      text-align: left;
      padding: 0 15px;
    }

    /* icons */
    figure {
      width: 60px;
      height: 70px;
      position: relative;
      float: none;
      display: inline-block;

      /* mobile first for phablets */

      font-size: 37px;
      line-height: 63px;
      margin: 0 auto 5px 0;
      @include responsive(desktop) {
        float: left;
        margin: 0 30px 25px 0;
      }

      img {
        display: block;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    /* ~./icons */
    .content-section {
      width: 100%;
      text-align: center;
      /* mobile first for phablets */
      @include responsive(desktop) {
        float: left;
        width: 85%;
        text-align: left;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          text-align: left;
        }
      }
      h5 {
        font-size: 20px;
        line-height: 28px;
        @include responsive(desktop) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }

  &__right-panel {
    text-align: center;
    /* mobile first for tablet portrait */
    @include responsive(desktop) {
      text-align: left;
      display: block;
      padding-top: 50px;
    }

    img {
      margin-bottom: 20px;
      width: 120px;
      @include responsive(desktop) {
        margin-bottom: 50px;
        width: 100%;
      }
    }
    p {
      background: url(/images/agencies/icon-quote.svg) no-repeat 0 0;
      padding: 20px 0 0 10px;
      font-size: 16px;
      line-height: 28px;
      background-size: 50px;
      /* mobile first for phablets */
      @include responsive(desktop) {
        font-size: 34px;
        line-height: 63px;
        text-align: left;
        background-size: auto;
      }
    }
  }
}

.agencies-bnr {
  h1 {
    text-align: center;
    @include responsive(desktop) {
      font-size: 32px;
      line-height: 42px;
      text-transform: capitalize;
      text-align: left;
    }
  }
  h2 {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-weight: 600;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 33px;
      font-weight: 600;
      text-align: left;
    }
  }
  .btn_wrapper {
    a {
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
  .anchor-link {
    @include responsive(desktop) {
      text-align: left;
    }
  }
}

/*CTO*/
.o-we-love-intro {
  // padding: 40px 0 80px;
  text-align: center;
  padding: 40px 0 20px;
  /* media query for tablet potrait */
  @include responsive(desktop) {
    padding: 40px 0 50px;
  }
  .col-md-offset-1 {
    @include responsive(desktop) {
      margin: 0 auto;
    }
  }
  h1 {
    margin-bottom: 30px;
    text-transform: none;
    padding-top: 50px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    /*mobile first for tablet landscape & small desktop*/
    @include responsive(desktop) {
      font-size: 80px;
      line-height: 55px;
      letter-spacing: 2.5px;
      text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333,
        -1px -1px 0px #333;
      padding-top: 110px;
      font-weight: 700;
    }
  }

  h2 {
    margin-bottom: 30px;
    text-transform: none;
    line-height: 50px;

    /*mobile first for tablet landscape & small desktop*/
    @include responsive(desktop) {
      font-size: 80px;
      line-height: 55px;
      // letter-spacing: 2.5px;
      // text-shadow:1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
    }
  }

  h2.love-subheading {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;

    /*mobile first for tablet landscape & small desktop*/
    @include responsive(desktop) {
      font-size: 40px;
      line-height: 50px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 20px;
  }
  p {
    strong {
      font-weight: 600;
    }
  }

  &__other-text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 28px;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px;
      font-weight: 400;
    }
  }

  &__we-love-image {
    padding-bottom: 20px;
    @include responsive(desktop) {
      padding-bottom: 25px;
    }
  }
  .c-btn {
    margin-top: 20px;
  }
  .o-contact-form__heart {
    img {
      width: 40px;
      @include responsive(desktop) {
        width: 65px;
      }
    }
  }
}

.cto-info-p-heading {
  font-size: 23px;
  line-height: 35px;
}

.cto-last-solid-info {
  padding: 0 60px;
  margin-bottom: 40px;
}

.margin-top-bottom-10 {
  margin: 10px 0;
}
.cmo-service {
  .o-home-service_holder {
    h3 {
      color: #fff !important;
    }
  }
}

.what-get {
  padding-top: 50px;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 20px;
    }
  }
  h2 {
    font-size: 30px;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
  .cto-wrap {
    text-align: center;
    margin-bottom: 0;
    width: 100%;
    @include responsive(desktop) {
      margin-bottom: 35px;
    }
    h3 {
      font-size: 20px;
      @include responsive(desktop) {
        font-size: 24px;
      }
    }
    .cto-services {
      position: relative;
      height: auto;
      // padding: 120px 30px 0px;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      padding: 20px 0 0;
      @include responsive(desktop) {
        width: 29%;
        // padding: 120px 30px 30px;
        padding: 30px 15px 0;
        margin: 15px;
      }
      @include media-query(1200px) {
        width: 30%;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: #333;
        font-family: $main-font;
        font-weight: 500;
        margin-bottom: 0;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 32px;
          font-family: $main-font;
          margin-bottom: 25px;
        }
      }
      &:before {
        // content: '';
        // background: url(../images/cto/cto-sprite.png) no-repeat 0 0;
        // width: 95px;
        // height: 80px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 3%;
        // transform: translate(0);
        display: none;
        // @include responsive(desktop){
        //     top: 8%;
        // }
      }
    }
    .cto-service-01 {
      &:before {
        background-position: 0 0;
      }
    }
    .cto-service-02 {
      &:before {
        background-position: -87px 0;
      }
    }
    .cto-service-03 {
      &:before {
        background-position: -210px 0;
      }
    }
  }
}

.unlike-section {
  padding-top: 45px;
  padding-bottom: 20px;
  background: linear-gradient(
    285deg,
    #07b260 0%,
    #00517f 30%,
    #023e66 75%,
    #051b39 100%
  );
  @include responsive(desktop) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  h2 {
    color: #fff;
    // font-weight: 700;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .cto-competitor {
    text-align: center;
    &-service {
      position: relative;
      padding: 40px 15px 30px;
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      @include responsive(desktop) {
        width: 30%;
        height: 500px;
        padding: 50px 25px 30px;
        margin: 15px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        font-family: $main-font;
        margin-bottom: 0;
        @include responsive(desktop) {
          font-size: 15px;
          line-height: 24px;
          font-family: $main-font;
          margin-bottom: 25px;
        }
        @include media-query(1200px) {
          font-size: 17px;
          line-height: 27px;
        }
      }
      &:before {
        // content: '';
        // background: url(../images/cto/cto-sprite.png) no-repeat 0 0;
        // width: 95px;
        // height: 80px;
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 8%;
        display: none;
      }
    }
    &-service-01 {
      &:before {
        background-position: -315px 0px;
      }
    }
    &-service-02 {
      &:before {
        background-position: -420px 0px;
      }
    }
    &-service-03 {
      &:before {
        background-position: 14px -89px;
      }
    }
    h3 {
      font-size: 20px;
      @include responsive(desktop) {
        font-size: 24px;
      }
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 30px;
    }
  }
}

/*CMO*/
.number-section-cmo {
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .col-md-offset-1 {
    margin: 0 auto;
  }
  .cto-last-solid-info {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
    margin-top: 30px;
    line-height: 32px;
    padding: 0;
    @include responsive(desktop) {
      padding: 0 60px;
      font-size: 28px;
      line-height: 40px;
    }
  }
  .cmo-blocks {
    height: auto;
    @include responsive(desktop) {
      height: 135px;
    }
  }
  .dc-white-pan div {
    .body-text-medium {
      font-size: 16px;
      line-height: 28px;
      padding-top: 0;
      float: none;
      width: 100%;
      text-align: center;
      @include responsive(desktop) {
        font-size: 19px;
        line-height: 30px;
        float: right;
        width: 78%;
        text-align: left;
      }
    }
    span {
      float: none;
      display: block;
      text-align: center;
      @include responsive(desktop) {
        float: left;
        text-align: left;
      }
    }
  }
}

.cmo-services {
  padding-top: 45px;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    // font-weight: 700;
    @include responsive(desktop) {
      margin-bottom: 30px;
      font-size: 48px;
      line-height: 61px;
    }
  }
  &-wrap {
    @include responsive(desktop) {
      column-count: 3;
      column-gap: 27px;
      margin-bottom: 30px;
    }
    .cmo-services-block {
      display: inline-block;
      vertical-align: top;
      margin: 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      padding: 30px 30px 10px;
      position: relative;
      @include responsive(desktop) {
        // padding: 120px 30px 30px;
        padding: 50px 30px 15px;
      }
      p {
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        font-family: $main-font;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          font-family: $main-font;
        }
      }
      h3 {
        font-size: 20px;
        @include responsive(desktop) {
          font-size: 26px;
        }
      }
      &:before {
        // content: "";
        // position: absolute;
        // left: 50%;
        // top: 35px;
        // transform: translate(-50%, 0);
        // background: url(../images/cmo/cmo-sprite.png) no-repeat 0 0;
        // width: 84px;
        // height: 98px;
        display: none;
      }
      i {
        height: 65px;
        display: block;
        img {
          height: 100%;
        }
      }
    }
    .cmo-services-block-01 {
      &:before {
        // background-position: 11px 0;
      }
    }
    .cmo-services-block-02 {
      &:before {
        //background-position: -81px 0;
      }
    }
    .cmo-services-block-03 {
      &:before {
        //background-position: -188px 0;
      }
    }
    .cmo-services-block-04 {
      &:before {
        // background-position: -283px 0px;
      }
    }
    .cmo-services-block-05 {
      &:before {
        //background-position: -371px ​0px;
      }
    }
    .cmo-services-block-06 {
      &:before {
        background-position: -461px 0;
        top: 15px;
        @include responsive(desktop) {
          top: 35px;
        }
      }
    }
    .cmo-services-block-07 {
      &:before {
        // background-position: -554px ​0px;
      }
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop) {
      margin-top: 0;
    }
  }
}

.no-webp {
  .tl-pt:before {
    background-image: url(/images/common/tl-pt.png);
  }
}
.webp {
  .tl-pt:before {
    background-image: url(/images/webp/common/tl-pt.webp);
  }
}

.tl-pt {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: top left;
    width: 200px;
    height: 80px;
    background-size: 75%;
    top: 0;
    left: 0;
    @include responsive(desktop) {
      width: 517px;
      height: 206px;
      background-size: 100%;
    }
  }
}

.no-webp {
  .br-pt:after {
    background-image: url(/images/common/mb-br-pt.png);
    @include responsive(desktop) {
      background-image: url(/images/common/br-pt.png);
    }
  }
}
.webp {
  .br-pt:after {
    background-image: url(/images/common/mb-br-pt.webp);
    @include responsive(desktop) {
      background-image: url(/images/webp/common/br-pt.webp);
    }
  }
}
.br-pt {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 160px;
    @include responsive(desktop) {
      width: 200px;
    }
    height: 80px;
    // transform: rotate(180deg);
    bottom: 0;
    right: 0;
    background-size: 100%;
    @include responsive(desktop) {
      width: 500px;
      height: 240px;
      background-size: 100%;
    }
  }
}

.br-pt.hm-bnr {
  &:after {
    background-position: bottom right;
    @include responsive(desktop) {
      background-position: 160px 0;
    }
  }
}

.no-webp {
  .tr-pt:before {
    background-image: url(/images/common/mb-tr-pt.png);
    @include responsive(desktop) {
      background-image: url(/images/common/tr-pt.png);
    }
  }
}
.webp {
  .tr-pt:before {
    background-image: url(/images/common/mb-tr-pt.webp);
    @include responsive(desktop) {
      background-image: url(/images/webp/common/tr-pt.webp);
    }
  }
}
.tr-pt {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
    height: 80px;
    width: 200px;
    top: 0;
    right: 0;
    @include responsive(desktop) {
      display: block;
      background-size: 100%;
      width: 530px;
      height: 215px;
    }
  }
}
.no-webp {
  .bl-pt:after {
    background-image: url(/images/common/tr-pt.png);
  }
}
.webp {
  .bl-pt:after {
    background-image: url(/images/webp/common/tr-pt.webp);
  }
}
.bl-pt {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 75px;
    width: 200px;
    bottom: 0;
    left: 0;
    transform: rotate(-180deg);
    @include responsive(desktop) {
      display: block;
      background-size: 100%;
      width: 530px;
      height: 215px;
    }
  }
}

.no-webp {
  .mb-tl-pt:after {
    background-image: url(/images/common/mb-tl-pt.png);
  }
}
.webp {
  .mb-tl-pt:after {
    background-image: url(/images/common/mb-tl-pt.webp);
  }
}

.mb-tl-pt {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100px;
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    transform: translate(0);
    @include responsive(desktop) {
      display: none;
    }
  }
}

.no-webp {
  .mb-w-tr-pt:before {
    background-image: url(/images/common/mb-w-tr-pt.png);
  }
}
.webp {
  .mb-w-tr-pt:before {
    background-image: url(/images/common/mb-w-tr-pt.webp);
  }
}

.mb-w-tr-pt {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100px;
    background-size: 100%;
    background-position: top right;
    background-repeat: no-repeat;
    z-index: 99;
    @include responsive(desktop) {
      display: none;
    }
  }
}

.cheaper-batter-header .btn_wrapper.btn-center-block.mobile-off {
  width: auto;
  @include responsive(desktop) {
    width: 330px;
  }
}
.acnt-p {
  @include responsive(desktop) {
    font-size: 16px;
  }
}
.form-panel {
  .u-submit {
    @include responsive(desktop) {
      margin-top: 35px;
    }
  }
}
.img-cr-block {
  margin: 0 auto;
  display: block;
}
.ai-chatbots-hero {
  .container {
    padding-top: 100px;
  }
}

.new-srv-slider {
  .col-sm-6,
  .col-sm-6 {
    flex-wrap: wrap;
    flex: 50% !important;
    max-width: 50%;
  }
}

.o-apply-now {
  .anchor-link {
    max-width: 340px;
  }
}
.cs-slider-img {
  img {
    width: 100%;
    // @include responsive(desktop){
    //     height: 100%;
    // }
  }
}

.ai-bot {
  .insights-section .fi-pan a {
    min-height: 370px;
  }
}

.navbar-brand source {
  width: 80%;
  @include responsive(desktop) {
    width: auto;
  }
}
.navbar-brand img {
  width: 100%;
  // @include responsive(desktop){
  //     width: auto;
  // }
}

.border-bottom-lightgreen {
  border-bottom: 1px solid #dedede;
}

.o-home-cn-details.pad-bot-40 {
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding-bottom: 50px;
  }
}
.cstm-link-p {
  margin-top: 0;
  @include responsive(desktop) {
    margin-top: 5px;
  }
}
.splide__pagination {
  position: static !important;
  transform: translate(0);
  margin-top: 10px !important;
  @include responsive(desktop) {
    margin-top: 20px !important;
  }
  .splide__pagination__page {
    margin: 3px 5px;
  }
  .splide__pagination__page.is-active {
    transform: scale(1.4);
    background: #27c070;
  }
  // @include responsive(desktop){
  //     display: none;
  // }
}
.o-case-studies-slider {
  .splide__pagination {
    display: flex !important;
    margin-top: 30px;
  }
  .btn_wrapper.pad-top-50 {
    padding-top: 20px;
    @include responsive(desktop) {
      padding-top: 20px;
    }
  }
}

.crop-pad-top {
  margin-top: 10px;
  @include responsive(desktop) {
    margin-top: 0;
  }
}

.splide__pagination {
  width: 100%;
}
.insights-section {
  padding: 45px 0 50px !important;
  @include responsive(desktop) {
    padding: 70px 0 80px !important;
  }
  .btn_wrapper {
    padding-top: 0;
    @include responsive(desktop) {
      padding-top: 40px;
    }
  }
}

.Web-Application-Development {
  font-family: $main-font !important;
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    font-family: $main-font !important;
  }
  .service-details {
    h2 {
      font-size: 24px;
      @include responsive(desktop) {
        font-size: 48px;
      }
    }
  }
  .c-icon-desc-3-col-box {
    h2 {
      font-size: 24px;
      line-height: 32px;
      // font-weight: 700;
      @include responsive(desktop) {
        font-size: 48px;
        line-height: 61px;
      }
    }
  }
  .web-app-service .service-wrap {
    margin-bottom: 15px;
    @include responsive(desktop) {
      margin-bottom: 35px;
    }
  }
}
.Custom-Software-Development {
  .c-icon-desc-3-col-box {
    h2 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      @include responsive(desktop) {
        font-size: 48px;
        line-height: 61px;
      }
    }
  }
  .service-details {
    .btn_wrapper {
      margin-top: 15px;
      @include responsive(desktop) {
        margin-top: 0;
      }
    }
  }
}

.c-icon-desc-3-col-ccn {
  .row {
    .col-md-4 {
      &:nth-child(6) {
        border: none;
      }
    }
  }
}
.mobile-container {
  padding: 0 30px !important;
  @include responsive(desktop) {
    padding: 0 15px !important;
  }
}

.splide__pagination__page {
  background: #666565;
}
.pager_new {
  display: block;
  @include responsive(desktop) {
    display: none;
  }
}
.lSSlideOuter {
  padding-bottom: 25px;
  position: relative;
}
// .pager_new {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: 0 auto;
//     bottom: 33px;
//     width: 175px !important;
// }
// .lSSlideOuter .lSPager.lSGallery li {
//     width: 9px !important;
//     height: 9px;
//     margin: 0 7px;
//     border-radius: 50%;
//     background: #9b9b9b;
//     text-indent: -10000px;
//     cursor: pointer;
//     font-size: 0;
//     display: inline-block ;
//     i {
//         &::before {
//             display: none;
//         }
//     }
// }
// .lSSlideOuter .lSPager.lSGallery li.active, li.focus .dot_sliders {
//     width: 12px !important;
//     height: 12px;
//     margin: 0 7px;
//     border-radius: 50%;
//     background: #27c070;
//     text-indent: -10000px;
//     cursor: pointer;
//     font-size: 0;
//     display: inline-block;
//     &::before {
//         display: none;
//     }
// }

.fa-circle {
  &:before {
    font-family: "Font Awesome\ 5 Free";
  }
}
.lSSlideOuter.custom-color .lSPager.lSGallery li i {
  color: #9b9b9b !important;
}
.lSSlideOuter.custom-color .lSPager.lSGallery li.active i,
li.focus .dot_sliders {
  color: #27c070 !important;
}
.lSSlideOuter.custom-color .lSPager.lSGallery {
  margin-top: 75px !important;
}
.o-case-studies-slider {
  .slick-dots {
    margin-bottom: 15px;
  }
}

.career-bnr {
  padding-top: 120px;
  @include responsive(desktop) {
    padding-top: 80px;
  }
  img {
    margin: 0 auto;
    @include responsive(desktop) {
      margin: 0 auto;
    }
  }
  &.career-bnr-new {
    padding-top: 60px;
    @include responsive(desktop) {
      padding-top: 80px;
    }
  }
}
.service-wrap {
  .services-pan {
    p {
      font-family: $main-font !important;
      @include responsive(desktop) {
        font-family: $main-font !important;
      }
    }
  }
}
.c-icon-desc-3-col-ccn .row > div p {
  font-family: $main-font !important;
  @include responsive(desktop) {
    font-family: $main-font !important;
  }
}
.o-case-studies-slider .cs-slider .cs-slider-text h4,
.o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
.o-case-studies-slider .cs-slider .cs-slider-text p,
.o-case-studies-new .faq-holder .panel1 p,
.insights-section .fi-pan p,
.new-review-box h4,
.o-bottom-form .form-text p.sub-heading,
.o-bottom-form .form-text p,
.o-bottom-form .form-text ul li span {
  font-family: $main-font !important;
  @include responsive(desktop) {
    font-family: $main-font !important;
  }
}

.PHP {
  .skill-frame {
    background-color: transparent !important;
    @include responsive(desktop) {
      background-color: #f5f5f5 !important;
    }
  }
}

// .tech {
//     &:before {
//         display: none;
//         @include responsive(desktop){
//             display: block;
//         }
//     }
// }
.faq-section {
  padding-top: 45px !important;
  @include responsive(desktop) {
    padding: 65px 0 50px !important;
  }
  .container {
    position: relative;
    z-index: 9;
  }
}

.hm-page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  span,
  small,
  strong {
    font-family: $main-font !important;
  }
  .o-case-studies-slider .cs-slider .cs-slider-text h4,
  .o-case-studies-slider .cs-slider .cs-slider-text .cs-tech,
  .o-case-studies-slider .cs-slider .cs-slider-text p,
  .o-case-studies-new .faq-holder .panel1 p,
  .insights-section .fi-pan p,
  .new-review-box h4,
  .o-bottom-form .form-text p.sub-heading,
  .o-bottom-form .form-text p,
  .o-bottom-form .form-text ul li span {
    font-family: $main-font !important;
  }
}

.Custom-Software-Development {
  .btn_wrapper {
    padding-top: 0;
    @include responsive(desktop) {
      padding-top: 0;
    }
  }
  .wb-bnr {
    padding-bottom: 20px !important;
    @include responsive(desktop) {
      padding-bottom: 50px !important;
    }
  }
  .service-details {
    h2 {
      font-size: 24px;
      @include responsive(desktop) {
        font-size: 48px;
      }
    }
  }
  .o-industrial-experience {
    .subheading {
      @include responsive(desktop) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

.Work {
  .cstm-link-p {
    text-align: center;
    margin-bottom: 30px;
    @include responsive(desktop) {
      text-align: inherit;
      margin-bottom: 0;
    }
  }
  .u-pad-top-30 {
    padding-top: 5px;
    @include responsive(desktop) {
      padding-top: 30px;
    }
  }
  .work-bnr h1 {
    padding-top: 25px;
    @include responsive(desktop) {
      padding-top: 60px;
    }
  }
  .o-two-col-section {
    @include responsive(desktop) {
      padding: 70px 0 50px;
    }
    p {
      @include responsive(desktop) {
        margin-top: 0;
      }
    }
  }
  .key-section {
    @include responsive(desktop) {
      padding: 70px 0 45px;
    }
    h2 {
      @include responsive(desktop) {
        margin-bottom: 10px;
      }
    }
    .kt-button-holder {
      p {
        padding: 0;
        margin-bottom: 23px;
        @include responsive(desktop) {
          padding: 0 0 0;
          margin-bottom: 30px;
        }
      }
    }
  }
  .key-traits {
    padding: 45px 0 5px;
    @include responsive(desktop) {
      padding: 70px 0 50px;
    }
    .style_prevu_kit {
      p {
        margin-bottom: 0;
        @include responsive(desktop) {
          margin-bottom: 25px;
        }
      }
    }
  }
  .o-two-col-section__why-choose-us-mod {
    @include responsive(desktop) {
      padding: 70px 0 70px;
    }
    p {
      @include responsive(desktop) {
        font-family: $main-font;
        padding-top: 0;
      }
    }
  }
  .o-two-col-section__choosing-steps {
    @include responsive(desktop) {
      padding: 70px 0 25px;
    }
  }
}

.eCommerce {
  .o-skills-hero {
    padding-top: 100px;
    padding-bottom: 20px !important;
    @include responsive(desktop) {
      padding-bottom: 0 !important;
      padding-top: 55px;
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0;
      @include responsive(desktop) {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 30px;
      }
    }
    .mb-h1 {
      margin-bottom: 25px;
    }
  }
  .green-head-ribbon span {
    &:first-letter {
      text-transform: none;
    }
  }
  .schedule-box {
    height: 100%;
  }
}

.CMS {
  .o-skills-hero {
    padding-top: 60px;
    padding-bottom: 20px !important;
    @include responsive(desktop) {
      padding-top: 90px;
      padding-bottom: 50px !important;
    }
    h1 {
      margin-bottom: 0;
      @include responsive(desktop) {
        margin-bottom: 15px;
      }
    }
  }
  .service-wrap {
    .services-pan {
      p {
        margin-bottom: 0;
        @include responsive(desktop) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.cmo-service {
  padding-top: 20px;
  @include responsive(desktop) {
    padding-top: 25px;
  }
  .home-link {
    @include responsive(desktop) {
      padding: 20px 0 35px;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
  .o-home-service_holder {
    margin-top: 0;
  }
  .home-link {
    padding-top: 10px;
    @include responsive(desktop) {
      padding-top: 25px;
    }
  }
  .btn_wrapper {
    padding-top: 20px;
    margin-bottom: 10px;
    @include responsive(desktop) {
      padding-top: 0;
      margin-bottom: 0;
    }
  }
  .anchor-link {
    @include responsive(desktop) {
      margin-top: 10px;
    }
  }
}

.Data {
  .o-skills-hero {
    @include responsive(desktop) {
      padding: 115px 20px 50px !important;
    }
  }
  .btn_wrapper {
    padding-top: 0;
    @include responsive(desktop) {
      padding-top: 15px;
    }
  }
  .c-icon-desc-3-col-box h2 {
    font-size: 24px;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
}
.Magento {
  .skill-service {
    h2 {
      color: #fff !important;
      margin-bottom: 15px;
      @include responsive(desktop) {
        color: #fff !important;
      }
    }
  }
}

.DevOps {
  .c-icon-desc-3-col-box h2 {
    font-size: 24px;
    line-height: 32px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .small-h1 {
    h1 {
      @include responsive(desktop) {
        margin-bottom: 15px;
      }
    }
    .bnr-ofr-text {
      line-height: 24px;
    }
  }
  // .o-skills-hero {
  //     .btn_wrapper {
  //         @include responsive(desktop){
  //             padding-top: 30px;
  //         }
  //     }
  // }
}

.agencies-bnr {
  padding-bottom: 45px;
  @include responsive(desktop) {
    padding: 115px 20px 50px !important;
  }
  h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 0;
    @include responsive(desktop) {
      font-size: 32px;
      line-height: 42px;
      padding-bottom: 30px;
    }
  }
}

.agen-why {
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  .service-wrap {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    @include responsive(desktop) {
      margin-bottom: 35px;
    }
    .brick.services-pan {
      padding: 30px 15px 30px;
      width: 100%;
      height: auto !important;
      @include responsive(desktop) {
        padding: 50px 30px 30px;
        width: 46% !important;
      }
      @include media-query(1200px) {
        width: 47% !important;
      }
      p {
        margin-bottom: 0;
        @include responsive(desktop) {
          margin-bottom: 25px;
          font-size: 19px;
          line-height: 30px;
        }
      }
      i {
        height: 65px !important;
      }
    }
  }
}
.Testing,
.AI,
.Outsourced,
.CMS,
.eCommerce {
  .c-icon-desc-3-col-box {
    h2 {
      font-size: 24px;
      line-height: 32px;
      @include responsive(desktop) {
        font-size: 48px;
        line-height: 61px;
      }
    }
  }
}

ul.srv-bnr-logo {
  svg {
    height: 100%;
    width: 100%;
  }
  //  &:last-child {
  //      display: none;
  //     @include responsive(desktop){
  //         display: block;
  //     }
  //  }
}

// .AI {
//     .o-skills-hero {
//         .btn_wrapper {
//             @include responsive(desktop){
//                 padding-top: 15px;
//             }
//         }
//         h2 {
//             @include responsive(desktop){
//                 padding: 20px 0 20px;
//             }
//         }
//     }
// }

.cmn-service-bnr-mod {
  padding-top: 95px;
  padding-bottom: 20px;
  @include responsive(desktop) {
    padding: 115px 20px 0 !important;
  }
  h1 {
    @include responsive(desktop) {
      // font-size: 55px;
      font-size: 48px;
      line-height: 65px;
      margin-top: 0;
    }
  }
  h2 {
    @include responsive(desktop) {
      font-size: 24px;
      font-weight: 600 !important;
    }
  }
  .mb-h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    margin-top: 15px;
  }
  .mb-h2 {
    font-size: 22px;
    line-height: 30px;
    padding: 0;
    margin-top: 25px !important;
  }
  .mb-p {
    color: #b7b7b7;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: 700;
    a {
      text-decoration: underline;
      color: #b7b7b7;
    }
  }
  .dd-mb-p {
    margin-bottom: 30px;
  }
}

.CMS {
  .cmn-service-bnr-mod h1 {
    margin-top: 25px;
  }
}
.DevOps,
.Testing {
  .o-skills-hero h1 {
    margin-top: 25px;
  }
}

.cms-can {
  .service-wrap {
    .services-pan {
      i {
        width: auto;
        height: 75px !important;
        margin: 0 auto;
      }
    }
  }
}
.Outsourced {
  .o-skills-hero {
    @include responsive(desktop) {
      padding: 115px 20px 50px !important;
    }
  }
}

.outsource-product,
.testing-qa {
  .service-wrap {
    .brick.services-pan {
      @include responsive(desktop) {
        padding: 50px 30px 50px;
      }
    }
  }
}

.cms-box {
  @include responsive(desktop) {
    min-height: 435px;
  }
}

.e-commerce-why {
  .service-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .brick.services-pan {
      padding: 30px 25px 5px;
      @include responsive(desktop) {
        padding: 50px 25px 30px;
      }
    }
  }
  .cstm-link-p a {
    color: #4a90e2 !important;
    @include responsive(desktop) {
      // color: #fff !important;
    }
  }
}

.gradient-blue-green-2 {
  @include responsive(desktop) {
    background: rgb(7, 178, 96);
    background: -moz-linear-gradient(
      141deg,
      rgba(7, 178, 96, 1) 0%,
      rgba(0, 81, 127, 1) 37%,
      rgba(2, 62, 102, 1) 100%
    );
    background: -webkit-linear-gradient(
      141deg,
      rgba(7, 178, 96, 1) 0%,
      rgba(0, 81, 127, 1) 37%,
      rgba(2, 62, 102, 1) 100%
    );
    background: linear-gradient(
      141deg,
      rgba(7, 178, 96, 1) 0%,
      rgba(0, 81, 127, 1) 37%,
      rgba(2, 62, 102, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#07b260",endColorstr="#023e66",GradientType=1);
  }
}

#solution-container {
  padding: 40px 0 35px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
}
.CMO {
  .number-section .section-para {
    font-family: $main-font;
  }
}

.o-two-col-section-agen {
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  .cstm-link-p {
    padding-top: 0;
  }
  .content-section {
    p {
      @include responsive(desktop) {
        padding-top: 5px;
      }
    }
  }
}

.daas-cmn {
  .row > div {
    padding: 0;
    @include responsive(desktop) {
      padding: 0 30px 0 15px;
      margin: 0 0 30px;
    }
    .daas-cmn-box {
      display: flex;
      &:before {
        display: none;
      }
      &-text {
        &:before {
          display: none;
        }
      }
      i {
        display: block;
        flex: 0 0 70px;
        margin-right: 20px;
      }
      h3 {
        font-size: 20px;
        @include responsive(desktop) {
          font-size: 26px;
        }
      }
    }
  }
}
.daas-how {
  padding-top: 45px;
  @include responsive(desktop) {
    padding: 65px 0 50px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
  .cstm-link-p {
    a {
      color: #fff !important;
      // @include responsive(desktop){
      //     color: #4a90e2!important;
      // }
    }
  }
}

.cto-wrap i {
  height: 70px;
  display: flex;
  justify-content: center;
}

.first-letter-small {
  &:first-letter {
    text-transform: lowercase;
  }
}

.apart-p {
  font-size: 16px;
  line-height: 24px;
  @include responsive(desktop) {
    font-size: 19px;
    line-height: 32px;
  }
}

.o-skills-hero__schdule .e-com-head {
  text-transform: none !important;
}
.Mobile {
  .btn-orange {
    text-transform: uppercase;
  }
  h2 {
    span {
      display: inline;
      // @include responsive(desktop){
      //     display: inline;
      // }
    }
  }
}

.cust-app {
  margin-top: 15px;
  @include responsive(desktop) {
    margin-top: 0;
  }
}

/*New banner css*/
.header_right_pan {
  position: relative;
}
.floating-line {
  background: #2c8b78;
  position: absolute;
  left: 15px;
  right: 15px;
  top: -50px;
  padding: 13px;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
}
ul.ul-grp-logo {
  margin-top: 30px !important;
}
.o-skills-hero__schdule {
  .schedule-box {
    // @include responsive(desktop){
    //     margin-top: -50px;
    // }
    &-top {
      padding: 10px 15px 0;
      background: url(/images/common/mid.png) repeat-y;
      background-position: 0 0;
      background-size: 100%;
      &-text {
        img {
          width: 69px;
          height: 69px;
        }
        h2 {
          text-align: left;
          font-size: 24px !important;
          line-height: 35px;
          padding-top: 0;
          font-weight: 600 !important;
          @include media-query(992px) {
            font-size: 20px !important;
            line-height: 28px;
          }
          @include media-query(1200px) {
            font-size: 24px !important;
            line-height: 35px;
          }
        }
      }
      .btn-orange {
        padding: 14px 15px;
        max-width: 340px !important;
        font-weight: 700;
      }
    }
  }
}
.green-head-ribbon-mod {
  @include responsive(desktop) {
    margin-top: 20px;
  }
  span {
    width: 90%;
    font-family: $main-font !important;
    @include responsive(desktop) {
      width: 281px;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      padding: 15px;
      font-weight: 700;
    }
  }
}

.schedule-box-top-img {
  background: url(/images/common/top.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 29px;
}
.schedule-box-bottom-img {
  background: url(/images/common/bottom.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
  height: 45px;
}

.schedule-box-mod {
  height: 700px;
}

.web-app-service.web-app-service-mod .service-wrap .brick.services-pan {
  // @include media-query(992px) {
  //     height: 495px;
  // }
  // @include media-query(1200px) {
  //     height: 660px;
  // }
}

ul.small-skill-page-mod {
  width: 80% !important;
  margin: 0 auto;
  @include media-query(992px) {
    margin: 0;
  }
}

.o-skills-hero-modified {
  @include responsive(desktop) {
    padding: 115px 20px 0 !important;
  }
}
.with-cn-h2 {
  margin-bottom: 25px;
}

.staff-aug h1 {
  margin-bottom: 25px;
}

.Analytics .web-app-service .service-wrap .brick.services-pan {
  height: auto;
}
.Analytics .web-app-service .service-wrap .brick.services-pan a {
  position: static;
  transform: translate(0);
}
.Graphic {
  .web-app-service .service-wrap .brick.services-pan {
    height: auto;
  }
}
.Graphic .web-app-service .service-wrap .brick.services-pan a {
  position: static;
  transform: translate(0);
}

.is-section {
  h3 {
    margin-bottom: 30px;
  }
}
.services-pan__codeigniter {
  padding-top: 50px;
}
.services-pan__codeigniter:before {
  display: none;
}

.details-point {
  margin-bottom: 25px;
  span {
    font-size: 16px;
    padding-left: 30px;
    background: url(/images/common/icon-bullet-dark.svg) 0 50% no-repeat;
    margin: 0 15px;
  }
}
.about-details-para-mod {
  width: 100% !important;
  h3 {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

.ppc-service {
  .service-wrap .brick.services-pan {
    height: auto;
    a {
      position: static;
      transform: translate(0);
    }
  }
}

.solution-left-mod {
  ul {
    ul {
      padding: 25px 0 5px;
    }
    li:last-child {
      margin-bottom: 25px;
    }
  }
}

.e-commerce-plus {
  h2 {
    color: #333;
  }
}

.rm-bnr {
  h1 {
    margin-bottom: 30px !important;
  }
}

.e-commerce-why-mod {
  .cstm-link-p a {
    color: #4a90e2 !important;
  }
}

.expert-seo {
  &-p {
    font-size: 18px;
  }
  h4 {
    margin-bottom: 30px;
  }
}

.schedule-box {
  margin-top: -50px;
}

.hire-designer {
  .schedule-box {
    margin-top: 0;
  }
}

.cms-bnr-award {
  ul.srv-bnr-logo li {
    flex: 0 0 20%;
    @include media-query(992px) {
      flex: 0 0 200px;
    }
  }
}

.Web-Application-Development,
.Custom-Software-Development,
.eCommerce,
.Dedicated,
.Analytics,
.hybrid,
.Mobile,
.hire-designer,
.Backend-Development,
.front-end-development {
  .o-skills-hero {
    h1 {
      margin-top: 25px;
    }
  }
}

.Outsourced,
.CMS,
.Chatbots {
  .o-skills-hero {
    h1 {
      margin-top: 25px;
    }
  }
}

.service-pages {
  .o-skills-hero {
    @include media-query(992px) {
      padding: 65px 20px 0 !important;
    }
  }
  .schedule-box {
    margin-top: 0;
    height: 100%;
  }
  .header_left_pan {
    img {
      @include media-query(992px) {
        margin-top: 40px;
      }
    }
    @include media-query(992px) {
      padding-right: 20px;
    }
    .skill-page-mod {
      img {
        @include media-query(992px) {
          padding: 20px 0 20px;
        }
        margin-top: 0;
      }
    }
  }
}

.panel1 {
  ul li {
    font-family: Montserrat, sans-serif;
  }
}

.font-size-50 {
  font-size: 30px;
  @include media-query(992px) {
    font-size: 50px !important;
  }
}
// .Backend-Development {
//     .o-skills-hero__schdule {
//         .schedule-box-top-text {
//             .font-size-24-mod {
//                 font-size: 24px !important;
//             }
//         }
//     }
// }

.aprch-blockchain {
  .masonry .brick {
    @include media-query(992px) {
      flex: 1;
    }
  }
}

.ind-off-section {
  .service-wrap {
    @include media-query(992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .brick.services-pan {
      @include media-query(992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px 30px 50px;
      }
    }
  }
}

.ind-off-section-com {
  .service-wrap {
    @include media-query(992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .brick.services-pan {
      @include media-query(992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px 30px 50px;
        align-content: baseline;
      }
    }
  }
}

.our-service-bnr-p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px;
}

.our-service-bnr-h1 {
  @include media-query(992px) {
    padding-bottom: 10px !important;
  }
}
.our-service-bnr-a {
  @include media-query(992px) {
    margin-bottom: 70px;
  }
}

.cms-dev-service {
  a {
    display: none;
    @include media-query(992px) {
      display: block;
    }
  }
}

/*What we test*/
.what-test-section {
  padding: 50px 0 25px;
  @include media-query(992px) {
    padding: 80px 0 70px;
  }
  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 36px;
    @include media-query(992px) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 60px;
    }
    br {
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  .what-test-section__para {
    margin-bottom: 20px;
    font-weight: 500;
    @include media-query(992px) {
      font-size: 21px;
      line-height: 31px;
      margin-bottom: 30px;
    }
  }
  ul {
    margin-bottom: 0;
    @include media-query(992px) {
      margin-bottom: 70px;
    }
    li {
      font-size: 18px;
      line-height: 40px;
      position: relative;
      padding-left: 30px;
      &:before {
        content: "";
        position: absolute;
        background: url(/images/common/green-tick.svg);
        height: 19px;
        width: 19px;
        top: 10px;
        left: 0;
      }

      a {
        color: #333;
        font-family: Poppins;
      }
    }
  }
  .last-ul {
    margin-bottom: 30px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
  }
  .row {
    @include media-query(992px) {
      justify-content: space-between;
    }
    .col-lg-4 {
      @include media-query(992px) {
        flex: inherit;
        width: auto;
      }
    }
  }
  &--industries-we-serve{
    ul {
      li{
        font-size: map-get($map: $values, $key: 17);
      }
    }
  }
}

.what-test-section-mod {
  .number-div {
    position: relative;
    flex: 0 0 90px;
    &:before {
      content: "";
      position: absolute;
      background: #dfdfdf;
      width: 6px;
      height: 97%;
      top: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  .h2-mb {
    margin-bottom: 50px;
  }
  .cmn-heading-para {
    max-width: 700px;
    margin: 0 auto 50px;
  }
  h3 {
    font-size: 28px;
    line-height: 26px;
    flex: 0 0 auto;
    padding-right: 20px;
    font-weight: 400;
    @include media-query(768px) {
      font-size: 36px;
      line-height: 32px;
    }
  }
  h4 {
    font-size: 18px;
    margin-left: 15px;
    margin-bottom: 0;
    @include media-query(540px) {
      font-size: 20px;
    }
    @include media-query(992px) {
      font-size: 24px;
      margin-left: 0;
      margin-bottom: 0.5rem;
    }
  }
  p {
    font-size: 16px;
    line-height: 22px;
    @include media-query(768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .spiral-col-left {
    flex: 1;
    @include media-query(992px) {
      flex: 0 0 38%;
      width: 38%;
    }
    &-box {
      position: relative;
      @include media-query(992px) {
        margin-bottom: 100px;
      }
      .triangle-class {
        position: absolute;
        top: 35px;
        left: -14px;
        @include media-query(992px) {
          right: 0;
          top: 60px;
          left: inherit;
          right: 0;
        }
        &:before {
          content: "";
          position: absolute;
          z-index: 9;
          // background: #fff;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 15px solid #fff;
          border-bottom: 10px solid transparent;
          @include media-query(992px) {
            border-top: 18px solid transparent;
            border-left: 28px solid #fff;
            border-bottom: 18px solid transparent;
            border-right: 0;
          }
        }
        &:after {
          content: "";
          position: absolute;
          // background: #fff;
          width: 0;
          height: 0;
          border-top: 11px solid transparent;
          border-right: 15px solid #e3e3e3;
          border-bottom: 11px solid transparent;
          top: -1px;
          left: -1px;
          @include media-query(992px) {
            border-top: 20px solid transparent;
            border-left: 30px solid #e3e3e3;
            border-bottom: 20px solid transparent;
            border-right: 0;
            top: -2px;
            left: inherit;
          }
        }
      }
    }
  }
  .spiral-col-right {
    flex: 0 0 80%;
    width: 80%;
    @include media-query(992px) {
      flex: 0 0 38%;
      width: 38%;
    }
    &-box {
      position: relative;
      @include media-query(992px) {
        margin-top: 120px;
      }
      .triangle-class {
        position: absolute;
        top: 35px;
        left: -14px;
        @include media-query(992px) {
          right: inherit;
          top: 60px;
          left: -28px;
        }
        &:before {
          content: "";
          position: absolute;
          z-index: 9;
          // background: #fff;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 15px solid #fff;
          border-bottom: 10px solid transparent;
          @include media-query(992px) {
            border-top: 18px solid transparent;
            border-right: 28px solid #fff;
            border-bottom: 18px solid transparent;
          }
        }
        &:after {
          content: "";
          position: absolute;
          // background: #fff;
          width: 0;
          height: 0;
          border-top: 11px solid transparent;
          border-right: 16px solid #e3e3e3;
          border-bottom: 11px solid transparent;
          top: -1px;
          left: -2px;
          @include media-query(992px) {
            border-top: 20px solid transparent;
            border-right: 30px solid #e3e3e3;
            border-bottom: 20px solid transparent;
            top: -2px;
            left: -2px;
          }
        }
      }
    }
  }
  .spiral-col-mid {
    flex: 0 0 22%;
    width: 22%;
    text-align: center;
    .spiral-col-mid img {
      width: 200px;
    }
    display: none;
    @include media-query(992px) {
      display: block;
    }
  }
  .spiral-col-box {
    background: #fff;
    padding: 30px 25px 30px 25px;
    border: 1px solid #e3e3e3;
    @include media-query(768px) {
      padding: 30px 30px 30px 35px;
    }
    border-radius: 10px;
    position: relative;
    &:before {
      @include media-query(992px) {
        display: block;
      }
    }
    &:after {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      top: 20px;
      left: -82px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 21px;
      @include media-query(992px) {
        display: none;
      }
    }
    p {
      margin-bottom: 0;
      @include media-query(768px) {
        margin-bottom: 25px;
      }
    }
    &-head {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @include media-query(992px) {
        margin-bottom: 15px;
        align-items: flex-start;
      }
      img {
        width: 50px;
      }
    }
    &1 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-bottom: 160px;
      }
      &:after {
        content: "01";
        background: #2c4d82;
      }
    }
    &1-mod {
      @include media-query(992px) {
        margin-bottom: 100px;
      }
    }
    &2 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-bottom: 160px;
      }
      &:after {
        content: "03";
        background: #1b89af;
      }
    }
    &3 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-bottom: 160px;
      }
      &:after {
        content: "05";
        background: #28c89a;
      }
    }
    &4 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-top: 230px;
      }
      &:after {
        content: "02";
        background: #036497;
      }
    }
    &5 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-top: 100px;
      }
      &:after {
        content: "04";
        background: #2bbdc3;
      }
    }
    &5-mod {
      @include media-query(992px) {
        margin-top: 175px;
      }
    }
    &6 {
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-bottom: 40px;
        margin-top: 130px;
      }
      &:after {
        content: "06";
        background: #36e079;
      }
    }
    &7 {
      margin-bottom: 30px;
      &:after {
        content: "07";
        background: #71F99B;
      }
    }

    .color-1 {
      color: #2c4d82;
    }
    .color-2 {
      color: #1b89af;
    }
    .color-3 {
      color: #28c89a;
    }
    .color-4 {
      color: #036497;
    }
    .color-5 {
      color: #2bbdc3;
    }
    .color-6 {
      color: #36e079;
    }
  }
}

.black-font-color {
  color: #333 !important;
}

.cmn-logo-fix {
  .o-skills-hero {
    h1 {
      margin-top: 25px;
    }
  }
}

.home-ind-off-section .service-wrap .brick.services-pan {
  @include media-query(992px) {
    align-content: flex-start;
    padding: 50px 30px 100px;
  }
  a {
    @include media-query(992px) {
      position: absolute;
      bottom: 50px;
    }
  }
  h3 {
    flex: 0 0 100%;
  }
}

.no-webp {
    .o-bottom-form .form-text ul li {
        background-image: url(/images/common/common-sprite.png);
    }
}
.webp {
    .o-bottom-form .form-text ul li {
        background-image: url(/images/webp/common/common-sprite.webp);
    }
}
.o-bottom-form{
    background: linear-gradient(
135deg
, #00b35c 0%, #0b8a8a 47%, #004475 100%);
position: relative;
    @include responsive(desktop){
        overflow: hidden;
    }
    .container {
        position: relative;
        z-index: 9;
    }
    .form-text{
        background-color: #1D2942;
        width: 100%;
        float: none;
        color: $white-color;
        text-align: left;
        padding: 40px 30% 15px 35px;
        position: relative;

        @include responsive(desktop){
            width: 50%;
            float: left;
            padding: 70px 60px;
            margin-top: 0;
        }
        &:before{
            content: '';
            background: #1D2942;
            width: 25px;
            height: 25px;
            transform: rotate(-45deg);
            position: absolute;
            bottom: -12px;
            left: 35px;
            @include responsive(desktop){
                display: none;
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: 45px;
            right: 5px;
            background: url(/images/common/b-form-envelop.webp) no-repeat center center / 90%;
            width: 159px;
            height: 159px;
            @include responsive(desktop){
                display: none;
            }
        }
        h2{
            font-size: 24px;
            line-height: 36px;
            font-weight: 700;
            // padding-bottom: 20px;
            color: $white-color;
            @include responsive(desktop){
                font-size: 36px ;
                line-height: 53px;
            }
        }
        .form-bot-text{
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
        ul{
            display: none;
            @include responsive(desktop){
                display: block;
            }
            li{
                display: inline-block;
                // margin-right: 10px;
                text-align: center;
                padding-top: 70px;
                width: 72px;
                background-repeat: no-repeat;
                background-position: 50% 0;
                &.am-1 {
                    background-position: 0 -570px;
                }
                &.am-2 {
                    background-position: -73px -570px;
                }
                &.am-3 {
                    background-position: -149px -570px;
                }
                &.am-4 {
                    background-position: -228px -570px;
                }
                &.am-5 {
                    background-image: url(/images/common/ray.png);
                    background-size: 100%;
                }
            }
        }
        p{
            color: $white-color;
            font-family: $main-font;
            font-size: 13px;
        }
        p.sub-heading{
            font-size: 16px;
            line-height: 23px;
            font-family: $main-font;
            @include responsive(desktop){
                font-size: 19px;
                line-height: 32px;
                font-family: $main-font;
            }
        }
        p.subheading-1{
            font-size: 24px;
            margin: 0 0 10px 0;
            font-family: $main-font;
        }
        .form-bot-text{
            border-top: 1px solid #364462;
            margin-top: 60px;
            padding-top: 60px;
        }
    }
    .form-panel{
        width: 100%;
        float: none;
        padding: 20px 20px 40px;
        background-color: $white-color;
        @include responsive(desktop){
            width: 50%;
            float: left;
            padding: 60px;
        }
    }
}

.common-input {
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    border:0;
    border-bottom: 1px solid #333;
    background-color: transparent;
}

.u-name {
    background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
}
.u-email {
    background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
}
.u-phone {
    background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
}
.u-company {
    background: url(/images/common/form-icon-4.png) 0 50% no-repeat;
}
.u-message {
    background: url(/images/common/form-icon-5.png) 0 20px no-repeat;
}

.attach {
    background: url(/images/common/form-icon-6.png) 0 20px no-repeat;
    padding: 20px 20px 20px 40px;
}


.webp {
    .hm-page {
        .mb-w-tr-pt {
            &:before {
                display: none;
                @include responsive(desktop){
                    display: block;
                }
            }
        }
    }
}

#bottom-form {
    ::-webkit-input-placeholder {
      color: #3b3b3b;
      opacity: 1;
      font-weight: 400;
    }
  }

.form-left{
    width: 100%;
    float: left;
    padding: 30px;
    margin-top: 0;
    background-color: #1d2942;
    color: #fff;
    @include responsive(desktop){
        width: 50%;
        padding: 70px 60px 46px;
    }
}

.form-left ul{
    padding-bottom: 0;
    margin-bottom: 0;
    @include media-query(768px) {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #515151;
    }
}
.form-left ul li{
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    @include responsive(desktop){
        margin-bottom: 0;
    }
    .bottom-form-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        @include media-query(768px) {
            width: 45px;
            height: 45px;
        }
        @include media-query(992px) {
            width: 80px;
            height: 80px;
        }
    }
}
.form-left ul li span{
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    @include media-query(768px) {
        font-size: 12px;
    }
    @include media-query(992px) {
        font-size: 15px;
    }
}
.form-left ul li img{
    filter: grayscale(100%);
    width: 100%;
    // @include responsive(desktop){
    //     width: 100%;
    // }
}

.form-left p{
    font-size: 16px;
    @include media-query(992px) {
        font-size: 14px;
    }
}
.form-bottom-text{

}
.form-bottom-sec{
    display: inline-block;
    width: 58%;
    font-size: 12px;
    line-height: 18px;
    vertical-align: top;
    margin-bottom: 32px;
    @include media-query(992px) {
        font-size: 14px;
        line-height: 20px;
    }
    @include media-query(1200px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.form-bottom-sec:nth-child(1){
    width: 40%;
}
.form-bottom-sec:nth-child(3){
    width: 40%;
}

.form-bottom-head{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    display: block;
    vertical-align: top;

    margin-bottom: 5px;
    color: #fff;
    @include media-query(992px) {
        font-size: 20px;
        line-height: 32px;
    }
    @include media-query(1200px) {
        font-size: 26px;
        line-height: 32px;
    }
}
.form-icon{
    margin-right: 15px;
    margin-bottom: 10px;
    width: 54px;
    display: inline-block;
    vertical-align: top;
    @include media-query(768px) {
        width: 40px;
        margin-right: 10px;
    }
    @include media-query(992px) {
        width: 35px;
        margin-bottom: 0;
        text-align: center;
        margin-right: 20px;
    }
    @include media-query(1200px) {
        width: 54px;
    }
}
.form-txt-pan{
    display: inline-block;
    width: 100%;
    @include media-query(420px) {
        width: 65%;
    }
    @include responsive(desktop){
        width: 70%;
    }
    color: #B9C6D0;
    font-weight: 500;
}
.form-bottom-sec:nth-child(1),
.form-bottom-sec:nth-child(3){
    .form-txt-pan{
        width: 60%;
        display: block;
        @include media-query(420px) {
            width: 40%;
            display: inherit;
        }
        @include media-query(1200px) {
            width: 55%;
        }
    }
}

.o-bottom-form h2{
    padding: 40px 30px 20px;
    @include responsive(desktop){
        padding:0;
    }
}

.form-sub-heading{
    font-size: 18px;
    line-height: 24px;
    padding: 0 30px;
    font-weight: 600;
    @include responsive(desktop){
        font-size: 30px;
        line-height: 40px;
    }
    br{
        display: none;
        @include responsive(desktop){
            display: block;
        }
    }
}

// #bottom-form .d-flex{
//         display: block!important;
//         @include responsive(desktop){
//             display: flex!important;
//         }
// }

.confidential-text-wrap
{
    margin-top: -10px;
    ul{
        display: flex;
        justify-content: space-evenly;
        li{
            color: #ffffff;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: center;
            width: 50%;
            position: relative;
            padding: 0px 7px;
            justify-content: flex-end;
            &:after{
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: #6eb9c8;
                right: 0px;
                top: 0px;
            }
            &:last-child{
                justify-content: flex-start;
                &:after{
                    display: none;
                }
            }
            img{
                width: 12px;
                height: 12px;
                margin-right: 6px;
            }
        }
    }
    &__inverse
    {
        margin-top: 15px;
        text-align: center;
        ul{
            display: inline-flex;
            margin-top: 5px;
            li{
                color: #000000;
                font-size: 15px;
                width: auto;
                padding: 2px 10px;
                @include media-query(992px) {
                    padding: 2px 10px;
                    font-size: 14px;
                }
                &:first-child {
                    padding-left: 0;
                    @include media-query(992px) {
                        padding: 0px 10px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                    @include media-query(992px) {
                        padding: 0px 10px;
                    }
                }
                &:after{
                    background-color: #D8D8D8;
                }
            }
        }
    }
}

.o-contact-form__attachement-text {
    font-size: 16px;
    @include media-query(992px) {
        font-size: 13px;
    }
}
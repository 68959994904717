.guide-home-banner {
    $guide-home-banner: &;
    &__image {
        background: url(/images/guides/banner-bg.jpg) no-repeat center center / cover;
        position: relative;
        padding: 30px 0 30px;
        @include media-query(992px) {
            min-height: 543px;
            padding: 100px 0 70px;
        }
        &:before {
            content: '';
            position: absolute;
            background: url(/images/guides/banner-gradient-tl.png) no-repeat 0 0;
            top: 0;
            left: 0;
            width: 668px;
            height: 486px;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
    }
    &__left {
        @include media-query(992px) {
            max-width: 55%;
        }
    }
    &__heading {
        font-family: "Poppins";
        font-size: 31px;
        line-height: 40px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
    }
    &__details-green {
        color: #2DBE70;
    }
    &__sub-heading {
        font-family: "Poppins";
        font-weight: 700;
        color: #fff;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
            font-size: 50px;
            line-height: 54px;
        }
    }
    &__download {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        @include media-query(992px) {
            padding-right: 65px;
            margin-top: 35px;
            flex-direction: row;
            align-items: flex-start;
        }
    }
    &__download-icon {
        flex: 0 0 45px;
        width: 45px;
        margin-right: 15px;
    }
    &__download-free {
        flex: 1;
        font-family: "Poppins";
        font-weight: 400;
        color: #fff;
        position: relative;
        text-align: center;
        font-size: 19px;
        line-height: 27px;
        @include media-query(992px) {
            text-align: left;
            font-size: 31px;
            line-height: 36px;
        }
    }
    &__download-free-green {
        color: #2DBE70;
        font-weight: 700;
    }
    &__wrapper {
        position: inherit;
        display: flex;
        justify-content: flex-end;
    }
    &__right {
        position: relative;
        top: -35px;
        @include media-query(992px) {
            position: absolute;
            top: 140px;
            max-width: 510px;
        }
    }
    &__form-wrapper {
        background: url(/images/guides/form-strip.png) repeat-x 0 0;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #E1E1E1;
        border-radius: 10px;
        position: relative;
        @include media-query(992px) {
            padding: 40px;
        }
    }
    &__form-heading {
        font-family: "Poppins";
        font-weight: 700;
        color: #262E36;
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 37px;
            margin-bottom: 25px;
        }
    }
    &__input {
        font-family: "Poppins";
        padding: 18px 15px;
        width: 100%;
        border: 1px solid #CFCFCF;
        border-radius: 3px;
        color: #333;
        font-size: 15px;
        &::placeholder {
            color: #333;
            opacity: 1;
        }
    }
    &__input-name {
        margin-bottom: 20px;
    }
    &__select {
        font-family: "Poppins";
        padding: 18px 15px;
        width: 100%;
        border: 1px solid #CFCFCF;
        border-radius: 3px;
        color: #333;
        font-size: 15px;
        margin-top: 20px;
        appearance: none;
        background: url(/images/guides/option-arrow.png) no-repeat 97% 50%;
    }
    &__form-para {
        font-family: "Poppins";
        color: #333;
        margin-top: 15px;
        @include media-query(992px) {
            font-size: 15px;
            line-height: 23px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
    &__privacy {
        display: flex;
        align-items: flex-start;
        font-family: "Poppins";
        color: #333;
        @include media-query(992px) {
            font-size: 14px;
            line-height: 20px;
            padding-right: 50px;
        }
    }
    &__privacy-icon {
        flex: 0 0 30px;
        width: 30px;
        margin-right: 10px;
    }
    &__review-splide {
        background: #355BBF;
        border-radius: 10px;
        padding: 20px 20px 20px 20px;
        margin-top: 20px;
        @include media-query(992px) {
            padding: 50px;
            margin-top: 60px;
        }
    }
    &__review-para {
        font-family: "Poppins";
        color: #fff;
        font-weight: 500;
        position: relative;
        padding-top: 35px;
        margin-bottom: 10px;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 35px;
            padding-top: 50px;
            margin-bottom: 25px;
        }
        &:before {
            content: '';
            position: absolute;
            background: url(/images/guides/double-quote.png) no-repeat 0 0;
            width: 32px;
            height: 26px;
            top: 0;
            left: 0;
        }
    }
    &__review-name {
        font-family: "Poppins";
        color: #fff;
        font-weight: 500;
        font-size: 18px;
    }
    &__review-company {
        font-family: "Poppins";
        color: #fff;
        font-weight: 600;
        font-size: 14px;
    }
    &__arrow-icon {
        @include media-query(992px) {
            position: absolute;
            top: -18px;
            right: -58px;
        }
    }
}

.guide-scrollable {
    $guide-scrollable: &;
    &__radio {
        display: inline-flex;
        position: relative;
        padding-left: 27px;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 15px;
        color: #333;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .guide-scrollable__radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #CFCFCF;
        border-radius: 50%;
    }
    .guide-scrollable__radio:hover input ~ .checkmark {
        background-color: #ccc;
    }
    .guide-scrollable__radio input:checked ~ .checkmark {
        background-color: #2196F3;
        border: none;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .guide-scrollable__radio input:checked ~ .checkmark:after {
        display: block;
    }
    .guide-scrollable__radio .checkmark:after {
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: white;
    }
    .btn-orange {
        margin: 15px 0 15px 0;
        text-transform: capitalize;
        font-size: 17px;
        font-weight: 600;
        font-family: "Poppins";
        width: 275px;
        padding: 16px 20px;
    }
}

.rewrite {
    $rewrite: &;
    padding: 0 0 30px;
    @include media-query(992px) {
        padding: 80px 0 60px;
    }
    &__wrapper {
        @include media-query(992px) {
            max-width: 50%;
            margin-right: auto;
            margin-left: 0;
        }
    }
    &__heading {
        font-family: "Poppins";
        font-weight: 600;
        color: #333;
        text-align: center;
        @include media-query(992px) {
            font-size: 41px;
            line-height: 50px;
            text-align: left;
        }
    }
    &__sub-heading {
        font-family: "Poppins";
        font-weight: 500;
        color: #333;
        text-align: center;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 30px;
            text-align: left;
        }
    }
    &__list {
        @include media-query(992px) {
            margin-bottom: 35px;
        }
    }
    &__items {
        display: flex;
        align-items: flex-start;
    }
    &__icon {
        display: flex;
        flex: 0 0 60px;
        width: 60px;
        margin-right: 15px;
    }
    &__details {
        flex: 1;
    }
    &__details-heading {
        font-family: "Poppins";
        font-weight: 700;
        color: #333;
        font-size: 19px;
        line-height: 27px;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    &__details-para {
        font-family: "Poppins";
        font-weight: 400;
        color: #333;
        @include media-query(992px) {
            font-size: 15px;
            line-height: 25px;
        }
    }
}

.download-free-guide {
    $download-free-guide: &;
    background: #04254A;
    text-align: center;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &--s1000 {
        background: url(/images/guides/s1000d/download-free-guide-bg.jpg) no-repeat center center / cover;
        .download-free-guide__heading--s1000 {
            margin-bottom: 10px;
        }
    }
    &__heading {
        font-family: "Poppins";
        font-weight: 400;
        color: #fff;
        margin-bottom: 15px;
        position: relative;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 35px;
            margin-bottom: 25px;
        }
        strong {
            color: #2DBE70;
        }
        &--s1000 {
            font-weight: 700;
            @include media-query(992px) {
                font-size: 35px;
                line-height: 55px;
            }
        }
    }
    &__sub-heading {
        font-family: "Poppins";
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: #CAE7FF;
    }
    &__btn-wrapper {
        @include media-query(992px) {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .btn-orange {
            margin: 10px auto 0;
            @include media-query(992px) {
                margin: 0;
            }
        }
    }
    &__btn-wrapper-left {
        @include media-query(992px) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            margin-right: 10px;
        }
    }
    &__btn-wrapper-right {
        @include media-query(992px) {
            display: flex;
            justify-content: flex-start;
            flex: 1;
            margin-left: 10px;
        }
        .btn-orange {
            background: none;
            color: #fff;
            &:hover {
                color: #ff5800;
                border-color: #fff;
                svg {
                    path {
                        fill: #ff5800;
                    }
                }
            }
        }
    }
    &__arrow {
        position: absolute;
        top: 45px;
        right: 145px;
    }
    .btn-orange {
        text-transform: capitalize;
        @include media-query(992px) {
            max-width: 350px;
            padding: 15px 20px;
            font-size: 17px;
        }
        svg {
            margin-left: 10px;
        }
    }
}

.guide-modal {
    $guide-modal: &;
    &__download-form {
        .guide-modal__input {
            font-family: "Poppins";
            padding: 18px 15px;
            width: 100%;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            color: #333;
            font-size: 15px;
            &::placeholder {
                color: #333;
                opacity: 1;
            }
        }
        .guide-modal__input-name {
            font-family: "Poppins";
            padding: 18px 15px;
            width: 100%;
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            color: #333;
            font-size: 15px;
            margin-bottom: 15px;
            &::placeholder {
                color: #333;
                opacity: 1;
            }
        }
        p {
            padding-top: 0px;
        }
        .guide-modal__radio {
            display: inline-flex;
            margin-bottom: 15px;
        }
        .guide-home-banner__form {
            padding: 20px 15px 20px 15px;
            @include media-query(992px) {
                padding: 20px 40px 20px 40px;
            }
        }
    }
    .guide-home-banner__select {
        margin-top: 5px;
        appearance: none;
        background: url(/images/guides/option-arrow.png) no-repeat 97% 50%;
    }
}
// S1000D Guide Line
.o-banner-s1000d{
    $o-banner-s100d: &;
    position: relative; 
    &__images{
        background: #091260 url(/images/guides/s1000d/banner-bg.jpg) no-repeat center center / cover;
        position: relative;
        padding: 85px 0 30px;
        @include media-query(992px) {
            min-height: 543px;
            padding: 140px 0 70px;
        }
    }
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;   
        margin-top: 20px;
        @include media-query(992px) {
            flex-direction: row;
            margin-top: 0;
        }        
    }
    &__leftside{
        width: 100%;
        @include media-query(992px) {
            width: 60%;
        }
    }
    &__rightside{
        width: 100%;
        @include media-query(992px) {
            width: 40%;
        }
    }
    &__bannerheading{
        font-family: "Poppins";
        font-size: 31px;
        line-height: 40px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        margin: 20px 0;
        @include media-query(992px) {
            text-align: left;
            font-size: 50px;
            line-height: 60px;
            margin-top: 0;
        }
    }
    &__bannercontent{
        font-family: "Poppins";
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        color: #ABD2FF;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 35px;
            text-align: left;
        }
    }
    &__contenthighlight{
        color: #fff;
        position: relative;
        padding: 0 0 5px;
        &:before{
            position: absolute;
            content: "";
            width: 100%;
            height: 4px;
            border-radius: 30px;
            bottom: 0;
            left: 0;
            animation: 4s linear animate infinite;
            background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#ff5800), color-stop(#fbba14), to(#03a9f4));
            background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
            background-size: 200%;
        }
    }
    &__formarea{
        background-color: #153995;
        max-width: 417px;
        margin: auto;
        padding: 20px 15px;
        @include media-query(992px) {
            padding: 40px;
        }
    }
    &__formtitle{
        font-family: "Poppins";
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 38px;
            text-align: left;
        }
    }
    &__formsubtitle{
        position: relative;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        color: #ABD2FF;
        @include media-query(992px) {
            font-size: 15px;
            line-height: 20px;
            text-align: left;
        } 
        &:after{
            position: absolute;
            content: "";
            width: 80px;
            height: 89px;
            background: url(/images/guides/s1000d/form-arrow.svg) no-repeat center center / 100%;
            right: -40px;
            bottom: -20px
        }
    }
    &__input{
        width: 100%;
        height: 55px;
        margin-bottom: 12px;
        padding: 15px;
        border-radius: 2px;
        font-size: 14px;
        color: #000;
        border: 0px;
        &::placeholder {
            opacity: 1 !important;
            font-weight: 500 !important;
        }
    }
    input::placeholder {
        opacity: 1 !important;
        font-weight: 500 !important;
    }
    .c-btn{
        width: 100%;
        text-transform: capitalize;
        font-size: 17px;
        font-weight: 600;
        border-radius: 5px;
    }
    .confidential-text-wrap{
        li{
            color: #fff;
            font-size: 14px;
        }
    }
}
.o-why-guide{
    $o-why-guide:&;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 80px 0;
    }
    background: #F5F8FC;
    border-bottom: 1px solid #D3E2F0;
    &__wrapper{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__imagearea{
        width: 100%;
        position: relative;
        text-align: center;
        @include media-query(992px) {
            width: 30%;
            text-align: inherit;
        }
    }
    &__image{
        @include media-query(992px) {
            position: absolute;
            bottom: -40px;
            left: 0;
        }
    }
    &__textpart{
        width: 100%;
        @include media-query(992px) {
            width: 70%;
        } 
    }
    &__heading{
        font-family: "Poppins";
        font-size: 35px;
        line-height: 50px;
        color: #262E36;
    }
    &__content{
        font-family: "Poppins";
        font-size: 18px;
        line-height: 26px;
        color: #333;
        max-width: 656px;
        margin-bottom: 15px;
        b{
            font-weight: 600;
        }
    }
}
.o-what-learn{
    $o-what-learn:&;
    padding: 40px 0; 
    &__heading{
        font-family: "Poppins";
        font-size: 35px;
        line-height: 50px;
        color: #262E36;  
        margin-bottom: 60px;
        text-align: center;
    }
    &__list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__item{
        padding: 10px;
    }
    &__items{
        max-width: 548px;
        display: flex;
        min-height: 101px;
        i{
            margin-right: 17px;
        }
        h5{
            font-family: "Poppins";
            font-size: 24px;
            line-height: 27px;
            margin-bottom: 10px;
            color: #0C2848;
            font-weight: 600;
        }
        p{
            font-family: "Poppins";
            font-size: 16px;
            line-height: 22px;
            margin: 0;
            color: #0C2848;
        }
    }
}

.why-choose-guide {
    $why-choose-guide: &;
    background: #F5F8FC;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__heading {
        font-family: "Poppins";
        font-size: 35px;
        line-height: 50px;
        font-weight: 700;
        margin: 0;
        color: #262E36;
        text-align: center;
        margin-bottom: 35px;
    }
    &__card {
        background:#fff;
        padding: 40px 30px;
        position: relative;
        border: 1px solid #DDDDDD;
        box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.05);
        height: 100%;
        height: 165px;
        display: flex;
        &:before {
            content: '';
            position: absolute;
            top: -3px;
            left: 30px;
            width: 90px;
            height: 5px;
            background: #ccc;
        }
        &--color1 {
            &:before {
                background: #64B9F4;
            }
        }
        &--color2 {
            &:before {
                background: #3CC065;
            }
        }
        &--color3 {
            &:before {
                background: #FBBA14;
            }
        }
    }
    &__card-details {
        font-family: "Poppins";
        font-size: 20px;
        line-height: 29px;
        font-weight: 500;
        color: #262E36;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    .btn-orange {
        max-width: 374px;
        margin-top: 35px;
        text-transform: capitalize;
        padding: 16px 20px;
    }
}

.section-footer--guide {
    background: #18192B;
    .section-footer__copy-right-details {
        margin-bottom: 0;
    }
    .section-footer__social-list {
        justify-content: flex-end;
    }
}

.g-recaptcha-container {
    width: 100%;
    margin-bottom: 19px;
    div {
        @include media-query(992px) {
            transform: scale(1.037);
            transform-origin: top left;
            -webkit-transform: scale(1.037);
            -webkit-transform-origin: top left; 
        }
    }
    .g-recaptcha {
        width: 100% !important;
        height: auto;
    }
}
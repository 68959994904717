

.fixed-engagement-model.new-fixed-engagement-model {
    background: linear-gradient(311deg, rgba(6,165,100,1) 0%, rgba(0,81,127,1) 41%, rgba(2,62,102,1) 72%, rgba(5,27,57,1) 100%);
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 65px 0 40px;
    }
    .heading-top {
        margin-bottom: 30px;
        position: relative;
        p {
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
            margin: 0 auto;
            max-width: 1000px;
            color: #fff;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 34px;
            }
        }
        h3 {
            color: #fff;
            font-size: 18px;
            line-height: 26px;
            display: inline-block;
            @include media-query(992px) {
                font-size: 24px;
                line-height: 48px;
                vertical-align: top;
            }
            @include media-query(1200px) {
                font-size: 30px;
                line-height: 56px;
            }
            span {
                font-weight: 700;
                color: #71E19D;
                font-size: 30px;
                line-height: 30px;
                @include media-query(992px) {
                    font-size: 36px;
                    line-height: 56px;
                }
            }
            strong {
                font-weight: 700;
                color: #71E19D;
                font-size: 30px;
                line-height: 30px;
                @include media-query(992px) {
                    font-size: 36px;
                    line-height: 56px;
                }
            }
            &.m-extra-padding {
                padding: 0 48px;
                @include media-query(992px) {
                    padding: 0;
                }
                span {
                    white-space: nowrap;
                    @include media-query(992px) {
                        white-space: inherit;
                    }
                }
            }
        }
        .list-unstyled {
            display: inline-block;
        }
        .slct-cntr {
            position: absolute;
            left: 20px;
            top: 19px;
            font-size: 15px;
            line-height: 17px;
            font-weight: 500;
            color: #fff;
            text-align: left;
        }
    }
    .fixed-engagement {
        @include media-query(992px) {
            max-width: 970px;
            margin: 0 auto 50px;
        }
        &-row {
            @include media-query(992px) {
                display: flex;
                flex-wrap: wrap;
            }
            h5 {
                color: #333;
                font-size: 20px;
                font-weight: 600 !important;
                position: relative;
                margin-bottom: 0;
                strong{
                    font-weight: 600 !important;
                }
                @include media-query(992px) {
                    font-weight: 700;
                }
                // .span-break {
                //     font-size: 18px;
                //     font-weight: 600;
                //     @include media-query(992px) {
                //         font-size: 20px;
                //         font-weight: 700;
                //     }
                // }
            }
            &-left {
                flex: 0 0 41%;
                display: flex;
                align-items: flex-start;
                background: #0160A9;
                padding: 20px 10px 20px 25px;
                border-bottom: 1px solid rgba(255,255,255,0.2);
                @include media-query(992px) {
                    margin-right: 5px;
                }
                i {
                    width: 42px;
                }
                h5 {
                    font-size: 16px;
                    flex: 1;
                    @include media-query(992px) {
                        font-size: 20px;
                    }
                }
                &-content {
                    flex: 1;
                    h5 {
                        color: #fff;
                        font-size: 18px;
                        font-weight: 600;
                        @include media-query(992px) {
                            margin-bottom: 5px;
                        }
                    }
                    p {
                        color: #fff;
                        font-size: 13px;
                        line-height: 17px;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                }
            }
            .fixed-engagement-right {
                margin-bottom: 30px;
                @include media-query(992px) {
                    flex: 1;
                    display: flex;
                    margin-bottom: 0;
                }
                .fixed-engagement-col {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    border-bottom: 1px solid #DADBDE;
                    text-align: center;
                    @include media-query(992px) {
                        margin: 0 5px;
                    }
                    span {
                        font-size: 16px;
                        padding: 0 10px;
                        @include media-query(992px) {
                            font-size: 15px;
                            padding: 10px 25px;
                        }
                        @include media-query(1200px) {
                            font-size: 16px;
                        }
                        font-weight: 500;
                        img {
                            margin-left: 5px;
                            position: relative;
                            top: -1px;
                            width: 20px;
                        }
                        &.has-pointer {
                            cursor: pointer;
                        }
                    }
                }
                .col-content-box {
                    background: #fff;
                    border-bottom: 1px solid #ecedee;
                    padding: 15px;
                    &-top {
                        display: flex;
                        align-items: center;
                        h5 {
                            color: #333;
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 400;
                            flex: 0 0 55%;
                            padding-right: 30px;
                            margin-bottom: 0;
                            position: relative;
                            cursor: pointer;
                            &:before {
                                content: '';
                                position: absolute;
                                background: url(/images/new-engagement-model/circle-down.svg) no-repeat center center;
                                width: 28px;
                                height: 28px;
                                right: 0px;
                                top: 50%;
                                transform: translate(0, -50%);
                            }
                            &.active {
                                &:before {
                                    background: url(/images/new-engagement-model/circle-up.svg) no-repeat center center;
                                }
                            }
                        }
                        span {
                            color: #333;
                            font-size: 16px;
                            font-weight: 700;
                            flex: 1;
                            padding-left: 25px;
                        }
                    }
                    .col-content-description {
                        display: none;
                        margin-top: 10px;
                        p {
                            margin-bottom: 0;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        &-row-top {
            .fixed-engagement-right {
                .fixed-engagement-col {
                    // background: #27C070;
                    background: #71E19D;
                    border-bottom: 0;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    min-height: 65px;
                    @include media-query(992px) {
                        min-height: 85px;
                    }
                    &.has-tooltip {
                        position: relative;
                    }
                }
                h5 {
                    @include media-query(992px) {
                        padding: 9px 0;
                        line-height: 25px;
                        text-align: center;
                    }
                    img {
                        @include media-query(992px) {
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
            .fixed-engagement-row-left {
                background: none;
                border-bottom: none;
            }
        }
    }
    .border-bottom-0 {
        border-bottom: none;
    }
    .border-bottom-left-radius {
        border-bottom-left-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-bottomleft: 10px;
    }
    .border-bottom-right-radius {
        border-bottom-right-radius: 10px;
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-bottomright: 10px;
    }
    .border-top-left-radius {
        border-top-left-radius: 10px;
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
    }
    .slct-box {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        @include media-query(992px) {
            margin-left: 10px;
        }
    }
    #price-tabs {
        margin-bottom: 0;
        position: absolute;
        width: 100%;
        background: #fff;
        z-index: 9999;
        padding: 0;
        display: none;
        li {
            width: 100%;
            display: block;
            border-bottom: 1px solid #e5e5e5;
            border-radius: 0;
            margin-bottom: 0;
            padding: 0;
            a {
                background: 0 0;
                border: none;
                font-size: 14px;
                font-weight: 500;
                padding: 5px 15px;
                height: auto;
                width: auto;
                text-align: left;
                color: #333;
                line-height: 26px;
                border-radius: 0;
                display: block;
               &:before {
                display: none;
               }
            }
            &:last-child {
                border: none;
            }
            &:hover {
                a {
                    background: #ccc;
                    color: #333;
                }
            }
        }
        &.ae-show {
            display: block;
        }
    }
    .accordion_body {
        display: none;
    }
      
      .dropdown-wrapper .ae-select {
        padding: 8px;
        position: relative;
        height: 56px;
        width: 240px;
        border: 2px #FF772F solid;
        position: relative;
        border-radius: 29px;
        &-content {
            font-size: 20px;
            color: #fff;
            font-weight: 600;
            width: 100%;
            display: block;
            text-align: right;
            padding-right: 35px;
            position: relative;
            text-transform: uppercase;
            top: 3px;
            &:before {
                content: '';
                background: url(/images/new-engagement-model/bottom-arrow.svg) no-repeat center center;
                position: absolute;
                width: 14px;
                height: 8px;
                top: 13px;
                right: 12px;
            }
        }
        .ae-select-content.active {
            &:before {
                transform: rotate(180deg);
                top: 12px;
                right: 12px;
            }
        }
      }
      
      .dropdown-wrapper .ae-select.chosen {
          color: #333;
      }
      
      .dropdown-wrapper .ae-select .down-icon, .ae-select .up-icon {
          position: absolute;
          right: 8px;
          top: 7px;
      }
      
      .dropdown-wrapper .ae-dropdown .select-menu {
          box-shadow: none;
          border-radius: 0px;
      }
      
      .dropdown-wrapper .ae-dropdown .ae-select, .dropdown-wrapper .ae-dropdown .select-menu>li {
          cursor: pointer;
      }
      
      .dropdown-wrapper .ae-dropdown .select-menu>li>a:focus, .dropdown-wrapper .ae-dropdown .select-menu>li>a:hover {
          background: none;
      }
      
      .dropdown-wrapper .ae-disabled{
          pointer-events: none;
      }
      
      .ae-hide{
        display:none;
      }
      
      ul.select-menu{
        list-style-type: none;
      }
      
      ul.select-menu{
        margin:0px;
        padding:5px;
        border: 1px solid #ccc;
      }
      
      ul.select-menu li{
        padding:5px 0px;
      }
    
      .shr-rqst-btn {
        a {
            @include media-query(992px) {
                max-width: 370px;
            }
        }
    }
    .hide {
        display: none;
    }
}

.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev {
    .fixed-engagement-row-left {
        flex: 0 0 35%;
        &.fixed-engagement-row-left-new-bg {
            background: #091B3A;
            h5 {
                color: #fff;
            }
            border-top-right-radius: 10px;
            align-items: center;
            display: flex;
        }
    }
}

    .ui-tooltip, .arrow:after {
        background: #fff9cf;
        border: 1px solid #e3d58f;
      }
      .ui-tooltip {
        box-shadow: 0px 10px 27px -9px rgba(0,0,0,0.75) !important;
        background: #fff9cf !important;
        border: 1px solid #e3d58f !important;
        color: #333;
        text-align: left;
        padding: 5px 15px !important;
        border-radius: 6px !important;
        max-width: 260px;
        min-width: 380px;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: $main-font !important;
      }
      .arrow {
        width: 70px;
        height: 16px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        margin-left: -35px;
        bottom: -16px;
      }
      .arrow.top {
        top: -16px;
        bottom: auto;
      }
      .arrow.left {
        left: 20%;
      }
      .arrow:after {
        content: "";
        position: absolute;
        left: 20px;
        top: -20px;
        width: 25px;
        height: 25px;
        box-shadow: 6px 5px 9px -9px black;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .arrow.top:after {
        bottom: -20px;
        top: auto;
      }

.border-right-radious
{
    border-bottom-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-bottomright: 10px;
}

.new-tech-mean-stack{
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 70px 0 80px;
    }
    h2{
        margin-bottom: 0;
        font-weight: 600;
        font-size: 24px;
        @include media-query(992px) {
            margin-bottom: 40px;
            font-weight: 600;
            font-size: 48px;
        }
    }
    .new-tech-mean-stack-ul{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include media-query(992px) {
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
        li{
            width: 100%;
            @include media-query(992px){
                width: 20%;
                
            }
            .new-tech-mean-stack-anchor{
                display: block;
                margin: 30px auto 0;
                @include media-query(992px){
                    margin: 0 25px;
                    
                }
            }
        }
    }
}
.font-weignt-400{
    font-weight: 400;
}
.blue-bnr-section {
    h2{
        &.font-size-resize-new{
            font-size: 26px;
            @include media-query(992px){
                font-size: 30px;
            }
        }
    }
}
.font-weight-500{
    font-weight: 500;
    margin-bottom: 22px;
}
.career-bnr {
 	background: #091a38!important;
	 padding-top: 119px !important;
	 padding-bottom: 50px !important;
 	@include responsive(desktop){
        background: #fff!important;
		padding: 135px 20px 45px!important;
      }

	.container {
	}
	&:before {

	}
	.o-skills-hero__background {
		background-image: url(/images/common/career-bnr-img.jpg);
	}
	&-icon {
	    display: flex;
	    align-items: center;
	    justify-content: space-evenly;

	    &-img {

	    	&:nth-child(1) {

	    	}
	    	&:nth-child(2) {
	    		flex: 0 0 140px;
	    		max-width: 140px;

	    	}
	    	&:nth-child(3) {

	    	}
	    	&:nth-child(4) {

	    	}
	    	&:nth-child(5) {

	    	}
	    	&:nth-child(6) {

	    	}
	    }
	}
	.font-family-mot {
		font-weight: 700;

	}
	.green-head-ribbon {
		span {
			&:before {

			}
			&:after {

			}
		}
	}
	h1 {
		font-size: 48px;
    	line-height: 66px;
	}
	h2 {
		font-size: 30px;
		line-height: 26px;
		font-weight: 700;
		padding: 0;
		margin-bottom: 20px;
		@include responsive(desktop){
			margin-bottom: 40px;
			line-height: 36px;
		}
	}
	.bnr-tag-line {
		font-size: 16px;
		font-weight: 600;
		color: #fff;
		margin-top: 30px;
		margin-bottom: 0;
		display: block;
		@include responsive(desktop){
			font-size: 24px;
		}
		@include responsive(desktop){
			margin-bottom: 30px;
		}
		strong {
			font-weight: 600;
			color: #49C684;
		}
		a{
			color: #fff;
		}
	}
	.btn_wrapper {
		padding-top: 25px;
		width: 340px!important;
		margin-bottom: 30px;

		.btn {
			max-width: 385px;

			strong {

			}
			&:after {
			}
		}
	}
}

.position {
	padding: 40px 0 50px;
	background: #F5F5F5;
	@include responsive(desktop){
		padding: 70px 0 80px;
	}

	&:after {

	}
	h2 {
		text-align: center;

	}
	h3 {
		text-align: center;
		position: relative;
		font-size: 18px;
		padding-bottom: 10px;
		margin-bottom: 25px;
		margin-top: 30px;
		@include responsive(desktop){
			font-size: 30px;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 60px;
			height: 2px;
			background: #2DBE70;
		}
	}
	.backend-wrap {
		&-con {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&-box {
				position: relative;
				box-shadow: 0 0px 20px -2px rgba(0,0,0,0.1);
				border-radius: 8px;
				background: #fff;
				flex: 0 0 100%;
				padding: 19px 15px 19px 0;
				margin: 10px 20px 20px;
				height: 103px;
				@include responsive(desktop){
				display: flex;
				align-items: center;
				flex: 0 0 29%;
				}
				&-img {
    				height: 64px;
    				width: 64px;
    				position: relative;
    				left: -6px;
    				float: left;
	    				@include responsive(desktop){
							height: 80px;
							width: 80px;
							left: -10px;
	    				}
    				}
				&-text {
					flex: 1;
					padding-left: 85px;
					margin-top: 5px;
					@include responsive(desktop){
						padding-left: 15px;
						margin-top: 0;
					}
					h4 {
						font-size: 16px;
						font-weight: 500;
						@include media-query(992px) {
							font-size: 17px;
						}
						@include media-query(1200px) {
							font-size: 19px;
						}

					}
					span {
						font-size: 15px;
						line-height: 28px;
						font-family: "Montserrat",sans-serif;
						color: #333;
						@include media-query(992px) {
							font-size: 15px;
						}
						@include media-query(1200px) {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
	.dont-see-text {
		display: block;
		text-align: center;
		margin: 20px 0 20px;
		font-size: 20px;
		font-weight: 400;
		font-family: "Montserrat",sans-serif;
		@include responsive(desktop){
			font-size: 30px;
			margin: 40px 0 30px;
			font-weight: 700;
		}

	}
	.btn-orange {
		@media all and (max-width: 767px) {
			font-size: 14px;
		}
	}
}

.key-section {
	padding: 40px 0 47px;
	@include responsive(desktop){
		padding: 70px 0 77px;
	}

	&:after {

	}
	.container {

		.row {

		}
	}
	h2 {
		text-align: center;
		margin-bottom: 30px;
		@include responsive(desktop){
			margin-bottom: 0;
		}

	}
	p {
		text-align: center;
		font-size: 20px;
		line-height: 30px;
		font-family: "Montserrat",sans-serif;
		padding: 15px 0 25px;

	}
	.key-box {
		display: flex;
		align-items: flex-start;
		&-img {
				width: 70px;

		}
		&-text {
			padding-left: 25px;
			h3 {
				font-size: 30px;
				line-height: 24px;
				color: #2DBE70;
				font-weight: 700;

				@include responsive(desktop){
					font-size: 30px;
					line-height: 24px;
					margin-bottom: 10px;
				}
			}
			p {
				text-align: left;
				font-size: 18px;
				line-height: 25px;
				padding: 0 0 5px;
				@include responsive(desktop){
					padding: 0 0 25px;
				}
				font-family: "Montserrat",sans-serif;
				font-weight: 600;

			}
		}
	}
	.btn-orange {

	}
	ul.slick-dots {
		margin-top: 0;
	}
}

.perks-section {
	padding: 40px 0;
	background: #f5f5f5;
	@include responsive(desktop){
		padding: 70px 0 77px;
	}
	ul.slick-dots {
		margin-top: 0;
		@include responsive(desktop){
			margin-top: 30px;
		}
	}

	h2 {
		text-align: center;

		font-size: 30px;
		line-height: 36px;
		font-weight: 600;
		@include responsive(desktop){
			font-size: 48px;
    		line-height: 61px;
		}
	}
	p {
		text-align: center;
		max-width: 1024px;
		margin: 0 auto 30px;
		font-size: 16px !important;
		line-height: 24px !important;
		font-family: $main-font;
		@include responsive(desktop){
			font-size: 20px !important;
			line-height: 30px !important;
		}
	}
	&-wrap {
		border-radius: 8px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 25px auto 0;
		@include responsive(desktop){
			margin: 40px -15px 50px;
		}
		&-box {
			padding: 28px 15px 28px 20px;
			flex: 0 0 100%;
			margin: 15px 0;
			border: none;
			border-bottom: 1px solid #D8D8D8;
			border-radius: 8px;
			background: #fff;
			display: flex;
			align-items: center;
			@include responsive(desktop){
				flex: 0 0 22.3%;
				margin: 15px 15px;
			}
			span {
				display: flex;
				justify-content: center;
				width: 52px;
				@include responsive(desktop){
					display: inline-block;
				}
			}
			h5 {
				flex: 1;
				padding-left: 15px;
				font-size: 18px;
				line-height: 22px;
				text-align: left;
				margin-bottom: 0;
				@include responsive(desktop){
					margin-bottom: 10px;
				}
			}
		}
		}
}
.working {

	h2 {
		font-size: 24px;
		// font-weight: 700;
		@include responsive(desktop){
			font-size: 48px;
			line-height: 61px;
			// font-weight: 700;
		}
	}
	p {
		font-family: $main-font;
		font-size: 16px;
		line-height: 28px;
		padding: 0;
		@include responsive(desktop){
			font-size: 20px;
			line-height: 32px;
		}
	}
	.container {

	}
	&:after {

	}
	.services-pan {
		border-radius: 8px;
	}
}

.process-section {
	padding: 40px 0 47px;
	@include responsive(desktop){
		padding: 70px 0 77px;
	}

	&:after {

	}
	h2 {
		text-align: center;
		margin-bottom: 30px;
		@include responsive(desktop){
			margin-bottom: 5px;
		}

	}
	p {
		text-align: center;
		max-width: 735px;
		margin: 0 auto 50px;
		font-size: 20px;
		line-height: 30px;
	}
	.container {

	}
	.process-wrap {

		justify-content: center;
		display: flex;
		flex-direction: row-reverse;
		width: 340px;
		margin: 0 auto;
		@include media-query(1200px){
			flex-direction: inherit;
			width: 100%;
			margin: inherit;
		}

		position: relative;
		&-box {
			flex: 0 0 199px;
		    position: relative;
		    margin-bottom: 25px;
		    text-align: center;
		    width: 199px;
		    height: 199px;
		    border-radius: 50%;

		    display: flex;
		    align-items: center;
		    justify-content: center;
		    margin: 0 auto 25px;
		    &:before {
		    	content: '';
		    	position: absolute;
		    	background: url(/images/career/path1.png) no-repeat 0 0;
		    	width: 199px;
		    	height: 100px;
		    	top: 0;
		    	left: 0;
		    }

			&-img {
				width: 153px;
			    height: 153px;
			    border-radius: 50%;
			    border: 5px solid #EAEAEA;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    margin: 0 auto;
			    position: relative;
			}
			&:before {

			}
			&:after {

			}
			h5 {
				text-align: center;
				font-size: 16px;
				font-weight: 600;
				position: absolute;
			    left: 0;
			    right: 0;
			    top: 55%;
			    padding: 0 75px;
			    line-height: 20px;

			}
			&:nth-child(3) {
				h5 {
					top: 50%;
				}
			}
			&:nth-child(2) {
				h5 {
					padding: 0 80px;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
	.top-process-name {
		display: flex;
		flex-wrap: wrap;
		position: inherit;
		flex: 1;
		padding-left: 15px;
		top: 10px;

	    @include media-query(1200px){
			position: absolute;
	    }

	    &-box {
	    	display: flex;
	    	flex: 0 0 100%;
			align-items: center;
			@include media-query(1200px) {
				flex: 1;
				align-items: inherit;
			}
	    	h4 {
				font-size: 24px;
    			line-height: 20px;
				font-weight: 500;
				padding-right: 10px;
				align-self: center;
				border-right: 1px solid #E2E2E2;
				margin-bottom: 0;
				@include media-query(1200px){
					align-self: baseline;
					font-size: 30px;
					line-height: 26px;
					margin-bottom: 10px;
				}

			}
			&:nth-child(1) {
				h4 {
					color: #36E079;
				}

			}
			&:nth-child(2) {
				h4 {
					color: #28C89A;
				}
				top: -8px;
				position: relative;
				@include media-query(1200px){
					top: 0;
					position: static;
				}

			}
			&:nth-child(3) {
				h4 {
					color: #2BBDC3;
				}
				position: relative;
				top: -26px;
				@include media-query(1200px){
					top: auto;
    				left: 24px;
				}
			}
			&:nth-child(4) {
				h4 {
					color: #1B89AF;
				}
				position: relative;
				top: -54px;
				@include media-query(1200px){
					position: relative;
					top: 0;
				}

			}
			&:nth-child(5) {
				h4 {
					color: #036497;
				}
				position: relative;
				top: -73px;
				@include media-query(1200px){
					position: relative;
					top: auto;
					flex: 0 0 213px;
				}

			}
			&:nth-child(6) {
				h4 {
					color: #2C4D82;
				}
				position: relative;
				top: -76px;
				@include media-query(1200px){
					position: relative;
					top: 0;
				}

			}
			h5 {
				font-size: 17px;
				color: #333333;
				line-height: 23px;
				padding-left: 15px;
				margin-bottom: 0;
				@include media-query(1200px){
					margin-bottom: 10px;
				}

			}
			&:nth-child(2n+1) {
			    position: relative;
			    bottom: 0;
				@include media-query(1200px) {
					bottom: -245px;
				}

			}
	    }
	}
	.process-img {
		padding: 0;
		@include media-query(1200px){
			padding: 50px 0;
		}
		margin-bottom: 75px;
	}
	.btn-orange {

	}
}

.working {
	padding: 40px 0 47px;
	background: linear-gradient(172deg, rgba(3,129,112,1) 2%, rgba(0,81,127,1) 36%, rgba(2,62,102,1) 56%, rgba(5,27,57,1) 100%) !important;
	@include responsive(desktop){
			padding: 70px 0 77px;
			background: linear-gradient(135deg, #07B260 0%, #00517F 40%, #023E66 60%, #051B39 100%) !important;
		}
	 p {
	 	margin-top: 10px;
	 	margin-bottom: 20px;
		 @include responsive(desktop){
			margin-top: 30px;
			margin-bottom: 40px;
		 }
	 }
	.masonry {
		display: flex;
    	flex-wrap: wrap;
    	justify-content: center;
    	h3 {
			font-size: 20px !important;
			margin-bottom: 0;
			@include responsive(desktop){
				font-size: 24px !important;
				margin-bottom: 0;
			}
    	}
		p {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 0;
			margin-top: 15px;
			@include responsive(desktop){
				font-size: 19px;
    			line-height: 32px;
			}
		}
		.brick {
			flex: 0 0 100%;

			padding-top: 30px !important;
			@include responsive(desktop){
	 			flex: 0 0 31%;
				 padding: 40px 25px 30px !important;
	 		}
			p {
				padding: 0;
			}
			i {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				height: 65px;
				img {
					height: 100%;
					width: auto;
				}
			}
		}
	}
	span.opportunity-text {
	    display: block;
	    text-align: center;
	    margin-bottom: 30px;
		margin-top: 0;
		@include responsive(desktop){
	    	margin-top: 30px;
		}
	    font-size: 20px;
	    color: #fff;
	    font-family: "Montserrat",sans-serif;
		@include responsive(desktop){
			font-size: 30px;
		}
	}
	.btn-orange {

	}
	ul.slick-dots {

	}
}

.hq {
	padding: 40px 0;
	@include responsive(desktop){
		padding: 70px 0 50px;
	}
	background: #f5f5f5;

	ul.slick-dots {
	    margin-top: 30px;
	    li {
	    	width: 10px;
	    	height: 10px;
	    	margin: 0 6px;

	    	&.slick-active {
	    		background: #49C684;
	    		width: 10px;
	    		height: 10px;

	    	}
	    }
	}
	h2 {
		text-align: center;
		margin-bottom: 10px;
		font-size: 30px;
    	line-height: 36px;
		@include responsive(desktop){
			margin-bottom: 40px;
			font-size: 48px;
    		line-height: 61px;
		}

	}
	p {
		text-align: center;
		max-width: 945px;
		margin: 0 auto 50px;
		font-size: 16px;
		line-height: 28px;
		font-family: $main-font;
		@include responsive(desktop){
			font-size: 20px;
			line-height: 30px;
		}
	}
	&-slider-all-img {
		display: flex !important;
    	align-items: flex-end;
		flex-wrap: wrap;
		@include responsive(desktop){
			flex-wrap: nowrap;
		}

    	&-box {
    		margin: 0 5px;
    		flex: 0 0 100%;
    		@include responsive(desktop){
    			margin: 5px;
				flex: inherit
    		}
    		a {
    			position: relative;
    			display: block;
    			&:before {
    				content: '';
    				position: absolute;
    				background: url(/images/career/view-icon.png) no-repeat center center;
    				width: 35px;
    				height: 35px;
    				top: 25px;
    				right: 20px;
    			}
    		}
    		img {
    			margin: 5px 0;
    		}
    	}
		img {
			width: 100%;
		}
	}
}

.any-q {
	display: block;
	text-align: center;
	font-size: 20px;
	font-family: "Montserrat",sans-serif;
	@include responsive(desktop){
		font-size: 30px;
	}
}
.any-q-a {
	font-size: 16px;
	margin-top: 15px;
	font-family: "Montserrat",sans-serif;
	text-align: center;
	a {
		color: #4A90E2;
	}
}


ul.slick-dots {
	    margin-top: 30px;
	    li {
	    	width: 7px;
	    	height: 7px;
	    	margin: 0 5px;
			cursor: pointer;
	    	&.slick-active {
	    		background: #49C684;
	    		width: 10px;
	    		height: 10px;

	    	}
	    }
	}
.display-none-f {

	display: none !important;
	@include responsive(desktop){
		display: block !important;
	}
}

.newest-nav {
	background: #fff !important;
}

.request-quote {
	background-color: transparent !important;
	font-size: 15px !important;
	border: 2px solid #FF5800 !important;
	color: #333 !important;
	font-weight: 400 !important;
	text-transform: capitalize !important;
	@include responsive(desktop){
		font-size: 15px !important;
	}
	@include responsive(tab){
		font-size: 14px !important;
	}

}
.hire-btn {
	background-color: #FF5800 !important;
	font-size: 15px !important;
	border: 2px solid #FF5800 !important;
	color: #fff !important;
	font-weight: 400 !important;
	width: 125px;
	padding: 10px 20px;
	border-radius: 5px;
	margin-left: 5px;
	@media all and (max-width: 767px) {
		display: none;
	}
}

.new-nav .o-navigation-bar #primaryNav li .dropdown-menu li a span {
	color: #fff !important;
}


.o-navigation-bar-new #primaryNav li.has-submenu>a, .o-navigation-bar-new #primaryNav li.has-submenu>span {
	background: url(/images/common/nav-down-arrow.svg) 100% 40% no-repeat !important;

}

.job-left h2.subheading, .job-left h1.subheading{
	color: #fff;
	padding-top: 20px;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 20px;
	text-align: left;
	@include media-query(992px) {
		font-size: 28px;
		line-height: 40px;
    }
    @include media-query(1200px) {
		font-size: 36px;
		line-height: 50px;
    }
}
.job-left h2.subheading span, .job-left h1.subheading span{
	color: #27C070;
	font-size: 24px;
	line-height: 32px;
	display: inline-block;
	@include media-query(992px) {
        display: inline-block;
		font-size: 36px;
		line-height: 50px;
    }
}

.job-right{
	margin-top: 90px;
	position: relative;
	padding-bottom: 30px;
}
.job-right .o-contact-form{
	box-shadow: -1px 1px 9px 0px rgba(0,0,0,0.18);
	-webkit-box-shadow: -1px 1px 9px 0px rgba(0,0,0,0.18);
	-moz-box-shadow: -1px 1px 9px 0px rgba(0,0,0,0.18);
}

.job-bnr{
	position: absolute!important;
    height: 460px!important;
	@include media-query(992px) {
		height: 520px!important;
	}
	@include media-query(1200px) {
		height: 460px!important;
	}
}

.from-icon {
    position: absolute;
    left: 0;
    top: -110px;
    z-index: 1;
}
.job-left span.bnr-tag-line{
	font-size: 16px!important;
	text-decoration: none!important;
	color: #fff!important;
	font-weight: bold;
	margin-top: 10px!important;
}
.job-left span.bnr-tag-line strong{
	color: #27C070;
	font-weight: bold;
}
.job-left h3{
	font-size: 24px;
	padding-bottom: 20px;
	color: $white-color;
	@include responsive(desktop){
		color: $black-color;
	}
}
.job-left ul li{
	padding-left: 35px;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 25px;
    position: relative;
    font-family: $main-font !important;
    color: $white-color;
    @include responsive(desktop){
		color: $black-color;
		font-size: 18px;
		line-height: 32px;
	}
}
.job-left ul li:before{
	content: '';
    background-image: url(/images/common/green-arrow.png);
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 71%;
    background-repeat: no-repeat;
}

.job-left h2{
	color: $white-color;
	text-align: left;
	@include responsive(desktop){
		padding-top: 80px;
		color: $black-color;
	}
}
.job-left p{
	color: $white-color;
	font-size: 16px;
	margin-bottom: 10px;
	font-weight: normal;
	line-height: 25px;
	// font-family: $secondary-font;
	text-align: left;
	@include responsive(desktop){
		color: $black-color;
		font-size: 18px;
		line-height: 32px;
	}
}

.job-left-top{
	border-bottom: 1px solid #27C070;
	padding-bottom: 25px;
	margin-bottom: 25px;
	margin-top: 25px;
	h2{
		font-size: 24px;
		@include media-query(992px) {
			font-size: 30px;
		}
	}
	@include media-query(992px) {
		padding-bottom: 40px;
		margin-bottom: 40px;
		margin-top: 40px;
	}
}

.job-right .o-contact-form h2{
	font-size: 28px;
	text-transform: uppercase;
	color: $black-color;
    letter-spacing: 2.5px;
    text-shadow: 1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
    text-align: center;
    padding: 0;
    line-height: 52px;
    @include media-query(992px) {
       line-height: 30px;
       font-size: 40px;
    }
	@include media-query(1200px) {
		line-height: 36px;
		font-size: 42px;
	}
}
.job-right h2 span{
	background-color: #27C070;
	border-radius:20px;
	color: #fff;
	padding: 0 20px;
}
.job-right h4{
	font-size: 18px;
    line-height: 28px;
    padding: 0;
    font-weight: 600;
    text-align: center;
    @include responsive(desktop){
       padding: 0 50px;
    }
}
.job-right h5{
	font-size: 16px!important;
    line-height: 28px!important;
    padding: 20px 50px;
    font-weight: 600!important;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 20px 0 20px;
}
.job-right input{
	width: 90%;
    margin: 0 20px 0 20px;
    border: 0;
    border-bottom: 1px solid #000 !important;
    padding: 30px 30px 30px 40px;
    font-size: 16px;
    color: #878787;
    font-weight: 600;
    @include responsive(desktop){
       // width: 38%;
    }

}
.job-right input.input1{
	background: url(/images/career/job-icon-1.png) 0 50% no-repeat !important;
}
.job-right input.input2{
	background: url(/images/career/job-icon-2.png) 0 50% no-repeat !important;
}
.job-right input.input3{
	background: url(/images/career/job-icon-3.png) 0 50% no-repeat !important;
}
.job-right input.input4{
	background: url(/images/career/job-icon-4.png) 0 50% no-repeat !important;
}
.job-right .input5{
	background: url(/images/career/job-icon-5.png) 0 50% no-repeat !important;
	width: 90%;
    margin: 0 20px 0 20px;
    border-bottom: 1px solid #ccc !important;
    padding: 30px 30px 30px 40px;
    font-size: 16px;
    color: #878787;
    font-weight: 600;
    display: inline-block;
    select{
    	width: 100%;
    	border: 0;
    }
    @include responsive(desktop){
       width: 38%;
    }

}
.job-right .input6{
	background: url(/images/career/job-icon-6.png) 0 50% no-repeat !important;
	width: 90%;
    margin: 0 20px 0 20px;
    border-bottom: 1px solid #ccc !important;
    padding: 30px 30px 30px 40px;
    font-size: 16px;
    color: #878787;
    font-weight: 600;
    display: inline-block;
    select{
    	width: 100%;
    	border: 0;
    }
    @include responsive(desktop){
       width: 38%;
    }
}
.job-right input.input7{
	background: url(/images/career/job-icon-7.png) 0 50% no-repeat !important;
}
.job-right input.input8{
	background: url(/images/career/job-icon-8.png) 0 50% no-repeat !important;
}
.job-right input.input9{
	background: url(/images/career/job-icon-9.png) 0 50% no-repeat !important;
}
.job-right input.input10{
	background: url(/images/career/job-icon-10.png) 0 50% no-repeat !important;
}
.job-right .job-description-holder__attachement-text{
	text-align: left;
}

.job-right label.attachement-button {
    border: 1px solid #ccc;
    padding: 12px 25px 12px 40px ;
    border-radius: 7px;
    margin: 40px 10px 10px 40px;
    text-align: left;
    background: url(/images/career/job-icon-11.png) 10px 50% no-repeat !important;
}
.job-right .job-description-holder__attachement-text span{
	font-size: 15px;
	padding-left: 40px;
}
.o-job-form{
	overflow: inherit;
}
.job-right button{
	display: block;
    text-align: center;
    border-radius: 5px;
    padding: 19px 0px;
    font-size: 18px;
    text-transform: uppercase;
    max-width: 100%;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    margin: 30px auto 40px;
    width: 100%;
    @include responsive(desktop){
       width: 300px;
       max-width: 350px;
    }
}
.job-right button:after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1;
}
.job-bottom{
	padding: 50px 0;
	@include responsive(desktop){
		padding: 0 0 60px;
	}
	// @include tablet-lg {
	// 	padding-top: 0;
	// }
}

.job-bottom h3{
	font-size: 24px;
	padding-bottom: 20px;
	padding-left: 10px;
	width: 100%;
}
.job-bottom ul li{
	padding-left: 35px;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 25px;
    position: relative;
    font-family: $main-font;
	@include responsive(desktop){
		font-size: 18px;
    	line-height: 32px;
	}
}
.job-bottom ul li:before{
	content: '';
    background-image: url(/images/common/green-arrow.png);
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 71%;
    background-repeat: no-repeat;
}
// .Careers .btn_wrapper{
// 	width: 240px;
// }
.Careers .position .btn_wrapper{
	width: 350px;
}
.Careers {
	.anchor-link {
		margin: 10px auto 0;
	}
	.small-h1 {
		h1 {
			margin: 30px 0 30px;
			@include responsive(desktop){
				padding: 0;
				font-size: 55px;
				line-height: 70px;
				margin: 40px 0 40px;
			}
		}
		h2 {
			@include responsive(desktop){
				margin-bottom: 40px;
				font-size: 30px;
			}
		}
	}
	.faq-section {
		padding: 15px 0 50px !important;
		@include responsive(desktop){
			padding: 65px 0 75px!important;
		}
	}
	.o-footer {
		padding: 0 0 40px;
	}
	ul.skill-page-mod li:nth-child(3) {
		flex: 1;
	}
}
.job-heading-pan{
	background-color: #091a38;
	padding: 20px;
}
.Job-Application .cn-form{
	padding: 80px 20px 20px 20px;
	position: relative;
	background: #fff;

}
.job-bnr-icon-img {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
}

.career-form {
	background: url(/images/career/form-bg.jpg) no-repeat center center;
	padding: 50px 0!important;
	@include responsive(desktop){
		padding: 80px 0!important;
	}

	// @media all and (max-width: 767px) {
	// 	padding-bottom: 50px;
 //    	padding-top: 50px;
	// }
	// &:after {
	// 	@media all and (max-width: 767px) {
	// 		content: '';
	// 	    position: absolute;
	// 	    background: url(images/career/tr-pt.png) no-repeat top right;
	// 	    top: 0;
	// 	    right: 0;
	// 	    width: 249px;
	// 	    height: 137px;
	// 	    background-size: 85%;
	// 	    display: block !important;
	// 	        z-index: 11;
	// 	}
	// }
	h2 {
		// font-weight: 700;
		margin-bottom: 10px;
	}
	p {
		font-size: 17px;
		line-height: 28px;
		font-weight: 400;
		font-family: "Montserrat",sans-serif;
		// @media all and (max-width: 767px) {
		// 	font-size: 16px;
		// 	line-height: 24px;
		// }
		@include responsive(desktop){
			font-size: 20px;
			line-height: 30px;
		}
	}
	.form-text {
		position: relative;
		width: 100%;
		float: none;
		padding: 50px 60px;
		display: none;
		@include responsive(desktop){
			display: block;
		}

		.form-bot-text {
			margin-top: 0;
    		padding-top: 0;
    		border-top: 0;

		}

		h2 {

			&:before {
			}
		}
		p {

		}
		ul li{

		}
		&:before{

		}
	}
	.form-bot-text {
		.font-family-mot {
			font-size: 24px;
			font-weight: 500;
		}
		.font-family-pro {
			font-family: $secondary-font;
			font-weight: 400;
			font-size: 15px;
		}

	}
	ul {

		li {

		}
	}
	.notice-period {
		background: url(/images/career/form-icon6.png) no-repeat center left;
	}
	.position-icon {
		background: url(/images/career/form-icon9.png) no-repeat center left;
	}
	.u-submit {
		padding: 15px 0px;
	}
	.form-panel {
		padding: 0;
		width: 100%;
		float: none;

		h4 {
			position: relative;
			font-size: 28px !important;
			@include responsive(desktop){
				font-size: 36px !important;
			}
			margin-bottom: 15px;

			&:before {
				content: '';
				position: absolute;
				left: 68px;
    			top: -2px;
				width: 100px;
				@include responsive(desktop){
					left: 91px;
			    	top: 0;
					width: 120px;
				}
			    background: url(/images/career/shape.png) no-repeat 0 0;
			    height: 55px;
				background-size: 80%;

			}
			span {
				z-index: 99;
				position: relative;
				color: #fff;
			}
		}
		form {
			padding: 53px 15px!important;
			@include responsive(desktop){
				padding: 53px 60px !important;
			}
			position: relative;

			&:before {
				content: '';
				// background: url(/images/contact-us/dashed-line.png) repeat-x 0 0;
				height: 5px;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
			&:after {
				content: '';
				// background: url(/images/contact-us/dashed-line.png) repeat-x 0 0;
				height: 5px;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	.am-1 {
		background: url(/images/career/hr-img1.png) 50% 0 no-repeat !important;
	}
	.am-2 {
		background: url(/images/career/hr-img3.png) 50% 0 no-repeat !important;
	}
	.am-3 {
		background: url(/images/career/hr-img2.png) 50% 0 no-repeat !important;
	}
	.am-4 {
		background: url(/images/career/hr-img5.png) 50% 0 no-repeat !important;
	}
	.am-5 {
		background: url(/images/career/hr-img4.png) 50% 0 no-repeat !important;
	}
	.am-6 {
		background: url(/images/career/hr-img6.png) 50% 0 no-repeat !important;
	}
	ul li {
		padding-top: 63px !important;
		span {
			font-size: 13px;
		}
	}
	.extra-wrap {
		max-width: 574px;
		margin: 0 auto;
	}
}

.mobile-off {
    display: none;
    @include responsive(desktop){
    	display: block;
    }
}
.mobile-on {
    display: block;
    @include responsive(desktop){
    	display: none;
    }
}

.o-contact-form{
	&--white-bg{
		-webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
		-ms-box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
		-o-box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
		box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
	}
}

.form-control {
    border: 0 !important;
    display: inline-block !important;
    background: transparent !important;
    box-shadow: none !important;
    height: 34px;
}
.slick-dots {
    margin-bottom: 30px;
    text-align: center;
}

.slick-dots li {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-indent: -10000px;
    width: 5px;
    height: 5px;
    background-color: #9B9B9B;
    margin: 0 10px;
    vertical-align: middle;
}

.quickform {
	.o-contact-form--white-bg {
		box-shadow: none;
		@include responsive(desktop){
			box-shadow: 0 0 20px rgba(0,0,0,0.2);
		}
	}
}


.career-bnr {
	ul.skill-page-mod li:nth-child(4) {
		@include media-query(992px) {
			flex: 0 0 120px;
		}
	}
	ul.skill-page-mod {
		display: none;
		@include media-query(992px) {
			display: flex;
		}
	}
}

.p-skills-icon{
	background: url(/images/career/form-icon9.png) no-repeat center left;
	width: 85%;
	margin: 0 auto;
	border-bottom: 1px solid #000!important;
	min-height: 64px;
}
.s-skills-icon{
	background: url(/images/career/form-icon9.png) no-repeat center left;
	width: 85%;
	margin: 0 auto;
	border-bottom: 1px solid #000!important;
	min-height: 64px;
}
#primary-skills,
#secondary-skills{
	height: 0px;
    padding: 20px 0 0 40px;
    overflow: hidden;
}

.select2-selection--multiple{
	border: 0!important;
    //border-bottom: 1px solid #000!important;
    border-radius: 0!important;
    padding: 20px 20px 20px 0!important;
    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat",sans-serif!important;

}

.select2-container .select2-search--inline .select2-search__field{
	font-family: "Montserrat",sans-serif!important;
}
.select2-results__option{
	font-family: "Montserrat",sans-serif!important;
}

.job-inner-right .select2-container{
	width: 85%!important;
	text-align: left;
}

.job-inner-right input{
	width: 85%;
}
.job-inner-right .select2-selection--multiple{
	border-bottom: 0!important;
}
.job-inner-right .select2-selection__choice:after{
	display: none;
}
.job-inner-right .notice-period {
    background: url(/images/career/form-icon6.png) no-repeat center left;
}
.job-inner-right .position-icon {
    background: url(/images/career/form-icon9.png) no-repeat center left;
}
input.years-of-experience{
	background: url(/images/career/job-icon-5.png) 0 50% no-repeat !important;
}
.job-inner-right .attach {
    background: url(/images/common/form-icon-6.png) 40px 20px no-repeat;
    font-family: "Montserrat",sans-serif;
    padding: 20px 50px 20px 84px;
    text-align: left;
}
.job-inner-right #attachedFiles li input{
	width: 80%!important;
	border: 0!important;
	margin: 0!important;
	padding: 0!important;
}
.job-inner-right #attachedFiles li button{
	display: inline-block!important;
	border: 0!important;
	margin: 0 10px 0 0!important;
	padding: 0!important;
}
.job-inner-right #attachedFiles li button:after{
	display: none;
}

.job-inner-right .modal .close{
	width: auto!important;
    border: 0!important;
    padding: 0!important;
    margin: 0!important;
}
.job-inner-right .modal p{
	color: #000!important;
	text-align: left!important;
}
.job-inner-right .modal input{
	border-bottom: 0!important;
	padding: 0!important;
    margin: 0!important;
}
.job-inner-right .select2-selection__choice__remove {
    width: auto!important;
    margin: 0;
}
.job-inner-right .select2-selection__choice__remove:after{
    display: none;
}


[data-tip] {
	position:relative;
}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #1a1a1a;
	position:absolute;
	top:50px;
	left:105px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:55px;
	left:40px;
	padding:15px 20px;
	background:#1a1a1a;
	color:#fff;
	z-index:9;
	font-size: 1em;
	height:60px;
	line-height:18px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/*white-space:nowrap;
	word-wrap:normal;*/
	width: 85%;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}

#django-o-contact-form .alert-danger{
	margin: 0 40px;
}


.career-form .select2-container .select2-search--inline .select2-search__field {
	height: 20px;
}
.select2-container .select2-search--inline .select2-search__field {
	height: 20px;
}



/*Widget css*/
.career-widget-section {
	#freshteam-widget {
		background: none;
		min-height: 170px;
		.content {
			width: 100%;
			position: relative;
		}
		.advanced-search {
			background: none;
			padding: 0 0 20px;
			.search-fields {
				background: #fff;
				padding: 30px 30px 0;
				border: 1px solid #ccc;
				border-bottom: 0;
				border-top-right-radius: 8px;
				border-top-left-radius: 8px;
				margin-bottom: 0 !important;
				.toggle-and-clear {
					flex: 0 0 100%;
					@include media-query(992px) {
						flex: 0 0 195px;
					}
					.clear-button {
						flex: 1;
						border-left: 0;
						position: relative;
						&:before {
							content: '';
							position: absolute;
							background: #ccc;
							height: 25px;
							width: 1px;
							top: 2px;
    						left: 6px;
						}
						button {
							text-align: left;
							@include media-query(992px) {
								text-align: center;
							}
						}
					}
				}
			}
			.input-field {
				background: #fff;
    			padding: 0 30px 30px;
				border: 1px solid #ccc;
				border-top: 0;
				border-bottom-right-radius: 8px;
				border-bottom-left-radius: 8px;
				input::placeholder {
					opacity: 1;
					color: #333;
				}
			}
			h3 {
				font-size: 30px;
				text-align: center;
				font-weight: 600;
				font-family: Montserrat,sans-serif;
				width: 100%;
				margin-bottom: 25px !important;
				@include media-query(720px) {
					margin-bottom: 25px;
				}
				@include media-query(992px) {
					font-size: 48px;
    				line-height: 61px;
					margin-bottom: 50px;
				}
				&:before {
					display: none;
				}
			}
			.select2-container--default {
				height: 50px;
				font-family: Montserrat,sans-serif;
			}
			.input-field {
				.form-control {
					height: 50px;
					border-bottom: 1px solid #ccc !important;
					border-radius: 0;
					font-family: Montserrat,sans-serif;
					font-size: 16px;
    				font-weight: 600;
					margin-bottom: 0;
					margin-top: 10px;
					@include media-query(992px) {
						font-size: 18px;
					}
				}
				svg {
					left: 30px;
					width: 18px;
					height: 18px;
					top: 25px;
				}
			}
			
			.select2-container--default .select2-selection--multiple::before {
				margin-top: 0px;
			}
			.select2-container--default .select2-selection--multiple .select2-selection__choice {
				margin-top: 2px;
				padding-left: 0;
				margin-left: 0;
			}
			.select2-container--default .select2-selection--multiple .select2-selection__clear {
				padding-right: 15px;
			}
			.title-and-clear {
				display: block;
				@include media-query(720px) {
					display: flex;
				}
			}
		}
		.job-role-list {
			.role-title {
				display: flex;
				align-items:center;
				margin-bottom: 20px;
				@include media-query(720px) {
					margin-bottom: 30px;
				}
				h5 {
					flex: 1;
					text-align: left;
					position: relative;
					font-size: 22px;
					color: #333;
					font-weight: 600;
					font-family: Montserrat,sans-serif;
					margin-bottom: 0;
					@include media-query(992px) {
						font-size: 30px;
						padding-bottom: 10px;
						margin-bottom: 0;
						margin-top: 5px;
					}
					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: -5px;
						width: 60px;
						height: 2px;
						background: #2dbe70;
						display: none;
						@include media-query(720px) {
							display: block;
						}
						@include media-query(992px) {
							bottom: 0;
						}
					}
				}
			}
			.role-count {
				margin-right: 0;
				align-self: center;
				background: none;
				color: #4b8fe2;
				font-weight: 600;
				border-radius: 5px;
				font-size: 15px;
				font-family: Montserrat,sans-serif;
				@include media-query(992px) {
					font-size: 16px;
				}
			}
			.job-list {
				.job-title {
					font-size: 20px;
					font-weight: 500;
					font-family: Montserrat,sans-serif;
					@include media-query(992px) {
						font-size: 24px;
					}
				}
				.job-desc {
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					font-family: Montserrat,sans-serif;
				}
				.location-info {
					font-family: Montserrat,sans-serif;
					font-size: 15px;
					@include media-query(992px) {
						font-size: 18px;
					}
					br {
						display: inline-block;
					}
					.location-icon {
						color: #07B260;
					}
				}
				.job-location {
					display: flex;
					align-items: center;
					text-align: left;
					@include media-query(992px) {
						display: block;
						text-align: right;
						flex: 1;
					}
				}
				li {
					transition: .3s all ease-in;
				}
			}
			.mobile-role-count {
				color: #4b8fe2;
			}
			.no-jobs-found{
				.not-found-title {
					font-family: Montserrat,sans-serif;
				}
				p {
					font-family: Montserrat,sans-serif;
					font-size: 16px;
					@include media-query(992px) {
						font-size: 18px;
					}
				}
			}
		}
		.job-details-header {
			background: linear-gradient(138deg,#165b5e 0,#0f3a4a 17%,#091b38 50%);
			padding: 30px 15px;	
			@include media-query(992px) {
				padding: 30px;	
			}
			.col-xs-4 {
				text-align: left;
				@include media-query(992px) {
					text-align: right;
					flex: 1;
				}
			}
			h1 {
				font-family: Montserrat,sans-serif;
				text-align: left !important;
			}
			.btn-primary {
				background: #ff5800;
				color: #fff;
				font-weight: 500;
				border: 1px solid #ff5800;
				margin: 0 auto!important;
				width: auto;
				display: block;
				@include media-query(992px) {
					width: auto;
					margin: 0 !important;
					display: inline-block;
				}
			}
			.text-color {
				display: flex;
				margin-top: 20px;
				font-family: Montserrat,sans-serif;
				margin-bottom: 20px;
				@include media-query(992px) {
					margin-bottom: 0;
				}
				div {
					margin-left: 20px;
				}
			}

		}
		#freshteam-jobs-back {
			display: flex;
    		align-items: center;
			margin-bottom: 15px;
			@include media-query(720px) {
				margin-bottom: 20px;
			}
			&:hover {
				svg {
					fill: #2dbe70;
				}
				color: #2dbe70;
			}
		}
		.icon-arrow-left {
			width: 15px;
			margin-right: 10px;
		}
		.job-details-content {
			margin-top: 5px;
			@include media-query(992px) {
				margin-top: 25px;
			}
			p {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 25px;
				font-family: Montserrat,sans-serif;
				@include media-query(992px) {
					font-size: 18px;
					line-height: 27px;
				}
				br {
					display: none;
				}
				strong {
					font-size: 20px;
					margin-bottom: 25px;
					display: block;
					margin-top: 20px;
					font-weight: 600;
					font-family: Montserrat,sans-serif;
					@include media-query(992px) {
						font-size: 24px;
					}
				}
			}
			div {
				font-family: Montserrat,sans-serif;
				margin-bottom: 25px;
				br {
					display: none;
				}
				strong {
					font-size: 20px;
					margin-bottom: 25px;
					display: block;
					margin-top: 20px;
					font-weight: 600;
					display: inline;
					@include media-query(992px) {
						font-size: 24px;
					}
				}
			}
			ul {
				li {
					font-size: 16px;
					line-height: 24px;
					font-weight: 400;
					margin-bottom: 15px;
					padding-left: 30px;
					position: relative;
					font-family: Montserrat,sans-serif;
					@include media-query(992px) {
						font-size: 18px;
						line-height: 27px;
					}
					&:before {
						content: '';
						position: absolute;
						background: url(/images/common/green-arrow.png) no-repeat 0 0 / contain;
						width: 20px;
						height: 20px;
						left: 0;
						top: 4px;

					}
				}
			}
		}
		.select2-container--default .select2-selection--multiple {
			border: 1px solid #ccc !important;
			border-radius: 8px !important;
			padding-left: 12px !important;
			background: #f7f7f7;
		}
		.col-xs-8 {
			width: 100%;
			@include media-query(992px) {
				width: 66.66667%;
			}
		}
	} 
	.select2-selection--multiple {
		padding: 8px 20px 0px 0!important;
	}
	.remote-toggle {
		span {
			font-family: Montserrat,sans-serif;
			font-size: 15px;
		}
	}
	#job-applicant-form
	{
		.application-form
		{
			margin: 25px auto;
			background-color: $white-color;
			padding: 25px;
		}
	}

}


p.any-q-a {
    margin: 15px auto 25px;
}

#freshteam-widget .search-fields .clear-button>button {
	color: #ff5800 !important;
	padding: 0;
}
#freshteam-widget .job-list .job-location .location-icon {
    color: #4b8fe2 !important;
	flex: 1;
	text-align: right;
	@include media-query(720px) {
		display: inherit;
		align-items: inherit;
		flex: inherit;
		text-align: inherit;
	}
}
#freshteam-widget .icon-arrow-right {
	fill: #4b8fe2 !important;
}
#freshteam-widget .job-list>li:hover {
    background-color: #e5fff1 !important;
	-webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.15);
	-moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.15);
	box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.15);
}
#freshteam-widget .clear-button-mobile {
	color: #ff5800 !important;
	position: absolute;
    z-index: 9999;
    bottom: 0;
    left: 140px;
	width: auto;
		&:before {
			content: '';
			position: absolute;
			background: #ccc;
			height: 25px;
			width: 1px;
			top: 0;
    		left: -4px;
		}
	@include media-query(720px) {
    width: inherit;
	}
}
#freshteam-widget {
	.select2-container--default:not(.form-control-highlight) .select2-search__field {
		padding-left: 0 !important;
	}
	.select2-container--default .select2-selection--multiple .select2-selection__rendered {
		padding: 3px 0 !important;
	}
	#ft-input-close-icon.close-icon {
		width: auto;
		right: 35px !important;
		top: 18px;
	}
}


.career-widget-section{
	#freshteam-widget {
		font-family: Montserrat,sans-serif !important;
		.application-form {
			padding: 5px 15px 25px;
			@include media-query(992px) {
				max-width: 980px;
				border: 1px solid #ebeaea;
				border-radius: 8px;
				box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
				margin-top: 70px;
				padding: 25px 35px 50px;
			}
			#freshhr_applicant_form {
				.form-group {
					&:nth-child(4) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							margin-right: 15px;
							display: inline-block;
						}
					}
					&:nth-child(5) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							margin-right: 15px;
							display: inline-block;
						}
					}
					&:nth-child(6) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							display: inline-block;
						}
					}
					&:nth-child(7) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							margin-right: 15px;
							display: inline-block;
						}
					}
					&:nth-child(8) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							margin-right: 15px;
							display: inline-block;
						}
					}
					&:nth-child(9) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							display: inline-block;
						}
					}
					&:nth-child(10) {
						width: 100%;
						@include media-query(992px) {
							// width: 48.9%;
							// display: inline-block;
							margin-right: 15px;
						}
					}
					&:nth-child(11) {
						width: 100%;
						@include media-query(992px) {
							// width: 48.9%;
							// display: inline-block;
						}
					}
					&:nth-child(19) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							margin-right: 15px;
							display: inline-block;
						}
					}
					&:nth-child(20) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							display: inline-block;
							margin-right: 15px;
						}
					}
					&:nth-child(21) {
						width: 100%;
						@include media-query(992px) {
							width: 31.86%;
							display: inline-block;
						}
					}
					&:nth-child(22) {
						width: 100%;
					}
					input, textarea, select {
						border-bottom: 1px solid #ccc !important;
						border-radius: 0;
						@include media-query(992px) {
							border: 1px solid #ccc !important;
						}
					}
					font-family: Montserrat,sans-serif !important;
					@include media-query(992px) {
						margin-bottom: 25px;
					}
					label {
						font-size: 14px;
						line-height: 22px;
						padding-left: 0;
						margin-left: 0;
						@include media-query(992px) {
							font-size: 16px;
							line-height: inherit;
						}
						&.complex-field-label {
							font-size: 18px;
						}
					}
					.link-label {
						padding-right: 50px;
						@include media-query(992px) {
							padding-right: 0;
						}
					}
				}
				.link-group {
					position: relative;
					.form-group {
						width: 100% !important;
						margin-right: 0 !important;
					}
					button.btn-add {
						position: relative;
						top: 0;
						right: 0;
						width: 40px;
						height: 40px;
						color: #333;
						border-radius: 5px;
						z-index: 99;
						background: #dde9f7;
						transition: .3s all ease-in-out;
						@include media-query(992px) {
							top: -41px;
							margin: 0 0 auto auto;
						}
						&:hover {
							background: darken(#dde9f7, 10%);
						}
					}
				}
				.employer-group {
					.form-group {
						&:nth-child(1) {
							width: 100%;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
								margin-right: 15px;
							}
						}
						&:nth-child(2) {
							width: 100%;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
							}
						}
						&:nth-child(3) {
							width: 100%;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
								margin-right: 15px;
							}
						}
						&:nth-child(4) {
							width: 100%;
							margin-right: 0;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
							}
						}
						&:nth-child(5) {
							width: 100%;
							margin: 10px 0 20px;
						}
						&:nth-child(6) {
							width: 100%;
							textarea {
								height: 70px;
							}
						}
						.form-group {
							width: 100%;
							display: block;
							margin-bottom: 0;
							margin-right: 0;
						}
					}
					label {
						background: #fbfbfb;
					}
				}
				.education-group {
					.form-group {
						&:nth-child(1) {
							width: 100%;
							@include media-query(992px) {
								width: 31.86%;
								display: inline-block;
								margin-right: 15px;
							}
						}
						&:nth-child(2) {
							width: 100%;
							@include media-query(992px) {
								width: 31.86%;
								display: inline-block;
								margin-right: 15px;
							}
						}
						&:nth-child(3) {
							width: 100%;
							@include media-query(992px) {
								width: 31.86%;
								display: inline-block;
							}
						}
						&:nth-child(4) {
							width: 100%;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
							}
						}
						&:nth-child(5) {
							width: 100%;
							margin-right: 0;
							@include media-query(992px) {
								width: 48.9%;
								display: inline-block;
							}
						}
						&:nth-child(6) {
							width: 100%;
							@include media-query(992px) {
								margin-right: 0;
							}
						}
						&:nth-child(7) {
							width: 100%;
							margin-right: 0;
						}
						.form-group {
							width: 100%;
							display: block;
							margin-bottom: 0;
						}
					}
					label {
						background: #fbfbfb;
					}
				}
			}
			h3 {
				font-size: 22px;
				font-family: Montserrat,sans-serif;
				color: #333;
				font-weight: 600;
				@include media-query(992px) {
					font-size: 28px;
				}
			}
			.g-recaptcha {
				&>div {
					width: 100% !important;
					@include media-query(992px) {
						width: 304px !important;
					}
				}
				iframe {
					width: 100%;
				}
			}
		}
		.btn-add {
			background: #4b8fe2;
			border: 1px solid #4b8fe2;
			color: #fff;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 200px;
			transition: .3s all ease-in-out;
			@include media-query(992px) {
				width: auto;
				margin-left: 0;
				margin-right: 0;
				display: inherit;
			}
			&:hover {
				color: #eae7e7;
				background: darken(#4b8fe2, 10%);
			}
			&:focus {
				outline: 0;
				border: 1px solid #4b8fe2;
				color: #fff;
			}
		}
		#applicant_submit {
			margin: 0 auto;
			background: #ff5800;
			border: 1px solid #ff5800;
			font-family: Montserrat,sans-serif !important;
			font-weight: 500;
			display: block;
			border-radius: 5px;
			@include media-query(992px) {
				display: inline-block;
			}
		}
		.resume-upload {
			.upload-file {
				font-size: 16px;
			}
			#uploadValue {
				font-size: 15px;
				padding: 8px 10px;
				border-radius: 5px;
				text-transform: capitalize;
				font-weight: 500;
				color: #333;
			}
			&:hover {
				#uploadValue {
					background: #4b8fe2;
					border-color: #4b8fe2;
					color: #fff;
				}
			}
		}
		.complex-field {
			background: #fbfbfb;
			padding: 10px 10px 20px;
			@include media-query(992px) {
				padding: 25px 25px 25px;
    		}
			&-label {
				background: #fbfbfb;
			}
		}
		.vertical-align {
			span {
				position: relative;
				top: -2px;
			}
		}
		.job-details-content {
			.container-fluid {
				padding-left: 0;
				padding-right: 0;
				@include media-query(992px) {
					padding-left: 15px;
    				padding-right: 15px;
				}
			}
		}
		.form-control {
			height: 42px;
		}
		.btn-remove {
			background: #f5e0e0;
			transition: .3s all ease-in-out;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 200px;
			@include media-query(992px) {
				width: auto;
				margin-left: 0;
				margin-right: 0;
				display: inherit;
			}
			&:hover {
				background: darken(#f5e0e0, 10%);
			}
		}
	}
}
.padding-25-modify{
	padding-top: 25px;
	@include media-query(992px){
		padding-top: 0;
	}
}
.job-left{
    h1{
        font-size: 24px;
        @include media-query(992px){
            font-size: 24px;
        }
        span{
            font-size: 24px;
            @include media-query(992px){
                font-size: 24px;
            }
        }
    }
}
.faq-pan-modify{
	position: relative;
}


/*New bnr video section*/
.video-testimonial {
    display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;
	@include media-query(992px){
		flex-wrap: nowrap;
		margin-bottom: 40px;
	}
	&-wrapper {
    	flex: 0 0 100%;
		padding: 15px 0;
		margin: 0 5px;
		@include media-query(992px){
			flex: 1;
			padding: 0 15px;
			margin: 0;
		}
		h6 {
			font-size: 14px;
			color: #fff;
			font-weight: 600;
			text-align: center;
			margin-top: 15px;
			@include media-query(992px){
				font-size: 18px;
				margin-top: 25px;
				text-align: left;
			}
			span {
				display: block;
				color: #36DF7D;
			}
		}
	}
	.pl-0 {
		padding-left: 0;
	}
	.pr-0 {
		padding-right: 0;
	}
	&-box {
		@include media-query(992px){
			height: 170px;
		}
		@include media-query(1200px){
			height: 208px;
		}
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		border: 1px solid #C5C5C5;
		.thumb-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.vid-play-btn {
			background: rgba(0,0,0,0.32);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: .3s all ease-in-out;
			&:before {
				content: '';
				position: absolute;
				background: url(/images/career/blue-play-btn.png) no-repeat center center;
				transition: .3s all ease-in-out;
				width: 62px;
				height: 62px;
			}
		}
		&:hover {
			.vid-play-btn {
				background: rgba(0,0,0,0.16);
				&:before {
					transform: scale(1.1);
				}
			}
		}
	}
	ul.slick-dots {
		margin-top: 0;
	}
}

.testimonial-clip-modal {
	z-index: 9999999;
	background: rgba(0,0,0,0.95);
	.modal-header {
		border: 0;
		height: 0;
		padding: 0;
		.btn-close {
			position: absolute;
			top: 0;
    		right: 20px;
			background-color: #fff;
			opacity: 1;
			border-radius: 50%;
			@include media-query(992px){
				top: -7px;
    			right: 64px;
			}
		}
	}
	.modal-content {
		background: none;
		padding: 0;
		border: 0;
		.modal-body {
			padding: 0;
		}
	}
	.modal-dialog {
		margin: 0 auto;
		@include media-query(992px){
			max-width: 990px!important;
		}
	}
}


.career-bnr {
	.btn-center-block {
		width: 100% !important;
		@include media-query(992px){
			width: 310px !important;
		}
	}
}

.owl-carousel {
	.owl-dots {
        text-align: center;
		margin-bottom: 30px;
        .owl-dot {
            width: 7px;
            height: 7px;
            display: inline-block;
            vertical-align: middle;
            background: #9b9b9b;
            margin: 0 5px;
            border-radius: 50%;
            cursor: pointer;
            &.active {
                background: #49c684;
                width: 10px;
                height: 10px;
            }
        }
    }
}
.vid-testimonial-slider {
	.owl-dots {
		margin-bottom: 30px;
	}
}
.o-clients {
    position: relative;
    padding: 50px 0 25px;
    text-align: center;
    position: relative;
    border-bottom: 1px solid #cae0e7;
    background-color: $white-color;
    h2{
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        color: #333;
        font-size: 17px;
        line-height: 20px;
        @include responsive(desktop){
            line-height: 25px;
            font-weight: bold;
        }
        margin: 0 0 20px 0;
        text-transform: none;
        vertical-align: middle;
    }
    // ul{
    //     // flex-wrap: wrap;
    //     // justify-content: center;
    //     // display: flex;
    //     // align-items: center;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     max-width: 360px;
    //     margin: 0 auto;
    //     height: 130px;
    //     flex-wrap: wrap;
    //     margin-bottom: 20px;
    //     @include responsive(desktop){
    //         flex-wrap: inherit;
    //         // height: 100px;
    //         height: auto;
    //         max-width: 100%;
    //     }
    //     li{
    //         // padding: 5px 5px;
    //         // flex: 0 0 33%;
    //         // text-align: center;
    //         // flex: 0 0 26%;
    //         flex: 20%;
    //         margin-bottom: 25px;
    //         background-repeat: no-repeat;
    //         background-size: contain;
    //         text-align: center;
    //         margin: 5px 11px;
    //         @include responsive(desktop){
    //             flex: 1 1 auto !important;
    //         }
    //         background-position: 50% 50%;
    //         &:nth-child(1){
    //             flex: 0 0 130px;
    //             @include responsive(desktop){
    //                 flex: 1;
    //             }
    //         }
    //         &:nth-child(2){
    //             display: none;
    //             @include responsive(desktop){
    //                 flex: 0 0 120px;
    //                 display: block;
    //             }

    //         }
    //         &:nth-child(3){
    //             @include responsive(desktop){
    //                 flex: 0 0 60px;
    //             }
    //         }
    //         &:nth-child(4){
    //             @include responsive(desktop){
    //                 flex: 0 0 90px;
    //             }
    //         }
    //         &:nth-child(5){
    //             @include responsive(desktop){
    //                 flex: 0 0 120px;
    //             }
    //         }
    //         &:nth-child(6){
    //             @include media-query(992px) {
    //                 flex: 0 0 60px;
    //             }
    //             @include media-query(1200px) {
    //                 flex: 0 0 100px;
    //             }
                
    //         }
    //         &:last-child{
    //             flex: 0 0 150px;
    //             margin-top: 15px;
    //             display: none;
    //             @include media-query(992px) {
    //                 flex: 0 0 140px;
    //                 display: block;
    //                 margin-top: 0;
    //             }
    //             @include media-query(1200px) {
    //                 flex: 0 0 200px;
    //             }
    //         }
    //     }
    // }
    .zoovu-custom-width {
        @include media-query(992px) {
            flex: 0 0 138px;
        }
    }
    .client-logo-modify {
        .client-logo-new {
            @include media-query(768px) {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }
            .client-logo-img {
                @include media-query(768px) {
                    margin: 15px 15px;
                }
            }
        }
    }

}


.sk-fading-circle {
    margin: 100px auto;
    width: 80px;
    height: 80px;
    position: relative;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #27c070;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  .why-hire-java {
      .service-wrap {
          column-count: inherit;
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .services-pan {
            @include media-query(992px) {
                flex: 0 0 31.5%
            }
        }
      }
  }


  .client-logo-slide {
    .owl-item {
        visibility: hidden;
        &.active {
            visibility: visible;
        }
    }
    .owl-nav {
        display: none;
    }
   .owl-dots {
    .owl-dot {
        background: #9b9b9b;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        border-radius: 50%;
        &.active {
            background: #49c684;
        }
    }
   }
   .owl-stage {
    display: flex;
    align-items: center;
    // @include media-query(992px) {
    //     display: block;
    // }
    .owl-item:last-child {
        @include media-query(992px) {
            height: auto;
        }
        @include media-query(1200px) {
            height: 222px;
        }
    }
   }
    .client-logo-img {
        display: flex;
        justify-content: center;
        img {
            height: 100%;
            object-fit: contain;
            @include media-query(992px) {
                height: inherit;
                object-fit: inherit;
            }
        }
    }
  }
  .nw-client-slider {
        .owl-item {
            height: 86px;
            align-items: center;
            justify-content: center;
            display: flex;
            @include media-query(992px) {
                height: auto;
            }
        }
    }

  /* Chrome version 29 and above */
  @media screen and (-webkit-min-device-pixel-ratio:0)
    and (min-resolution:.001dpcm) {
        .client-logo-slide .owl-stage .owl-item:last-child {
            @include media-query(992px) {
                height: 198px;
            }
        }
    }

    .nw-client {
        padding: 20px 0 20px;
        @include media-query(992px) {
            padding: 50px 0 25px;
        }
        .client-logo-slide {
            margin-bottom: 0;
            @include media-query(992px) {
                margin-bottom: 0 0 1rem;
            }
        }
        h2 {
            margin: 0;
            @include media-query(992px) {
                margin: 0 0 20px 0;
            }
        } 
        .owl-dots {
            display: none;
        }
    }

.splide__arrows {
    display: none;
}

.dev-client {
    background: #F5F7F8 !important;
    @include media-query(992px) {
        padding: 60px 0;
    }
    h2 {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        @include media-query(992px) {
            margin-bottom: 25px !important;
        }
    }
    .client-logo-img {
        height: 96px;
        border: 1px solid #ECE9E9;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        padding: 10px;
        @include media-query(768px) {
            margin: 3px 3px !important;
            padding: 0;
        }
        @include media-query(992px) {
            flex: 1;
        }
        img {
            height: 100%;
            object-fit: contain;
        }
    }
}

.company-profile-client {
    .dev-client {
        background: #fff !important;
        border-bottom: none !important;
    }
}



.certification {
    border-bottom: 1px solid #E2E2E2;
    background: #F2F5F7;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 0;
    }
    .certification-left {
        @include media-query(992px) {
            display: flex;
            align-items: center;
        }
        h4 {
            margin-bottom: 0;
            text-align: center;
            @include media-query(992px) {
                text-align: left;
            }
        }
        svg {
            margin: 0 25px;
            display: none;
            @include media-query(992px) {
                display: inline-block;
            }
        }
    }
    .certification-list {
        @include media-query(992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
        }
    }
    .certification-items {
        align-items: center;
        justify-content: center;
        display: flex;
        @include media-query(992px) {
            flex: 1;
        }
    }
}

.o-appoinrment-header {
  $o-appoinrment-header: &;
  background-color: #0d2642;
  padding: 30px 0;
  min-height: auto;
  @include media-query(992px) {
    min-height: 117px;
  }
  &__logopan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    height: 100%;
    min-height: 57px;
    @include media-query(992px) {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }
  &__logoicon {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #a3c2d0;
    max-width: 48%;
    @include media-query(992px) {
      max-width: 160px;
      margin-right: 27px;
      padding-right: 27px;
    }
    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
  &__list {
    padding: 0 5px;
    @include media-query(992px) {
      padding: 0 20px;
    }
    &:before {
      position: absolute;
      content: "";
      width: 18px;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      background: rgb(13, 38, 66);
      background: linear-gradient(
        270deg,
        rgba(13, 38, 66, 0) 0%,
        rgba(13, 38, 66, 1) 45%
      );
      z-index: 9999;
      @include media-query(992px) {
        width: 30px;
      }
    }
    &:after {
      position: absolute;
      content: "";
      width: 18px;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgb(13, 38, 66);
      background: linear-gradient(
        90deg,
        rgba(13, 38, 66, 0) 0%,
        rgba(13, 38, 66, 1) 45%
      );
      z-index: 9999;
      @include media-query(992px) {
        width: 30px;
      }
    }
  }
  &__listarea{
    .o-appoinrment-header__item{
      width: auto!important;
    }
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 57px;
    padding: 0 10px;
    @include media-query(992px) {
      padding: 0 13px;
    }
  }
  &__itemcontent {
    margin-left: 14px;
    flex: 1;
  }
  &__itemtittle {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    margin: 0;
    color: $white-color;
    @include media-query(992px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__itemsub {
    font-family: "Poppins";
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    font-weight: 500;
    color: $white-color;
    @include media-query(992px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
.o-appointment {
  $o-appointment: &;
  padding-bottom: 22px;
  border-bottom: 1px solid #a3c2d0;
  position: relative;
  background: rgb(227, 243, 250);
  background: linear-gradient(
    180deg,
    rgba(227, 243, 250, 1) 0%,
    rgba(235, 246, 251, 0.6) 66%,
    rgba(255, 255, 255, 1) 100%
  );
  @include media-query(992px) {
    background: transparent;
  }
  &:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 100%;
    background: rgb(227, 243, 250);
    background: linear-gradient(
      180deg,
      rgba(227, 243, 250, 1) 0%,
      rgba(235, 246, 251, 0.6) 66%,
      rgba(255, 255, 255, 1) 100%
    );
    left: 0;
    top: 0;
    bottom: 0;
    display: none;
    @include media-query(992px) {
      display: block;
    }
  }

  &:after {
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  &.js-grayed-out {
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: rgb(163,194,208);
      background: linear-gradient(0deg, rgba(163,194,208,0) 0%, rgba(163,194,208,0.1) 35%, rgba(163,194,208,0.6) 50%, rgba(163,194,208,0.3) 70%, rgba(163,194,208,0) 100%);
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      filter: grayscale(0);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      opacity:1;
      animation: fadeIn ease 5s;
      -webkit-animation: fadeIn ease .5s;
      -moz-animation: fadeIn ease .5s;
      -o-animation: fadeIn ease .5s;
      -ms-animation: fadeIn ease .5s;
      filter: grayscale(1);
      @include media-query(992px) {
        width: 50%;
        height: 86%;
      }
    }
  }
  .right-border-transparent {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgb(163, 194, 208);
      background: linear-gradient(
        180deg,
        rgba(163, 194, 208, 1) 0%,
        rgba(163, 194, 208, 0.62) 70%,
        rgba(163, 194, 208, 0) 100%
      );
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  &__ortext{
      position: absolute;
      font-family: "Poppins";
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      width: 50px;
      height: 50px;
      background: #4492DC;
      border-radius: 50%;
      left: 0;
      right: 0;
      top: 52px;
      margin: auto;
      align-items: center;
      justify-content: center;
      z-index: 9;
      display: none;
      @include media-query(992px) {
        display: flex;
      }
  }
  &__formpanel {
    position: relative;
    width: 100%;
    height: auto;
    padding: 2em 0;
    @include media-query(992px) {
      padding: 52px 0 0;
      background: transparent;
    }
  }
  &__tittle {
    position: relative;
    font-family: "Poppins";
    color: #262e36;
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 12px;
    @include media-query(992px) {
      font-size: 28px;
      line-height: 46.5px;
      justify-content: flex-start;
    }
    i {
      margin-right: 10px;
    }
  }
  &__subtittle {
    font-family: "Poppins";
    color: #262e36;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
    span {
      border-bottom: 1px solid #848484;
    }
    @include media-query(992px) {
      font-size: 21px;
      line-height: 29px;
      text-align: left;
    }
  }
  &--conversation-form {
    max-width: 100%;
    width: 100%;
    margin: auto;
    @include media-query(992px) {
      padding-right: 3em;
    }
    input {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 69px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      display: inline-flex;
      padding: 10px 0 10px 16px;
      font-size: 17px;
      font-weight: 500;
      color: #262e36;
      border-radius: 5px;
      &[type=number] {
          -moz-appearance: textfield;
          -webkit-appearance: none;
          appearance: none; 
      }
      &::-webkit-outer-spin-button{
          -webkit-appearance: none;
          margin: 0;
      }
      &::-webkit-inner-spin-button{
          -webkit-appearance: none;
          margin: 0;
      }
      &.fileattached-url-mod{
        height: auto;
        padding: 0;
        margin: 0;
      }
      &#phoneNumber {
        background: #fff !important;
      }
      &::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }

      &:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }
    }
    textarea {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 157px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      float: left;
      padding: 20px 16px 16px;
      color: #262e36;
      font-size: 16px;
      font-weight: 500;
      resize: none;
      border-radius: 5px;
      &::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important;
      }

      &:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #262e36 !important;
      }
    }
  }
  &__formwrapper {
    position: relative;
    box-shadow: none !important;
  }
  &__formwrapperinside {
    padding: 0;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .o-contact-form__contact-form {
      padding: 0;
    }
  }
  &__calender {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px 0 15px;
    margin-top: 50px;
    z-index: 9;
    @include media-query(992px) {
      margin-top: inherit;
      padding: 52px 0 2em 3em;
    }
    &:before {
      position: absolute;
      content: "Or";
      font-family: "Poppins";
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      width: 50px;
      height: 50px;
      background: #4492DC;
      border-radius: 50%;
      left: 0;
      right: 0;
      top: -45px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      @include media-query(992px) {
        display: none;
      }
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      background: rgb(163, 194, 208);
      background: linear-gradient(
        270deg,
        rgba(163, 194, 208, 0) 0%,
        rgba(163, 194, 208, 0.68) 50%,
        rgba(163, 194, 208, 0) 100%
      );
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: -20px;
      margin: auto;
      @include media-query(992px) {
        display: none;
      }
    }
  }
  &__attach {
    position: relative;
    background: url(/images/two-for-one/attach-icon-black.svg) no-repeat center;
    background-position: 0 5px !important;
    padding: 0 0 0 35px !important;
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 10px 0 0!important;
    @include media-query(992px) {
      margin:10px 0 !important;
    }
    .o-contact-form__attachement-text {
      font-family: "Poppins";
      color: #262e36;
      font-size: 16px;
      line-height: 30px;
      label {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
  &__calendercontainer {
    #calendar {
      max-width: 375px;
      margin: auto;
      .ui-datepicker-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0 5px;
        .ui-datepicker-prev {
          order: 1;
          // pointer-events: none;
          // cursor: default;
          cursor: pointer;
          .ui-icon {
            font-size: 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3cc065;
            border-radius: 50%;
            transition: .3s all ease-in-out;
            &:hover {
              background: #ff5800;
            }
            &:after {
              content: "‹";
              font-size: 30px;
              line-height: 0;
              color: #fff;
              position: relative;
              top: -2px;
              left: -1px;
            }
          }
          &.ui-state-disabled {
            pointer-events: none;
            opacity: .4;
            .ui-icon {
              filter: grayscale(100%);
            }
          }
        }
        .ui-datepicker-title {
          order: 2;
          font-family: "Poppins";
          margin: 0 15px;
          font-weight: 400;
          font-size: 16px;
        }
        .ui-datepicker-next {
          order: 3;
          cursor: pointer;
          .ui-icon {
            font-size: 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3cc065;
            border-radius: 50%;
            transition: .3s all ease-in-out;
            &:hover {
              background: #ff5800;
            }
            &:after {
              content: "›";
              font-size: 30px;
              line-height: 0;
              color: #fff;
              position: relative;
              top: -2px;
              right: -1px;
            }
          }
          &.ui-state-disabled {
            pointer-events: none;
            opacity: .4;
            .ui-icon {
              filter: grayscale(100%);
            }
          }
        }
      }
      .ui-datepicker-calendar {
        width: 100%;
        font-family: "Poppins";
        font-size: 12px;
        th {
          font-weight: 400;
          text-align: center;
          height: 44px;
          width: 44px;
          color: #1a1a1a;
          text-transform: uppercase;
        }
        td {
          font-size: 16px;
          text-align: center;
          height: 44px;
          width: 44px;
          color: rgba(26, 26, 26, 0.612);
        }
      }
    }
    .ui-datepicker-week-end.ui-datepicker-today {
      background: none;
      pointer-events: none;
      a {
        background: none;
        color: inherit;
        font-weight: 500;
        &::after {
          display: none;
        }
      }    
    }
  }
  &__calendertittle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 32px;
    color: $black-color;
    text-align: center;
  }
  &__emailtext {
    position: relative;
    font-family: "Poppins";
    font-size: 20px;
    line-height: 32px;
    color: $black-color;
    font-weight: 600;
    margin-top: 25px;
    @include media-query(992px) {
      font-size: 22px;
      line-height: 36px;
      margin-top: 50px;
      z-index: 3;
    }
  }
  &__emailanchor {
    color: #4a90e2;
    text-decoration: underline;
  }
  .c-btn {
    font-family: "Poppins";
    max-width: 100% !important;
    width: 100%;
    margin: 20px 0 0;
    text-transform: capitalize;
    font-size: 17px;
    padding: 15px;
    float: none;
    z-index: 2;
    @include media-query(992px) {
      max-width: 250px !important;
      float: right;
      margin: 0 0 0;
    }
    svg {
      margin-left: 10px;
    }
    &:hover {
      background: #fff;
      color: #ff5800;
      g {
        fill: #ff5800;
      }
    }
  }
  &__flipform{
    max-width: 400px;
    width: 100%;
    margin: 30px auto 0;
    input {
      font-family: "Poppins";
      max-width: 100%;
      width: 100%;
      height: 69px;
      border: 1px solid #7fa0b0;
      margin: 0 0 20px 0;
      display: inline-flex;
      padding: 20px 0 20px 16px;
      font-size: 17px;
      font-weight: 500;
      color: #262e36;
      border-radius: 5px;
      &::-webkit-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }

      &:-ms-input-placeholder {
        font-family: "Poppins";
        font-weight: 500 !important;
        font-size: 17px !important;
        color: #262e36 !important;
      }
    }
    .c-btn{
      border-radius: 5px;
      font-weight: 600;
      float: none !important;
      margin: 15px auto 0 !important;
      max-width: 100% !important;
      &:disabled {
        pointer-events: none;
        background-color: #f2b493;
        border-color: #f2b493;
      }
    }
  }
}

//check

.ui-datepicker-current {
  a {
    position: relative;
    background-color: rgba(0, 105, 255, 0.067);
    color: #0060e6;
    font-weight: 700;
    width: 44px;
    height: 100%;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 105, 255, 0.149);
    }
    &:after {
      content: ".";
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: auto;
      font-weight: bold;
      font-size: 25px;
    }
  }
}
.ui-datepicker-noneworking {
  a {
    background-color: rgba(0, 105, 255, 0.067);
    color: #0060e6;
    font-weight: 700;
    width: 44px;
    height: 100%;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 105, 255, 0.149);
    }
  }
}

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  border-radius: 5px;
  border: 1px solid #a3c2d0;
  border-top: 5px solid #3cc065;
  position: relative;
  overflow: hidden;
}

.flip-container.hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 350px;
  height: 430px;
  margin: auto;
  @include media-query(767px) {
    width: 400px;
  }
  @include media-query(992px) {
    margin: 0;
  }
  @include media-query(1025px) {
    width: 517px;
  }
}

.flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;

  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;

  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;

  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 15px;
  .back-logo{
    position: relative;
    margin: 0 auto 25px;
    max-width: 428px;
  }
  .back-anchore{
    font-family: "Poppins";
    position: relative;
    font-size: 20px;
    line-height: 26px;
    color: #262e36;
    font-weight: 600;
    svg{
      position: relative;
      margin-right: 4px;
      margin-top: -2px;
      transition: 1s all ease-in-out;
    }
    &:hover{
      color: #ff5800;
      svg{
        transform: translate(-10px,0);
        g{
          stroke: #ff5800;;
        }
      }
    }
  }
}

.front {
  z-index: 2;
}

.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: #F5F9FA;
  z-index: 3;
}

body{
  &.js-mobilebodygray{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .mobile-overlay{
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(163,194,208,0.5);
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      animation: fadeIn ease 1s;
      -webkit-animation: fadeIn ease 1s;
      -moz-animation: fadeIn ease 1s;
      -o-animation: fadeIn ease 1s;
      -ms-animation: fadeIn ease 1s;
    }
    .flip-container{
      position: fixed;
      top:50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      z-index: 999;
      background: #fff;
    }
    .o-appointment{
      position: initial;
    }
    .o-appointment__calender{
      position: initial;
    }
  }
}
.js-conversation-error{
  text-align: left;
  @include media-query(1180px) {
    position: absolute;
    left: 0;
    bottom: -78px
  }
}
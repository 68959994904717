.hybrid-tech-use {
    &.o-key-diff-modify {
        h2 {
            @include media-query(992px) {
                margin-bottom: 50px;
            }
        }
    }
    .o-key-diff-box {
        border-left-width: 5px;
        @include media-query(992px) {
            padding: 35px 50px 35px 35px;
        }
        img {
            margin-bottom: 15px;
        }
    }
}

.hybrid-dev-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box3,
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box2 {
            @include media-query(992px) {
                margin-top: 30px;
            }
        }
        .d-trans-pro-steps:before {
            @include media-query(992px) {
                height: 80%;
            }
        }
    }
}



/*Flutter development*/
.flutter-dev-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box3,
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box2 {
            @include media-query(992px) {
                margin-top: 30px;
            }
        }
        .d-trans-pro-content-box5,
        .d-trans-pro-content-box6 {
            @include media-query(992px) {
                margin-top: 120px;
            }
        }
        .d-trans-pro-steps:before {
            @include media-query(992px) {
                height: 90%;
            }
        }
    }
}

.sd-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-steps:before {
            @include media-query(992px) {
                height: 86%;
            }
        }
    }
}
.o-banner {
  background-color: $blue-color;
  // padding: 50px 0;
  padding: 45px 0 20px;
  text-align: center;
  position: relative;
  @include responsive(desktop) {
    text-align: left;
    padding: 85px 0 50px;
  }
  // h1 {
  //   font-size: 24px;
  //   line-height: 40px;
  //   color: #fff;
  //   padding: 35px 0;
  // }
  h1 {
    color: $white-color;
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
    @include responsive(desktop) {
      font-size: 62px;
      line-height: 75px;
    }
    .subheading {
      font-size: 24px;
      padding-bottom: 20px;
      display: block;
      line-height: normal;
      text-transform: capitalize;
      font-weight: 600;
      @include responsive(desktop) {
        font-size: 40px;
        line-height: 50px;
        font-weight: 500;
      }
    }
  }
  .btn {
    margin: 0 auto;
    @include responsive(desktop) {
      margin: 0;
    }
  }
}
.hm-page {
  .o-banner {
    @include media-query(992px) {
      padding: 85px 0 80px;
    }
    h1 {
      font-size: 26px;
      line-height: 40px;
      color: #fff;
      padding: 25px 0 20px;
    }
    h2 {
      color: $white-color;
      font-size: 36px;
      font-weight: 700;
      padding-bottom: 20px;
      line-height: 44px;
      @include responsive(desktop) {
        font-size: 62px;
        line-height: 75px;
      }
      .subheading {
        font-size: 24px;
        padding-bottom: 20px;
        display: block;
        line-height: normal;
        text-transform: capitalize;
        font-weight: 600;
        @include responsive(desktop) {
          font-size: 40px;
          line-height: 50px;
          font-weight: 500;
          margin-top: 10px;
        }
      }
    }
  }
}
.bnr-icon {
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  height: 160px;
  @include responsive(desktop) {
    display: none;
  }
}
.home-bnr-icon {
  flex-wrap: wrap;
  margin-bottom: 20px;
  li {
    flex: 0 0 33%;
    margin-bottom: 25px;
    font-size: 0;
    background-repeat: no-repeat;
    &:nth-child(1) {
      background-position: 0 -140px;
    }
    &:nth-child(2) {
      background-position: -115px -140px;
    }
    &:nth-child(3) {
      background-position: -244px -140px;
    }
    &:nth-child(4) {
      background-position: -380px -145px;
    }
    &:nth-child(5) {
      background-position: -500px -140px;
    }
  }
}

.video-pan {
  display: none;
  @include responsive(desktop) {
    position: relative;
    overflow: hidden;
    display: block;
    height: 323px;
    width: 98%;
    .banner-logo {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      // transform: translate(-50%, -50%);
    }
  }
  @include media-query(1199px) {
    height: 400px;
    width: 100%;
  }

  video{
    width: 97%;
  }
}
.home-award-logo {
  display: none;
  margin-top: 10px;
  @include media-query(992px) {
    background-size: contain;
    display: block;
    width: 100%;
    height: 186px;
    background-repeat: no-repeat;
    opacity: .9;
    background-position: center center;
  }
  @include media-query(1200px) {

  }
}

.banner-right {
  text-align: center;
  z-index: 9999;
  @include responsive(desktop) {
    text-align: left;
  }
  .anchor-link {
    text-align: center;
    @include responsive(desktop) {
      text-align: left;
    }
  }
  .btn {
    @include responsive(desktop) {
      margin: 0;
    }
  }
}
#myModal1 .modal-dialog {
  width: 100%;
  background: #000;
  margin: 0 auto;
  height: 100%;
  transform: translate(0, 0);
  max-width: 100% !important;
}
.contact-modal {
  background: rgba(0,0,0,0.4);
}
.ytp-large-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  transition: opacity .25s cubic-bezier(0,0,0.2,1);
  z-index: 63;
}
#myModal1 .modal-body {
  padding: 80px 0;
}
.modal-body {
  position: relative;
}
#myModal1 button.close {
  z-index: 999;
  position: relative;
  right: 0;
  top: 0;
  background: #ffffff;
  opacity: 1;
  padding: 10px;
}
div#myModal1 {
  z-index: 9999999;
}

.break-line {
  display: block;
  @include responsive(desktop) {
    display: inline;
  }
}

.banner-right {
  h1 {
    br {
      display: none;
      @include responsive(desktop) {
        display: block;
      }
    }
  }
}

.hm-about-section {
  p {
    strong {
      margin-top: 20px;
    }
  }
}

ul.home-bnr-group-logo {
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  @include media-query(992px) {
    display: flex;
  }
  li {
    padding: 15px 9px;
    opacity: .8;
    @include media-query(992px) {
      flex: 0 0 25%;
      display: flex;
      justify-content: center;
    }
    @include media-query(1200px) {
      padding: 15px 20px;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      flex: 1;
    }
  }
}

.full-witdth-box{
  width: 100%;
  -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
    -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
    box-shadow: 0px 0px 15px 0px #e3e3e3;
    margin-bottom: 20px;
    padding: 25px;
    overflow: hidden;
    text-align: left;
    @include media-query(768px){
        padding: 60px;
    }
}
.full-witdth-box .u-pull-left{
    float: none;
    
    @include media-query(992px){
        float: left;
    }
}
.full-witdth-box .u-pull-left img{
    max-width: 233px;
    padding-bottom: 20px;
}

.fb-right-pan{
    padding-left: 0;
    width: 100%;
    @include media-query(992px){
        padding-left: 60px;
        width: 62%;
    }
    @include media-query(1200px) {
        padding-left: 60px;
        width: 70%;
    }
    h3 {
        font-size: 24px;
        font-weight: 600;
    }

}
.fb-right-pan p{
    font-size: 16px;
    line-height: 22px;
    @include media-query(992px){
        font-size: 18px;
        line-height: 28px;
    }
    font-weight: 400;
    font-family: "Montserrat",sans-serif;
}
.fb-right-pan h3 + p {
    font-weight: 500;
    font-size: 20px;
}

.fb-right-pan ul li{
    background: url(/images/common/tick.png) no-repeat 0 2px;
    padding-left: 40px;
    font-size: 16px;
    line-height: 27px;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    background-size: 21px;
    margin-bottom: 6px;
}
.pricing-box{
    width: 100%;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 20px;
    vertical-align: top;
    margin:0 10px 40px;
    min-height: 480px;
    position: relative;
    @include media-query(992px){
      width: 30%;
      min-height: 520px;

    }
}
.pricing-box h2{
    font-size: 30px;
    line-height: 37px;
    color: #333;
    padding: 10px 0;
    position: relative;
    margin-bottom: 0;
}
.pricing-box p{
    font-weight: 500;
}
.pricing-box h2:before{
    content: "";
    width: 60px;
    height: 1px;
    background-color: #ccc;
    position: absolute;
    left:50%;
    bottom: 0;
    transform: translate(-50%, 0);
}
.pricing-box .price-text{
    text-align: center;
    position: relative;
    padding-top: 20px;
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
}

.pricing-box .price-text span{
    font-size: 30px;
    padding-top: 15px;
    color: #FF5800;
    display: block;
    text-transform: none;
    font-weight: 600;
}
.pricing-box .price-text span strong{
    font-size: 20px;
}

.pricing-box a{
    width: 228px;
    height: 46px;
    line-height: 42px;
    border: 2px solid #333333;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    transition: .3s all ease-in;
    margin: 0 auto;
    padding: 0 0 0 30px;
    display: inline-block;
    background: url(/images/common/arrow-black-new.png) no-repeat 85% 50%;
    text-align: left;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);
}
.pricing-box a:hover{
    border: 2px solid #FF5800;
    color: #FF5800;
    background: url(/images/common/arrow-orange.png) no-repeat 85% 50%;
}
.four-box{
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
    -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
    box-shadow: 0px 0px 15px 0px #e3e3e3;
    padding: 30px 30px 5px 30px;
    margin-bottom: 20px;
    @include media-query(992px){
        min-height: 286px;
        padding: 40px 30px 20px 30px;
    }
}
.four-box p{
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.four-box p span{
    font-family: "Montserrat",sans-serif;
    font-size: 20px;
    color: #4A90E2;
}
.free-terms-list li{
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 32px;
    clear: both;
}
.free-terms-list li span{
    display: inline-block;
    font-family: "Montserrat",sans-serif;
    font-size: 20px;
    color: #2DBE70;
    line-height: 51px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border:1px solid #2DBE70;
    width:51px;
    height: 51px;
    text-align: center;
    margin-right: 20px;
    float: left;
    margin-bottom: 20px;
    padding: 0 14px;
    @include media-query(768px){
        margin-right: 40px;
    }
}
.free-fact-section .all-facts ul li:last-child{
    width: 20%;
}
.free-fact-section .all-facts ul li:nth-child(3) {
    background: #009C52;
    border-color: #009C52;
}
.free-fact-section .all-facts ul li:nth-child(4) {
    background: transparent;
    border-color: #4A90E2;
}
.free-two-box{
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 15px 0px #e3e3e3;
    -moz-box-shadow: 0px 0px 15px 0px #e3e3e3;
    box-shadow: 0px 0px 15px 0px #e3e3e3;
    padding: 50px;
    background-color: #fff;
    text-align: left;
}
.free-two-box img{
    float: left;
}
.free-two-box h3{
    padding: 0 0 30px 80px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    @include media-query(768px){
        font-size: 30px;
        line-height: 40px;
    }
}

.free-two-box ul li{
    font-size: 16px;
    line-height: 28px;
    background: url(/images/common/tick.png) no-repeat 0 4px;
    padding-bottom: 20px;
    padding-left: 40px;
    background-size: 18px;
}

.free-two-box ul li.cross{
    font-size: 16px;
    line-height: 28px;
    background: url(/images/free-trial/red-cross.svg) no-repeat 0 4px;
    padding-bottom: 20px;
    padding-left: 40px;
}

.free-trial .header_left_pan{
    text-align: center;
    @include media-query(992px){
        text-align: left;
    }
}
.free-trial .header_left_pan h1{
    font-size: 30px;
    line-height: 36px;
    margin-top: 30px;
    @include media-query(992px){
        font-size: 48px;
        line-height: 54px;
        margin-top: 0;
    }
}
.free-trial .header_left_pan .btn_wrapper{
    margin: 0 auto;
    @include media-query(992px){
        margin: 0;
    }
}
.free-trial .header_left_pan p{
    display: block;
}
.free-trial .fact-section h2{
    font-size: 24px;
    line-height: 32px;
    @include media-query(992px){
        font-size: 48px;
        line-height: 61px;
    }
}

.free-trial .c-icon-desc-3-col-box h2{
    padding-bottom: 30px;
}

.hire-designer .font-size-30{
    font-size: 30px;
    line-height: 40px;
}

.MERN .skill-service .brick h3{
    color: #333!important;
}


.free-trial-banner {
    .container {
        .row {
            flex-direction: column-reverse;
            @include media-query(992px){
                flex-direction: inherit;
            }
        }
    }
}

.free-terms-list{
    li{
        display: flex;
        @include media-query(992px){
            display: inherit;
        }
    }
}
.banner-graphics-img-modify{
    justify-content: center;
    @include media-query(992px){
        justify-content: flex-end;
    }
    img{
        object-fit: contain;
    }
}
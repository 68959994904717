.box.review-item{
    margin-bottom: 30px;
    display: inline-block;
    border: 1px solid #c3c3c3;
    border-radius: 6px;
    padding: 30px 35px;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
    position: relative;
  }
  
  .review-masonry {
    column-count: 3;
    margin: 2em 0;
    column-gap: 2em;
    -webkit-column-gap: 2em;
  }
  .review-item{ 
      p {
        color: #333333;
        font-size: 15px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      h3 {
        color: #333333;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        padding-right: 15px;
      }
  }
  .review-star {
    display: inline-block;
    background: url(/images/common/star-icon.svg) no-repeat 0 0;
    width: 88px;
    height: 14px;
    margin-top: 10px;
}

.o-case-studies-top-selector .selector-dropdown {
    width: 100%;
    height: 42px;
    // border: 1px solid #979797;
    font-size: 14px;
    line-height: 42px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    margin-right: auto;
}
.o-case-studies-top-selector__client-review .selector-dropdown .selector-toggle {
    position: relative;
    border: 1px solid #AEBECD !important;
    padding: 0 60px 0 20px;
    border-radius: 25px;
    min-width: 227px;
    text-align: left;
    line-height: 35px;
    cursor: pointer;
    display: block;
}
.o-case-studies-top-selector__client-review .selector-dropdown .selector-toggle:before {
    content: "";
    background: url(/images/common/nav-down-arrow.svg) 100% 50% no-repeat;
    position: absolute;
    right: 20px;
    width: 10px;
    height: 10px;
    top: 16px;
}
.o-case-studies-top-selector__client-review .selector-dropdown .dropdown-content {
    position: absolute;
    min-width: 219px;
    box-shadow: 0px 0 0 0px rgba(0,0,0,0.2);
    padding: 12px 24px;
    z-index: 1;
    width: 100%;
    left: 4px;
    top: 30px;
    border-left: 1px solid #AEBECD;
    border-right: 1px solid #AEBECD;
    border-bottom: 1px solid #AEBECD;
    background: #fff;
    display: none;
}
.o-case-studies-top-selector .selector-dropdown .dropdown-content ul li a {
    color: #333;
    display: block;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    padding: 12px 0;
    text-transform: uppercase;
    max-width: 350px;
    text-decoration: none;
    position: relative;
    border-radius: 5px;
}
.client-top-wrap {
    display: flex;
}
.client-top-wrap .client-circle {
    display: inline-block;
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-right: 20px;
}
.client-top-wrap .client-review-right {
    flex: 1;
}
.client-top-wrap .client-review-right h3 {
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 5px;
}
.client-top-wrap .client-review-right h6 {
    font-size: 14px;
    line-height: 14px;
    color: #333;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
}
.client-top-wrap .client-review-right p {
    color: #8c8585;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

.client-review-helper {
    padding-top: 50px;
}
.box.review-item:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url(/images/clients-reviews/quote-icon.png);
    background-repeat: no-repeat;
    right: 35px;
    bottom: 30px;
}
.review-item .star {
    display: none;
}
.client-review-bnr-icon-wrap img {
    margin: 0 25px;
}
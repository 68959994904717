.bg-lightgray {
    background-color: #f9f9f9;
}
.a-dev-pro {
    h2 {
        margin-bottom: 15px;
    }
    p.d-trans-pro-para {
        font-size: 16px;
        text-align: center;
        margin-bottom: 40px;
        @include media-query(992px) {
            margin-bottom: 70px;
            font-size: 24px;
        }
    }
    .d-trans-pro-wrapper {
        .d-trans-pro-steps {
            &:before {
                @include media-query(992px) {
                    height: 91%;
                }
            }
        }
        .d-trans-pro-content-box4 {
            @include media-query(992px) {
                margin-top: 215px;
            }
        }
        .d-trans-pro-content-box5 {
            @include media-query(992px) {
                margin-top: 360px;
            }
        }
        .d-trans-pro-content-box6 {
            @include media-query(992px) {
                margin-top: 305px;
            }
        }
        
    }
}

.new-tech-stack {
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    .tech-list ul li {
        font-weight: 500;
        a {
            font-weight: 500;
            color: #4A90E2;
            text-decoration: underline;
            transition: .2s all ease-in-out;
            &:hover {
                color: darken(#4A90E2, 10%);
            }
        }
    }
    &-para {
        font-size: 16px;
        line-height: 24px;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 28px;
            max-width: 790px;
            margin: 0 auto 50px;
        }
    }
    h2 {
        padding-bottom: 0;
        margin-bottom: 5px;
        @include media-query(992px) {
            margin-bottom: 15px;
        }
        &.without-para-heading {
            margin-bottom: 20px;
            @include media-query(992px) {
                margin-bottom: 35px;
            }
        }
    }
}


.o-key-diff {
    padding: 50px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    h2 {
        text-align: center;
        margin-bottom: 15px;
    }
    &-para {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 20px;
            line-height: 36px;
            max-width: 1024px;
            margin: 0 auto 50px;
        }
    }
    &-left {
        @include media-query(992px) {
            padding-right: 20px;
            padding-top: 30px;
        }
        h3 {
            font-size: 22px;
            line-height: 34px;
            position: relative;
            padding-bottom: 10px;
            text-align: center;
            @include media-query(992px) {
                font-size: 30px;
                line-height: 40px;
                padding-bottom: 0;
                text-align: left;
            }
            font-weight: 500;
            &:before {
                content: '';
                position: absolute;
                bottom: -40px;
                left: 0;
                background: url(/images/api-development/l-right-arrow.svg) no-repeat 0 0;
                width: 70px;
                height: 14px;
                display: none;
                @include media-query(992px) {
                    display: block;
                }
            }
        }
    }
    &-box {
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        border-left-width: 8px;
        padding: 20px 15px;
        margin-bottom: 30px;
        width: 100%;
        @include media-query(992px) {
            padding: 30px;
        }

        ul {
            list-style: disc;
            margin-top: 10px;
            padding-left: 20px;

            li {
                font-size: 16px;
                line-height: 27px;
                margin-bottom: 8px; 
            }
        }

        p {
            font-size: 16px;
            line-height: 27px;
            margin-bottom: 0;
        }
        h4 {
            font-size: 20px;
            font-weight: 500;
            @include media-query(992px) {
                font-size: 24px;
            }
        }
    }
    .bord-col-1 {
        border-left-color: #4A90E2;
    }
    .bord-col-2 {
        border-left-color: #49C684;
    }
    .bord-col-3 {
        border-left-color: #4A90E2;
        @include media-query(992px) {
            border-left-color: #49C684;
        }
    }
    .bord-col-4 {
        border-left-color: #49C684;
        @include media-query(992px) {
           border-left-color: #4A90E2;
        }
    }
    .col-lg-4 {
        @include media-query(992px) {
            display: flex;
        }
    }
}


.type-of-dev {
    padding: 50px 0 0;
    @include media-query(992px) {
        padding: 70px 0 0;
    }
    h2 {
        text-align: center;
        margin-bottom: 15px;
    }
    &-para {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
        @include media-query(992px) {
            font-size: 20px;
            line-height: 36px;
            max-width: 960px;
            margin: 0 auto 50px;
        }
    }
    &-box {
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        border-bottom-width: 4px;
        padding: 20px 15px;
        margin-bottom: 30px;
        @include media-query(992px) {
            padding: 30px;
        }
        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 27px;
        }
        h3 {
            font-size: 20px;
            font-weight: 500;
            @include media-query(992px) {
                font-size: 24px;
            }
        }
        &-color-1 {
            border-bottom-color: #4A90E2;
        }
        &-color-2 {
            border-bottom-color: #49C684;
        }
    }
    .col-lg-3 {
        @include media-query(992px) {
            display: flex;
        }
    }
}


.new-service-section-api-dev {
    .ns-order-3 {
        order: 4;
    }
    .ns-order-4 {
        order: 3;
    }
    .ns-order-5 {
        order: 6;
    }
    .ns-order-6 {
        order: 5;
    }
}

.no-bnr-mod {
    background: #fff;
    padding-top: 20px;
    @include media-query(992px) {
        padding-top: 40px;
    }
}


/*smart-contract page*/
.smart-contract-use-case {
    .o-key-diff-box {
        background: #fff;
    }
}
































/*Rtecard*/

.ratecard-section {
    padding: 90px 0 40px;
    min-height: 400px;
    @include media-query(992px) {
        padding: 120px 0 80px;
        min-height: 600px;
    }
    h2 {
        font-size: 30px;
        @include media-query(992px) {
            font-size: 48px;
            line-height: 61px;
        }
    }
}
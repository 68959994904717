.stack-list{
    li{
        background-color: $white-color;
        display: inline-block;
        @include border-radius(5px);
        margin: 0 5px 20px;
        position: relative;
        padding: 120px 10px 0;
        font-size: 20px;
        color: #333;
        font-weight: 500;
        vertical-align: top;
        z-index: 9;
        position: relative;
        width: 44%;
        font-size: 18px;
        padding: 100px 10px 0;
        align-items: flex-start ;
        padding: 13px 15px;
        height: 79px;
        position: relative;
        @include box-shadow(0, 0, 12px, #a8a8a880);
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translate(-50%, 0);
            background-image: url(/images/home/home-arrow.png);
            width: 22px;
            height: 22px;
        }
        // &:before{
        //     content: '';
        //     position: absolute;
        //     left: 50%;
        //     top: 50px;
        //     transform: translate(-50%, 0);
        //     background-image: url(/images/home/home-sprite.png);
        //     width: 60px;
        //     height: 55px;
        // }
        // &:nth-child(1) &:before{
        //     background-position: 0 -73px;
        // }

    }

}

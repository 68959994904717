.thank-contact {
    $thank-contact: &;
    text-align: center;
    background: #EDFAFF;
    padding: 40px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__heading {
        font-family: "Poppins";
        font-weight: 700;
        color: #000;
        @include media-query(992px) {
            font-size: 40px;
            line-height: 55px;
        }
    }
    &__sub-heading {
        font-family: "Poppins";
        font-weight: 600;
        color: #4492DC;
        margin-bottom: 0;
        @include media-query(992px) {
            font-size: 25px;
            line-height: 29px;
        }
    }
}

.discover-services {
    $discover-services: &;
    position: relative;
    padding-bottom: 25px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #EDFAFF;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #C2D7DF;
    }
    &__wrapper {
        border: 3px solid #84A5F9;
        border-radius: 10px;
        padding: 30px 15px;
        position: relative;
        background: #fff;
        z-index: 99;
        @include media-query(992px) {
            padding: 60px 0 50px;
        }
    }
    &__heading {
        font-family: "Poppins";
        font-weight: 700;
        color: #000;
        text-align: center;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 33px;
        }
    }
    &__sub-heading {
        font-family: "Poppins";
        font-weight: 500;
        color: #333;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 27px;
        }
    }
    &__btn-list {
        margin-top: 25px;
        @include media-query(992px) {
            margin-top: 35px;
        }
        .banner-technologies__list-link {
            font-family: "Poppins";
            z-index: 1;
            position: relative;
            background: #fff;
            border-radius: 30px;
            color: #333;
            display: inline-block;
            padding: 8px 10px;
            font-size: 16px !important;
            font-weight: 600 !important;
            @include media-query(992px) {
                font-size: 26px !important;
                padding: 8px 30px;
            }
        }
        .new-home-banner__list-item {
            margin: 0 1px 3px;
            @include media-query(992px) {
                margin: 0 3px 10px;
            }
            &:hover {
                svg {
                    @include media-query(992px) {
                        transform: translateX(10px);
                    }
                }
                path {
                    transform: none;
                    fill: #35B15C;
                }
                .banner-technologies__list-link {
                    color: #35B15C;
                }
            }
        }
    }
}

.story {
    $story: &;
    padding-bottom: 20px;
    @include media-query(992px) {
        padding-bottom: 40px;
    }
    &__dive {
        background: #355BBF;
        padding: 30px 20px;
        border-radius: 10px;
        @include media-query(992px) {
            padding: 60px 50px;
            height: 100%;
        }
        h2 {
            font-family: "Poppins";
            color: #fff;
            font-weight: 700;
            margin-bottom: 15px;
            @include media-query(992px) {
                font-size: 31px;
                line-height: 33px;
            }
        }
    
        p{
            font-family: "Poppins";
           color: #fff;
           font-weight: 500;
           @include media-query(992px) {
            font-size: 21px;
            line-height: 30px;
           }
        }
    }
    &__btn {
        font-family: "Poppins";
        background: transparent;
        border: 2px solid #fff;
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        padding: 15px 30px;
        border-radius: 5px;
        display: inline-block;
        margin-top: 25px;
        svg {
            margin-left: 10px;
        }
        &:hover {
            color: #fff;
            border-color: #FF5800;
            background: #FF5800;
            svg {
                transform: translateX(10px);
            }
        }
        &--reverse {
            border-color: #FF5800;
            color: #333;
            &:hover {
                path {
                    fill: #fff;
                }
            }
        }
        &--white {
            color: #fff;
        }
    }
    &__uncover {
        padding: 30px 20px;
        border: 1px solid #84A5F9;
        border-radius: 10px;
        height: auto;
        @include media-query(992px) {
            height: 100%;
            padding: 60px 50px;
            margin-top: 0;
        }
        h2 {
            font-family: "Poppins";
            color: #000;
            font-weight: 700;
            margin-bottom: 15px;
            @include media-query(992px) {
                font-size: 31px;
                line-height: 36px;
            }
        }
        p {
            font-family: "Poppins";
            font-size: 21px;
            line-height: 30px;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
        }
    }
    &__box-head {
        @include media-query(992px) {
            display: flex;
            align-items: flex-start;
        }
        i {
            display: inline-block;
            flex: 0 0 auto;
            margin-bottom: 20px;
            @include media-query(992px) {
                margin-right: 30px;
                margin-bottom: 0;
            }
        }
    }
    &__insights {
        padding: 30px 20px;
        border: 1px solid #84A5F9;
        border-radius: 10px;
        height: auto;
        @include media-query(992px) {
            height: 100%;
            padding: 60px 50px;
            display: flex;
            align-items: flex-start;
            margin-top: 0;
        }
        i {
            flex: 0 0 auto;
            margin-right: 30px;
            margin-bottom: 20px;
            @include media-query(992px) {
                margin-bottom: 0;
            }
        }
        h2 {
            color: #000;
            font-weight: 700;
            margin-bottom: 15px;
            @include media-query(992px) {
                font-size: 31px;
                line-height: 36px;
            }
        }
        p {
            font-family: "Poppins";
            font-size: 21px;
            line-height: 30px;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
        }
        .story__box-head {
            display: block;
        }
    }
    &__business {
        background: url(/images/new-thank-you/business.jpg) no-repeat 0 0 / cover;
        border-radius: 10px;
        padding: 30px 20px;
        @include media-query(992px) {
            padding: 60px 50px;
            height: 100%;
            margin-top: 0;
        }
        h2 {
            font-family: "Poppins";
            color: #fff;
            font-size: 31px;
            line-height: 38px;
            font-weight: 700;
        }
        p {
            font-family: "Poppins";
            font-size: 21px;
            line-height: 30px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 0;
        }
    }
    &__wrapper-top {
        
    }
    &__wrapper {
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
    }
}

.new-thank-you-page {
    .slbWrapOuter {
        height: auto;
    }
}
.mvp-texh-stack {
    .profile-tabs {
        .tab-profile-content {
            .tab-profile-content-area {
                ul {
                    li {
                        a {
                            img {
                                &.icon-small {
                                    width: 30px;
                                }
                                &.icon-medium {
                                    width: 40px;
                                }
                                &.icon-c-sharp {
                                    width: 52px;
                                }
                                &.icon-azure-sql-database {
                                    width: 52px;
                                }
                            }
                        }
                    }
                    &.list-wise {
                        margin-left: 10px;
                        li {
                            width: 100%;
                            font-size: 16px;
                            position: relative;
                            padding: 10px 10px 10px 15px;
                            margin: 0;
                            color: #333;
                            @include media-query(992px) {
                                width: 50%;
                            }
                            &:before {
                                content: '';
                                position: absolute;
                                background: #005eb8;
                                height: 8px;
                                width: 8px;
                                top: 18px;
                                left: 0;
                            }
                            a {
                                text-decoration: underline;
                                background: none;
                                border: none;
                                padding: 0;
                                margin: 0;
                                height: auto;
                                color: #333;
                                &:hover {
                                    box-shadow: none;
                                    color: #005eb8;
                                }
                            }
                        }
                    }
                }
                .tab-profile-content-wrap {
                    h4 {
                        padding-left: 10px;
                        margin-bottom: 15px;
                        font-size: 18px;
                        display: none;
                        @include media-query(992px) {
                            display: block;
                        }
                    }
                    span {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

.mvp-benefit-development {
    .heading-top {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 15px;
        }
        p {
            font-size: 19px;
            text-align: center;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 38px;
            }
        }
    }
}

.mvp-key-role {
    .heading-top {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 15px;
            padding-bottom: 0;
        }
        p {
            font-size: 19px;
            text-align: center;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 38px;
            }
        }
    }
}

.mv-dev-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box5 {
            @include media-query(992px) {
                margin-top: 170px;
            }
        }
    }
}
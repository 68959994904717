.home-fi {
    position: relative;
    background-position:50% 50%;
    background-size: cover;
    overflow: hidden;
    // background-image: url(../images/home/remotedev-img-mobile.jpg) !important;
    // background: linear-gradient(172deg, rgba(3,55,90,1) 0%, rgba(16,105,147,1) 60%, rgba(15,54,92,1) 90%) !important;
    @include responsive(desktop){
        // background-image: url(../images/home/remotedev-bg.jpg) !important;
        // background-image: url(../../../images/home/feature-bg-new.jpg) !important;
        background-size: cover;
    }
    &:before {
        display: block;
        content: '';
        position: absolute;
        background-image: url(/images/home/remotedev-img-mobile.svg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 65%;
        height: 100%;
        top: 0;
        right: 0;
        @include responsive(desktop){
            display: none;
        }
    }
    h2{
        font-size: 16px;
        padding: 0px 0 10px 0;
        text-align: center;
        margin-top: 40px;
        width: 200px;
        line-height: 40px;
        background: url(/images/home/remotedev-heading-bg.png) no-repeat 0 0;
        color: $white-color;
        background-size: 100%;
        font-weight: 600;
        @include responsive(desktop){
            width: 230px;
            line-height: 47px;
            font-size: 20px;
            padding: 0 0 15px 20px;
            text-align: left;
        }
    }
    h3{
        font-size: 22px;
        line-height: 29px;
        color: $white-color;
        padding: 20px 47% 20px 0;
        font-weight: 700;
        @include media-query(768px) {
            font-weight: 600;
            padding: 20px 0 20px 0;
        }
        @include media-query(992px) {
            padding: 20px 0 20px 0;
            font-size: 37px;
            line-height: 50px;
        }
        @include media-query(1280px) {
            font-size: 40px;
        }
        span{
            &.text-green {
                color: $green-color;
            }
        }
    }
    .btn{
        margin: 0;
    }
    .btn-orange:after {
        margin-left: 5px;
        @include responsive(desktop){
            margin-left: 15px;
        }
    }
    .story-bg {
        background-image: url(/images/home/remotedev-bg.jpg) !important;
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        &:before {
            display: block;
            content: '';
            position: absolute;
            background-image: url(/images/home/remotedev-img-mobile.svg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 65%;
            height: 100%;
            top: 0;
            right: 0;
            @include media-query(992px) {
                display: none;
            }
        }
    }
    .whitepaper-bg {
        background-image: url(/images/home/feature-bg-new.jpg) !important;
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        &:before {
            display: block;
            content: '';
            position: absolute;
            background-image: url(/images/home/remotedev-white-img-mobile.png);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 65%;
            height: 100%;
            top: 0;
            right: 0;
            @include media-query(992px) {
                display: none;
            }
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        text-align: center;
        .owl-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #9b9b9b;
            margin: 0 5px;
            &.active {
                background: #49c684;
            }
        }
    }
    .featured-book-img {
        position: relative;
        top: 0;
        @include media-query(992px) {
            right: -5px;
            position: absolute;
        }
        @include media-query(1280px) {
            right: -15px;
        }
        height: 100%;
        width: auto;
        img{
            height: 100%;
            width: auto;
            object-fit: cover;
        }
        &-white {
            @include media-query(992px) {
                right: -25px;
            }
            @include media-query(1280px) {
                right: -35px;
            }
        }
    }
    .row {
        position: relative;
    }
}
.no-webp {
    .book-img-left {
        // background-image: url(../images/home/remotedev-img-mobile.png);
        background-size: cover;
        background: none;
        @include responsive(desktop){
            background-image: none;
        }
    }
}
.webp {
    .book-img-left {
        // background-image: url(../images/webp/home/remotedev-img-mobile.webp);
        background-size: cover;
        background: none;
        @include responsive(desktop){
            background-image: none;
        }
    }
}
.book-img-left {
    // padding-right: 60px;
    padding-bottom: 40px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    padding-left: 15px;
    @include media-query(992px) {
        padding-bottom: 40px;
    }
    @include media-query(1200px) {
        background-image: none;
        padding-top: 10px;
        padding-bottom: 47px;
        padding-left: 15px;
    }
    // &-mod {
    //     h3 {
    //         @include media-query(992px) {
    //             font-size: 35px;
    //             line-height: 40px;
    //         }
    //     }
    // }
    &-mod {
        h3 {
            padding-right: 44%;
            @include media-query(992px) {
                padding-right: 0;
            }
        }
    }
}
.book-img {
    display: none;
    @include responsive(desktop){
        position: relative;
        display: block;
    }
    img{
        top: 0;
        position: absolute;
        left: -15px;
    }
}

.hire-expert-home {
    padding: 40px 0;
    @include media-query(992px) {
        padding: 65px 0;
    }
    h2 {
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 42px;
            line-height: 52px;
        }
    }
    h3 {
        font-size: 22px;
        line-height: 34px;
        font-weight: 500;
    }
}




.project-based-banner {
    @include media-query(992px) {
        padding-bottom: 0 !important;
    }
    .paroject-based-banner-graphic {
        text-align: center;
        @include media-query(992px) {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        img {
            width: 90%;
        }
    }
    .paroject-based-banner-graphic-mod {
        @include media-query(992px) {
            position: absolute;
            bottom: 0;
            right: inherit;
            left: -65px;
        }
        img {
            width: 100%;
        }
    }
    h1 {
        margin-top: 15px;
        @include media-query(992px) {
            font-size: 32px;
            line-height: 44px;
            margin-top: 0;
        }
        @include media-query(1089px) {
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 10px;
        }
    }
    p {
        font-weight: 500;
        font-size: 16px;
        @include media-query(992px) {
            font-size: 20px;
        }
    }
    .project-based-banner-right-p {
        @include media-query(992px) {
            padding-right: 170px;
        }
    }
    .btn-orange {
        margin: 0 auto;
        @include media-query(992px) {
            margin: 0;
        }
    }
    .anchor-link {
        margin-bottom: 35px;
        @include media-query(992px) {
            margin-bottom: 70px;
        }
    }
    .header_left_pan {
        p {
            display: block;
        }
    }
    .row {
        flex-direction: column-reverse;
        @include media-query(992px) {
            flex-direction: row;
        }
    }
}
.project-tailored {
    .heading-top {
        margin-bottom: 35px;
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 20px;
            text-align: center;
            line-height: 36px;
        }
    }
    .tailored-box {
        @include media-query(992px) {
            padding: 0 60px;
        }
        &-gray {
            border-radius: 15px;
            padding-top: 60px;
            padding-bottom: 60px;
            @include media-query(992px) {
                margin: 70px 0;
                background: #F7F7F7;
            }
            .row {
                @include media-query(992px) {
                    flex-direction: row-reverse;
                }
            }
        }
        &-sequence1 {
            @include media-query(992px) {
                margin-top: 0;
            }
            .row {
                @include media-query(992px) {
                    flex-direction: row;
                }
            }
        }
        &-sequence2 {
            .row {
                @include media-query(992px) {
                    flex-direction: row-reverse;
                }
            }
        }
        &-text {
            h4 {
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 20px;
                margin-top: 20px;
                @include media-query(992px) {
                    margin-top: 0;
                }
            }
            p {
                font-size: 16px;
                line-height: 27px;
                color: #333;
            }
            h5 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            ul {
                li {
                    font-size: 16px;
                    line-height: 25px;
                    color: #333;
                    position: relative;
                    padding-left: 30px;
                    padding-bottom: 15px;
                    &:before {
                        content: '';
                        position: absolute;
                        background: url(/images/project-based/circle-tick.svg) no-repeat 0 0;
                        top: 4px;
                        left: 0;
                        width: 18px;
                        height: 18px;
                    }
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
        .row {
            align-items: center;
            img {
                margin: 0 auto;
                display: block;
                @include media-query(992px) {
                    margin: 0;
                    display: inline-block;
                }
            }
        }
    }
}


.pros-cons {
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 75px 0 80px;
    }
    .heading-top {
        margin-bottom: 40px;
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 20px;
            text-align: center;
            line-height: 36px;
        }
    }
    &-slab {
        @include media-query(992px) {
            display: flex;
        }
        border-radius: 5px;
        background: #fff;
        margin-top: 30px;
        padding: 10px 30px 30px;
        @include media-query(992px) {
            padding: 30px;
        }
        &-box {
            flex: 1;
            border-width: 1px;
            border-style: solid;
            border-color: #E3E3E3;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);
            h6 {
                color: #333333;
                font-size: 18px;
                font-weight: 700;
                position: relative;
                padding-bottom: 5px;
                margin-bottom: 15px;
                &:before {
                    content: '';
                    position: absolute;
                    background: #333;
                    bottom: 0;
                    left: 0;
                    width: 25px;
                    height: 2px;
                }
            }
            li {
                font-size: 16px;
                position: relative;
                padding-left: 10px;
                padding-bottom: 10px;
                &:before {
                    content: '•';
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &-title {
                width: 100%;
                border: none;
                box-shadow: none;
                text-align: center;
                @include media-query(992px) {
                    width: 200px;
                    flex: 0 0 200px;
                    text-align: left;
                    padding-top: 40px;
                }
                h4 {
                    margin-top: 15px;
                    text-align: center;
                    @include media-query(992px) {
                        text-align: left;
                    }
                }
            }
            &-gray {
                margin: 25px 0;
                @include media-query(992px) {
                    margin: 0 30px;
                }
                background: #F7F7F7;
                border-color: #C7C7C7;
                box-shadow: none;
                li {
                    &:before {
                        content: '-';
                    }
                }
            }
            &-green {
                background: #30B058;
                border-color: #30B058;
                color: #fff;
                box-shadow: none;
                h6 {
                    color: #fff;
                    &:before {
                        background: #fff;
                    }
                }
                li {
                    &:before {
                        content: '+';
                    }
                }
            }
        }
    }
}


.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover {
    background: #fff;
    .heading-top {
        p {
            color: #333;
        }
    }
    .fixed-engagement {
        @include media-query(992px) {
            max-width: 870px;
        }
        &-row-left {
            background: #091B3A;
            h5 {
                @include media-query(992px) {
                    font-size: 20px;
                }
            }
        }
        &-row-left-head {
            background: #E8EAED;
            display: flex;
            align-items: center;
            @include media-query(992px) {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            h5 {
                color: #333;
                font-weight: 600;
            }
        }
    }
    .fixed-engagement-row-top {
        .fixed-engagement-right {
            h5 {
                color: #333;
                @include media-query(992px) {
                    font-size: 20px;
                }
                small {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
            .fixed-engagement-col {
                background: #71E19D;
                min-height: 65px;
                @include media-query(992px) {
                    min-height: 85px;
                }
                ul {
                    li {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .fixed-engagement-row {
        .fixed-engagement-right {
            .fixed-engagement-col {
                border-left: 1px solid #DADBDE;
                border-right: 1px solid #DADBDE;
                ul {
                    padding: 27px 30px 27px 20px;
                    li {
                        text-align: left;
                        padding: 3px 0 3px 12px;
                        position: relative;
                        @include media-query(992px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                        font-weight: 500;
                        &:before {
                            content: '';
                            background: url(/images/dedicated-team-based/right-arrow.svg) no-repeat 0 0;
                            width: 7px;
                            height: 11px;
                            position: absolute;
                            top: 11px;
                            left: 0;
                        }
                    }
                }
                &-ul {
                    align-items: flex-start;
                }
            }
        }
    }
    .col-content-box-top-ul {
        ul {
            padding-left: 5px;
                li {
                    text-align: left;
                    padding: 3px 0 3px 12px;
                    position: relative;
                    font-size: 13px;
                    line-height: 21px;
                    font-weight: 500;
                    &:before {
                        content: '';
                        background: url(/images/dedicated-team-based/right-arrow.svg) no-repeat 0 0;
                        width: 7px;
                        height: 11px;
                        position: absolute;
                        top: 11px;
                        left: 0;
                    }
                }
        }
    }
    .click-block {
        pointer-events: none;
        .col-content-box-top-ul {
            h5 {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.new-engagement-model-page {
    background: url(/images/engagement-model/new-graphic/engagement-new-graphic.jpg) no-repeat 0 0 / cover;
    position: relative;
    &__left {
        position: relative;
        z-index: 99;
    }
    @include media-query(992px) {
        padding: 130px 20px 45px!important;
    }
    h1 {
        @include media-query(992px) {
            font-size: 45px;
            margin-bottom: 30px;
        }
    }
    .new-btn {
        max-width: 300px;
    }
}
.circle-with-hand {
    text-align: center;
    @include media-query(992px) {
        position: absolute;
        top: -45px;
        text-align: right;
        right: -50px;
    }
    img {
        max-width: 90%;
    }
}

.fixed-engagement-model.new-fixed-engagement-model.new-fixed-engagement-model-bg-remover {
    .heading-top-compare-model {
        @include media-query(992px) {
            margin-bottom: 45px;
        }
    }
    .new-compare-engagement {
        @include media-query(992px) {
            max-width: 100%;
            padding: 30px;
        }
        background: #F6F6F6;
        border: 1px solid #E3DFDF;
        border-radius: 10px;
    }
    .fixed-engagement-row-left {
        margin-right: 0;
        background: #1D3D70;
        flex: 0 0 23%;
        align-items: center;
        h5 {
            font-size: 17px;
            line-height: 27px;
            margin-bottom: 0;
            position: relative;
            padding-left: 25px;
            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                background: url(/images/engagement-model/new-graphic/white-tick.svg) no-repeat center center;
                width: 20px;
                height: 20px;
            }
        }
    }
    .fixed-engagement-row-left-head {
        margin-right: 0;
        background: none;
        padding-left: 0;
        h5 {
            font-size: 28px;
            line-height: 41px;
            font-weight: 700 !important;
            color: #1D3D70;
            position: relative;
            padding-left: 0;
            &::before {
                @include media-query(992px) {
                    content: '';
                    position: absolute;
                    top: inherit;
                    bottom: -20px;
                    left: 0;
                    background: #3CC065;
                    width: 91px;
                    height: 4px;
                    border-radius: 20px;
                }
            }
        }
    }
    .fixed-engagement-row {
        .fixed-engagement-right {
            border-top: 1px solid rgba(29, 61, 112, 0.3);
            border-left: 1px solid rgba(29, 61, 112, 0.3);
            border-right: 1px solid rgba(29, 61, 112, 0.3);
            .fixed-engagement-col {
                margin: 0;
                padding: 25px 25px;
                border-left: 0;
                border-right: 0;
                border-bottom: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                background: #fff;
                display: block;
                text-align: left;
                i {
                    display: flex;
                    height: 50px;
                    align-items: center;
                }
                h5 {
                    font-size: 21px;
                    line-height: 27px;
                    font-weight: 700 !important;
                    text-align: left;
                }
                p {
                    font-size: 16px;
                    line-height: 27px;
                    font-weight: 600;
                }
                span {
                    padding: 0;
                    font-size: 16px;
                    line-height: 27px;
                    font-weight: 500;
                }
                &-border-right {
                    border-right: 1px solid rgba(29, 61, 112, 0.3);
                }
            }
            .fixed-engagement-col-right-head {
                padding: 10px 25px 5px;
                .box-head-bar {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        top: -31px;
                        left: 0;
                        width: 100%;
                        height: 12px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        z-index: 99;
                    }
                    &-green {
                        &::before {
                            background: #3CC065;
                        }
                    }
                    &-blue {
                        &::before {
                            background: #4B699B;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: -30px;
                        right: -4px;
                        background: url(/images/engagement-model/new-graphic/triangle.png) no-repeat center center;
                        width: 6px;
                        height: 6px;
                        background-size: 100%;
                    }
                }
            }
        }
        &:first-child {
            .fixed-engagement-right {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                padding-top: 15px;
                background: #fff;
            }
        }
        &:last-child {
            .fixed-engagement-right {
                .fixed-engagement-col {
                    border-bottom: 1px solid rgba(29, 61, 112, 0.3);
                }
            }
            .fixed-engagement-row-left {
                border-bottom-left-radius: 15px;
            }
        }
        &:nth-child(2) {
            .fixed-engagement-row-left {
                border-top-left-radius: 15px;
            }
        }
    }
}

.fixed-engagement-model.new-fixed-engagement-model {
    .m-compare-model {
        .fixed-engagement-row {
            .fixed-engagement-right {
                .col-content-box-top {
                    h5 {
                        flex: 0 0 100%;
                    }
                }
                .fixed-engagement-col {
                    padding: 10px 25px 25px;
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    
}
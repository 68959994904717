.common-padding {
  padding: 30px 0 40px;
  @include media-query(992px) {
    padding: 45px 0 60px;
  }
}
.about-us {
  $about-us: &;
  &__topheading {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 34px;
    font-weight: 700;
    color: #3cc065;
    text-align: center;
    margin-bottom: 10px;
    @include media-query(992px) {
      font-size: 31px;
      line-height: 54px;
    }
  }
  &__heading {
    font-family: "Poppins";
    color: #262e36;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
    @include media-query(992px) {
      font-size: 46px;
      line-height: 60px;
    }
  }
}

.company-banner {
  $company-banner: &;
  position: relative;
  background: #0d2642
    url(/images/new-company-profile/company-profile-banner.png) no-repeat;
  background-position: 27% 0;
  background-size: 200%;
  margin-top: 68px;
  overflow: hidden;
  @include media-query(992px) {
    background: #0d2642
      url(/images/new-company-profile/company-profile-banner.png) no-repeat;
    background-position: top right;
    background-size: 80%;
  }
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(/images/new-home-banner/banner-gradient.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 20%;
    top: 0;
    left: 0;
  }
  &__spacing {
    padding: 60px 0;
    @include media-query(992px) {
      padding: 110px 0;
    }
    @include media-query(1540px) {
      padding: 159px 0;
    }
  }
  &__heading {
    font-family: "Poppins";
    color: $white-color;
    font-size: 31px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    @include media-query(992px) {
      font-size: 51px;
      line-height: 54px;
    }
  }
  &__subheading {
    font-family: "Poppins";
    color: $white-color;
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
    @include media-query(992px) {
      font-size: 31px;
      line-height: 38px;
    }
  }
  &__btn {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    text-transform: capitalize;
    @include media-query(992px) {
      max-width: 333px;
    }
  }
}

.o-our-story {
  position: relative;
  background-color: #f9f9f9;
  .container {
    @include media-query(1200px) {
      max-width: 1000px;
    }
  }
  .about-us__heading {
    margin-bottom: 30px;
    @include media-query(992px) {
      margin-bottom: 60px;
    }
  }
  &__content {
    font-family: "Poppins";
    color: $black-color;
    font-size: 16px;
    line-height: 27px;
    margin: 0 auto 15px;
    width: 100%;
    text-align: center;
    @include media-query(992px) {
      margin: 0 auto 20px;
      text-align: left;
    }
    @include media-query(1180px) {
      width: 90%;
    }
  }
  &__btn {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    text-transform: capitalize;
    margin: 15px auto 0;
    @include media-query(992px) {
      max-width: 292px;
      margin: 40px auto 0;
    }
  }
}

.o-our-evolution {
  $o-our-evolution: &;

  .about-us__heading {
    margin-bottom: 20px;
    @include media-query(992px) {
      margin-bottom: 10px;
    }
  }
  &__bottomtext {
    font-family: "Poppins";
    color: $black-color;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 27px;
    }
  }
  &__timeline {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 30px 0 5px;
    width: 100%;
    overflow: hidden;
    @include media-query(992px) {
      margin: 66px 0 10px;
      flex-direction: row;
    }
    .splide__arrows {
      display: block;
    }
    .splide__arrow--prev {
      top: 28px;
    }
    .splide__arrow--next {
      top: 28px;
    }
  }
  &__year {
    width: 100%;
    display: none;
    @include media-query(800px) {
      width: 20%;
      display: block;
      padding-left: 35px;
    }
    li {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 100%;
        top: 27px;
        background-color: #c4bbc5;
        left: -23px;
        z-index: 1;
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
      a {
        display: block;
        width: auto;
        height: auto;
        text-align: center;
        opacity: 1;
        background: 0 0;
        color: #6e6e71;
        margin: 15px 0;
        position: relative;
        font-size: 21px;
        font-weight: 600;
        display: inline-block;
        &:hover {
          color: #4492dc;
          text-indent: 2px;
          &:before {
            border-color: #4492dc;
          }
        }
        @include media-query(992px) {
          text-align: left;
        }
        &:before {
          content: "";
          position: absolute;
          top: 6px;
          left: -32.5px;
          width: 21px;
          height: 21px;
          border-radius: 100%;
          background-color: #fff;
          border: 2px solid #898989;
          transition: 0.2s;
          z-index: 2;
          display: none;
          @include media-query(992px) {
            display: block;
          }
        }
        &.active {
          color: #4492dc;
          &:hover {
            text-indent: 0;
          }
          &:before {
            background-color: #4492dc;
            border: 2px solid #4492dc;
          }
        }
      }
      &:first-child {
        a {
          @include media-query(992px) {
            margin: 0 0 15px;
          }
        }
      }
      &:last-child {
        a {
          @include media-query(992px) {
            margin: 15px 0 0;
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &__yearcontent {
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-query(800px) {
      width: 40%;
      padding: 0 10px;
    }
  }
  .owl-item {
    animation-duration: 30ms;
  }
  .owl-nav {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
  }
  .owl-prev {
    font-size: 45px !important;
    color: #000 !important;
    background-color: $white-color !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    span {
      position: relative;
      top: -3px;
      left: -1px;
      height: 50px;
      line-height: 50px;
    }
    &:hover {
      color: #fff !important;
      background-color: #3cc065 !important;
    }
  }
  .owl-next {
    font-size: 45px !important;
    color: #000 !important;
    background-color: $white-color !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    span {
      position: relative;
      top: -3px;
      right: -1px;
      height: 50px;
      line-height: 50px;
    }
    &:hover {
      color: #fff !important;
      background-color: #3cc065 !important;
    }
  }
  .ele {
    margin-bottom: 50px;
    h6 {
      font-family: "Poppins";
      color: #4492dc;
      display: block;
      font-size: 30px;
      margin: 10px 0 30px;
      font-weight: 700;
      opacity: 1;
      transition: 0.2s ease 0.4s;
      text-align: center;
      @include media-query(800px) {
        opacity: 0.2;
        font-size: 24px;
        color: $black-color;
      }
      @include media-query(992px) {
        font-size: 31px;
        margin: 0 0 15px;
        text-align: left;
      }
    }
    p {
      font-family: "Poppins";
      color: $black-color;
      font-size: 18px;
      line-height: 26px;
      color: $black-color;
      font-weight: 500;
      opacity: 1;
      transition: 0.2s ease 0.6s;
      margin: 0;
      text-align: center;
      @include media-query(800px) {
        opacity: 0.2;
      }
      @include media-query(992px) {
        font-size: 21px;
        line-height: 35px;
        text-align: left;
      }
    }
    .mobile-timeline-image {
      margin-top: 15px;
      display: block;
      @include media-query(800px) {
        display: none;
      }
    }
    &.active {
      h6 {
        opacity: 1;
        transition: 0.4s ease 0.8s;
      }
      p {
        opacity: 1;
        transition: 0.4s ease 1s;
      }
    }
  }
  .timeline-image {
    width: 100%;
    padding-left: 0;
    margin-top: 15px;
    display: none;
    position: relative;
    @include media-query(800px) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 36%;
      margin-left: 4%;
      margin-top: 0;
    }
  }
  .time-image {
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: 0.2s ease 0.8s;
    transform: translate3d(20px, 0, 0);
    left: 0;
    top: 0;
    right: 0;
    width: auto;
    margin: auto;
    &.active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.6s ease 1.4s;
    }
  }
}

.o-partner-need {
  $o-partner-need: &;
  .container {
    @include media-query(1200px) {
      max-width: 1000px;
    }
  }
  .about-us__heading {
    @include media-query(992px) {
      margin-bottom: 50px;
    }
  }
  &__box {
    background-color: $white-color;
    border: 1px solid #e4e6e9;
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 4px 40px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 25px;
    @include media-query(992px) {
      padding: 35px 25px 20px;
    }
    @include media-query(1180px) {
      padding: 43px 50px 50px;
      display: flex;
      flex-direction: column;
    }
    i {
      display: inline-block;
      height: 50px;
    }
    &--green {
      border-bottom: 8px solid #3cc065;
    }
    &--blue {
      border-bottom: 8px solid #4492dc;
    }
  }
  &__boxheading {
    font-family: "Poppins";
    font-size: 28px;
    line-height: 33px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 15px;
    text-align: center;
    color: $black-color;
    @include media-query(992px) {
      font-size: 31px;
      line-height: 36px;
    }
  }
  &__boxcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: $black-color;
  }
  &__boxbtn {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    margin-top: auto;
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 600;
    border: 2px solid #ff5800;
    @include media-query(992px) {
      max-width: 250px;
    }
  }
  &__btn {
    width: 100%;
    max-width: 100%;
    margin: 27px auto 0;
    text-transform: capitalize;
    @include media-query(992px) {
      max-width: 275px;
    }
  }
}
.mb-30 {
  margin-bottom: 15px !important;
  @include media-query(768px) {
    margin-bottom: 30px !important;
  }
}
.o-why-capital {
  $o-why-capital: &;
  position: relative;
  background-color: #355bbf;
  .about-us__heading {
    color: $white-color;
    margin-bottom: 15px;
    text-align: center;
  }
  &__content {
    color: $white-color;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 45px;
  }
  &__box {
    width: 100%;
    height: 100%;
    background-color: $white-color;
    padding: 25px 22px;
    text-align: center;
    i {
      display: inline-block;
      height: 50px;
    }
    @include media-query(992px) {
      text-align: left;
    }
  }
  &__title {
    font-family: "Poppins";
    font-size: 21px;
    line-height: 26px;
    color: $black-color;
    margin: 18px 0 7px;
    font-weight: 600;
  }
  &__boxcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 22px;
    color: $black-color;
    margin: 0;
  }
  &__btn {
    max-width: 100%;
    width: 100%;
    text-transform: capitalize;
    padding: 15px;
    margin: 11px auto 0;
    @include media-query(992px) {
      max-width: 358px;
    }
  }
}

.o-people {
  $o-people: &;

  &__topcontent {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 30px;
    color: $black-color;
    margin: 25px auto;
    font-weight: 500;
    text-align: center;
    width: 100%;
    @include media-query(992px) {
      width: 92%;
      font-size: 25px;
      line-height: 37px;
    }
  }
  &__btn {
    padding: 15px;
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    margin: auto;
    @include media-query(992px) {
      max-width: 311px;
      margin: 30px auto 0;
    }
  }
  .team {
    position: relative;
    border-color: #898989 !important;
    border-radius: 0 !important;
    font-family: "Poppins";
    font-size: 15px;
    min-height: 348px;
    &:hover {
      border-color: #fff !important;
    }
    span {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .owl-dots {
    margin-top: 30px;
  }
  .splide__pagination {
    position: relative;
    margin: 30px 0 20px;
    .splide__pagination__page {
        &.is-active {
            background: #ff5800;
        }
    }
  }
}

.o-testimonial {
  $o-testimonial: &;
  background-color: #f9f9f9;

  &__heading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 35px;
    @include media-query(992px) {
      font-size: 51px;
      line-height: 56px;
    }
  }
  .splide__arrows {
    display: block;
  }
  .splide__arrow--prev {
    font-size: 45px !important;
    color: #000 !important;
    background-color: $white-color !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    bottom: -125px;
    top: inherit;
    left: 35%;
    @include media-query(992px) {
        left: 40%;
    }
    @include media-query(1100px) {
      left: 45%;
    }
    svg {
      position: relative;
      top: 0px;
    left: 0px;
    height: 20px;
    line-height: 0;
    width: 20px;
    }
    &:hover {
      color: #fff !important;
      background-color: #3cc065 !important;
      svg path {
        fill: #fff;
      }
    }
  }
  .splide__arrow--next {
    font-size: 45px !important;
    color: #000 !important;
    background-color: $white-color !important;
    width: 50px;
    height: 50px;
    border: 1px solid #3cc065 !important;
    line-height: 0;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: 300 !important;
    bottom: -125px;
    top: inherit;
    right: 35%;
    @include media-query(992px) {
        right: 40%;
    }
    @include media-query(1100px) {
      right: 45%;
    }
    svg {
      position: relative;
      top: 0px;
        right: 0px;
        height: 20px;
        line-height: 0;
        width: 20px;
    }
    &:hover {
      color: #fff !important;
      background-color: #3cc065 !important;
      svg path {
        fill: #fff;
      }
    }
  }
  &__list {
    .owl-nav {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      @include media-query(992px) {
        margin-top: 60px;
      }
    }
    .owl-prev {
      font-size: 45px !important;
      color: #000 !important;
      background-color: $white-color !important;
      width: 50px;
      height: 50px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        top: -3px;
        left: -1px;
        height: 50px;
        line-height: 50px;
      }
      &:hover {
        color: #fff !important;
        background-color: #3cc065 !important;
      }
    }
    .owl-next {
      font-size: 45px !important;
      color: #000 !important;
      background-color: $white-color !important;
      width: 50px;
      height: 50px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        top: -3px;
        right: -1px;
        height: 50px;
        line-height: 50px;
      }
      &:hover {
        color: #fff !important;
        background-color: #3cc065 !important;
      }
    }
  }
  &__item {
    max-width: 620px;
    width: 95%;
    margin: 0;
    @include media-query(992px) {
      margin: auto;
      background-color: $white-color;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07);
    }
  }
  &__itemimage {
    width: 100%;
    display: block;
    height: 300px;
    @include media-query(992px) {
      height: 435px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__itemcontent {
    padding: 20px;
    min-height: auto;
    border: 1px solid #e1e1e1;
    border-top: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include media-query(992px) {
      padding: 35px 25px 35px 42px;
      height: auto;
      min-height: 317px;
    }
  }
  &__itemtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 32px;
    color: $black-color;
    font-weight: 700;
    margin-bottom: 15px;
    @include media-query(992px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  &__itemtech {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
    color: $black-color;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 25px;
    }
    span {
      color: #4492dc;
    }
  }
  &__itembtn {
    font-family: "Poppins";
    color: #4492dc;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-top: auto;
    &:hover {
      color: #ff5800;
      svg {
        transform: translate(5px, 0);
        g {
          fill: #ff5800;
        }
      }
    }
  }
  &__more {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 26px;
    color: $black-color;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 40px;
    display: inline-block;
    &:hover {
      color: #ff5800;
      text-decoration: underline;
    }
    &--splidebtn{
      margin-top: 130px;
    }
  }
  &__more--about {
    margin-top: 140px;
  }
}
.bt-ass {
  border-top: 1px solid #e6e6e6;
}
.o-company-awards {
  $o-company-awards: &;
  &__heading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 38px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 51px;
      line-height: 56px;
      margin-bottom: 35px;
    }
  }
  &__navtab {
    border: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 27px;
    @include media-query(992px) {
      margin-bottom: 39px;
    }
    li {
      position: relative;
      max-width: 100px;
      width: 100%;
      @include media-query(992px) {
        max-width: 130px;
      }
      a {
        font-family: "Poppins";
        font-size: 18px;
        line-height: 30px;
        color: #898989;
        padding: 0 10px 20px;
        border-bottom: 1px solid #d9d9d9;
        display: block;
        width: 100%;
        text-align: center;
        @include media-query(992px) {
          font-size: 21px;
          line-height: 40px;
        }
      }
      &.active {
        a {
          color: #4492dc;
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 5px;
          background-color: #4492dc;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
  &__tab-content {
    min-height: 395px;
  }
  .o-company-awards__list {
    &:before {
      position: absolute;
      content: "";
      width: 25%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 9;
      display: none;
      @include media-query(992px) {
        width: 10%;
        display: block;
      }
      @include media-query(1600px) {
        width: 15%;
      }
      @include media-query(1800px) {
        width: 25%;
      }
    }
    &:after {
      position: absolute;
      content: "";
      width: 25%;
      height: 100%;
      right: 0;
      top: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 9;
      display: none;
      @include media-query(992px) {
        width: 10%;
        display: block;
      }
      @include media-query(1600px) {
        width: 15%;
      }
      @include media-query(1800px) {
        width: 25%;
      }
    }
  }
  &__list {
    position: relative;
    .splide__arrows {
      display: block !important;
      align-items: center;
      justify-content: center;
      margin: 32px 0 5px;
      position: absolute;
      bottom: -42px;
      width: 100%;
      svg {
        display: none;
      }
    }
    .splide__arrow--prev {
      opacity: 1;
      position: absolute;
      bottom: 0;
      left: 36%;
      width: 31px;
      height: 12px;
      background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-279" fill="%23D9D9D9" fill-rule="nonzero"><path d="M0.21966975,4.99264075 C-0.07322325,5.28553075 -0.07322325,5.76041075 0.21966975,6.05330075 L4.99263975,10.8262708 C5.28552975,11.1191708 5.76040975,11.1191708 6.05329975,10.8262708 C6.34618975,10.5333708 6.34618975,10.0584708 6.05329975,9.76557075 L1.81065975,5.52297075 L6.05329975,1.28033075 C6.34618975,0.98744075 6.34618975,0.51256275 6.05329975,0.21966975 C5.76040975,-0.07322325 5.28552975,-0.07322325 4.99263975,0.21966975 L0.21966975,4.99264075 Z M0.74999975,6.27297075 L30.7499998,6.27297075 L30.7499998,4.77297075 L0.74999975,4.77297075 L0.74999975,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 100% !important;
      margin: 0 13px;
      @include media-query(992px) {
        left: 46%;
      }
      @include media-query(1300px) {
        left: 46.5%;
      }
      &:hover {
        background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-279" fill="%23333333" fill-rule="nonzero"><path d="M0.21966975,4.99264075 C-0.07322325,5.28553075 -0.07322325,5.76041075 0.21966975,6.05330075 L4.99263975,10.8262708 C5.28552975,11.1191708 5.76040975,11.1191708 6.05329975,10.8262708 C6.34618975,10.5333708 6.34618975,10.0584708 6.05329975,9.76557075 L1.81065975,5.52297075 L6.05329975,1.28033075 C6.34618975,0.98744075 6.34618975,0.51256275 6.05329975,0.21966975 C5.76040975,-0.07322325 5.28552975,-0.07322325 4.99263975,0.21966975 L0.21966975,4.99264075 Z M0.74999975,6.27297075 L30.7499998,6.27297075 L30.7499998,4.77297075 L0.74999975,4.77297075 L0.74999975,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
      }
    }
    .splide__arrow--next {
      opacity: 1;
      position: absolute;
      bottom: 0;
      right: 36%;
      width: 31px;
      height: 12px;
      background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-280" transform="translate(0.000000, 0.477029)" fill="%23D9D9D9" fill-rule="nonzero"><path d="M30.5303,6.05330075 C30.8232,5.76041075 30.8232,5.28553075 30.5303,4.99264075 L25.7574,0.21966975 C25.4645,-0.07322325 24.9896,-0.07322325 24.6967,0.21966975 C24.4038,0.51256375 24.4038,0.98744075 24.6967,1.28033075 L28.9393,5.52297075 L24.6967,9.76557075 C24.4038,10.0584708 24.4038,10.5333708 24.6967,10.8262708 C24.9896,11.1191708 25.4645,11.1191708 25.7574,10.8262708 L30.5303,6.05330075 Z M0,6.27297075 L30,6.27297075 L30,4.77297075 L0,4.77297075 L0,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 100% !important;
      margin: 0 13px;
      @include media-query(992px) {
        right: 46%;
      }
      @include media-query(1300px) {
        right: 46.5%;
      }
      &:hover {
        background: url('data:image/svg+xml,<svg width="31px" height="12px" viewBox="0 0 31 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Vector-280" transform="translate(0.000000, 0.477029)" fill="%23333333" fill-rule="nonzero"><path d="M30.5303,6.05330075 C30.8232,5.76041075 30.8232,5.28553075 30.5303,4.99264075 L25.7574,0.21966975 C25.4645,-0.07322325 24.9896,-0.07322325 24.6967,0.21966975 C24.4038,0.51256375 24.4038,0.98744075 24.6967,1.28033075 L28.9393,5.52297075 L24.6967,9.76557075 C24.4038,10.0584708 24.4038,10.5333708 24.6967,10.8262708 C24.9896,11.1191708 25.4645,11.1191708 25.7574,10.8262708 L30.5303,6.05330075 Z M0,6.27297075 L30,6.27297075 L30,4.77297075 L0,4.77297075 L0,6.27297075 Z" id="Shape"></path></g></g></svg>') !important;
      }
    }
  }
  &__item {
    max-width: 375px;
    height: 351px;
    border: 1px solid #f6c7a3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 5px;
    flex-direction: column;
    background-color: $white-color;
    transition: 0.3s all ease-in-out;
    i {
      height: 144px;
      display: inline-flex;
      margin-bottom: 20px;
      min-width: 120px;
      width: auto;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        max-height: 100%;
      }
    }
    p {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 28px;
      font-weight: 500;
      margin: 0;
      text-align: center;
    }
    &:hover {
      border-color: #e1263b;
    }
  }
  &__btn {
    width: 100%;
    max-width: 100%;
    padding: 15px;
    margin: 20px auto 0;
    text-transform: capitalize;
    @include media-query(992px) {
      max-width: 363px;
      margin: 30px auto 0;
    }
  }
}

.o-social-responsible {
  $o-social-responsible: &;
  position: relative;
  background: #001a36;
  background-size: auto 100%;
  overflow: hidden;
  width: 100%;
  @include media-query(992px) {
    background: #001a36 url(/images/new-company-profile/social-background.png)
      no-repeat right bottom;
  }
  &:before {
    position: absolute;
    content: "";
    width: 420px;
    height: 400px;
    background: url(/images/new-company-profile/left-blue.svg) no-repeat bottom
      left;
    background-size: 100%;
    bottom: 0;
    left: 0;
    display: none;
    @include media-query(992px) {
      display: block;
    }
  }
  &__topgreen {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 450px;
      height: 460px;
      background: url(/images/new-company-profile/top-green.svg) no-repeat top
        left;
      background-size: 100%;
      top: -60px;
      left: -10%;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  &__heading {
    font-family: "Poppins";
    color: $white-color;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
    @include media-query(992px) {
      font-size: 40px;
      line-height: 50px;
      text-align: left;
    }
  }
  &__content {
    font-family: "Poppins";
    color: $white-color;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
      font-size: 25px;
      line-height: 37px;
    }
    span {
      color: #3cc065;
    }
  }
  &__btn {
    margin: auto;
    text-transform: capitalize;
    font-size: 17px;
    padding: 15px;
    max-width: 100%;
    width: 100%;
    @include media-query(992px) {
      max-width: 290px;
      margin: 0;
    }
  }
}
.btn-center-block1 {
  width: 100%;
  margin: auto;
  padding: 0 15px;
  @include media-query(768px) {
    max-width: 363px;
  }
}

.career-form{
    background-size: cover;
}
.new-career-form .common-input{
    width: 100%;
    @include media-query(768px){
        width: 46%;
        display: inline-block;
        margin: 0 20px 0 0;
    }
}
.new-career-form [data-tip] {
    width: 100%;
    @include media-query(768px){
        width: 46%;
        display: inline-block;
        margin: 0 20px 0 0;
    }
}
.new-career-form input.input7{
    background: url(/images/career/job-icon-7.png) 0 50% no-repeat!important;
    width: 100%;
}
.new-career-form input.years-of-experience {
    background: url(/images/career/job-icon-5.png) 0 50% no-repeat!important;
    width: 100%;
}
.new-career-form .p-skills-icon,
.new-career-form .s-skills-icon{
    background: url(/images/career/form-icon9.png) no-repeat 0 30px!important;
    padding-left: 30px;
}
.new-career-form .form-panel h4:before{
    display: none;
}
.new-career-form .p-skills-icon{
    width: 100%;
    @include media-query(768px){
        display: inline-block;
        width: 46%;
        margin-right: 20px;
    }
}
.new-career-form .s-skills-icon{
    width: 100%;
    @include media-query(768px){
        display: inline-block;
        width: 46%;
    }
}
.new-career-form .attach {
    background-position: 0 19px;
}
.new-career-form  #attachedFiles li{
    padding-top: 0!important;
}
.new-career-form .select2 ul li{
    padding-top: 0!important;
}
.new-career-form .select2 button{
    width: auto;
}
.career-form .u-submit{
    margin-top: 20px;
}

/*hire designer page css fixing and webflow page css fixing*/
.o-case-studies-new{
    .faq-holder{
        .accordion1 span.font-size-modify{
            font-size: 20px;
            @include media-query(992px){
                font-size: 24px !important;
            }
        }
    }
}

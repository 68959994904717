//Poppins Fonts Start
.mwc-header {
  $mwc-header: &;
  background-color: #102e6e;
  background-size: 101% 105%;
  max-width: 1146px;
  margin: 0 auto;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  padding: 5px 0;
  border-bottom: 1px solid #1d4c88;
  @include media-query(992px) {
    top: 15px;
    padding: 0;
    border: 1px solid #1d4c88;
    background: url(../images/mwc-new/banner/header-bg.jpg) no-repeat center center;
  }
  &__wrapper {
    align-items: center;
    @include media-query(992px) {
      display: flex;
    }
  }
  &__logo {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    @include media-query(992px) {
      flex: 0 0 50%;
      max-width: 50%;
      display: block;
      padding: 13px 3px 13px 35px;
      // border-right: 1px solid #385F85;
      margin-bottom: 0;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      background: #385f85;
      height: 1px;
      width: 90%;
      margin: 0 auto;
      @include media-query(992px) {
        display: none;
      }
    }
    svg {
      margin: 0 0 0 15px;
    }
  }
  .gsma-logo {
    display: inline-block;
    @include media-query(992px) {
      position: relative;
      left: -12px;
    }
  }
  .leap-logo {
    display: inline-block;
    @include media-query(992px) {
      position: relative;
      margin-left: 12px;
    }
  }
  &__information {
    text-align: center;
    margin-top: 10px;
    @include media-query(992px) {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding-left: 58px;
      text-align: left;
      margin-top: 0;
      position: relative;
    }
    &::before {
      @include media-query(992px) {
        content: "";
        position: absolute;
        left: 3px;
        top: -3px;
        width: 1px;
        height: 72px;
        background: #385f85;
      }
    }
    p {
      margin-bottom: 0;
      font-family: "Poppins";
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      @include media-query(992px) {
        font-size: 22px;
        line-height: 33px;
      }
    }
    .line1 {
      color: #fff;
    }
    .line2 {
      color: #3cc065;
    }

    &--leap {
      @include media-query(992px) {
        padding: 13px 0 13px 60px;
      }
      &:before {
        @include media-query(992px) {
          top: 13px;
          left: 13px;
          width: 1px;
          height: 84px;
        }
      }
      p {
        @include media-query(992px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}

.mwc-main-banner-wrap {
    background: #061d3a;
    padding-top: 15px;
    @include media-query(992px) {
      background: url(/images/mwc-new/banner/bg-graphic.jpg) no-repeat center center / cover;
    }
}

.mwc-banner {
  $mwc-banner: &;
  padding: 0 0 50px !important;
  position: relative;
  @include media-query(992px) {
    padding: 45px 0 80px !important;
  }
  &--leap {
    padding: 50px 0 50px !important;
    @include media-query(992px) {
      padding: 45px 0 80px !important;
    }
  }
  &__top {
    margin-top: 30px;
    @include media-query(992px) {
      margin-top: 0;
    }
  }
  &__left {
    h1 {
      font-family: "Poppins";
      font-size: 32px;
      line-height: 42px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
      @include media-query(992px) {
        font-size: 49px;
        line-height: 60px;
        text-align: left;
        margin-bottom: 15px;
      }
      @include media-query(1300px) {
        font-size: 51px;
      }
    }
    h3{
      font-family: Poppins;
      font-size: 20px;
      line-height: 40px;
      font-weight: 600;
      color: #3cc065;
      text-align: center;
      @include media-query(992px) {
        font-size: 31px;
        line-height: 47px;
        text-align: left;
      }
    }
    p {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 34px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      @include media-query(992px) {
        font-size: 25px;
        line-height: 38px;
        text-align: left;
        padding-right: 115px;
      }
      &.long-text{
        font-weight: 400;
        @include media-query(992px) {
          font-size: 24px;
          line-height: 32px;
        }
        strong{
          font-weight: 600;
        }
      }
      a {
        position: relative;
        display: inline-block;
        color: #fff;
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
        }
        &.bottom-line1 {
          &:before {
            background: #64b9f4;
          }
        }
        &.bottom-line2 {
          &:before {
            background: #e26826;
          }
        }
        &.bottom-line3 {
          &:before {
            background: #8ac640;
          }
        }
        &.bottom-line4 {
          &:before {
            background: #fbba14;
          }
        }
        &.bottom-line5 {
          &:before {
            background: #804b8b;
          }
        }
        &.bottom-line6 {
          &:before {
            background: #64b9f4;
          }
        }
        &.bottom-line7 {
          &:before {
            background: #e98e5c;
          }
        }
      }
    }
    &--post{
      h1{
        @include media-query(992px) {
          font-size: 38px;
          line-height: 50px;
        }
      }
      p{
        font-weight: 400;
        @include media-query(992px) {
          font-size: 21px;
          line-height: 32px;
        }
        strong{
          font-weight: 600;
        }
      }
    }
  }
  &__right {
    text-align: center;
    flex: 0 0 100%;
    @include media-query(992px) {
      flex: 1;
      text-align: right;
    }
  }
  &__bracket {
    height: 115px;
    @include media-query(992px) {
      height: 165px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__ratting {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(992px) {
      justify-content: flex-start;
    }
  }
  &__animated {
    position: relative;
    width: 150px;
    height: 150px;
    pointer-events: none;
    position: relative;
    top: -15px;
    @include media-query(992px) {
      width: 200px;
      height: 200px;
      position: static;
    }
    img {
      position: relative;
      transform: rotate(270deg);
    }
    .animated1 {
      top: 18px;
      left: 12px;
      width: 140px;
      @include media-query(992px) {
        top: 40px;
        left: 35px;
      }
    }
    .animated2 {
      top: 22px;
      left: 5px;
      @include media-query(992px) {
        top: 47px;
        left: 24px;
      }
    }
    .animated3 {
      top: 15px;
      left: 6px;
      @include media-query(992px) {
        top: 40px;
        left: 29px;
      }
    }
    .animated4 {
      top: 32px;
      left: 7px;
      @include media-query(992px) {
        top: 56px;
        left: 30px;
      }
    }
  }
  &__bottom {
    position: relative;
    margin-top: 15px;
    @include media-query(992px) {
      margin-top: 60px;
    }
    h2 {
      font-family: "Poppins";
      position: absolute;
      top: -25px;
      left: 0;
      right: 0;
      color: #000;
      font-weight: 700;
      font-size: 20px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      text-align: center;
      background: #fcc900;
      padding: 5px 0;
      width: 250px;
      z-index: 99;
      line-height: 31px;
      margin: 0 auto;
      @include media-query(992px) {
        font-size: 25px;
        width: 300px;
        padding: 11px 0;
        top: -35px;
        left: 0;
        right: inherit;
        margin: 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: -24px;
        left: 39%;
        background: url(/images/mwc-new/banner/touble-triangle.svg) no-repeat
          center center;
        width: 45px;
        height: 37px;
      }
    }
  }
  &__gradient {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    padding: 5px;
    &:before {
      content: "";
      position: absolute;
      top: -307px;
      left: -113px;
      width: 1400px;
      height: 1400px;
      animation: rotation2 3s linear infinite;
      background: linear-gradient(
        180deg,
        #88deb1 0,
        #1ab864 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #88deb1 0,
        #1ab864 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
      opacity: 0.5;
    }
  }
  &__offer {
    padding: 30px 20px 20px;
    position: relative;
    background: #061833;
    z-index: 9;
    border-radius: 10px;
    @include media-query(992px) {
      display: flex;
      gap: 15px;
      padding: 30px 20px;
    }
  }
  &__offer1 {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: rgb(18, 183, 70);
    background: -moz-linear-gradient(
      142deg,
      rgba(18, 183, 70, 1) 0%,
      rgba(0, 154, 48, 1) 100%
    );
    background: -webkit-linear-gradient(
      142deg,
      rgba(18, 183, 70, 1) 0%,
      rgba(0, 154, 48, 1) 100%
    );
    background: linear-gradient(
      142deg,
      rgba(18, 183, 70, 1) 0%,
      rgba(0, 154, 48, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12b746",endColorstr="#009a30",GradientType=1);
    margin-bottom: 15px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
  }
  &__offer2 {
    flex: 1;
    display: flex;
    align-items: center;
    background: rgb(50, 150, 243);
    padding: 10px;
    border-radius: 5px;
    background: -moz-linear-gradient(
      142deg,
      rgba(50, 150, 243, 1) 0%,
      rgba(31, 92, 210, 1) 100%
    );
    background: -webkit-linear-gradient(
      142deg,
      rgba(50, 150, 243, 1) 0%,
      rgba(31, 92, 210, 1) 100%
    );
    background: linear-gradient(
      142deg,
      rgba(50, 150, 243, 1) 0%,
      rgba(31, 92, 210, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3296f3",endColorstr="#1f5cd2",GradientType=1);
  }
  .shine-effect {
    position: relative;
    overflow: hidden;
    &:before {
      background: linear-gradient(
        to right,
        fade_out(#fff, 1) 0%,
        fade_out(#fff, 0.7) 100%
      );
      content: "";
      display: block;
      height: 100%;
      left: -75%;
      position: absolute;
      top: 0;
      transform: skewX(-25deg);
      width: 50%;
      z-index: 2;
    }
    &:hover,
    &:focus {
      &:before {
        animation: shine 0.85s linear;
      }
    }
  }
  &__offer-img {
    flex: 0 0 100px;
    max-width: 100px;
    height: 90px;
    overflow: hidden;
    border-radius: 4px;
    @include media-query(992px) {
      flex: 0 0 147px;
      max-width: 147px;
      height: 138px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__offer-info {
    margin-left: 10px;
    @include media-query(992px) {
      margin-left: 25px;
    }
    h3 {
      font-family: "Poppins";
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      @include media-query(992px) {
        font-size: 31px;
        line-height: 36px;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
    span {
      font-family: "Poppins";
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      display: block;
      width: 90px;
      @include media-query(992px) {
        font-size: 16px;
        width: 109px;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
      }
    }
  }
  .owl-carousel-vertical {
    transform: rotate3d(0, 0, 1, 90deg);
    height: 100%;
    .owl-stage-outer {
      height: 100%;
    }
  }
  .owl-carousel-vertical .item {
    transform: rotate3d(0, 0, 1, -90deg);
  }
  // meeticons section
  &__meeticons {
    position: relative;
    padding: 0 0 20px;
    @include media-query(570px) {
      padding: 20px 0;
    }
    @include media-query(768px) {
      padding: 0;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    @include media-query(1024px) {
      margin-bottom: 0;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 1px;
      height: 70px;
      background-color: #fff;
      left: 0;
      right: 0;
      top: 5px;
      margin: auto;
      @include media-query(570px) {
        height: 60px;
        top: 20px;
      }
      @include media-query(770px) {
        display: none;
      }
    }
  }
  &__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    @include media-query(770px) {
      width: 33%;
      padding: 10px;
    }
    @include media-query(992px) {
      width: 16.66%;
      padding-bottom: 30px;
      flex-direction: row;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      justify-content: flex-start;
    }
    &:before {
      display: none;
      @include media-query(770px) {
        display: block;
        position: absolute;
        content: "";
        width: 93%;
        height: 1px;
        background-color: #5b81e1;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &:after {
      display: none;
      @include media-query(770px) {
        display: block;
        position: absolute;
        content: "";
        width: 1px;
        height: 85%;
        background-color: #5b81e1;
        top: 49%;
        right: -1px;
        transform: translateY(-50%);
      }
    }
    &:nth-child(3n + 3) {
      &:after {
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
    }
    &:nth-child(6n + 6) {
      @include media-query(992px) {
        padding-left: 10px;
        &:after {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 6) {
      @include media-query(992px) {
        padding-bottom: 5px;
        &:before {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 5) {
      @include media-query(992px) {
        padding-bottom: 5px;
        &:before {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 4) {
      @include media-query(992px) {
        padding-bottom: 5px;
        &:before {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 3) {
      @include media-query(770px) {
        &:before {
          display: none;
        }
      }
      @include media-query(992px) {
        padding-bottom: 5px;
        &:before {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 2) {
      @include media-query(770px) {
        &:before {
          display: none;
        }
      }
      @include media-query(992px) {
        padding-bottom: 5px;
      }
    }
    img {
      margin-right: 10px;
      @include media-query(992px) {
        margin-right: 0;
      }
    }
  }
  &__listcontent {
    margin-top: 5px;
    margin-left: 0;
    text-align: left;
    @include media-query(992px) {
      margin-top: 0;
      margin-left: 10px;
    }
  }
  &__contenttittle {
    font-family: "Poppins";
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    color: #358acb;
    margin: 0;
    @include media-query(992px) {
      font-size: 17px;
      line-height: 22px;
    }
  }
  &__contenttext {
    font-family: "Poppins";
    color: #fff;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &__container {
    position: relative;
    @include media-query(992px) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .highest-rated {
    background: none;
    padding-bottom: 0;
  }
  .highest-rated__content-area {
    justify-content: space-between;
    @include media-query(992px) {
        max-width: 600px;
    }
  }
  .highest-rated__image-area {
    @include media-query(992px) {
        gap: 25px;
    }
  }
  .highest-rated__ratings {
    height: auto;
  } 
}

// @keyframes bannermove {
//     0% {
//        margin-top: 0px;
//     }
//     100% {
//        margin-top: -540px;
//     }

// }

@keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes rotation2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes horizontal-shaking {
  // 0% { transform: translateX(0) }
  // 25% { transform: translateX(15px) }
  // 50% { transform: translateX(-15px) }
  // 75% { transform: translateX(15px) }
  // 100% { transform: translateX(0) }
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shake-animation {
  animation: horizontal-shaking 0.2s;
}

.trusted {
  $trusted: &;
  padding: 40px 0 50px;
  @include media-query(992px) {
    padding: 80px 0;
  }
  &__container {
    position: relative;
    @include media-query(992px) {
      display: flex;
      justify-content: flex-end;
    }
  }
  h2 {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #262e36;
    margin-bottom: 15px;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
      font-size: 25px;
      line-height: 29px;
      margin-bottom: 30px;
    }
  }
  .good-company__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px 20px;
    margin-bottom: 25px;
    justify-content: center;
    @include media-query(992px) {
      display: flex;
      justify-content: inherit;
      gap: 30px 20px;
      min-height: 200px;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      flex: 0 0 31%;
      @include media-query(992px) {
        flex: auto;
        justify-content: inherit;
      }
    }
  }
  .owl-carousel .owl-stage {
    display: flex;
    align-items: center;
  }
  .m-good-company-slider {
    .good-company__list {
      li {
        flex: 0 0 47%;
        max-width: 47%;
        @include media-query(992px) {
          height: 120px;
        }
      }
    }
  }
  .owl-dots {
    .owl-dot {
      background: #e1e1e1;
      &.active {
        background: #ff5800;
      }
    }
  }
  &__wrapper-top {
    @include media-query(992px) {
      margin-bottom: 25px;
    }
  }
  &__slider-wrapper {
    margin-bottom: 25px;
  }
}

.book-an-appointment-toggle {
  position: fixed;
  bottom: 15px;
  background: #4492dc;
  z-index: 9999999;
  width: 50px;
  height: 50px;
  right: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
  &.close {
    top: 13px;
    bottom: inherit;
    opacity: 1;
    width: 30px;
    height: 30px;
    &::before {
      content: "✕";
      position: absolute;
      font-size: 16px;
      color: #fff;
    }
    img {
      visibility: hidden;
    }
  }
}
.super-sticky {
    @include media-query(992px) {
        position: absolute;
        z-index: 9999;
        padding-top: 15px;
    }
    &.sticky-active {
        @include media-query(992px) {
            position: fixed;
            top: 0;
        }
      }
}
.mwc-appointment-form {
  background: none;
  position: relative;
  width: 100%;
  padding: 0;
  box-shadow: 1px 10px 9px 10px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  @include media-query(992px) {
    display: block;
    right: inherit;
    left: inherit;
    width: 440px;
  }
  &.show {
    display: block;
    height: 100vh;
    background: #fff;
  }
  .form-panel {
    float: none;
    width: 100%;
    padding: 20px 0;
    background: #fff;
    @include media-query(992px) {
      padding: 25px;
    }
  }
  .common-input {
    font-family: "Poppins";
    padding: 10px 15px 10px 15px;
    background: none;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
    @include media-query(992px) {
      padding: 10px 15px 10px 15px;
    }
    &::placeholder {
      color: #333 !important;
      font-size: 14px !important;
    }
    &.u-message {
      height: 85px;
      @include media-query(992px) {
        height: 75px;
      }
      margin-bottom: 5px !important;
    }
  }
  .u-submit {
    font-family: "Poppins";
    padding: 12px 0;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
    margin: 15px 0 0 0;
    max-width: 100%;
  }
  .book-slot-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 10px;
    @include media-query(992px) {
      flex-wrap: inherit;
    }
    .date-slot {
      flex: 1;
      padding: 10px 10px 10px 10px;
      margin-bottom: 0;
      @include media-query(992px) {
        padding: 10px 10px 10px 10px;
        flex: 0 0 43%;
      }
    }
    .time-slot {
      flex: 1;
      padding: 10px 10px 10px 10px;
      margin-bottom: 0;
      @include media-query(992px) {
        padding: 10px 10px 10px 10px;
        flex: 0 0 30%;
      }
    }
  }
  .book-a-slot-label {
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 1;
    font-size: 0;
    position: absolute;
  }
  .book-a-slot-heading {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #163996;
    flex: 0 0 100%;
    margin-bottom: 15px;
    @include media-query(992px) {
      flex: auto;
      margin-bottom: 0;
    }
  }
  .slot-radio-wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  .slot-radio {
    height: 42px;
    position: relative;
    margin-top: 15px;
    flex: 1;
  }

  .slot-radio label,
  .slot-radio input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .slot-radio input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    cursor: pointer;
  }

  .slot-radio input[type="radio"]:checked + label {
    border-radius: 4px;
    font-weight: 600;
    border: 1px solid #757575;
    transition: 0.3s all ease;
  }

  .slot-radio label {
    cursor: pointer;
    z-index: 90;
    line-height: 1.8em;
    font-family: "Poppins";
    font-size: 15px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
  }
  .mwc-appointment-form-top {
    background: #163996;
    padding: 15px 20px 20px 20px;
    position: relative;
    @include media-query(992px) {
      padding: 25px 20px 30px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: url(/images/mwc-new/trusted/stripe-bg.jpg) repeat-x;
      background-position: 15px 0px;
    }
    h3 {
      font-family: "Poppins";
      color: #fff;
      text-align: center;
      font-size: 19px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 0;
      .light-text {
        font-family: "Poppins";
        font-size: 20px;
      }
      .hall-info {
        font-family: "Poppins";
        font-size: 20px;
        color: #53d094;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 7px;
          bottom: 0;
          height: 1px;
          width: 96%;
          background: #53d094;
        }
      }
    }
  }
}

.common-left-side-wrapper {
  max-width: 100%;
  @include media-query(992px) {
    max-width: 690px;
  }
}
.common-bottom-area {
  max-width: 320px;
  margin: 0 auto;
  @include media-query(992px) {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0;
  }
  .common-orange-btn {
    font-family: "Poppins";
    border: 2px solid #ff5800;
    border-radius: 5px;
    background: #ff5800;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 17px;
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    @include media-query(992px) {
      flex: 0 0 340px;
      max-width: 340px;
      transition: 0.3s all ease-in;
    }
    .common-arrow {
      margin-left: 15px;
      transition: 0.3s all ease-in;
    }
    &:hover {
      background: none;
      color: #ff5800;
      .common-arrow {
        transform: translate(15px, 0);
      }
      path {
        fill: #ff5800;
      }
    }
  }
  .email-line {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #898989;
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
    display: block;
    flex: 1;
    @include media-query(992px) {
      text-align: left;
      margin-top: 0;
      margin-left: 25px;
      font-size: 18px;
      line-height: 28px;
      display: inline;
      flex: inherit;
    }
    a {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 700;
      color: #4492dc;
      position: relative;
      max-width: 252px;
      margin: 0 auto;
      @include media-query(992px) {
        max-width: 308px;
        max-width: inherit;
        margin: 0;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #4492dc;
      }
    }
  }
}

.fortune-information {
  $fortune-information: &;
  padding: 50px 0;
  @include media-query(992px) {
    padding: 80px 0;
  }
  background: #0d2642;
  h2 {
    font-family: "Poppins";
    color: $white-color;
    font-size: 21px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
      padding-right: 50px;
      font-size: 24px;
      line-height: 34px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    span {
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
      }
      &:nth-child(1) {
        &:after {
          background: #64b9f4;
        }
      }
      &:nth-child(2) {
        &:after {
          background: #e26826;
        }
      }
      &:nth-child(3) {
        &:after {
          background: #8ac640;
        }
      }
      &:nth-child(4) {
        &:after {
          background: #fbba14;
        }
      }
      &:nth-child(5) {
        &:after {
          background: #64b9f4;
        }
      }
      &:nth-child(6) {
        &:after {
          background: #804b8b;
        }
      }
    }
  }
  &__meetsarea {
    position: relative;
    max-width: 660px;
    @include media-query(992px) {
      max-width: 660px;
      margin-bottom: 30px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 29px;
      margin: 0 auto;
      width: 1px;
      height: 41%;
      background: #5b81e1;
      display: block;
      @include media-query(992px) {
        display: none;
        height: 76%;
      }
    }
    .owl-dots {
      background: #0d2642;
      .owl-dot {
        background: #e1e1e1;
        &.active {
          background: #ff5800;
        }
      }
    }
  }
  &__meetslist {
    display: flex;
    @include media-query(992px) {
      flex-wrap: wrap;
    }
  }
  &__meetsitem {
    position: relative;
    width: 100%;
    padding: 0;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    @include media-query(992px) {
      width: 33.33%;
      padding-bottom: 30px;
      align-items: flex-start;
      flex-direction: row;
      border-right: 1px solid #5b81e1;
      border-bottom: 1px solid #5b81e1;
    }
    &:after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #0d2642;
      bottom: -7.5px;
      right: -7.5px;
      z-index: 1;
    }

    &:nth-child(2n + 2) {
      border-right: none;
      &:after {
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
      @include media-query(992px) {
        border-right: 1px solid #5b81e1;
      }
    }
    &:nth-child(3n + 3) {
      @include media-query(992px) {
        border-right: none;
        padding-left: 20px;
        &:after {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 3) {
      @include media-query(992px) {
        border-bottom: none;
        padding-top: 30px;
        padding-bottom: 10px;
        &:after {
          display: none;
        }
      }
    }
    &:nth-last-child(-n + 2) {
      border-bottom: none;
    }
    &:nth-child(2n) {
      justify-content: center;
      .excellence-meets__listcontent {
        max-width: 100px;
      }
    }
    &:nth-child(5n) {
      justify-content: center;
      .excellence-meets__listcontent {
        margin: 0;
        max-width: 120px;
      }
    }
  }
  &__listcontent {
    margin-top: 20px;
    margin-left: 10px;
    max-width: 100%;
    text-align: left;
    @include media-query(992px) {
      margin-top: 0;
      margin-left: 20px;
      max-width: 162px;
    }
  }
  &__contenttittle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    margin-top: 5px;
    @include media-query(992px) {
      font-size: 23px;
      line-height: 25px;
      margin-top: 10px;
    }
  }
  &__contenttext {
    font-family: "Poppins";
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    margin: 0;
    @include media-query(992px) {
      font-size: 15px;
      line-height: 18px;
    }
  }
  &--new {
    .fortune-information__meetsarea {
      &::before {
        top: 15px;
      }
    }
    .fortune-information__contenttittle {
      font-size: 18px;
      line-height: 20px;
      color: #358acb;
      @include media-query(992px) {
        font-size: 23px;
        line-height: 25px;
        margin-top: 5px;
      }
    }
    .fortune-information__meetsitem {
      @include media-query(992px) {
        padding-bottom: 20px;
      }
      &:nth-child(2n) {
        justify-content: flex-start;
      }
      &::after {
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
      &:nth-last-child(-n + 3) {
        @include media-query(992px) {
          padding-top: 20px;
        }
      }
    }
  }
  .common-bottom-area {
    margin-top: 20px;
    @include media-query(992px) {
      margin-top: 0;
    }
  }
}

.limited-time {
  $limited-time: &;
  padding: 40px 0 50px;
  border-bottom: 1px solid #e6e6e6;
  @include media-query(992px) {
    padding: 100px 0;
  }
  &__wrapper {
    max-width: 660px;
  }
  h2 {
    font-family: "Poppins";
    font-size: 32px;
    line-height: 40px;
    color: #262e36;
    margin-bottom: 15px;
    text-align: center;
    @include media-query(992px) {
      font-size: 51px;
      line-height: 40px;
      text-align: left;
      margin-bottom: 60px;
    }
    .mwc-offer-black {
      color: #000;
      display: block;
      margin-top: 0;
      @include media-query(992px) {
        display: inline-block;
        margin-top: 0;
        margin-top: 24px;
      }
    }
    .top-graphic {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -9px;
        right: 0;
        left: 0;
        margin: auto;
        background: url(/images/mwc-new/banner/touble-triangle.svg) no-repeat
          center center;
        width: 18px;
        height: 18px;
        background-size: 100%;
        @include media-query(992px) {
          top: -32px;
          width: 45px;
          height: 37px;
          background-size: inherit;
        }
      }
    }
  }
  &__card-top {
    height: 1px;
  }
  &__card {
    position: relative;
    margin-top: 13px;
    margin-bottom: 30px;
    padding: 30px 15px;
    border: 1px solid;
    border-top: 8px solid;
    border-color: #3cc065;
    background: $white-color;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    @include media-query(992px) {
      padding: 45px 39px;
      margin-bottom: 43px;
    }
    h3 {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      position: absolute;
      left: 15px;
      top: -24px;
      padding: 10px 10px;
      color: #000;
      background: #fcc900;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      @include media-query(992px) {
        top: -23px;
        left: 39px;
        font-size: 19px;
        padding: 8px 14px;
      }
      &:before {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 16px 12px 0 0;
        border-color: #fcc900 transparent transparent transparent;
        transform: rotate(0deg);
        right: -12px;
        top: 0;
      }
      &:after {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 16px 0 0 12px;
        border-color: transparent transparent transparent #fcc900;
        transform: rotate(0deg);
        right: -12px;
        bottom: 0;
      }
    }
    h4 {
      font-family: "Poppins";
      font-size: 21px;
      line-height: 30px;
      color: #262e36;
      margin-bottom: 16px;
      font-weight: 700;
      @include media-query(992px) {
        font-size: 31px;
      }
    }
    ul {
      padding-left: 30px;
      margin-bottom: 20px;
      @include media-query(992px) {
        margin-bottom: 28px;
      }
      li {
        font-family: "Poppins";
        position: relative;
        color: #262e36;
        font-size: 16px;
        line-height: 28px;
        @include media-query(992px) {
          font-size: 17px;
        }
        &:before {
          position: absolute;
          content: "";
          left: -30px;
          top: 5px;
          width: 16px;
          height: 16px;
          background: url(/images/mwc-new/icon/green-tick.svg) no-repeat center
            center;
          background-size: 100% 100%;
        }
      }
    }
    .btn {
      font-family: "Poppins";
      text-transform: capitalize;
      margin: 0;
      border: 2px solid $black-color;
      font-weight: 600;
      padding: 10px;
      width: 214px;
      font-size: 15px;
      font-weight: 700;
      color: $black-color;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease-in;
      .far-arrow {
        margin-left: 15px;
        transition: 0.3s all ease-in;
      }
      &:hover {
        background: none;
        color: #ff5800;
        border-color: #ff5800;
        .far-arrow {
          transform: translate(15px, 0);
        }
        path {
          fill: #ff5800;
        }
      }
    }
    &--blue {
      border-color: #4492dc;
      ul {
        li {
          &:before {
            background: url(/images/mwc-new/icon/blue-tick.svg) no-repeat center
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  &__lets-meet {
    position: relative;
    h5 {
      font-family: "Poppins";
      font-size: 26px;
      line-height: 36px;
      color: #262e36;
      font-weight: 600;
      text-align: center;
      @include media-query(992px) {
        font-size: 36px;
        text-align: left;
      }
    }
    p {
      font-family: "Poppins";
      font-size: 20px;
      line-height: 30px;
      color: #262e36;
      font-weight: 500;
      margin-bottom: 18px;
      text-align: center;
      @include media-query(992px) {
        font-size: 24px;
        line-height: 36px;
        text-align: left;
      }
      span {
        background-color: #7feba1;
        padding: 0 5px;
        border-radius: 10px;
        font-weight: 600;
        display: block;
        max-width: 300px;
        margin: 0 auto;
        @include media-query(992px) {
          display: inline-block;
          max-width: inherit;
          margin: 0;
        }
      }
    }
    &--post{
      h5{
        @include media-query(992px) {
          font-size: 29px;
        }
      }
      p{
        font-size: 16px;
        line-height: 24px;
        @include media-query(992px) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
  .common-bottom-area {
    .email-line {
      a {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

.mwc-reviews {
  $mwc-reviews: &;

  position: relative;
  background-color: #f3f3f3;
  padding: 30px 0 80px;
  @include media-query(992px) {
    padding: 76px 0 98px;
  }
  &__star {
    margin-bottom: 11px;
    display: flex;
    justify-content: flex-start;
    li {
      margin-left: 1px;
      margin-right: 1px;
      @include media-query(992px) {
        margin-left: inherit;
        margin-right: 3px;
      }
    }
  }
  h2 {
    font-family: "Poppins";
    font-size: 26px;
    line-height: 36px;
    color: #262e36;
    padding-bottom: 34px;
    margin-bottom: 22px;
    text-align: left;
    @include media-query(992px) {
      font-size: 42px;
      line-height: 46px;
      margin-bottom: 35px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      background-color: #498df9;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      @include media-query(992px) {
        right: inherit;
      }
    }
  }
  &__reviewarea {
    @include media-query(992px) {
      padding-bottom: 30px;
      max-width: 660px;
    }
    .owl-nav {
      margin-bottom: 0;
      margin-top: 10px;
      width: auto;
      text-align: left;
      @include media-query(992px) {
        float: right;
        margin-bottom: 0;
        margin-top: 40px;
        margin-right: -5px;
        margin-bottom: 15px;
      }
      .owl-prev {
        font-size: 30px !important;
        color: #3cc065 !important;
        width: 36px;
        height: 36px;
        border: 1px solid #3cc065 !important;
        line-height: 0;
        border-radius: 50%;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 5px;
        font-weight: 300 !important;
        @include media-query(992px) {
          font-size: 45px !important;
          width: 50px;
          height: 50px;
        }
        span {
          position: relative;
          top: -1px;
          left: -1px;
          height: 50px;
          line-height: 50px;
          @include media-query(992px) {
            top: -3px;
          }
        }
        &:hover {
          color: #fff !important;
          background-color: #3cc065 !important;
        }
      }
      .owl-next {
        font-size: 30px !important;
        color: #3cc065 !important;
        width: 36px;
        height: 36px;
        border: 1px solid #3cc065 !important;
        line-height: 0;
        border-radius: 50%;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 5px;
        font-weight: 300 !important;
        @include media-query(992px) {
          font-size: 45px !important;
          width: 50px;
          height: 50px;
        }
        span {
          position: relative;
          top: -1px;
          right: -1px;
          height: 50px;
          line-height: 50px;
          @include media-query(992px) {
            top: -3px;
          }
        }
        &:hover {
          color: #fff !important;
          background-color: #3cc065 !important;
        }
      }
    }
  }
  &__reviewitems {
    max-width: 100%;
    height: 100%;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 20px 20px 20px 20px;
    margin: 0 0 20px;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    @include media-query(992px) {
      height: auto;
      max-width: 345px;
      margin: 0 0 30px;
      min-height: 380px;
      padding: 38px 30px 50px 43px;
    }
    &:hover {
      border-color: #fff;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &__quoteimg {
    margin-bottom: 20px;
  }
  &__quotecontent {
    font-family: "Poppins";
    color: #333;
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
  }
  &__authorarea {
    display: flex;
    margin-top: 15px;
    @include media-query(992px) {
      margin-top: auto;
    }
  }
  &__authorimage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__authordetails {
    flex: 1;
    margin-left: 15px;
  }
  &__authorname {
    font-family: "Poppins";
    font-size: 18px;
    line-height: normal;
    color: #333;
    font-weight: 600;
    margin: 0;
  }
  &__authordesignation {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin: 0;
    font-weight: 600;
  }
  &__authorcompany {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 20px;
    color: #898989;
    margin: 0;
    font-weight: 500;
  }
  .splide__arrows {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -80px;
  }
  .splide__arrow {
    position: static;
    width: 50px;
    height: 50px;
    background: none;
    border: 1px solid #3cc065;
    transition: .3s all ease-in-out;
    svg {
      path {
        fill: #3cc065;
      }
    }
    &:hover {
      background: #3cc065;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .splide__arrow--prev {
    margin-right: 10px;
  }
}

.mwc-footer {
  $mwc-footer: &;
  padding: 50px 0;
  background-color: #04254a;
  font-family: "Poppins";
  @include media-query(992px) {
    padding: 79px 0 82px;
  }

  &__top {
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #244376;
    text-align: left;
    @include media-query(992px) {
      max-width: 660px;
    }
  }
  &__paragraph {
    font-family: "Poppins";
    font-size: 12px;
    line-height: 20px;
    color: #aec7da;
    margin: 20px 0 0;
    @include media-query(992px) {
      padding-right: 30px;
    }
  }
  &__bottom {
    text-align: left;
    p {
      font-family: "Poppins";
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      @include media-query(992px) {
        line-height: 15px;
      }
    }
  }
  &__copyright {
    font-family: "Poppins";
    margin-bottom: 15px;
  }
}

.datepicker-wrapper {
  position: relative;
  display: flex;
  border: 1px solid #dbdbdb;
  width: 100%;
  align-items: center;
  justify-content: center;
  input {
    font-family: "Poppins";
    border: 0;
    font-weight: 400;
    font-size: 15px;
    flex: 1;
    text-align: center;
    padding: 10px 15px;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }
  #prevBtn {
    flex: 0 0 40px;
    max-width: 40px;
    font-size: 24px;
  }
  #nextBtn {
    flex: 0 0 40px;
    max-width: 40px;
    font-size: 24px;
  }
}

.mwc-gain{
  margin: 40px 0 0;
  .btn{
    font-family: Poppins;
    border: 2px solid #ff5800;
    border-radius: 5px;
    background: #ff5800;
    max-width: fit-content;
    width: 100%;
    padding: 17px;
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    .common-arrow{
      margin-left: 15px;
      transition: .3s all ease-in;
    }
    &:hover{
      .common-arrow{
        transform: translate(10px, 0);
      }
    }
  }
  p{
    font-family: Poppins;
    position: relative;
    color: #262e36;
    font-size: 17px;
    line-height: 28px;
    margin: 10px 0 20px;
  }
}
// .ui-datepicker {
//     position: absolute !important;
//     z-index: 99999 !important;
//     top: 0 !important;
//     left: 0 !important;
//     right: 0 !important;
//     margin: 0 auto;
// }
.custom-day {
  font-weight: 700;
}
// MWC CAESTUDY
.mwc-casestudy-wrap{
  width: 100%;
  @include media-query(992px) {
    max-width: 660px;
  }
  .o-testimonial__heading{
    font-family: Poppins;
    font-size: 26px !important;
    line-height: 36px !important;
    color: #262e36;
    padding-bottom: 34px;
    text-align: left;
    font-weight: 800 !important;
    margin: 0 !important;
    @include media-query(992px) {
      font-size: 42px !important;
      line-height: 46px !important;
      margin-bottom: 35px;
    }
  }
  .o-testimonial__more--splidebtn{
    margin: 20px 0 0 !important;
    position: relative;
    text-decoration: none;
    font-family: Poppins;
    text-transform: capitalize;
    margin: 0;
    border-radius: 5px;
    border: 2px solid #ff5800;
    font-weight: 600;
    padding: 10px;
    width: 250px;
    font-size: 17px;
    font-weight: 600;
    padding: 17px;
    color: #ff5800;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: .3s all ease-in;
    transition: .3s all ease-in;
    &:hover{
      text-decoration: none;
      background: #ff5800;
      color: #fff;
    }
  }
  .o-testimonial__item{
    margin: 0;
    background: transparent;
    box-shadow: none;
  }
  .o-testimonial__itemimage{
    height: 200px !important;
  }
  .o-testimonial__itemtitle{
    font-size: 18px !important;
    line-height: 26px !important;
  }
  .splide__arrows{
    display: flex !important;
    justify-content: flex-end;
    position: absolute;
    bottom: -80px;
    width: 100%;
  }
  .splide__arrow{
    position: static !important;
    width: 50px;
    height: 50px;
    background: 0 0;
    border: 1px solid #3cc065;
    transform: none;
    &--prev{
      margin-right: 10px;
    }
    svg{
      fill: #3cc065;
    }
  }
}
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 9999;
}

.preloader.hidden {
  display: none;
}

.mwc-banner-digital{
  @include media-query(992px) {
    padding: 45px 0 50px!important;
  }
  .mwc-banner__left{
    p{
      @include media-query(992px) {
        padding-right: 50px;
      }
      &.long-text{
        @include media-query(992px) {
          padding-right: 40px;
        }
      }
    }
  }
}

.o-skills-hero {
    position: relative;
    overflow: hidden;
    padding: 100px 0 60px;
    @include responsive(desktop){
        padding: 115px 20px 45px!important;
    }

    h1{
        color: $white-color;
        // text-transform: capitalize;
        font-size: 32px;
        @include responsive(desktop){
            // font-size: 54px;
            font-size: 48px;
        }
    }
    h2{
        font-size: 24px;
        line-height: 30px;
        color: $white-color;
        padding: 0px 0 20px;
        margin-top: 20px !important;
        @include responsive(desktop){
            padding: 10px 0 20px;
            font-size: 28px;
            line-height: 38px;
            margin-top: 0 !important;
        }
        span {
            display: block;
            @include responsive(desktop){
                display: inline;
            }
        }
    }

    &__schdule{
        h1{
            font-size: 30px;
            text-transform: inherit!important;
            @include responsive(desktop){
                // font-size: 50px;
                font-size: 47px;
                line-height: 60px;

            }
        }
        h2{
            font-size: 20px;
            font-weight: 500!important;
            @include responsive(desktop){
                font-size: 25px;
                line-height: 35px;
            }
        }
        .header_right_pan{
            h2{
                font-size: 18px;
                @include responsive(desktop){
                    font-size: 24px!important;
                    line-height: 35px;
                }
            }
            h3{
                font-size: 18px;
                @include responsive(desktop){
                    font-size: 21px!important;
                    line-height: 35px;
                    padding-bottom: 0;
                    margin-top: 20px;
                }
            }
            .or{
                background-color: #091B38;
                width: 30px;
                height: 30px;
                font-size: 11px;
                line-height: 30px;
                color: #fff;
                border-radius: 50%;
                margin:10px auto 0;
            }
        }
    }

    p{
        color: $light-green;
        font-family: $main-font;
    }

}

.header_left_pan{
    text-align: center;
    @include responsive(desktop){
        text-align: left;
    }
    img{
        // padding:40px 0 20px;
    }
    .award-logo{
        margin: 0 auto;
        padding: 0 0 20px 0;
    }
    p{
        display: none;
        @include responsive(desktop){
            font-size: 20px;
            line-height: 33px;
            display: block;
            font-weight: 600;
        }
    }
    ul{
        display: none;
        @include responsive(desktop){
            display: block;
        }
        li{
            font-size: 17px;
            padding-left: 30px;
            padding-bottom: 10px;
            position: relative;
            color: $white-color;
            font-weight: 500;
            &:before{
                content: '';
                background: url(/images/common/tick.png) no-repeat 0 0;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 0;
                background-size: 100%;
                @include responsive(desktop){
                    top: 4px;
                }
            }
        }
    }
}


.new-award{
    div{
        display: inline-block;
        margin: 0 20px 30px;
    }
}
.schedule-box{
    // border: 5px solid $dark-blue-color;
    padding:40px 14px 15px;
    position: relative;
    @include responsive(desktop){
        padding: 40px 30px 15px 30px;
    }
    p{
        color: $white-color;
    }
    h3{
        font-size: 20px;
        padding-bottom: 10px;
    }
    :before{
        content: "";
        position: absolute;
        left:50%;
        top: 0;
        background: url(/images/common/skills-lets-talk.png) no-repeat 0 0;
    }
}
.sub-text{
    font-size: 19px;
    border-bottom: 2px dotted $green-color;
    padding: 20px 0;
}
.WordPress,
.Swift,
.PHP,
.Laravel,
.CodeIgniter,
.Symfony,
.Python,
.Django,
.NodeJS,
.dotnet,
.Angular,
.ReactJs,
.VueJS,
.MEAN,
.MERN,
.Magento,
.WooCommerce,
.Shopify,
.Drupal,
.iOS,
.Android,
.React-Native,
.React,
.hybrid,
.Flutter,
.Ionic,
.Flask,
.front-end-development,
.Backend-Development {
    .o-skills-hero {
        padding-bottom: 20px;
        padding-top: 100px;
        @include responsive(desktop){
            padding: 65px 20px 0!important;
        }
        h1 {
            @include responsive(desktop){
                margin-bottom: 15px !important;
            }
        }
        h2 {
            font-size: 24px;
            line-height: 30px;
            margin-top: 15px;
            font-weight: 600;
            @include media-query(992px) {
                margin-top: 0;
                // font-weight: 700;
                font-size: 20px;
                line-height: 32px;
            }
            @include media-query(1200px) {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .header_left_pan {
            @include responsive(desktop){
                padding-right: 20px;
            }
            img {
                // padding: 0 0 20px;
                // margin-bottom: 10px;
                padding: 0 0 20px;
                margin-bottom: 10px;
                @include responsive(desktop){
                    padding: 40px 0 20px;
                    margin-bottom: 0;
                }

            }
            h2 {
                @include responsive(desktop){
                    font-size: 24px;
                    line-height: 35px;
                    font-weight: 600 !important;
                }
            }
            .skill-page-mod {
                img {
                    padding: 0;
                    margin: 0;
                    @include media-query(992px) {
                        padding: 20px 0 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .green-head-ribbon {
            margin-top: 40px;
            margin-bottom: 10px;
        }
    }
    .skill-service {
        // background: #fff !important;
        // border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
        @include responsive(desktop){
            // background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%) !important;
            border-top: none;
            // border-bottom: none;
        }
        // .anchor-link {
        //     color: #4a90e2!important;
        //     @include responsive(desktop){
        //         color: #fff !important;
        //     }
        // }
        h2{
            color: $black-color;
        }
        h3{
            color: $black-color;
        }
    }
    .services-pan {
        // padding: 50px 15px;
        // @include responsive(desktop){
        //     padding: 50px 30px 50px;
        // }
        i {
            display: block;
            height: 60px;
            img {
                height: 100%;
            }
        }
        h3 {
            font-size: 20px;
            @include responsive(desktop){
                font-size: 24px;
            }
        }
    }
    .skill-why {
        ul.slick-dots {
            margin-top: 0;
        }
    }
    .skill-frame {
        h2 {
            font-size: 24px;
            @include responsive(desktop){
                font-size: 30px;
            }
        }
    }
    .custom-software-development {
        h2 {
            font-size: 30px;
            // font-weight: 700;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
        .cmn-heading-para {
            margin-bottom: 0;
            @include responsive(desktop){
                margin-bottom: 20px
            }
        }
    }
    ul.skill-page-mod li:nth-child(3){
        flex: 1
    }
}
.WordPress {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
// .Digital {
//     .o-skills-hero .header_left_pan img {
//         @include media-query(992px){
//             padding: 40px 0 20px;
//         }
//     }
// }

// .PPC {
//     .o-skills-hero .header_left_pan img {
//         @include media-query(992px){
//             padding: 40px 0 20px;
//         }
//     }
// }
.Native {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.iOS {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
    .mean-intro {
        min-height: auto !important;
        @include responsive(desktop){
            min-height: 606px!important;
        }
    }
    .o-skills-benefits {
        background-color: #fff !important;
        // @include responsive(desktop){
        //     background-color: #f5f5f5!important;
        // }
    }
}
.Android {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.MEAN, .MERN {
    .skill-what {
        &:after {
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
        h2 {
            font-size: 24px;
            line-height: 32px;
            // font-weight: 700 !important;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
    .skill-service {
        background: #f5f5f5 !important;
        @include responsive(desktop){
            background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%) !important;
        }
        h2,
        h3 {
            color: #333 !important;
            @include responsive(desktop){
                color: #fff !important;
            }
        }
    }
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.mean-stack {
    .skill-what {
        &:after {
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
        h2 {
            font-size: 24px;
            // font-weight: 700 !important;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.MERN {
    .o-skills-benefits {
        background-color: #fff !important;
        @include responsive(desktop){
            background-color: #f5f5f5!important;
        }
    }
}
.Magento {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
    .skill-service {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.WooCommerce {
    .skill-why {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
    .skill-service {
        h2 {
            font-size: 24px;
            line-height: 32px;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
            }
        }
    }
}
.PHP,
.NodeJS,
.Laravel,
.React-Native,
.React,
.symphony,
.ReactJS,
.WordPress,
.WooCommerce,
.dotnet,
.Django,
.Angular,
.VueJS,
.Python,
.hybrid,
.front-end-development,
.Backend-Development,
.CodeIgniter,
.Yii,
.Web-Application-Development,
.Blockchain {
    .services-pan {
        padding: 50px 15px 10px;
        @include responsive(desktop){
            padding: 50px 30px 50px;
        }
    }
}
.Symfony, .iOS, .Android, .Magento, .Ionic, .MERN, .mean-stack, .Drupal, .Flutter, .Java, .Shopify, .Swift, .Flask {
    .services-pan {
        padding: 50px 15px 10px;
        @include responsive(desktop){
            // padding: 120px 30px 50px;
            padding: 50px 15px 50px;
        }
    }
}

.CakePHP, .Joomla, .ROR, .Zend, .Dedicated {
    .services-pan {
        padding: 50px 15px 10px;
        @include responsive(desktop){
            padding: 50px 30px 50px;
        }
    }
}

.Java {
    .services-pan {
        padding: 50px 15px 50px;
        @include responsive(desktop){
            padding: 50px 30px 50px;
        }
    }
}

.Flask {
    .o-skills-hero {
        @include responsive(desktop){
            padding: 65px 20px 0!important;
        }
    }
    .schedule-box {
        margin-top: 0;
    }
}
.Magento {
    .o-skills-hero {
        @include responsive(desktop){
            padding: 65px 20px 0!important;
        }
        h1 {
            margin-bottom: 35px;
            @include responsive(desktop){
                margin-bottom: 0;
            }
        }
    }
    .skill-service {
        @include responsive(desktop){
            padding: 75px 0 50px;
        }
        h2 {
            line-height: 30px;
            margin-bottom: 5px;
            @include responsive(desktop){
                line-height: 40px;
                margin-bottom: .5rem;
            }
        }
    }
    .skill-why {
        @include responsive(desktop){
            padding: 75px 0 50px;
        }
    }
    .o-engagement-model {
        @include responsive(desktop){
            padding: 65px 0 40px;
        }
    }
    .web-app-service {
        h2 {
            // font-weight: 700;
        }
    }
    .schedule-box {
        margin-top: 0;
        height: 100%;
    }
}
.magento-section-1 {
    padding: 40px 0 20px !important;
    @include responsive(desktop){
        padding: 70px 0 50px !important;
    }
    h2 {
        // font-weight: 700;
    }
    .cmn-heading-para {
        margin-bottom: 0;
        @include responsive(desktop){
            margin-bottom: 20px;
        }
    }
}
.o-engagement-model {
    p {
        margin-bottom: 0;
        padding-bottom: 0;
        @include responsive(desktop){
            margin-bottom: 20px;
            padding-bottom: 15px;
        }
    }
}

.schdule_h_img{
    vertical-align: top;
    margin-right: 15px;
}
.schdule_h_text{
    width: 96%;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    font-weight: 500;
}


.php-bnr {
    background: #000;
}

// .ReactJS {
//     .o-skills-hero {
//         padding: 115px 20px 50px!important;
//         @include media-query(992px) {
//             padding: 115px 20px 0!important;
//         }
//     }
// }

.PHP, .WordPress, .eCommerce, .Mobile, .ROR {
    .schedule-box {
        margin-top: 0;
    }
}
.Mobile {
    .schedule-box {
        height: 100%;
    }
}

// .WordPress {
//     .o-skills-hero {
//         padding-top: 150px !important;
//         @include media-query(992px) {
//             padding-top: 65px !important;
//         }
//     }
// }

 .Mobile {
    .o-skills-hero {
        @include media-query(992px) {
            padding: 65px 20px 0!important;
        }
    }
    .header_left_pan {
        @include media-query(992px) {
            padding-top: 50px;
        }
    }
}

.Dedicated, .Web-Application-Development, .Custom-Software-Development, .eCommerce, .Mobile, .cmn-logo-fix {
    ul.skill-page-mod li:nth-child(3){
        flex: 1
    }
}

.blue-heading {
    background: #08426f;
    padding: 10px;
    background-color: #08426f;
    color: #fff;
    margin-top: 0;
    text-align: center;
    font-size: 12px;
    @include media-query(992px) {
        font-size: 17px;
    }
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    z-index: 9999999;
    width: 100%;
    margin-bottom: 0;
}

// .React, .CakePHP{
//     .o-skills-hero {
//         @include media-query(992px) {
//             padding: 115px 20px 0!important;
//         }
//     }
// }

.Joomla {
    .cmn-service-bnr-mod {
        @include media-query(992px) {
            padding: 65px 20px 0!important;
        }
    }
    .header_left_pan {
        @include media-query(992px) {
            padding-top: 50px;
        }
    }
}

.ROR {
    .o-skills-hero {
        @include media-query(992px) {
            padding: 65px 20px 0!important;
        }
    }
    .header_left_pan {
        @include media-query(992px) {
            padding-top: 50px;
        }
    }
}



.Magento .skill-service {
    .black-text {
        color: #333 !important;
    }
}

.CMS {
    .schedule-box {
        margin-top: 0;
    }
}

// .Blockchain {
//     .header_left_pan {
//         @include media-query(992px) {
//             padding-right: 20px;
//         }
//         h1 {
//             margin-top: 20px;

//         }
//         img {
//             @include media-query(992px) {
//                 margin-top: 25px;
//             }
//         }
//     }
//     .o-skills-hero {
//         @include media-query(992px) {
//             padding: 65px 20px 0!important;
//         }
//     }
//     .schedule-box {
//         margin-top: 0;
//         height: 100%;
//     }

// }
.schdule-min-height{
    height: 100%;
}

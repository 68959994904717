.sitemap-body {
    padding: 70px 0 20px;
    @include media-query(992px) {
        padding: 90px 0 60px;
    }
    h2 {
        font-size: 24px;
        line-height: 40px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 20px;
        @include media-query(992px) {
            margin-bottom: 30px;
            font-size: 30px;
        }
    }
    h3 {
        // margin-top: 30px;
        position: relative;
        padding-left: 35px;
        font-size: 20px;
        @include media-query(992px) {
            font-size: 24px;
        }
        &::before {
            content: '';
            background-image: url(/images/common/green-arrow.png);
            width: 25px;
            height: 25px;
            position: absolute;
            left: 0;
            top: 0;
            background-size: 100%;
            @include media-query(992px) {
                top: 1px;
            }
        }
    }
    h1 {
        font-size: 24px;
        @include media-query(992px) {
            font-size: 30px;
        }
    }
    h1, h2, h3 {
        font-weight: 600;
        a {
            color: #2dbe70;
            transition: .3s all ease-in-out;
            &:hover {
                color: #ff5800;
            }
        }
        &.industry-mb-mod {
            @include media-query(992px) {
                margin-bottom: 15px;
            }
        }
    }
}
.sitemap-page {
    vertical-align: top;
    &.sitemap-page-3 {
        @include media-query(992px) {
            width: 33%;
        }
    }
    &.sitemap-page-2 {
        @include media-query(992px) {
            width: 49.5%;
        }
    }
    li {
        position: relative;
        padding: 6px 0 6px 20px;
        @include media-query(992px) {
            padding: 10px 0 10px 30px;
        }
        a {
            font-size: 16px;
            transition: .3s all ease-in-out;
            @include media-query(992px) {
                font-size: 18px;
            }
            &:hover {
                color: #2dbe70;
            }
        }
        &::before {
            border: 4px solid #ecebf9;
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            background: #4842a8;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            @include media-query(992px) {
                top: 15px;
            }
        }
    }
}
.sitemap-page-tech {
    display: flex;
    flex-wrap: wrap;
    li {
        flex: 0 0 33%;
    }
}
.sitemap-page-tech2 {
    display: flex;
    flex-wrap: wrap;
    li {
        flex: 0 0 50%;
    }
}
.sitemap-wrap {
    margin-bottom: 20px;
    padding-left: 15px;
    &.pl-0 {
        padding-left: 0;
    }
    @include media-query(992px) {
        margin-bottom: 40px;
        padding-left: 25px;
    }
}
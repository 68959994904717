.how-stuff-augmentation {
    background: #F5F6F8;
    border-bottom: 1px solid #D6E3F0;
    .our-approach__heading {
        font-weight: 700;
        font-family: "Poppins";
        @include media-query(992px) {
            font-size: 45px;    
        }
    }
    .o-development-process__items {
        border: none;
        box-shadow: none;
        height: auto;
        background: none;
        text-align: center;
        margin-top: 20px;
        @include media-query(992px) {
            text-align: left;
            padding: 0 35px 0 60px;
            margin-top: 50px;
        }
        &::after {
            content: '';
            background: url(/images/staff-augmentation/how-it-works-v-line.svg) no-repeat center;
            width: 4px;
            height: 148px;
            z-index: 9;
            right: inherit;
            left: 0;
            top: 37px;
            bottom: inherit;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        &:nth-child(1) {
            @include media-query(992px) {
                padding: 0 35px 0 50px;
            }
        }
        &:nth-child(2) {
            @include media-query(992px) {
                padding: 0 35px 0 60px;
            }
        }
        &:nth-child(3) {
            @include media-query(992px) {
                padding: 0 15px 0 60px;
            }
            &:after {
                transform: rotate(0);
                @include media-query(992px) {
                    top: -40px;
                }
            }
        }
        &:nth-child(4){
            @include media-query(992px) {
                padding: 0 0 0 60px;
            }
            &::after {
                @include media-query(992px) {
                    content: '';
                    position: absolute;
                    top: 16px;
                    left: -37px;
                    background: url(/images/staff-augmentation/how-it-works-thunder.svg) no-repeat center;
                    width: 92px;
                    height: 179px;
                    display: block;
                }
            }
        }
        .o-development-process__number {
            background: #FFFFFF;
            margin: 0 auto 15px;
            @include media-query(992px) {
                position: absolute;
                top: -21px;
                left: -35px;
                z-index: 99;
                margin: 0;
            }
            strong {
                background: #0A9DEF;
            }
        }
        .o-development-process__titlearea {
            justify-content: center;
            @include media-query(992px) {
                justify-content: flex-start;
            }
        }
    }
    .btn {
        margin-top: 25px;
        height: 62px;
        padding: 16px 20px;
        @include media-query(992px) {
            max-width: 340px;
            margin-top: 60px;
        }
    }
}

.more-reason {
    $more-reason: &;
    background: #F5F6F8;
    padding: 30px 0;
    border-bottom: 1px solid #d6e3f0;
    @include media-query(768px) {
        padding: 60px 0;
    }
    h2 {
        font-family: "Poppins";
        margin-bottom: 25px;
        text-align: center;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
    }
    &__wrapper {
        position: relative;
        @include media-query(768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__item {
        flex: 0 0 33%;
        position: relative;
        border-bottom: 1px solid #E1E1E1;
        text-align: center;
        @include media-query(768px) {
            border: none;
            text-align: left;
        }
        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            background: #E1E1E1;
            width: 1px;
            height: 100%;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            border-bottom: 1px solid #E1E1E1;
        }
        &:nth-child(1) {
            padding: 15px 0;
            @include media-query(768px) {
                padding: 0 60px 30px 0;
            }
            &:after {
                content: '';
                position: absolute;
                right: -15px;
                bottom: -26px;
                background: #F5F6F8;
                width: 30px;
                height: 50px;
                display: none;
                z-index: 1;
                @include media-query(992px) {
                    display: block;
                }
            }
        }
        &:nth-child(2) {
            padding: 15px 0;
            @include media-query(768px) {
                padding: 0 60px 30px 60px;
            }
            &:after {
                content: '';
                position: absolute;
                right: -15px;
                bottom: -26px;
                background: #F5F6F8;
                width: 30px;
                height: 50px;
                display: none;
                z-index: 1;
                @include media-query(992px) {
                    display: block;
                }
            }
        }
        &:nth-child(3) {
            padding: 15px 0;
            @include media-query(768px) {
                padding: 0 60px 30px 60px;
            }
        }
        &:nth-child(4) {
            padding: 15px 0;
            @include media-query(768px) {
                padding: 30px 60px 0 0;
            }
        }
        &:nth-child(5) {
            padding: 15px 0;
            @include media-query(768px) {
                padding: 30px 60px 0 60px;
            }
        }
        &:nth-child(6) {
            border: none;
            padding: 15px 0;
            @include media-query(768px) {
                padding: 30px 60px 0 60px;
            }
        }
        &:nth-child(3),
        &:nth-child(6) {
            &::before {
                display: none;
            }
        }
    }
    &__icon {
        display: flex;
        margin-bottom: 10px;
        height: 60px;
        justify-content: center;
        @include media-query(768px) {
            justify-content: flex-start;
        }
    }
    &__card-title {
        font-size: 24px;
        line-height: 27px;
        font-weight: 700;
        color: #2C2C2C;
        font-family: "Poppins";
    }
    &__card-para {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #2C2C2C;
        font-family: "Poppins";
        margin-bottom: 0;
    }
    .btn {
        margin-top: 25px;
        height: 62px;
        padding: 16px 20px;
        @include media-query(992px) {
            max-width: 340px;
            margin-top: 60px;
        }
    }
}

.industry-empower {
    $industry-empower: &;
    padding: 30px 0;
    border-bottom: 1px solid #D6E3F0;
    @include media-query(768px) {
        padding: 60px 0;
    }
    h2 {
        font-family: "Poppins";
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 40px;
            font-size: 45px;
            line-height: 50px;
        }
    }
    .col-lg-4 {
        margin-bottom: 26px;
    }
    &__box {
        text-align: center;
        border: 1px solid #D6E3F0;
        border-radius: 10px;
        padding: 15px 15px 30px 15px;
    }
    &__image {
        border-radius: 10px;
    }
    &__image1 {
        background: #EFF4F8;
    }
    &__image2 {
        background: #F6EEE6;
    }
    &__image3 {
        background: #D7EDF9;
    }
    &__image4 {
        background: #E6E7F9;
    }
    &__image5 {
        background: #EEF4EC;
    }
    &__image6 {
        background: #FEF1EF;
    }
    h3 {
        font-family: "Poppins";
        margin-top: 25px;
        margin-bottom: 0;
        color: #333333;
        font-size: 24px;
        font-weight: 600;
    }
    .btn {
        padding: 16px 20px;
        @include media-query(992px) {
            max-width: 340px;
            margin-top: 20px;
        }
    }
    &__sub-heading {
        font-family: Poppins;
        text-align: center;
        font-weight: 500;
        @media(min-width:992px){
            font-size: 19px;
            line-height: 32px;
            margin: 0 auto 60px;
        }
    }
    &--it {
        h2 {
            margin-bottom: 20px;
        }
        .industry-empower__box {
            background: #fff;
        }
    }
}

.dev-center {
    $dev-center: &;
    padding: 30px 0;
    border-bottom: 1px solid #D6E3F0;
    @include media-query(768px) {
        padding: 60px 0 10px;
    }
    h2 {
        font-family: "Poppins";
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
    }
    .dev-center__icon {
        overflow: hidden;
        border-radius: 10px;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &__wrapper-gap {
        margin-bottom: 25px;
    }
}

.how-help-you--staff-aug {
    background: url(/images/staff-augmentation/engagement-bg.jpg) no-repeat center / cover;
    .how-help-you__card-heading {
        font-size: 24px;
        line-height: 29px;
        font-weight: 700;
        color: #333333;
    }
    .how-help-you__card-sub-heading {
        font-size: 18px;
        line-height: 25px;
        @include media-query(992px) {
            padding: 0 14px;
        }

    }
    .how-help-you__card-items {
        display: flex;
        justify-content: flex-start;
        @include media-query(992px) {
            padding: 40px 25px 40px;
        }
    }
    .how-help-you__icon {
        margin: 0 auto;
        @include media-query(992px) {
            margin: 0 auto 35px;
        }
        display: block;
        height: 70px;
    }
    .how-help-you__links {
        margin-top: auto;
    }
    .how-help-you__heading {
        @include media-query(992px) {
            font-size: 45px;
            line-height: 50px;
        }
    }
}


.stuff-augmentation-technologies--new {
    border-bottom: 1px solid #D6E3F0;
    padding-bottom: 30px;
    @include media-query(992px) {
        padding-bottom: 0;
    }
    .technologies-tab__heading {
        font-weight: 700;
        @include media-query(992px) {
            font-size: 45px;
            line-height: 55px;
        }
    }
    .o-accordion__content-background {
        background: #EFF4F8;
    }
    .o-accordion__content {
        background: #EFF4F8;
    }
    .o-accordion__button {
        font-weight: 700;
    }
    .accordion-button {
        &::after {
            background: url(/images/staff-augmentation/collapse.png) no-repeat center;
        }
        &:not(.collapsed) {
            &::after {
                transform: rotate(90deg);
            }
        }
    }
    .o-accordion__nav {
        @include media-query(992px) {
            padding-left: 0;
        }
    }
    .o-accordion__tab {
        @include media-query(992px) {
            padding-left: 0;
        }
    }
    .pool-box-list {
        box-shadow: 5px 0px 10px 2px rgba(0,0,0,0.1);
        a {
            @include media-query(992px) {
                padding: 14px 20px;
            }
        }
        small {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .o-accordion__content-title {
        @include media-query(992px) {
            font-size: 36px;
            line-height: 42px;
        }
        font-weight: 700;
    }
}

.skill-review-staff-aug {
    padding-bottom: 90px;
    @include media-query(992px) {
        padding: 0 0 110px;
    }
    h2 {
        text-align: center;
        font-family: "Poppins";
        @include media-query(992px) {
            font-size: 45px;
            font-weight: 700;
            line-height: 56px;
            margin-top: 25px;
        }
    }
    .b2b-text {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #355BBF;
        display: block;
        text-align: center;
        margin-top: 25px;
    }
    .custom-review__reviewitems {
        max-width: inherit;
        height: 100%;
        border: 1px solid #D0E3F5;
        padding: 40px 15px 40px 40px;
    }
    .custom-review__quotecontent {
        font-size: 24px;
        line-height: 34px;
        font-weight: 500;
    }
    
    .custom-review__authordesignation {
        display: inline-block;
    }
    .custom-review__authorimage {
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
        picture {
            width: 50px;
            height: 50px;
            object-fit: cover;
            display: inline-block;
            img,
            source {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .custom-review__quoteimg {
        margin-bottom: 30px;
    }
    .custom-review {
        .splide__arrows {
            display: flex;
            justify-content: center;
            left: 0;
            right: 0;
            bottom: -110px;
        }
        .splide__arrow {
            margin: 0 5px;
            width: 50px;
            height: 50px;
            opacity: 1;
            border: 1px solid #AEC6DC;
            background-color: transparent;
            svg {
                fill: #AEC6DC;
                font-size: 40px;
                font-weight: 500;
            }
            &:hover {
                background: #0D65CA;
                svg {
                    fill: #fff;
                }
            }
            &:disabled {
                opacity: 1;
                border: 1px solid #AEC6DC;
                background-color: transparent;
                svg {
                    fill: #AEC6DC;
                }
                &:hover {
                    background: #0D65CA;
                    svg {
                        fill: #fff;
                    }
                }
            }
        }
        .splide__arrow--prev {
            position: static;
        }
        .splide__arrow--next {
            position: static;
        }
    }
    .recognize-reviews {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 25px;
        @include media-query(992px) {
            justify-content: center;
            margin-bottom: 50px;
        }
        li {
            @include media-query(992px) {
                margin: 0 30px;
            }
            img {
                width: 70px;
                @include media-query(768px) {
                    width: auto;
                }
            }
        }
    }
    .splide__slide {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 40px;
            background: #ccc;
            width: 38px;
            height: 4px;
        }
        &:nth-child(1) {
            &:before {
                background: #20DEA5;
            }
        }
        &:nth-child(2) {
            &:before {
                background: #FFC327;
            }
        }
        &:nth-child(3) {
            &:before {
                background: #A66BD4;
            }
        }
        &:nth-child(4) {
            &:before {
                background: #4a5806;
            }
        }
        &:nth-child(5) {
            &:before {
                background: #EF8D1A;
            }
        }
        &:nth-child(6) {
            &:before {
                background: #0A9DEF;
            }
        }
        &:nth-child(7) {
            &:before {
                background: #f280df;
            }
        }
        &:nth-child(8) {
            &:before {
                background: #ff9d09;
            }
        }
    }
}

.o-development-process--it-staff-aug {
    .o-skill-flow__title {
        font-weight: 700 !important;
        font-size: 28px !important;
        line-height: 40px !important;
        @include media-query(992px) {
            font-size: 45px !important;
            line-height: 60px !important;
        }
    }
    .o-development-process__list {
        .o-development-process__items {
            @include media-query(992px) {
                width: 46%;
                margin-top: 60px;
            }
            &:nth-child(3) {
                @include media-query(992px) {
                    margin-left: 0;
                    margin-right: 30px;
                    margin-top: 60px;
                }
                &:after {
                    display: none;
                }
            }
            &:nth-child(2) {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }
            &:nth-child(4) {
                margin-right: 0;
                @include media-query(992px) {
                    margin-left: 30px;
                }
                &:after {
                    display: none;
                }
            }
            &:after {
                display: none;
            }
        }
    }
}

.o-core-competencies--it-staff-aug {
    .o-core-competencies__box {
        @include media-query(992px) {
            padding: 30px 30px 0;
            min-height: 230px;
        }
    }
    .o-core-competencies__box {
        text-align: left;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            background: #000;
            width: 38px;
            height: 4px;
            top: -2px;
            left: 30px;
        }
        &--color1 {
            &:before {
                background: #0a9def;
            }
        }
        &--color2 {
            &:before {
                background: #ffbd00;
            }
        }
        &--color3 {
            &:before {
                background: #20dea5;
            }
        }
        &--color4 {
            &:before {
                background: #20dea5;
            }
        }
        &--color5 {
            &:before {
                background: #0a9def;
            }
        }
        &--color6 {
            &:before {
                background: #ef8d1a;
            }
        }
        &--color7 {
            &:before {
                background: #fe2f05;
            }
        }
        &--color8 {
            &:before {
                background: #ee16c4;
            }
        }
        &--color9 {
            &:before {
                background: #2aa7b3;
            }
        }
    }
}

.skill-review-staff-aug--it {
    h2 {
        margin-top: 0;
    }
}

.custom-dev-case-study--staff-aug-it {
    .o-testimonial__heading {
        font-weight: 700;
        @include media-query(992px) {
            font-size: 45px;
            line-height: 60px;
            margin-bottom: 15px;
        }
    }
    .o-testimonial__sub-heading {
        font-family: Poppins;
        text-align: center;
        font-weight: 500;
        @media (min-width: 992px) {
            font-size: 19px;
            line-height: 32px;
            margin: 0 auto 60px;
            margin-bottom: 45px;
        }
    }
}

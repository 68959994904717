.o-team{

    .team-holder{
        -webkit-box-shadow: 0px 0px 20px 0px rgba(128,126,128,0.3);
        -moz-box-shadow: 0px 0px 20px 0px rgba(128,126,128,0.3);
        box-shadow: 0px 0px 20px 0px rgba(128,126,128,0.3);
        padding: 30px 30px 10px;
        min-height: 100%;
        border-radius: 5px;
        margin-bottom: 20px;
        @include responsive(desktop){
            min-height: 710px;
        }
        h5 {
            font-size: 24px;
            font-weight: 600;

        }
        h3 {
            font-size: 24px;
            font-weight: 600;
            color: $dark-green-color;
        }
        p.department {
            font-size: 16px;
            padding: 5px 0;
            color: $black-color;
            font-weight: 600;
        }
        p {
            font-size: 19px;
            line-height: 32px;

        }


        &__team-1{
            background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/dimpi.png') no-repeat 50% 100% $white-color;
            }
        }
        &__team-2{
            background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/sabir.png') no-repeat 50% 100% $white-color;
            }
        }
        &__team-3{
            background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/tanuja.png') no-repeat 50% 100% $white-color;
            }
        }
        &__team-4{
            background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/gourab.png') no-repeat 50% 100% $white-color;
            }
        }
        &__team-5{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-1.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-6{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-2.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-7{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-3.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-8{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-4.jpg') no-repeat 50% 100% $white-color;
            }
        }

        &__team-9{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-5.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-10{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-6.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-11{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-7.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-12{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-8.jpg') no-repeat 50% 100% $white-color;
            }
        }

        &__team-13{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-9.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-14{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-10.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-15{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-11.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-16{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-12.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-17{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-13.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-18{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-14.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-19{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-15.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-20{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-16.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-21{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-17.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-22{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-18.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-23{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-19.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-24{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-20.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-25{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-21.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-26{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-22.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-27{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-23.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-28{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-24.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-29{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-25.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-30{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-26.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-31{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-27.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-32{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-28.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-33{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-29.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-34{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-30.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-35{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-31.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-36{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-32.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-37{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-33.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-38{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-34.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-39{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-35.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-40{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-36.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-41{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-37.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-42{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-38.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-43{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-39.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-44{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-40.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-45{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-45.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-46{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-46.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-47{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-47.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-48{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-48.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-49{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-49.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-50{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-50.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-51{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-51.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-52{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-52.jpg') no-repeat 50% 100% $white-color;
            }
        }

        &__team-53{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-53.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-54{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-54.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-55{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-55.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-56{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-56.jpg') no-repeat 50% 100% $white-color;
            }
        }

        &__team-57{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-57.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-58{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-58.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-59{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-59.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-60{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-60.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-61{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-61.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-62{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-62.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-63{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-63.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-64{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-64.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-65{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-65.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-66{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-66.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-67{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-67.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-68{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-68.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-69{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-69.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-70{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-70.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-71{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-71.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-72{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-72.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-73{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-73.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-74{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-74.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-75{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-75.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-76{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-76.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-77{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-77.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-78{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-78.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-79{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-79.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-80{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-80.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-81{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-81.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-82{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-82.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-83{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-83.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-84{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-84.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-85{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-85.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-86{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-86.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-87{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-87.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-88{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-88.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-89{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-89.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-90{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-90.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-91{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-91.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-92{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-92.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-93{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-93.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-94{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-94.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-95{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-95.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-96{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-96.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-97{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-97.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-98{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-98.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-99{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-99.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-100{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-100.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-101{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-101.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-102{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-102.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-103{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-103.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-104{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-104.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-105{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-105.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-106{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-106.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-107{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-107.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-108{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-108.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-109{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-109.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-110{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-110.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-111{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-111.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-112{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-112.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-113{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-113.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-114{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-114.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-115{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-115.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-116{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-116.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-117{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-117.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-118{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-118.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-119{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-119.jpg') no-repeat 50% 100% $white-color;
            }
        }
        &__team-120{
           background-color: $white-color;
            @include responsive(desktop){
                background: url('/images/common/team-120.jpg') no-repeat 50% 100% $white-color;
            }
        }


    }
}

// BANNER STYLE START
.banner--staff-aug {
  position: relative;
  text-align: center;
  background-color: #081c36;
  background-image: none;
  @media (min-width: 992px) {
    background-image: url(/images/staff-augmentation/staff-banner-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }
  @media (min-width: 1024px) {
    background-size: 100% 100%;
  }
  .banner__top-rated {
    display: inline-flex;
    background: transparent;
    border-color: #a3a8b0;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    @media (min-width: 992px) {
      flex-wrap: nowrap;
    }
  }
  .banner__top-rating {
    background: #fff;
    height: 68px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .banner__top-rating-number {
    color: #515050;
    font-family: "Poppins";
  }
  .banner__top-rated-text {
    font-family: "Poppins";
  }
  .banner__top-rating-review {
    color: #515050;
    font-family: "Poppins";
  }
  .banner-technologies__heading {
    position: relative;
    font-size: 48px;
    margin: 25px 0;
    @media (min-width: 1024px) {
      font-size: 105px;
      font-weight: 700;
      margin: 95px 0 45px;
    }
    &:before {
      display: none;
      position: absolute;
      content: "";
      width: 410px;
      height: 693px;
      margin: auto;
      top: -304px;
      left: 0;
      right: 0;
      background: url(/images/staff-augmentation/staff-aug-text-background.png)
        no-repeat center;
      background-size: auto 100%;
      @media (min-width: 992px) {
        display: block;
      }
    }
    span {
      position: relative;
      z-index: 1;
    }
  }
  .banner-technologies__sub-heading {
    color: #fff;
    margin: 0 0 30px;
    font-family: "Poppins";
    @media (min-width: 1024px) {
      font-size: 45px;
      line-height: 56px;
      font-weight: 700;
      margin: 0 0 45px;
    }
  }
  .new-home-banner__list {
    max-width: 950px;
    width: 100%;
    margin: auto;
  }
  .how-stuff-augmentation__btn {
    max-width: 312px;
    margin: 25px auto 0;
    padding: 17px 22px;
    @media (min-width: 1024px) {
      margin: 40px auto 0;
    }
  }
}
// BANNER STYLE ENDS

// WHY TOP STYLE START
.why-choose--staff-aug {
  background-color: #fff;
  .why-choose__heading {
    font-weight: 700;
    @media (min-width: 1024px) {
      font-size: 45px;
      line-height: 60px;
      max-width: 950px;
      margin: 0 auto 60px;
    }
  }
  .why-choose__item {
    border: 1px solid #dde2e9;
    border-radius: 5px;
    @media (min-width: 992px) {
      flex: 0 0 32%;
      height: 200px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 38px;
      height: 4px;
      left: 20px;
      bottom: 0;
      background-color: #0a9def;
    }
    &--blue {
      &:after {
        background-color: #0a9def;
      }
    }
    &--yellow {
      &:after {
        background-color: #ffbd00;
      }
    }
    &--green {
      &:after {
        background-color: #20dea5;
      }
    }
    &--gold {
      &:after {
        background-color: #ef8d1a;
      }
    }
    &:nth-child(5) {
      .why-choose__itemscontent {
        @media (min-width: 992px) {
          padding-right: 10px;
        }
      }
    }
  }
  .why-choose__items--stuff-aug {
    @media (min-width: 992px) {
      padding: 30px 20px;
    }
  }
  .why-choose__itemscontent {
    @media (min-width: 992px) {
      padding-top: 0;
      padding-left: 20px;
    }
    @media (min-width: 1200px) {
      padding-right: 20px;
    }
  }
  .why-choose__title {
    color: #0c2848;
    font-weight: 600;
  }
  .why-choose__content {
    color: #0c2848;
    font-weight: 500;
    line-height: 25px;
  }
  .why-choose__more {
    text-align: center;
    margin: 30px 0 0;
    @media (min-width: 1024px) {
      margin: 80px 0 0;
    }
    h3 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: normal;
      color: #262e36;
      @media (min-width: 1024px) {
        font-size: 30px;
      }
    }
  }
  .why-choose__more-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    @media (min-width: 1024px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
  .why-choose__more-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    flex-direction: column;
    max-width: 200px;
    @media (min-width: 1024px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
    i {
      height: 60px;
    }
    h4 {
      font-family: Poppins;
      font-size: 24px;
      line-height: 27px;
      color: #0c2848;
      font-weight: 600;
      margin: 0 0 5px;
      text-align: left;
    }
    p {
      font-family: Poppins;
      font-size: 16px;
      line-height: 27px;
      color: #0c2848;
      font-weight: 500;
      margin: 0;
      text-align: left;
    }
  }
  .sample-pods__btn {
    max-width: 340px;
    padding: 17px 22px;
    svg {
      margin-left: 10px;
    }
  }
}

.why-choose--staff-aug-it {
  $why-choose--staff-aug-it: &;
  .why-choose__heading {
    margin: 0 auto 20px;
  }
  &__sub-heading {
    font-family: Poppins;
    text-align: center;
    font-weight: 500;
    @media (min-width: 992px) {
      font-size: 19px;
      line-height: 32px;
      margin: 0 auto 60px;
    }
  }
}

.banner--staff-aug-it {
  .banner-technologies__heading {
    font-size: 30px;
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 64px;
      margin: 0 0 25px;
    }
    &:before {
      display: none;
    }
  }
  &__sub-heading {
    color: #fff;
    font-weight: 500;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 34px;
    @media (min-width: 992px) {
      font-size: 22px;
      line-height: 36px;
    }
    .highlight-color1 {
      position: relative;
      display: inline-block;
      &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        -webkit-animation: 4s linear animate infinite;
        animation: 4s linear animate infinite;
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#03a9f4),
          color-stop(#ff5800),
          color-stop(#fbba14),
          to(#03a9f4)
        );
        background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
        background-size: 200%;
      }
    }
  }
}

.o-scale-dedicated{
    $o-scale-dedicated : &;
    background-color: #f9f9f9;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        font-family: 'Poppins';
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: #262E36;
        margin: 0 0 20px;
        text-align: center;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 30px;
            margin: 0 0 45px;
        }
        &--second{
            margin: 30px 0 20px;
            @include media-query(992px) {
                line-height: 65px;
                margin: 30px 0 20px;
            }
        }
    }
    &__box{
        width: 100%;
        height: auto;
        background-color: $white-color;
        padding: 18px 18px 22px;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
        text-align: center;
        @include media-query(992px) {
            height: 100%;
            min-height: 225px;
            padding: 29px 30px 40px 45px;
            text-align: left;
        }
    }
    &__boxtitle{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: $black-color;
        margin: 10px 0 10px;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 32px;
        }
    }
    &__boxcontent{
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 27px;
        font-weight: 500;
        color: $black-color;
        margin: 0;
    }
    .mb-30{
        margin-bottom: 30px;
    }
    &__list{
        max-width: 942px;
        margin: 20px auto 0;
    }
    &__item{
        display: flex;
        padding: 18px;
        flex-wrap: wrap;
        background-image: linear-gradient(to right, #898989 40%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 10px 1px;
        background-repeat: repeat-x;
        justify-content: center;
        text-align: center;
        @include media-query(992px) {
            padding: 34px 0 44px;
            justify-content: flex-start;
            text-align: left;
        }
        i{
            flex: 0 0 54px;
            margin-right: 18px;
            height: auto !important;
        }
    }
    i{
        display: inline-block;
        height: 52px;
    }
    &__itemcontent{
        flex:0 0 90%;

    }
    &__itemtitle{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: $black-color;
        margin: 12px 0;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 32px;
            margin:0 0 10px;
        }
    }
    &__itemsub{
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $black-color;
        margin: 0;
    }
    &__headingbottom{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: #262E36;
        text-align: center;
        margin: 40px 0 30px;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 40px;
        }
    }
    &__btn{
        margin:auto;
        text-transform: capitalize;
    }
}

.o-how-dedicated{
    $o-how-dedicated : &;
    background-color: #355BBF;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        font-family: 'Poppins';
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: $white-color;
        margin: 0 0 15px;
        text-align: center;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
        }
    }
    &__subheading{
        font-family: 'Poppins';
        font-size: 21px;
        line-height: 27px;
        text-align: center;
        color: $white-color;
        margin-bottom: 45px;
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        flex-wrap: wrap;
        flex-direction: column;
        @include media-query(992px) {
            flex-direction: row;
            margin-bottom: 50px;
        }
    }
    &__item{
        background-color: $white-color;
        border:1px solid #ddd;
        padding: 28px 15px 20px 22px;
        max-width: 254px;
        margin: 0 auto 60px;
        width: 100%;
        min-height: 220px;
        position: relative;
        @include media-query(992px) {
            width: 22%;
            margin: 0;
        }
        &:after{
            position: absolute;
            content: "";
            background:url(/images/two-for-one/dedicated-right-arrow.svg) no-repeat center;
            background-size: 100%;
            height: 10px;
            width: 35px;
            top:inherit;
            right: 0;
            left: 0;
            bottom: -35px;
            margin: auto;
            transform: rotate(90deg);
            @include media-query(992px) {
                top:50%;
                right: -35px;
                bottom: inherit;
                left: inherit;
                transform: none;
            }
        }
        &:last-child{
            margin: 0 auto;
            @include media-query(992px) {
                margin: 0;
            }
            &:after{
                display: none;
            }
        }
        i{
            display: inline-block;
            height: 52px;
        }
    }
    &__itemtitle{
        font-family: 'Poppins';
        font-size: 21px;
        line-height: 31px;
        color: $black-color;
        font-weight: 600; 
        margin:18px 0 8px;
    }
    &__itemcontent{
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 22px;
        color: $black-color;
        margin:0;
        a{
            color: #ff5800;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
                position: relative;
            }
        }
    }
    &__number{
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 600;
        color: $white-color;
        width: 58px;
        height: 58px;
        background-color: #355BBF;
        border-radius: 50%;
        border:11px solid #ebeff9;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 9px;
    }
}

.new-home-banner__btn-arrow {
    margin-left: 10px;
}
.faq-subform{
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 35px;
    color: $black-color;
}
.pool-dedicated-btn{
    margin: 15px auto 0 !important;
    @include media-query(992px) {
        margin: 50px 5px 0 !important;
    }
}
.x-page-logo {
    padding: 25px 0;
}

.pricing-page {
    padding: 50px 0;
    @include media-query(992px) {
        padding: 80px 0;
    }
    h2 {
        @include media-query(992px) {
            font-size: 56px;
        }
    }
    small {
        display: block;
        font-size: 30px;
        margin-bottom: 30px;
    }
    .p-page-box {
        padding: 50px 30px 50px;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
        background: #fff;
        width: 100%;
        text-align: center;
        h3 {
            color: #333;
            font-size: 24px;
            text-transform: capitalize;
            font-weight: 400;
            margin: 15px 0;
        }
        h4 {
            color: #ff5800;
            font-size: 36px;
            small {
                font-size: 18px;
            }
        }
        p {
            font-size: 16px;
            line-height: 28px;
            color: #414446;
        }
        ul {
            li {
                font-size: 14px;
                line-height: 19px;
                padding-left: 15px;
                position: relative;
                margin-bottom: 10px;
                text-align: left;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    background: #ff6614;
                }
            }
        }
        a {
            display: inline-block;
            margin: 20px auto 0;
            border: 2px solid #333;
            border-radius: 5px;
            font-size: 18px;
            color: #333;
            padding: 6px 20px;
        }
    }
    h6 {
        color: #ff6614;
        margin-top: 20px;
    }
}


.payment-section {
    padding: 50px 0;
    @include media-query(992px) {
        padding: 80px 0;
    }
    h2 {
        font-size: 56px;
        margin-bottom: 30px;
    }
    .payment-box-wrap {
        padding: 25px 50px;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
        background: #fff;
        margin-bottom: 25px;
        .payment-box-left {
            @include media-query(992px) {
                flex: 0 0 25%;
                border-right: 1px solid #ccc;
            }
            padding: 20px 0 0;
        }
        .payment-box-right {
            @include media-query(992px) {
                flex: 0 0 25%;
                border-left: 1px solid #ccc;
            }
            padding: 20px 0 0 30px;
        }
        .payment-box-mid {
            @include media-query(992px) {
                padding: 20px 0 0 30px;
                flex: 1;
            }
        }
        h4 {
            font-size: 18px;
            font-weight: 500;
        }
        p {
            padding: 20px 30px;
        }
    }
}

.UI-UX {
    .o-engagement-model__cards__top__inner {
        h3 {
            @include media-query(1200px) {
                font-size: 28px;
            }
        }
    }
    
}

.new-price-wrapper
{
    .o-engagement-model__cards
    {
        .o-engagement-model__cards__top__inner
        {
            margin: 15px 0 5px 0;
            @include responsive(desktop){
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 74px;
                margin: 15px 0 5px 0;
            }
            h3{
                font-size: 24px;
                line-height: 30px;
                font-weight: 500;
                margin-top: 0px;
                margin-bottom: 0px;
                @include responsive(desktop){
                    font-size: 28px;
                    line-height: 37px;
                }
                .container1
                {
                    color: #FF5800;
                }
                .price-hour-tag
                {
                    color: #FF5800;
                    font-weight: 500;
                }
            }
        }
        .o-engagement-model__cards__bottom
        {
            padding: 0px 0px;
            margin-bottom: 10px;
            @include responsive(desktop){
                padding: 0;
            }
            .price-text
            {
                margin-bottom: 0px;
            }
        }
        .o-engagement-model__cards__btn
        {

        }
        .time-material-side {
            @include media-query(992px) {
                margin-bottom: 35px;
            }
        }
    }
    
}
.price-separate-btn
{
    max-width: 370px;
    padding: 14px 10px;
}
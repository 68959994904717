.new-home-banner {
  $new-home-banner: &;

  position: relative;
  background-color: #0d2642;
  padding: 25px 0 40px;
  @include media-query(992px) {
    background-image: url(/images/new-home-banner/banner-gradient.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 60px 0 160px;
  }
  @include media-query(992px) {
    &:after {
      content: "";
      width: 453px;
      height: 199px;
      background-image: url(/images/new-home-banner/banner-gradient2.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }
  &__wrapper {
    position: relative;
    @include media-query(992px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &--out {
      @include media-query(992px) {
        align-items: center;
      }
    }
    &--leap-mwc {
      &:before {
        content: '';
        position: absolute;
        background-image: url(/images/new-home-banner/leap-mwc-gradiant.svg);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        left: 140px;
        bottom: -235px;
        width: 854px;
        height: 685px;
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
    }
  }
  &__left {
    flex: 0 0 69%;
    position: relative;
    @include media-query(992px) {
      flex: 0 0 65%;
      padding-right: 80px;
    }
    @include media-query(1025px) {
      flex: 0 0 69%;
    }
    &::before {
      display: none;
      @include media-query(992px) {
        content: "";
        position: absolute;
        top: 5px;
        right: 5px;
        width: 2px;
        height: 565px;
        background: url(/images/new-home-banner/banner-devider2.png) no-repeat 0 0;
        display: block;
      }
    }
    &--leap-mwc {
      @include media-query(992px) {
        padding-top: 20px;
      }
      &:before {
        display: none;
      }
      @include media-query(992px) {
        flex: 0 0 58%;
        padding-right: 25px;
      }
      @include media-query(1025px) {
        flex: 0 0 60%;
      }
      .new-home-banner__sub-heading-white {
        margin-top: 15px;
        @include media-query(992px) {
          font-size: 58px;
          line-height: 68px;
          margin-top: 0;
          margin-bottom: 25px;
        }
        .new-home-banner__sub-heading-green {
          color: #3CC065;
        }
      }
      .new-home-banner__blue-para {
        color: #97B3CE;
        font-weight: 500;
        font-family: Poppins;
        text-align: center;
        @include media-query(992px) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 35px;
          text-align: left;
        }
      }
      .new-home-banner__btn {
        margin-left: 0;
      }
    }
    &--out {
      @include media-query(992px) {
        flex: 1;
      }
      &:before {
        display: none;
      }
    }
  }
  &__right {
    flex: 1;
    @include media-query(992px) {
      padding-left: 65px;
    }
    &--out {
      padding: 20px 15px;
      border: 1px solid #355BBF;
      background: #04254A;
      text-align: center;
      margin-top: 20px;
      @include media-query(992px) {
        padding: 50px;
        flex: 0 0 482px;
        text-align: left;
        margin-top: 0;
      }
    }
    &--leap-mwc {
      // background: rgba(255, 255, 255, 0.2);
      padding: 25px 15px;
      margin-top: 20px;
      @include media-query(992px) {
        padding: 15px 15px;
        position: relative;
        margin-top: 0;
      }
      .new-home-banner__right-deatils {
        top: 0;
      }
      .new-home-banner__right-deatils {
        font-weight: 700;
      }
      position: relative;
    }
    &--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px;
      margin-bottom: 20px;
      p {
        margin-bottom: 0;
      }
      .date {
        font-family: Poppins;
        background: #DE002B;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff;
      }
    }
    &--leap-mwc-top-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff;
    }
    &--leap-mwc-bottom-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #fff;
    }
    &--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px;
      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
      }
      .date {
        font-family: Poppins;
        background: #007D9F;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid #fff;
      }
    }
    &--leap-mwc-top {
      background: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(101,115,132,0.62);
      padding: 25px 25px 20px;
      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        @include media-query(992px) {
          text-align: left;
          font-size: 18px;
        }
      }
    }
    &--leap-mwc-top-box-leap {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &--leap-mwc-top-box-leap-left {
      flex: 1;
      position: relative;
      background-color: #fff;
      &:before{
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto;
      }
      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--leap-mwc-top-box-leap-right {
      flex: 1;
      background: #0C0C68;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff;
        @include media-query(992px) {
          font-size: 34px;
        }
      }
    }
    &--leap-mwc-bottom {
      background: rgba(0, 0, 0, 0.7);
      padding: 25px 25px 20px;
      border: 1px solid rgba(101,115,132,0.62);
      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: Poppins;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        @include media-query(992px) {
          text-align: left;
          font-size: 18px;
        }
      }
    }
    &--leap-mwc-bottom-box-leap {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &--leap-mwc-bottom-box-leap-left {
      position: relative;
      flex: 1;
      background-color: #fff;
      &:before{
        position: absolute;
        content: "";
        width: 80%;
        height: 80%;
        background-color: #000;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: auto;
      }
      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--leap-mwc-bottom-box-leap-right {
      flex: 1;
      background: #22569D;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: Poppins;
        font-weight: 800;
        color: #fff;
        text-align: center;
        @include media-query(992px) {
          font-size: 24px;
        }
        small {
          font-size: 19px;
          display: block;
        }
      }
    }
    &--convergence-gitex{
      position: relative;
      .new-home-banner__right--leap-mwc-top-box-leap-left{
        padding: 12px 17px;
        &::before{
          display: none;
        }
        img{
          width: inherit;
          height: inherit;
          object-fit: initial;
        }
      }
      .new-home-banner__right--leap-mwc-bottom-box-leap-right{
        background: #0E1488;
        &.bg-darkgreen{
          background: #00666C;
        }
      }
      .new-home-banner__right--leap-mwc-top .date{
        background: #F70026;
        padding: 6px 0;
        font-weight: 700;
        @include media-query(992px) {
          font-size: 21px;
        }
      }
      .new-home-banner__right--leap-mwc-bottom-box-leap-right{
        span{
          font-size: 20px;
          font-weight: 600;
          @include media-query(992px) {
            font-size: 28px;
            line-height: 30px;
            font-weight: 800;
          }
          small {
            display: block;
            font-size: 16px;
            font-weight: 600;
            @include media-query(992px) {
              font-size: 20px;
              font-weight: 800;
            }
            
          }
        }
      }
      .new-home-banner__right--leap-mwc-bottom-box-leap-left{
        padding: 10px 0;
        &::before{
          display: none;
        }
        img{
          width: inherit;
          height: inherit;
          object-fit: initial;
        }
      }
      .new-home-banner__right--leap-mwc-bottom .date{
        padding: 6px 0;
        font-weight: 700;
        @include media-query(992px) {
          font-size: 21px;
        }
      }
    }
  }
  &__heading {
    color: #fff;
    font-weight: 700;
    font-family: "Poppins" !important;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    position: relative;
    padding-bottom: 20px;
    @include media-query(992px) {
      font-size: 56px;
      line-height: 68px;
      text-align: left;
      padding-bottom: 30px;
    }
    &:before {
      @include media-query(992px) {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: #91accd;
        height: 2px;
        width: 265px;
      }
    }
    &--leap-mwc {
      padding-bottom: 0;
      font-size: 26px;
      line-height: normal;
      margin-bottom: 0;
      @include media-query(992px) {
        font-size: 34px;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
    }
    &--leap-mwc {
      &:before {
        display: none;
      }
    }
    &--out {
      position: relative;
      @include media-query(992px) {
        font-size: 31px;
        line-height: 40px;
        font-weight: 700;
      }
      &:before {
        content: '';
        position: absolute;
        background: #84A5F9;
        width: 168px;
        height: 5px;
        display: none;
        @include media-query(992px) {
          display: block;
        }
      }
    }
    &--green-out {
      color: #2DBE70;
    }
  }
  &__arrow {
      position: absolute;
      top: -86px;
      right: -76px;
  } 
  &__sub-heading {
    color: #3cc065;
    font-family: "Poppins" !important;
    @include media-query(992px) {
      font-size: 31px;
      line-height: 40px;
      font-weight: 600;
    }
  }
  &__sub-heading-white {
    color: #fff;
    text-align: center;
    @include media-query(992px) {
      font-size: 60px;
      line-height: 68px;
      font-weight: 700;
      margin-top: 35px;
      text-align: left;
    }
  }
  &__right-heading {
    font-family: "Poppins" !important;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    color: #3cc065;
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: center;
    display: block;
    @include media-query(992px) {
      text-align: left;
      font-size: 26px;
      line-height: 42px;
      color: #fff;
      margin-top: 0;
    }
    .banner-line-break {
      li{
        display: inline-block;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
        @include media-query(992px) {
          display: block;
          margin-right: 0px;
        }
      }
    }
    a {
      color: #3cc065;
      display: inline-block;
      margin-right: 4px;
      @include media-query(992px) {
        margin-right: 6px;
        color: #fff;
      }
      span {
        @include media-query(992px) {
          background: linear-gradient(#3cc065) left no-repeat, #fff;
          background-size: 0% 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: 0.2s all ease-in-out;
          display: inline-block;
        }
      }
      &:hover {
        span {
          @include media-query(992px) {
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  &__right-bottom-bar {
    position: relative;
    display: inline-block;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      // background-color: #3CC065;
      border-radius: 10px;
      animation: 4s linear animate infinite;
      @include media-query(992px) {
        height: 4px;
        border-radius: 30px;
      }
    }
    // &:hover {
    //     &:before {
    //         animation: hue-rotate 3s linear infinite;
    //     }
    // }
    &--first {
      &:before {
        background: -moz-linear-gradient(
          90deg,
          #03a9f4,
          #ff5800,
          #fbba14,
          #03a9f4
        );
        background: -webkit-linear-gradient(
          90deg,
          #03a9f4,
          #ff5800,
          #fbba14,
          #03a9f4
        );
        background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
        background-size: 200%;
      }
    }
    &--second {
      &:before {
        background: -moz-linear-gradient(
          90deg,
          #ffe70e,
          #78e6ff,
          #15fe8a,
          #ffe70e
        );
        background: -webkit-linear-gradient(
          90deg,
          #ffe70e,
          #78e6ff,
          #15fe8a,
          #ffe70e
        );
        background: linear-gradient(90deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
        background-size: 200%;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // gap: 5px;
    justify-content: center;
    @include media-query(992px) {
      flex-direction: column;
      flex-wrap: inherit;
      align-items: flex-start;
      justify-content: inherit;
    }
  }
  &__list-item {
    border-width: 1px;
    border-style: solid;
    border-radius: 26px;
    padding: 1px;
    transition: 0.3s all ease-in-out;
    transition: width 0.3s ease;
    position: relative;
    overflow: hidden;
    margin: 0 2.5px 5px;
    @include media-query(992px) {
      border-radius: 30px;
      transform: translateZ(0);
      border: none;
      padding: 1.5px;
      margin: 0 0 5px;
    }
    path {
      transition: 0.3s all ease-in-out;
    }
    &:hover {
      svg {
        transform: translate(30px, 0);
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -80px;
      right: 0;
      bottom: 0;
      left: -13px;
      width: 250px;
      height: 250px;
      transition: 0.3s all ease;
    }
    &--border-color1 {
      @include media-query(992px) {
        width: 155px;
      }
      &::before {
        background: #64b9f4;
      }
      &:hover {
        @include media-query(992px) {
          width: 181px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color2 {
      @include media-query(992px) {
        width: 155px;
      }
      &:before {
        background: #e26826;
      }
      &:hover {
        @include media-query(992px) {
          width: 185px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color3 {
      @include media-query(992px) {
        width: 195px;
      }
      &:before {
        background: #fbba14;
      }
      &:hover {
        @include media-query(992px) {
          width: 225px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color4 {
      @include media-query(992px) {
        width: 155px;
      }
      &:before {
        // background: #8ac640;
        background: #648ff4;
        width: 310px;
        height: 310px;
        left: -20px;
        top: -120px;
      }
      &:hover {
        @include media-query(992px) {
          width: 180px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color5 {
      @include media-query(992px) {
        width: 260px;
      }
      &:before {
        background: #804b8b;
        width: 310px;
        height: 310px;
        left: -20px;
        top: -120px;
      }
      &:hover {
        @include media-query(992px) {
          width: 275px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color6 {
      @include media-query(992px) {
        width: 165px;
      }
      &:before {
        background: #3b95d3;
      }
      &:hover {
        @include media-query(992px) {
          width: 195px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color7 {
      @include media-query(992px) {
        width: 155px;
      }
      &:before {
        background: #e98e5c;
      }
      &:hover {
        @include media-query(992px) {
          width: 185px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color8 {
      @include media-query(992px) {
        width: 115px;
      }
      &:before {
        background: #3cc065;
      }
      &:hover {
        @include media-query(992px) {
          width: 145px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    &--border-color9 {
      @include media-query(992px) {
        width: 270px;
      }
      &:before {
        background: #E98E5C;
        width: 310px;
        height: 310px;
        left: -20px;
        top: -120px;
      }
      &:hover {
        @include media-query(992px) {
          width: 285px;
        }
        path {
          fill: #fff;
        }
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
    a {
      font-family: "Poppins" !important;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0 5px;
      background: #0d2642;
      border-radius: 30px;
      padding: 5px 15px;
      position: relative;
      z-index: 9;
      @include media-query(992px) {
        font-size: 26px;
        padding: 5px 25px;
      }
      svg {
        transition: 0.3s all ease-in-out;
      }
    }
  }
  &__left-arrow {
    position: absolute;
    left: 0;
    top: -15px;
  }
  &__right-arrow {
    position: absolute;
    right: 0;
    top: -15px;
  }
  &__right-deatils {
    font-family: "Poppins" !important;
    color: #fff;
    font-weight: 400;
    @include media-query(992px) {
      position: relative;
      top: -25px;
      font-size: 31px;
      line-height: 40px;
    }
  }
  &__right-deatils-green {
    color: #3CC065;
    font-weight: 700;
  }
  &__rating {
    @include media-query(992px) {
      margin: 20px auto 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      padding: 0 50px 0 0;
      position: relative;
    }
  }
  &__rated-text {
    flex: 0 0 49%;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
    }
  }
  &__rated-heading {
    font-family: "Poppins" !important;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }
  &__rated-sub-heading {
    font-family: "Poppins" !important;
    color: #3cc065;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500;
  }
  &__rated-icon {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    @include media-query(992px) {
      margin-left: 40px;
    }
  }
  &__icon-item {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
  }
  &__btn-wrapper {
    margin-top: 25px;
    @include media-query(992px) {
      margin-bottom: 40px;
      position: absolute;
      bottom: -135px;
      left: 0;
      margin-top: 0;
    }
    @include media-query(1025px) {
      bottom: -85px;
    }
  }
  &__btn {
    border-color: #ff5800;
    color: #fff;
    border-width: 2px;
    background: #ff5800 !important;
    @include media-query(992px) {
      width: 380px;
      background: none !important;
      &:hover {
        background: none;
        color: #fff;
        transition: 0.3s all ease-in-out;
      }
    }
    path {
      fill: #fff;
      @include media-query(992px) {
        fill: #ff5800;
      }
    }
  }
  &__btn-arrow {
    margin-left: 10px;
  }
  &__leftlist{
    width: 100%;
    max-width: 510px;
    margin: auto;
    @include media-query(1024px) {
      max-width: inherit;
      margin: 0 0 20px;
    }
    li{
      font-size: 18px;
      line-height: normal;
      font-weight: 500;
      color: #8FC4F5;
      background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat 0 0;
      background-size: 20px 20px;
      padding-left: 28px;
      margin: 12px 0;
      @include media-query(992px) {
        font-size: 21px;
        line-height: 36px;
        background: url(/images/new-home-banner/new-banner-sky-tick.svg) no-repeat center left;
        background-size: 23px 23px;
        margin: 0;
        padding-left: 32px;
      }
    }
  }
  .new-banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    transition: all 0.8s ease-in-out;

    @include media-query(1266px) {
      height: auto;
    }
    @include media-query(1367px) {
      height: 90%;
    }
    &.desktop-banner-img {
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
    &.mobile-banner-img {
      display: block;
      @include media-query(992px) {
        display: none;
      }
    }
  }
  .new-home-banner-content {
    padding: 60px 0;
    position: relative;
    @include media-query(992px) {
      padding: 125px 0 195px;
    }
    @include media-query(1089px) {
      padding: 100px 0 220px;
    }
    @include media-query(1367px) {
      padding: 100px 0 220px;
    }
    @include media-query(1600px) {
      padding: 100px 0 215px;
    }
    @include media-query(1700px) {
      padding: 100px 0 215px;
    }
    @include media-query(1813px) {
      padding: 115px 0 232px;
    }
    @include media-query(2040px) {
      // padding: 86px 0 390px;
      padding: 170px 0 275px;
    }
    @include media-query(2720px) {
      // padding: 86px 0 630px;
      padding: 190px 0 400px;
    }
    @include media-query(4080px) {
      padding: 86px 0 550px;
    }
    .new-home-banner-text-order-1 {
      order: 2;
      @include media-query(992px) {
        order: 1;
      }
    }
    .new-home-banner-text-order-2 {
      order: 1;
      @include media-query(992px) {
        order: 2;
      }
    }
    .banner-main-heading-text {
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 44px;
      text-align: center;
      @include media-query(992px) {
        font-weight: 700;
        margin-bottom: 20px;
        font-size: 49px;
        line-height: 61px;
        text-align: left;
      }
      @include media-query(1280px) {
        font-size: 51px;
        line-height: 62px;
      }
    }
    .on-demand-p {
      color: #fff;
      font-size: 22px;
      margin: 25px 0 20px;
      @include media-query(992px) {
        font-size: 24px;
        margin: 25px 0 35px;
      }
    }
    .new-banner-text-area {
      .new-banner-text {
        margin-bottom: 0;
        padding-left: 0;
        position: relative;
        text-align: center;
        @include media-query(992px) {
          padding-left: 40px;
          text-align: left;
          &::before {
            content: "";
            width: 2px;
            left: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            background-color: #d8d8d8;
          }
        }

        .new-banner-text-li {
          margin-bottom: 10px;
          font-size: 22px;
          font-weight: 600;
          line-height: 22px;
          color: #46df75;
          @include media-query(992px) {
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .btn-orange {
      &.banner-btn-new {
        margin: 0 auto;
      }
    }
    .bottom-form-link {
      text-align: center;
      @include media-query(992px) {
        text-align: left;
      }
    }
  }

  .new-banner-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-query(992px) {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    width: 100%;
    margin-bottom: 30px;
    li {
      color: #3cc065;
      position: relative;
      width: 100%;
      @include media-query(992px) {
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        margin: 0 15px;
        width: auto;
      }
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      font-size: 20px;
      padding: 5px 0;
      &:before {
        content: "";
        position: absolute;
        background: url(/images/new-home-banner/banner-bullet.png) no-repeat 0 0;
        top: 13px;
        right: -65px;
        height: 40px;
        width: 40px;
        background-size: 100%;
        background-position-y: 50%;
        display: none;
        @include media-query(992px) {
          display: block;
          top: 10px;
          right: -25px;
          width: 20px;
        }
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  .new-banner-rated-area {
    // background-color: #091B38;
    background: #061226;
    position: relative;
    &.m-height {
      height: 110px;
      @include media-query(992px) {
        height: auto;
      }
    }
    p {
      margin-bottom: 0;
    }
    .new-banner-rated-area-main-box,
    .new-banner-rated-area-main-box-mod {
      flex-wrap: wrap;
      @include media-query(992px) {
        display: flex;
        // margin-left: -30px;
        // margin-right: -30px;
        flex-wrap: nowrap;
        justify-content: center;
      }
      @include media-query(1200px) {
        // margin-left: -50px;
        // margin-right: -50px;
      }
      .new-banner-rated-area-box {
        display: flex;
        align-items: center;
        // flex-basis: 25%;
        padding: 18px 50px;
        border-right: 0;
        justify-content: center;
        position: relative;
        @include media-query(992px) {
          padding: 18px 25px;
          justify-content: left;
        }
        @include media-query(1200px) {
          padding: 14px 40px;
        }
        @include media-query(1280px) {
          padding: 14px 44px;
        }
        &:before {
          content: "";
          position: absolute;
          background: url(/images/new-home-banner/banner-devider.png) no-repeat
            0 0;
          width: 1px;
          height: 100%;
          top: 0;
          right: 0;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
        .rated-left-area {
          text-align: center;
          padding-right: 20px;
          @include media-query(992px) {
            padding-right: 20px;
          }
          @include media-query(1200px) {
            padding-right: 38px;
          }

          .rated-text {
            color: #8d9eb2;
            font-size: 13px;
          }
          .rated-percentage {
            color: #81baff;
            font-weight: 500;
            font-size: 36px;
            padding: 0px 0 5px;
          }
          .rated-star {
            i {
              color: #f7b500;
              font-size: 10px;
            }
          }
        }
      }
      .slick-dots {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }
  }
  .splide__arrows {
    display: none;
  }
  &--time-business-award {
    position: relative;
    &:after {
      background: url(/images/new-home-banner/green-gradiant-tr.png) no-repeat 0 0;
      width: 710px;
      height: 486px;
      top: 0;
      right: 0;
      left: inherit;
      transform: none;
      z-index: 0;
    }
    @include media-query(992px) {
      padding: 0;
    }
    .new-home-banner__left {
      &:before {
        display: none;
      }
    }
    .new-home-banner__left {
      @include media-query(992px) {
        padding: 120px 0 0;
      }
    }
    .new-home-banner__heading {
      font-size: 31px;
      line-height: 38px;
      font-weight: 700;
      @include media-query(992px) {
        font-size: 44px;
        line-height: 53px;
      }
      &:before {
        display: none;
      }
    }
    .new-home-banner__heading-quote {
      color: #3CC065;
    }
    .new-home-banner__blue-para {
      font-family: "Poppins";
      font-size: 17px;
      line-height: 23px;
      font-weight: 600;
      color: #8FC4F5;
      text-align: center;
      margin-top: 20px;
      @include media-query(992px) {
        padding-right: 290px;
        font-size: 21px;
        line-height: 27px;
        text-align: left;
        margin-top: 0;
      }
    }
    .new-home-banner__btn-wrapper {
      position: relative;
      top: 0;
      @include media-query(992px) {
        margin-top: 27px;
      }
    }
    .new-home-banner__award-img {
      @include media-query(992px) {
        position: absolute;
        top: 35px;
        right: 0;
        z-index: 999;
      }
      img {
        @include media-query(992px) {
          width: 94%;
          margin-left: auto;
          display: block;
        }
      }
    }
  }
  &--et-award {
    @include media-query(992px) {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/et-award-slider.jpg) no-repeat center center / cover;
    }
    &:after {
      display: none;
    }
    .new-home-banner__left {
      &:before {
        display: none;
      }
    }
    .new-home-banner__heading {
      &:before {
        display: none;
      }
    }
    .new-home-banner__heading {
      font-family: "Poppins";
      font-weight: 700;
      margin-top: 20px;
      @include media-query(992px) {
        font-size: 44px;
        line-height: 50px;
        padding-bottom: 0;
        margin-bottom: 25px;
        margin-top: 0;
      }
    }
    .new-home-banner__blue-para {
      font-family: "Poppins";
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #97B3CE;
      text-align: center;
      @include media-query(992px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left;
      }
    }
    .new-home-banner__heading-quote {
      color: #3CC065;
    }
    .new-home-banner__left {
      @include media-query(992px) {
        padding-top: 100px;
      }
      @include media-query(1025px) {
        flex: 0 0 55%;
      }
    }
  }
  &--et-excellence{
    @include media-query(992px) {
      padding: 80px 0;
      background: url(/images/new-home-banner/et-excellence-slider-new2.jpg) no-repeat center right;
      background-size: 100% 100%;
    }
    @include media-query(1600px) {
      background-size: cover;
    }
    &:after {
      display: none;
    }
    .new-home-banner__left {
      padding-right: 0;
      flex: 0 0 49%;
      &:before {
        display: none;
      }
    }
    .new-home-banner__right{
      padding: 30px 0;
      @include media-query(992px) {
        padding: 0;
      }
    }
    .new-home-banner__heading {
      @include media-query(992px) {
        font-size: 44px;
        line-height: 58px;
        margin: 0;
      }
      &:before {
        display: none;
      }
    }
    .new-home-banner__blue-para {
      font-family: "Poppins";
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      @include media-query(992px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 55px;
        text-align: left;
      }
    }
    .new-home-banner__btn-wrapper{
      margin-top: 35px;
    }
  }
  &--outsoucing-success-blueprint {
    @include media-query(992px) {
      padding: 60px 0 80px 0;
      background: url(/images/new-home-banner/outsourcing-bg.jpg) no-repeat center center / cover;
    }
    &:after {
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 386px;
      height: 360px;
      background: url(/images/new-home-banner/banner-gradient.png) no-repeat 0 0;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
    .new-home-banner__btn-wrapper {
      position: relative;
      left: 0;
      bottom: 0;
      margin-bottom: 15px;
    }
    .new-home-banner__btn {
      background: #FF5800 !important;
      svg {
        path {
          fill: #fff;
        }
      }
      &:hover {
        background: #FFFFFF !important;
        svg {
          path {
            fill: #FF5800;
          }
        }
      }
    }
  }
  &--leap-mwc {
    @include media-query(992px) {
      padding: 80px 0 50px 0;
      background: url(/images/new-home-banner/leap-mwc-bg.jpg) no-repeat center center / cover;
    }
    &:after {
      display: none;
    }
    .new-home-banner__btn-wrapper {
      position: static;
      margin-bottom: 0;
    }
    .btn-orange {
      background: #ff5800 !important;
      font-size: 17px;
      @include media-query(992px) {
        max-width: 350px;
        width: 350px;
      }
      svg {
        path {
          fill: #fff !important;
        }
      }
      &:hover {
        background: none !important;
        color: #fff;
      }
    }
    .new-home-banner__right-items {
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      margin: 8px 0;
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
    .new-home-banner__right-list {
      margin: 25px 0 35px;
    }
  }
}
// @keyframes hue-rotate {
//     from {
//       -webkit-filter: hue-rotate(0);
//       -moz-filter: hue-rotate(0);
//       -ms-filter: hue-rotate(0);
//       filter: hue-rotate(0);
//     }
//     to {
//       -webkit-filter: hue-rotate(360deg);
//       -moz-filter: hue-rotate(360deg);
//       -ms-filter: hue-rotate(360deg);
//       filter: hue-rotate(360deg);
//     }
//   }
@keyframes animate {
  0% {
    background-position: 400%;
  }
  100% {
    background-position: 0%;
  }
}
.text-left-mod {
  text-align: left !important;
}

.margin-unset {
  margin-bottom: 0;
}
// .new-home-banner:hover .new-banner-img{
//     @include media-query(992px){
//         transform: scale(1.06);
//     }
// }
.num-color {
  color: #43bf69;
}
// .home-page-spacer
// {
//     padding-top: 53px;
//     @include media-query(992px){
//         padding-top: 64px;
//     }
// }

.hm-about-section {
  .about-details-para {
    @include media-query(992px) {
      width: 92%;
    }
  }
  .font-size-42 {
    font-size: 24px;
    line-height: 36px;
    @include media-query(992px) {
      font-size: 41px;
      line-height: 52px;
    }
    @include media-query(1280px) {
      font-size: 42px;
      line-height: 52px;
    }
  }
}

.new-home-banner-wrapper {
  .splide__arrows {
    display: none;
  }
  .custom-pagination-wrapper {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    bottom: 0;
    height: 60px;
  }
  .custom-pagination {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    max-width: 1150px;
    margin: 0 auto;
    align-items: center;
    padding: 0 15px;
    @include media-query(992px) {
      padding: 0;
    }
  }
  
  .pagination-dot {
    position: relative;
    background:  linear-gradient(to right, #ffffff 50%, #acacac 50%);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    color: transparent; /* Hide the original text color */
    border: none;
    padding: 0;
    margin: 0 30px 0 0;
    cursor: pointer;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 300;
    // transition: 10s all ease-in-out;
    width: auto;
    text-transform: capitalize; 
    background-position: 100% 0%;
  }
  
  .pagination-dot.is-active {
    background-position : 0% 0%;
  }
  
  // #playPauseButton.paused {
  //   background-color: #555;
  // }
  
  .pay-pause-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
  
  .pagination-pay-pause {
    width: 30px;
    height: 30px;
    color: #acacac;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 0;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 50%;
    background: url(/images/new-home-banner/pause-white.svg) no-repeat 55% 50% / 60%;
    transition: .3s all ease-in-out;
    &.paused {
      border: 2px solid #fff;
      border-radius: 50%;
      background: url(/images/new-home-banner/play-white.svg) no-repeat 62% 50% / 60%;
    }
    &:hover {
      transform: scale(1.1);
    }
    opacity: 0.5;
  }
  .splide__pagination {
    position: relative;
    bottom: 25px;
    @include media-query(992px) {
      display: none;
    }
  }
  .splide__progress {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .splide__progress__bar {
    background: #a8d04d;
    height: 4px;
    animation: 4s linear animate infinite;
      background: -moz-linear-gradient(
          90deg,
          #03a9f4,
          #ff5800,
          #fbba14,
          #03a9f4
        );
      background: -webkit-linear-gradient(
          90deg,
          #03a9f4,
          #ff5800,
          #fbba14,
          #03a9f4
        );
      background: linear-gradient(90deg, #03a9f4, #ff5800, #fbba14, #03a9f4);
      background-size: 200%;
  }
}

.new-home-banner-wrapper {
  background: #0d2642;
}

@keyframes fillLine {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.convergence-gitx-left{
  @include media-query(992px) {
    flex: 0 0 60%;
    padding-right: 20px;
  }
  @include media-query(1025px) {
    flex: 0 0 63%;
  }
  .new-home-banner__sub-heading-white{
    @include media-query(992px) {
      font-family: Poppins;
      font-size: 50px;
      line-height: 60px;
    }
  }
}


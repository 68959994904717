.aws-devops-tech-stack {
    padding: 40px 0 50px;
    background-color: #F4F4F4;
    @include media-query(992px) {
        padding: 70px 0 80px;
        background-color: #F4F4F4;
    }
    h2 {
        font-weight: 600;
        @include media-query(992px) {
            margin-bottom: 20px;
        }
    }
    &-wrapper {
        .aws-devops-tech-stack-slider{
            display: flex;
            flex-wrap: wrap;
            .splide_slidemod {
                text-align: center;
                @include media-query(992px) {
                    width: 25%;
                }
                padding: 0 10px;
                margin-top: 40px;
                .aws-devops-container
                {
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 25px 15px;
                    margin-bottom: 15px;
                    -webkit-box-shadow: 5px 10px 20px 0px rgba(0,0,0,0.05);
                    -moz-box-shadow: 5px 10px 20px 0px rgba(0,0,0,0.05);
                    box-shadow: 5px 10px 20px 0px rgba(0,0,0,0.05);
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 10px;
                        display: block;
                    }
                    i {
                        display: inline-block;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        width: 100px;
                        margin: 0 auto;
                        @include media-query(992px) {
                            height: 85px;
                            width: 185px;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                        &.stack-img-mod {
                            width: 100px;
                        }
                        &.open-api {
                            position: relative;
                            left: 5px;
                        }
                    }
                }
            }
            .slick-dots {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                li {
                    padding: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

.aws-devops-tech-stack-slider {
    .owl-dots {
        margin-bottom: 0;
    }
}
// .aws-devops-tech-stack-no-slider {
//     display: flex;
//     flex-wrap: wrap;
//     li{
//         text-align: center;
//         padding: 0 10px;
//         margin-top: 40px;
//         width: 25%;
//         .aws-devops-container{
//             background-color: #fff;
//             border-radius: 5px;
//             padding: 25px 15px;
//             margin-bottom: 15px;
//             -webkit-box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, .05);
//             box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, .05);
//         }
//     }
// }


.portfolio-banner {
  $portfolio-banner: &;
  position: relative;
  background: #04254a url(/images/new-portfolio/banner-left-graphic.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 30px 0 25px;
  width: 100%;
  overflow: hidden;
  @include media-query(992px) {
    padding: 36px 0 36px;
  }
  &:after {
    position: absolute;
    content: "";
    width: 289px;
    height: 196px;
    background: url(/images/new-portfolio/banner-right-graphic.png) no-repeat
      bottom center;
    background-size: 100%;
    bottom: 0;
    right: 0;
  }
  &__heading-text {
    font-family: Poppins;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    @include media-query(992px) {
      margin-bottom: 0;
    }
  }
  &__assets {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 690px;
      height: 345px;
      background: url(/images/new-portfolio/banner-assets-background.svg)
        no-repeat center;
      background-size: 100% 100%;
      top: -35px;
      left: -80px;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  &__middle {
    position: relative;
    max-width: 430px;
    width: 100%;
    margin: auto;
    z-index: 1;
  }
  &__asset-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__asset-heading {
    font-family: Poppins;
    color: #3cc065;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    width: 300px;
    margin-bottom: 22px;
  }
  &__asset-box {
    flex: 0 0 50%;
    display: inline-flex;
    margin-bottom: 26px;
    &:nth-child(3),
    &:nth-child(4) {
      @include media-query(992px) {
        margin-bottom: 16px;
      }
    }
  }
  &__asset-icon {
    width: 50px;
    text-align: center;
    margin-right: 15px;
  }
  &__asset-text {
    max-width: 105px;
    h3 {
      font-family: Poppins;
      font-size: 27px;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }
    p {
      font-family: Poppins;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      color: #fff;
      margin: 0;
    }
  }
}

.portfolio-view {
  $portfolio-view: &;
  &__spacing {
    padding: 15px 0 0;
    @include media-query(992px) {
      padding: 40px 0 0;
      display: block;
    }
  }

  &__leftpart {
    // height: 100%;
    // position: fixed;
    // z-index: 99;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background: #ccc;
    @include media-query(992px) {
      border-right: 1px solid #e0e0e0;
      position: static;
      background: inherit;
      height: 100%;
    }
    .portfolio-view__spacing {
      display: flex;
      @include media-query(992px) {
        display: block;
      }
    }
    .sk-fading-circle {
      margin: 15px auto 0;
      width: 40px;
      height: 40px;
      position: relative;
    }
  }

  &__filterwrapper {
    padding-right: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
    position: relative;
    @include media-query(992px) {
      margin-bottom: 30px;
    }
    &:before {
      content: "OR";
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
      font-family: Poppins;
      font-size: 11px;
      font-weight: 600;
      color: #333;
      padding: 0 5px;
      width: 30px;
      background: #ccc;
      height: 30px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: none;
      @include media-query(992px) {
        display: flex;
      }
    }
    &--country {
      margin-bottom: 0;
      @include media-query(992px) {
        margin-bottom: 30px;
      }
      &::before {
        display: none;
      }
    }
  }

  &__filtertittle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    border-radius: 15px 0 15px 0;
    margin-bottom: 18px;

    &--blue {
      background-color: #dee7ff;
    }

    &--green {
      background-color: #d4ffe1;
    }

    &--orange {
      background-color: #ffeadf;
    }

    i {
      margin-right: 8px;
    }

    h4 {
      font-family: Poppins;
      font-size: 16px;
      color: #333333;
      line-height: 33px;
      font-weight: 600;
      margin: 0;
    }
  }

  &__filterlist {
    position: relative;
  }

  &__filteritem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      margin-right: 12px;
      color: #898989;
      border-color: #898989;
      width: 16px;
      height: 16px;
    }
    img {
      margin-right: 10px;
    }
    label {
      font-family: Poppins;
      font-size: 15px;
      line-height: 33px;
      color: #333333;
      font-weight: 500;
    }
  }

  &__filtermore {
    font-family: Poppins;
    color: #4492dc;
    font-size: 15px;
    line-height: 33px;
    font-weight: 500;
    margin-left: 27px;
    display: inline-block;
    margin-top: 6px;
    cursor: pointer;
  }

  &__rightpart {
    position: relative;
    padding-bottom: 30px;
    @include media-query(992px) {
      padding-bottom: 60px;
    }
    .sticky-bottom {
      width: 100%;
      color: white;
      text-align: center;
      padding: 5px 0;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-query(992px) {
        padding: 15px 0;
      }
      &.fixed {
        position: fixed;
        top: 71px;
        left: 0;
        right: 0;
        z-index: 1000;
        padding: 5px 12px;
        background: #fff;
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #f5f5f5;
      }
      .filter-icon {
        font-size: 16px;
        font-weight: 500;
        font-family: Poppins;
        color: #000;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    .icon-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .sticky-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  &__rightittle {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media-query(992px) {
      display: flex;
      flex-direction: inherit;
      align-items: center;
    }
  }

  &__rightittlearea {
    margin-bottom: 15px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
    h4 {
      font-family: Poppins;
      font-size: 24px;
      line-height: 27px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }
    p {
      font-family: Poppins;
      font-size: 16px;
      line-height: 27px;
      color: #000;
      margin: 0;
    }
  }

  &__select {
    border: 1px solid #d9d9d9;
    padding: 10px;
    width: 183px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 16px;
    line-height: 27px;
    color: #000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/images/new-portfolio/select-arrow.svg) 93% / 15% no-repeat
      #fff;
    background-size: 13px;
  }

  &__masonry {
    position: relative;
    margin-top: 20px;
    @include media-query(992px) {
      column-gap: 30px;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
    &.orphan3 {
      @include media-query(992px) {
        orphans: 2;
      }
    }
    .no-data {
      width: 100%;
      font-size: 30px;
      font-weight: 600;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-query(992px) {
        top: 50%;
        height: 100vh;
        margin-top: 60px;
        padding-bottom: 30px;
        position: absolute;
        font-size: 40px;
      }
    }
  }

  &__masonryitem {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 12px 13px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 20px;
    @include media-query(992px) {
      margin-bottom: 30px;
    }
  }

  &__itemimage {
    margin-bottom: 24px;

    img {
      border-radius: 10px;
      width: 100%;
    }
  }

  &__itemtitlearea {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }

  &__titlelink {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex: 1;
    // margin-right: 25px;
    @include media-query(992px) {
      flex: inherit;
      flex-wrap: wrap;
      gap: 5px;
    }
    .portfolio-tittle {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 22px;
      line-height: 33px;
      font-weight: 700;
      color: #000;
      padding-right: 15px;
      margin-bottom: 0;
      @include media-query(992px) {
        margin: 0 15px 0 0;
        // border-right: 1px solid #ccc;
      }
    }
    .d-flex.justify-content-center.align-items-center{
      .portfolio-link{
        display: none;
      } 
    }
    .portfolio-link {
      font-family: "Plus Jakarta Sans", sans-serif;
      color: #4492dc;
      font-size: 14px;
      line-height: 33px;
      img {
        margin-right: 10px;
      }
    }
  }

  &__itemlink {
    width: 10%;
  }

  &__itemdesc {
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 15px;
  }
  &__filter-value {
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    display: none;
    text-indent: 99999;
  }

  &__techstack {
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 3px;
      // width: 47px;
      // height: 47px;
      // border-radius: 50%;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      &:nth-child(1){
        border: 1px solid #64b9f4;
      }
      &:nth-child(2){
        border: 1px solid #e26826;
      }
      &:nth-child(3){
        border: 1px solid #fbba14;
      }
      &:nth-child(4){
        border: 1px solid #8ac640;
      }
      &:nth-child(5){
        border: 1px solid #804b8b;
      }
      &:nth-child(6){
        border: 1px solid #64b9f4;
      }
      &:nth-child(7){
        border: 1px solid #e26826;
      }
      &:nth-child(8){
        border: 1px solid #fbba14;
      }
      &:nth-child(9){
        border: 1px solid #8ac640;
      }
      &:nth-child(10){
        border: 1px solid #804b8b;
      }
      a{
        color: #030303;
        font-weight: 700;
      }
    }
  }
}

.portfolio-view__paggination {
  display: flex;
  li {
    .flex-center {
      width: 51px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      color: #000;
      border: 1px solid #e1e1e1;
      margin-right: 13px;
      border-radius: 50%;
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      &.active-page {
        background-color: #3cc065;
        border-color: #3cc065;
        font-weight: 600;
        color: #fff;
      }
    }
    &:hover {
      .flex-center {
        border-color: #3cc065;
      }
    }
    &:first-child,
    &:last-child {
      .flex-center {
        background-color: #f4f4f4;
        &.active-page {
          background-color: #3cc065;
        }
      }
    }
    &:nth-last-child(2) {
      display: inline-block !important;
      &:before {
        content: "";
        background: url(/images/portfolio/three-dot.png) no-repeat 18px 23px;
        float: left;
        margin: 0 0px 0 13px;
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #000;
        line-height: 0;
        position: relative;
        left: -13px;
      }
    }
  }
  &.midShep {
    li {
      &:nth-child(2) {
        display: inline-block !important;
        &:before {
          content: "";
          background: url(/images/portfolio/three-dot.png) no-repeat 17px 23px;
          float: right;
          margin: 0 13px 0 13px;
          border: 1px solid #ccc;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background-size: 14px;
        }
      }
    }
  }
}

.discover {
  $discover: &;
  background-color: #0f123e;

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 31px;
    line-height: 33px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
    }
  }

  &__desc {
    font-family: Poppins;
    font-size: 21px;
    line-height: 27px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
    }
  }

  &__btn {
    max-width: 333px;
    border-color: #ff5800;
    margin: 10px auto 15px;
    padding: 16px;
    color: #fff;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    @include media-query(992px) {
      margin: 10px 0 0;
    }
    img {
      margin-left: 10px;
    }
    &:hover {
      color: #ff5800;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      width: auto !important;
      margin: 10px 5px;

      a {
        padding: 1px 20px;
      }
    }
  }
}

.portfolio-we-do {
  .how-help-you {
    background: #052c58 url(/images/new-portfolio/banner-left-graphic.png);
    background-repeat: no-repeat;
    background-position: top left;
  }
}

.portfolio-loader {
  display: none; /* Hide loader by default */
  position: fixed; /* Position loader fixed to the screen */
  top: 50%; /* Center loader vertically */
  left: 50%; /* Center loader horizontally */
  transform: translate(-50%, -50%); /* Center loader precisely */
  z-index: 99999999;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
}

.portfolio-loader-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    width: 40px; /* Adjust loader size */
    height: 40px; /* Adjust loader size */
    margin: 0 auto;
    border: 4px solid #3498db; /* Loader color */
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite; /* Apply rotation animation */
  }
}

// .hidden {
//   display: none;
// }

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-query(992px) {
    justify-content: inherit;
  }
  a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 13px;
    color: #000;
    border: 1px solid #e1e1e1;
    margin-right: 10px;
    border-radius: 50%;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    @include media-query(992px) {
      width: 50px;
      height: 50px;
      margin-right: 13px;
      font-size: 16px;
    }
    &.active {
      background-color: #3cc065;
      border-color: #3cc065;
      font-weight: 600;
      color: #fff;
    }
    &:hover {
      border-color: #3cc065;
    }
    &.pagination-prev,
    &.pagination-next {
      background-color: #f4f4f4;
    }
  }
}

#filterModal {
  display: none;
  position: fixed;
  z-index: 9999999 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 0;
  border-radius: 0;
  align-items: flex-end;

  .modal-content {
    background-color: #fefefe;
    margin: 0;
    padding: 20px 0 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    height: 540px;
  }
  .modal-body {
    flex: inherit;
    height: 100vh;
    overflow: scroll;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .close {
    color: #000000;
    float: right;
    font-size: 44px;
    font-weight: 500;
    opacity: 1;
    text-align: right;
    position: absolute;
    right: 10px;
    top: -3px;
    z-index: 99;
  }
  .modal-footer {
    display: flex;
    width: 100%;
    margin: 0 auto;
    border: 0 !important;
    padding: 15px 15px;
    .warning {
      color: red;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      flex: 1;
    }
    button:disabled {
      filter: grayscale(100%);
    }
  }
  #clearFiltersBtn {
    border: 1px solid #000;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 20px;
    flex: 1;
    margin: 0 7px 0 0;
  }
  #applyFiltersBtn {
    background: #3cc065;
    border: 1px solid #3cc065;
    color: #fff;
    font-size: 15px;
    padding: 10px 20px;
    flex: 1;
    font-weight: 600;
    margin: 0 0 0 7px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

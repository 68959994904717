// .no-webp {
//     .o-home-technology .box-wrapper .box:before {
//         background-image: url(/images/home/home-sprite.png);
//     }
// }
// .webp {
//     .o-home-technology .box-wrapper .box:before {
//         background-image: url(/images/webp/home/home-sprite.webp);
//     }
// }
.o-home-technology{
    position: relative;
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding: 70px 0 50px;
    }
    h2 {
        font-size: 30px;
        @include responsive(desktop){
            font-size: 48px;
        }
    }
    .home-link {
        margin-top: 0;
        @include responsive(desktop){
            margin-top: 15px;
            padding-bottom: 20px;
        }
    }
    .box-wrapper{
        margin-bottom: 10px;
        @include responsive(desktop){
            margin-bottom: 0;
        }
        .box{
            overflow: hidden;
            display: inline-block;
            @include border-radius(5px);
            margin: 0 4px 4px;
            position: relative;
            padding: 30px 10px 30px;
            font-size: 20px;
            color: $black;
            font-weight: 500;
            position: relative;
            width: 48%;
            height: 180px;
            font-size: 18px;
            @include box-shadow(0, 0, 12px, #a8a8a880);
            @include responsive(desktop){
                width: 170px;
                height: 190px;
                margin: 0 7px 14px;
            }
            i {
                display: block;
                height: 50px;
                margin-bottom: 20px;
                position: relative;
                z-index: 1;
                svg {
                    height: 100%;
                }
            }
            &:after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: 20px;
                transform: translate(-50%, 0);
                background-image: url(/images/home/home-arrow.png);
                width: 22px;
                height: 22px;
                background-position: 0;
            }
            // &:before{
            //     content: '';
            //     position: absolute;
            //     left: 50%;
            //     top: 30px;
            //     transform: translate(-50%, 0);
            //     width: 60px;
            //     height: 55px;
            // }
            &:nth-child(1){
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #00B05C;
                        color: $white-color;
                    }
                }
                border-bottom: 4px solid #00b05c;
            }
            &:nth-child(2){
                border-bottom: 4px solid #0095b5;
                @include responsive(desktop){
                    border-bottom: 4px solid #00af93;
                }
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #00AF93;
                        color: $white-color;
                    }
                }
            }
            &:nth-child(3){
                border-bottom: 4px solid #34bbea;
                @include responsive(desktop){
                    border-bottom: 4px solid #0095b5;
                }
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #0095B5;
                        color: $white-color;
                    }
                }
            }
            &:nth-child(4){
                border-bottom:4px solid #00af93;
                @include responsive(desktop){
                    border-bottom: 4px solid #007fab;
                }
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #007FAB;
                        color: $white-color;
                    }
                }
            }
            &:nth-child(5){
                border-bottom: 4px solid #018d46;
                @include responsive(desktop){
                    border-bottom: 4px solid #014d95;
                }
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #014D95;
                        color: $white-color;
                    }
                }
            }
            &:nth-child(6){
                border-bottom: 4px solid #014d95;
                @include responsive(desktop){
                    border-bottom: 4px solid #15336B;
                }
                @media(hover: hover) and (pointer: fine) {
                    &:hover{
                        // background-color: #15336B;
                        color: $white-color;
                    }
                }
            }
            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    &:after {
                        background-image: url(/images/home/home-arrow-hover.png);
                    }
                    i {
                        svg {
                            path, 
                            polygon,
                            rect, {
                                fill: #fff;
                                stroke: #fff;
                            }
                            
                        }
                    }
                }
            }
            .cmn-color {
                display: block;
                position: absolute;
                top: inherit;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                width: 100%;
                height: 0;
                transition: .3s all ease-out;
            }
            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    .cmn-color {
                        height: 100%;
                    }
                    .box-txt {
                        z-index: 9;
                        color: #fff;
                        position: relative;
                    }
                    &:after {
                        background-image: url(/images/home/home-arrow-hover.png);
                    }
                    &:before {
                        z-index: 9;
                        // background-repeat: no-repeat;
                        // background-size: contain;
                        // background-position: 0 0;
                    }
                }
            }
            &:nth-child(2n+1) {
                margin-left: 0;
                @include responsive(desktop){
                    margin-left: 7px;
                }
            }
            &:nth-child(2n) {
                margin-right: 0;
                @include responsive(desktop){
                    margin-right: 7px;
                }
            }
        }
    }
    .pad-bot-40 {
        padding-bottom: 10px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
    .btn_wrapper.padding-top-40 {
        padding-top: 0;
        @include responsive(desktop){
            padding-top: 15px;
        }
    }
}
.o-home-technology {
    .box-wrapper{
        .box{
            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    border-bottom: 0;
                }
            }
            &:nth-child(1){
                .cmn-color {
                    background: #00b05c;
                }
                // &:hover {
                    
                // }
            }
            &:nth-child(2){
                .cmn-color {
                    background: #00af93;
                }
                // &:hover {
                //     .cmn-color {
                //         background: #00af93;
                //     }
                // }
            }
            &:nth-child(3){
                .cmn-color {
                    background: #0095b5;
                }
                // &:hover {
                    
                // }
            }
            &:nth-child(4){
                .cmn-color {
                    background: #007fab;
                }
                // &:hover {
                //     .cmn-color {
                //         background: #007fab;
                //     }
                // }
            }
            &:nth-child(5){
                .cmn-color {
                    background: #014d95;
                }
                // &:hover {
                    
                // }
            }
            &:nth-child(6){
                .cmn-color {
                    background: #15336b;
                }
                // &:hover {
                    
                // }
            }
        }
    }
}

.box-bg {
    box-shadow: 0px 0px 35px rgba(0,0,0,0.1);
    border-radius: 5px;
    height: 188px;
    margin-bottom: 25px;
    position: relative;
    display: block;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.logo-thumb {
    h4 {
        font-size: 30px;
        // font-weight: 700;
        margin-bottom: 35px;
        margin-top: 0;
    }
    a:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 75%;
        transform: translate(-50%, 0);
        background-image: url(images/home/home-arrow.png);
        width: 22px;
        height: 22px;
    }
}
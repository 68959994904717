.custom-solution {
    $custom-solution: &;

    background-color: #F9F9F9;
    padding: 30px 0 40px;
    @include media-query(992px){
        padding: 60px 0;
    }
    &__heading {
        color: #262E36;
        font-weight: 700;
        position: relative;
        padding-bottom: 20px;
        @include media-query(992px){
            padding-bottom: 0;
        }
    }
    &__sub-heading {
        font-family: 'Poppins' !important;
        font-size: 25px;
        line-height: 34px;
        font-weight: 600;
        color: #474747;
        margin-top: 15px;
        &--home-sub{
            padding: 10px 0 30px;
        }
        &--small{
            font-size: 21px;
            line-height: 32px;
            margin: 25px 0 0;
        }
    }
    &__card-gradient {
        margin-bottom: 15px;
        border: 1px solid #898989;
        display: block;
        position: relative;
        @include media-query(992px){
            cursor: pointer;
            overflow: hidden;
            padding: 2px;
            border: 0;
            margin-bottom: 25px;
        }
        &:before {
                content: '+';
                position: absolute;
                top: 20px;
                right: 10px;
                font-size: 36px;
                line-height: 0;
                font-weight: 300;
                color: #898989;
                z-index: 99;
                display: none;
                @include media-query(992px){
                    display: block;
                }
        }
        &:after {
            content: '';
            position: absolute;
            top: -106px;
            right: 0;
            bottom: 0;
            left: -31px;
            background: #898989;
            width: 430px;
            height: 430px;
            transition: .3s all ease;
            display: none;
            @include media-query(992px){
                display: block;
            }
        }
        &:hover {
            &:after {
                animation: rotation2 3s linear infinite;
                background: -moz-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                background: -webkit-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                background: linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
                display: none;
                @include media-query(992px){
                    display: block;
                }
            }
            &:before {
                color: #35b15c;
            }
        }
    }
    &__card-gradient--active {
        @include media-query(992px){
            background: -moz-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: -webkit-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
        &:before {
                content: '-';
                top: 16px;
                font-size: 44px;
                color: #35b15c;
        }
        &:after {
            @include media-query(992px){
                animation: rotation2 3s linear infinite;
                background: -moz-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                background: -webkit-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                background: linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
            }
        }
    }
    &__card {
        background: #F9F9F9;
        page-break-inside: avoid;
        padding: 20px 40px 20px 15px;
        position: relative;
        z-index: 9;
        @include media-query(992px){
            padding: 20px 20px 20px 20px;
        }
    }
    &__collapse {
        display: flex;
        align-items: center;
        // gap: 15px;
    }
    &__icon {
        display: flex;
        align-items: center;
        height: 50px;
        margin-right: 15px;
        &--fixed-flex {
            flex: 0 0 45px;
            max-width: 45px;
        }
    }
    &__card-heading {
        font-family: 'Poppins' !important;
        font-size: 19px;
        line-height: 31px;
        font-weight: 600;
        margin-bottom: 0;
    }
    &__card-description-wrapper {
        @include media-query(992px){
            display: none;
            margin-top: 15px;
        }
    }
    &__card-description {
        font-family: 'Poppins' !important;
        font-size: 14px;
        line-height: 22px;
        color: #727272;
        display: none;
        @include media-query(992px){
            display: block;
        }
    }
    &__card-link {
        font-family: 'Poppins' !important;
        font-size: 0;
        font-weight: 600;
        color: #333;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include media-query(992px){
            font-size: 14px;
            position: static;
        }
        &::before {
            content: '';
            position: absolute;
            background: url(/images/custom-solutions/r-arrow.png) no-repeat 0 0;
            width: 22px;
            height: 12px;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            display: block;
            @include media-query(992px){
                display: none;
            }
        }
        svg {
            margin-left: 10px;
            display: none;
            @include media-query(992px){
                display: inherit;
            }
        }
    }
    .btn-wrapper-v2--pair .btn-v2 {
        margin-bottom: 0;
        @include media-query(992px){
            margin-top: 25px;
        }
    }
}

@keyframes rotation2 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
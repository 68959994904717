.investor-bnr {
    padding: 40px 0;
    background-image: none;
    min-height: auto;
    @include media-query(992px) {
        padding: 100px 0;
    }
    h1 {
        margin-bottom: 0;
        @include media-query(992px) {
            font-size: 50px;
        }
    }
}

.investor-pdf {
    $investor-pdf: &;
    padding: 40px 0 40px;
    @include media-query(992px) {
        padding: 60px 0 70px;
    }
    h2 {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
    &__wrapper {
        margin-top: 25px;
        @include media-query(992px) {
            margin-top: 0;
        }
        &--small {
            max-width: 800px;
            margin: 0 auto;
        }
        &--multi {
            @include media-query(992px) {
                margin-top: 40px;
            }
        }
    }
    &__list {
        margin-top: 20px;
    }
    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px 15px;
        border: 1px solid #333;
        border-bottom: 0;
        &:last-child {
            border-bottom: 1px solid #333;
        }
    }
    &__name {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a {
            &:hover {
                p {
                    color: #355bbf;
                }
            }
        }
    }
    p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        transition: .3s all ease-in-out;
    }
    &__icon {
        flex: 0 0 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
            display: inline-block;
            img {
                width: 30px;
            }
        }
    }
}

.investor-table{
    margin-bottom: 20px;
}
.investor-table  {
    th{
        padding: 15px 0;
        background-color: #ddf0f8;
        color: #000;
        font-size: 18px;
        width: 33.3%;
    }
}

.investor-table {
    td{
        padding: 14px 0 !important;
        font-size: 14px;
        color: #000;
        font-weight: 500;
        width: 33.3%;
        @include media-query(992px) {
            font-size: 16px;
        }
    }
}
.ipo-heading {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
    @include media-query(992px) {
        font-size: 30px;
        line-height: 40px;
    }
}
.ipo-block-separator {
    margin-bottom: 25px;
    @include media-query(992px) {
        margin-bottom: 35px;
    }
}


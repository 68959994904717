.ar-vr-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box5, 
        .d-trans-pro-content-box6 {
            @include media-query(992px) {
                margin-top: 150px;
            }
        }
    }
}

.ar-vr-tech {
    .o-key-diff-box {
        @include media-query(992px) {
            padding: 30px 5px 30px 20px;
        }
        ul {
            @include media-query(992px) {
                padding-right: 10px;
                flex: 1;
            }
            li {
                font-size: 15px;
                padding: 0 0 8px 25px;
            }
        }
        .pr-0 {
            padding-right: 0;
        }
    }
    .o-key-diff-left {
        @include media-query(992px) {
            padding-top: 20px;
        }
        h3 {
            @include media-query(992px) {
                font-size: 27px;
                line-height: 38px;
            }
        }
    }
}
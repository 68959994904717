.masonry {
     @include responsive(desktop){
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        transition: all .5s ease-in-out;
        column-gap: 30px;
        column-fill: initial;
     }
    .brick{
        margin-bottom: 20px;
        width: 100%;
        @include media-query(778px) {
            display: block;
        }
        @include responsive(desktop){
            margin-bottom: 30px;
            display: inline-block;
        }
        
        display: inline-block;
        vertical-align: top;
        border: 1px solid #D9D9D9;
        p{
            font-size: 16px;
            line-height: 24px;
            font-family: $main-font;
            @include responsive(desktop){
              font-family: $main-font;
              font-size: 19px;
              line-height: 32px;
            }
        }
        a{
          width: 134px;
          height: 46px;
          line-height: 42px!important;
          border: 2px solid #333;
          border-radius: 5px;
          font-size: 18px!important;
          color: #333!important;
            padding: 0;
            text-transform: none;
            color: $blue-color1;
            display: block;
            margin: 0 auto 30px;
            font-weight: 400;
            @include responsive(desktop){
              margin: 0 auto;
            } 
         }
    }
}


// .services-pan{
//     position: relative;
//     background-color: #fff;
//     border-radius: 5px;
//     padding: 30px;
//     text-align: center;
//     @include responsive(desktop){
//         padding: 120px 30px 30px;
//     }

//     &__php {
//         &:before {
//             @include responsive(desktop){
//                 background-image: url('../images/php/php-sprite.png');
//                 width: 75px;
//                 height: 75px;
//             }
//         }
//     }



//     &__php{
//         @include responsive(desktop){
//             &:before{
//                 background-image: url(../images/php/php-sprite.png);
//                 width: 75px;
//                 height: 75px;
//                 content: "";
//                 position: absolute;
//                 left: 50%;
//                 top: 50px;
//                 transform: translate(-50%, 0);
//             }
//         }
//         &-service-01{
//             &:before{
//                 background-position: -80px -169px;
//             }
//         }
//         &-service-02{
//             &:before{
//                 background-position: 0px -83px;
//             }
//         }
//         &-service-03{
//             &:before{
//                 background-position: -255px -83px;
//             }
//         }
//         &-service-04{
//             &:before{
//                 background-position: -76px -83px;
//             }
//         }
//         &-service-05{
//             &:before{
//                 background-position: -330px -83px;
//             }
//         }
//         &-service-06{
//             &:before{
//                 background-position: -430px -83px;
//             }
//         }
//         &-service-07{
//             &:before{
//                 background-position: 0px -168px;
//             }
//         }
//         &-service-08{
//             &:before{
//                 background-position: -168px -83px;
//             }
//         }
//         &-service-09{
//             &:before{
//                 background-position: -168px -83px;
//             }
//         }
//     }



//     &__react{
//         @include responsive(desktop){
//             &:before{
//                 background-image: url(../images/react/react-sprite.png);
//                 width: 100px;
//                 height: 75px;
//                 content: "";
//                 position: absolute;
//                 left: 50%;
//                 top: 50px;
//                 transform: translate(-50%, 0);
//             }
//         }
//     }

//     &__react-service-01 {
//       &:before {
//           background-position: 0 -85px;
//         }
//     }
//     &__react-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//     }
//     &__react-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//     }
//     &__react-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//     }
//     &__react-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//     }
//     &__react-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//     }
//     &__react-service-07 {
//       &:before {
//           background-position: -220px -168px;
//       }
//     }
// }


.services-pan{
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  margin-top:5px;
  margin-bottom: 5px;
//   &:before{
//         content: "";
//         position: absolute;
//         left: 50%;
//         top: 45px;
//         @include responsive(desktop){
//             top:50px;
//         }
//         width: 100px;
//         height: 75px;
//         transform: translate(-50%, 0);
//   }

//   &__django{
//          &:before{
//             background-image: url('../images/django/django-sprite.png');
//           }
//     }
//     &__ios{
//          &:before{
//             background-image: url('../images/ios/ios-sprite.png');
//             width: 68px;
//             height: 68px;
//           }
//     }
//     &__android{
//          &:before{
//             background-image: url('../images/android/android-sprite.png');
//             width: 75px;
//             height: 67px;
//           }
//     }
//     &__reactn{
//          &:before{
//             background-image: url('../images/react-native/react-native-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }
//     &__flutter{
//          &:before{
//             background-image: url('../images/flutter/flutter-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }
//     &__swift{
//          &:before{
//             background-image: url('../images/swift/swift-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }
//     &__ionic{
//          &:before{
//             background-image: url('../images/ionic/ionic-sprite.png');
//             width: 72px;
//             height: 64px;
//           }
//     }
//     &__flask{
//          &:before{
//             background-image: url('../images/flask/flask-sprite.png');
//             width: 72px;
//             height: 67px;
//           }
//     }

//   &__php {
//         &:before {
//                 // background-image: url('../images/php/php-sprite.png');
//                 // width: 75px;
//                 // height: 75px;
//                 display: none;
//         }
//   }

//   &__codeigniter{
//         &:before{
//             background-image: url('../images/codeigniter/codeigniter-sprite.png');
//           }
//     }

//    &__laravel{
//         &:before{
//             background-image: url('../images/laravel/laravel-sprite.png');
//           }
//     }

//     &__symfony{
//         &:before{
//             background-image: url('../images/symfony/symfony-sprite.png');
//           }
//     }

//     &__yii{
//         &:before{
//             background-image: url('../images/yii/yii-sprite.png');
//           }
//     }
//     &__zend{
//         &:before{
//             background-image: url('../images/zend/zend-sprite.png');
//           }
//     }
//     &__rshiny{
//         &:before{
//             background-image: url('../images/rshiny/rshiny-sprite.png');
//           }
//     }

//     &__react{
//         &:before{
//             background-image: url('../images/react/react-sprite.png');
//           }
//     }

//   &__python{
//          &:before{
//             background-image: url('../images/python/python-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }

//   &__wordpress{
//          &:before{
//             background-image: url('../images/wordpress/wordpress-sprite.png');
//             width: 87px;
//             height: 63px;
//       }
//     }

//   &__dotnet{
//          &:before{
//             background-image: url('../images/dotnet/dotnet-sprite.png');
//             width: 87px;
//             height: 63px;
//           }
//     }

//   &__woocommerce{
//          &:before{
//             background-image: url('../images/woocommerce/woocommerce-sprite.png');
//             width: 70px;
//             height: 63px;
//           }
//     }
//   &__magento{
//          &:before{
//             background-image: url('../images/magento/magento-sprite.png');
//             width: 76px;
//             height: 63px;
//           }
//     }
//   &__joomla{
//          &:before{
//             background-image: url('../images/joomla/joomla-sprite.png');
//             width: 88px;
//             height: 63px;
//           }
//     }
//   &__drupal{
//          &:before{
//             background-image: url('../images/drupal/drupal-sprite.png');
//             width: 75px;
//             height: 63px;
//           }
//     }
//   &__shopify{
//          &:before{
//             background-image: url('../images/shopify/shopify-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }

//   &__vuejs{
//          &:before{
//             background-image: url('../images/vue/vuejs-sprite.png');
//             width: 64px;
//             height: 64px;
//           }
//     }
//   &__meanstackjs{
//          &:before{
//             background-image: url('../images/mean/meanstackjs-sprite.png');
//             width: 75px;
//             height: 63px;
//           }
//     }
//   &__mernstackjs{
//          &:before{
//             background-image: url('../images/mern/mernstackjs-sprite.png');
//             width: 75px;
//             height: 63px;
//           }
//     }
//   &__angularjs{
//          &:before{
//             background-image: url('../images/angular/angularjs-sprite.png');
//             width: 64px;
//             height: 63px;
//           }
//     }
//   &__nodejs{
//          &:before{
//             background-image: url('../images/node/nodejs-sprite.png');
//             width: 75px;
//             height: 63px;
//           }
//     }

  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 105px 30px 30px;
  text-align: center;
    // @include responsive(desktop){
    //     padding: 120px 30px 30px;
    // }

    /* django Dev*/

//   &__django-service-01 {
//       &:before {
//           background-position: 0 -85px;
//       }
//   }
//   &__django-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//   }
//   &__django-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//   }
//   &__django-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//   }
//   &__django-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//   }
//   &__django-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//   }


//    /* iOS Dev*/

//   &__ios-service-01 {
//       &:before {
//           background-position: -81px -78px;
//       }
//   }
//   &__ios-service-02 {
//       &:before {
//           background-position: -157px -78px;
//       }
//   }
//   &__ios-service-03 {
//       &:before {
//           background-position: -234px -79px;
//       }
//   }
//   &__ios-service-04 {
//       &:before {
//           background-position: -313px -79px;
//       }
//   }
//   &__ios-service-05 {
//       &:before {
//           background-position: 0 -157px;
//       }
//   }
//   &__ios-service-06 {
//       &:before {
//           background-position: -79px -156px;
//       }
//   }


// /* android Dev*/

//   &__android-service-01 {
//       &:before {
//           background-position: -171px -77px;
//       }
//   }
//   &__android-service-02 {
//       &:before {
//           background-position: -256px -77px;
//       }
//   }
//   &__android-service-03 {
//       &:before {
//           background-position: 0px -154px;
//       }
//   }
//   &__android-service-04 {
//       &:before {
//           background-position: -86px -154px;
//       }
//   }
//   &__android-service-05 {
//       &:before {
//           background-position: -171px -154px;
//       }
//   }
//   &__android-service-06 {
//       &:before {
//           background-position: -256px -154px;
//       }
//   }
//   &__android-service-07 {
//       &:before {
//           background-position: 0 -232px;
//       }
//   }


//   /* react native Dev*/

//   &__reactn-service-01 {
//       &:before {
//           background-position: -75px -74px;
//       }
//   }
//   &__reactn-service-02 {
//       &:before {
//           background-position: -148px -73px;
//       }
//   }
//   &__reactn-service-03 {
//       &:before {
//           background-position: -222px -74px;
//       }
//   }
//   &__reactn-service-04 {
//       &:before {
//           background-position: -296px -74px;
//       }
//   }
//   &__reactn-service-05 {
//       &:before {
//           background-position: 0 -147px;
//       }
//   }
//   &__reactn-service-06 {
//       &:before {
//           background-position: -74px -147px;
//       }
//   }
//   &__reactn-service-07 {
//       &:before {
//           background-position: -148px -147px;
//       }
//   }


//   /* Flutter Dev*/

//   &__flutter-service-01 {
//       &:before {
//           background-position: -75px -75px;
//       }
//   }
//   &__flutter-service-02 {
//       &:before {
//           background-position: -149px -74px;
//       }
//   }
//   &__flutter-service-03 {
//       &:before {
//           background-position: -223px -74px;
//       }
//   }
//   &__flutter-service-04 {
//       &:before {
//           background-position: -297px -74px;
//       }
//   }
//   &__flutter-service-05 {
//       &:before {
//           background-position: 0 -149px;
//       }
//   }
//   &__flutter-service-06 {
//       &:before {
//           background-position: -76px -148px;
//       }
//   }
//   &__flutter-service-07 {
//       &:before {
//           background-position: -148px -148px;
//       }
//   }
//   &__flutter-service-08 {
//       &:before {
//           background-position: -223px -148px;
//       }
//   }


//   /* Swift Dev*/

//   &__swift-service-01 {
//       &:before {
//           background-position: -73px -72px;
//       }
//   }
//   &__swift-service-02 {
//       &:before {
//           background-position: -146px -72px;
//       }
//   }
//   &__swift-service-03 {
//       &:before {
//           background-position: -221px -72px;
//       }
//   }
//   &__swift-service-04 {
//       &:before {
//           background-position: -296px -74px;
//       }
//   }
//   &__swift-service-05 {
//       &:before {
//           background-position: 0 -148px;
//       }
//   }
//   &__swift-service-06 {
//       &:before {
//           background-position: -73px -148px;
//       }
//   }


//    /* Ionic Dev*/

//   &__ionic-service-01 {
//       &:before {
//           background-position: 0 0;
//       }
//   }
//   &__ionic-service-02 {
//       &:before {
//           background-position: -84px 0;
//       }
//   }
//   &__ionic-service-03 {
//       &:before {
//           background-position: -166px 0px;
//       }
//   }
//   &__ionic-service-04 {
//       &:before {
//           background-position: -248px 0;
//       }
//   }
//   &__ionic-service-05 {
//       &:before {
//           background-position: -330px 0;
//       }
//   }
//   &__ionic-service-06 {
//       &:before {
//           background-position: 0 -74px;
//       }
//   }
//   &__ionic-service-07 {
//       &:before {
//           background-position: -85px -74px;
//       }
//   }


//     /* Php Dev*/

//   &__php-service-01 {
//       &:before {
//           background-position: -80px -169px;
//       }
//   }
//   &__php-service-02 {
//       &:before {
//           background-position: 0px -83px;
//       }
//       padding-top: 115px;
//       @include responsive(desktop){
//         padding-top: 120px;
//       }
//   }
//   &__php-service-03 {
//       &:before {
//           background-position: -242px -83px;
//       }
//       padding-top: 95px;
//       @include responsive(desktop){
//         padding-top: 120px;
//       }
//   }
//   &__php-service-04 {
//       &:before {
//           background-position: -76px -83px;
//       }
//       padding-top: 120px;
//       @include responsive(desktop){
//         padding-top: 120px;
//       }
//   }
//   &__php-service-05 {
//       &:before {
//           background-position: -330px -83px;
//       }
//   }
//   &__php-service-06 {
//       &:before {
//           background-position: -430px -83px;
//       }
//   }
//   &__php-service-07 {
//       &:before {
//           background-position: 6px -168px;
//       }
//       padding-top: 115px;
//       @include responsive(desktop){
//         padding-top: 120px;
//       }
//   }
//   &__php-service-08 {
//       &:before {
//           background-position: -168px -83px;
//       }
//   }

//   /* codeigniter Dev*/

//   &__codeigniter-service-01 {
//       &:before {
//           background-position: 0 -85px;
//       }
//   }
//   &__codeigniter-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//   }
//   &__codeigniter-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//   }
//   &__codeigniter-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//   }
//   &__codeigniter-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//   }
//   &__codeigniter-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//   }
//   &__codeigniter-service-07 {
//       &:before {
//           background-position: -220px -168px;
//       }
//   }
//   &__codeigniter-service-08 {
//       &:before {
//           background-position: -330px -168px;
//       }
//   }

//   /* Laravel Dev*/

//   &__laravel-service-01 {
//       &:before {
//           background-position: 0 -85px;
//       }
//   }
//   &__laravel-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//   }
//   &__laravel-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//   }
//   &__laravel-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//   }
//   &__laravel-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//   }
//   &__laravel-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//   }
//   &__laravel-service-07 {
//       &:before {
//           background-position: -220px -168px;
//       }
//   }
//   &__laravel-service-08 {
//       &:before {
//           background-position: -330px -168px;
//       }
//   }
//   &__laravel-service-09 {
//       &:before {
//           background-position: 0 -263px;
//       }
//   }

//   /* Symfony Dev*/

//   &__symfony-service-01 {
//       &:before {
//           background-position: 0 -85px;
//       }
//   }
//   &__symfony-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//   }
//   &__symfony-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//   }
//   &__symfony-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//   }
//   &__symfony-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//   }
//   &__symfony-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//   }
//   &__symfony-service-07 {
//       &:before {
//           background-position: -220px -168px;
//       }
//   }
//   &__symfony-service-08 {
//       &:before {
//           background-position: -330px -168px;
//       }
//   }
//   &__symfony-service-09 {
//       &:before {
//           background-position: 0 -263px;
//       }
//   }


//    /* React Dev*/

//   &__react-service-01 {
//       &:before {
//           background-position: 0 -85px;
//       }
//   }
//   &__react-service-02 {
//       &:before {
//           background-position: -110px -85px;
//       }
//   }
//   &__react-service-03 {
//       &:before {
//           background-position: -220px -85px;
//       }
//   }
//   &__react-service-04 {
//       &:before {
//           background-position: -330px -85px;
//       }
//   }
//   &__react-service-05 {
//       &:before {
//           background-position: 0 -168px;
//       }
//   }
//   &__react-service-06 {
//       &:before {
//           background-position: -110px -168px;
//       }
//   }
//   &__react-service-07 {
//       &:before {
//           background-position: -220px -168px;
//       }
//   }

//   /* cake php Dev*/

//   &__cake-php-service-01 {
//       &:before {
//           background-position: 15px -85px;
//       }
//   }
//   &__cake-php-service-02 {
//       &:before {
//           background-position: -100px -85px;
//       }
//   }
//   &__cake-php-service-03 {
//       &:before {
//           background-position: -200px -85px;
//       }
//   }
//   &__cake-php-service-04 {
//       &:before {
//           background-position: -320px -85px;
//       }
//   }
//   &__cake-php-service-05 {
//       &:before {
//           background-position: 10px -170px;
//       }
//   }
//   &__cake-php-service-06 {
//       &:before {
//           background-position: -430px -85px;
//       }
//   }
//   &__cake-php-service-07 {
//       &:before {
//           background-position: -100px -170px;
//       }
//   }

//   /* python Dev*/

//   &__python-service-01 {
//       &:before {
//           background-position: 0 -78px;
//       }
//   }
//   &__python-service-02 {
//       &:before {
//           background-position: -74px -78px;
//       }
//   }
//   &__python-service-03 {
//       &:before {
//           background-position: -148px -78px;
//       }
//   }
//   &__python-service-04 {
//       &:before {
//           background-position: -222px -78px;
//       }
//   }
//   &__python-service-05 {
//       &:before {
//           background-position: -296px -78px;
//       }
//   }
//   &__python-service-06 {
//       &:before {
//           background-position: -370px -78px;
//       }
//   }
//   &__python-service-07 {
//       &:before {
//           background-position:  0px -152px;
//       }
//   }
//   &__python-service-08 {
//       &:before {
//           background-position: -74px -152px;
//       }
//   }
//   &__python-service-09 {
//       &:before {
//           background-position: -148px -152px;
//       }
//   }

//   /* wordpress Dev*/

//   &__wordpress-service-01 {
//       &:before {
//           background-position: 11px -73px;
//       }
//   }
//   &__wordpress-service-02 {
//       &:before {
//           background-position: -83px -73px;
//       }
//   }
//   &__wordpress-service-03 {
//       &:before {
//           background-position: -194px -73px;
//       }
//   }
//   &__wordpress-service-04 {
//       &:before {
//           background-position: -280px -73px;
//       }
//   }
//   &__wordpress-service-05 {
//       &:before {
//           background-position: -377px -73px;
//       }
//   }
//   &__wordpress-service-06 {
//       &:before {
//           background-position: 11px -146px;
//       }
//   }
//   &__wordpress-service-07 {
//       &:before {
//           background-position: -86px -146px;
//       }
//   }
//   &__wordpress-service-08 {
//       &:before {
//           background-position: -182px -146px;
//       }
//   }
//   &__wordpress-service-09 {
//       &:before {
//           background-position: -281px -146px;
//       }
//   }
//   &__wordpress-service-10 {
//       &:before {
//           background-position: -377px -146px;
//       }
//   }
//   &__wordpress-service-11 {
//       &:before {
//           background-position: 11px -219px;
//       }
//   }

//   /* woocommerce Dev*/

//   &__woocommerce-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__woocommerce-service-02 {
//       &:before {
//           background-position: -80px -73px;
//       }
//   }
//   &__woocommerce-service-03 {
//       &:before {
//           background-position: -161px -73px;
//       }
//   }
//   &__woocommerce-service-04 {
//       &:before {
//           background-position: -242px -73px;
//       }
//   }
//   &__woocommerce-service-05 {
//       &:before {
//           background-position: -322px -73px;
//       }
//   }
//   &__woocommerce-service-06 {
//       &:before {
//           background-position: -402px -73px;
//       }
//   }
//   &__woocommerce-service-07 {
//       &:before {
//           background-position: -0px -146px;
//       }
//   }

//   /* magento Dev*/

//   &__magento-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__magento-service-02 {
//       &:before {
//           background-position: -86px -73px;
//       }
//   }
//   &__magento-service-03 {
//       &:before {
//           background-position: -172px -73px;
//       }
//   }
//   &__magento-service-04 {
//       &:before {
//           background-position: -258px -73px;
//       }
//   }
//   &__magento-service-05 {
//       &:before {
//           background-position: -344px -73px;
//       }
//   }
//   &__magento-service-06 {
//       &:before {
//           background-position: -431px -73px;
//       }
//   }


//   /* Drupal Dev*/

//   &__drupal-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__drupal-service-02 {
//       &:before {
//           background-position: -85px -73px;
//       }
//   }
//   &__drupal-service-03 {
//       &:before {
//           background-position: -170px -73px;
//       }
//   }
//   &__drupal-service-04 {
//       &:before {
//           background-position: -255px -73px;
//       }
//   }
//   &__drupal-service-05 {
//       &:before {
//           background-position: -340px -73px;
//       }
//   }
//   &__drupal-service-06 {
//       &:before {
//           background-position: -0px -146px;
//       }
//   }

//   /* Shopify Dev*/

//   &__shopify-service-01 {
//       &:before {
//           background-position: 0 -81px;
//       }
//   }
//   &__shopify-service-02 {
//       &:before {
//           background-position: -74px -81px;
//       }
//   }
//   &__shopify-service-03 {
//       &:before {
//           background-position: -148px -81px;
//       }
//   }
//   &__shopify-service-04 {
//       &:before {
//           background-position: -222px -81px;
//       }
//   }
//   &__shopify-service-05 {
//       &:before {
//           background-position: -296px -81px;
//       }
//   }
//   &__shopify-service-06 {
//       &:before {
//           background-position: -370px -81px;
//       }
//   }


//   /* Ecommerce Developer Dev*/

//   &__ecommerce-developer-service-01 {
//       &:before {
//           background-position: 0 -81px;
//       }
//   }
//   &__ecommerce-developer-service-02 {
//       &:before {
//           background-position: -74px -81px;
//       }
//   }
//   &__ecommerce-developer-service-03 {
//       &:before {
//           background-position: -148px -81px;
//       }
//   }
//   &__ecommerce-developer-service-04 {
//       &:before {
//           background-position: -222px -81px;
//       }
//   }
//   &__ecommerce-developer-service-05 {
//       &:before {
//           background-position: -296px -81px;
//       }
//   }
//   &__ecommerce-developer-service-06 {
//       &:before {
//           background-position: -370px -81px;
//       }
//   }

//   /* VueJS Dev*/

//   &__vuejs-service-01 {
//       &:before {
//           background-position: 0 -146px;
//       }
//   }
//   &__vuejs-service-02 {
//       &:before {
//           background-position: -74px -146px;
//       }
//   }
//   &__vuejs-service-03 {
//       &:before {
//           background-position: -149px -146px;
//       }
//   }
//   &__vuejs-service-04 {
//       &:before {
//           background-position: -223px -146px;
//       }
//   }
//   &__vuejs-service-05 {
//       &:before {
//           background-position: -297px -146px;
//       }
//   }
//   &__vuejs-service-06 {
//       &:before {
//           background-position: -370px -146px;
//       }
//   }

//   /* Mean Stack JS Dev*/

//   &__meanstackjs-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__meanstackjs-service-02 {
//       &:before {
//           background-position: -85px -73px;
//       }
//   }
//   &__meanstackjs-service-03 {
//       &:before {
//           background-position: -170px -73px;
//       }
//   }
//   &__meanstackjs-service-04 {
//       &:before {
//           background-position: -255px -73px;
//       }
//   }
//   &__meanstackjs-service-05 {
//       &:before {
//           background-position: -340px -73px;
//       }
//   }
//   &__meanstackjs-service-06 {
//       &:before {
//           background-position: 0px -146px;
//       }
//   }

//   /* Mern Stack JS Dev*/

//   &__mernstackjs-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__mernstackjs-service-02 {
//       &:before {
//           background-position: -85px -73px;
//       }
//   }
//   &__mernstackjs-service-03 {
//       &:before {
//           background-position: -170px -73px;
//       }
//   }
//   &__mernstackjs-service-04 {
//       &:before {
//           background-position: -255px -73px;
//       }
//   }
//   &__mernstackjs-service-05 {
//       &:before {
//           background-position: -340px -73px;
//       }
//   }
//   &__mernstackjs-service-06 {
//       &:before {
//           background-position: 0px -146px;
//       }
//   }

//   /* Angular JS Dev*/

//   &__angularjs-service-01 {
//       &:before {
//           background-position: 0 -70px;
//       }
//   }
//   &__angularjs-service-02 {
//       &:before {
//           background-position: -73px -70px;
//       }
//   }
//   &__angularjs-service-03 {
//       &:before {
//           background-position: -146px -70px;
//       }
//   }
//   &__angularjs-service-04 {
//       &:before {
//           background-position: -219px -70px;
//       }
//   }
//   &__angularjs-service-05 {
//       &:before {
//           background-position: -292px -70px;
//       }
//   }

//   /* Node JS Dev*/

//   &__nodejs-service-01 {
//       &:before {
//           background-position: 0 -73px;
//       }
//   }
//   &__nodejs-service-02 {
//       &:before {
//           background-position: -85px -73px;
//       }
//   }
//   &__nodejs-service-03 {
//       &:before {
//           background-position: -170px -73px;
//       }
//   }
//   &__nodejs-service-04 {
//       &:before {
//           background-position: -256px -73px;
//       }
//   }
//   &__nodejs-service-05 {
//       &:before {
//           background-position: -342px -73px;
//       }
//   }
//   &__nodejs-service-06 {
//       &:before {
//           background-position: -428px -73px;
//       }
//   }

// /* dotnet Dev*/

//   &__dotnetn-service-01 {
//       &:before {
//           background: url(../images/dotnet/service-icon-1.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-02 {
//       &:before {
//           background: url(../images/dotnet/service-icon-2.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-03 {
//       &:before {
//           background: url(../images/dotnet/service-icon-2.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-04 {
//       &:before {
//           background: url(../images/dotnet/service-icon-4.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-05 {
//       &:before {
//           background: url(../images/dotnet/service-icon-5.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-06 {
//       &:before {
//           background: url(../images/dotnet/service-icon-6.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-07 {
//       &:before {
//           background: url(../images/dotnet/service-icon-7.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-08 {
//       &:before {
//           background: url(../images/dotnet/service-icon-8.png) no-repeat 0 0!important;
//       }
//   }
//   &__dotnetn-service-09 {
//       &:before {
//           background: url(../images/dotnet/service-icon-9.png) no-repeat 0 0!important;
//       }
//   }

}


.mean-intro {
    @include responsive(desktop){
        min-height: 320px;
    }
  background-color: $white-color;
  margin-bottom: 30px;

  &__min-h-570{
    min-height: 570px!important;
  }

  &__technology {
    padding: 50px 15px;
    @include responsive(desktop){
        padding: 50px;
    }
    text-align: center;

    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      font-family: $main-font;
      @include responsive(desktop){
            font-size: 20px;
            line-height: 32px;
          margin-bottom: 25px;
          font-family: $main-font;
      }
    }
    h5{
        font-size: 20px;
        @include responsive(desktop){
            font-size: 30px;
        }
      margin: 30px 0;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: -20px;
        width: 58px;
        height: 1px;
        background-color: #ccc;
        left: 43%;
        transform: translateY(-45%);
        -webkit-transform: translateY(-45%);
        -moz-transform: translateY(-45%);
        -ms-transform: translateY(-45%);
      }
    }
  }
  border: 1px solid #E0E0E0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
}


.work-holder{
    margin-bottom: 20px;
    .work-pan{
        margin:2%;
        min-height: 420px;
        display: inline-block;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
        -moz-box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
        box-shadow: 0px 0px 5px 0px rgba(143,143,143,1);
        vertical-align: top;
        background-color: $white-color;
        @include responsive(desktop){
          width: 45%;
        }
        .work-img-bg{
            padding: 20px 20px 0 20px;
        }
        .work-text{
            padding: 20px;
            text-align: left;
            h3{
                color: #333;
                font-size: 20px;
                @include responsive(desktop){
                    font-size: 26px;
                    line-height: 32px;
                }
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 10px;
            }
            p{
                color: #333;
                margin-bottom: 10px;
                font-size: 19px;
                line-height: 28px;
            }
            a{
                text-decoration: underline;
                color: #578EDC;
                font-size: 14px;
            }
        }
    }
    .work-pan-auto {
      min-height: auto;
    }
}

.related-tab{
  -webkit-box-shadow: 0px 0px 18px 0px rgba(186,184,186,1);
  -moz-box-shadow: 0px 0px 18px 0px rgba(186,184,186,1);
  box-shadow: 0px 0px 18px 0px rgba(186,184,186,1);
  margin-bottom: 50px;
  height: 400px;
  overflow: hidden;

  /* Style the tab */
  .tab {
    float: left;
    width: 25%;
    height: 445px;
    overflow-y: scroll;
  }

  /* Style the buttons inside the tab */
  .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px 22px 80px;
    width: 100%;
    float: left;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    position: relative;
    font-size: 16px;
    font-family: $main-font;
    font-weight: 500;
    span{
        width: 37px;
        height: 23px;
        position: absolute;
        left: 20px;
        top:50%;
        transform: translate(0, -50%);
        background: url(/images/react/react-sprite.png) no-repeat 0 0;
        &.react-tab-1{
          background-position: 0 -253px;
        }
        &.react-tab-2{
          background-position: -47px -253px;
        }
        &.react-tab-3{
          background-position: -94px -253px;
        }
        &.react-tab-4{
          background-position: -141px -253px;
        }
        &.react-tab-5{
          background-position: -188px -253px;
        }
        &.react-tab-6{
          background-position: -235px -253px;
        }
        &.react-tab-7{
          background-position: -282px -253px;
        }
        &.react-tab-8{
          background-position: -329px -253px;
        }
        &.react-tab-9{
          background-position: -376px -253px;
        }
        &.react-tab-10{
          background-position: 0 -286px;
        }
        &.react-tab-11{
          background-position: -47px -286px;
        }
        &.react-tab-12{
          background-position: -94px -286px;
        }
    }

  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: $white-color;
  }

  /* Create an active/current "tab button" class */
  .tab button.active {
    background-color: $white-color;
  }

  /* Style the tab content */
  .tabcontent {
        float: left;
        width: 75%;
        padding: 90px 50px 90px 26%;
        border-left: none;
        height: 445px;
        background-color: $white-color;
        position: relative;
        text-align: left;
        display: block;
    &:before{
      content: "";
      width: 246px;
      height: 174px;
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translate(0, -50%);
    }
    h5{
      font-size: 24px;
      line-height: 32px;
    }
    p{
      font-size: 19px;
      line-height: 32px;
    }
    &__react-1{
      &:before{
        background: url(/images/react/ts-b.png) no-repeat 50% 50%;
      }
    }
    &__react-2{
      &:before{
        background: url(/images/react/es-b.png) no-repeat 50% 50%;
      }
    }
    &__react-3{
      &:before{
        background: url(/images/react/redux-b.png) no-repeat 50% 50%;
      }
    }
    &__react-4{
      &:before{
        background: url(/images/react/GraphQl-b.png) no-repeat 50% 50%;
      }
    }
    &__react-5{
      &:before{
        background: url(/images/react/apollo-b.png) no-repeat 50% 50%;
      }
    }
    &__react-6{
      &:before{
        background: url(/images/react/Jest-b.png) no-repeat 50% 50%;
      }
    }
    &__react-7{
      &:before{
        background: url(/images/react/Jaasmine-b.png) no-repeat 50% 50%;
      }
    }
    &__react-8{
      &:before{
        background: url(/images/react/Karma-b.png) no-repeat 50% 50%;
      }
    }
    &__react-9{
      &:before{
        background: url(/images/react/next-b.png) no-repeat 50% 50%;
      }
    }
    &__react-10{
      &:before{
        background: url(/images/react/webpack-b.png) no-repeat 50% 50%;
      }
    }
    &__react-11{
      &:before{
        background: url(/images/react/babel-b.png) no-repeat 50% 50%;
      }
    }
    &__react-12{
      &:before{
        background: url(/images/react/flux-b.png) no-repeat 50% 50%;
      }
    }

  }


}

.reasons-capital__blocks{
  position: relative;
}

.our-work {
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding: 80px 0;
    }
    h2 {
        font-size: 30px;
        line-height: 34px;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 61px;
        }
    }
}

.magento-section-1 ul li {
    background: url(/images/magento/greentick.png) no-repeat 0 7px !important;
    padding: 0 0 10px 40px;
    font-size: 20px;
    line-height: 32px;
    font-family: $secondary-font;
}
.skill-what {
    .row {
        padding-bottom: 20px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
    h2 {
        padding-bottom: 10px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
    h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        padding-bottom: 30px;
        @include responsive(desktop){
            font-size: 26px;
            line-height: inherit;
            font-weight: 600;
            padding-bottom: 40px;
        }
    }
    .font-size-20 {
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        @include responsive(desktop){
            font-size: 20px;
            line-height: 33px;
        }
    }
}

.blue-bnr-section-new-modify{
  a{
    &.new-btn-new-modify{
      margin-top: 25px;
      max-width: 300px;
    }
  }
}
.img-resize-php-logo{
  width: 140px;
}
.d-trans-pro-wrapper-new{
  .d-trans-pro-tab-btn{
    &.d-trans-pro-wrapper-new-modify{
      border: 1px solid transparent;
    }
  }
  .d-trans-pro-content-spacing-new{
    &.d-trans-pro-content-spacing-new-laravel{
      margin-top: 100px;
    }
  }
}
.d-trans-pro-wrapper{
  .d-trans-pro-content-box{
    &.d-trans-pro-content-box3{
      &.d-trans-pro-content-spacing-new-magento{
        margin-top: 0;
        @include media-query(992px){
          margin-top: 150px;
        }
      }
    }
   
  }
}
.d-trans-pro-wrapper{
  .d-trans-pro-content-box{
    &.d-trans-pro-content-box4{
      &.d-trans-pro-content-spacing-new-magento{
        margin-top: 0;
        @include media-query(992px){
          margin-top: 150px;
        }
      }
    }
   
  }
}
.d-trans-pro-wrapper{
  .d-trans-pro-content-box{
    &.d-trans-pro-content-box5{
      &.d-trans-pro-content-spacing-new-shopify{
        margin-top: 0;
        @include media-query(992px){
          margin-top: 110px;
        }
      }
    }
   
  }
}
.d-trans-pro-wrapper{
  .d-trans-pro-content-box{
    &.d-trans-pro-content-box4{
      &.d-trans-pro-content-spacing-new-shopify{
        margin-top: 0;
        @include media-query(992px){
          margin-top: 110px;
        }
      }
    }
   
  }
}
.d-trans-pro-wrapper{
  .d-trans-pro-content-box{
    &.d-trans-pro-content-box3{
      &.d-trans-pro-content-spacing-new-shopify{
        margin-top: 0;
        @include media-query(992px){
          margin-top: 110px;
        }
      }
    }
   
  }
}

.survey-thank-pg {
    padding-top: 99px;
    .o-navigation-bar {
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 110;
        width: 100%;
        padding: 20px 0;
    }
    .o-skills-hero__thank-you-heading {
        text-align: center;
        text-align: center;
        background: #10D08E;
        background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
        background: -webkit-linear-gradient(
    -45deg
    , #10D08E 0%, #082653 100%);
        background: linear-gradient(
    135deg
    , #10D08E 0%, #082653 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
        margin-bottom: 40px;
        @include responsive(desktop){
            // margin-bottom: 320px;
        }
        h1.js-hero-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 20px;
            @include responsive(desktop){
                font-size: 40px;
                line-height: 44px;
            }
        }
        p {
            font-size: 16px;
            line-height: 28px;
            color: #fff;
            font-weight: 500;
        }
    }
    .col-md-10 {
        margin: 0 auto;
    }
    .hurry {
        box-shadow: 0px 0px 18px 0px #a6a6a6;
        background: #fff;
        text-align: center;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 40px;
        @include responsive(desktop){
            width: 648px;
            // position: absolute;
            // left: 50%;
            // top: -210px;
            // transform: translate(-50%, -50%);
            padding: 40px 80px 60px;
        }
        &:before {
            display: none;
            @include responsive(desktop){
                // content: "";
                // width: 0;
                // height: 0;
                // border-left: 30px solid transparent;
                // border-right: 30px solid transparent;
                // border-bottom: 30px solid #fff;
                // position: absolute;
                // left: 50%;
                // top: -14px;
                // transform: translate(-50%, -50%);
                // display: block;
            }
        }
    }
    .o-photo-gallery .close {
        top: -15px;
        right: -13px;
        line-height: 29px;
    }
}

.hurry-section {
    position: relative;
    h3 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        color: #333;
        font-weight: 300;
    }
    .js-talk-to-us {
        font-size: 16px;
        line-height: 51px;
        padding: 0 24px;
        border-radius: 10px;
        display: inline-block;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        color: #fff;
        background: #FF5800;
        border-radius: 4px;
        font-size: 16px;
        line-height: 43px;
        text-transform: uppercase;
        border: 2px solid #FF5800;
        padding: 0 27px;
        @include responsive(desktop){
            line-height: 60px;
            font-size: 20px;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 13px;
            background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
            background-size: 7px auto;
            margin-left: 15px;
        }
    }
}
.j-center {
    justify-content: center;
}
.fifty-section {
    .cheaper-better {
        margin-top: 0;
        p {
            span {
                color: #414446;
            }
        }
    }
    .js-talk-to-us {
        display: inline-block;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        color: #fff;
        background: #FF5800;
        border-radius: 4px;
        font-size: 16px;
        line-height: 43px;
        text-transform: uppercase;
        border: 2px solid #FF5800;
        padding: 0 27px;
        &:after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 13px;
            background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
            background-size: 7px auto;
            margin-left: 15px;
        }
    }
    .retention-rate {
        h4 {
            font-size: 26px;
            color: #fff;
            font-weight: normal;
            line-height: 30px;
            padding-bottom: 20px;
            font-weight: 600;
        }
        .c-btn.js-talk-to-us.js-hero-button {
            background-color: transparent;
            color: #fff;
            border-color: #fff;
        }
        img {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .off-video-pan {
        p {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 28px;
        }
        .off-video-txt {
            a {
                &:after {
                    background: url(/images/common/icon-arrow-next-o.svg) 50% 50% no-repeat;
                }
            }
        }
    }
    .post-pan {
        h2 {
            // text-transform: uppercase;
            // margin-top: 15px;
        }
         padding: 20px;
        @include responsive(desktop){
             padding: 40px;
        }

    }
}
.c-bodytext-5 {
    font-size: 12px;
    line-height: 15px;
    font-family: $main-font;
    font-weight: 500;
    color: #414446;
}
.survey-thank-pg-final {
    .o-skills-hero__thank-you-heading {
        margin-bottom: 75px;
    }
}

.case-study-thank-pg {
    .thank-right-top .btn, .cheaper-better .btn, .retention-rate1 .btn {
        display: block;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;
        font-family: $main-font;
        font-weight: 600;
        z-index: 0;
        color: #fff;
        border: 2px solid #FF5800;
        background-color: #FF5800;
        padding: 12px 0px;
        max-width: 240px;
        margin: 0;
        @include responsive(desktop){
            font-size: 18px;
        }
        &:after {
            content: '';
            background: url(/images/common/arrow-white.png) no-repeat;
            width: 20px;
            height: 14px;
            display: inline-block;
            margin-left: 15px;
            z-index: -1;
        }
    }
    .off-video-pan .btn{
        background-color: transparent !important;
        color: #000 !important;
        border-color: #FF5800 !important;
        z-index: 0;
        border: 2px solid #FF5800;
        padding: 12px 0px;
        max-width: 240px;
        font-size: 16px;
        @include responsive(desktop){
            font-size: inherit;
        }
    }
    .post-pan {
        .btn {
            z-index: 0;
            color: #fff;
            border: 2px solid #FF5800;
            background-color: #FF5800;
            padding: 12px 0px;
            max-width: 300px !important;
            margin: 7px 0;
            font-size: 16px;
            @include responsive(desktop){
                font-size: inherit;
            }
            &::after {
                content: '';
                background: url(/images/common/arrow-white.png) no-repeat;
                width: 20px;
                height: 14px;
                display: inline-block;
                margin-left: 15px;
                z-index: -1;
            }
        }
    }
}

.o-casestudies-thankyou {
    text-align: center;
    position: relative;
    padding: 170px 0 50px;
    overflow: hidden;
    background: url(/images/common/thank-you-banner.jpeg) 50% 50% no-repeat;
    margin-bottom: 290px;
}
.case-study-thank-pg {
    .o-casestudies-thankyou {
        padding-top: 75px;
    }
}

.hurry-section-cs {
    .hurry {
        width: 70%;
        position: absolute;
        left: 50%;
        top: -210px;
        transform: translate(-50%, -50%);
        padding: 40px 80px 60px;
        text-align: center;
        box-shadow: 0px 0px 18px 0px #a6a6a6;
        background: #fff;
        text-align: center;
        &:before {
            display: none;
        }
        .btn-orange {
            @include responsive(desktop){
                padding-right: 35px !important;
            }
        }
        h3 {
            font-size: 36px;
            margin-bottom: 10px;
        }
        p {
            font-size: 24px;
            line-height: 40px;
            font-weight: 500;
            font-family: $main-font;
        }
    }
}

.page-404 {
    .o-hero-small {
        padding: 80px 0 0;
    }
    .o-hero-small .js-hero-sub-title {
        color: #333;
        font-size: 24px;
        font-weight: 700;
        margin: 10px 0 0 0;
        font-family: $main-font;
    }
    .o-hero-small__shadow {
        margin: 30px auto;
        text-align: center;
        span {
            display: inline-block;
            width: 100px;
            height: 8px;
            background: #333;
        }
    }
    .common-bg-holder {
        padding: 80px 0 60px;
        p {
            font-size: 16px;
        line-height: 28px;
        font-family: $main-font;
        }
        .c-btn {
            display: inline-block;
            outline: none;
            cursor: pointer;
            font-family: "Montserrat",sans-serif;
            font-weight: 600;
            color: #fff;
            background: #FF5800;
            border-radius: 4px;
            font-size: 16px;
            line-height: 43px;
            text-transform: uppercase;
            border: 2px solid #FF5800;
            padding: 0 27px;
            line-height: 60px;
            font-size: 20px;
            &:after {
                content: '';
                display: inline-block;
                width: 14px;
                height: 24px;
                background-size: 14px auto;
                // vertical-align: text-bottom;
                content: '';
                display: inline-block;
                width: 7px;
                height: 13px;
                background: url(/images/common/icon-arrow-next-bg.svg) 50% 50% no-repeat;
                background-size: 7px auto;
                margin-left: 15px;
            }
        }
    }
}

.st-header{
    background-color: #F7F8FA;
    padding: 20px 0 0px;
    @include responsive(desktop){
        padding: 60px 0 35px;
    }
    h1{
        font-size: 24px;
        line-height: 30px;
        @include responsive(desktop){
            font-size: 45px;
            margin-bottom: 20px;
        }
    }
    p{
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        font-weight: 600;
        @include responsive(desktop){
            font-size: 22px;
            line-height: 34px;
        }
    }
    img{
        float: left;
        margin-right: 20px;
        width: 90px;
        @include responsive(desktop){
            width: auto;
        }
    }
    .download-guide-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        h1 {
            margin: 30px 0 40px;
        }
    }
    .download-guide-icon {
        img {
            margin-right: 0;
        }
    }
    .s1000d-thank-dwnld-btn {
        font-family: 'poppins';
        background-color: #ff5800;
        border: 2px solid #ff5800;
        font-size: 18px;
        color: #fff;
        border-radius: 5px;
        font-weight: 600;
        text-transform: uppercase;
        width: 230px;
        height: 71px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s all ease-in-out;
        svg {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            transition: .3s all ease-in-out;
        }
        &:hover {
            background-color: transparent;
            color: #ff5800;
            svg {
                stroke: #ff5800;
                path {
                    fill: #ff5800;
                    stroke: #ff5800;
                }
            }
        }
    }
}
.st-header-1{
    background: url(/images/common/thankyou-bg.svg) 50% 50% no-repeat;
    text-align: center;
    p{
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        font-weight: 600;
        @include responsive(desktop){
            font-size: 22px;
            line-height: 34px;
        }
    }
}

.s-thank{
    .post-pan{
        ul{
            li{
                a{
                    width: 67%;
                    padding-top: 0;
                    @include responsive(desktop){
                       width: 80%;
                    }
                }
            }
        }
    }
}

.survey-thank-pg-final {
    .thank-you-final-banner {
        padding-top: 35px !important;
        padding-bottom: 5px !important;
        margin-bottom: 50px;
        .o-skills-hero__icon {
            max-width: 80px;
            margin: 0 auto;
        }
        h1.js-hero-title {
            font-size: 32px;
            line-height: 32px;
        }
    }
}

.post-pan ul li {
    @include media-query(992px){
        font-size: 17px;
    }
    a {
        padding-top: 10px;
        @include media-query(992px){
            padding-top: 0;
        }       
    }
}

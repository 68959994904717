.codeigniter-dev {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box2,
        .d-trans-pro-content-box3,
        .d-trans-pro-content-box4 {
            @include media-query(992px) {
                margin-top: 0;
            }
        }
        .d-trans-pro-steps {
            &:before {
                @include media-query(992px) {
                    height: 86%;
                }
            }
        }
    }
}
.hr-btn {
    margin-top: 20px;
    @include media-query(992px) {
        margin-top: 30px;
    }
}
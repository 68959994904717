@mixin clear {
  width: 100%;
  float: left;
}
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin padding($top, $right, $bottom, $left) {
  padding: $top $right $bottom $left;
}
@mixin margin($top, $right, $bottom, $left) {
  margin: $top $right $bottom $left;
}
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}
@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-shadow($left, $top, $radius, $color) {
  box-shadow: $left $top $radius $color;
  -webkit-box-shadow: $left $top $radius $color;
  -moz-box-shadow: $left $top $radius $color;
}

@mixin background($red, $green, $blue, $opacity) {
  @include background(rgba($red, $green, $blue, $opacity));
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
// rorate
@mixin rotate($degree) {
  @include transform(rotate($degree));
}

//@include size(200px);
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Mixin For Media Query = uses @include media-query(960px) {
@mixin media-query($value, $operator: "min-width", $query: "screen") {
  @media #{$query} and (#{$operator}: #{$value}) {
    @content;
  }
}

// Mixin For Media Query = uses @include media-query-max(960px) {
@mixin media-query-max($value, $operator: "max-width", $query: "screen") {
  @media #{$query} and (#{$operator}: #{$value}) {
    @content;
  }
}

/*
    ==========================
    TRANSITION & HELPER MIXINS
    ==========================
*/
@mixin prefixed-transition($time, $ease) {
  -webkit-transition: all $time $ease;
  -moz-transition: all $time $ease;
  -ms-transition: all $time $ease;
  -o-transition: all $time $ease;
  transition: all $time $ease;
}

@mixin prefixed-transition-delay($time) {
  -webkit-transition-delay: $time;
  -moz-transition-delay: $time;
  -ms-transition-delay: $time;
  -o-transition-delay: $time;
  transition-delay: $time;
}

@mixin prefixed-box-shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
  -moz-box-shadow: $x $y $blur $color;
  -ms-box-shadow: $x $y $blur $color;
  -o-box-shadow: $x $y $blur $color;
  box-shadow: $x $y $blur $color;
}

@mixin prefixed-transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin prefixed-border-radius($border-radius) {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}

@mixin prefixed-animation($name, $time, $ease, $delay, $itaration) {
  -webkit-animation: $name $time $ease $delay $itaration;
  -moz-animation: $name $time $ease $delay $itaration;
  -ms-animation: $name $time $ease $delay $itaration;
  -o-animation: $name $time $ease $delay $itaration;
  animation: $name $time $ease $delay $itaration;
}

@mixin prefixed-animation-scale($scale) {
  -moz-transform: scale($scale);
  -webkit-transform: scale($scale);
  transform: scale($scale);
}

// variables
$slowly: all 0.2s ease;

// mixins
@mixin transition($value...) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  transition: $value;
}

@mixin transform($value...) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

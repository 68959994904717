.quickform-holder{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 99999999;
    padding: 0;
    width: 95%;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    @include responsive(desktop){
        width: 754px;
        bottom: 0;
        left: inherit;
        margin: 0;
        top: inherit;
        height: auto;
        overflow: initial;
        right: 3%;
        z-index: 999999;
    }

    .big-girl{
        position: absolute;
        left: -100%;
        top: 60px;
        z-index: 1;
        width: 245px;
        display: none;
        @include responsive(desktop){
           left:7%;
           top: 20px;
        }
    }
    .small-girl{
        float: right;
    }
    .qi-btn-holder{
        text-align: right;
        height: 68px;
        @include responsive(desktop){
            height: 102px;
        }
        .qi-btn{
            display: none;
            vertical-align: top;
            border-radius: 4px;
            background: #3be06e;
            padding: 0;
            position: relative;
            width: 60px;
            margin-bottom: 10px;
            text-align: left;
            text-transform: capitalize;
            box-shadow: 0px 0px 5px 0px rgba(51,51,51,0.5);
            border-radius: 50%;
            height: 60px;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1383c8+0,00cdd2+100 */
            background: rgb(19,131,200); /* Old browsers */
            background: -moz-linear-gradient(-45deg, rgba(19,131,200,1) 0%, rgba(0,205,210,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg, rgba(19,131,200,1) 0%,rgba(0,205,210,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, rgba(19,131,200,1) 0%,rgba(0,205,210,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1383c8', endColorstr='#00cdd2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            // background: url(../../assets/images/common/schedule-icon.png) no-repeat 20px 50%;*/
            bottom: 0;
            @include responsive(desktop){
                width: 45%;
                height: auto;
                border-radius: 4px;
                padding: 10px;
                display: inline-block;
            }
            i{
                width: 30px;
                height: 25px;
                background: url(/images/common/icon-plus.png) no-repeat 50% 50%;
                // border-radius: 50%;
                border-radius: 0;
                position: absolute;
                right: 20px;
                top:15px;
                display: none;
                @include responsive(desktop){
                    display: inline-block;
                }
            }
            &.plus {
                display: inline-block;
                // @include responsive(desktop){
                //     display: none;
                // }
                i {
                    // background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
                    background: url(/images/common/slide-form-minus-white.svg) no-repeat;
                    @include media-query(992px){
                        background: url(/images/common/slide-form-minus-black.svg) no-repeat;
                    }
                }
            }
            img{
                
            }
            span{
                padding-top: 7px;
                padding-left: 10px;
                vertical-align: top;
                font-weight: 500;
                font-size: 20px;
                width: 230px;
                line-height: 26px;
                display: none;
                font-family: $main-font;
                @include responsive(desktop){
                    display: inline-block;
                }
            }
            &.plus{
                position: relative;
                top: 26px;
                z-index: 100;
                background: transparent;
                box-shadow: none;
                i{
                    // background: url(../images/common/black-minus.png) no-repeat 50% 50%;
                    display: block;
                    position: relative;
                    right: -25px;
                    top: 43px;
                    opacity: .8;
                    @include responsive(desktop){
                        right: 15px;
                        top: 100px;
                        position: absolute;
                    }

                }
                img{
                    display: none;
                }
                span{
                    display: none;
                }
                &:after{
                    display: none;
                }
            }
            &:after{
                @include responsive(desktop){
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #2fc9d2;
                    position: absolute;
                    content: "";
                    right: 20px;
                    bottom: -9px;
                    display: none;
                }

            }
        }
    }
    .quickform{
        overflow: auto;
        border-radius: 10px;
        box-shadow: 0px 0px 6px 0px rgba(51,51,51,0.5);
        position: relative;
        bottom: 0;
        display: block;
        @include responsive(desktop){
            display: flex;
        }
        .s-f-left {
            background: #183059;

            @include responsive(desktop){
                float: left;
                width: 50%;
            }

            .calender{
                padding:40px 20px 20px 20px;
                @include responsive(desktop){
                    background: url(/images/common/s-f-left-date.png) no-repeat 0 0;
                    padding:163px 20px 39px 20px;
                }
                .girl{
                    position: absolute;
                    left: 70px;
                    top: 0;
                    height: 388px;
                }
                .text{
                    z-index: 1;
                    position: relative;

                }
                .text1{
                    z-index: 1;
                    position: relative;
                    margin: 0 auto;
                    display: block;
                    padding: 25px 0 7px;

                }
            }

        }
        .s-f-right {
            float: none;
            width: 100%;
            background-color: $white-color;
            position: relative;
            padding-bottom: 15px;
            @include responsive(desktop){
               float: right;
               width: 50%;
            }
            h6{
                color: #325153;
                font-size: 32px;
                line-height: 40px;
                font-weight: 500;
                background: url(/images/common/s-f-right-arrow.png) no-repeat 200px 31px #fff;
                padding: 20px 0 20px 20px;
                background-size: 31px;
            }
            h2{
                color: #325153;
                font-size: 32px;
                line-height: 40px;
                font-weight: 500;
                background: url(/images/common/s-f-right-arrow.png) no-repeat 200px 31px #fff;
                padding: 20px 0 5px 30px;
                background-size: 22px;
            }
            .s-f-right-bottom{
                background-color: #DBF1F3;
                overflow: hidden;
                padding: 20px 20px 6px 20px;
                display: none;
                @include responsive(desktop){
                    display: block;
                }
                ul{
                    li{
                        float: left;
                        width: 41%;
                        min-height: 70px;
                        margin-bottom: 7px;
                        font-size: 13px;
                        line-height: 14px;
                        margin-left: 20px;
                        img{
                            display: block;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

    }
}

.tranparent-bg {
    background: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000000;
    left: 0;
    top: 0px;
    display: none;
    @include media-query(992px){
        z-index: 10000;
    }
}

.consultation {
    background: rgba(7,22,35,.9);
    padding: 16px 20px;
    text-align: center;
    position: relative;
    z-index: 99;
    max-width: 339px;
    margin: 0 auto;
    @include media-query(992px){
        margin: 165px auto 0;
    }
    z-index: 99;
    h5 {
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
        color: #fff;
        text-transform: capitalize;
        margin-bottom: 0;
        @include media-query(992px){
            font-size: 26px;
            line-height: 35px;
        }
        span {
            color: #27EB99;
        }
    }
}
.sliding-form-icon {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px 0 30px;
    @include media-query(992px){
        padding: 25px 0px 0 30px;
    }
    li {
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        margin-bottom: 15px;
        padding-right: 15px;
        @include media-query(992px){
            margin-bottom: 25px;
        }
        i {
            flex: 0 0 31px;
            margin-right: 15px;
        }
        h6{
            font-size: 13px;
            line-height: 17px;
            color: #fff;
            margin-bottom: 0;
        }
    }
}

.form-modal-z-index {
    z-index: 9999999;
}

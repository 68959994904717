/*Pool Setion*/
.pool-section {
  padding: 25px 0 40px;
  border-bottom: 1px solid #e0e0e0;
  @include media-query(992px) {
    padding: 60px 0;
    border-bottom: 0;
  }
  h2 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
    @include media-query(992px) {
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 0;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #3cc065;
      width: 83px;
      height: 7px;
      margin: 0 auto;
      @include media-query(992px) {
        display: none;
      }
    }
  }
  h3 {
    font-family: "Poppins" !important;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #35b15c;
    @include media-query(992px) {
      margin-bottom: 40px;
      font-size: 25px;
      line-height: 34px;
    }
    .heading-underline {
      position: relative;
      display: inline-block;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #35b15c;
        height: 1px;
        width: 100%;
      }
    }
  }
  h4 {
    font-family: "Poppins" !important;
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    @include media-query(992px) {
      font-size: 17px;
      line-height: 30px;
      text-align: left;
      margin-top: 0;
    }
    @include media-query(1280px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .p-box {
    display: flex;
    flex-wrap: wrap;
  }
  .pool-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &.mb-40{
      margin-bottom: 45px;
    }
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0 0;
      position: relative;
      gap: 0 10px;
      height: 45px;
      @include media-query(992px) {
        top: 35px;
        padding: 30px 0;
        height: 73px;
      }
      .owl-dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #e1e1e1;
        &.active {
          width: 13px;
          height: 13px;
          background: #ff5800;
        }
      }
    }
    .splide__pagination__page {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #e1e1e1;
      &.is-active {
          width: 13px;
          height: 13px;
          background: #ff5800;
          transform: scale(1);
      }
    }
    &-list {
      background: #d6e3f0;
      border-radius: 10px;
      border: 1px solid #d6e3f0;
      padding: 2px;
      flex: auto;
      text-align: center;
      background: #fff;
      margin: 7px 3px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      height: 77px;
      transition: 0.3s all ease-in-out;
      @include media-query(992px) {
        flex: inherit;
        padding: 2px;
        overflow: hidden;
        border: none;
        border: none;
        height: inherit;
        transform: translateZ(0);
      }
      &:before {
        display: none;
        @include media-query(992px) {
          display: block;
          content: "";
          position: absolute;
          top: -106px;
          right: 0;
          bottom: 0;
          left: -100px;
          background: #d6e3f0;
          width: 560px;
          height: 500px;
          transition: 0.5s all ease-in-out;
        }
      }
      img {
        display: block;
        height: 100%;
        object-fit: contain;
      }
      small {
        font-family: "Poppins" !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 15px;
      }
      i {
        display: inline-block;
        width: auto;
      }
      .wd-50 {
        width: 45px;
      }
      .rltv-3 {
        position: relative;
        top: -3px;
      }
      a {
        background: #fff;
        color: #333333;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        position: relative;
        z-index: 99;
        @include media-query(992px) {
          padding: 17px 23px;
        }
      }
      &:hover {
        border-color: #508ec8;
        &:before {
          animation: rotation2 3s linear infinite;
          background: -moz-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: -webkit-linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          background: linear-gradient(
            180deg,
            rgba(136, 222, 177, 1) 0%,
            rgba(26, 184, 100, 1) 20%,
            rgba(35, 116, 212, 0.62) 52%,
            rgba(6, 87, 181, 0.62) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
        }
      }
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include media-query(992px) {
      width: 180px;
      margin: 20px 0 0;
    }
    .btn {
      font-family: "Poppins" !important;
      margin: 0 auto;
      font-weight: 500;
      @include media-query(992px) {
        margin: 0;
        padding: 8px;
        font-size: 15px;
      }
    }
  }
  .col-lg-8 {
    @include media-query(992px) {
      width: 69%;
    }
    @include media-query(1280px) {
      width: 66%;
    }
  }
  .col-lg-4 {
    @include media-query(992px) {
      width: 31%;
    }
    @include media-query(1280px) {
      width: 34%;
    }
  }
  .pool-slider {
    position: relative;
    .owl-prev {
      position: absolute;
      left: -30px;
      top: 32px;
      width: auto;
      span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px;
      }
    }
    .owl-next {
      position: absolute;
      right: -30px;
      top: 32px;
      width: auto;
      span {
        font-family: "Poppins" !important;
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        color: #3cc065;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        font-size: 30px;
        line-height: 26px;
      }
    }
  }
  .btn-wrapper-v2--pair .btn-v2 {
    margin-bottom: 0;
    margin-top: 44px;
  }

  &--subpage {
    h2 {
      margin-bottom: 40px;
    }
    .pool-box-list {
      margin: 7px;
    }
  }

  &--cloud-engineering {
    .pool-box {
      &-list {
        i {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
  .splide__pagination {
    position: absolute;
    bottom: -25px;
  }
}

// Platform Section
.o-platform-work {
  $o-platform-work: &;
  border-bottom: 1px solid #dedede;
  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
    &--inner {
      border-top: 1px solid #cae0e7;
    }
  }
  &__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    //margin-bottom: 30px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
      //margin-bottom: 50px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
    &--inner {
      margin-bottom: 20px;
    }
  }
  &__subtitle {
    font-family: Poppins;
    color: #35b15c;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 50px;
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e3f0;
    border-radius: 10px;
    height: 100%;
    background-color: #fff;
    transition: 0.3s all ease-in-out;
    &--noanchor{
      padding: 30px;
      @include media-query(767px) {
        padding: 20px 10px;
      }
    }
    &:hover {
      box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &__anchor {
    display: block;
    text-align: center;
    width: 100%;
    padding: 30px;
    @include media-query(767px) {
      padding: 20px 10px;
    }
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 25px;
    // @include media-query(767px) {
    //   height: 76px;
    // }
    img {
      max-height: 100%;
    }
  }
  &__text {
    font-family: Poppins;
    color: #333333;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0;
  }
  .btn-wrapper-v2--pair {
    .btn-v2 {
      margin: 20px 5px 0;
    }
  }
}

.bg-gray {
  background-color: #f9f9f9;
}
.mb-30 {
  margin-bottom: 30px;
}
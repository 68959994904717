.o-home-service{
    position: relative;
    // min-height: 780px;
    // @include responsive(desktop){
    //     min-height: 820px;
    // }
    .n-gradient-green-blue{
        min-height: 430px;
    }
    padding-bottom: 20px;
    @include responsive(desktop){
        padding-bottom: 50px;
    }
    .btn_wrapper {
        @include responsive(desktop){
            padding-top: 0;
        }
    }
    .home-link.pad-top-20 {
        padding-bottom: 20px;
        padding-top: 10px;
        @include responsive(desktop){
            padding-top: 25px;
            padding-bottom: 35px;
        }
    }
}
.o-home-service_holder{
    width: 90%;
    position: relative;
    // left: 50%;
    // top: 20px;
    // transform: translate(-50%, 0);
    margin: 0 auto;
    margin-top: -410px;
    h3{
        font-size: 20px;
        line-height: 29px;
        color: $white-color;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
        @include responsive(desktop){
            font-size: 36px;
            line-height: 49px;
            padding: 50px 100px 30px;
        }
    }
}
.no-webp {
    .o-home-service {
        .white-box{
            &:after{
                background-image: url(/images/home/home-arrow.png);
            }
            &::before{
                background-image: url(/images/home/home-sprite.png);
            }
        }
    }
}
// .webp {
//     .o-home-service {
//         .white-box{
//             &:after{
//                 background-image: url(/images/webp/home/home-arrow.webp);
//             }
//             &::before{
//                 background-image: url(/images/webp/home/home-sprite.webp);
//             }
//         }
//     }
// }

.o-home-service {
    .white-box{
        background-color: $white-color;
        display: inline-block;
        height: 180px;
        padding: 30px 25px;
        width: 48%;
        margin: 0 4px 10px;
        overflow: hidden;
        border-bottom: 4px solid $green-color;
        @include border-radius(5px);
        position: relative;
        font-size: 16px;
        line-height: 18px;
        color: $black;
        font-weight: 500;
        vertical-align: top;
        @include box-shadow(0, 0, 12px, #a8a8a880);
        position: relative;
        @include responsive(desktop){
            width: 225px;
            height: 225px;
            font-size: 20px;
            font-size: 18px;
            line-height: 24px;
        }
        &__blue-border{
            border-bottom: 4px solid $light-blue-color;
            &:hover{
                @include responsive(desktop){
                    // border-bottom:20px solid $light-blue-color!important;
                }
            }
        }
        &:hover{
            @include responsive(desktop){
                // border-bottom:20px solid $green-color;
            }
        }
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translate(-50%, 0);
            background-image: url(/images/home/home-arrow.png);
            width: 22px;
            height: 22px;
            background-position: 0 0;
        }
        // &:before{
        //     content: '';
        //     position: absolute;
        //     left: 50%;
        //     top: 30px;
        //     transform: translate(-50%, 0);
        //     background-image: url(/images/home/home-sprite.png);
        //     width: 70px;
        //     height: 63px;
        // }
        &:nth-child(2){
            &:before{
                background-position: 0 0;
            }
        }
        &:nth-child(3){
            &:before{
                background-position: -80px 0;
            }
        }
        &:nth-child(4){
            &:before{
                background-position: -160px 0;
            }
        }
        &:nth-child(5){
            &:before{
                background-position: -240px 0;
            }
        }
        .cmn-color {
            display: block;
            position: absolute;
            top: inherit;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            width: 100%;
            height: 0;
            transition: .3s all ease-out;
        }
        i {
            display: block;
            height: 45px;
            position: relative;
            z-index: 1;
            margin-bottom: 15px;
            @include responsive(desktop){
                margin-bottom: 33px;
                height: 55px;
            }
            svg {
                height: 100%;
            }
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                .cmn-color {
                    height: 100%;
                }
                .box-txt {
                    z-index: 9;
                    color: #fff;
                    position: relative;
                }
                &:after {
                    background-image: url(/images/home/home-arrow-hover.png);
                }
                &:before {
                    z-index: 9;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: 0 0;
                }
                i {
                    svg {
                        path,
                        polygon,
                        rect{
                            fill: #fff;
                            stroke: #fff;
                        }
                    }
                }
            }
        }
    }
    .home-link {
        font-size: 16px;
        @include responsive(desktop){
            font-size: 19px;
        }
        &:hover {
            // color: #fff;
            color: #333;
            text-decoration: underline;
        }
    }
    .white-box__choose-01{
        .cmn-color {
            background: #14df7d;
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:before{
                    background-image: url(/images/home/h-service-icon1.png);
                }
                .cmn-color {
                    @include responsive(desktop){
                        background: #14df7d;
                    }
                }
            }
        }
    }
    .white-box__choose-02{
        border-bottom: 4px solid #4a90e2;
        @include responsive(desktop){
            border-bottom: 4px solid #00af93;
        }
        &:before {
            background-image: url(/images/home/b-h-service-icon2.png) !important;
            background-repeat: no-repeat;
            background-position: center center !important;
        }
        .cmn-color {
            background: #4a90e2;
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:before{
                    background-image: url(/images/home/h-service-icon2.png) !important;
                    background-size: 75%;
                    background-position: center center;
                }
            }
            .cmn-color {
                background: #00af93;
            }
        }
    }
    .white-box__choose-03{
        border-bottom: 4px solid #4a90e2;
        @include responsive(desktop){
            border-bottom: 4px solid #0095b5;
        }
        .cmn-color {
            background: #14df7d;
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:before{
                    background-image: url(/images/home/h-service-icon3.png);
                    background-size: 80%;
                    background-position: center center;
                }
                .cmn-color {
                    background: #0095b5;
                }
            }
        }
    }
    .white-box__choose-04{
        border-bottom: 4px solid #14df7d;
        @include responsive(desktop){
            border-bottom: 4px solid #4a90e2;
        }
        .cmn-color {
            background: #4a90e2;
        }
        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:before{
                    background-image: url(/images/home/h-service-icon4.png);
                    background-size: 75%;
                    background-position: center center;
                }
                .cmn-color {
                    background: #4a90e2;
                }
            }
        }
    }
}

.srv-a-box-wrap {
    padding: 0 15px;
    @include responsive(desktop){
        padding: 0;
    }
    a {
        &:nth-child(2n+1) {
            margin-left: 0;
            @include responsive(desktop){
                margin-left: 4px;
            }
        }
        &:nth-child(2n) {
            margin-right: 0;
            @include responsive(desktop){
                margin-right: 4px;
            }
        }
    }
}


.methodologies {
    background: linear-gradient(150deg, rgba(3,128,112,1) 0%, rgba(0,81,127,1) 45%, rgba(2,62,102,1) 64%, rgba(5,27,57,1) 100%);
    padding: 50px 0 30px;
    @include media-query(992px) {
        padding: 60px 0 80px;
    }
    .section-heading-top {
        @include media-query(992px) {
            margin-bottom: 40px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            @include media-query(992px) {
                font-size: 18px;
                line-height: 28px;
                max-width: 815px;
                margin: 0 auto;
            }
        }
    }
    &-box {
        text-align: center;
        background: #fff;
        padding: 30px 20px 5px;
        border-radius: 8px;
        margin-bottom: 20px;
        @include media-query(992px) {
            padding: 50px 20px 30px;
            margin-bottom: 0;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            @include media-query(992px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}

.industries-serve {
    .industries-para {
        font-size: 16px;
        line-height: 24px;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}


.break-column-mod {
    @include media-query(992px) {
        break-after: column;
    }
}

.why-web-development {
    background: linear-gradient(150deg,#00B35D 0,#00517f 45%,#023e66 64%,#051b39 100%);
    .methodologies-box {
        i {
            height: 50px;
            display: flex;
            justify-content: center;
            img {
                display: flex;
                align-items: flex-start;
            }
        }
    }
    .section-heading-top {
        p {
            font-size: 22px;
            line-height: 38px;
            max-width: 93%;
        }
    }
}
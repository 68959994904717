.o-skill-banner--event {
  background-image: url(/images/events/event-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 90px 0 20px;
  @include media-query(992px) {
    padding: 80px 0 8px;
  }
  .o-skill-banner--event-bg {
    position: relative;
    @include media-query(992px) {
      min-height: 350px;
      display: flex;
      align-items: center;
    }
  }
  .o-skill-banner--event-img {
    text-align: center;
    @include media-query(992px) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      text-align: left;
    }
    @include media-query(1340px) {
      width: auto;
    }
    img {
      @include media-query(992px) {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
      @include media-query(1340px) {
        width: auto;
        object-fit: cover;
      }
    }
  }
  .o-skill-banner--event-heading {
    font-family: "Poppins";
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-top: 15px;
    text-align: center;
    @include media-query(992px) {
      margin-top: 0;
      text-align: left;
      padding-right: 250px;
    }
    @include media-query(1280px) {
      font-size: 40px;
      line-height: 52px;
      padding-right: 0;
    }
    @include media-query(1440px) {
      font-size: 44px;
      line-height: 54px;
    }
    @include media-query(1630px) {
      font-size: 51px;
      line-height: 60px;
    }
    span {
      color: #3cc065;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 2px;
        background: rgb(32, 222, 165);
        background: linear-gradient(
          90deg,
          rgba(32, 222, 165, 1) 0%,
          rgba(255, 173, 64, 1) 74%,
          rgba(255, 173, 64, 1) 100%
        );
        @include media-query(992px) {
          bottom: 5px;
          height: 4px;
        }
      }
    }
  }
  .o-skill-banner--event-sub {
    font-family: "Poppins";
    font-weight: 500;
    color: #cee8ff;
    margin-bottom: 0;
    text-align: center;
    @include media-query(992px) {
      font-size: 19px;
      line-height: 30px;
      text-align: left;
      padding-right: 220px;
    }
    @include media-query(1440px) {
      font-size: 22px;
      line-height: 32px;
      padding-right: 0;
    }
  }
}

.event-modal {
  $event-modal: &;

  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 999999;
  &__content {
    background: #fff;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    position: relative;
    width: 96%;
    @include media-query(992px) {
      width: auto;
    }
  }
  &__head {
    padding: 15px 20px 10px 20px;
    text-align: left;
    background: linear-gradient(135deg, #00b35c -22%, #0e5579 47%, #004475 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include media-query(992px) {
        padding: 15px 35px 10px 35px;
    }
  }
  &__heading {
    color: #fff;
    margin-bottom: 5px;
    @include media-query(992px) {
      font-family: "Poppins";
      font-size: 32px;
      line-height: 36px;
    }
  }
  &__date {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
  }
  &__location {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
  }
  &__body {
    padding: 20px 20px 20px 20px;
    @include media-query(992px) {
        padding: 0 20px 0 20px;
    }
  }
  &__location {
    margin-bottom: 0;
  }
  &__close {
    position: absolute;
    top: -11px;
    right: -11px;
    font-size: 34px;
    cursor: pointer;
    z-index: 99;
    background: #fff;
    width: 40px;
    height: 40px;
    line-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
  }
}

.all-event {
  $all-event: &;

  padding: 40px 0 15px;
  @include media-query(992px) {
    padding: 60px 0 35px;
  }
  &__box-wrapper {
    position: relative;
    width: 100%;
    background: linear-gradient(
      -180deg,
      #88deb1 0,
      #1ab864 20%,
      rgba(35, 116, 212, 0.62) 52%,
      rgba(6, 87, 181, 0.62) 100%
    );
    // background: linear-gradient(90deg, #20dea5 0, #ffad40 74%, #ffad40 100%);
    padding: 2px;
    overflow: hidden;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -140px;
      right: 0;
      bottom: 0;
      left: -227px;
      background: #d6e3f0;
      width: 760px;
      height: 700px;
      transition: 0.5s all ease-in-out;
      animation: rotation2 3s linear infinite;
      background: -moz-linear-gradient(
        180deg,
        rgba(136, 222, 177, 1) 0%,
        rgba(26, 184, 100, 1) 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(136, 222, 177, 1) 0%,
        rgba(26, 184, 100, 1) 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(136, 222, 177, 1) 0%,
        rgba(26, 184, 100, 1) 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
    }
    .all-event__box {
      border: none;
    }
  }
  &__box {
    border: 1px solid #d9d9d9;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    padding-bottom: 20px;
    position: relative;
    background: #fff;
    @include media-query(992px) {
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      min-height: 482px;
    }
    &--past-event {
      opacity: 0.7;
      pointer-events: none;
      .all-event__box-btn {
        background: #d8d8d8;
        border-color: #d8d8d8;
        color: #999999;
        svg {
          path {
            fill: #999999;
          }
        }
      }
    }
  }
  .col-lg-4 {
    margin-bottom: 25px;
  }
  &__box-details {
    padding: 20px 20px 0 20px;
    @include media-query(992px) {
      padding: 25px 30px 0 40px;
    }
  }
  &__box-year {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    font-size: 0;
  }
  &__box-img {
    height: 150px;
    position: relative;
    @include media-query(992px) {
      height: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__box-status-current {
    position: absolute;
    bottom: -12px;
    left: 0;
    background: #28a745;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 10px;
  }
  &__box-status-upcoming {
    position: absolute;
    bottom: -12px;
    left: 0;
    background: #fd7e14;
    font-family: Poppins;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 10px;
  }
  &__box-name {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
  }
  &__box-sub {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 26px;
  }
  &__box-btn-wrapper {
    margin-left: 20px;
    margin-top: 20px;
    @include media-query(992px) {
      margin-top: auto;
      margin-left: 40px;
    }
  }
  &__box-btn {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    color: #333;
    border-radius: 5px;
    border: 1px solid #ff5800;
    width: 245px;
    padding: 15px 0;
    display: block;
    text-align: center;
    transition: 0.3s all ease-in-out;
    svg {
      transition: 0.3s all ease-in-out;
      margin-left: 15px;
    }
    &:hover {
      background: #ff5800;
      color: #fff;
      svg {
        transform: translateX(10px);
        path {
          fill: #fff;
        }
      }
    }
  }
  &__message {
    min-height: 300px;
    font-family: Poppins;
    font-size: 32px;
    color: #9d9d9d;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.event-form {
  position: relative;
  top: inherit;
  right: inherit;
  left: inherit;
  max-width: none;
  box-shadow: none;
  background: none;
  .form-panel {
    padding: 0;
    @include media-query(992px) {
      padding: 20px 0;
    }
  }
  .o-contact-form__contact-form {
    padding: 0;
    @include media-query(992px) {
      padding: 0 15px;
    }
  }
}
.d-trans-pro-content-box {
  .process-content-order-list {
    li {
      font-size: 18px;
      margin-bottom: 12px;
      position: relative;
      padding-left: 30px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
        height: 16px;
        width: 16px;
      }
    }
  }
}
.cloude-computing-tech-stack {
  .tech-stack-oreder-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    li {
      width: 100%;
      @include media-query(480px) {
        width: 50%;
      }
      @include media-query(992px) {
        width: 25%;
      }
      .tech-stack-oreder-list-iteam {
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        border-radius: 5px;
        padding: 15px;
        margin: 10px;
        font-weight: 500;
        color: #000;
        font-size: 16px;
        position: relative;
        padding-left: 35px;

        @include media-query(992px) {
          min-height: 78px;
        }
        &::before {
          content: "";
          position: absolute;
          left: 12px;
          top: 18px;
          background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}
.cloude-case-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media-query(992px) {
    flex-wrap: nowrap;
  }
  .iot-case-wrapper-box {
    border-bottom-width: 1px;
    box-shadow: unset;
    width: 100%;
    @include media-query(992px) {
      width: 30.33%;
    }
  }
}
.clode-computing-deployment-models-row {
  width: 100%;
  margin: auto;
  @include media-query(992px) {
    width: 90%;
  }
  .clode-computing-deployment-models-box {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    @include media-query(992px) {
      padding: 30px 30px 30px 20px;
      flex-wrap: nowrap;
    }
    .ml-cases-img {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      @include media-query(992px) {
        width: 22%;
        margin-right: 30px;
        margin-bottom: 0;
      }
      img {
        width: 70px;
        @include media-query(992px) {
          width: 100%;
        }
      }
    }
    .clode-computing-right-side-text {
      width: 100%;
      @include media-query(992px) {
        width: 70%;
      }
    }
  }
  &--cloud-engineering {
    .clode-computing-deployment-models-box {
      border-left-width: 6px;
      .ml-cases-img {
        @include media-query(992px) {
          width: 15%;
        }
      }
    }
  }
}
.cloud-computing-development-process-section {
  @include media-query(992px) {
    height: 800px;
  }
  .cloud-computing-development-process {
    .d-trans-pro-steps {
      &.d-trans-pro-steps-new {
        &::before {
          height: 98%;
          @include media-query(992px) {
            height: 40%;
          }
        }
      }
    }
    .cloud-computing-development-process-box {
      margin-top: 0;
    }
  }
}
.cloud-computing-service-solution {
  position: relative;
  z-index: 2;
}
.robust-new-modify {
  padding-bottom: 50px;
  @include media-query(992px) {
    padding-bottom: 70px;
  }
}
.margin-bottom-30 {
  margin-bottom: 15px;
  @include media-query(992px) {
    margin-bottom: 30px;
  }
}

.ratecard {
    background: #091B38;
}
.logo-section {
    padding: 25px 0;
    background: #fff;
}
.rate-card-form-section {
    padding: 30px 0;
    @media only screen and (min-width: 992px) {
        padding: 80px 0;
    }
    .rate-card-form-wrapper {
        @media only screen and (min-width: 992px) {
            max-width: 860px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            border-radius: 10px;
            overflow: hidden;
        }
        .rate-card-form-left {
            flex: 0 0 50%;
            position: relative;
            @media only screen and (min-width: 992px) {
                overflow: hidden;
                padding: 50px 40px 50px 50px;
                background: linear-gradient(172deg, rgba(5,117,104,1) 0%, rgba(3,71,109,1) 43%, rgba(15,39,78,1) 100%);
            }
            h3 {
                margin-bottom: 10px;
                color: #fff;
                font-size: 26px;
                line-height: 35px;
                font-weight: 700;
                @media only screen and (min-width: 992px) {
                    font-size: 28px;
                    line-height: 45px;
                }
            }
            p {
                color: #fff;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                width: 50%;
                @media only screen and (min-width: 992px) {
                    font-size: 18px;
                    line-height: 27px;
                    width: 100%;
                }
                span {
                    color: #2DBE70;
                }
            }
            &:before {
                content: '';
                position: absolute;
                background: url(/images/rate-card/download-img.svg) no-repeat;
                width: 170px;
                height: 170px;
                bottom: -40px;
                right: -15px;
                background-size: contain;
                @media only screen and (min-width: 992px) {
                    width: 204px;
                    height: 204px;
                    left: 40px;
                    right: inherit;
                    bottom: 70px;
                }
            }
        }
        .rate-card-form-right {
            flex: 0 0 50%;
            background: #091B38;
            border: 0;
            @media only screen and (min-width: 992px) {
                padding: 50px 50px 70px;
                overflow: hidden;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border: 1px solid #284678;
                border-left: 0;
            }
            form {
                input {
                    background-color: transparent;
                    border: 0;
                    border-bottom: 1px solid #fff;
                    padding: 15px 15px 15px 35px;
                    margin-bottom: 10px;
                    width: 100%;
                    color: #fff;
                    &::placeholder {
                        opacity: 1;
                        color: #fff;
                        font-weight: 400;
                    }
                }
                .user {
                    background: url(/images/rate-card/user-icon.svg) no-repeat 0 50%;
                }
                .email {
                    background: url(/images/rate-card/email-icon.svg) no-repeat 0 50%;
                }
                .mobile {
                    background: url(/images/rate-card/mobile-icon.svg) no-repeat 0 50%;
                }
                .btn-orange {
                    width: 100%;
                    max-width: inherit;
                    padding: 13px;
                    font-size: 14px;
                    margin-top: 20px;
                    margin-bottom: 15px;
                    @media only screen and (min-width: 992px) {
                        margin-top: 25px;
                        margin-bottom: 20px;
                    }
                }
            }
            p {
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                margin-top: 5px;
                a {
                    color: #4A90E2;
                }
            }
        }
    }
}
.u-width {
  $uWidth: &;

  &--65 {
    width: map-get($map: $values, $key: 65);
  }
  &--36-i {
    width: map-get($map: $values, $key: 36) !important;
  }
}

// Engagement Banner Start
.o-engagement-banner{
    $o-engagement-banner : &;
    position: relative;
    background-color: #052C58;
    &:before{
        position: absolute;
        content: "";
        width: 70%;
        height: 93%;
        background: #060a25;
        background: radial-gradient(circle,rgba(6,10,37,.7) 0,rgba(5,44,88,0) 40%);
        border-radius: 50%;
        left: 0;
        top: 0;
    }
    &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(/images/new-home-banner/banner-gradient-inverse.png),url(/images/new-home-banner/banner-gradient.png);
        background-repeat: no-repeat;
        background-position: 100% 0,0 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .z-1{
        z-index: 1;
    }
    .col-55{
        width: 100%;
        max-width: 100%;
        @include media-query(992px) {
            flex: 0 0 auto;
            width: 55%;
        }
    }
    .col-45{
        width: 100%;
        max-width: 100%;
        @include media-query(992px) {
            flex: 0 0 auto;
            width: 45%;
        }
    }
    .col-52{
        width: 100%;
        max-width: 100%;
        @include media-query(992px) {
            flex: 0 0 auto;
            width: 52%;
            padding-right: 0;
        }
    }
    .col-48{
        width: 100%;
        max-width: 100%;
        @include media-query(992px) {
            flex: 0 0 auto;
            width: 48%;
            padding-left: 0;
        }
    }
    &__spacing{
        padding: 110px 0 30px;
        @include media-query(992px) {
            padding: 150px 0 40px;
        }
        &--how-work{
            @include media-query(992px) {
                padding: 150px 0 60px;
            }
        }
        &--dedicated{
            @include media-query(992px) {
                padding: 150px 0 0;
            }
        }
    }
    &__title{
        font-family: 'Poppins';
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: $white-color;
        margin-bottom: 15px;
        text-align: center;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            text-align: left;
        }
    }
    &__subtitle{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        color: #3CC065;
        text-align: center;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 64px;
            text-align: left;
        }
        &--how-we{
            line-height: 40px;
        }
        &--dedicated{
            margin-bottom: 20px;
            font-weight: 600;
            color: $white-color;
            @include media-query(992px) {
                font-size: 31px;
                line-height: 38px;
                margin-bottom: 40px;
            }
        }
    }
    &__subheading{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        color: $white-color;
        text-align: center;
        margin-bottom: 12px;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 40px;
            text-align: left;
        }
    }
    &__subcontent{
        font-family: 'Poppins';
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: $white-color;
        text-align: center;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 26px;
            text-align: left;
        }
    }
    &__bannerimage{
        margin: 0 auto 30px;
        display: block;
        @include media-query(992px) {
            margin: 0 auto;
        }
        &--dedicated{
            @include media-query(992px) {
               // margin:0 0 0 -30px;
            }
        }
    }
    &__reviewicon{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url(/images/new-home-banner/banner-left-arrow.svg),url(/images/new-home-banner/banner-right-arrow.svg);
        background-repeat: no-repeat;
        background-position: 0 0,100% 0;
        background-size: auto 100%;
        padding: 30px 25px 20px;
        margin-bottom: 37px;
    }
    &__icontitle{
        font-family: 'Poppins';
        font-size: 17px;
        line-height: 26px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
        color: $white-color;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 31px;
        }
    }
    &__iconlist{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__iconitem{
        padding: 10px;
        max-width: 50%;
        @include media-query(992px) {
            max-width: 25%;
        }
    }
    &__btn{
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 15px;
        @include media-query(992px) {
            max-width: 280px;
        }
        &--autowidth{
            @include media-query(992px) {
                max-width: fit-content;
                padding: 15px 30px;
                text-transform: capitalize;
                margin-bottom: 80px;
            }
        }
    }
}
// Engagement Banner Ends
// Core Engagement Start
.o-core-engagement{
    $o-core-engagement : &;
    position: relative;
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 7%;
        left: 0;
        top: 0;
        right: 0;
        background-color: #052c58;
    }
    &__wrapper{
        position: relative;
        padding: 25px 10px 10px;
        background-color: $white-color;
        position: relative;
        width: 100%;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid #b2bac7;
        @include media-query(992px) {
            padding: 35px 20px 20px;
        }
        @include media-query(1180px) {
            padding: 65px 80px 30px;
        }
        &:before{
            position: absolute;
            content: "";
            width: 1px;
            height: 25%;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(178,186,199,1) 100%);
            left: 0;
            top: 5px;
        }
        &:after{
            position: absolute;
            content: "";
            width: 1px;
            height: 25%;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(178,186,199,1) 100%);
            right: 0;
            top: 5px;
        }
    }
    &__largetitle{
        font-family: 'Poppins';
        font-size: 26px;
        line-height: 32px;
        font-weight: 700;
        color: #262E36;
        margin-bottom: 20px;
        text-align: center;
        @include media-query(992px) {
            font-size: 46px;
            line-height: 60px;
            margin-bottom: 35px;
        }
    }
    &__smalltitle{
        font-family: 'Poppins';
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        color: #262E36;
        margin-bottom: 20px;
        margin-top: 25px;
        text-align: center;
        @include media-query(768px) {
            margin-top: 60px;
            margin-bottom: 30px;
        }
        @include media-query(992px) {
            font-size: 34px;
            line-height: 48px;
            margin-bottom: 44px;
            margin-top: 100px;
        } 
    }
    &__corewrapper{
        border-radius: 10px;
        box-shadow: 0 4px 40px 0 rgba(0,0,0,.12);     
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        @include media-query(768px) {
            height: 100%;
            margin-bottom: 0;
        }
    }
    &__coreheading{
        position: relative;
        padding: 30px 18px 20px;
        display: flex;
        flex-direction: row;
        background: #355BBF;
        justify-content: flex-start;
        border-radius: 5px 5px 0 0;
        @include media-query(1024px) {
            padding: 26px 25px 26px;
        }
        i{
            width: 50px;
            flex: 0 0 50px;
            @include media-query(1024px) {
                display: flex;
                align-items: center;
            }
        }
    }
    &__popular{
        position: absolute;
        left:25px;
        top: -15px;
        background: #3CC065;
        font-family: 'Poppins';
        color: $white-color;
        font-size: 12px;
        line-height: 30px;
        font-weight: 600;
        padding: 0 19px;
        text-transform: uppercase;
    }
    &__corecontent{
        padding: 16px 30px 30px; 
        display: block;
        @include media-query(768px) {
            display: flex;
            flex-direction: column;
            height: 87%;
        }
        @include media-query(1024px) {
            height: 80%;
        }
        @include media-query(1180px) {
            display: block;
            height: auto;
        }
    }
    &__coretitle{
        font-family: 'Poppins';
        font-size: 20px;
        line-height: 30px;
        color: $white-color;
        font-weight: 700;
        margin-left: 11px;
        margin-bottom: 0;
        flex: 0 0 1;
        @include media-query(992px) {
            font-size: 31px;
            line-height: 40px;
            margin-left: 20px;
        }
    }
    &__innertitle{
        font-family: 'Poppins';
        color: $black;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        i{
            margin-right: 16px;
        }
    }
    &__innercontent{
        font-family: 'Poppins';
        color: $black;
        font-size: 16px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 25px;
        &:nth-child(6){
            margin-bottom: 35px;
        }
    }
    &__innerbtn{
        text-transform: capitalize;
        font-size: 19px;
        max-width: 100%;
        padding: 15px;
        margin-top: auto;
    }
    &__moreitem{
        margin-bottom: 40px;
        padding-bottom: 40px;
        background-image: linear-gradient(to right, #b2bac7 40%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 12px 1px;
        background-repeat: repeat-x;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
        &:last-child{
            background-image: none;
            padding: 0;
        }
    }
    &__itemtitle{
        font-family: 'Poppins';
        color: $black;
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        &:after{
            position: absolute;
            content: "";
            width: 64px;
            height: 4px;
            background: #3CC065;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            @include media-query(992px) {
                right: inherit;
            }
        }
    }
    &__itemcontent{
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        margin-bottom: 5px;
    }
    &__btn-gradient{
        position: relative;
        display: inline-flex;
        padding: 2px;
        border-radius: 7px;
        margin:25px auto 0;
        overflow: hidden;
        cursor: pointer;
        &:after{
            content: '';
            position: absolute;
            top: -200px;
            right: 0;
            bottom: 0;
            left: -50px;
            background:#ff5800;
            width: 500px;
            height: 500px;
            -webkit-transition: .3s all ease;
            transition: .3s all ease;
        }
        &:hover{
            &:after{
                animation: rotation2 3s linear infinite;
                background: -webkit-gradient(linear,left top,left bottom,from(#88deb1),color-stop(20%,#1ab864),color-stop(52%,rgba(35,116,212,.62)),to(rgba(6,87,181,.62)));
                background: linear-gradient(180deg,#88deb1 0,#1ab864 20%,rgba(35,116,212,.62) 52%,rgba(6,87,181,.62) 100%);
            }
        }
    }
    &__itembtn{
        position: relative;
        max-width: 100%;
        width: 100%;
        font-size: 15px;
        text-transform: capitalize;
        color: $black;
        padding: 10px 20px;
        background-color: $white-color;
        z-index: 1;
        @include media-query(768px) {
            font-size: 17px;
            max-width: fit-content;
            width: auto;
            padding: 10px 30px;
        }
    }
}
// Core Engagement Ends
// Compare Sheet Start
.o-compare-sheet{
    $o-compare-sheet : &;
    background-color: #F6F6F6;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        font-family: 'Poppins';
        font-size: 34px;
        line-height: 42px;
        font-weight: 700;
        color: #262E36;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            margin-bottom: 30px;
        }
    }
    &__table{
        position: relative;
        width: 100%;
        margin-bottom: 68px;
    }
    &__thead{
        margin-bottom: 20px;
        border: 1px solid #bbc6d4;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 10px 30px 0px rgba(0,0,0,0.1);
        @include media-query(768px) {
            margin-bottom: 0;
            border:none;
            border-radius: none;
            box-shadow: none;
        }
    }
    &__tbody{
        .o-compare-sheet__tr{
            margin-bottom: 30px;
            border-radius: 10px;
            overflow: hidden;
            border: 1px solid #bbc6d4;
            border-bottom: none;
            box-shadow: 0 10px 30px 0px rgba(0,0,0,0.1);
        }
        @include media-query(768px) {
            box-shadow: 0 10px 30px 0px rgba(0,0,0,0.1);
            overflow: hidden;
            border-radius: 0 0 10px 10px;
            .o-compare-sheet__tr{
                margin-bottom: 0;
                border-radius: 0;
                overflow: visible;
                border:none;
                box-shadow: none;
                &:first-child{
                    .o-compare-sheet__td{
                        &:first-child{
                            border-radius: 10px 0 0 0;
                        }
                    }
                }
                &:last-child{
                    .o-compare-sheet__td{
                        &:first-child{
                            border-radius: 0 0 0 10px;
                            overflow: hidden;
                        }
                        &:last-child{
                            border-radius:  0 0 10px 0;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
    &__tr{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        @include media-query(768px) {
            flex-direction: row;
        }
    }
    &__th{
        font-family: 'Poppins';
        width: 100%;
        padding: 15px 10px 10px;
        background-color: #F3F4F9;
        font-size: 17px;
        line-height: 25px;
        color: #262E36;
        font-weight: 600;
        border-bottom: 1px solid #bbc6d4;
        text-align: center;
        @include media-query(768px) {
            width: 19.5%;
            border-right: 1px solid #bbc6d4;
            box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
            text-align: center;
        }
        @include media-query(1180px) {
            width: 19%;
            font-size: 21px;
            line-height: 30px;
            padding: 25px 25px 15px;
        }
        &:first-child{
            width: 100%;
            background-color: transparent;
            padding: 0;
            font-size: 20px;
            line-height: 30px;
            padding: 10px;
            border-right: none;
            box-shadow: none;
            border-bottom: none;
            text-align: center;
            @include media-query(768px) {
                width: 22%;
                padding: 10px 10px 0 0;
                text-align: left;
            }
            @include media-query(1180px) {
                width: 24%;
                font-size: 26px;
                line-height: 38px;
            }
        }
        &:nth-child(2){
            border-radius: 10px 0 0 0; 
        }
        &:last-child{
            border-radius: 0 10px 0 0;
            border-right: none;
            border-bottom: none;
            @include media-query(768px) {
                border-bottom: 1px solid #bbc6d4;
            }
        }
        i{
            display: block;
            margin-bottom: 7px;
        }
    }
    &__td{
        font-family: 'Poppins';
        color: $black;
        font-size: 16px;
        line-height: 22px;
        padding: 10px;
        font-weight: 500;
        width: 100%;
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: $white-color;
        border-bottom: 1px solid #bbc6d4;
        @include media-query(768px) {
            font-size: 15px;
            width: 19.5%;
            border-right: 1px solid #bbc6d4;
        }
        @include media-query(1180px) {
            font-size: 16px;
            line-height: 27px;
            padding: 25px;
            width: 19%;
        }
        &:first-child{
            width: 100%;
            background: #355BBF;
            color: $white-color;
            font-weight: 600;
            border-bottom: 1px solid #2746a0;
            @include media-query(768px) {
                width: 22%;
            }
            @include media-query(1180px) {
                width: 24%;
            }
        }
        i{
            margin-right: 7px;
        }
    }
    &__moretitle{
        font-family: 'Poppins';
        color: $black-color;
        font-size: 25px;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 23px;
    }
    &__freebtn{
        max-width: 409px;
        width: 100%;
        padding: 15px;
        text-transform: capitalize;
    }
}
// Compare Sheet Ends
// Transform Idea Start
.o-idea-transform{
    $o-idea-transform : &;
    background-color: #052c58;
    background-image: url(/images/how-we-help-you/how-we-help-graphic.png);
    background-repeat: no-repeat;
    background-position: top left;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__wrapper{
        max-width: 860px;
        width: 100%;
        margin: auto;
        text-align: center;
    }
    &__heading{
        font-family: 'Poppins';
        font-size: 34px;
        line-height: 42px;
        font-weight: 700;
        color: $white-color;
        margin-bottom: 20px;
        margin-top: 28px;
        text-align: center;
        text-transform: capitalize;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            margin-bottom: 30px;
        } 
        &--black{
            color: #262E36;
        }
    }
    &__content{
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: $white-color;
        margin-bottom: 40px;
        text-align: center;
        &--black{
            color: #262E36;
        }
    }
    &__btnarea{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include media-query(992px) {
            flex-direction: row;
        }
    }
    &__btn{
        text-transform: capitalize;
        font-size: 17px;
        padding: 15px;
        margin:10px 0;
        width: 100%;
        @include media-query(768px) {
            width: 333px;
        }
        @include media-query(1180px) {
            margin:0 8px;
        }
        &--white{
            color: $white-color;
            border-color: $white-color;
            &:hover{
                background: $white-color;
                svg{
                    g{
                        fill: $black-color;
                    }
                }
            }
        }
        &--black{
            border-color: #FF5800;
            &:hover{
                color: #FF5800;
                background: $white-color;
                svg{
                    g{
                        fill: #FF5800;
                    }
                }
            }
        }
    }
    &--no-background{
        background-color: #f6f6f6;
        background-image: none;
    }
}
// Transform Idea Ends
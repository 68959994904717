.no-webp {
    .o-industrial-experience__listed-items ul li:before {
        background-image: url(/images/common/common-sprite.png);
    }
}
.webp {
    .o-industrial-experience__listed-items ul li:before {
        background-image: url(/images/webp/common/common-sprite.webp);
    }
}

.o-industrial-experience{
    text-align: center;
    position: relative;
    display: none;
    @include responsive(desktop){
        display: block;
    }
    .subheading{
        font-size: 20px;
        line-height: 32px;
        font-family: $main-font;
        font-weight: 500;
        @include responsive(desktop){
            font-size: 30px;
            line-height: 36px;
            color: #333;
            margin-bottom: 20px;
            margin: 15px 0 0;
        }
    }

    &__listed-items{

        ul{
            li{
                display: inline-block;
                padding: 15px;
                vertical-align: top;
                position: relative;
                // padding-top: 50px;
                margin-bottom: 35px;
                width:40%;
                @include responsive(desktop){
                    width: 24%;
                }
                &:before{
                    content: '';
                    position: absolute;
                    left: 0px;
                    width: 50px;
                    height: 50px;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    display: none;
                }
                img{
                    margin-bottom: 20px;
                }
                &:nth-child(1){
                    &:before{
                        background-position: 0 0;
                    }
                }
                &:nth-child(2){
                    &:before{
                        background-position: -60px 0;
                    }
                }
                &:nth-child(3){
                    &:before{
                        background-position: -120px 0;
                    }
                }
                &:nth-child(4){
                    &:before{
                        background-position: -180px 0;
                    }
                }
                &:nth-child(5){
                    &:before{
                        background-position: -240px 0;
                    }
                }
                &:nth-child(6){
                    &:before{
                        background-position: -300px 0;
                    }
                }
                &:nth-child(7){
                    &:before{
                        background-position: 0px -60px;
                    }
                }
                &:nth-child(8){
                    &:before{
                        background-position: -60px -60px;
                    }
                }
                &:nth-child(9){
                    &:before{
                        background-position: -120px -60px;
                    }
                }
                &:nth-child(10){
                    &:before{
                        background-position: -180px -60px;
                    }
                }
                &:nth-child(11){
                    &:before{
                        background-position: -240px -60px;
                    }
                }
                &:nth-child(12){
                    &:before{
                        background-position: -300px -60px;
                    }
                }
                p{
                    font-size: 15px;
                    color: $black-color;
                    margin-bottom: 0;
                    font-weight: 500;
                    font-family: $main-font !important;
                    @include responsive(desktop){
                        font-size: 19px;
                    }
                    a {
                        color: $black-color;
                    }
                }

            }
        }

    }
}

.hm-page {
    .o-industrial-experience__listed-items ul li p {
        font-family: $main-font !important;
    }
}


.industry-banner {
    .header_right_pan {
        .skill-page-mod {
            li {
             background: none;
             border: none;
             border-radius: 0;
             margin: 0;
                 img {
                     width: auto;
                 }
             }
       }
    }
    
}
/* Global Utility Style*/

// Spacing
.mx-auto,
.ml-auto {
  margin-left: auto;
}
.mx-auto,
.mr-auto {
  margin-right: auto;
}
.mr-lg-0 {
  @media (min-width: 992px) {
    margin-right: 0;
  }
}
// Size
.w-75 {
  width: 75%;
}
// Display
// .d-none{
//     display: none;
// }
// .d-block{
//     display: block;
// }
// .d-flex{
//     display: flex;
// }
// .d-lg-flex{
//     @media (min-width: 992px) {
//         display: flex;
//     }
// }
// Contet Placement
.align-items-center {
  align-items: center;
}

// IMG
.img-fluid {
  max-width: 100%;
  height: auto;
}

// Contet placement Order

.order-lg-2 {
  @media (min-width: 992px) {
    order: 2;
  }
}

/** CSR Page **/

/* Section Global */
.o-section {
  $self: &;
  //
  padding-top: 50px;
  padding-bottom: 50px;
  @media (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &--think-green {
    @media (min-width: 992px) {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 50px;
    }
  }

  &__heading {
    // style goes here
  }

  &__desc {
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;

    // @include tablet {
    //     font-size: 18px;
    //     line-height: 1.75;
    // }
  }
  &__container {
    &-mobile {
      @media (max-width: 767px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

/* Hero */
.o-skills-hero {
  $self: &;
  //
  &--csr {
    background: #232b34;
    padding-bottom: 0;
  }

  &__background {
    #{$self}--csr & {
      // @include tablet-lg {
      //     left: auto;
      //     right: 0;
      //     background-size: auto 100%;
      //     background-position: right center;
      // }
      @media (min-width: 1800px) {
        background-size: cover;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        width: 70%;
        height: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#232b34+0,232b34+50,7db9e8+99&1+0,1+50,0+100 */
        background: -moz-linear-gradient(
          left,
          rgba(35, 43, 52, 1) 0%,
          rgba(35, 43, 52, 1) 50%,
          rgba(125, 185, 232, 0.02) 99%,
          rgba(125, 185, 232, 0) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          rgba(35, 43, 52, 1) 0%,
          rgba(35, 43, 52, 1) 50%,
          rgba(125, 185, 232, 0.02) 99%,
          rgba(125, 185, 232, 0) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(35, 43, 52, 1) 0%,
          rgba(35, 43, 52, 1) 50%,
          rgba(125, 185, 232, 0.02) 99%,
          rgba(125, 185, 232, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232b34', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
        // @include desktop-lg {
        //     width: 80%;
        // }
      }
    }
  }

  &__container {
    #{$self}--csr & {
      // @include tablet{
      //     padding-top:80px ;
      //     padding-bottom:80px;
      // }
      @media (min-width: 992px) {
        padding-top: 140px;
        padding-bottom: 140px;
      }
    }
  }

  &__heading {
    @media (min-width: 992px) {
      font-size: 55px;
      line-height: 70px;
    }
  }

  &__sub-heading {
    font-family: "Montserrat", sans-serif;

    // @include tablet {
    //     font-size: 18px;
    //     line-height: 1.75;

    // }
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 40px;
    }
  }
}

/* Think Green */
.o-efforts {
  &__item {
    margin-bottom: 50px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media (min-width: 992px) {
      margin-bottom: 80px;
    }
    &:last-child {
      margin-bottom: 40px;
    }
  }
  &__heading {
    margin-bottom: 10px;
    @media (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__info {
    padding: 30px 30px 0;
    margin: -50px 30px 0;
    text-align: center;
    @media (min-width: 768px) {
      padding: 50px 50px 0;
    }
    @media (min-width: 992px) {
      padding: 30px 0 30px 30px;
      margin: 0 0 0 -180px;
      text-align: left;
      max-width: 535px;
    }
    &--right {
      @media (min-width: 992px) {
        padding: 30px 30px 30px 0;
        margin: 0 -180px 0 0;
      }
    }
  }
  &__desc {
    // @extend .margin-bottom-0;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 2;
    @media (min-width: 992px) {
      font-size: 18px;
    }
  }
}

/* Think Green */
.o-think-green {
  &__media-wrapper {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  &__list-item {
    position: relative;
    font-family: $main-font;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 36px;
    @media (min-width: 992px) {
      font-size: 18px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &::before {
      content: "";
      background-image: url("/images/csr/check_icon.svg");
      width: 21px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
}

// Node JS Page
.o-section {
  $self: &;

  #{$self}--portfolio & {
    &__heading {
      @media (min-width: 992px) {
        font-size: 46px;
        line-height: 56px;
      }
    }

    &__desc {
      font-weight: 500;
      max-width: 90%;
      margin: 0 auto;
      @media (min-width: 992px) {
        font-size: 24px;
        /* line-height: 40px; */
        max-width: 80%;
      }
    }
  }
}
.o-portfolio {
  $self: &;
  margin-left: -15px;

  &__item {
    padding: 0 0 15px 15px;
    position: relative;
    cursor: pointer;
  }
  &__inner {
    position: relative;
  }
  &__media {
    margin: 0;
  }
  &__name {
    position: absolute;
    left: 35px;
    bottom: 30px;
    font-family: $main-font;
    font-size: 20px;
    line-height: normal;
    font-weight: 600;
    color: $white-color;
    margin: 0;
    padding-right: 60px;
    word-break: break-word;
    text-align: left;
    @media (min-width: 1200px) {
      font-size: 24px;
      line-height: 32px;
      left: 50px;
      bottom: 35px;
      padding-right: 80px;
    }
  }
  &__icon {
    position: absolute;
    right: 20px;
    bottom: 35px;
    @media (min-width: 1200px) {
      right: 35px;
      bottom: 42px;
    }
  }
  & .slick-prev,
  & .slick-next {
    background-image: url(/images/node/prev.svg);
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    padding: 20px 30px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    background-size: 10px;
    @include responsive(desktop) {
      background-size: 100%;
      left: -30px;
    }
    &.slick-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
  & .slick-next {
    background-image: url(/images/node/next.svg);
    left: auto;
    right: -45px;
  }

  &__right-pan {
    padding: 30px 15px;
    color: #333;
    @media (min-width: 992px) {
      padding: 60px;
    }
  }

  &__proj-name {
    font-size: 24px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 32px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
    @media (min-width: 992px) {
      font-size: 30px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
  &__proj-desc {
    font-family: $main-font;
    font-size: 16px;
    line-height: normal;
    @include media-query(992px) {
      font-size: 14px;
    }
    @include media-query(1200px) {
      font-size: 18px;
      font-family: $main-font;
    }
  }
  &__link {
    font-family: $main-font;
    color: #4a90e2;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
    @include responsive(desktop) {
      font-family: $main-font;
    }
  }
}
/* Slick Lightbox Customization for Portfolio Node JS page*/
.slick-lightbox-on {
  overflow: hidden !important;
}
.js-slider-modal {
  display: none;
}

.slick-lightbox {
  z-index: 99999999 !important;
  & .slick-lightbox-inner {
    overflow-y: auto;
    display: flex;
    padding: 50px;
    @media (min-width: 992px) {
      padding: 0;
    }
  }
  & .slick-lightbox-inner-wrap {
    background: $white-color;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    max-width: 100%;

    @media (min-width: 992px) {
      max-width: 940px;
    }
    @media (min-width: 1200px) {
      max-width: 1170px;
    }
  }

  & .slick-lightbox-slick-item {
    height: auto !important;
  }
  & .slick-lightbox-slick-item-inner {
    max-width: 100%;
    max-height: none !important;
    margin-top: -20px;
    @media (min-width: 992px) {
      max-height: 600px !important;
      overflow: hidden;
    }
  }

  & .slick-lightbox-slick-img {
    display: none !important;
  }
  & .slick-lightbox-slick-caption {
    margin: 0;
    text-align: left;
    display: inherit;
    @include responsive(desktop) {
      display: flex;
    }
  }
  & .slick-slide img {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  & .slick-lightbox-close {
    background: url(/images/node/close.svg) no-repeat center #fff;
    text-indent: -9999px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    @media (min-width: 992px) {
      top: 10px;
      right: 10px;
    }
  }

  & .slick-arrow {
    background-image: url(/images/node/prev.svg);
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2dbe70;
    background-size: 13px auto;
    border: 1px solid #2dbe70;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%);

    &.slick-disabled {
      opacity: 0.3;
      cursor: default;
      border: 1px solid #333;
      background: #fff;
    }
    @media (min-width: 768px) {
      width: 50px;
      height: 50px;
    }

    @media (min-width: 992px) {
      transform: none;
      top: auto;
      bottom: 0;
      border: 0;
      border-left: 1px solid #2dbe70;
      border-right: 1px solid #2dbe70;
    }
  }
  & .slick-next {
    background-image: url(/images/node/next.svg);
    right: -45px !important;
    @media (min-width: 992px) {
      right: 0 !important;
    }
  }

  & .slick-prev {
    left: -45px !important;
    @media (min-width: 992px) {
      left: auto !important;
      right: 51px;
    }
  }
}

ul.freamwork-list {
  display: inherit;
  text-align: center;
  @include responsive(desktop) {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    strong {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }
  }

  li {
    margin-bottom: 20px;
    display: inherit;
    @include responsive(desktop) {
      flex: 50%;
      display: flex;
      align-items: flex-start;
    }
    img {
      @include responsive(desktop) {
        max-width: 187px;
        margin-right: 20px;
      }
    }
    p {
      font-size: 16px;
      padding-bottom: 15px;
      font-family: $main-font;
      margin-top: 10px;
      @include responsive(desktop) {
        font-family: $main-font;
        font-size: 18px;
        padding-right: 20px;
        margin-top: 0;
      }
      a {
        display: block;
        font-family: $main-font;
        color: #333333;
        text-decoration: underline;
        margin-top: 10px;
        font-weight: 500;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.font-14 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.step-heading {
  display: block;
  vertical-align: top;
  margin-top: -5px;
  width: 150px !important;
  @include responsive(desktop) {
    display: inline-block;
    margin-left: 10px;
    width: 80% !important;
  }
}

.step-holder img {
  // @include tablet-lg {
  //   margin: 100px auto 0!important;
  // }
}

.why-dotnet {
  li {
    vertical-align: top;
    margin-bottom: 30px;
    text-align: center;
    @include responsive(desktop) {
      text-align: left;
    }
    img {
      display: inline-block;
      margin-right: 0;
      vertical-align: top;
      margin-bottom: 10px;
      @include responsive(desktop) {
        width: 5%;
        margin-bottom: 0;
        position: relative;
        top: 5px;
        margin-right: 20px;
      }
    }
    span {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      vertical-align: top;
      font-family: $main-font;
      width: 100%;
      margin-bottom: 10px;
      @include responsive(desktop) {
        width: 19%;
        margin-bottom: 0;
        margin-right: 30px;
      }
    }
    p {
      display: inline-block;
      font-size: 18px;
      line-height: 28px;
      vertical-align: top;
      margin-bottom: 0;
      @include responsive(desktop) {
        width: 68%;
        margin-bottom: 25px;
      }
    }
  }
}

.step-holder {
  ol {
    padding-left: 20px;
    li {
      list-style-type: decimal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-family: $main-font;
        font-size: 19px;
        line-height: 30px;
      }
    }
  }
}

.new-step {
  .how-it-works-holder {
    span {
      line-height: 29px;
    }
  }
}

.faq-holder {
  background-color: $white-color;
  .panel1 {
    p.related-tech {
      font-family: $main-font !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}

.o-case-studies-new {
  .faq-holder {
    .accordion1 {
      span {
        // font-size: 20px!important;
        font-size: 16px !important;
        @include responsive(desktop) {
          font-size: 20px !important;
        }
        // @include responsive(desktop){
        //     font-size: 20px!important;
        // }
      }
    }
  }
}
.technology {
  .o-skills-hero {
    h1 {
      font-size: 28px !important;
      line-height: 38px !important;
      color: $white-color;
      @include responsive(desktop) {
        font-size: 48px !important;
        line-height: 68px !important;
      }
    }
  }
}

.tech-text {
  font-size: 19px;
  font-family: $main-font;
  font-weight: 600;
  padding-top: 20px;
}

.lds-dual-ring {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.calendly-preloader {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  .calendly-preloader-text {
    color: $white-color;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 16px;
  }
}

.s-thank {
  .why-details {
    h5 {
      font-family: $main-font;
    }
  }
}

.o-efforts__item {
  .col-md-4 {
    z-index: 9;
  }
}

.people-behind {
  padding: 50px 0;
  @include responsive(desktop) {
    padding: 80px 0 50px;
  }
}

.o-aboutus--leadership-team .team {
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  font-size: 18px;
  line-height: 26px;
  padding: 40px 0;
  margin-bottom: 30px;
  transition: all 0.3s ease-out;
  position: relative;
  @include media-query(992px) {
    font-size: 16px;
    line-height: 24px;
  }
  @include media-query(1200px) {
    font-size: 18px;
    line-height: 26px;
  }
}
.o-aboutus--leadership-team-new .team {
  background-color: #fff;
  // margin-top: 30px;
}
.team {
  min-height: 380px;
}

.o-aboutus--leadership-team-new .team .team-img-holder {
  width: 178px;
  height: 178px;
  margin: 0 auto 40px;
  position: relative;
}
.o-aboutus--leadership-team .team figure {
  display: inline-block;
  border: 20px solid #f7f7f7;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}
.o-aboutus--leadership-team-new .team span {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: block;
  @include media-query(992px) {
    font-size: 18px;
  }
  @include media-query(1200px) {
    font-size: 20px;
  }
}
.o-aboutus--leadership-team-new .team .team-img-holder a.linkid-in {
  position: absolute;
  right: 12px;
  bottom: 40px;
}

.o-section__desc {
  font-size: 18px;
  line-height: 24px;
}

.csr-leadership-team {
  h2 {
    margin-bottom: 30px;
  }
}

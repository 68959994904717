.o-key-diff-modify{
    h2{
        margin-bottom: 20px;
    }
    .o-key-diff-box{
        .ml-cases-img{
            min-height: 65px;
        }
        .skillsz-link{
            color: #000;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }
}
.ml-framwork-box-area{
    display: flex;
    align-items: stretch;
}
.margin-top-50-modify{
    margin-top: 30px;
    @include media-query(992px){
        margin-top: 40px;

    }
}
.gradient-bg-new{
    background: linear-gradient(135deg, #07B260 0%, #051B39 40%);
    &.ml-framwork{
        h2, p{
            color: #fff;
        }
    }
    .ml-framwork-box{
        background-color: #fff;
        border: 1px solid #b2b2b2;
        border-radius: 10px;
        padding: 20px 20px 20px 10px;
        display: flex;
        margin: 15px 0;
        .heading-area{
            position: relative;
            &::after{
                content: "";
                // background: url(../../images/machine-learning/border-line.svg);
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                position: absolute;
                right: 0;
            }
            h4{
                color: #fff;
                font-weight: 500;
                text-align: center;
                
            }
        }
        .ml-framwork-box-tick-icon{
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
        .text-area{
            width: 90%;
            .ml-framwork-box-heading{
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 20px;
            }
            p{
                color: #000;
                font-size: 16px;
                margin: 0;
            }
        }
        
    }
}
.heading-area-main{
    @include media-query(992px){
        display: flex;
        text-align: left;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    display: block;
    position: relative;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    &::after{
        content: "";
        position: absolute;
        top: auto;
        transform: translateX(50%);
        right: 50%;
        border-top: 10px solid #07B260;
        border-bottom: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        width: 10px;
        height: 10px;
        bottom: -9px;
        @include media-query(992px){
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #07B260;
            width: 10px;
            height: 10px;
            bottom: auto;
        }

    }
    &::before{
        @include media-query(992px){
            content: "";
            position: absolute;
            height: 80%;
            width: 2px;
            top: 50%;
            transform: translateY(-50%);
            right: 28px;
            background-color: #07B260;
            bottom: auto;
        }
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        top: auto;
        bottom: 0;
        right: 50%;
        background-color: #07B260;
        transform: translateX(50%);
    }
    h4{
        color: #fff;
    }
}

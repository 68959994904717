//OFFER BENEFITS
.offer-benefits{
  $offer-benefits : &;

  position: relative;
  background: $white-color;

  &__spacing{
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }
  &__tittle{
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    color: $black-color;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
    @include media-query(992px) {
      font-size: 34px;
      line-height: 35px;
      margin-bottom: 45px;
    }
  }
  &__list{
    margin: 46px 0 67px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item{
    position: relative;
    background-color: $white-color;
    border:1px solid #DDDDDD;
    box-shadow: 0px 0px 18px 0 rgba(0,0,0,0.1);
    padding: 40px 36px 40px;
    border-radius: 5px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 362px;
    width: 100%;
    text-align: center;
    height: 318px;
    &:nth-child(3n+3){
      @include media-query(1181px) {
        margin-right: 0;
      }
    }
    @include media-query(1025px) {
      margin-right: 30px;
      margin-left: inherit;
      box-shadow: 0px 10px 30px 0 rgba(0,0,0,0.1);
      width: 30%;
    }
    
  }
  &__itemtittle{
    font-family: 'Poppins';
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    color: $black-color;
    display: block;
    margin: 20px 0 10px;
    width: 90%;
    @include media-query(992px) {
      font-size: 21px;
      line-height: 25px;
    }
  }
  &__itemtext{
    font-family: 'Poppins';
    margin-top: auto;
    font-size: 16px;
    line-height: 27px;
    color: $black-color;
    margin-bottom: 0;
    font-weight: 400;
  }
  &__btn{
    font-family: 'Poppins';
    margin:20px auto 0;
    width: 100%;
    max-width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(767px) {
      width: 397px;
      margin:0 auto;
      padding: 17px 25px;
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      g{
        fill: #ff5800;
      }
    }
  }
}
.data-engineering-service-wrap{
    display: flex;
    flex-wrap: wrap;
    .data-engineering-service-box{
        width: 30%;
    }
}
.service-section-heading-new{
    margin-bottom: 25px;
    @include media-query(992px){
        margin-bottom: 40px;
    }
    h2{
        margin-bottom: 20px;
    }
    p{
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
    }
    &--industries-we-serve{
        p {
            font-size: map-get($map: $values, $key: 18);
            @include devices("min-w-992") {
              font-size: map-get($map: $values, $key: 21);
            }
        }
    }
}
.d-trans-pro-tab-btn-new{
    p{
        margin-bottom: 0;
    }
}
.industries-we-serve-content{
    display: block;
    width: 80%;
    margin: 0 auto;
    @include media-query(992px){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    ul{
        @include media-query(992px){
            margin-bottom: 0;
        }
    }
    &.ux-ui-serve-content-new{
        ul{
            @include media-query(992px){
                margin-bottom: 50px;
            }
        }
    }
}
.d-trans-pro-wrapper-new{
    margin-bottom: 50px;
    .d-trans-pro-content-spacing-new{
        margin-top: 0;
        @include media-query(992px){
            margin-top: 150px;
        }
    }
    .d-trans-pro-tab-btn-new{
        width: 400px;
        &.d-trans-pro-wrapper-analysis-new{
            margin-bottom: 0;
        }
        .d-trans-pro-steps{
            &::before{
                content: "";
                height: 98%;
                @include media-query(992px){
                    height: 88%;
                }
            }
        }
    }
}
.new-service-section-wrapper{
    @include media-query(992px){
        display: inherit;
    }
    // &.data-engineering-service-wrapper{
    //     @include media-query(992px){
    //         display: table-cell;
    //     }
    // }
}
.o-key-diff-new{
    h2{
        margin-bottom: 40px;
        @include media-query(992px){
            margin-bottom: 60px;
        }
    }
}

.d-new-process{
    h2{
        // font-size: 34px;
        margin-bottom: 62px;
    }
    &__ul{
        width: 100%;
        @include media-query(992px){
            max-width: 960px;
        }
        @include media-query(1280px){
            max-width: 1140px;
        }
    }
    &__item{
        max-width: 440px;
        border-radius: 10px;
        background-color: #fff;
        padding: 36px 30px 18px 34px;
        position: relative;
        width: 100%;
        @include media-query(992px){
            width: 39.3%;
        }
        &:after{
            position: absolute;
            content: "";
            right: -18px;
            top:30%;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 17px 0 17px 18px;
            border-color: transparent transparent transparent #FFFFFF;
            transform: rotate(0deg);
            display: none;
            @include media-query(992px){
                display: block;
            }
        }
        .count{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border:1px solid #ECECEC;
            color: #4492DC;
            font-size: 16px;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top:-20px;
            left: 34px;
            background: #fff;
        }
        h4{
            font-size: 22px;
            line-height: 40px;
            color: #333;
            margin-bottom: 5px;
        }
        p{
            font-size: 15px;
            color: #333;
            line-height: 24px;
        }
        .process-image{
            width: 84px;
            height: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            left: inherit;
            right: inherit;
            top: inherit;
            @include media-query(992px){
                position: absolute;
                border:3px solid #33DF67;
                right: -39%;
                background: #fff;
                top: 16%;
                border-radius: 50%;
            }
            &:after{
                position: absolute;
                content: "";
                width: 16px;
                height: 67px;
                background: url(/images/generative-ai/arrow1.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
                left: 0;
                right: 0;
                bottom: -105%;
                margin: auto;
                display: none;
                @include media-query(992px){
                    display: block;
                }
            }
        }
    }
    &__list{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        @include media-query(992px){
            justify-content: flex-start;
            margin-bottom: 0;
        }
        &:nth-child(even){
            @include media-query(992px){
                justify-content: flex-end;
            }
            .d-new-process__item{
                .count{
                    @include media-query(992px){
                        left: inherit;
                        right: 25px;
                    }
                }
                &:after{
                    position: absolute;
                    content: "";
                    right:inherit;
                    left: -18px;
                    top:30%;
                    width: 0px;
                    height: 0px;
                    border-style: solid;
                    border-width: 17px 18px 17px 0;
                    border-color: transparent #FFFFFF transparent transparent;
                    transform: rotate(0deg);
                    display: none;
                    @include media-query(992px){
                        display: block;
                    }
                }
                .process-image{
                    @include media-query(992px){
                        left: -39%;
                        right: inherit;
                    }
                }
            }
        }
        &:nth-child(2){
            .d-new-process__item{
                .process-image{
                    border-color: #37DC72;
                    &:after{
                        background: url(/images/generative-ai/arrow2.svg);
                    }
                }
            }
        }
        &:nth-child(3){
            .d-new-process__item{
                .process-image{
                    border-color: #3CD38A;
                    &:after{
                        background: url(/images/generative-ai/arrow3.svg);
                    }
                }
            }
        }
        &:nth-child(4){
            .d-new-process__item{
                .process-image{
                    border-color: #41C9A1;
                    &:after{
                        background: url(/images/generative-ai/arrow4.svg);
                    }
                }
            }
        }
        &:nth-child(5){
            .d-new-process__item{
                .process-image{
                    border-color: #46C0B9;
                    &:after{
                        background: url(/images/generative-ai/arrow5.svg);
                    }
                }
            }
        }
        &:last-child{
            .d-new-process__item{
                .process-image{
                    border-color: #4BB6D0;
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}
.technology-stack-sub-tittle{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 28px;
    margin-top: 40px;
    text-align: left;
    @include media-query(992px){
        font-size: 24px;
    }
}
.blue-background{
    background-color: #204379;
}
.service-case-study{
    padding: 90px 0;
    .tittle{
        color: #fff;
        font-weight: 700;
        //font-size: 34px;
        text-align: center;
    }
    .sub-tittle{
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        max-width: 1060px;
        margin: 0 auto 62px;
    }
    .service-area{
        background: #fff;
        padding: 20px 40px 60px;
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }
    .service-tittle{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 20px;
        i{
            margin-right: 10px;
            width: 45px;
            height: 45px;
        }
        h3{
            margin: 0;
            line-height: 40px;
        }
    }
    .service-list{
        margin-top: 22px;
    }
    .service-item{
        margin-bottom: 15px;
        font-size: 18px;
        color: #333;
        line-height: 27px;
        background: url(/images/common/checkmark-icon-green.svg);
        background-position: left 4px;
        background-repeat: no-repeat;
        background-size: 17px;
        padding-left: 30px;
        width: 100%;
        @include media-query(992px){
            
        }
    }
}
.gradient-blue-bottom-green{
    background: linear-gradient(324deg,#1F906D 0,#0A4A75 15%,#042242 60%,#042242 100%);
}
.ai-workflow{
    position: relative;
    background: rgba(0,0,0,0.3);
    border-radius: 20px;
    backdrop-filter: blur(5px);
    padding: 77px 60px 70px;
    max-width: 1177px;
    margin: auto;


    &__first{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        @include media-query(992px){
            flex-direction: row;
        }
    }
    &__userblock{
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 30px;
        @include media-query(992px){
            flex-direction: row;
            margin-bottom: 0;
            margin-right: 15px;
        }
        @include media-query(1177px){
            margin-right: 0;
        }
    }
    &__iconblock{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include media-query(992px){
            margin-right: 10px;
        }
        @include media-query(1177px){
            margin-right: 25px;
        }
    }
    &__icontitle{
        color: #fff;
        font-size: 22px;
        line-height: 40px;
        font-weight: 700;
    }
    &__text{
        font-size: 13px;
        line-height: 15px;
        color: #6D99CE;
        padding-bottom: 0 !important;
        margin:0 !important;
        text-align: center;
        @include media-query(992px){
            text-align: left;
        }
        &--witharrow{
            position: relative;
            padding-bottom: 26px !important;
            &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 8px;
                background: url(/images/generative-ai/user-line.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
                left: 0;
                bottom: 0;
                display: none;
                @include media-query(992px){
                    display: block;
                }
            }
        }
    }
    &__icon{
        width: 73px;
        height: 73px;
        border-radius: 50%;
        background: #4492DC;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__arrowtext{
        max-width: 155px;
    }

    &__pointblock{
        display: inline-flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 0 15px 15px;
        max-width: 248px;
        background: rgba(0,0,0,0.1);
        // background-image: repeating-linear-gradient(-11deg, #ffffff, #ffffff 9px, transparent 9px, transparent 15px, #ffffff 15px), repeating-linear-gradient(79deg, #ffffff, #ffffff 9px, transparent 9px, transparent 15px, #ffffff 15px), repeating-linear-gradient(169deg, #ffffff, #ffffff 9px, transparent 9px, transparent 15px, #ffffff 15px), repeating-linear-gradient(259deg, #ffffff, #ffffff 9px, transparent 9px, transparent 15px, #ffffff 15px);
        // background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
        // background-position: 0 0, 0 0, 100% 0, 0 100%;
        // background-repeat: no-repeat;
        border:1px dashed #fff;
        @include media-query(1177px){
            padding: 0 30px 30px;
        }
    }
    &__smallpoint{
        border-radius: 10px;
        background-color: #4492DC;
        color: #000;
        text-align: center;
        width: 100%;
        position: relative;
        margin-top: -18px;
        margin-bottom: 40px;
        p{
            font-size: 16px;
            line-height: 40px;
            font-weight: 400;
            margin: 0;
            padding: 0;
            color: #000;
            span{
                font-weight: 600;
            }
        }
    }
    &__largepoint{
        border-radius: 10px;
        color: #000;
        width: 100%;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 25px;
        i{
            margin-right: 15px;
        }
        h6{
            font-size: 18px;
            line-height: 40px;
            font-weight: 700;
            margin: 0;
            color: #000;
        }
        &--green{
            background-color: #3CC065;
        }
        &--white{
            background-color: #FFFFFF;
        }
    }

    &__arrowpointarea{
        display: flex;
        margin:14px 0 18px;
        align-items: center;
        justify-content: center;
        .ai-workflow__text{
            width: 45%;
        }
    }
    &__arrowpoint{
        width: 55%;
        height: 67px;
        background: url(/images/generative-ai/compare-line.svg) no-repeat;
        background-position: 85% center;
        background-size: 8px 100%;
    }

    &__releventblock{
        max-width: 200px;
        margin:30px auto;
        @include media-query(992px){
            margin: 0 15px;
        }
        @include media-query(1177px){
            margin: 0;
        }
        p{
            width: 100%;
            @include media-query(992px){
                width: 89%;
            }
        }
        h6{
            color: #fff;
            text-align: center;
            font-size: 16px;
            line-height: 40px;
            font-weight: 600;
        }
    }
    &__releventicon{
        text-align: center;
        position: relative;
        margin: 14px 0 7px;
        &:before{
            position: absolute;
            content: "";
            width: 62px;
            height: 6px;
            background: url(/images/generative-ai/relevent-arrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            @include media-query(992px){
                display: block;
            }
        }
        &:after{
            position: absolute;
            content: "";
            width: 62px;
            height: 6px;
            background: url(/images/generative-ai/relevent-arrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            @include media-query(992px){
                display: block;
            }
        }
    }
    &__second{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        @include media-query(992px){
            justify-content: flex-end;
            margin-top: 16px;
        }
    }
    &__taskoutput{
        @include media-query(992px){
            margin-right: 95px;
        }
        @include media-query(1177px){
            margin-right: 125px;
        }
        .ai-workflow__iconblock{
            position: relative;
            margin-right: 0;
            @include media-query(992px){
                left: 10px;
            }
        }
        .ai-workflow__arrowtext{
            max-width: 205px;
        }
        .ai-workflow__text--witharrow {
            text-align: center;
            @include media-query(992px){
                text-align: right;
                padding: 20px 19px 20px 0 !important;
            }
            &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: url(/images/generative-ai/task-output-arrow.svg);
                background-repeat: no-repeat;
                background-position: top right;
                background-size: 100% 100%;
                right: 0;
                top: 0;
                display: none;
                @include media-query(992px){
                    display: block;
                }
            }
        }
    }
}
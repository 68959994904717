.o-we-love-intro{
	padding: 40px 0 80px;
	text-align: center;

    /* media query for tablet potrait */
    // @include tablet-sm {
    //     padding: 40px 0 100px;
    // }
    // @media all and (max-width: 767px) {
    //     padding: 40px 0 30px;
    // }
    h1{
        margin-bottom: 30px;
        text-transform: none;
        line-height: 50px;

        /*mobile first for tablet landscape & small desktop*/
            font-size: 80px;
            line-height: 55px;
            letter-spacing: 2.5px;
            text-shadow:1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
        // @media all and (max-width: 767px) {
        //     padding-top: 50px;
        // }
    }

    h2{
        margin-bottom: 30px;
        text-transform: none;
        line-height: 50px;

        /*mobile first for tablet landscape & small desktop*/

            font-size: 80px;
            line-height: 55px;
            // letter-spacing: 2.5px;
            // text-shadow:1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;

    }

    h2.love-subheading{
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;

        /*mobile first for tablet landscape & small desktop*/

            font-size: 40px;
            line-height: 50px;
            font-weight: 600;
            margin-bottom: 10px;

    }

    h3{
        margin-bottom: 20px;

    }

    h4{
        margin-bottom: 20px;

    }
    p{
        strong{
            font-weight: 600;
        }
    }

    &__other-text{
        // font-weight:400;
        // font-size: 22px;
        // line-height: 32px;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        @include responsive(desktop){
            margin-bottom: 20px;
        }
        font-weight: 400;
        font-family: "Montserrat",sans-serif;
        // @media all and (max-width: 767px) {
        //     font-size: 16px;
        //     line-height: 28px;
        // }
    }

    &__we-love-image{
        padding-bottom: 40px;

    }
    .c-btn{
        margin-top: 20px;
    }

}

.cto-info-p-heading {
    font-size: 20px!important;
    line-height: 24px!important;
    font-weight: 500;
    @include responsive(desktop){
	    font-size: 23px!important;
        line-height: 35px!important;
        font-weight: 400;
    }
}

.cto-last-solid-info {
	 padding: 0 60px;
	 margin-bottom: 40px;
}

.margin-top-bottom-10 {
	margin: 10px 0!important;
}
// .cmo-service {
//     .o-home-service_holder {
//         h3 {
//             @media all and (max-width: 767px) {
//                 color: #fff !important;
//             }
//         }
//     }
// }


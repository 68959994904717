.o-case-studies-slider {
  position: relative;
  padding: 45px 0 25px;
  @include responsive(desktop) {
    padding: 70px 0 55px;
  }
  .case-studies-slider {
    width: 100%;
    padding: 0;
    // visibility: hidden;
    .slick-prev {
      background: url(/images/common/previous-arrow.png) no-repeat 0 0;
      width: 21px;
      height: 42px;
      text-indent: -10000px;
      position: absolute;
      top: 50%;
      z-index: 999;
      display: none;
      &:disabled {
        display: none;
      }
      svg {
        display: none;
      }
    }

    .slick-next {
      background: url(/images/common/next-arrow.png) no-repeat 0 0;
      width: 21px;
      height: 42px;
      text-indent: -10000px;
      position: absolute;
      top: 50%;
      display: none;
      // @include media-query(767px) {
      //   display: inline-block;
      // }
      &:disabled {
        display: none;
      }
      svg {
        display: none;
      }
    }
    &.slick-initialized {
      visibility: visible;
    }
  }
  .cs-slider {
    overflow: hidden;
    @include responsive(desktop) {
      background-color: $white-color;
    }
    .cs-slider-text {
      padding: 20px 20px 20px;
      flex: 0 0 100%;
      // height: 400px;
      background-color: $white-color;
      box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.1);
      @include responsive(desktop) {
        padding: 50px;
        // width: 50%;
        flex: 0 0 50%;
        // height: auto;
        height: 498px;
        box-shadow: none;
      }
      h4 {
        position: relative;
        font-size: 22px;
        line-height: 28px;
        color: #333;
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-family: $main-font;
        display: none;
        @include responsive(desktop) {
          font-family: $main-font;
          color: #27c070;
        }
        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50px;
          height: 2px;
          background-color: #27c070;
          @include responsive(desktop) {
            background-color: $green-color;
            height: 4px;
          }
        }
      }
      h5, h3 {
        font-size: 20px;
        font-weight: 600;
        @include responsive(desktop) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
        }
      }
      .cs-tech {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: $main-font;
        margin-bottom: 5px;
        @include responsive(desktop) {
          font-family: $main-font;
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 25px;
        }
        span {
          color: #333;
          @include responsive(desktop) {
            color: $green-color;
          }
          font-weight: 600;
        }
      }
      p {
        font-size: 16px;
        line-height: 23px;
        font-family: $main-font;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          font-family: $main-font;
        }
      }
      a {
        text-transform: uppercase;
        color: #27c070;
        font-size: 15px;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        background: url(/images/common/arrow-green.png) no-repeat 100% 50%;
        padding-right: 30px;
        @include responsive(desktop) {
          color: #27c070;
          display: inline-block;
        }
      }
    }

    .cs-slider-img {
      // min-height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      @include responsive(desktop) {
        flex: 1;
        // float: right;
        // width: 50%;
      }
    }
  }
  .splide {
    .cs-slider {
      @include responsive(desktop) {
        background: none;
      }
      .cs-slider-img {
        picture {
          @include responsive(desktop) {
            display: inline-block;
            height: 100%;
          }
          img {
            @include responsive(desktop) {
              height: 100%;
              object-fit: cover;
            }
          }
        }
        img {
          @include responsive(desktop) {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .splide__track {
      &:active{
        cursor: grabbing;
      }
      @include responsive(desktop) {
        background-color: #fff;
        box-shadow: 4px 4px 30px -2px rgba(0,0,0,.1);
      }
    }
    .splide__pagination{
      @include responsive(desktop) {
        margin-top: 30px!important;
      }
    }
  }
  .px-4 {
    padding-right: 0!important;
    padding-left: 0!important;
    margin: auto;
    @include responsive(desktop) {
      padding-right: 1.5rem!important;
      padding-left: 1.5rem!important;
    }
  }
  .btn {
    max-width: 195px;
    padding: 20px 0;
  }
}

.o-case-studies-slider a.btn.btn-orange.c-btn.non-cta {
  background: none;
  color: #333;
  font-weight: 500;
  border: 0;
  font-size: 16px;
  text-decoration: underline;
  width: auto;
  margin-top: 0;
    padding-top: 0;
    text-transform: initial;
    @include responsive(desktop) {
      font-size: 18px;
      margin-top: 0;
    }
  &:after {
    display: none;
  }
}

.cs-slider {
    @include responsive(desktop) {
      flex-direction: row-reverse;
      display: flex !important;
    }
    img {
      object-fit: cover;
    }
}

.grt-slider-box{
  &.slick-slide{
    height: auto;
  }
}

.case-studies-slider {
  .owl-stage-outer {
    box-shadow: 4px 4px 30px -2px rgba(0,0,0,0.2);
    background: #fff;
  }
  .owl-dots {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 30px;
    .owl-dot {
        width: 7px;
        height: 7px;
        display: inline-block;
        vertical-align: middle;
        background: #9b9b9b;
        margin: 0 5px;
        border-radius: 50%;
        cursor: pointer;
        &.active {
            background: #49c684;
            width: 10px;
            height: 10px;
        }
    }
}
}


.hm-case-study {
  .owl-stage-outer {
    height: auto;
    @include media-query(992px) {
      height: 498px;
    }
  }
}


.cs-height {
  @include media-query(992px) {
    height: 800px;
  }
}
.cs-cont {
  &.invisible {
    display: none !important;
  }
}

.product-bnr {
    //background: url(/images/mobile-all/m-product-bnr.jpg) no-repeat 0 0;
    min-height: 520px;
    background-size: cover;
    @include responsive(desktop){
        background: none;
    }
}

.o-skills-hero {
    &__product{
        text-align: left;
        padding-top: 95px!important;
        .container{
            padding: 0 30px 0 30px!important;
            @include responsive(desktop){
                padding: 30px 15px 0 15px!important;
            }
            .product_banner{
                padding-bottom: 80px;
                @include responsive(desktop){
                    background: url(/images/products/product-banner.png) no-repeat 0 0;
                    padding-bottom: 125px;
                    padding-top: 80px;
                    padding-left: 40%;
                    background-size: 34%;
                }
                h1{
                    color: #fff;
                    font-size: 28px;
                    line-height: 38px;
                    padding-bottom: 10px;
                    @include responsive(desktop){
                        font-size: 55px;
                        line-height: 60px;
                        color: #14DF7D;
                    }
                }
                p{
                    color: #49C684;
                    font-family: "Montserrat",sans-serif;
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 600;
                    @include responsive(desktop){
                        font-size: 32px;
                        line-height: 48px;
                        color: #fff;
                    }
                }
            }
        }
        .o-skills-hero__case-studies__banner-content {
            text-align: left;
        }
    }
}

.product-1-left{
    h3{
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        font-family: "Montserrat",sans-serif;
        margin-bottom: 30px;
        @include responsive(desktop){
            font-size: 30px;
            line-height: 45px;
            margin-bottom: 80px;
        }
    }
    p{
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        @include responsive(desktop){
            font-family: $main-font;
            font-size: 19px;
            line-height: 30px;
        }
    }
}
.product-1-right{
    ul{
        @include responsive(desktop){
            padding-left: 100px;
        }
        li{
            background: url(/images/products/green-tick.png) no-repeat 0 10px;
            padding: 0 0 10px 30px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            @include responsive(desktop){
                font-size: 19px;
                line-height: 28px;
                font-weight: 700;
            }
        }
    }

}
.product-pan2 {
    h3{
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        font-family: "Montserrat",sans-serif;
        margin-bottom: 30px;
        padding-top: 15px;
        @include responsive(desktop){
            font-size: 30px;
            line-height: 45px;
            margin-bottom: 80px;
            padding-top: 50px;
        }
    }
    p{
        font-size: 16px;
        line-height:24px;
        padding-top: 20px;
        font-family: $main-font;
        margin-bottom: 0;
        @include responsive(desktop){
            font-size: 19px;
            line-height: 30px;
            font-family: $main-font;
            margin-bottom: 25px;
        }
    }
    ul{
        padding-top: 20px;
        li{
            background: url(/images/products/green-tick.png) no-repeat 0 6px;
            padding: 0 0 10px 30px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: $main-font;
            @include responsive(desktop){
                font-family: $main-font;
                font-weight: 700;
                font-size: 19px;
                line-height: 28px;
                background-position: 0 10px;
            }
        }
    }
}

.p-logo-sec{
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    @include responsive(desktop){
        display: block;
    }
    li{
        display: inline-block;
        padding: 0 15px;
        vertical-align: middle;
        flex: 0 0 50%;
        &:first-child{
            padding: 0 15px 0 0 !important;
            border-right: 1px solid #ccc;
            text-align: left;
            flex: 0 0 35%;
            @include responsive(desktop){
                padding: 15px 20px 20px 0;
                padding: 5px 20px 5px 0 !important;
                flex: 0 0 50%;
                border-right: 1px solid #979797;
            }
            img {
                width: 100%;
                @include responsive(desktop){
                    width: auto;
                }
            }
        }
        &:last-child{
            font-family: "Montserrat",sans-serif;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include responsive(desktop){
                display: inline;
                flex: 0 0 50%;
                justify-content: flex-end;
            }
            img{
                margin-right: 10px;
                display: inline-block;
                flex: 0 0 15px;
                width: 15px;
                height: 15px;
                @include responsive(desktop){
                    width: auto;
                    height: auto;
                }
            }
        }
        a{
            display: inline-block;
            color: #333333;
            font-size: 12px;
            @include responsive(desktop){
                font-size: 18px;
            }
        }
    }
}

.product-blue-banner{
    background: #091B38;
    padding-bottom: 50px;
    @include media-query(992px){
        padding-bottom: 30px;
    }
    @include media-query(1200px){
        padding-bottom: 0;
    }
}
.product-blue-banner img{
  float: right;
}
.product-blue-banner h2{
    color: #fff;
    font-size: 24px;
    line-height: 37px;
    // font-weight: bold;
    padding: 25px 0 0;
    @include media-query(992px){
        font-size: 36px;
        line-height: 42px;
    }
    @include media-query(1200px){
        font-size: 44px;
        line-height: 55px;
    }
}
.product-blue-banner a{
    border:2px solid #FF5800;
    background: url(/images/common/arrow-white.png) 86% 50% no-repeat;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-family: "Montserrat",sans-serif;
    padding: 16px 60px 18px 60px;
    border-radius: 5px;
    margin: 20px 0 0 0;
    display: inline-block;
    font-weight: 500;
    width: 310px;
    margin: 15px auto 0;
    text-align: center;
    text-transform: uppercase;
    @include media-query(992px) {
        width: inherit;
        margin: 15px 0 15px;
        padding: 16px 30px 18px 0px;
    }
    @include media-query(1200px) {
        padding: 16px 60px 18px 60px;
        margin: 15px 0 0;
    }
}
.product-blue-banner a:focus{
  color: #fff;
}
.product-blue-banner a:hover{
  background-color: #FF5800;
  color: #fff;
}
.btn2 ul {
    margin-bottom: 50px;
    text-align: center;
    @include responsive(desktop){
        margin-bottom: 75px;
    }
    li {
        display: block;
        margin: 0 5px;
        @include responsive(desktop){
            display: inline-block;
        }
    }
}
.btn2 ul li a.btn-whi {
    border: 2px solid #FF5800;
    background: url(/images/products/arrow-black.png) 75% 50% no-repeat #fff !important;
    color: #333;
    font-size: 17px;
    line-height: 24px;
    padding: 16px 60px 18px 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 20px 0 0 0;
    display: inline-block;
    font-weight: 600;
    width: 300px;
    font-family: $main-font;
    text-transform: uppercase;
    @include responsive(desktop){
        max-width: 350px;
        text-decoration: none;
        position: relative;
        width: inherit;
        background-position: 83% 50% !important;
    }
}
.btn2 ul li a.btn-ora {
    border: 2px solid #FF5800;
    background: url(/images/common/arrow-white.png) 93% 50% no-repeat #FF5800 !important;
    color: #fff;
    font-size: 17px;
    line-height: 24px;
    font-family: $main-font;
    padding: 16px 40px 18px 10px;
    border-radius: 5px;
    margin: 20px 0 0 0;
    display: inline-block;
    font-weight: 600;
    width: 300px;
    text-transform: uppercase;
    @include responsive(desktop){
        padding: 16px 60px 18px 30px;
        max-width: 350px;
        text-decoration: none;
        position: relative;
        width: inherit;
        background-position: 89% 50% !important;
    }
}

.product-pan1 {
    padding-top: 40px;
    @include responsive(desktop){
        padding-top: 80px;
        padding-bottom: 5px;
    }
}

.zip-alert-logo {
    margin-bottom: 15px;
    @include responsive(desktop){
        margin-bottom: 30px;
    }
}



.o-skills-hero {
  $self : &;
  //
  &__case-studies {
    &__banner-image {
      padding: 50px 0 0;
      @include media-query(768px) {
        padding: 0;
      }
    }

    &__banner-content {
      text-align: center;
      @include responsive(desktop){
        text-align: left;
      }
      h4 {
        color: $green-color;
        font-family: $main-font;
      }
      h2 {
        color: $white-color;
        font-family: $main-font;
        font-weight: 700;
      }
      p {
        color: $green-color;
        font-family: $main-font;
        font-size: 25px;
        line-height: 43px;
        font-weight: 600;
        margin-bottom: 0;
        border-bottom: 1px solid $green-color;
        display: inline-block;
        margin-bottom: 10px;
        @include responsive(desktop){
          font-size: 30px;
          line-height: 43px;
          border-bottom: 0;
        }
      }
      h1 {
        color: $white-color;
        font-family: $main-font;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-transform: none;
       @include media-query(768px) {
          font-size: 36px;
          line-height: 46px;
        }
        @include media-query(992px) {
          font-size: 46px;
          line-height: 61px;
        }
      }
    }

    &__technology-div {
      h2{
        font-size: 24px;
      }
      ul {
          display: block;
          text-align: center;
          li {
            vertical-align: top;
            display: inline-block;
            margin: 0 6px;
            font-family: $main-font;
            span {
              display: block;
              width: 100px;
              height: 100px;
              text-align: center;
              padding: 20px 10px;
              border: 2px solid $green-color;
              border-radius: 50%;
              margin-bottom: 10px;
              color: $white-color;
              font-size: 11px;
              font-family: $main-font;
              position: relative;
              a{
                display: inline-block;
                width: 100%;
                height: 100%;
              }
              img{
                margin-bottom: 3px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                // height: 42px;
                height: auto;
                width: 42px;
                &.wdth-25 {
                  width: 25px;
                }
                &.wdth-15 {
                  width: 15px;
                }
              }
              .vertical-image-resize
              {
                height: 54px;
                width: auto;
              }
              .transip {
                @include media-query(992px) {
                  width: 70px;
                  height: 70px;
                }
              }
          }
        }
      }
      }
    }
  }

.o-case-study {
  &__clients {

    &__the-client-content {
        padding: 0;
      @include responsive(desktop){
        padding:30px 0 0 0;
      }
      h3 {
        font-size: 30px;
        font-family: $main-font;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: 0px;
      }
      h4 {
        font-size: 30px;
        font-family: $main-font;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: 20px;
        padding-bottom: 0px;

      }
      p {
        @include responsive(desktop){
          font-size: 19px;
          line-height: 30px;
      }


      }
      ul {
        margin-bottom: 15px;
        margin-top: 15px;
        @include responsive(desktop){
          margin-top: 0;
        }
        li{
          padding-left: 35px;
          padding-bottom: 15px;
          font-size: 19px;
          line-height: 32px;
          position: relative;
          font-family: $main-font;
          position: relative;
          &:before {
            content: '';
            background-image: url(/images/common/green-arrow.png);
            width: 25px;
            height: 25px;
            position: absolute;
            left: 0;
            top: 3px;
            background-size: 100%;
          }
        }
        &.number-style {
          li {
            &::before {
              background-image: none !important;
              top: 0 !important;
            }
          }
          .number-style1 {
            &::before {
              content: '1.';
            }
          }
          .number-style2 {
            &::before {
              content: '2.';
            }
          }
          .number-style3 {
            &::before {
              content: '3.';
            }
          }
          .number-style4 {
            &::before {
              content: '4.';
            }
          }
          .number-style5 {
            &::before {
              content: '5.';
            }
          }
          .number-style6 {
            &::before {
              content: '6.';
            }
          }
          .number-style7 {
            &::before {
              content: '7.';
            }
          }
          .number-style8 {
            &::before {
              content: '8.';
            }
          }
        }
      }

      &__services {
        padding: 60px 0 0 0;
        ul {
          display: flex;

          li {
              padding-left: 15px;
              padding-right: 15px;
              text-align: center;
              font-size: 30px!important;
              font-weight: 500;
              line-height: 25px;
              width: 170px;
              font-family: $main-font;
              @include responsive(desktop){
                flex: 0 0 25%;
              }
                span{
                  display: block;
                  min-height: 60px;
                  font-size: 16px;

                  img{
                    margin-bottom: 10px;
                  }
                  &:last-child {
                    min-height: auto !important;
                  }
                }
            &:before {
              display: none;
            }
            &:after {

                content: '';
                border-right: 1px solid #d8d8d8;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0px;

            }
            &:last-of-type {
              &:after {
                content: '';
                border-right: 1px solid transparent;
              }
            }
            // @media all and (max-width: 767px) {
            //   display: inline-block;
            //   width: 46%;
            //   vertical-align: top;
            // }
            &:first-child {
              @include responsive(desktop){
                flex: 0;
                padding-right: 50px !important;
              }
            }
          }
        }
      }



      .the-results {
        position: absolute;
        top: -70px;
        left: 50%;
        transform: translate(-50%,0%);
        -webkit-transform: translate(-50%,0%);
        -moz-transform: translate(-50%,0%);
        -ms-transform: translate(-50%,0%);
        // @include tablet-lg {
        //  top: -150px;
        // }
      }

      .results-content {
        background-color: $white-color;
        color: $black-color;
        ul{
          li{
            font-family: $main-font;
            &:before{
              width: 20px;
              height: 20px;
              top: 5px;
              background-size: 100%;
              content: '';
              background-image: url(/images/common/green-arrow.png);
              position: absolute;
              left: 0;

            }
          }
        }
        h4{
          margin: 0;
        }

      }

      &__icon-header {
        position: relative;
        img {
          position: absolute;
          max-width: 36px;
        }
        h4 {
          padding-left: 55px;
        }
      }



    }

    &__the-results {
        position: relative;
        padding-top: 100px;
        // @include tablet-lg {
        //  margin-top: 50px;
        // }

        &__div {
          padding-top: 10px;
          // @include tablet-lg {
          //  padding-top: 0;
          // }

          h4{
            color: $dark-green-color;
            padding-bottom: 20px;
          }

        }
      }
  }
}

.font-size-24-i {
  font-size: 24px !important;
  line-height: 32px !important;
}
.padding-top-60 {
  padding-top: 60px;
}

.o-case-studies-new{
  padding: 15px 0 20px;
  // padding-top: 20px;
  @include responsive(desktop){
    padding: 65px 0 50px;
    // padding-top: 150px;
  }
  .btn_wrapper {
    @include responsive(desktop){
      margin-top:0;
    }
  }
  .filter{
    @include responsive(desktop){
      margin-bottom: 10px;
      text-align: right;
    }
    span.flt-tech {
      font-size: 15px;
      font-family: $main-font;
      @include responsive(desktop){
        margin-right: 0;
        margin-left: 15px;
        font-size: 16px;
      }
      font-weight: 500;
      color: #000;
    }
    ul{
      li{
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        font-family: $main-font;
        label {
          cursor: pointer;
        }
      }
    }
  }
  .product{
        width: 90%;
        margin: 15px;
        @include responsive(desktop){
          min-height: 430px;
        }
        text-align: center;
        border: 1px solid #EAEAEA;
        padding: 20px;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 0px 0px 15px 0px #00000030;
        background-color: $white-color;
        overflow: hidden;

      a{
        img{
          width: 100%;
        }
      }

      &-portfolio{
        width: 100%;
        float: left;
        text-align: center;
        min-height: 340px;
        padding: 0;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
        border: 0;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $white-color;
        /* mobile query for tablet landscape */
        // @include tablet-sm {
        //   width: 31%;
        //   float: left;
        //   text-align: left;
        // }
        h3{
          // border-bottom: 1px solid #ccc;
          padding: 10px 0;
          min-height: 70px;
          text-align: left;
          font-weight: 400;
        }
        p{
          font-weight: 500;
          text-align: left;
        }
        img{
          margin-left: 0;
          margin-right: 0;
        }
        .pp-thumb{
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
        .p-button{
          border: 1px solid #A4BB66;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          display: block;
          margin-bottom: 10px;
          color: #333;
          font-size: 13px;
          font-weight: 500;
          margin-left:20px;
          margin-right:20px;
          &:hover{
            background-color: #A4BB66;
            color: #fff;
          }
        }
        .p-link{
          margin-bottom: 10px;
          color: #333;
          font-size: 13px;
          font-weight: 300;
          background: url(/assets/images/portfolio/link-icon.png) 83% 6px no-repeat;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
          width: 120px;
          border: 1px solid #ccc;
          display: inline-block;
          padding: 2px 0 2px 19px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          border-radius: 20px;
          float: right;
          &:hover{
            color: #fff;
            background: url(/assets/images/portfolio/link-icon-hover.png) 83% 6px no-repeat #FF5800;
          }
        }

        .bottom-casestudies{
          padding: 0 20px;
          text-align: left!important;
          a{
            // font-size: 16px;
            // font-weight: bold;
          }
          .country-code{
            text-indent: 0!important;
            font-size: 16px!important;
            font-weight: 500;
            line-height: 30px!important;
            display: inline-block!important;
            img{
              width: auto;
            }
          }

        img{
            border: none!important;
            padding: 0!important;
            -webkit-border-radius: 0!important;
            -moz-border-radius: 0!important;
            border-radius: 0!important;
            margin-left: 0!important;
            margin-right: 15px!important;
            width: 20px;
          }

        }

      }
      span{
        text-indent: -10000px;
        font-size: 0;
        line-height: 0;
        display: none;
      }
      h3{
        font-size: 18px;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        height: 80px;
        overflow: hidden;
        a{
            color: #000;
          }
      }
      h2{
        font-size: 17px;
        line-height: 23px;
        margin-top: 20px;
        @include responsive(desktop){
          margin-top: 20px;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 0px;
          padding-bottom: 15px;
        }
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        // height: 80px;
        overflow: hidden;
        a{
            color: #000;
          }
      }
      p{
        font-size: 15px;
        line-height: 21px;
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        min-height: 85px;
        text-align: left;
        padding-bottom: 5px;
        @include responsive(desktop){
          font-size: 16px;
          line-height: 28px;
          padding-bottom: 20px;
          margin-top: 0px;
        }
      }
      .bottom-casestudies{
        text-align: center;
        overflow: hidden;
        /* mobile query for tablet landscape */
          // @include tablet-sm {
          //   text-align: left;
          //   border-top: 1px solid #EAEAEA;
          //   padding: 15px 20px 20px;
          //   margin-top: 20px;
          // }

          a.color-icon{
            width: 40px;
            height: 40px;
            text-align: center;
            display: inline-block;
            margin: 0 10px 0 0;
            border: 1px solid #ccc;
            padding: 5px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            img{
              width: 100%;
              height: auto;
              margin-left: 0;
              margin-right: 0;
            }
          }
        .btn-casestudies{
          padding: 0 13px;
          @include responsive(desktop){
            padding: 0 15px;
          }
          float: none;
          display: block;
          margin: 0 auto 0;
          max-width: 200px;
          background-color: $white-color;
          color: #FF5800;
          float: right;
          &:hover{
            background-color: #FF5800;
            color: $white-color;
            border: 2px solid #FF5800;

          }

          /* mobile query for tablet landscape */
          // @include tablet-sm {
          //   float: right;
          //   margin: 0 0 0 0;
          // }
        }

        .btn-casestudies1{
          padding: 13px 32px;
          float: left;
          display: block;
          margin: 10px auto 0;
          max-width: 200px;
          background-color: $white-color;
          color: #333;
          background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
          text-transform: uppercase;
          // text-decoration: underline;
          font-family: "Montserrat",sans-serif;
          font-size: 15px;
          font-weight: 400;
          position: relative;
          // @include tablet-m-sm {
          //   float: none;
          //   background-size: 15px;
          //   background-position: 15% 50%;
          // }
          &:after{
            content: "";
            position: absolute;
            left: 32px;
            bottom: 9px;
            width: 100px;
            background-color: #C3C3C3;
            height: 1px;
            // @include tablet-m-sm {
            //   width: 120px;
            // }
          }
           &:hover {
            &::after{
              // background-color: #FF5800;
              // color: $white;
              // border: 2px solid #FF5800;
              // text-decoration: none;
              background-color: #494646;
            }
          }

          /* mobile query for tablet landscape */
          // @include tablet-sm {
          //   float: left;
          //   margin: 0 0 0 0;
          // }
        }
      }
  }

  .filter-height{
      height: 340px;
      overflow-y: scroll;
      margin: 0 0 50px 0;
      display: none;
      position: absolute;
      z-index: 9;
      background-color: #fff;
      padding: 10px;
      border: 1px solid #ccc;
      margin-top: 25px;
      right: 0px;
      top: 2px;
      text-align: left;
      min-width: 215px;
      ul{
        li{
          .case-study-label
          {
            position: relative;
            // top: -3px;
            line-height: 100%;
            cursor: pointer;
          }
          &:last-child{
            padding-bottom: 0px;
          }
        }
      }
  }
  .tech-filter-height {
    z-index: 99;
  }
  .filter-height ul li {
      padding: 0 0 12px;
      position: relative;
  }
  .filter-height ul li img{
      margin-right: 10px;
      width: 20px;
  }
  .filter-height ul li input {
      margin: 0 5px 0 0;
      cursor: pointer;
  }

}

.btn-casestudies {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    border-radius: 4px;
    font-size: 15px;
    line-height: 38px;
    text-transform: uppercase;
    border: 2px solid #FF5800;
    padding: 0 27px;
    @include responsive(desktop){
      font-size: 16px;
      line-height: 43px;
    }
    &:hover {
      color: #fff;
      border-color: #fff;
      background-color: #14DF7D;
    }
}

.drop-down-technologies {
    text-align: center;
    @include responsive(desktop){
      margin-right: 20px;
      text-align: right;
    }
    position: relative;
    .selected {
      a {
        img.filter-icon {
          margin-top: -6px;
        }
        img.icon-arrow {
          z-index: 10;
          position: relative;
          background-color: #fff;
        }
        img.icon-up {
          padding: 10px 5px;
          @include responsive(desktop){
            padding: 10px 15px;
          }
          // border-right: 1px solid #ccc;
          transform: rotate(180deg);
          -wenkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
        }
        img.icon-down {
          padding: 10px 5px;
          @include responsive(desktop){
            padding: 10px 15px;
          }
          border: 1px solid 0;
          transform: rotate(0deg);
          -wenkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          border-bottom: none;
        }

      }
    }
}

.o-case-study-cta{
  padding:20px 0 60px 0;
  text-align: center;

  /* media query for tablet landscape */
    // @include tablet-sm {
    //     padding: 30px 0 80px;
    // }
}

.paging {
    text-align: center;
    margin: 15px 0 30px 0;
    @include responsive(desktop){
      margin: 30px 0 30px 0;
    }
    span{
        padding: 6px 12px;
        font-weight: bold;
        color: #000;
        margin: 5px;
        &.current{
            background: #FF5800;
            color: #fff;
        }
    }
    em{
        padding: 10px;
        font-weight: bold;
        color: #000;
        margin: 5px;
    }
    a{
      color:#000;
      font-weight: bold;
      padding: 6px;
    }
    ul{
      text-align: center;
      li{
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        font-family: "Montserrat",sans-serif;
        font-weight: 500;
        border-radius: 5px;
        color: #000;
        &:first-child {
          border: 1px solid #333333;
          color: #373737;
          // @include tablet-m-sm {
          //   margin-right: 5px;
          // }
            margin-right: 5px;
            position: relative;
            background: url(/images/common/left-icon.svg) no-repeat center center;
            // &:before {
            //   content: '';
            //   background: url(../images/common/right-icon.svg) no-repeat center center;
            //   width: 15px;
            //   height: 15px;
            //   top: 9px;
            //   left: 9px;
            //   position: absolute;
            //   transform: rotate(180deg);
            // }
            .flex-center {
              text-indent: 9999px;
            }
        }
        .flex-center{
          padding: 6px 0;
          cursor: pointer;
          width: 32px;
          height: 32px;
        }
        .active-page {
          background: #FF5800;
          color: #fff;
          border-radius: 5px;
          height: 34px !important;
        }
        &:last-child {
          border: 1px solid #333333;
          color: #373737;
          // @include tablet-m-sm {
          //   margin-left: 5px;
          // }
          margin-left: 5px;
          position: relative;
          background: url(/images/common/right-icon.svg) no-repeat center center;
          // &:before {
          //   content: '';
          //   background: url(../images/common/right-icon.svg) no-repeat center center;
          //   width: 15px;
          //   height: 15px;
          //   top: 9px;
          //   left: 9px;
          //   position: absolute;
          // }
          .flex-center {
            text-indent: 9999px;
          }
        }
        &:nth-child(2){
          display: inline-block !important;
          position: relative;
        }
        &:nth-last-child(2){
          display: inline-block !important;
          position: relative;
          &:before{
            content: "...";
            float: left;
            margin: 2px 0 0;
          }
        }
      }
      &.prevShep{
        li{
          &:nth-child(2){
            &:before{
              display: none;
            }
          }
        }
      }
      
      &.midShep{
        li{
          &:nth-child(2){
            &:before{
              content: "...";
              float: right;
              margin: 2px 0 0;
            }
          }
        }
      }

      &.lastShep{
        li{
          &:nth-child(2){
            &:before{
              content: "...";
              float: right;
              margin: 2px 0 0;
            }
          }
          &:nth-last-child(2){
            &:before{
              display: none;
            }
          }
        }
      }

      &.eSepter{
        li{
          &:nth-last-child(2){
            &:before{
              display: none;
            }
          }
        }
      }

    }
}

/* width */
.filter ::-webkit-scrollbar {
  width: 5px;
  background-color: #ccc;
}

/* Track */
.filter ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FCFCFC;
  border-radius: 10px;
}

/* Handle */
.filter ::-webkit-scrollbar-thumb {
  border-radius: 25px;
  height: 30px;
  background-color: #4A4A4A;
  margin-top: 25px;
}

.banner-image-over {
  display: none;
  // @include tablet-lg {
  //  display: block;
  //  position: absolute;
  //  top: 65%;
  //  right: -85px;
  //  transform: translateY(-50%);
  //  -webkit-transform: translateY(-50%);
  //  -moz-transform: translateY(-50%);
  //  -ms-transform: translateY(-50%);
  // }

}

.image-position-case {
  position: relative;
  width: 80px;
  float: left;
  height: 60px;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -wenkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}

.padding-top-90-mlnp{
  padding-top: 90px!important;
  height: 509px;
}

.case-study-background-mlnp {
  height: 509px;
}

.case-studies-banner {
  background-color: #cc2622db;
  padding: 0 15px!important;
  // @include tablet-m-sm {
  //  padding: 0px!important;
  // }
  .mlpn-left {
    padding-left: 0px;
  }
  .mlpn-right {

  }
  &__banner-content {
    padding-top: 50px;
    // @include tablet-m-sm {
    //  padding-left: 15px;
    // }
    p {
      background-color: #9E2116;
      display: inline;
      padding: 10px 20px;
      font-size: 18px;
      color: #fff;
    }
    h1 {
      font-size: 36px;
      padding: 5px 0 25px;
      line-height: 46px;
      // @include tablet-m-sm {
      //  font-size: 24px;
      //  line-height: 34px;
      // }
      // @include tablet-m-sm {
      //   font-size: 34px !important;
      //   line-height: 42px !important;
      // }
    }
  }
}

.client-heading-mlnp {
  color: #494949;
  font-size: 55px!important;
  text-transform: uppercase!important;
  margin-bottom: 30px!important;
  margin-top: 35px!important;
  font-family: "Montserrat",sans-serif!important;
  font-weight: 700;
  // @include tablet-min-sm {
  //  font-size: 55px!important;
  // }
  // @include tablet-m-sm {
  //  font-size: 30px!important;
  // }
}

.client-description-mlnp {
  font-size: 17px!important;
}

.challenge-bullets {
  display: block;
  @include responsive(desktop){
   display: flex;
  }
  li {
    padding-left: 20px!important;
    padding-right: 10px;
    line-height: 28px!important;
    &:before {
      content: '';
      background-color: #EC3E2E;
      background-image: none!important;
      top: 5px !important;
      width: 4px !important;
      height: 50px !important;
      @include responsive(desktop){
        height: 75px !important;
      }
      // @include tablet-m-sm {
      //  height: 20px !important;
      // }
    }
  }
}

.mlnp-watch-text {
  &:before {
    content: '';
    position: absolute;
    width: 290px;
    height: 4px;
    background-color: #D0352B;
    left: 0;
    top: -8px;
  }
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  display: inline;
  position: relative;
  padding-top: 2px!important;
}

.video-mlnp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.video{
    height:315px;
}
.video-box{
    height:315px;
    position:relative;
}

.video-thumbnail{
    z-index:300;
    position:absolute;
    top:0;
    left:0;
    width:100%;
}

.video-frame{
    z-index:100;
    position:absolute;
    top:0;
    left:0;
    width:100%;
}

.thumbnail-link .video-thumbnail{
    display:block;
}

.thumbnail-link:hover .video-thumbnail{
    display:none;
}

#video-container {
    position: relative;
    max-width: 40em;
    margin: auto;
    border: 1px solid #000;
    box-shadow: 0.25em 0.25em 0.25em rgba( 0, 0, 0, 0.3 );
 }

#video-container video {
    display: block;
    width: 100%;
    height: auto;
 }

#video-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    cursor: pointer;
 }
.hide {
    display: none;
 }

#starter {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.padding-small-view {
  // @include tablet-m-sm {
  //  padding: 0;
  // }
  .padding-0 {
    // @include tablet-m-sm {
    //  padding: 30px 15px!important;
    // }
  }
}

.gradient-green-blue-mlnp {
    background: #00b35c;
    background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
    background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: linear-gradient(135deg, #00b35c -22%, #0e5579 47%, #004475 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 );
}
p.service-txt{
    min-height: auto!important;
    margin-bottom: 0!important;
    font-family: "Montserrat",sans-serif;
}
.download-form{
    max-width: 625px;
    margin: 0 auto;
    padding:0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    // @media all and (max-width: 767px) {
    //   margin: 0 15px;
    // }
    form{
      padding: 20px 40px 20px 40px;
      @include responsive(desktop) {
        padding: 20px 60px 40px;
      }
      background-color: #fff;
      // @media all and (max-width: 767px) {
      //   padding: 20px 15px 40px;
      // }
    }

    label{
        display: block;
        margin-bottom: 10px;
    }
    input{
        border-bottom: 1px solid #000;
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 20px 10px 20px 50px;
        font-size: 16px;
        font-family: "Montserrat",sans-serif;
        font-weight: 500;
    }
    .cs-name{
      background: url(images/common/form-icon-1.png)  0 50% no-repeat;
    }
    .cs-email{
      background: url(images/common/form-icon-2.png)  0 50% no-repeat;
    }

    button{
      margin: 20px 0 0 0;
    }
    .popup-heading{
      width: 100%;
      height: 110px;
      padding: 30px 140px 30px 40px;
      @include responsive(desktop) {
        padding: 30px 110px 30px 60px;
      }
      // background-size: 100%;
      position: relative;
      // @media all and (max-width: 767px) {
      //   padding: 15px 15px 15px 15px;
      //   height: auto;
      //   text-align: center;
      // }
      &:after{
        background: url(images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
        content: "";
        position: absolute;
        right: 30px;
        bottom: 0;
        width: 108px;
        height: 88px;
      }
      h2{
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        @include responsive(desktop) {
          font-size: 30px;
          line-height: 47px;
        }
      }
      p{
        color: #fff;
        font-size: 19px;
        line-height: 30px;
      }
    }
    .ohnohoney {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }
}
.popup-modal button.close {
    z-index: 999;
    position: relative;
    right: 23%!important;
    top: 42px!important;
    background: transparent!important;
    padding: 10px;
    opacity: 1;
    // @media all and (max-width: 767px) {
    //   right: 2% !important;
    // }
}

.cs-banner-pdf{
  padding: 7px 32px;
  float: none;
  // display: inline-block;
  margin: 30px auto;
  color: #fff;
  background: url(/images/case-study/icon-pdf-1.svg) 0 50% no-repeat;
  // text-transform: uppercase;
  text-decoration: underline;
  font-family: "Montserrat",sans-serif;
  position: relative;
  text-decoration: none;
  font-size: 20px;
     // Details page
     .case-studies-details &{
      font-size: 16px;
      font-weight: 600;
      padding-right: 0;
    }

  @include responsive(desktop){
    float: left;
    display: block;
    margin: 30px auto 0;
  }
    &:after{
      content: "";
      position: absolute;
      left: 34px;
      bottom: 0px;
      width: 288px;
      background-color: #14DF7D;
      height: 2px;
      display: none;
      @include responsive(desktop){
      display: block;
      }
      // Details page
      .case-studies-details &{
        display:block;
        width: auto;
        right:0;
      }
    }
     &:hover {
      color: #fff;
      &::after{
        // background-color: #FF5800;
        // color: $white;
        // border: 2px solid #FF5800;
        // text-decoration: none;
        background-color: #fff;

      }
    }
    &:focus{
        color: #fff;
    }
    // @media all and (max-width: 767px) {
    //   font-size: 14px;
    //   margin-bottom: 25px;
    //   float: none;
    //   text-align: center;
    //   background-size: 15px;
    //   background-position: 5% 50%;
    //   max-width: 280px;

    // }
  }

.case-study-download{
  padding: 30px 0;
  margin: 60px 0 0 0;
  text-align: center;
  background-color: #091B38;
  @include responsive(desktop){
    background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat#091B38;
    text-align: left;
  }

}
.case-study-download img.download-icon{
  float: right;
  display: none;
   @include responsive(desktop){
    display: block;
   }
}
.case-study-download h2{
  color: #fff;
  font-size: 40px;
  line-height: 55px;
  font-weight: bold;
  padding: 15px 0 0;
  text-transform: uppercase;
  // @media all and (max-width: 767px) {
  //   text-align: center;
  //   font-size: 34px;
  //   line-height: 44px;
  //   text-transform: capitalize;
  // }
}
.cs-bot-banner-pdf{
  border:2px solid #FF5800;
  color: #fff;
  background: url(/images/case-study/icon-down-arrow.png) 88% 50% no-repeat;
  font-size: 17px;
  line-height: 24px;
  font-family: "Montserrat",sans-serif;
  padding: 16px 60px 18px 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 40px 0 0 0;
  display: inline-block;
  background-color: #FF5800;
  font-weight: 500;
  // @media all and (max-width: 767px) {
  //     margin: 40px auto;
  //     width: 235px;
  //     display: block;
  // }
  @include media-query(992px) {
    padding: 16px 60px 18px 15px;
  }
  @include media-query(1200px) {
    padding: 16px 60px 18px 30px;
  }
}
.cs-bot-banner-pdf:focus{
  color: #fff;
}
.cs-bot-banner-pdf:hover{
  background-color: #FF5800;
  color: #fff;
  border:2px solid #fff;
}

.retention-rate {
    background: #10D08E;
    background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
    padding: 45px 35px 130px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.hurry {
    width: 80%;
    position: absolute;
    left: 50%;
    top: -210px;
    transform: translate(-50%, -50%);
    padding: 40px 80px 60px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 18px 0px #a6a6a6;
    -moz-box-shadow: 0px 0px 18px 0px #a6a6a6;
    box-shadow: 0px 0px 18px 0px #a6a6a6;
    background: #fff;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.hurry h3{
  font-size: 36px;
  margin-bottom: 10px;
}
.hurry p{
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Montserrat",sans-serif;
}
.hurry .btn{
  padding: 12px 0!important;
    max-width: 240px!important;
  @include responsive(desktop){
    padding: 19px 0px!important;
    max-width: 350px!important;
  }
}
.o-casestudies-thankyou{
    text-align: center;
    position: relative;
    padding: 170px 0 50px;
    overflow: hidden;
    background: url(images/casestudies-thank-you/thank-you-banner.jpg) 50% 50% no-repeat;
    margin-bottom: 290px;
}
.o-casestudies-thankyou h1{
  font-size: 40px;
  line-height: 44px;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.o-casestudies-thankyou p{
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  padding-bottom: 40px;
}
.o-casestudies-thankyou p a{
  text-decoration: underline;
  color: #fff!important;
}
.case-thanks .btn{
    z-index: 0;
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    padding: 12px 0px;
    max-width: 240px;
}
.case-thanks .btn:after {
    content: '';
    background: url(images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1;
}
.case-thanks .social-footer-divider--social-pan{
  border-top: 0!important;
}
.cheaper-better {
    border: 2px solid #15B96A;
    padding: 10px 35px 30px;
    @include responsive(desktop){
      padding: 50px 35px;
    }
    margin-bottom: 30px;
    margin-top: 30px;
}
.cheaper-better p {
  font-size: 24px;
  line-height: 30px;
  @include responsive(desktop){
      font-size: 30px;
      line-height: 36px;
  }
    font-family: $main-font;
}
.cheaper-better p span {
    display: block;
    font-size: 110px;
    line-height: 120px;
    font-weight: 600;
}
.retention-rate1 {
    background: #10D08E;
    background: -moz-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: -webkit-linear-gradient(-45deg, #10D08E 0%, #082653 100%);
    background: linear-gradient(135deg, #10D08E 0%, #082653 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10D08E', endColorstr='#082653',GradientType=1 );
    padding: 20px 35px 0;
    @include responsive(desktop){
      padding: 45px 35px 0;
    }
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.retention-rate1 h4 {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 20px;
    text-align: center;
    @include responsive(desktop){
      text-align: left;
      font-size: 28px;
      line-height: 36px;
    }
}
// .retention-rate1 a {
//     background-color: transparent!important;
//     color: #fff!important;
//     border-color: #fff!important;
// }

.off-video-pan {
    border: 1px solid #D8D8D8;
    padding: 25px 30px;
    @include responsive(desktop){
      padding: 20px;
    }
    margin-bottom: 30px;
}
.off-video-pan .off-video {
  max-width: 100%;
  @include responsive(desktop){
    max-width: 250px;
    margin-right: 10px;
  }
    float: left;
}
.off-video-pan .off-video-txt {
    width: 45%;
    margin-top: 0;
    float: left;
    margin-left: 10px;
}
.off-video-pan .off-video-txt h3 {
    font-size: 22px;
    margin-bottom: 5px;
    line-height: 28px;
}
.off-video-pan .off-video-txt p {
    margin-bottom: 10px;
    font-family: "Montserrat",sans-serif;
}
.off-video-pan .off-video-txt a {
    background-color: transparent!important;
    color: #000!important;
    border-color: #FF5800!important;
}
.post-pan {
    border: 1px solid #D8D8D8;
    padding: 25px 20px 20px 20px;
    @include responsive(desktop){
      padding: 10px 20px 30px 20px;
    }
}
.post-pan h2 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    @include responsive(desktop){
      text-align: left;
      font-size: 30px;
    }
}
.post-pan ul {
    padding-bottom: 20px;
}
.post-pan ul li {
  font-size: 14px;
  line-height: 20px;
  @include responsive(desktop){
    font-size: 18px;
    line-height: 26px;
  }
    margin-bottom: 15px;
    overflow: hidden;
    font-family: "Montserrat",sans-serif;
}
.post-pan ul li span {
    float: left;
    margin-right: 20px;
}
.post-pan ul li a {
    color: #000;
    display: inline-block;
    width: 80%;
    padding-top: 10px;
}
.post-pan .btn{
  max-width: 300px!important;
}
.thank-right-top{
  overflow: hidden;
}
.thank-right-top{
  ul{
    overflow: hidden;
    margin-bottom: 20px;
    li{
      display: inline-block;
      width: 48%;
      vertical-align: top;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Montserrat",sans-serif;
      span{
        display: inline-block;
        margin-right: 10px;
        width: 25px;
      }
      a{
        color: #000;
      }
    }
  }
  h3{
    font-size: 28px;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
  }
  p{
    font-family: "Montserrat",sans-serif;
    font-size: 19px;
    font-weight: 600;
  }
}
.t-left{
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: left;
  width: 48%;
  min-height: 368px;
}
.t-right{
  border: 1px solid #D8D8D8;
  padding: 30px;
  margin-bottom: 30px;
  float: right;
  width: 48%;
  min-height: 368px;
}


// .o-skills-hero-cs__case-studies__technology-div {
//   // @media all and (max-width: 767px) {
//   //   display: none;
//   // }
// }
// section.o-case-studies-new {
//   // @media all and (max-width: 767px) {
//   //   padding-top: 80px !important;
//   // }
// }

// .case-studies-class {
//   .navbar-toggle .icon-bar {
//     // @media all and (max-width: 767px) {
//     //   background-color: #333;
//     // }
//   }
// }
.banner-top-position__center {

  @include responsive(desktop){
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-60%);
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
  }
}

.case-studies-details {
  .banner-top-position__center {
    @include responsive(desktop){
      display: block;
      position: relative;
      top: inherit;
      transform: translateY(0);
    }
  }
}

.o-case-studies-new {
  .masonry {
    column-count: inherit;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0 !important;
    justify-content: center;

    @include media-query(768px) {
      justify-content: space-between;
    }
    @include media-query(1280px) {
      justify-content: flex-start; // modified
    }
  }
  .product {
    width: 100%;
    margin: 15px 0;
    @include responsive(desktop){
      margin: 15px 10px;
    }
    display: flex;
    flex-direction: column;
    padding: 0;
    @include media-query(992px) {
      flex: 0 0  47%;
      width: 47%;
    }
    @include media-query(1280px) {
      //flex: 0 0 30%;
      flex: 0 0 31.55%; // modified
      //width: 30%;
      max-width: 31.55%; // modified
      flex-grow:1;
    }
  }
}

.o-case-studies-new .product .bottom-casestudies {
    text-align: left;
    border-top: 1px solid #EAEAEA;
    padding: 13px 20px 13px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    @include responsive(desktop){
      margin-top: auto;
    }
}

.o-case-studies-new .product .bottom-casestudies .btn-casestudies1 {
    padding: 13px 13px 13px 32px;
    @include responsive(desktop){
      padding: 13px 32px;
    }
    float: left;
    display: block;
    margin: 0 auto 0;
    max-width: 200px;
    background-color: #fff;
    color: #333;
    background: url(/images/case-study-home/icon-pdf.svg) 0 50% no-repeat;
    text-transform: uppercase;
    font-family: "Montserrat",sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
}

#myModal1{
  .modal-content{
    padding: 0!important;
    background-color: transparent;
  }
}
#myModal1 .modal-body{
  padding: 0!important;
}

.download-form .popup-heading:after {
    background: url(/images/case-study/popup-heading-bg.png) 100% 100% no-repeat;
    content: "";
    position: absolute;
    right: 30px;
    bottom: 0;
    width: 108px;
    height: 88px;
}
.download-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 10px 20px 50px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif;
    font-weight: 500;
    border: 0;
    border-bottom: 1px solid #000;
}
.download-form .cs-name {
    background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
}
.download-form .cs-email {
    background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
}
.download-form .u-submit {
    display: block;
    text-align: center;
    border-radius: 5px;
    padding: 19px 0px;
    font-size: 18px;
    text-transform: uppercase;
    max-width: 350px;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    width: 250px;
    margin: 20px 0 0 0;
}
.u-submit:after {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: -1;
    display: none;
}
.download-form p{
  font-family: "Montserrat",sans-serif;
  padding-top: 20px;
  font-size: 14px;
}

#myModal1 button.close {
    z-index: 999;
    position: relative;
    //right: -26%!important;
    right: -88% !important;
    //top: 35px!important;
    top:0 !important;
    background: 0 0!important;
    padding: 10px;
    opacity: 1;
    //width: 35px;
    width: 37px;
    text-align: center;
    //background: #fff !important;
    background: transparent !important;
    //margin-left: 142px;
    float: right;

    //added
    @include responsive(desktop){
      right: -50%!important;
      top: 5px!important;
      margin-left: 325px;
    }
}

// #myModal1 button.close {
//     z-index: 999;
//     position: relative;
//     right: 0;
//     top: 0;
//     background: #ffffff;
//     opacity: 1;
//     padding: 10px;
//     width: 20px;
// }

.o-thank-you__application {
    padding: 150px 0;
}
.o-thank-you__top p {
    font-size: 14px;
    margin-bottom: 30px;
    font-family: "Montserrat",sans-serif;
}
.o-thank-you__footer {
    text-align: center;
    padding: 50px 0;
    font-family: "Montserrat",sans-serif;
    font-weight: 300;
    color: #414446;
    font-size: 12px;
    line-height: 15px;
}
.case-study-text {
  margin-top: 15px !important;
  display: inline-block !important;
}
.mlnp-ch {
  .row {
    flex-direction: row-reverse;
    padding: 60px 0;
  }
}

.mlnp-sol {
  padding: 60px 0;
  .mlnp-sol-img {
    display: block;
    margin: 0 auto;
  }
}
.mlnp-res {
  padding-top: 65px;
  h3 {
    color: #fff;
  }
  .padding-bottom-60.img-responsive.center-block {
    display: block;
    margin: 0 auto;
  }
  h4 {
    line-height: 32px;
    padding-bottom: 20px;
    font-size: 24px !important;
    color: #333;
    margin-top: 50px !important;
  }
}

.faq-section {
  .btn_wrapper {
    @include responsive(desktop){
      margin-top: 40px !important;
    }
  }
}

.case-study-bnr {
  background: #0a1b39;
  padding: 80px 0 0 0;
  min-height: 145px;
  @include media-query(992px) {
    padding: 80px 0 0 0;
    min-height: 150px;
  }
  

  // @include media-query(992px) {
  //   background-image: none;
  //   padding: 50px 0 0 0;
  //   min-height: 240px;
  // }
  @include media-query(992px) {
    // min-height: 280px;
    min-height: 280px;
    padding: 130px 0 0 200px;
    background: url(/images/case-study/case-studies-banner.png) no-repeat 10% 100% #0a1b39;
  }

  h1 {
    text-align: center;
    font-weight: 700;
    font-family: $main-font;
    font-size: 20px;
    text-transform: uppercase;
    color: #36DF7D;
    @include responsive(desktop){
      font-size: 62px;
    }
  }
}
.case-studies-class {
  .o-case-studies-new {
    @include responsive(desktop){
      padding-top: 80px;
    }
  }
}

body.case-studies-details{

  // Fonts
  & .o-skills-hero__case-studies__banner-content,
  & .o-skills-hero__case-studies__banner-content p,
  & .o-case-study__clients__the-client-content h3,
  & .o-case-study__clients__the-client-content p,
  & .o-case-study__clients__the-client-content h4,
  & .o-case-study__clients__the-client-content__services ul li,
  & .o-case-study__clients__the-client-content ul li{
    font-family: $main-font;
    @include responsive(desktop){
      font-family: $main-font;
    }
  }
  // Banner
  & .o-skills-hero{
    padding:100px 0 50px;
    @include responsive(desktop){
      padding: 100px 0 !important;
      &.padding-100{
        padding:120px 0 80px !important;
        .o-skills-hero__case-studies__banner-image{
          padding-top:30px !important;
        }
      }
    }
  }
  & .o-skills-hero__case-studies .container > .row{
    display: flex;
    align-items: center;
  }
  & .o-skills-hero__case-studies .col-sm-6{
    // order:2;
    // @include media-query(768px) {
    //   order:1;
    // }
  }
  & .o-skills-hero__case-studies .col-sm-6.banner-top-position__center{
    // order:1;
    position: relative;
    top: auto;
    transform: none;
    // @include responsive(desktop){
    //   order:2;
    //   position: relative;
    //   top: auto;
    //   transform: none;
    // }

    @include media-query(768px) {
      order:2;
    }
  }
  & .o-skills-hero__case-studies__banner-image{
    @include responsive(desktop){
      padding-top:0;
    }
  }
  & .cs-banner-pdf{
    margin:15px auto 0;
    @include responsive(desktop){
      margin: 30px 0 0;
    }
  }

  & .o-case-study__clients .container{
    padding-left:30px;
    padding-right:30px;
    @include responsive(desktop){
      padding-left:15px;
      padding-right:15px;
    }
  }
  & .pad-80{
    padding: 50px 0;
    @include responsive(desktop){
      padding: 80px 0;
    }
  }
  & .padding-80{
    padding-top: 50px;
    @include responsive(desktop){
      padding-top: 80px;
    }
  }
  & .font-weight-600{ font-weight: 600 !important;}
  & .font-weight-700{ font-weight: 700 !important;}
  // Client
  & .o-case-study__clients-first{
    text-align: center;
    padding-top:50px;
    @include media-query(768px){
      padding-top:40px;
      text-align: left;
    }
    ul{
      text-align: left;
    }
  }
  & .o-case-study__clients-title{
    font-size: 30px !important;
  }

  // Case studdy small Banner
  & .pad-80.o-case-study__clients-banner{
    padding-top: 0;
    @include responsive(desktop){
      padding-top:80px;
    }
  }
  & .o-case-study__clients-banner{
    padding-bottom: 50px;
    @include responsive(desktop){
      padding-bottom:80px;
    }
  }
  & .o-case-study__clients-banner .container{
    padding: 0;
  }
  & .o-case-study__clients-banner .o-case-study__clients__the-client-image{
    img{
      width:100%;
      @include responsive(desktop){
        width:auto;
      }
    }
  }
  & .o-case-study__clients-banner .o-case-study__clients__the-client-content{
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      padding-top: 30px;
    @include media-query(992px) {

    }
  }

  .pad-bot-150{
    padding-bottom:105px;
    @include responsive(desktop){
      padding-bottom:150px;
    }
  }
  & .o-case-study__clients__the-client-content h3{
    font-size:24px;
    line-height: 30px;
    @include responsive(desktop){
      font-size: 24px;
      line-height: 36px;
    }
  }
  & .o-case-study__clients__the-client-content h4{
    font-size:20px;
    @include responsive(desktop){
      font-size: 30px;
    }
  }
  & .o-case-study__clients__the-client-content p,
  & .o-case-study__clients__the-client-content ul li{
    font-size:16px;
    line-height:24px;
    font-weight: 500;
    @include responsive(desktop){
      font-size: 19px;
      line-height: 30px;
    }
  }

  & .o-case-study__clients__the-client-content p{
    margin-bottom:20px;
    @include responsive(desktop){
      margin-bottom:25px;
    }
    &[class="font-weight-600"] {
      @include responsive(desktop){
        margin-bottom: 10px;
      }
    }
  }
  .o-case-study__clients__the-client-content > p{
    &:last-of-type{
      margin-bottom: 0;
      @include responsive(desktop){
        margin-bottom: 25px;
      }
    }
  }
  & .o-case-study__clients__the-client-content .font-size-24-i{
    font-size: 20px !important;
    @include responsive(desktop){
      font-size:24px !important;
    }
  }
  // Services
  & .o-case-study__clients__the-client-content__services {
    padding-top:0;
    @include responsive(desktop){
      padding-top:60px;
    }
  }
  & .o-case-study__clients__the-client-content__services ul {
    margin-bottom:0;
    @include responsive(desktop){
      margin-bottom:15px;
    }
  }
  & .o-case-study__clients__the-client-content__services ul li{
    padding: 0 5px;
    @include responsive(desktop){
      padding: 0 15px 15px;
    }
  }
  & .o-case-study__clients__the-client-content__services ul li:after{
    display: none;
    @include responsive(desktop){
      display: block;
    }
  }
  .o-case-study__clients__the-client-content__services ul li span{
    font-size: 14px;
    line-height: normal;
    @include responsive(desktop){
      font-size: 18px;
      line-height: 24px;
    }
  }
  & .o-case-study__clients__the-client-content__services + h3, .o-case-study__clients__the-client-content__services + h4{
    @include responsive(desktop){
      margin-top:20px
    }
  }
  // The Solution
  & .o-case-study__clients__the-client-image{
    text-align: center;
    @include responsive(desktop){
      text-align: left;
    }
  }
  // Result
  & .padding-all-60{
    padding: 30px;
    @include responsive(desktop){
      padding: 60px;
    }
  }
  & .o-case-study__clients__the-results{
    // background:#023E66;
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    padding-top: 55px;
    @include responsive(desktop){
      padding-top:100px;
    }
  }
  & .o-case-study__clients__the-results__div{
    padding-top: 0;
    @include responsive(desktop){
      padding-top: 10px;
    }
  }
  & .o-case-study__clients__the-client-content .the-results{
    top:-55px;
    max-width: 158px;
    @include responsive(desktop){
      top:-70px;
      max-width: none;
    }
  }
  & .o-case-study__clients__the-results .o-case-study__clients__the-client-content h3{
    margin-bottom:0;
    @include responsive(desktop){
      margin-bottom: 20px;
    }
  }
  & .o-case-study__clients__the-results__div h4,
  & .font-size-24-i{
    font-size:20px;
    line-height: 28px;
    @include responsive(desktop){
      font-size: 30px;
      line-height: normal;
    }
  }
  & .o-case-study__clients__the-results__div p{
    font-size:16px;
    @include responsive(desktop){
      font-size: 19px;
    }
  }
  & .results-content{
    padding-bottom: 5px;
    @include responsive(desktop){
      padding-bottom: 60px;
    }
  }

  // Download PDF
  & .case-study-download {
    margin-top: 50px;
    padding: 50px 0;
    background: url(/images/case-study/download-mob-bg.jpg) 50% 50% no-repeat #091b38;
    background-size:auto 100% ;

    @include responsive(desktop){
      margin-top: 60px;
      padding: 30px 0;
      background: url(/images/case-study/download-bg.jpg) 50% 50% no-repeat #091b38;
      background-size:100% auto ;
    }
  }
  & .case-study-download img.download-icon{
    display: block;
    margin: 0 auto;
    float: none;
    max-width: 117px;
    @include responsive(desktop){
      max-width: 100%;
    }
  }
  & .cs-bot-banner-pdf{
    margin-top:15px;
    @include responsive(desktop){
      margin-top:40px;
    }
  }
  & .case-studies-banner .cs-bot-banner-pdf{
    margin-top:15px ;
  }
  & .case-study-download h2{
    font-size: 24px;
    line-height: 32px;
    max-width: 225px;
    margin: 0 auto;
    @include responsive(desktop){
      font-size: 40px;
      line-height: 55px;
      max-width: none;
    }
  }


  // // Case study Thank you page
  // & .hurry-section-cs .hurry{
  //   top: 0;
  //   position: relative;
  //   width: 100%;
  //   left: 0;
  //   right: 0;
  //   padding: 1rem;
  //   transform: none;
  //   padding: 30px;
  //     @include responsive(desktop){
  //       width: 70%;
  //       position: absolute;
  //       left: 50%;
  //       top: -210px;
  //       -webkit-transform: translate(-50%,-50%);
  //       transform: translate(-50%,-50%);
  //       padding: 40px 80px 60px;
  //   }
  // }
  // & .o-casestudies-thankyou{
  //   margin: 0;
  // }
  // & .hurry-section-cs .hurry h3 {
  //   font-size: 23px;
  //   margin-bottom: 10px;
  //   @include responsive(desktop){
  //     font-size: 36px;
  //     margin-bottom: 10px;
  //   }
  // }
  // .hurry-section-cs .hurry p {
  //   font-size: 16px;
  //   line-height: 28px;
  //   @include responsive(desktop){
  //     font-size: 24px;
  //     line-height: 40px;
  //   }
  // }
  // &  .hurry .btn {
  //     padding: 19px 30px!important;
  //     max-width: none !important;
  //     margin: 0 -10px;
  // }
  // .o-casestudies-thankyou{
  //   padding-bottom: 0;
  // }
}

body.case-studies-thanks{
  & .hurry-section-cs .hurry .btn-orange {
    padding-right: 0 !important;
  }
  .o-casestudies-thankyou{
    padding-bottom: 0;
    margin: 0;
    @include responsive(desktop){
      padding-bottom: 50px;
      margin-bottom: 290px;
    }
    h1{
      font-size: 30px;
      max-width: 225px;
      margin: 0 auto;
      @include responsive(desktop){
        font-size: 40px;
        max-width: none;
      }
    }
    p{
      font-size: 18px;
      @include responsive(desktop){
        font-size: 24px;
      }
    }
  }
  .survey-thank-pg {
    padding-top: 50px;
  }
  .hurry{
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      padding: 30px;
      transform: none;
      @include responsive(desktop){
        width: 70%;
        position: absolute;
        left: 50%;
        top: -200px;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        padding: 40px 80px 60px;
      }
      h3 {
          font-size: 23px;
          margin-bottom: 10px;
          @include responsive(desktop){
            font-size: 36px;
            margin-bottom: 10px;
        }
      }
      p {
      font-size: 16px;
      line-height: 28px;
      @include responsive(desktop){
        font-size: 24px;
        line-height: 40px;
      }
    }

    .btn-center-block{
      width: auto;
      @include responsive(desktop){
        width: 350px;
      }
    }

    .btn{
      width: auto;
      font-size: 16px;
      @include responsive(desktop){
        font-size: 18px;
      }
    }
  }

  & .t-left,
  & .t-right{
    width: 100%;
    @include responsive(desktop){
      width: 48%;
    }
  }
  & .off-video-pan .off-video-txt{
    width: 100%;
    @include responsive(desktop){
      width: 45%;
    }
  }
  & .off-video{
    margin: 0 0 15px;
    @include responsive(desktop){
      margin-bottom: 0;
    }
  }
  & .post-pan ul li a{
    width: 68%;
    @include responsive(desktop){
      width: 80%;
    }
  }
  & .thank-right-top ul li a{
    font-size: 13px;
    @include responsive(desktop){
      font-size: 16px;
    }
  }
  & .btn{
    margin-left: auto !important;
    margin-right: auto !important;
    @include responsive(desktop){
      margin-left: 0 !important;
    }
  }
 & .off-video-txt{
    margin-left: 0;
    @include responsive(desktop){
      margin-left:20px;
    }
  }
}

.case-studies-thanks {
  .o-navigation-bar {
    img {
      padding-top: 0;
      @include responsive(desktop){
        padding-top: 15px;
      }
    }
  }
  .o-casestudies-thankyou {
    p {
      padding-bottom: 15px;
      @include responsive(desktop){
        padding-bottom: 40px;
      }
    }
  }
  .t-right {
    min-height: inherit;
    @include responsive(desktop){
      min-height: 368px;
    }
  }
  .o-footer {
    p {
      font-family: $main-font;
      @include responsive(desktop){
        font-family: $main-font;
      }
    }
  }
}
.hurry-section-cs {
  margin-top: 45px;
  @include responsive(desktop){
    margin-top: 0;
  }
}
.o-skills-hero__case-studies{
  .mobile-off {
    display: none!important;
    @include responsive(desktop) {
      display: inline-block!important;
    }
  }

  .desktop-off {
    display: inline-block!important;
    @include responsive(desktop) {
      display: none!important;
    }
  }
}

.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.jpg"] {
  margin-bottom: 40px;
}
.o-case-study__clients__the-client-image picture img[src="images/case-study/the-solution.png"] {
  margin-bottom: 60px;
}


.ch-content {
  @include responsive(desktop) {
    padding-left: 25px !important;
  }
}
.sol-content,
.cli-content {
  @include responsive(desktop) {
    padding-left: 25px !important;
  }
}
.sol {
  @include responsive(desktop) {
    padding-top: 80px;
  }
}


.small-img-text {
  margin-bottom: 20px;
  align-items: center;
  padding: 50px 30px 0;
  @include responsive(desktop) {
    align-items: flex-start;
    padding: 0;
  }
  h2 {
    max-width: 290px;
    padding-left: 20px;
    padding-top: 0;
    font-size: 28px;
    line-height: 36px;
    @include responsive(desktop) {
      font-size: 42px;
      line-height: 52px;
      padding-top: 10px;
    }
  }
}
.new-style {
  h3 {
    font-size: 30px;
    line-height: 46px;
    font-weight: 500;
  }
}

.case-studies-details {
  .gradient-green-blue {
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
  }
}

form#brandfilter {
    display: inline-block;
}

.case-studies-class .process-section h2{
  margin-bottom: 20px!important;
}

.o-skills-hero__case-studies .row{
  flex-direction: column-reverse;
  @include media-query(768px) {
    flex-direction: inherit;
  }
}

.case-study-bnr:after{
  background-size: 70%;
}

.o-case-studies-slider {
  .slick-track {
    background: #fff;
  }
}
.list-box{
  margin-bottom: 25px !important;
  li{
    padding: 15px !important;
    &:before{
      display: none;
    }
    p{
      margin-bottom: 10px !important;
      font-size: 16px !important;
      padding-left: 30px;
    }
    .title{
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px !important;
      font-size: 19px !important;
      &:before{
        width: 20px;
        height: 20px;
        top: 5px;
        background-size: 100%;
        content: '';
        background-image: url(/images/common/green-arrow.png);
        position: absolute;
        left: 0;
      }
    }
  }

}


.cs-table {
  table {
    font-size: 14px;
    color: #333;
    tr {
      td {
        font-weight: 500;
      }
    }
  }
}

.cs-finance-img-wrp {
  text-align: left;
  .l-15 {
    margin-left: 15px;
  }
}

.green-arrow {
  background: url(/images/case-study/green-right-arrow.png)no-repeat center center;
  width: 11px;
  height: 17px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 9px;
  background-size: 8px;
}
.dm-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #05b281;
  margin-right: 5px;
  display: none;
}
.table {
  td {
    position: relative;
  }
}
.results-content {
  .table {
    @include responsive(desktop){
      margin: 20px 0 30px;
    }
  }
}

.car-box {
  img {
    box-shadow: 0 5px 10px 4px rgba(0,0,0,.1);
    border-radius: 15px;
    border-width: 1px 1px 1px 1px;
    border-color: #ead6d6;
    border-style: solid;
    border-bottom-color: #e6e0e0;
  }
  margin-bottom: 30px;
}

// /* montserrat-200 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 200;
//   font-display:swap;
//   src: url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot');
//   src: local(''),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-200.svg#Montserrat') format('svg');
// }
// /* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-display:swap;
//   src: url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot');
//   src: local(''),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg');
// }
// /* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   font-display:swap;
//   src: url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot');
//   src: local(''),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat') format('svg');
// }
// /* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   font-display:swap;
//   src: url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot');
//   src: local(''),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat') format('svg');
// }
// /* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 700;
//   font-display:swap;
//   src: url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot');
//   src: local(''),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'),
//        url('../fonts/montserrat-v24-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat') format('svg');
// }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//Poppins Fonts Start
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/Poppins-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/fonts/Poppins-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/fonts/Poppins-Black.woff2) format('woff2');
}

//Poppins Fonts Ends
/* FONTS declear start */
$main-font: "Montserrat", sans-serif;
$secondary-font: $main-font;
//$secondary-font: "Source Serif Pro", sans-serif;
/* FONTS declear end */

/* Site color declear start */
$black: #333;
$black-color: #333;
$white-color: #fff;
$dark-blue-color: #1D2942;
$blue-color1: #337ab7;
$blue-color: #091B38;
$blue-color2: #194B83;
$light-blue-color: #4A90E2;
$lighter-blue-color: #A3C2D0;
$dark-green-color: #05B281;
$green-color: #14DF7D;
$light-green: #93fff1;
$hover-color: #ff0085;
$grey-color: #f5f5f5;
$grey-color1: #DBDFE7;
$orange-color: #FF5800;
$border-blue: #495376;
$dark-blue-mega: #020a13;
$off-white: #cdcdcd;
$font-blue: #476FD4;

$base-font-size: 13px;

img {
  max-width: 100%;
  height: auto;
}

$imagePath: "images";
$fontPath: "fonts";

/*======Responsive starts=======*/
@mixin responsive($breakpoint) {
  @if $breakpoint==desktop {
    @media (min-width: 992px) {
      @content;
    }
  }

  // @if $breakpoint==iphone5 {
  //     @media (max-width: 320px) {
  //         @content;
  //     }
  // }
  // @if $breakpoint==iphone5-land {
  //     @media (min-width: 568px) {
  //         @content;
  //     }
  // }
  // @if $breakpoint==lowResolution {
  //     @media (max-width: 375px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==phone6plus {
  //     @media (min-width: 414px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==phone {
  //     @media (max-width: 480px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==iphone {
  //     @media (min-width: 640px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==iphonePLUS-land {
  //     @media (min-width: 667px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==pixel-land {
  //     @media (min-width: 731px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==tipad {
  //     @media (min-width: 768px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==land-res-phone {
  //     @media (max-width: 736px) and (min-width: 568px) {
  //         @content;
  //     }
  // }
  @else if $breakpoint==tab {
    @media (max-width: 768px) {
      @content;
    }
  }

  // @else if $breakpoint==pixel2-land {
  //     @media (max-width: 823px) and (min-width: 812px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==desktop {
  //     @media (max-width: 991px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==onlyHtabs {
  //     @media (min-width: 992px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==onlyHtab {
  //     @media (max-width: 992px) and (min-width: 768px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==ipad {
  //     @media (max-width: 1024px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==onlyHipad {
  //     @media (max-width: 1024px) and (min-width: 992px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==mdesktop {
  //     @media (max-width: 1200px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==onlyMdesktop {
  //     @media (min-width: 1024px) and (max-width: 1200px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==xbig {
  //     @media (min-width: 1200px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==ldesktop {
  //     @media (max-width: 1400px) {
  //         @content;
  //     }
  // }
  // @else if $breakpoint==onlyLdesktop {
  //     @media (max-width: 1400px) and (min-width: 1200px) {
  //         @content;
  //     }
  // }
}

/*======Responsive ends=======*/

body {
  font-family: $main-font;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: $black;

  &.home-page-spacer {
    padding-top: 72px;

    @include media-query(992px) {
      padding-top: 70px;
    }
  }

  &.no-padding {
    padding-top: 0;
  }

  &.o-fixedBody {
    @include responsive(tab) {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
  color: $black;
  position: relative;
  font-weight: 600;

  @include responsive(desktop) {}
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
  color: $black;
  position: relative;
  font-weight: 600;

  @include responsive(desktop) {}
}

h1,
.h1 {
  font-size: 34px;

  @include responsive(desktop) {
    font-size: 62px;
    line-height: 75px;
  }
}

h2,
.h2 {
  font-size: 30px;

  @include responsive(desktop) {
    font-size: 48px;
  }
}

h3,
.h3 {
  font-size: 26px;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 18px;
}

p {
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 25px;
  font-family: $main-font;
  color: $black;
}

a,
input,
button,
svg {
  font-family: $main-font;
  @include transition(all 0.5s);
}

button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  background: none;
  font-family: $main-font;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  z-index: 2;
}

a,
button,
textarea,
select {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

fieldset {
  border: 1px solid #dbd7d7;
  padding: 15px;
  margin: 0 0 25px;

  legend {
    font-size: 14px;
    color: $black-color;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    float: none;
    margin: 0;
  }

  input[type="text"],
  input[type="tel"],
  input[type="url"],
  select,
  textarea {
    border: none;
    color: #797979;
    font-weight: 400;
    width: 100%;
    padding: 0 10px;
  }

  textarea {
    height: 150px;
  }
}

textarea {
  resize: none;
}

select::-ms-expand {
  display: none;
}

::placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #797979;
  font-weight: 400;
  opacity: 1;
}

ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

//Font size responsive
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint==null {
      font-size: $fs-font-size;
      line-height: $fs-font-size + 4px;
    }

    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
        line-height: $fs-font-size + 5px;
      }
    }
  }
}

// functions
$p-font-sizes: (
  null: 14px,
  480px: 16px,
  768px: 16px,
  992px: 16px,
  1280px: 18px,
  1480px: 40px,
);
// header font size.
$h1-font-sizes: (
  null: 28px,
  480px: 30px,
  768px: 36px,
  992px: 30px,
  1280px: 40px,
  1480px: 63px,
);
$h2-font-sizes: (
  null: 30px,
  480px: 32px,
  768px: 32px,
  992px: 35px,
  1280px: 40px,
  1480px: 48px,
);
$h3-font-sizes: (
  null: 20px,
  480px: 20px,
  768px: 20px,
  992px: 30px,
  1280px: 30px,
  1480px: 40px,
);
$h4-font-sizes: (
  null: 16px,
  480px: 16px,
  640px: 16px,
  1280px: 16px,
  1480px: 18px,
);
$h5-font-sizes: (
  null: 16px,
  480px: 16px,
  640px: 16px,
  1280px: 16px,
  1480px: 16px,
);
$h6-font-sizes: (
  null: 20px,
  480px: 20px,
  640px: 20px,
  1280px: 20px,
  1480px: 20px,
);
// Banner Text
$banlarge-txt: (
  null: 48px,
  480px: 40px,
  768px: 36px,
  992px: 30px,
  1280px: 40px,
  1480px: 63px,
);
$bansmall-txt: (
  null: 22px,
  480px: 16px,
  768px: 16px,
  992px: 16px,
  1280px: 18px,
  1480px: 40px,
);
// blockquote
$blockquote: (
  null: 22px,
  768px: 26px,
  992px: 30px,
  1480px: 36px,
);

$base-duration: 500ms;
.padding-bottom-60 {
  padding-bottom: 60px;
}
.o-home-hero {
  .home-video {
    width: auto;
    @include media-query(992px) {
      height: 900px;
      width: auto;
    }
    @include media-query(1200px) {
      height: 1025px;
      width: 100%;
    }
  }
}
.about-bg {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 27, 56, 0.8);
    transform: translate(-50%, 0);
    z-index: 1;
  }
  .green-head-ribbon {
    background: none;
  }
  h2 {
    @include responsive(desktop) {
      font-size: 62px;
      line-height: 70px;
    }
  }
  .play-video {
    // background: url(../images/common/mb-play-button.svg) no-repeat center center;
    // width: 315px;
    // height: 215px;
    // background-size: 100%;
    padding-top: 0;
    padding-bottom: 0;
    @include responsive(desktop) {
      background: url(/images/common/play-button.svg) no-repeat 50% 0;
      width: inherit;
      height: inherit;
      background-size: auto;
      font-size: 14px;
      padding-top: 90px;
      padding-bottom: 30px;
    }
    color: #8abaf2;
    font-size: 0;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
  }
  ul.srv-bnr-logo li:nth-child(4) {
    @include responsive(desktop) {
      width: 116px;
      flex: 0 0 120px;
    }
  }
  ul.skill-page-mod {
    @include responsive(desktop) {
      margin-bottom: 60px !important;
    }
  }
}

.about-details {
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  &-para {
    width: 100%;
    text-align: center;
    @include responsive(desktop) {
      width: 77%;
      margin: 0 auto;
    }
    p {
      color: #333;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-family: $main-font;
        font-size: 19px;
        line-height: 30px;
      }
      strong {
        font-weight: 500;
        display: block;
        @include responsive(desktop) {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }
      }
    }
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 35px;
    }
  }
}

.market-section {
  padding: 50px 0;
  background-color: #f9f9f9;
  @include responsive(desktop) {
    padding: 80px 0 20px;
  }
  .market-wrapper {
    display: flex;
    align-items: flex-start;
    @include responsive(desktop) {
      width: 77%;
      margin: 0 auto 30px;
    }
    &-icon {
      flex: 0 0 13%;
    }
    &-text {
      flex: 1;
      h3 {
        @include responsive(desktop) {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 25px;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 30px;
          font-weight: 500;
        }
      }
    }
  }
}

.o-awards,
.n-award {
  padding: 50px 0;
  @include responsive(desktop) {
    text-align: center;
    padding: 75px 0 40px;
  }
  .n-award-wrapper {
    @include responsive(desktop) {
      width: 80%;
      margin: 0 auto;
    }
    ul {
      li {
        display: inline-block;
        margin: 0 15px 35px;
      }
    }
  }
  h3{
    font-family: 'Poppins';
  }
}

.n-award {
  h2 {
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    @include responsive(desktop) {
      display: block;
    }
    li {
      flex: 0 0 21%;
      margin: 0 5px 15px !important;
      @include responsive(desktop) {
        flex: auto;
        margin: 0 10px 15px !important;
      }
    }
  }
}
.o-aboutus {
  .slick-next {
    position: absolute;
    background: url(/images/mobile-all/small-right-icon.png) no-repeat 0 0;
    right: 44%;
    bottom: -10px;
    font-size: 0;
    width: 14px;
    height: 26px;
    background-size: 10px;
  }
  .slick-prev {
    position: absolute;
    background: url(/images/mobile-all/small-left-icon.png) no-repeat 0 0;
    right: 52%;
    bottom: -10px;
    font-size: 0;
    width: 14px;
    height: 26px;
    background-size: 10px;
  }
  .people-slider {
    margin-bottom: 30px;
    @include responsive(desktop) {
      margin-bottom: 0;
    }
  }
}
.culture-slider {
  ul.slick-dots {
    margin-top: 0;
  }
  .slick-arrow {
    display: none !important;
  }
}
.new-srv-slider {
  .slick-dots {
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    @include responsive(desktop) {
      margin-top: 30px;
    }
    li {
      display: inline-block;
      border-radius: 50%;
      text-indent: -10000px;
      width: 7px;
      height: 7px;
      margin: 0 6px;
      background-color: #9b9b9b;
      vertical-align: middle;
      &.slick-active {
        width: 10px;
        height: 10px;
        background: #49c684;
      }
    }
  }
}

.fact-section {
  background: linear-gradient(
    197deg,
    rgba(3, 120, 114, 1) 0%,
    rgba(0, 81, 127, 1) 30%,
    rgba(5, 27, 57, 1) 85%
  );
  padding: 40px 0 50px;
  @include responsive(desktop) {
    padding: 60px 0 50px;
    background: linear-gradient(145deg, #07b260 0%, #00517f 25%, #051b39 100%);
  }
  h2 {
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    position: relative;
    padding-bottom: 10px;
    // font-weight: 700;
    font-family: $main-font;
    @include responsive(desktop) {
      line-height: 61px;
      font-size: 30px;
      padding-bottom: 15px;
    }
  }
  .all-facts {
    margin-top: 20px;
    ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      @include responsive(desktop) {
        margin-bottom: 50px;
      }
      li {
        list-style: none;
        width: inherit;
        max-width: 46%;
        flex: 0 0 46%;
        border: 1px solid #49c684;
        background-color: transparent;
        border-radius: 3px;
        padding: 25px 20px 15px 20px;
        line-height: 16px;
        margin: 5px 5px;
        @include responsive(desktop) {
          width: 100%;
          flex: auto;
          max-width: none;
          margin: 3px 2px;
          padding: 25px 20px 15px 30px;
        }
        img {
          width: 45px;
          @include responsive(desktop) {
            width: auto;
          }
        }
        h3 {
          font-weight: 600;
          color: #fff;
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 0;
          margin-top: 5px;
          @include responsive(desktop) {
            font-size: 36px;
            line-height: 24px;
            margin-bottom: 10px;
            margin-top: 15px;
          }
        }
        small {
          color: #fff;
          font-size: 13px;
          line-height: 16px;
          font-family: $main-font;
          @include responsive(desktop) {
            font-size: 20px;
            line-height: 26px;
            font-family: $main-font;
          }
        }
        &:nth-child(2) {
          background: #4a90e2;
          border-color: #4a90e2;
        }
        &:nth-child(3) {
          border-color: #4a90e2;
        }
        &:nth-child(4) {
          background: #009c52;
          border-color: #009c52;
        }
        &:nth-child(5) {
          background: #4a90e2;
          border-color: #4a90e2;
        }
        &:nth-child(6) {
          background: #091b38;
          border-color: #091b38;
        }
        &:nth-child(8) {
          background: #fff;
          border-color: #fff;
          small {
            font-size: 10px;
            line-height: 12px;
            color: #333;
            @include responsive(desktop) {
              font-size: 18px;
            }
          }
          h3 {
            color: #333;
            font-size: 15px;
            margin-bottom: 0;
            margin-top: 5px;
            @include responsive(desktop) {
              font-size: 24px;
              margin-bottom: 10px;
            }
          }
        }
        &:last-child {
          width: inherit;
          max-width: 95%;
          flex: 0 0 95%;
          padding: 30px 35px;
          border-color: #4a90e2;
          @include responsive(desktop) {
            width: 38%;
            flex: auto;
            max-width: none;
          }
        }
        .ex-small {
          font-size: 16px;
        }
        span {
          color: #fff;
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
          display: block;
          font-family: $main-font;
          @include responsive(desktop) {
            font-size: 20px;
            line-height: 27px;
            font-family: $main-font;
          }
        }
        @include responsive(desktop) {
          width: 18%;
        }
      }
    }
  }
  &:after {
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
}

.pricings-section {
  background: #f5f5f5;
  padding: 45px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  border-bottom: 1px solid #dedede;
  h3 {
    margin-bottom: 25px;
    text-align: center;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 40px;
    }
  }
  .pmb-holder {
    display: flex;
    flex-wrap: wrap;
    @include responsive(desktop) {
      width: 65%;
      margin: 0 auto;
      flex-wrap: nowrap;
    }
    .pricing-modal-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin: 0 15px 25px 15px;
      background: #fff;
      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
      padding: 20px 30px;
      border-radius: 5px;
      @include responsive(desktop) {
        margin: 0 15px 50px 15px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-child(2) {
        margin-right: 0;
      }
      .pricing-modal-img {
        position: relative;
        width: 50%;
        .img-hold {
          position: relative;
          width: 101px;
        }
      }
      .pricing-modal-text {
        h4 {
          padding-left: 45px;
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          color: #333;
          position: relative;
          @include responsive(desktop) {
            font-size: 24px;
          }
          &:before {
            content: "";
            position: absolute;
            background: #2dbe70;
            height: 1px;
            width: 20px;
            left: 10px;
            top: 27px;
            @include responsive(desktop) {
              width: 35px;
              left: 4px;
            }
          }
        }
      }
    }
  }
}
.pricings-section .pmb-holder .pricing-modal-box:nth-child(1) {
  margin-right: 0;
  @include responsive(desktop) {
    margin-right: 15px;
  }
}
.pricings-section .pmb-holder .pricing-modal-box:nth-child(2) {
  margin-left: 0;
  @include responsive(desktop) {
    margin-left: 15px;
  }
}

.about-client-section {
  padding: 20px 0 17px;
  @include responsive(desktop) {
    padding: 5px 0 25px;
  }
  h2 {
    padding-top: 64px;
    text-align: center;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  .client-logo {
    background: #fff;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      content: "";
      width: 800px;
      height: 2px;
      background-color: #ccc;
      transform: translate(0);
    }
    li {
      list-style: none;
      padding: 33px 21px;
      display: inline-block;
    }
  }
  .client-icon {
    padding-right: 0;
    margin-top: 15px;
    @include responsive(desktop) {
      padding-right: 50px;
      margin-top: 40px;
    }
  }
  .list-client {
    display: flex;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    font-family: $main-font;
    align-items: center;
    padding: 0 30px;
    @include media-query(768px) {
      justify-content: center;
    }
    @include media-query(992px) {
      font-size: 17px;
      line-height: 25px;
      font-family: $main-font;
      align-items: inherit;
      margin-bottom: 50px;
      padding: 0;
    }
    span {
      flex: 0 0 60px;
      display: inline-block;
      margin-right: 15px;
      @include responsive(desktop) {
        flex: 0 0 27%;
        margin-right: 0;
      }
    }
  }
  .slick-dots {
    margin-top: 15px;
  }
}

.services {
  padding: 40px 0 20px;
  background: #f9f9f9;
  @include responsive(desktop) {
    padding: 70px 0 30px;
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 20px;
    }
  }
  h2 {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 40px;
      margin: 0 auto 40px;
      max-width: 700px;
    }
  }
  .col-sm-6 a,
  .col-sm-3 a {
    position: relative;
    display: block;
    background: #fff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
    padding: 20px 10px 0;
    min-height: auto;
    height: 180px;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    @include responsive(desktop) {
      height: auto;
      min-height: 233px;
      padding: 35px 20px 0;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
    }
    .srv-box {
      position: relative;
      z-index: 9;
      img,
      svg {
        margin: 0 auto;
        margin-bottom: 15px;
        width: 45px;
        height: 50px;
        @include responsive(desktop) {
          margin-bottom: 30px;
          // width: auto;
        }
      }
      p {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 40px;
        font-family: $main-font;
        @include responsive(desktop) {
          font-size: 19px;
          line-height: 24px;
        }
      }
    }
    .cmn-color {
      display: block;
      position: absolute;
      // background: #000;
      top: inherit;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      height: 0;
      transition: 0.4s all;
      opacity: 1;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .cmn-color {
          height: 100%;
          opacity: 1;
        }
        p {
          color: #fff;
        }
        path,
        polygon,
        rect,
        circle {
          fill: #fff;
        }
      }
    }
  }
  .row {
    .col-sm-3 {
      &:nth-child(2n + 1) {
        a {
          border-bottom: 5px solid #49c684;
          .cmn-color {
            background: #49c684;
          }
          // &:hover {
          //     .cmn-color {
          //         background: #49C684;
          //     }
          // }
        }
      }
      &:nth-child(2n) {
        a {
          border-bottom: 5px solid #4a90e2;
          .cmn-color {
            background: #4a90e2;
          }
          // &:hover {
          //     .cmn-color {
          //         background: #4A90E2;
          //     }
          // }
        }
      }
    }
    .col-sm-6 {
      flex: 1;
      &:nth-child(2n + 1) {
        padding-right: 5px;
        a {
          border-bottom: 5px solid #49c684;
          .cmn-color {
            background: #49c684;
          }
          // &:hover {
          //     .cmn-color {
          //         background: #49C684;
          //     }
          // }
        }
      }
      &:nth-child(2n) {
        padding-left: 5px;
        a {
          border-bottom: 5px solid #4a90e2;
          .cmn-color {
            background: #4a90e2;
          }
          // &:hover {

          // }
        }
      }
    }
    .col-sm-6 {
      &:nth-child(3) {
        a {
          border-bottom: 5px solid #4a90e2;
          @include responsive(desktop) {
            border-bottom: 5px solid #49c684;
          }
        }
      }
      &:nth-child(4) {
        a {
          border-bottom: 5px solid #49c684;
          @include responsive(desktop) {
            border-bottom: 5px solid #4a90e2;
          }
        }
      }
    }
  }
}

.ex-padding-srv {
  // padding-top: 25px;
  svg {
    width: 40px;
  }
}

.tech {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 45px;
  }
  h2 {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
    }
  }
  span.tch-span {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    font-weight: 500;
    margin-bottom: 25px;
    display: block;
    width: 100%;
    margin-top: 10px;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      margin-top: 35px;
    }
  }
  ul {
    text-align: center;
    margin-bottom: 10px;
    @include responsive(desktop) {
      margin-bottom: 30px;
    }
    li {
      background: #fff;
      box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.05);
      text-align: center;
      border: 1px solid #ededed;
      height: 70px;
      line-height: 70px;
      display: inline-block;
      border-radius: 5px;
      width: 46%;
      margin: 0 5px 10px;
      // &:first-child {
      //     margin-left: 0;
      // }
      // &:last-child {
      //     margin-right: 0;
      // }
      @include responsive(desktop) {
        width: 13%;
        margin: 0 10px 20px;
      }
      &:nth-child(14) {
        a {
          img {
            width: 100px;
          }
        }
      }
      // a {

      // }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px;
    @include responsive(desktop) {
      font-size: 19px;
      line-height: 30px;
    }
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 20px;
    }
  }
}

.culture {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    margin-bottom: 40px;
    font-size: 30px;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
  &-box {
    margin-bottom: 20px;
    padding: 0;
    @include responsive(desktop) {
      margin-bottom: 40px;
      padding: 0 15px;
    }
    img {
      margin: 0 auto;
    }
    h3 {
      font-size: 22px;
      margin: 15px 0 25px;
      @include responsive(desktop) {
        margin: 15px 0 10px;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-family: $main-font;
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 25px;
      }
    }
  }
  &:after {
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
}
.rw-rev {
  margin-bottom: 30px;
  @include responsive(desktop) {
    flex-direction: row-reverse;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .product-img {
    @include responsive(desktop) {
      text-align: left !important;
    }
  }
}
.product {
  padding: 40px 0 20px;
  @include responsive(desktop) {
    padding: 70px 0 50px;
  }
  h2 {
    // font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    @include responsive(desktop) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 50px;
      // font-weight: 600;
    }
  }
  &-wrapper {
    .product-logo {
      padding: 0;
      margin: 5px auto 25px;
      display: flex;
      align-items: center;
      max-width: 360px;
      padding: 9px 15px;
      background: #fff;
      @include responsive(desktop) {
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 9px 25px;
        height: 61px;
        max-width: 430px;
        margin: 5px 0 25px;
      }
      a {
        flex: 0 0 120px;
        @include responsive(desktop) {
          flex: 1;
        }
        img {
          width: 120px;
          @include responsive(desktop) {
            width: 155px;
          }
        }
      }
      span {
        background: url(/images/mobile-all/globe-icon.png) no-repeat 10px 3px;
        margin-left: 25px;
        position: relative;
        background-size: 16px;
        @include responsive(desktop) {
          flex: 1;
          background-size: auto;
          background-position: 2px 4px;
        }
        &:before {
          content: "";
          position: absolute;
          @include responsive(desktop) {
            left: -20px;
            top: -4px;
            height: 35px;
          }
          top: 2px;
          left: -8px;
          height: 20px;
          width: 1px;
          background: #d8d8d8;
        }
        a {
          font-size: 12px;
          @include responsive(desktop) {
            font-size: 18px;
          }
          font-weight: 500;
          color: #333;
          padding-left: 35px;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 25px;
      margin-top: 20px;
      text-align: center;
      font-family: $main-font;
      @include responsive(desktop) {
        font-family: $main-font;
        font-size: 19px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 25px;
        text-align: left;
      }
    }
  }
  .product-img {
    text-align: center;
    @include responsive(desktop) {
      text-align: right;
    }
  }
  .btn_wrapper {
    @include responsive(desktop) {
      margin-top: 20px;
    }
  }
  &.product-section-new {
    background: linear-gradient(
      152deg,
      rgba(7, 178, 96, 1) 0%,
      rgba(5, 27, 57, 1) 60%
    );
    h2 {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
}

.people {
  padding: 15px 0 20px;
  @include responsive(desktop) {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  h2 {
    margin: 25px 0;
    text-align: center;
    font-size: 30px;
    @include responsive(desktop) {
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 48px;
    }
  }
  .leader-para {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    @include responsive(desktop) {
      font-size: 20px;
      line-height: 32px;
      max-width: 990px;
      margin: 0 auto 30px;
    }
  }
  .team {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    font-size: 14px;
    line-height: 26px;
    padding: 30px 0;
    margin-bottom: 0;
    transition: all 0.3s ease-out;
    min-height: auto;
    text-align: center;
    font-family: $main-font;
    @include responsive(desktop) {
      margin-bottom: 25px;
      min-height: 380px;
      font-size: 18px;
      font-family: $main-font;
    }
    &:hover {
      border: 1px solid #fff;
      box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
    }
    &-img-holder {
      width: 178px;
      height: 178px;
      margin: 0 auto 20px;
      position: relative;
    }
    figure {
      display: inline-block;
      border: 20px solid #f7f7f7;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 30px;
      img {
        width: 158px;
      }
    }
    span {
      font-size: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      display: block;
    }
  }
}

.about-contact {
  padding: 50px 0;
  text-align: center;
  @include responsive(desktop) {
    padding-top: 70px;
    padding-bottom: 80px;
  }
  h2 {
    margin-bottom: 25px;
    font-size: 30px;
    @include responsive(desktop) {
      font-size: 48px;
    }
  }
}

.work-slider {
  .slick-prev {
    position: absolute;
    background: url(/images/common/left-icon.png) no-repeat 0 0;
    left: 10px;
    top: 15px;
    font-size: 0;
    width: 14px;
    height: 26px;
  }
  .slick-next {
    position: absolute;
    background: url(/images/common/right-icon.png) no-repeat 0 0;
    right: 0;
    top: 15px;
    font-size: 0;
    width: 14px;
    height: 26px;
  }
}
.about-us {
  .o-clients {
    border-bottom: 0;
    padding: 42px 0;
    @include responsive(desktop) {
      padding: 65px 0 65px 25px;
    }
    h2 {
      margin-bottom: 20px;
      @include responsive(desktop) {
        margin: 0 0 20px 0;
      }
    }
    .o-clients__list {
      position: relative;
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -35px;
        margin: 0 auto;
        background: #ccc;
        height: 1px;
        width: 140px;
        @include responsive(desktop) {
          display: none;
        }
      }
    }
  }
  #myModal1 button.close {
    z-index: 999;
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    padding: 5px 0;
    opacity: 1;
    width: 35px;
    text-align: center;
    background: #fff !important;
    float: right;
  }
  .o-skills-hero {
    padding-bottom: 50px !important;
    padding-top: 100px !important;
    @include responsive(desktop) {
      padding: 135px 20px 45px !important;
    }
    h2 {
      padding-bottom: 0;
      margin-top: 5px !important;
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 10px;
      @include media-query(992px) {
        padding: 10px 0 20px;
        margin-top: 0 !important;
        font-size: 59px;
        line-height: 70px;
        margin-bottom: 20px;
      }
    }
    position: relative;
    // &:before {
    //     display: block;
    //     content: '';
    //     position: absolute;
    //     background-image: url(../images/common/mb-br-pt.webp);
    //     background-repeat: no-repeat;
    //     background-position: bottom right;
    //     background-size: 100%;
    //     width: 160px;
    //     height: 80px;
    //     bottom: 0;
    //     right: 0;
    //     @include responsive(desktop){
    //         display: none;
    //     }
    // }
  }
  ul.srv-bnr-logo {
    li {
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
        @include responsive(desktop) {
          display: block;
        }
      }
    }
  }
  ul.srv-bnr-logo li:nth-child(4) {
    display: block !important;
  }
  ul.srv-bnr-logo li {
    flex: 0 0 33%;
    margin-bottom: 15px;
    @include responsive(desktop) {
      flex: 0 0 auto !important;
      margin-bottom: inherit;
    }
    &:nth-child(1) {
      @include responsive(desktop) {
        flex: 0 0 161px;
      }
    }
    &:nth-child(2) {
      @include media-query(992px) {
        flex: 0 0 150px;
      }
      @include media-query(1200px) {
        flex: 0 0 170px;
      }
    }
    &:nth-child(3) {
      flex: 0 0 33%;
      @include media-query(992px) {
        flex: 0 0 150px;
      }
      @include media-query(1200px) {
        flex: 0 0 140px;
      }
    }
    &:nth-child(4) {
      @include responsive(desktop) {
        flex: 0 0 115px;
      }
    }
    &:nth-child(5) {
      @include media-query(992px) {
        flex: 0 0 150px;
      }
      @include media-query(1200px) {
        flex: 0 0 190px;
      }
    }
    &:nth-child(6) {
      @include media-query(992px) {
        flex: 0 0 150px;
      }
      @include media-query(1200px) {
        flex: 0 0 175px;
      }
    }
    // &:nth-child(8) {
    //     background: #091B38;
    //     @include responsive(desktop){
    //         flex: 0 0 175px;
    //         background: #1d2942;
    //     }
    // }
  }
  .skill-page-mod.abt-bnr-logo {
    margin-bottom: 35px !important;
    @include responsive(desktop) {
      margin-bottom: 65px !important;
    }
  }
  .why-details ul li:nth-child(7) {
    border: 0;
    background: #091b38;
    @include responsive(desktop) {
      background: #1d2942;
    }
  }
  .why-details ul li:nth-child(8) {
    border: 1px solid #4a90e2;
    background: none;
  }
  .why-details {
    margin-bottom: 30px;
    @include responsive(desktop) {
      margin-bottom: 45px;
    }
  }
  .o-clients {
    ul {
      margin-bottom: 0;
      @include responsive(desktop) {
        margin-bottom: 20px;
      }
    }
  }
  .new-service {
    .slick-slide {
      padding: 15px 5px;
      @include responsive(desktop) {
        padding: 0;
      }
    }
    .cmn-heading-para {
      font-family: $main-font;
    }
  }
  // .o-home-hero .home-video {
  //     height: auto;
  // }
}
ul.abt-bnr-logo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
  @include responsive(desktop) {
    justify-content: space-evenly;
    margin-bottom: 60px;
  }
  svg {
    overflow: visible;
  }
  li {
    flex: 0 0 33%;
    padding: 0 5px;
    @include responsive(desktop) {
      flex: 1;
      padding: 0 10px;
    }
    a {
      height: 100px;
      display: block;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
        padding: 0 !important;
        // &.w-clutch {
        //   width: 123px;
        // }
      }
    }
  }
}

.w-clutch {
  width: 123px !important;
}

ul.srv-bnr-logo {
  justify-content: center;
  @include media-query(992px) {
    justify-content: space-around;
  }
  li {
    flex: 0 0 25%;
    @include responsive(desktop) {
      flex: 0 0 200px;
    }
    // &:nth-child(4) {
    //     display: none;
    //     @include responsive(desktop){
    //         display: block;
    //     }
    // }
  }
}

.skill-page-mod {
  margin-bottom: 35px !important;
  align-items: center;
  @include responsive(desktop) {
    margin-bottom: 35px !important;
  }
  li {
    a {
      height: auto !important;
    }
  }
}

ul.skill-page-mod {
  margin-top: 0;
  @include responsive(desktop) {
    margin-top: 10px;
    margin-bottom: 35px;
  }
  li {
    @include responsive(desktop) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 0 0 65px;
      @include responsive(desktop) {
        flex: 0 0 100px;
      }
    }
    // &:last-child {
    //     display: none;
    //     @include responsive(desktop){
    //         display: block;
    //     }
    // }
    &:before {
      display: none;
    }
  }
  &.mobile-off {
    display: none;
    @include responsive(desktop) {
      display: flex;
    }
  }
}

.pricings-section {
  h4 {
    text-align: center;
    margin-bottom: 20px;
    font-family: $main-font;
    font-weight: 600;
    font-size: 30px;
    @include responsive(desktop) {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }
}
.graph {
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9e8ec;
  border-radius: 8px;
  text-align: center;
  padding: 25px 15px;
  @include responsive(desktop) {
    padding: 40px 15px;
    margin-bottom: 50px;
    border: 1px solid #e1dfe6;
    width: 100%;
  }
  margin-bottom: 35px;
  ul {
    margin-top: 5px;
    padding-left: 40px;
    @include media-query(768px) {
      max-width: 300px;
      margin: 5px auto;
    }
    @include media-query(992px) {
      margin-top: 35px;
      padding-left: 25px;
    }
    li {
      text-align: left;
      margin-bottom: 5px;
      font-family: $main-font;
      @include responsive(desktop) {
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 600;
        margin-bottom: 2px;
      }
      font-size: 16px;
      font-weight: 500;
      position: relative;
      padding-left: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        background: #000;
        width: 7px;
        height: 7px;
        @include responsive(desktop) {
          width: 9px;
          height: 9px;
        }
        border-radius: 50%;
      }
      &.g-color1 {
        &:before {
          background: #2dbe70;
        }
      }
      &.g-color2 {
        &:before {
          background: #3775bd;
        }
      }
      &.g-color3 {
        &:before {
          background: #d85f0e;
        }
      }
      &.g-color4 {
        &:before {
          background: #d99f00;
        }
      }
      &.g-color5 {
        &:before {
          background: #61a2bd;
        }
      }
    }
  }
  span {
    svg {
      width: 175px;
      @include responsive(desktop) {
        width: auto;
      }
    }
  }
  .graph-3 {
    padding-left: 0;
    max-width: 300px;
    margin: 5px auto 0;
    @include responsive(desktop) {
      padding-left: 25px;
      max-width: inherit;
      margin: 35px 0 0;
    }
    li {
      flex: 0 0 50%;
    }
  }
}

.addr-section {
  &:after {
    display: none;
    @include responsive(desktop) {
      display: block;
    }
  }
}

.product-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  @include responsive(desktop) {
    display: block;
  }
}
.skill-pad-left {
  @include responsive(desktop) {
    padding-left: 30px;
  }
}

.client-heading {
  font-size: 30px;
  margin-bottom: 10px;
  font-family: $main-font;
  // font-weight: 700;
  display: block;
  @include responsive(desktop) {
    font-size: 48px;
    margin-bottom: 15px;
  }
}
.cstm-svg {
  margin: 15px 0;
  @include responsive(desktop) {
    width: 204px;
    height: 206px;
    margin: 0;
  }
}

.world-map {
  img {
    margin: 0 auto;
  }
}

.tech {
  .home-link {
    width: 205px;
    margin: 0 auto;
  }
}
.o-home-technology {
  .home-link {
    width: 210px;
    margin: 0 auto;
    @include responsive(desktop) {
      margin: 10px auto 0;
    }
  }
}
.o-home-service {
  .home-link {
    width: 210px;
    margin: 0 auto 0;
  }
}

.skill-page-mod-pro {
  width: 100%;
}

.profile-our-story-text-content {
  h2 {
    text-align: left;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
.service-heading-text-area {
  h2 {
    margin-bottom: 15px;
  }
  p {
    font-size: 18px;
    text-align: center;
  }
}
.year-award-wrapper {
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    @include responsive(desktop) {
      width: 30%;
      text-align: left;
    }
  }
  .year-award-content {
    width: 70%;
    position: relative;
    h3 {
      padding-bottom: 40px;
      margin: 0 15px;
      &::after {
        content: "";
        position: absolute;
        width: 170px;
        height: 3px;
        background-color: #47c281;
        bottom: 20px;
        left: 0;
      }
      &.award-21 {
        &::after {
          background-color: #4a90e2;
        }
      }
      &.award-20 {
        &::after {
          background-color: #000;
        }
      }
    }
    .year-award-img-section {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      align-items: center;
      .year-award-img {
        // width: 20%;
        padding: 15px;
      }
    }
  }
}

.sequrity-matter-banner {
  background-color: #063d72;
  padding-top: 50px;
  @include media-query(992px) {
    padding-top: 0;
  }
  .sequrity-matter-content {
    position: relative;
    z-index: 999;
    margin: 25px 0 50px;
    text-align: center;
    @include responsive(desktop) {
      margin: 70px 50px;
      text-align: left;
    }
    h2 {
      color: #fff;
      font-weight: 500;
      line-height: 54px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      color: #fff;
      line-height: 30px;
    }
    .btn {
      margin: 0 auto;
      @include responsive(desktop) {
        margin: 0;
      }
    }
  }
  img {
    width: 180px;
    margin: 0 auto;
    display: block;
    @include media-query(992px) {
      width: auto;
      margin: 0;
      display: inherit;
    }
  }
}
.office-infrastucture-img-wrapper {
  display: flex;
  .office-infrastucture-img {
    margin: 10px;
  }
}
.company-service-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  justify-content: center;
  width: 100%;
  @include media-query(992px) {
    margin: 55px auto;
    width: 80%;
  }
  a {
    flex-basis: 100%;
    margin: 15px;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
    text-align: center;
    @include responsive(desktop) {
      flex-basis: 30%;
    }
    @include responsive(768px) {
      flex-basis: 44%;
    }
    &.ecommerce-service-box {
      padding: 40px 5px;
    }
    h4 {
      margin-bottom: 0;
      font-size: 18px;
    }
    &:nth-child(even) {
      border-top: 3px solid #4a90e2;
    }
    &:nth-child(odd) {
      border-top: 3px solid #49c684;
    }
  }
  .profile-service-box-img {
    @include media-query(992px) {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.profile-tabs {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  margin: 40px auto;
  border-radius: 5px;
  border: none;
  @include responsive(desktop) {
    width: 90%;
    border: 1px solid #ddd;
  }
  .tab-profile-header {
    display: none;
    @include responsive(desktop) {
      width: 25%;
      border-right: 1px solid #ddd;
      display: block;
    }
    ul {
      li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 25px 15px;
        border-left: 3px solid transparent;
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
        transition: 0.4s all ease-in-out;
        &.active {
          border-left: 3px solid #2dbe70;
          background-color: #ecf9f9;
        }
        &:hover {
          @include media-query(992px) {
            background-color: #ecf9f9;
            border-left: 3px solid #2dbe70;
          }
        }
      }
    }
  }
  .tab-profile-content {
    background-color: #fff;
    flex: 1;
    position: relative;
    width: 100%;
    @include responsive(desktop) {
      width: 75%;
      background-color: #f2f2f2;
    }
    div {
      position: relative;
      opacity: 1;
      top: auto;
      left: auto;
      @include responsive(desktop) {
        position: absolute;
        top: -50vh;
        left: 100px;
        opacity: 0;
      }
    }
    .tab-profile-content-area {
      width: 100%;
      .tab-profile-content-wrap,
      .tab-profile-content-title {
        position: static;
        opacity: 1;
      }
      .tab-profile-content-title {
        display: block;
        background: #ecfaf9;
        padding: 15px 20px;
        margin-bottom: 10px;
        @include responsive(desktop) {
          display: none;
          padding: 10px 20px;
        }
        h2 {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 0;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding-right: 20px;
          @include responsive(992px) {
            display: inherit;
          }
          &::after {
            content: "\f107";
            font-family: "Font Awesome 5 Free";
            position: absolute;
            font-weight: 600;
            right: 0;
          }
        }
      }
      .tab-profile-content-wrap {
        display: none;
        @include responsive(992px) {
          display: block;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        @include media-query(992px) {
          margin-bottom: 0;
        }
        li {
          width: 33.33%;
          @include media-query(768px) {
            width: 20%;
          }
          @include media-query(992px) {
            width: 16.6%;
          }

          a {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #fff;
            padding: 15px 5px;
            border-radius: 5px;
            border: 1px solid #e1dfe6;
            max-width: 100%;
            margin: 10px;
            height: 75px;
            -o-object-fit: contain;
            object-fit: contain;
            -webkit-transition: all 0.4s linear;
            transition: all 0.4s linear;
            @include media-query(992px) {
              padding: 15px 20px;
            }
            img {
              width: 100%;
              margin: 0 auto;
              height: 100%;
              object-fit: contain;
              @include media-query(992px) {
                height: auto;
              }
              &.img-resize {
                width: 40%;
              }
              &.img-resize-new {
                width: 50%;
              }

              &.resize-img-new {
                width: 60%;
              }
              &.resize-img {
                width: 100%;
              }
            }
            &:hover {
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
      p {
        margin-bottom: 0;
        padding: 0 11px;
        font-size: 16px;
      }
      &.active {
        top: 0;
        left: 0;
        opacity: 1;
        padding: 0;
        background: #f2f2f2;
        margin-bottom: 10px;
        @include responsive(desktop) {
          padding: 20px;
        }
        .tab-profile-content-wrap {
          display: block;
        }
        h2 {
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}
.hq-slider-new {
  position: relative;
  z-index: 999;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 10px;
      a {
        height: 100%;
        display: block;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.hq-slider-small-img {
        width: 100%;
        @include responsive(desktop) {
          width: 25%;
        }
      }
      &.hq-slider-big-img {
        width: 100%;
        @include responsive(desktop) {
          width: 50%;
        }
      }
    }
  }
  .slick-dots {
    margin: 15px auto;
    li {
      padding: 5px;
    }
  }
}
.lets-talk {
  margin-top: 0;
}
.client-logo-new {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .slick-dots {
    height: auto;
    margin-top: 0;
    li {
      flex: none;
      &:nth-child(1) {
        flex: none;
      }
    }
  }
}

.profile-our-story-img {
  img {
    display: block;
    margin: 0 auto;
    width: 180px;
    @include media-query(992px) {
      display: inherit;
      margin: 0;
      width: inherit;
    }
  }
}

.clientLogoSlider {
  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @include media-query(992px) {
      display: inherit;
      margin: inherit;
    }
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    @include media-query(992px) {
      display: inherit !important;
    }
  }
}
.pointer-disable {
  pointer-events: none;
}
.slbWrapOuter {
  height: calc(100vh - 64px);
  top: 64px;
}
.pointer-disable-modify {
  pointer-events: none;
}
.tab-profile-content-wrap {
  .resize-img-new {
    &.resize-img-new-iso {
      width: 70% !important;
    }
  }
}

.new-clientLogoSlider {
  .owl-stage-outer {
    margin: 0;
    .owl-stage {
      display: flex;
      justify-content: center;
      align-items: center;
      .owl-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        opacity: 0;
        height: 100px;
        &.active {
          display: flex;
          opacity: 1;
        }
      }
    }
  }
  .owl-dots {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 30px;
    .owl-dot {
      width: 7px;
      height: 7px;
      display: inline-block;
      vertical-align: middle;
      background: #9b9b9b;
      margin: 0 5px;
      border-radius: 50%;
      cursor: pointer;
      &.active {
        background: #49c684;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.clientLogoSlider-box {
  @include media-query(992px) {
    margin: 10px 25px;
  }
}

.profile-redirection-layer
{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

span.linkid-in {
  position: absolute;
  right: 12px;
  bottom: 40px;
}

.o-awards--skill-salesforce {
  @include responsive(desktop) {
    padding: 60px 0 40px;
  }
}
.o-how-it-work{
    $o-how-it-work : &;
    background-color: #f9f9f9;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        text-align: center;
        font-family: 'Poppins';
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: #262E36;
        margin-bottom: 45px;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 53px;
            margin-bottom: 60px;
        }
    }
    &__list{
        position: relative;
        width: 100%;
        margin-bottom: 60px;
    }
    &__item{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: inherit;
        margin-bottom: 50px;
        @include media-query(992px) {
            margin-bottom: inherit;
            margin-top: -30px;
        }
        &:nth-child(2n+2){
            justify-content: flex-end;
            #{$o-how-it-work}__itembox{
                &:before{
                    @include media-query(992px) {
                        left: -36px;
                        border-color: transparent #bbc6d4 transparent transparent;
                    }
                }
                &:after{
                    @include media-query(992px) {
                        left: -32px;
                        border-color: transparent #fff transparent transparent;
                    }
                }
            }
            #{$o-how-it-work}__itemnumber{
                @include media-query(992px) {
                    left: inherit;
                    right: 35px;
                }
            }
        }
    }
    &__itembox{
        position: relative;
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
        border:1px solid #bbc6d4;
        padding: 35px;
        background: #fff;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            top: inherit;
            left: 0;
            right: 0;
            bottom: -36px;
            border-style: solid;
            border-color: #bbc6d4 transparent transparent transparent;
            border-width: 18px;
            margin: auto;
            @include media-query(992px) {
                top: 28px;
                left: 100%;
                right: inherit;
                bottom: inherit;
                border-color: transparent transparent transparent #bbc6d4;
            }
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-style: solid;
            top: inherit;
            bottom: -32px;
            border-color: #fff transparent transparent transparent;
            border-width: 16px;
            @include media-query(992px) {
                top: 30px;
                left: 100%;
                right: inherit;
                bottom: inherit;
                border-color: transparent transparent transparent #fff;
            }
        }
        @include media-query(992px) {
            max-width: 510px;
            width: 40%;
        }
    }
    &__itemnumber{
        position: absolute;
        width: 49px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4492DC;
        font-size: 16px;
        font-family: 'Poppins';
        font-weight: 600;
        left: 35px;
        top: -25px;
        border: 1px solid #4492dc;
        border-radius: 50%;
        background: #fff;
        z-index: 1;
    }
    &__itemtitle{
        font-family: 'Poppins';
        color: $black-color;
        font-size: 21px;
        line-height: 40px;
        margin-bottom: 14px;
    }
    &__itemcontent{
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 0;
    }
    &__imagemarker{
        position: absolute;
        width: 84px;
        height: 84px;
        border: 1px solid #7FEBA1;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        left: 0;
        right: 0;
        top: 10px;
        margin: auto;
        padding: 15px;
        &:after{
            position: absolute;
            content: "";
            width: 11px;
            height: 48px;
            background: url(/images/two-for-one/how-it-work-downarrow.svg) no-repeat;
            background-position: center;
            background-size: auto 100%;
            left: 0;
            right: 0;
            bottom: -60px;
            margin: auto;
        }
    }
    &__itempoint{

    }
    &__itempointitem{
        position: relative;
        padding-left: 30px;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 5px;
        color: $black-color;
        font-weight: 500;
        text-align: left;
        &:last-child{
            margin-bottom: 0;
        }
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 4px;
            width: 20px;
            height: 20px;
            background: url(/images/two-for-one/how-it-work-list-icon.svg) no-repeat;
            background-position: center;
            background-size: 100% 100%;
        }
    }
    &__btn{
        width: auto;
        max-width: fit-content;
        font-size: 19px;
        font-weight: 600;
        text-transform: capitalize;
        padding: 15px 30px;
    }
}

.o-tailored{
    $o-tailored : &;
    background: #ffffff;
    border-top: 1px solid #E0E0E0;
    &--company-profile{
        background: #f9f9f9;
        border-top: none;
        .container{
            @include media-query(1200px) {
                max-width: 1050px;
            } 
        }
    }
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        text-align: center;
        font-family: 'Poppins';
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: #262E36;
        margin-bottom: 25px;
        position: relative;
        &:after{
            position: absolute;
            content: "";
            width: 83px;
            height: 7px;
            left: 0;
            right: 0;
            bottom: -22px;
            margin: auto;
            background-color: #3CC065;
        }
        &--company-profile{
            @include media-query(992px) {
                line-height: 65px !important;
            }
            &:after{
                display: none;
            }
        }
        @include media-query(992px) {
            font-size: 51px;
            line-height: 53px;
            margin-bottom: 45px;
        }
        &--company-profile-dita {
            @include media-query(992px) {
                margin-bottom: 20px;
            }
        }
    }
    &__subheading{
        text-align: center;
        font-family: 'Poppins';
        color: #474747;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 35px;
        @include media-query(992px) {
            font-size: 25px;
            line-height: 34px;
        }
    }
    &__box{
        padding: 25px;
        border: 1px solid #898989;
        height: 100%;
        min-height: auto;
        width: 100%;
        text-align: center;
        @include media-query(992px) {
            min-height: 193px;
            text-align: left;
            padding: 26px 18px 18px 22px;
        }
        &--company-profile{
            @include media-query(992px) {
                min-height: 294px;
                padding: 40px 18px 15px 30px;
            }
            i{
                display: inline-block;
                height: 46px;
            }
            .o-tailored__boxheading{
                font-size: 21px;
                line-height: 31px;
                margin-bottom: 15px;
            }
            .o-tailored__boxcontent{
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    .mb-30{
        margin-bottom: 30px;
    }
    &__boxheading{
        font-family: 'Poppins';
        color: $black-color;
        font-size: 20px;
        line-height: 31px;
        margin: 11px 0 7px;
        font-weight: 600;
    }
    &__boxcontent{
        font-family: 'Poppins';
        color: #727272;
        font-size: 14px;
        line-height: 22px;
        margin: 0;
    }
    &__bottomtittle{
        font-family: 'Poppins';
        color: $black-color;
        font-size: 25px;
        line-height: 40px;
        margin: 18px 0 16px;
        font-weight: 700;
        text-align: center;
    }
    &__btn{
        max-width: 100%;
        width: 100%;
        text-transform: capitalize;
        font-size: 19px;
        padding: 15px;
        @include media-query(992px) {
            max-width: 313px;
        }
        
    }
    &--company-profile-dita {
        border-bottom: 1px solid #E6E6E6;
        background: #fff !important;
        .container {
            @include media-query(1280px) {
                max-width: 1170px;
            }
        }
        .o-tailored__box--company-profile {
            border: 1px solid #DDDDDD;
            box-shadow: 0px 1px 22px 10px  rgba(0,0,0,0.05);
            @include media-query(992px) {
                min-height: 250px;
            }
            i {
                height: 50px;
            }
        }
        .o-tailored__boxheading {
            margin-top: 20px;
            margin-bottom: 5px;
            @include media-query(992px) {
                font-size: 21px;
                line-height: 25px;
                font-weight: 700;
            }
        }
        .o-tailored__boxcontent {
            font-size: 15px;
            line-height: 25px;
            color: #333;
        }
        .btn-orange {
            font-size: 16px;
            @include media-query(992px) {
                width: 280px;
                margin-top: 10px;
            }
        }
    }
    &__para {
        text-align: center;
        font-family: 'Poppins';
        color: #333333;
        font-weight: 500;
        @include media-query(992px) {
            font-size: 22px;
            line-height: 29px;
            margin-bottom: 40px;
        }
    }
}

.o-how-excellence{
    $o-how-excellence : &;
    background-color: #355BBF;
    &__spacing{
        padding: 30px 0;
        @include media-query(992px) {
            padding: 60px 0;
        }
    }
    &__heading{
        text-align: center;
        font-family: 'Poppins';
        color: $white-color;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        max-width: 100%;
        margin: 0 auto 45px;
        @include media-query(992px) {
            max-width: 85%;
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        @include media-query(992px) {
            gap: 0;
        }
    }
    &__item{
        position: relative;
        max-width: 48%;
        width: 48%;
        padding: 5px;
        display: flex;
        align-items:  center;
        justify-content: center;
        @include media-query(992px) {
            max-width: 25%;
            width: 25%;
            border-right: 1px solid #5B81E1;
            border-bottom: 1px solid #5B81E1;
        }
        @include media-query(1140px) {
            max-width: 16.5%;
            width: 16.5%;
        }
        &:after{
            display: none;
            @include media-query(992px) {
                display: block;
                position: absolute;
                content: "";
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #355BBF;
                bottom: -7.5px;
                right: -7.5px;
                z-index: 1;
            }
        }
        &:first-child{
            @include media-query(992px) {
                justify-content: flex-start;
            }
        }
        &:nth-child(2n+2){
            border-right: none;
            @include media-query(992px) {
                border-right: 1px solid #5B81E1;
            }
        }
        &:nth-child(4n+4){
            @include media-query(992px) {
                border-right: none;
            }
            @include media-query(1140px) {
                border-right: 1px solid #5B81E1;
            }
        }
        &:nth-child(6n+6){
            @include media-query(1140px) {
                border-right: none;
            }
        }
        &:last-child{
            border-right: none;
            border-bottom: none;
        }
        &:nth-last-child(-n+2){
            border-bottom: none;
        }
        &:nth-last-child(-n+3){
            @include media-query(992px) {
                border-bottom: none;
            }
        }
        &:nth-last-child(-n+4){
            @include media-query(992px) {
                border-bottom: none;
            }
        }
        &:nth-last-child(-n+5){
            @include media-query(1140px) {
                border-bottom: none;
            }
        }
        &:nth-last-child(-n+6){
            @include media-query(1140px) {
                border-bottom: none;
            }
        }
    }
    &__items{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 85%;
        width: 100%;
        @include media-query(992px) {
            max-width: 178px;
        }
        i{
            flex: 0 0 auto;
            margin-right: 10px;
            @include media-query(992px) {
                flex: 0 0 40px;
                margin-right: 9px;
            }
            @include media-query(1180px) {
                margin-left: 10px;
                margin-right: 15px;
            }
        }
    }
    &__itemcontent{
    }
    .owl-stage-outer{
        &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: 0 auto;
            width: 1px;
            height: 100%;
            background: #5b81e1;
        }
    }
    &__itemtitle{
        font-family: 'Poppins';
        color: $white-color;
        font-size: 18px !important;
        line-height: 20px !important;
        font-weight: 700;
        margin: 0;
        @include media-query(992px) {
            font-size: 20px !important;
            line-height: 24px !important;
        }
        &--f21{
            font-size: 16px !important;
            @include media-query(992px) {
                font-size: 20px !important;
            }
        }
    }
    &__itemsub{
        font-family: 'Poppins';
        color: $white-color;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        @include media-query(992px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    &__asterisktext{
        margin: 5px 0 0;
        color: #fff;
        line-height: normal;
    }
}
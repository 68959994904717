.service-pages {
  font-family: Poppins !important;
}

// Bannner
.o-skill-banner {
  $o-skill-banner: &;

  background: #0d2642 url(/images/new-home-banner/banner-gradient.png) no-repeat;
  background-position: top left;
  padding: 135px 20px 27px;
  overflow: hidden;

  &__bannertitle {
    font-family: Poppins;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;
    @include media-query(767px) {
      font-size: 41px;
      line-height: 45px;
      text-align: left;
      margin-bottom: 30px;
    }
    @include media-query(992px) {
      font-size: 51px;
      line-height: 60px;
    }
    &--dita {
      @include media-query(992px) {
        font-size: 44px;
        line-height: 56px;
      }
    }
    &--salesforce {
      @include media-query(992px) {
        font-size: 42px !important;
        line-height: 47px !important;
      }
    }
  }

  &__bannersubtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    @include media-query(767px) {
      font-size: 31px;
      line-height: 44px;
      text-align: left;
    }
    &--ver-2 {
      @include media-query(767px) {
        font-size: 28px;
        line-height: 40px;
      }
    }
    &--ver-3 {
      @include media-query(767px) {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500;
      }
    }
    &--custom-soft {
      @include media-query(767px) {
        font-size: 27px;
        line-height: 38px;
      }
    }
    &--green {
      color: #3cc065;
      @include media-query(767px) {
        font-size: 24px;
        line-height: 31px;
      }
    }
  }

  &__bannerpara {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
    }
  }

  &__subtitlelink {
    color: #fff;
    &:hover {
      span {
        background-size: 100% 100%;
      }
    }
    span {
      position: relative;
      background: -webkit-gradient(linear, left top, left bottom, from(#3cc065))
          left no-repeat,
        #fff;
      background: linear-gradient(#3cc065) left no-repeat, #fff;
      background-size: 0 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transition: 0.2s all ease-in-out;
      transition: 0.2s all ease-in-out;
      display: inline-block;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        -webkit-animation: 4s linear animate infinite;
        animation: 4s linear animate infinite;
      }
      &.link-color1 {
        &:before {
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#03a9f4),
            color-stop(#ff5800),
            color-stop(#fbba14),
            to(#03a9f4)
          );
          background: linear-gradient(
            90deg,
            #03a9f4,
            #ff5800,
            #fbba14,
            #03a9f4
          );
          background-size: 200%;
        }
      }
      &.link-color2 {
        &:before {
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#ffe70e),
            color-stop(#78e6ff),
            color-stop(#15fe8a),
            to(#ffe70e)
          );
          background: linear-gradient(
            90deg,
            #ffe70e,
            #78e6ff,
            #15fe8a,
            #ffe70e
          );
          background-size: 200%;
        }
      }
    }
  }

  &__reviewarea {
    position: relative;
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid #495fcd;
    margin-top: 50px;
    &:before {
      position: absolute;
      content: "";
      width: 3px;
      height: 70%;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #0d2642;
      @include media-query(767px) {
        width: 2px;
        left: -1px;
      }
    }
    &:after {
      position: absolute;
      content: "";
      width: 3px;
      height: 70%;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #0d2642;
      @include media-query(767px) {
        width: 2px;
        right: -1px;
      }
    }
  }

  &__reviewtitle {
    position: relative;
    font-family: Poppins;
    font-size: 15px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
    background: #0d2642;
    padding: 0 5px;
    display: inline-block;
    margin: 0;
    top: -30px;
    @include media-query(767px) {
      font-size: 18px;
    }
  }

  &__reviewlist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__reviewitem {
    padding: 0;
    @include media-query(767px) {
      width: 20%;
    }
  }

  &__rightoverlay {
    position: relative;
    margin-top: 70px;
    @include media-query(992px) {
      margin: 0;
    }
    &:before {
      position: absolute;
      content: "";
      width: 570px;
      height: 1000px;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-47%);
      background: rgb(60, 192, 101);
      background: radial-gradient(
        circle,
        rgba(60, 192, 101, 0.4) 0%,
        rgba(255, 255, 255, 0) 50%
      );
    }
  }

  &__reviewtitle {
    top: -33px;
  }
  &__reviewlist {
    position: relative;
    top: -7px;
    @include media-query(992px) {
      position: relative;
      top: -6px;
    }
  }
  &__reviewitem {
    display: flex;
    justify-content: space-around;
    &:last-child {
      .o-skill-banner__reviewitem-star {
        position: relative;
        top: -3px;
      }
    }
  }
  &__reviewitem-left {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__reviewitem-right {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &__reviewitem-icon {
    img {
      // height: 40px;
      @include media-query(992px) {
        height: inherit;
      }
    }
  }
  &__reviewitem-star {
    img {
      height: 20px;
      @include media-query(992px) {
        height: inherit;
      }
    }
  }
  &__reviewitem-name {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    @include media-query(992px) {
      font-size: 15px;
    }
  }
  &__reviewitem-number {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
    @include media-query(992px) {
      font-size: 28px;
    }
  }

  &__rightbox {
    position: relative;
    background-color: #0c315b;
    max-width: 429px;
    width: 100%;
    display: inline-block;
    border: 2px solid #3cc065;
    padding: 15px;
    @include media-query(767px) {
      padding: 29px;
    }
    &:before {
      position: absolute;
      content: "";
      width: 35%;
      height: 2px;
      background-color: #0c315b;
      left: 0;
      right: 0;
      top: -2px;
      margin: auto;
    }
    &--ver-1 {
      &:before {
        width: 83%;
      }
    }
    &--ver-2 {
      @include media-query(767px) {
        padding: 29px 20px 20px;
        margin-top: 12px;
      }
    }
    &--salesforce {
      @include media-query(767px) {
        padding: 29px 20px 40px;
      }
    }
  }
  &__rightboxtop {
    text-align: center;
    padding-top: 30px;
    @include media-query(767px) {
      padding-top: 20px;
    }
    .btn {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      max-width: 310px !important;
      padding: 12px;
      svg {
        margin-left: 7px;
      }
      @include media-query(767px) {
        font-size: 16px;
        padding: 14px;
      }
    }

    @keyframes gradient-spin {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    h5 {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 100%;
      font-size: 14px;
      background: linear-gradient(45deg, #ffe70e, #78e6ff, #15fe8a, #ffe70e);
      background-size: 300% 300%;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      animation: gradient-spin 4s infinite linear;
      @include media-query(767px) {
        font-size: 20px;
      }
    }
    &--no-padding {
      padding-top: 0;
    }
  }
  &__rightboxtopimg {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
  }
  &__rightboxtitle {
    font-family: Poppins;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
    @include media-query(767px) {
      font-size: 25px;
      line-height: 32px;
    }
    &--24 {
      font-size: 24px;
    }
    a {
      color: #fff;
      span {
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          height: 2px;
          width: 100%;
          border-radius: 10px;
          -webkit-animation: 4s linear animate infinite;
          animation: 4s linear animate infinite;
        }
        &.link-color1 {
          &:before {
            background: -webkit-gradient(
              linear,
              left top,
              right top,
              from(#03a9f4),
              color-stop(#ff5800),
              color-stop(#fbba14),
              to(#03a9f4)
            );
            background: linear-gradient(
              90deg,
              #03a9f4,
              #ff5800,
              #fbba14,
              #03a9f4
            );
            background-size: 200%;
          }
        }
        &.link-color2 {
          &:before {
            background: -webkit-gradient(
              linear,
              left top,
              right top,
              from(#ffe70e),
              color-stop(#78e6ff),
              color-stop(#15fe8a),
              to(#ffe70e)
            );
            background: linear-gradient(
              90deg,
              #ffe70e,
              #78e6ff,
              #15fe8a,
              #ffe70e
            );
            background-size: 200%;
          }
        }
      }
    }
  }
  &__rightboxbottom {
    background-color: #194b83;
    padding: 12px;
    margin-top: 18px;
    border-radius: 4px;
    @include media-query(767px) {
      padding: 25px;
      margin-top: 26px;
    }
    &--pad-20 {
      padding: 12px;
      @include media-query(767px) {
        padding: 20px;
      }
    }
    &--salesforce {
      @include media-query(767px) {
        margin-top: 40px;
      }
    }
  }
  &__rightboxbottomtitle {
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 12px;
    text-align: center;
    @include media-query(767px) {
      font-size: 17px;
      line-height: 29px;
    }
    &--font-18 {
      font-size: 12px;
      @include media-query(767px) {
        font-size: 19px;
        line-height: 29px;
      }
    }
  }
  &__rightboxbottomtext {
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    font-family: Poppins;
    margin-bottom: 0;
    @include media-query(767px) {
      font-size: 15px;
      line-height: 25px;
    }
    &--font-17 {
      font-size: 11px;
      @include media-query(767px) {
        font-size: 17px;
        line-height: 27px;
      }
    }
  }
  &__rightboxbottomlist {
    position: relative;
    counter-reset: counter;
  }
  &__rightboxbottomitem {
    font-family: Poppins;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #fff;
    text-align: left;
    @include media-query(767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: counter(counter);
      counter-increment: counter;
      color: #fff;
      background-color: #3cc065;
      display: inline-flex;
      width: 27px;
      height: 27px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-top: 4px;
      border-radius: 50%;
      float: left;
    }
    &-14 {
      font-size: 11px;
      @include media-query(767px) {
        font-size: 14px;
      }
    }
  }
  &__with-logo-wrapper {
    @include media-query(992px) {
      display: flex;
      align-items: center;
    }
  }
  &__with-logo {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-query(992px) {
      flex: 0 0 140px;
      max-width: 140px;
      margin-right: 45px;
      display: inherit;
    }
  }
  &__with-logo-partner {
    font-family: Poppins;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block;
    @include media-query(992px) {
      font-size: 31px;
      margin-bottom: 0;
    }
  }
  &--hire-salesforce {
    .o-skill-banner__bannertitle {
      @include media-query(992px) {
        font-size: 38px;
        line-height: 48px;
      }
    }
  }
  .o-skill-banner__bannersubtitle {
    @include media-query(992px) {
      font-size: 18px;
      line-height: 32px;
      font-weight: 600;
    }
  }
  .o-skill-banner__rightboxtop {
    .btn {
      @include media-query(992px) {
        max-width: 340px !important;
        padding: 14px 10px;
        font-size: 15px;
      }
    }
  }
}

// Service
.o-skill-service {
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  .custom-solution__heading {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 46px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
    &--nosub {
      margin: 0 0 30px;
      @include media-query(992px) {
        margin: 0 0 45px;
      }
    }
  }

  .custom-solution__sub-heading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    color: #474747;
    margin: 0 0 30px;
    font-weight: 500;
    display: block;
    @include media-query(992px) {
      font-size: 21px;
      line-height: 31px;
      margin: 0 0 45px;
    }
  }

  .custom-solution__card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include media-query(992px) {
      display: inherit;
    }
  }
  .custom-solution__card-gradient {
    &:before {
      display: block;
    }
  }
  .custom-solution__card-heading {
    font-family: Poppins;
    font-size: 20px;
    line-height: 25px;
    &--smaller {
      font-size: 17px;
    }
  }
  .custom-solution__card-description {
    font-family: Poppins;
    font-size: 15px;
    line-height: 25px;
    color: #333;
    margin: 0 0 15px;
    a {
      font-family: Poppins;
      color: #3cc065;
      font-weight: 500;
    }
  }
  .custom-solution__card-description-wrapper {
    margin-top: 20px;
    display: none;
    @include media-query(992px) {
    }
  }
  .card-list-bulleted {
    list-style: disc;
    padding-left: 15px;
    li {
      &::marker {
        color: #102355;
      }
    }
  }
  .custom-solution__card-gradient1 {
    display: block;
    position: relative;
    margin-bottom: 26px;
    cursor: pointer;
    overflow: hidden;
    padding: 2px;
    border: 0;
    &:before {
      content: "+";
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 36px;
      line-height: 0;
      font-weight: 300;
      color: #898989;
      z-index: 99;
    }
    &:after {
      content: "";
      position: absolute;
      top: -200px;
      right: 0;
      bottom: 0;
      left: -150px;
      background: #898989;
      width: 950px;
      height: 950px;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease;
    }
    &:hover {
      &:after {
        -webkit-animation: rotation2 3s linear infinite;
        animation: rotation2 3s linear infinite;
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#88deb1),
          color-stop(20%, #1ab864),
          color-stop(52%, rgba(35, 116, 212, 0.62)),
          to(rgba(6, 87, 181, 0.62))
        );
        background: linear-gradient(
          180deg,
          #88deb1 0,
          #1ab864 20%,
          rgba(35, 116, 212, 0.62) 52%,
          rgba(6, 87, 181, 0.62) 100%
        );
      }
    }
  }
  .custom-solution__card-gradient1--active {
    &:before {
      content: "-";
      top: 16px;
      font-size: 44px;
      color: #568fe0;
    }
    &:after {
      -webkit-animation: rotation2 3s linear infinite;
      animation: rotation2 3s linear infinite;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#88deb1),
        color-stop(20%, #1ab864),
        color-stop(52%, rgba(35, 116, 212, 0.62)),
        to(rgba(6, 87, 181, 0.62))
      );
      background: linear-gradient(
        180deg,
        #88deb1 0,
        #1ab864 20%,
        rgba(35, 116, 212, 0.62) 52%,
        rgba(6, 87, 181, 0.62) 100%
      );
    }
  }
  .custom-solution__card-description {
    display: block;
  }
  .btn-v2 {
    width: 100%;
    max-width: 434px;
    font-size: 16px;
    @include media-query(767px) {
      flex: 0 0 434px;
      font-size: 17px;
    }
  }
  .btn-v2-small {
    max-width: 310px;
    @include media-query(767px) {
      flex: auto;
      font-size: 17px;
    }
  }
}

// Expert Section
.skill-technologies-tab {
  $skill-technologies-tab: &;

  &__heading {
    font-family: Poppins;
    font-size: 26px;
    line-height: 35px;
    color: #262e36;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  &__subheading {
    font-family: Poppins;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #3cc065;
    text-align: center;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 34px;
    }
  }
  .o-accordion__tab {
    font-family: Poppins;
    font-size: 24px;
    line-height: 55px;
    color: #262e36;
    font-weight: 700;
    padding: 15px 0;
    border: none;
    flex-shrink: 0;
    &--small {
      font-size: 22px;
    }
    &--short {
      font-size: 23px;
    }
    @include media-query(992px) {
      padding: 0;
    }
    &:hover {
      color: #355bbf;
      @include media-query(992px) {
        border-color: #e1e1e1;
      }
    }
    &.active {
      color: #355bbf;
      @include media-query(992px) {
        border-color: #e1e1e1;
      }
    }
  }
  .o-accordion__content {
    @include media-query(992px) {
      background: #355bbf;
    }
  }
  .o-accordion__content-background {
    @include media-query(992px) {
      background: #355bbf;
    }
  }
  .pool-section {
    padding: 0;
    border: none;
  }
  .o-accordion__content-title {
    font-family: Poppins;
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    @include media-query(992px) {
      font-size: 36px;
      line-height: 42px;
      color: #fff;
    }
  }
  .o-accordion__btn {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    margin: 50px auto 0;
    max-width: 100%;
    @include media-query(767px) {
      max-width: fit-content;
    }
    @include media-query(992px) {
      margin: 50px 0 0;
      font-size: 19px;
    }
  }
  .technologies-tab__area {
    padding-top: 0;
    @include media-query(992px) {
      padding-top: 50px;
    }
  }
  .o-accordion__item {
    padding-top: 30px;
  }
  &__bottom-link {
    font-family: Poppins;
    font-size: 14px;
    line-height: 26px;
    color: #355bbf;
    text-decoration: underline;
    font-weight: 500;
    margin-top: 10px;
    display: block;
    text-align: center;
    @include media-query(992px) {
      display: inline-block;
      color: #bed7ee;
    }
    &:hover {
      color: #000;
      @include media-query(992px) {
        color: #fff;
      }
    }
  }
}

.ai-technologies-tab {
  .o-accordion__tab {
    line-height: 35px;
    margin-bottom: 25px;

    span {
      padding-right: 10px;
    }
  }
}

// Skill logo
.skill-logo {
  .o-clients {
    background-color: #f9f9f9;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
  h2 {
    font-family: Poppins;
    color: #000;
    font-size: 25px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 20px;
    @include media-query(992px) {
      margin-bottom: 10px;
    }
  }
}

// Expentencies
.o-core-competencies {
  $o-core-competencies: &;

  &--servicenow {
    .btn-orange {
      @include media-query(992px) {
        max-width: 260px;
      }
    }
  }

  &--border-box1 {
    border: 1px solid #20dea5!important;
   }
   &--border-box2 {
    border: 1px solid #f29906!important;
   }
   &--border-box3 {
    border: 1px solid #64b9f4!important;
   }
   &--border-box4 {
    border: 1px solid #4fa941!important;
   }
   &--border-box5 {
    border: 1px solid #355bbf!important;
   }
   &--border-box6 {
    border: 1px solid #fbba14!important;
   }

  &--hover-box1 {
   &:hover{
    border: 1px solid #20dea5;
   }
  }
  &--hover-box2 {
    &:hover{
     border: 1px solid #f29906;
    }
   }
   &--hover-box3 {
    &:hover{
     border: 1px solid #64b9f4;
    }
   }
   &--hover-box4 {
    &:hover{
     border: 1px solid #4fa941;
    }
   }
   &--hover-box5 {
    &:hover{
     border: 1px solid #fbba14;
    }
   } &--hover-box6 {
    &:hover{
     border: 1px solid #355bbf;
    }
   }

  &--leftlist {
    #{$o-core-competencies}__box {
      text-align: left;
      padding: 16px 30px 30px;
      box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease-out;
      &:hover {
        background: #04254a;
        border-color: #04254a;
        .o-core-competencies__heading {
          color: #fff;
        }
        .o-core-competencies__content {
          color: #fff;
        }
        #{$o-core-competencies}__item {
          color: #fff;
          &:before {
            background: url(/images/new-skill-section/icons/white-circle-icon.png)
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    i {
      padding: 10px;
      background: #fff;
      border-radius: 12px;
      width: 85px;
      height: 85px;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
    }
    #{$o-core-competencies}__list {
      margin-top: 15px;
    }
    #{$o-core-competencies}__item {
      position: relative;
      font-family: Poppins;
      font-size: 15px;
      line-height: 25px;
      color: #333;
      margin: 0;
      padding-left: 30px;
      margin-bottom: 8px;
      transition: all 0.5s ease-out;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        position: absolute;
        background: url(/images/e-commerce/black-circle-icon.png) no-repeat;
        background-size: 100% 100%;
        width: 20px;
        height: 20px;
        top: 2px;
        left: 0;
      }
    }
    #{$o-core-competencies}__btn {
      text-transform: inherit;
    }
  }

  &--noboximage {
    #{$o-core-competencies}__box {
      min-height: auto;
    }
  }

  &--noheight {
    #{$o-core-competencies}__box {
      min-height: auto;
      padding: 25px;
      text-align: left;
    }
    i {
      margin-bottom: 0;
    }
    #{$o-core-competencies}__heading {
      font-size: 18px;
      margin: 7px 0 12px;
    }
  }

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  &__subtitle {
    font-family: Poppins;
    font-size: 21px;
    line-height: 31px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    @include media-query(992px) {
      margin-bottom: 50px;
    }
    &-small{
      font-size: 15px;
      margin-bottom:10px;
    }
    &-smallfaq{
      font-size: 15px;
      margin-bottom:10px;
      margin-top: 40px;
    }
  }

  &__box {
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    min-height: auto;
    height: 100%;
    text-align: center;
    padding: 30px 25px;
    @include media-query(992px) {
      min-height: 469px;
      padding: 40px 35px;
    }
    &--little {
      @include media-query(992px) {
        min-height: 350px;
      }
    }
    i {
      margin-bottom: 15px;
      // display: inline-block;
      height: 70px;
      display: inline-flex;
      align-items: center;
    }
    &--no-fix {
      @include media-query(992px) {
        min-height: auto;
      }
    }
    &--ai {
      @include media-query(992px) {
        min-height: auto;
      }
    }
  }

  &__heading {
    font-family: Poppins;
    color: #333333;
    font-size: 21px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    transition: all 0.2s ease-out;
  }

  &__content {
    font-family: Poppins;
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin: 0;
    transition: all 0.2s ease-out;
    a {
      color: #3cc065;
      font-weight: 500;
      transition: all 0.5s ease-in-out;
    }
  }

  &__btn {
    font-size: 16px;
    max-width: 384px;
    text-transform: capitalize;
    margin: 30px auto 0;
    width: 100%;
    padding: 16px;
    @include media-query(767px) {
      font-size: 17px;
    }
    @include media-query(992px) {
      margin: 20px auto 0;
    }
    &--notcap {
      text-transform: inherit;
    }
  }
}

// Flow
.o-skill-flow {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 30px 0;
  overflow: hidden;
  @include media-query(992px) {
    padding: 60px 0;
  }
  &__title {
    font-family: Poppins;
    font-size: 30px !important;
    line-height: 35px !important;
    font-weight: 700 !important;
    color: #262e36;
    margin: 0;
    @include media-query(767px) {
      font-size: 38px !important;
      line-height: 45px !important;
    }
    @include media-query(992px) {
      font-size: 48px !important;
      line-height: 58px !important;
      margin-bottom: 50px;
    }
  }
  &__block-label {
    text-align: center;
    margin: 30px 0;
    @include media-query(992px) {
      margin: 0 0 40px;
    }
    span {
      font-family: Poppins;
      position: relative;
      display: inline-block;
      font-size: 25px;
      line-height: 25px;
      color: #333333;
      font-weight: 600;
      &:before {
        position: absolute;
        content: "";
        width: 44px;
        height: 1px;
        background-color: #898989;
        left: -60px;
        top: 50%;
      }
      &:after {
        position: absolute;
        content: "";
        width: 44px;
        height: 1px;
        background-color: #898989;
        right: -60px;
        top: 50%;
      }
    }
  }
  .faq-holder {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: none;
    background-color: transparent;
    &.d-active {
      background-color: #fff;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
      .o-skill-flow__panel {
        max-height: fit-content;
      }
    }
  }
  .accordion1 {
    &:after {
      color: #4492dc !important;
    }
  }
  &__btn-title {
    font-family: Poppins;
    font-weight: 600;
    color: #262e36;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  &__panel {
    padding: 0 18px;
    background-color: #fff;
    overflow: hidden;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    max-height: 0;
    p {
      font-family: Poppins;
      font-size: 20px;
      line-height: 30px;
      color: #262e36;
      margin-bottom: 12px;
    }
  }
  &__tool-heading {
    color: #4492dc !important;
    font-size: 18px !important;
    line-height: 25px !important;
    margin: 0 0 8px !important;
  }
  &__small-p {
    font-family: Poppins;
    font-size: 15px !important;
    line-height: 25px !important;
    margin: 0;
  }
  &__btn {
    font-family: Poppins;
    font-size: 17px;
    max-width: 384px;
    text-transform: capitalize;
    margin: 30px auto 0;
    width: 100%;
    padding: 16px;
  }
}

// Easy Step

.o-easy-step {
  $o-easy-ste: &;

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    text-align: center;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  &__subtitle {
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    color: #333;
    text-align: center;
    font-weight: 500;
    @include media-query(767px) {
      font-size: 21px;
      line-height: 31px;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 75px;
    @include media-query(992px) {
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row;
      margin-top: 80px;
    }
  }

  &__items {
    position: relative;
    max-width: 330px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dddddd;
    text-align: center;
    padding: 0 32px 35px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 120px;
    @include media-query(767px) {
      margin-bottom: 100px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @include media-query(992px) {
      width: 28%;
      margin-bottom: 0;
    }
    @include media-query(1200px) {
      width: 30%;
    }
    &:after {
      position: absolute;
      content: "";
      width: 55px;
      height: 20px;
      background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat
        center;
      background-size: 100%;
      top: inherit;
      transform: rotate(90deg);
      right: 0;
      left: 0;
      bottom: -40px;
      margin: auto;
      @include media-query(992px) {
        transform: translateY(-50%);
        left: inherit;
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
        right: -55px;
        margin: 0;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__counter {
    position: relative;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    background-color: rgba(53, 91, 191, 0.1);
    font-family: Poppins;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -50px auto 20px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: #355bbf;
    }
  }

  &__heading {
    font-family: Poppins;
    color: #333333;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__content {
    font-family: Poppins;
    color: #333333;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  &__contentbold {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }

  &__link {
    font-family: Poppins;
    color: #4492dc;
    font-size: 14px;
    line-height: 22px;
  }

  &__btn {
    width: 100%;
    max-width: 100%;
    font-size: 19px;
    font-weight: 600;
    margin: 50px auto 0;
    text-transform: capitalize;
    padding: 16px;
    max-width: 100%;
    @include media-query(767px) {
      max-width: 330px;
    }
  }
}

// Journy
.o-journey {
  $o-journey: &;
  background-color: #f9f9f9;
  border-top: 1px solid #e4e4e4;

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 35px 0 45px;
    }
  }

  &__title {
    font-family: Poppins;
    color: #0e2743;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 10px;
    @include media-query(767px) {
      font-size: 21px;
      line-height: 38px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-query(992px) {
      margin: 10px 15px !important;
      display: inherit;
      align-items: flex-start;
    }
  }
  .owl-stage {
    display: flex;
    align-items: center;
  }
  .owl-carousel {
    .owl-item {
      img {
        height: 94%;
        object-fit: contain;
        @include media-query(992px) {
          height: inherit;
          object-fit: inherit;
        }
      }
    }
  }
}

// How we Help
.o-skill-how-help {
  background: #052c58 url(/images/new-home-banner/banner-gradient.png) no-repeat;

  .how-help-you__heading {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px;
    @include media-query(992px) {
      font-size: 48px;
      line-height: 62px;
      margin-bottom: 45px;
    }
  }
  .how-help-you__card-heading {
    @include media-query(992px) {
      line-height: 46px;
    }
  }
  .how-we-help-buttons {
    padding: 15px 0;
    height: 58px;
    font-size: 14px;
    @include media-query(992px) {
      font-size: 17px;
    }
  }
  .how-we-help-knowmore {
    font-family: Poppins;
    text-align: center;
    font-size: 14px;
    margin: 15px auto 0;
    text-decoration: underline;
    font-weight: 600;
    display: inline-block;
    color: #4492dc;
    &:hover {
      color: #ff5800;
    }
  }
  .let-help {
    @include media-query(992px) {
      padding: 0 18px 20px 18px;
    }
    @include media-query(1600px) {
      padding: 0 19px 20px 19px;
    }
    @include media-query(1705px) {
      padding: 0 18px 20px 18px;
    }
    @include media-query(1920px) {
      max-width: 1075px;
      padding: 0 18px 20px 18px;
    }
    &:before {
      border: none;
    }
  }
  .let-help__inner-wrapper {
    @include media-query(1600px) {
      padding: 17px 14px 17px 14px;
    }
    @include media-query(1705px) {
      padding: 17px 12px 17px 12px;
    }
    @include media-query(1920px) {
      padding: 17px 12px 17px 15px;
    }
  }
  .let-help__checkbox-label {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
  .let-help__btn {
    width: 360px;
    font-size: 14px;
    font-weight: 600;
    padding: 17px 12px;
    @include media-query(992px) {
      font-size: 17px;
    }
  }
}

// Review Skill
.skill-review {
  .custom-review__partner {
    display: none;
  }
}

// Why this Skill

.o-why-hire {
  $o-why-hire: &;
  background-color: #f9f9f9;
  border-top: 1px solid #e4e4e4;

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 50px;
    }
  }

  &__subheading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;

    @include media-query(992px) {
      font-size: 21px;
      line-height: 31px;
      margin-bottom: 60px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dae9fb;

    &--massonary {
      display: inherit;
      column-count: auto;
      column-gap: 0;

      @include media-query(767px) {
        column-count: 2;
      }

      .o-why-hire__items {
        page-break-inside: avoid;
        width: 100%;

        &:nth-child(even) {
          @include media-query(767px) {
            border-right: 1px solid #dae9fb;
            padding-left: 0;
          }
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          @include media-query(767px) {
            padding-right: 30px;
            border-right: none;
          }
        }

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11) {
          @include media-query(767px) {
            border-right: none;
            border-left: 1px solid #dae9fb;
            padding-right: 0;
          }

          @include media-query(767px) {
            padding-left: 30px;
          }
        }

        .content-part-side {
          ol {
            padding-left: 18px;

            li {
              font-family: Poppins;
              font-size: 15px;
              line-height: 25px;
              color: #262e36;
              margin: 0;
            }
          }
        }
      }
    }
  }

  &__items {
    width: 100%;
    padding: 20px;
    border: 1px solid #dae9fb;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include media-query(767px) {
      width: 50%;
      border-left: none;
      border-bottom: none;
    }
    @include media-query(992px) {
      padding: 40px 0;
      flex-direction: row;
    }
    .content-part-side {
      margin-left: 0;
      margin-top: 20px;
      @include media-query(992px) {
        margin-left: 10px;
        margin-top: 0;
      }
      h5 {
        font-family: Poppins;
        font-size: 22px;
        line-height: 27px;
        font-weight: 700;
        margin-bottom: 5px;
        color: #262e36;
      }
      p {
        font-family: Poppins;
        font-size: 15px;
        line-height: 25px;
        color: #262e36;
        margin: 0;
      }
    }
    &:nth-child(even) {
      @include media-query(767px) {
        padding: 40px 0 40px 30px;
        border-right: none;
      }
    }
    &:nth-child(odd) {
      @include media-query(767px) {
        padding: 40px 30px 40px 0;
      }
    }
  }
  &__btn {
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 40px auto 0;
    max-width: 100%;
    width: 100%;
    padding: 16px;
    @include media-query(767px) {
      max-width: 305px;
    }

    &--django {
      max-width: 335px;
    }
  }

  .btn--lg {
    max-width: 345px;
  }
  &--hire-salesforce {
    .o-why-hire__title {
      @include media-query(992px) {
        margin-bottom: 25px;
      }
    }
  }
}

// Bottom Form
.skill-bottom-form {
  .new-bottom-form {
    background: #052c58 url(/images/new-home-banner/banner-gradient.png)
      no-repeat !important;
    background-position: 0 0;
  }
}

// Bottom Work
.o-bottom-work {
  $o-bottom-work: &;
  background: #081c36 url(/images/new-skill-section/left-top-courner.png)
    no-repeat;

  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 45px 0;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 830px;
    width: 100%;
    margin: auto;
    border: 1px solid #63ff93;
    flex-direction: column;
    @include media-query(992px) {
      flex-direction: row;
    }
    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 101%;
      background: rgb(8, 28, 54);
      background: linear-gradient(
        90deg,
        rgba(8, 28, 54, 0.95) 0%,
        rgba(8, 28, 54, 0.7) 70%,
        rgba(8, 28, 54, 0.1) 100%
      );
      left: -1px;
      top: -1px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 50%;
      height: 101%;
      background: rgb(8, 28, 54);
      background: linear-gradient(
        270deg,
        rgba(8, 28, 54, 0.95) 0%,
        rgba(8, 28, 54, 0.7) 70%,
        rgba(8, 28, 54, 0.1) 100%
      );
      right: -1px;
      top: -1px;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #63ff93;
    padding: 35px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media-query(767px) {
      flex-direction: row;
      justify-content: space-between;
    }
    @include media-query(992px) {
      width: 50%;
      border-bottom: none;
      border-right: 1px solid #63ff93;
    }
    &:first-child {
      #{$o-bottom-work}__images {
        margin-left: 0;
        @include media-query(992px) {
          margin-left: -115px;
        }
      }
    }
    &:last-child {
      border-right: none;
      flex-direction: column-reverse;
      @include media-query(767px) {
        flex-direction: row-reverse;
      }
      @include media-query(992px) {
        flex-direction: row;
      }
      #{$o-bottom-work}__images {
        margin-right: 0;
        background-color: #081c36;
        @include media-query(992px) {
          margin-right: -90px;
        }
      }
      #{$o-bottom-work}__text-wrap {
        flex: 0 0 68%;
        @include media-query(992px) {
          flex: 0 0 72%;
        }
      }
    }
  }

  &__images {
    position: relative;
    z-index: 1;
  }

  &__text-wrap {
    flex: 0 0 68%;
    position: relative;
    z-index: 1;
    @include media-query(992px) {
      flex: 0 0 77%;
    }
  }

  &__heading-text {
    font-family: Poppins;
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    color: #fff;
    @include media-query(992px) {
      font-size: 31px;
      line-height: 38px;
    }
  }

  &__heigh-text {
    color: #36e079;
  }

  &__content-text {
    font-family: Poppins;
    color: #36e079;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
    @include media-query(992px) {
      font-size: 19px;
    }
  }
}

.for-tablet {
  display: block;
  @include media-query(1024px) {
    display: none;
  }
}

// faq section
.o-skill-faq {
  h2 {
    padding-bottom: 15px;
    margin: 0;
    @include media-query(992px) {
      line-height: 58px;
    }
  }
  &__sub-heading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 34px;
    }
  }
  .accordion1 {
    span {
      width: 95%;
      line-height: 28px;
    }
  }
}

.o-skill-testimonial {
  .o-testimonial__heading {
    font-size: 32px;
    line-height: 40px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 45px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  .o-testimonial__itemimage {
    height: 259px;
  }
  .o-testimonial__itemcontent {
    padding: 20px 25px 35px 25px;
    min-height: auto;
    height: auto;
    background-color: #fff;
    @include media-query(992px) {
      min-height: 276px;
    }
  }
  .o-testimonial__itemtitle {
    font-family: Poppins;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .o-testimonial__itemtech {
    font-family: Poppins;
    font-size: 15px;
    line-height: 22px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .o-testimonial__itembtn {
    font-family: Poppins;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
  }
  .o-testimonial__list {
    .owl-nav {
      margin-top: 40px;
    }
  }
}
.o-key-diff-modify {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  padding: 30px 0;
  @include media-query(992px) {
    padding: 60px 0;
  }
  h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 46px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  .cmn-heading-para {
    font-family: Poppins;
    font-size: 16px;
    line-height: 26px;
    color: #333;
    text-align: center !important;
    font-weight: 500 !important;
    @include media-query(992px) {
      font-size: 21px;
      line-height: 31px;
    }
  }
  .clode-computing-right-side-text {
    h4 {
      font-family: Poppins;
      font-weight: 700;
    }
    p {
      font-family: Poppins;
    }
  }
}

.leverage-section__modify {
  padding: 30px 0;
  @include media-query(992px) {
    padding: 60px 0;
  }
  h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 46px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  .o-key-diff-box {
    h4 {
      font-family: Poppins;
      font-weight: 700;
    }
    p {
      font-family: Poppins;
    }
  }
}
.iot-case__modify {
  h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 15px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 46px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  .iot-case-wrapper-box {
    h3 {
      font-family: Poppins;
      font-weight: 700;
      margin: 20px 0;
    }
    li {
      font-family: Poppins;
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.what-test-section--servicenow {
  .btn-orange {
    font-size: 17px;
    max-width: 260px;
  }
}
.what-test-section__modify {
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 30px 0;
  @include media-query(992px) {
    padding: 60px 0;
  }
  h2 {
    font-family: Poppins;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
    color: #262e36;
    margin-bottom: 30px;
    @include media-query(767px) {
      font-size: 38px;
      line-height: 46px;
    }
    @include media-query(992px) {
      font-size: 48px;
      line-height: 58px;
    }
  }
  li {
    font-family: Poppins;
  }
}

.mobile-app {
  font-family: Poppins !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Poppins !important;
  }

  .spiral-col-box {
    p {
      margin-bottom: 0;
    }
  }
}

.no-border {
  border: none !important;
}
.normal-text-button {
  text-transform: inherit;
}
.light-text-button {
  text-transform: initial;
  height: 62px;
  padding: 16px;
}
.skill-technologies-tab {
  .pool-section .pool-box-list {
    height: 77px;
    img {
      max-height: 38px;
    }
  }
}
.title-margin-bottom {
  margin: 0 0 20px !important;
  @include media-query(992px) {
    margin: 0 0 35px !important;
  }
}

.o-skill-service {
  .padding-bottom-30 {
    padding-bottom: 5px;
    @include media-query(992px) {
      padding-bottom: 30px;
    }
  }
  .experience-list {
    ul {
      li {
        a {
          position: relative;
          padding: 13px 15px;
          display: flex;
          z-index: 1;
          height: 100%;
          align-items: center;
          justify-content: center;
          @include media-query(992px) {
            padding: 30px 15px;
          }
        }
      }
    }
  }
}
.mb-75 {
  @include media-query(992px) {
    margin-bottom: 75px !important;
  }
}
.mb-55 {
  @include media-query(992px) {
    margin-bottom: 55px !important;
  }
}

.mobile-app-serv {
  .custom-solution__card-heading {
    @include media-query(992px) {
      font-size: 19px;
    }
  }
}
span.underline {
  position: relative;
  display: inline-block;
  &:before {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    background-color: #fff;
    height: 2px;
    width: 100%;
  }
}

.dita-engagement {
  .how-help-you__card-heading {
    @include media-query(992px) {
      font-size: 29px;
      line-height: 42px;
    }
  }
}
.btn {
  &--notcap {
    text-transform: initial;
  }
}

.what-are--staff-aug {
  .what-are__title {
    font-weight: 500;
  }
}

.why-choose--staff-aug {
  .why-choose__heading {
    font-weight: 500;
    @include media-query(992px) {
      margin-bottom: 60px;
    }
  }
  .why-choose__list {
    @include media-query(992px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .why-choose__item {
    max-width: inherit;
    background: #fff;
    border: 1px solid #d0e3f5;
    @include media-query(992px) {
      flex: 0 0 49%;
      height: 230px;
    }
    &:before {
      display: none;
    }
  }
  .why-choose__items {
    background: #fff;
  }
  .why-choose__items-top {
    display: flex;
    align-items: center;
    i {
      margin-right: 15px;
      display: inline-block;
    }
  }
  .why-choose__bottom-text {
    font-family: Poppins;
    font-size: 30px;
    line-height: 40px;
    color: #262e36;
    margin-top: 35px;
    font-weight: 500;
  }
}

.development-banner {
  background: #0d2642;
  .o-skill-banner__rightbox {
    &::before {
      display: none;
    }
  }
}

.dev-service {
  .custom-solution__heading {
    font-weight: 500;
  }
  .custom-solution__sub-heading {
    font-weight: 500;
  }
  .custom-solution__card-description-wrapper {
    @include media-query(992px) {
      margin: 0;
      padding: 0 20px;
    }
  }
  .custom-solution__card-gradient1 {
    padding: 0;
    border: 1px solid #d7d6d6;
    margin-bottom: 15px;
    @include media-query(992px) {
      margin-bottom: 30px;
    }
    &:after {
      display: none;
    }
    &:before {
      font-family: "Montserrat",sans-serif;
      font-weight: 400;
      @include media-query(992px) {
        top: 50px;
        right: 25px;
      }
    }
    &.custom-solution__card-gradient1-color1 {
      border-bottom: 4px solid #64b9f4;
    }
    &.custom-solution__card-gradient1-color2 {
      border-bottom: 4px solid #ffc327;
    }
    &.custom-solution__card-gradient1-color3 {
      border-bottom: 4px solid #20DEA5;
    }
    &.custom-solution__card-gradient1-color4 {
      border-bottom: 4px solid #A15FAE;
    }
    &.custom-solution__card-gradient1-color5 {
      border-bottom: 4px solid #FDA474;
    }
  }
  .custom-solution__collapse-heading {
    @include media-query(992px) {
      min-height: 100px;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
  }
  .custom-solution__card-heading {
    @include media-query(992px) {
      font-size: 22px;
      line-height: 25px;
    }
  }
  .custom-solution__card {
    background: none;
    @include media-query(992px) {
      padding: 24px 20px 24px 20px;
      padding: 0;
    }
  }
}

.banner {
  $banner: &;
  &.development-banner {
    background: #0F1858 url(/images/custom-dev/banner-pattern.jpg) no-repeat 0 0 /
      cover;
    position: relative;
    padding: 70px 20px 30px;
    @include media-query(992px) {
      padding: 150px 20px 65px;
    }
    .o-skill-banner__rightbox {
      background: #1A2979;
      border-color: #5158D6;
    }
  }
  .o-skill-banner__rightbox--ver-2 {
    @include media-query(992px) {
      padding: 70px 50px 70px;
      margin-top: 0;
    }
  }
  .o-skill-banner__rightoverlay {
    margin-top: 20px;
    @include media-query(992px) {
      margin-top: 0;
    }
  }
  .o-skill-banner__rightboxtop {
    padding-top: 0;
  }

  .o-skill-banner__rightboxtitle {
    @include media-query(992px) {
      font-size: 28px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    a {
      span {
        &:before {
          height: 4px;
        }
      }
    }
  }
  &__top-rated {
    margin-top: 25px;
    @include media-query(992px) {
      max-width: 466px;
      display: flex;
      border: 1px solid #5158D6;
      border-radius: 5px;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 40px;
      background: #1A2979;
    }
  }
  &__top-rated-text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    @include media-query(992px) {
      padding: 14px 15px;
      text-align: left;
    }
  }
  &__top-rated-highlight {
    color: #84a5f9;
    font-weight: 700;
  }
  &__top-rating {
    margin: 20px auto;
    flex: 0 0 183px;
    max-width: 183px;
    border-radius: 5px;
    @include media-query(992px) {
      margin: 0;
      border-radius: 0;
    }
    /* Style for the Splide container */
    .splide__track {
      overflow: hidden;
    }

    /* Style for each slide */
    .splide__slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /* Add any additional styles if needed */
  }
  &__top-rating-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: center;
  }
  &__top-rating-left {
    padding-right: 10px;
  }
  &__top-rating-right {
    flex-direction: column;
    display: flex;
    position: relative;
    padding-left: 13px;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      background: #d7dfe6;
      opacity: 0.5;
      width: 1px;
      height: 29px;
      text-align: left;
    }
  }
  &__top-rating-number {
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
    text-align: left;
  }
  &__top-rating-review {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
  }

  .o-skill-banner__bannertitle {
    @include media-query(992px) {
      margin-bottom: 20px;
    }
  }

  .o-skill-banner__bannersubtitle {
    color: #cee8ff;
    font-weight: 500;
    @include media-query(992px) {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

.dev-service-benefit {
    h2 {
        font-weight: 500 !important;
        @include media-query(992px) {
            margin-bottom: 20px !important;
        }
    }
  .service-card {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 30px;
      width: 68px;
      height: 6px;
      background: #000;
    }
  }
  .service-card-color1 {
    &::before {
      background: #64b9f4;
    }
  }
  .service-card-color2 {
    &::before {
      background: #f29906;
    }
  }
  .service-card-color3 {
    &::before {
      background: #20dea5;
    }
  }
  .service-card-color4 {
    &::before {
      background: #4fa941;
    }
  }
  .service-card-color5 {
    &::before {
      background: #fbba14;
    }
  }
  .service-card-color6 {
    &::before {
      background: #a15fae;
    }
  }
}

.custom-dev-tech {
  border-bottom: 1px solid #D8D8D8;
  .o-accordion__content-background {
    background: #F7F9FB;
    border: 1px solid #D8D8D8;
    border-bottom: none;
  }
  .o-accordion__content-title {
    color: #333333;
    font-weight: 600;
  }
  .o-accordion__content {
    background: #F7F9FB;
    @include media-query(992px) {
      border: 1px solid #D8D8D8;
      border-bottom: none;
      border-right: none;
    }
  }
  .o-accordion__button {
    font-weight: 600;
  }
  .skill-technologies-tab__bottom-link {
    color: #355bbf;
  }
  .o-accordion__tab {
    @include media-query(992px) {
      line-height: 50px;
    }
    font-weight: 600;
  }
  .o-accordion__tab.active {
    border-bottom: 1px solid #DDE2EA;
  }
//   .pool-section {
//     .pool-box-list {
//         @include media-query(992px) {
//             padding: 1px;
//         }
//     }
//   }
.pool-section {
  .pool-box-list {
    small {
      @include media-query(992px) {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.o-accordion__btn {
  @include media-query(992px) {
    margin-top: 35px;
  }
}
.tab-pane {
  &:last-child {
    .o-accordion__content {
      border-bottom: none;
    }
  }
}
}

.custom-dev-method {
  background: #fff;
  .custom-solution__sub-heading {
    @include media-query(992px) {
      margin: 0 0 30px;
    }
  }
  .o-we-develop__box {
    border-radius: 5px;
    border: 1px solid #ccc;
    border-bottom-width: 3px;
    box-shadow: none;
  }
  .o-we-develop__box-color1 {
    border-color: #64B9F4;
  }
  .o-we-develop__box-color2 {
    border-color: #20DEA5;
  }
  .o-we-develop__box-color3 {
    border-color: #FDA474;
  }
  .o-we-develop__box-color4 {
    border-color: #A15FAE;
  }
  .o-we-develop__boxcontent {
    font-size: 16px;
  }
}

.custom-dev-journey {
    border-top: none;
}


.o-testimonial__heading {
    .o-testimonial__heading {
        font-weight: 500;
    }
}

.custom-dev-case-study {
  .o-testimonial__heading {
    font-weight: 500;
  }
  .o-testimonial__item {
    width: 100%;
  }
  .o-testimonial__itemtitle {
    font-weight: 600;
    font-size: 21px;
  }
  &--staff-aug-it {
    .o-testimonial__item {
      @include media-query(992px) {
        margin: 0;
      }
    }
    .o-testimonial__itemcontent {
      min-height: inherit;
      height: inherit;
      border: none;
    }
  }
}
.custom-dev-faq {
    h2 {
        font-weight: 500;
    }
}

.splide__list--data-eng {
  height: auto !important;
}

.splide__list--auto-height {
  height: auto !important;
}


.static-image {
  display: block; /* Show by default */
}

.js-banner-review-slider {
  display: none; /* Hide slider initially */
}



.main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px;
}
.mainInner {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}
.mainInner div {
  display: table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px;
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  right: 0;
  width: 250px;
  transform: translateX(250px);
  transition: transform 250ms ease-in-out;
  background-color:#333;
  background: url(../images/choosing-img.jpg) ;
  background-position: 60% 0;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  z-index: 9;

  &.active{
    width: 100%;
  }

  &:after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}

.sidebarInner{
  padding-top: 50px;
  position: relative;
  z-index: 3;
}


  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (orientation : landscape){
    .sidebarInner{
    height: 280px;
      overflow: scroll;
  }
  }

.o-menuTitle{
  width: 100%;
  color: #fff;
  font-size: 10px;
  margin-bottom: 25px;
  letter-spacing: 2px;
}

.sidebarMenuInner {
  margin: 0;
  padding: 0;
}
.sidebarMenuInner li {
  list-style: none;
  color: #7B3E23;
  text-transform: uppercase;
  cursor: pointer;
}
.sidebarMenuInner li a {
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;
  padding: 15px 5px;
  display: inline-block;
  position: relative;
  transition: all 0.5s;
  font-size: 35px;
  line-height: normal;
  font-family: 'Prata', serif;
}

@media screen and (max-width: 480px){
  .sidebarMenuInner li a {
    font-size: 24px;
  }
}

.sidebarMenuInner li a::after {
  content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 50px;
    height: 1px;
    background-color: #a7a6a6;
    -webkit-transition: all .5s;
    transition: all .5s;
    transform: translateX(-50%);
}
.sidebarMenuInner li a:hover{
  padding-left: 30px !important;
  transition: all 0.5s;
}
.sidebarMenuInner li a:hover::after {
  width: 15px;
  transition: all 0.5s;
}

.o-side-menu .sidebarMenuInner li.active a{
  color: #ff0085 !important;
}

.o-side-menu input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

.o-side-menu input[type="checkbox"] {
  transition: all 0.5s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
    cursor: pointer;
    position: absolute;
    z-index: 99;
    top: 12px;
    //top: 50%;
    right: 15px !important;
    margin: 0;
    padding: 0;
    //transform: translateY(-50%);
}

.sidebarIconToggle span{
  color: #fff;
  text-transform: uppercase;
  margin: 5px -3px;
  display: inline-block;
  font-size: 11px;
}

.spinner {
  transition: all 0.3s;
  height: 3px;
  margin-bottom: 5px;
  background-color: #333;
}
.horizontal {
  transition: all 0.3s;
  position: relative;
}
.diagonal.long {
  width: 37px;
}
.horizontal.short {
  width: 24px;
}
.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 0;
  background: #333;
  width: 25px;
}
.o-side-menu input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  background: #333;
  margin-top: -17px;
  width: 25px;
}

.blackBG{
    z-index: 9;
    background: rgba(255,255,255,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
}

.o-fixedBody{
  .sidebarIconToggle{
    position: fixed;
    top: 33px;
    transform: translateY(0);
  }
}

.hire-top-one {
    @include media-query(992px) {
        padding: 70px 0 0;
    }
    .max-width-1024-center {
        padding-top: 10px;
        margin-bottom: 0;
        @include media-query(992px) {
            max-width: 900px;
            margin: 0 auto;
            font-size: 19px;
        }
    }
    .text-bg {
        box-shadow: none;
        border: 0;
        padding: 30px 0 10px;
        margin-bottom: 0;
        @include media-query(992px) {
            padding: 50px 40px 30px;
            padding-bottom: 0;
        }
    }
    h3 {
        @include media-query(992px) {
            margin-bottom: 20px;
        }
        width: 100% !important; 
    }
    .text-bg {
        &:after {
            display: none;
        }
        &:before {
            display: none !important;
        }
    }
    .how-it-works-holder {
        background: none;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background: #f5f5f5;
            left: 10px;
            top: 0;
            border-radius: 5px;
            width: 4px;
            height: 98%;
            @include media-query(992px) {
                right: 0;
                margin: 0 auto;
                left: 0;
            }
        }
        .col-lg-5 {
            position: relative;
        }
        .blue-c {
            position: absolute;
            border: 8px solid #fff;
            left: -58px;
            @include media-query(992px) {
                top: 40px;
            }
            span {
                width: 50px;
                height: 50px;
                background: #1B7AAF;
                color: #fff;
                border-radius: 50%;
                border: 5px solid #fff;
                box-shadow: 0px 0px 0px 2px rgba(205, 225, 251, 1);
                font-size: 20px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .blue-c1 {
            @include media-query(992px) {
                right: -113px;
                left: inherit;
            }
            @include media-query(1200px) {
                right: -127px;
            }
            @include media-query(1280px) {
                right: -130px;
            }
        }
        .blue-c2 {
            @include media-query(992px) {
                left: -113px;
            }
            @include media-query(1200px) {
                left: -127px;
            }
            @include media-query(1280px) {
                left: -130px;
            }
        }
        .blue-c3 {
            @include media-query(992px) {
                right: -113px;
                left: inherit;
            }
            @include media-query(1200px) {
                right: -127px;
            }
            @include media-query(1280px) {
                right: -130px;
            }
        }
        .blue-c4 {
            @include media-query(992px) {
                left: -113px;
            }
            @include media-query(1200px) {
                left: -127px;
            }
            @include media-query(1280px) {
                left: -130px;
            }
        }
        .blue-c5 {
            @include media-query(992px) {
                right: -113px;
                left: inherit;
            }
            @include media-query(1200px) {
                right: -127px;
            }
            @include media-query(1280px) {
                right: -130px;
            }
        }
        padding-left: 50px;
        @include media-query(992px) {
            padding-left: 0;
            margin: 25px 0 50px;
        }
        img {
            @include media-query(992px) {
                width: 100%;
            }
        }
    }
    p {
        font-size: 16px;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 27px;
        }
        strong {
            font-weight: 600;
        }
    }
}
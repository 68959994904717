.font-bold {
	font-weight: 700;
}

//	.u-pull-left {
//		@include tablet-sm {
//			float: left !important;
//		}
//		float: none !important;
//	}

//	.anchor-link {
//		max-width: 340px;
//		font-size: 14px;
//		font-weight: 600;
//		margin-top: 10px;
//	}

	/** AI & Chatbots **/
	.ai-pattern-bl {
		&:before {
			background: url(images/ai-chatbots/bottom-left-pattern.png) no-repeat bottom left;
			bottom: 0px;
			height: auto;
		}
		&:after {
			display: none;
		}
	}
	.ai-chatbots {
		padding-bottom: 50px;
		background-color: #f8f8f8;
		h2 {

				padding: 0 50px;
				margin-bottom: 50px;
			margin-bottom: 20px;
            font-size: 30px;
            // font-weight: 700;
            @include responsive(desktop){
                font-size: 48px;
            }
		}
		h3 {
			text-align: center;
				font-size: 36px;
			font-size: 28px;
			margin-bottom: 25px;
			margin-top: 30px;
		}
		.section-para {
			font-size: 19px;
			line-height: 29px;
			text-align: center;
                font-size: 16px;
                line-height: 28px;
		}
		.dc-white-pan {
				min-height: inherit;
				height: auto;
					min-height: inherit;
					box-shadow: 0px 0px 34px 0px #0000001c;
                    height: auto;
                    @include responsive(desktop){
                        height: 180px;
                    }
			div {
					span {
						position: absolute;
						width: 88px;
						height: 88px;
						left: 0;
						img {
							margin-right: 20px;
						}
					}
				}
			}
		.body-text-medium {
			font-size: 16px;
			line-height: 28px;
			color: #333333;
			font-family: $main-font;
			font-weight: 500;
			margin-top: 15px;
            padding-left: 0;
            padding-top: 100px;
            text-align: center;
            @include media-query(992px) {
                font-size: 15px;
			    line-height: 24px;
                padding-left: 100px;
                padding-top: 0;
                text-align: left;
            }
            @include media-query(1200px) {
                font-size: 18px;
			    line-height: 28px;
            }
		}
		.correct-img {
				text-align: inherit;
			text-align: center;
			img {
					width: 100%;
					margin: 0;
					width: 300px;
					margin: 0 auto;
			}
		}
		.number-section-bnt {
		text-align: center;
		margin-bottom: 25px;
		a {
				width: 470px;
				height: 72px;
				margin: 0 15px 15px;
				font-size: 24px;
				width: 470px;
			display: inline-block;
			padding: 10px 50px 10px 15px;
			line-height: 46px;
			background: transparent;
			position: relative;
			text-align: center;
			color: #fff;
			font-size: 13px;
			font-family: $main-font;
			border-radius: 6px;
			border: 3px solid #14DF7D;
			margin-bottom: 15px;
			transition: .3s all ease-in;
			&:before {
				content: '';
				position: absolute;
				background: url(images/common/right-circle-icon.png) no-repeat right center;
				height: 35px;
				width: 35px;
				top: 15px;
				right: 10px;
					right: 30px;
			}
			&:hover {
				background: #fff;
				color: #FF5800;
				border: 3px solid #FF5800;
			}
		}
	}
	}
// .web-application {
// 	color: #fff;
// }




/*CHeaper and better scss*/
.text-capitalize {
	text-transform: capitalize !important;
}
.skill-mod {
    padding: 45px 0 47px;
    @include responsive(desktop){
	    padding: 70px 0 75px;
    }
    background-size: 30% auto;
    text-align: center;
	h2 {
		font-family: "Montserrat",sans-serif;
		color: #333;
		margin-bottom: 25px;
        font-size: 30px;
        line-height: 36px;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 61px;
        }
	}
	p {
		font-family: $secondary-font;
		font-weight: 400;
		color: #414446;
		font-size: 19px;
		line-height: 30px;
			padding-right: 80px;
			padding-left: 80px;
	}
	.holder {
	    text-align: left;
	    margin-bottom: 20px;
	    .listing-heading {
    		text-align: left;
    		padding-bottom: 35px;
    		display: flex;
			align-items: center;
    		img.listing-heading-icon {
			    display: inline-block;
			    vertical-align: top;
			    margin: 0;
			}
			h3.listing-heading-txt {
			    display: inline-block;
			    margin: 0;
			    padding-left: 30px;
                @include responsive(desktop){
                    font-size: 36px;
                    line-height: 49px;
                }
			}
		}
		ul {
    		text-align: left;
    		margin: 0px 0px 25px 0;
    		li {
    			padding: 0 0 5px 35px;
				line-height: 28px;
				position: relative;
				display: block;
                font-family: $main-font;
                @include responsive(desktop){
				    font-family: $main-font;
                    font-weight: 500;
                    padding: 0 0 15px 35px;
                }
				font-weight: 400;
				color: #414446;
				font-size: 17px;
    			&:before {
    				content: '';
					display: block;
					width: 20px;
					height: 20px;
					position: absolute;
					top: 2px;
					left: 0px;
					background: url(/images/common/icon-bullet-dark.svg) 50% 50% no-repeat;
					background-size: auto;
					background-size: contain;
    			}
    		}
		}
	}
	h5 {
		font-size: 22px;
		line-height: 28px;
		font-weight: 300;
		margin: 0px 0px 35px 0px;
        font-family: $secondary-font;
	}
}
img.cb-wecare-heading.cb-wecare-heading-img {
    width: 130px;
    @include responsive(desktop){
        width: auto;
    }
}
.tech-mod {
    padding: 45px 0 20px;
    @include responsive(desktop){
	    padding: 70px 0 50px;
    }
    background-size: 30% auto;
    text-align: center;
   	h2 {
    	font-family: "Montserrat",sans-serif;
		color: #333;
		margin-bottom: 25px;
		text-align: center;
    }
    h5 {
    	font-weight: 400;
		padding-bottom: 40px;
		font-size: 18px;
		line-height: 30px;
		text-align: center;
    }
	// padding: 40px 0 80px;
	// background: url('//assets/images/common/bottom-pattern-r.png') no-repeat bottom right $light-grey;
	// background-size: 50% auto;
	// text-align: center;

    /* media query for tablet potrait */
    // @include tablet-sm {
    //     padding: 50px 0;
    // }

    &--no-background{
        background: none;

        /* media query for tablet landscape */
            padding: 100px 0 120px 0;
    }

    &--inline-block{

        /* media query for tablet landscape */
                width: 49%;
                vertical-align: top;

        /* media query for small desktop and above */
                display: inline-block;
                float: none;
                width: 24%;
    }

    &__blocks{
        border-radius: 6px;
        font-size: 18px;
        line-height: 26px;
        padding: 40px;
        margin-bottom: 30px;
        background-color: #fff;
        // @include prefixed-box-shadow (0px, 0px, 24px, rgba(0,0,0,0.1));
        margin-top: 8px;
        h5{
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            /* media query for tablet landscape */
                font-size: 24px;
                line-height: 32px;
        }
        h3{
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 20px;

            /* media query for tablet landscape */
                font-size: 24px;
                line-height: 32px;
        }
        span.technologies-sub-heaidng{
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;

            /* media query for tablet landscape */
                font-size: 24px;
                line-height: 32px;
        }

        &__icons{

            .tech-icon {
                width: 132px;
                height: 132px;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 25px;

                img {
                    display: block;
                    position: absolute;
                    z-index: 10;
                    top: 50%;
                    left: 50%;
                    // @include prefixed-transform (translate(-50%, -50%));
                }

                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 5;
                    background-image: url('//assets/images/common/why-us-oval.svg');
                    // @include prefixed-animation (spin, 30s, linear, 0s, infinite);
                }

                // &.delay-1s:before {
                //     @include prefixed-animation (spin, 30s, linear, 1s, infinite);
                // }

                // &.delay-2s:before {
                //     @include prefixed-animation (spin, 30s, linear, 2s, infinite);
                // }

                // &.delay-3s:before {
                //     @include prefixed-animation (spin, 30s, linear, 3.5s, infinite);
                // }

                // &.delay-4s:before {
                //     @include prefixed-animation (spin, 30s, linear, 2.5s, infinite);
                // }

                // &.delay-5s:before {
                //     @include prefixed-animation (spin, 30s, linear, 5s, infinite);
                // }
             }
        }



        &__lists{
            text-align: left;
            width: 147px;
            margin: 27px auto 0 auto;

            li{
                line-height: 30px;
                margin-bottom: 14px;
                position: relative;
                white-space: nowrap;
                height: 30px;
                i{
                    width: 30px;
                    display: inline-block;
                    margin-right: 20px;
                }

                a{
                    color: $black;
                    font-size: 14px;
                    line-height: 30px;
                    display: inline-block;
                    vertical-align: top;
                    // @include montserrat-semi-bold;

                    &:hover{
                        color: #666666;
                    }
                }

                &:last-child{
                    &:after{
                       border: none;
                    }
                }

                &:after{
                    position: absolute;
                    content: "";
                    left:48px;
                    bottom:-8px;
                    width: 95px;
                    height: 1px;
                    border-bottom: 1px solid #E7E7E7;
                }

            }
        }
    }

    &__samefee{
        h2{
            margin-bottom: 40px;
            text-align: center;
        }
        h5{
            font-weight: 600;
            padding-bottom: 40px;
            text-align: center;
        }
        .samefee-left-pan{
            background-color: #fff;
            text-align: center;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            -moz-box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            padding-top: 60px;
            padding-bottom: 60px;
            /* mobile first for tablet portrait */
                   padding-top:110px;

            h5{
                font-size: 20px;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 10px;
                    padding-bottom: 40px;
            }
            img{
                margin-bottom: 35px;
            }
            p{
                padding-left: 15px;
                padding-right: 15px;
                    padding-left: 40px;
                    padding-right: 40px;
                font-size: 18px;
				line-height: 32px;
                font-family: $secondary-font;
				font-weight: 300;
				color: #414446;
            }
        }
        .samefee-mid-pan{
            padding-top: 5%;
            padding-bottom: 6%;
            /* mobile first for tablet portrait */
                    padding-top: 20%;
        }
        .samefee-right-pan{
            background-color: #fff;
            text-align: center;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            -moz-box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            box-shadow: 10px 11px 45px 0px rgba(227,227,227,1);
            padding: 30px 0;
            h3{
                background: url('images/common/cheaper-and-better-heading-bg.png') no-repeat 50% 0;
                margin-bottom: 20px;
                font-size: 30px;
                font-family: 'Caveat', cursive;
                font-weight: normal;
            }
            .samefee-right-pan-holder{
                width: 100%;
                position: relative;
                display: inline-block;
                padding: 30px 20px;
                vertical-align: top;
                /* mobile first for tablet portrait */
                    width: 32%;
                    width: 33%;

                h5{
                    font-size: 20px;
                    padding-bottom: 10px;
                        padding-bottom: 40px;
                }
                p {
                	font-size: 18px;
					line-height: 32px;
	                font-family: $secondary-font;
					font-weight: 300;
					color: #414446;
                }
                img{
                    margin-bottom: 35px;
                }
            }
            .samefee-right-pan-mid{
                border-left: 0;
                border-right: 0;
                border-top: 1px solid #E7E7E7;
                border-bottom: 1px solid #E7E7E7;
                /* mobile first for tablet portrait */
                        border-left: 1px solid #E7E7E7;
                        border-right: 1px solid #E7E7E7;
                        border-top: 0;
                        border-bottom: 0;
                &:after{
                    position: absolute;
                    right: -17px;
                    top:24%;
                    content: "";
                    background: url('images/common/cheaper-and-better-icon-9.png') no-repeat 0 0;
                    width: 34px;
                    height: 34px;
                    display: none;
                    /* mobile first for tablet portrait */
                        display: block;
                }
                &:before{
                    position: absolute;
                    left: -17px;
                    top:24%;
                    content: "";
                    background: url('images/common/cheaper-and-better-icon-9.png') no-repeat 0 0;
                    width: 34px;
                    height: 34px;
                    display: none;
                    // transform: translate(-50%, 0);
                    /* mobile first for tablet portrait */
                        display: block;
                }

            }

        }
    }
    .js-tech-col {
        min-height: inherit;
            min-height: 735px;
            min-height: 608px;

    }
}

.margin-top-30-i {
	margin-top: 30px !important;
}
.margin-top-50-i {
    margin-top: 50px !important;
}

.cb-wecare {
    .margin-top-50-i {
        margin-top: 30px !important;
        @include responsive(desktop){
            margin-top: 50px !important;
        }
    }
}
.o-apply-now {
    padding: 50px 0 50px;
    @include responsive(desktop){
	    padding: 70px 0 75px;
    }
	background: #00b35d;
background: -moz-linear-gradient(-45deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
background: -webkit-linear-gradient(-45deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
background: linear-gradient(135deg, #00b35d 0%, #1db39b 16%, #28b2b3 24%, #004475 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35d', endColorstr='#004475',GradientType=1 );
	position: relative;
	text-align: center;
	h5 {
    	color: #fff;
    	font-size: 36px;
		line-height: 48px;
		font-weight: 400;
		margin-bottom: 40px;
	}
}

.hiring-section {
    .margin-top-50-i {
        margin-top: 5px !important;
        @include responsive(desktop){
            margin-top: 50px !important;
        }
    }
}

.big-data {
    padding: 35px 0 20px;
    @include responsive(desktop){
        padding: 70px 0;
    }
	p {
		font-family: "Montserrat",sans-serif;
	}
	.heighlight-txt {
		font-family: "Montserrat",sans-serif;
		span {
			font-family: "Montserrat",sans-serif;
		}
	}
    .right-banner-txt {
        width: 90%;
        margin: 0 auto;
            width: 58.33333333%;
            margin: 0;
    }
}

.font-weight-400 {
    font-weight: 400 !important;
}


/*Work with page*/
.u-pad-top-30 {
    padding-top: 30px;
}

.margin-left-0 {
    margin-left: 0 !important;
}
.work-bnr {
    padding: 35px 0 65px 0;
    .margin-25 {
        margin-bottom: 15px;
        @include responsive(desktop){
            margin-bottom: 25px;
        }
    }
    h1 {
        font-size: 20px;
        line-height: 28px;
        padding-top: 40px;
        font-weight: 400;
        padding-top: 50px;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
            font-size: 24px;
            line-height: 34px;
            padding-top: 95px;
        }
    }
}

.o-two-col-section-mod {
    background: url(/images/common/bottom-left-pt.png) left bottom no-repeat;
    background-size: 30% auto !important;
    h2 {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        @include responsive(desktop){
            font-size: 42px;
            line-height: 52px;
        }
    }
    p {
        text-align: center;
        font-family: $secondary-font;
        font-weight: 400;
        color: #414446;
        font-size: 18px;
        line-height: 28px;
        margin-top: 30px;
    }
}

.o-two-col-section__key-traits {
    text-align: center;
    background-color: #F9F9F9;
    position: relative;
    text-align: center;
    h2 {
        margin-bottom: 30px;
        font-size: 30px;
        @include responsive(desktop){
            font-size: 48px;
        }
    }
    p {
        font-family: $secondary-font;
        font-weight: 400;
        color: #414446;
        font-size: 18px;
        line-height: 30px;
        margin-top: 0;
        @include responsive(desktop){
            margin-top: 30px;
        }
    }
    ul {
        height: 420px;
        height: auto;
        margin-bottom: 0;
        @include responsive(desktop){
            padding-top: 50px;
        }
        &:after {
            clear: both;
            content: "";
        }
        li {
            .style_prevu_kit {
                margin-bottom: 20px;
                width: 100%;
                overflow: hidden;
                display: inline-block;
                border: 0;
                height: auto;
                position: relative;
                transition: all 400ms ease-in;
                transform: scale(1);
                cursor: pointer;
                border-radius: 6px;
                box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
                padding: 30px 20px;
                border: 1px solid #e0e0e0;
                @include responsive(desktop){
                    height: 310px;
                    padding: 80px 0;
                }
                img {
                    margin-bottom: 40px;
                        margin-bottom: 0;
                }
                h2 {
                    font-size: 22px;
                    line-height: 28px;
                    text-transform: none;
                    padding-top: 20px;
                    display: none;
                    @include responsive(desktop){
                        display: block;
                    }
                    br {
                        display: none;
                        @include responsive(desktop){
                            display: block;
                        }
                    }
                }
                .icon-text-hover {
                    opacity: 1;
                    @include responsive(desktop){
                        opacity: 0;
                    }
                }
                h3 {
                    text-align: center;
                    margin: 15px 0;
                    font-size: 20px;
                    @include responsive(desktop){
                        margin-bottom: 50px;
                        font-size: 22px;
                        margin: 30px 0;
                    }
                }
                @media(hover: hover) and (pointer: fine) {
                &:hover {
                    box-shadow: 0px 0px 10px #ccc;
                        z-index: 2;
                        -webkit-transition: all 400ms ease-in;
                        -webkit-transform: scale(1.4);
                        -ms-transition: all 400ms ease-in;
                        -ms-transform: scale(1.4);
                        -moz-transition: all 400ms ease-in;
                        -moz-transform: scale(1.4);
                        transition: all 400ms ease-in;
                        transform: scale(1.4);
                        background-color: #094A79;
                        color: #fff;
                        padding: 30px 0;

                    .icon-text-hover {
                        opacity: 1;
                            padding: 20px;
                        padding: 0;
                        h3 {
                                font-size: 15px;
                                line-height: 22px;
                                color: #fff;
                                text-align: left;
                                margin-bottom: 50px;
                                font-size: 18px;
                                line-height: 28px;
                                margin: 0;
                            color: #333333;
                            text-align: center;
                            padding: 0;


                        }
                        p {
                                color: #fff;
                                font-size: 11px;
                                line-height: 16px;
                                margin-top: 15px;
                                text-align: left;
                                font-size: 12px;
                                line-height: 18px;
                                margin-top: 30px;
                            color: #333333;
                            text-align: center;
                        }
                    }
                    img, .o-two-col-section__key-traits ul li .style_prevu_kit:hover h2 {
                        display: inline-block;
                            display: none;

                    }
                    h2 {
                        display: none;
                    }
                }
            }
            }
        }
    }
}
// .pt-mod {
//     background: url(images/common/small-bottom-right-pt.png) right bottom no-repeat #FAFAFA;
//     background-size: 30% auto !important;
// }

.o-two-col-section__choosing-steps {
    background: url(/images/common/bottom-left-pt.png) bottom left no-repeat;
    background-size: 30% auto !important;
    padding-top: 80px;
    position: relative;
    h2 {
        margin-bottom: 30px;
        font-size: 30px;
        @include responsive(desktop){
            font-size: 45px;
            margin-bottom: 50px;
        }
    }
    .steps-holder {
        padding: 10px 0;
        @include responsive(desktop){
            padding: 10px;
            margin-bottom: 50px;
        }
        margin-bottom: 20px;
        clear: both;
        display: table;
        width: 100%;
        .steps-point {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid #3E93DA;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background-color: #fff;
            z-index: 1;
        }
        .step-left-img {
            float: none;
            width: 100%;
            text-align: center;
            padding-bottom: 40px;
            padding-top: 0;
            @include responsive(desktop){
                float: left;
                width: 33%;
                text-align: left;
                padding-bottom: 0;
                padding-top: 50px;
            }
            img {
                display: block;
                margin: 0;
                    margin: 0 auto;
                    display: inherit;
            }
        }
        .step-right-content {
            float: none;
            width: 100%;
            box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
            padding: 40px 15px;
            border-radius: 6px;
            border: 1px solid #E0E0E0;
            @include responsive(desktop){
                float: right;
                width: 67%;
                padding: 40px;
            }
            span {
                font-size: 20px;
                font-weight: 500;
                color: #3E93DA;
                padding-bottom: 25px;
                text-align: center;
                display: block;
                @include responsive(desktop){
                   text-align: left;
                   font-family: $main-font;
                }
            }
            h3 {
                text-align: center;
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 29px;
                text-transform: none;
                font-family: "Montserrat",sans-serif;
                font-weight: 600;
                color: #333;
                @include responsive(desktop){
                   text-align: left;
                   margin-bottom: 0;
                   font-size: 24px;
                }
            }
            p {
                padding-bottom: 20px;
                font-family: $main-font;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #333;
                padding-bottom: 0 !important;
                text-align: center;
                @include responsive(desktop){
                    font-size: 18px;
                    line-height: 32px;
                    text-align: left;
                    font-family: $main-font;
                }
            }
            ul {
                li {
                    padding-left: 20px;
                    padding-bottom: 20px;
                    font-family: $main-font;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333;
                    text-align: center;
                    background: url(/images/work-with-the-top-developers/blue-tick.png) 0 8px no-repeat;
                    @include responsive(desktop){
                        font-size: 18px;
                        line-height: 32px;
                        text-align: left;
                        font-family: $main-font;
                    }
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .u-text-left {
        @include responsive(desktop){
            text-align: left;
        }
    }
    .step-1 {
        position: relative;
        &:before {
            border-bottom: 1px dashed #3E93DA;
            height: 1px;
            position: absolute;
            left: 320px;
            top: 200px;
            content: "";
            width: 61px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        &:after {
            border-left: 1px dashed #3E93DA;
            height: 200px;
            position: absolute;
            left: 320px;
            top: 0;
            content: "";
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        .steps-point {
            position: absolute;
            left: 310px;
            top: 0;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
    }
    .step-2 {
        position: relative;
        padding: 10px 0;
        @include responsive(desktop){
            padding: 10px;
        }
        margin-bottom: 50px;
        clear: both;
        display: table;
        width: 100%;
        &:before {
            border-bottom: 1px dashed #3E93DA;
            height: 1px;
            position: absolute;
            right: 320px;
            top: 150px;
            content: "";
            width: 61px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        &:after {
            border-left: 1px dashed #3E93DA;
            height: 210px;
            position: absolute;
            right: 320px;
            top: -60px;
            content: "";
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        .steps-point-2 {
            position: absolute;
            right: 310px;
            top: 40px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
    }
    .step-3 {
        position: relative;
        &:before {
            border-bottom: 1px dashed #3E93DA;
            height: 1px;
            position: absolute;
            left: 320px;
            top: 200px;
            content: "";
            width: 61px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        &:after {
            border-left: 1px dashed #3E93DA;
            height: 260px;
            position: absolute;
            left: 320px;
            top: -60px;
            content: "";
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        .steps-point-3 {
            position: absolute;
            left: 310px;
            top: 50px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
    }
    .step-4 {
        position: relative;
        padding: 10px 0;
        @include responsive(desktop){
            padding: 10px;
            margin-bottom: 50px;
        }
        margin-bottom: 20px;
        clear: both;
        display: table;
        width: 100%;
        &:before {
            border-bottom: 1px dashed #3E93DA;
            height: 1px;
            position: absolute;
            right: 320px;
            top: 150px;
            content: "";
            width: 61px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        &:after {
            border-left: 1px dashed #3E93DA;
            height: 210px;
            position: absolute;
            right: 320px;
            top: -60px;
            content: "";
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        .steps-point-4 {
            position: absolute;
            right: 310px;
            top: 50px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
    }
    .step-5 {
        position: relative;
        &:before {
            border-bottom: 1px dashed #3E93DA;
            height: 1px;
            position: absolute;
            left: 320px;
            top: 200px;
            content: "";
            width: 61px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        &:after {
            border-left: 1px dashed #3E93DA;
            height: 260px;
            position: absolute;
            left: 320px;
            top: -60px;
            content: "";
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
        .steps-point-5 {
            position: absolute;
            left: 310px;
            top: 50px;
            display: none;
            @include media-query(1200px) {
                display: block;
            }
        }
    }
}
.o-two-col-section__why-choose-us-mod {
    // background: url(/images/common/small-bottom-right-pt.png) 100% 100% no-repeat;
    background-size: 30% auto !important;
    background-color: #F9F9F9;
    text-align: center;
    padding: 80px 0 60px;
    h2 {
        margin-bottom: 30px;
    }
    p {
        font-family: $secondary-font;
        font-size: 18px;
        line-height: 28px;
        @include responsive(desktop){
            padding: 0;
        }
    }
}
.u-align-right {
    text-align: center;
    @include responsive(desktop){
        text-align: right !important;
    }
}
.u-pull-right {
    float: none !important;
    @include responsive(desktop){
        float: right !important;
    }
}


/*CN uses AI*/
.o-skills-hero--cn-uses-ai {
    padding: 35px 0;
        padding: 35px 0 0 0;
    .container {
        position: relative;
        padding-bottom: 0;
        .machine-img {
                position: absolute;
                right: 0;
                bottom: 0;
        }
        .extra-padding {
                padding-bottom: 80px;
            padding-bottom: 0;
        }
    }
    .margin-25 {
        margin-bottom: 25px;
    }
    h1 {
        font-size: 24px;
        line-height: 32px;
        padding-top: 0;
        text-align: center;
        @include responsive(desktop){
            font-size: 46px;
            line-height: 58px;
            text-align: left;
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        margin: 0px 0px 25px 0px;
        padding: 0px 0px 0px 0px;
        text-align: center;
        @include media-query(992px) {
            font-size: 20px;
            line-height: 32px;
            text-align: left;
            font-family: $main-font;
        }
        @include media-query(1200px) {
            font-size: 22px;
            line-height: 32px;
        }
    }
}

.o-skill-intro--cn-uses-ai {
    // background: url(images/common/bottom-left-pt.png) left bottom no-repeat;
    background-size: auto;
    background-size: 30% auto;
    text-align: center;
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding:70px 0 47px;
    }
    h3 {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 28px;
        @include responsive(desktop){
            font-size: 36px;
            line-height: 44px;
        }
    }
    p {
       font-family: $main-font;
       font-size: 16px;
        line-height: 28px;
       @include responsive(desktop){
            font-size: 18px;
            line-height: 30px;
            font-family: $main-font;
       }
    }
}

.o-skill-intro--puzzle {
    background-color: #F9FBFC;
    background-image: none;
        padding: 40px 0 20px;
        // background: url("/images/common/small-bottom-right-pt.png") right bottom no-repeat #F9FBFC;
        background-size: auto;
        background-size: 30% auto;
        @include responsive(desktop){
            padding: 70px 0 50px;
        }
    p {
        font-family: $main-font;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        @include responsive(desktop){
            font-size: 18px;
            line-height: 30px;
        }
    }
    .font-20 {
        font-family: "Montserrat",sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        @include responsive(desktop){
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
        }
    }
    img {
        text-align: center;
        margin: 40px auto;
        display: block;
    }

}

.project-management {
    // background: url(images/common/bottom-left-pt.png) left bottom no-repeat;
    background-size: auto;
    background-size: 30% auto !important;
    position: relative;
    text-align: center;
    padding: 30px 0 15px;
    @include responsive(desktop){
        padding: 70px 0 45px;
        background-size: 30% auto;
    }
    img {
        margin: 0 0 20px;
    }
    h2 {
        font-size: 24px;
        line-height: 30px;
        text-transform: capitalize;
        margin: 0px 0px 20px 0px;
        @include responsive(desktop){
            font-size: 42px;
            line-height: 52px;
            margin: 0px 0px 30px 0px;
        }
    }
    p {
        font-family: $main-font;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        margin: 0px 0px 25px 0px;
        @include responsive(desktop){
            font-family: $main-font;
        }
    }
}

.o-skill-intro--better {
    background-color: #F8F9FB;
    background-image: none;
    position: relative;
    padding: 40px 0 20px;
    text-align: center;
    @include responsive(desktop){
        padding: 70px 0 50px;
        background-size: auto;
        background-size: 30% auto;
    }
    h2 {
        font-size: 24px;
        line-height: 30px;
        margin: 0px 0px 25px 0px;
        @include responsive(desktop){
            font-size: 42px;
            line-height: 52px;
            margin: 0px 0px 50px 0px;
        }
        text-transform: capitalize;
    }
    p {
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin: 0px 0px 25px 0px;
        @include responsive(desktop){
            font-size: 18px;
            line-height: 30px;
            font-family: $main-font;
        }
    }
    .white-bg {
        background-color: #fff;
        text-align: center;
        padding: 0 0 30px 0;
        width: 100%;
        margin: 0 0 20px;
        min-height: initial;
        flex: 0 0 100%;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
        @include responsive(desktop){
            text-align: left;
            margin: 40px 15px 40px;
            min-height: 640px;
            padding: 0;
            width: inherit;
            flex: 1;
        }
        h3 {
            font-size: 18px;
            line-height: 22px;
            color: #222222;
            padding: 30px 35px 15px;
            position: relative;
            margin-bottom: 20px;
            text-align: left;
            @include responsive(desktop){
                padding: 30px 35px 20px;
            }
            &:before {
                content: '';
                position: absolute;
                background-color: #27c070;
                height: 3px;
                width: 35px;
                bottom: 0;
                left: 35px;
                // right: 0;
                margin: 0 auto;
                @include responsive(desktop){
                    left: 15px;
                    right: inherit;
                    margin: 0;
                    left: 35px;
                }
            }
        }
        p {
            @include responsive(desktop) {
                padding: 0 35px;
                font-family: $main-font;
            }
            padding: 0 35px;
            font-family: $main-font;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
        }
        img {
            width: 100%;
        }
    }
    .btn_wrapper {
        @include responsive(desktop) {
            margin-top: 0;
        }
        margin-top: 30px;
    }
}



.key-benefits {
    &:after {
        display: none !important;
    }
    .main-span {
        font-weight: 400;
        line-height: 28px;
    }
    .service-wrap {
       .services-pan {
        padding-top: 40px !important;
        @include responsive(desktop){
            padding: 40px 20px 30px !important;
        }
            &:before {
                display: none;
            }
            p {
                font-size: 16px !important;
                line-height: 26px !important;
                font-family: $main-font !important;
            }
            height: auto;
                @include media-query(992px) {
                    min-height: 515px !important;
                }
        }
    }
    .cmn-heading-para-w {
        margin-bottom: 15px;
        @include responsive(desktop){
            margin-bottom: 20px;
        }
    }
}

.o-skill-intro--conclusion {
    position: relative;
    padding: 40px 0 20px;
    text-align: center;
    @include responsive(desktop) {
        padding: 70px 0 50px;
        // background: url("/images/common/small-bottom-right-pt.png") right bottom no-repeat;
        background-size: auto;
        background-size: 30% auto;
    }
    h2 {
        font-size: 30px;
        line-height: 36px;
        text-transform: capitalize;
        @include responsive(desktop) {
            margin: 0px 0px 25px 0px;
            font-size: 48px;
            line-height: 52px;
        }
    }
    p {
        font-family: $main-font;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        @include responsive(desktop) {
            font-family: $main-font;
        }
    }
}

.left-banner-txt-mod {
        padding-top: 40px;
}


.bottom-right-pt {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        background: url(/images/work-with-the-top-developers/bottom-right-pt.png) bottom right;
        background-repeat: no-repeat;
        z-index: 9;
        width: 615px;
        height: 325px;
        bottom: 0;
        right: 0;
        display: none;
        @include media-query(992px) {
            display: block;
        }
    }
}
.bottom-left-pt {
        position: absolute;
        background: url(/images/common/bottom-left-pt.png) bottom left;
        background-repeat: no-repeat;
        z-index: 9;
        width: 615px;
        height: 325px;
        bottom: 0;
        left: 0;
        display: none;
            display: block;
}


.padding-0-100 {
    padding: 0 !important;
        @include responsive(desktop){
            padding: 0 100px !important;
        }
}

.margin-bottom-15 {
    margin-bottom: 20px;
}
.index-bnr-logo {
    margin-bottom: 35px;
    svg, img {
        margin: 10px 5px;
    }
}


.machine-img {
    z-index: 1;
    bottom: -15px;
    position: relative;
    max-width: 480px;
    width: auto;
    padding: 0 30px;
    margin: 0 auto;
    @include responsive(desktop){
        position: absolute;
        text-align: right;
        text-align: center;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        padding: 0 15px;
        max-width: none;
        width: 51%;
        right: 30px;
    }
}
.custom-align {
    text-align: right;
        text-align: center;
        margin-bottom: 15px;
}
.ex-padding-top {
        margin-top: 20px;
}



// .how-it-works-holder {
//     span.blue {
//         font-size: 36px;
//         font-family: 'Montserrat', sans-serif;
//         color: #5EACF9 !important;
//         margin-bottom: 15px;
//         display: inline-block;
//         font-weight: 600;
//     }
//     span.green {
//         font-size: 36px;
//         font-family: 'Montserrat', sans-serif;
//         margin-bottom: 15px;
//         display: inline-block;
//         font-weight: 600;
//     }
//     span.orange {
//         font-size: 36px;
//         font-family: 'Montserrat', sans-serif;
//         margin-bottom: 15px;
//         display: inline-block;
//         font-weight: 600;
//     }
//     h3 {
//         font-size: 24px;
//     }
//     p {
//         font-size: 19px !important;
//         line-height: 30px !important;
//     }
//     img {
//         margin: 15px auto 0;
//             margin: 50px auto 0;
//     }
//     .text-bg {
//         max-width: inherit;
//             max-width: 514px !important;
//             max-width: 413px;

//     }
//     .custom-left {
//             float: left;
//     }
//     .custom-right {
//             float: right;
//     }
// }

// .o-services-intro-2 .text-bg-1::before {
//     left: -71px !important;
// }
// .o-services-intro-2 .text-bg-2::before {
//     right: -71px !important;
// }
// .o-services-intro-2 .text-bg-3::before {
//     left: -71px !important;
// }
// .o-services-intro-2 .text-bg-4::before {
//     right: -71px !important;
// }
// .o-services-intro-2 .text-bg-5::before {
//     left: -71px !important;
// }
.ex-padding-bottom2 {
        padding-bottom: 200px;
}




/*office-and-infastructure*/
.description-box {
    padding-top: 15px;
    @include responsive(desktop){
        padding-top: 80px;
    }
    p {
        font-size: 19px;
        line-height: 30px;
    }
    .description-box-img {
            height: 500px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .frame-text {
            padding: 30px 30px 5px 30px;
            max-height: 500px;
            @include responsive(desktop){
                padding: 40px 50px;
            }
            max-height: 500px;
            border: 1px solid #f3f3f3;
        p {
            font-size: 13px;
            line-height: 22px;
                font-family: $main-font;
                @include responsive(desktop){
                    font-family: $main-font;
                    font-size: 16px;
                    line-height: 30px;
                }
        }
    }
    .frame-text2 {
        padding: 20px 15px;
            padding: 40px 40px 20px;
    }
    .o-open-office--description {
        .description-box-img {
            position: relative;
            span {
                bottom: 30px;
                padding: 20px 15px;
                   @include responsive(desktop){
                    padding: 40px 40px;
                   }
            }
            .m-left {
                left: 0;
                    left: 0;
            }
            .m-right {
                right: 0;
                    right: 0;
            }
        }
    }
}
.o-office-design2 {
    @include responsive(desktop){
        padding: 70px 0 80px;
    }
}
.here-details {
    p {
        font-family: 'Montserrat', sans-serif;
        line-height: 22px;
        margin-bottom: 15px;
        font-weight: 600;
        @include responsive(desktop){
            margin: 0 0 25px 0;
            line-height: 28px;
        }
    }
    .h-are-the {
        margin-bottom: 35px;
        font-size: 17px;
        font-weight: 500;
        @include responsive(desktop){
            margin: 0 0 25px 0;
            font-size: 19px;
            font-weight: 600;
        }
    }
    // .btn_wrapper {
    //     margin-bottom: 30px;
    // }
}
.frame-text3 {
    padding: 15px;
    height: auto;
    margin-bottom: 15px;
        height: 500px;
        padding: 0;
    border: 1px solid #f3f3f3;
    position: relative;
    h3 {
        padding: 0 0 40px;
    }
}
.frame-text4 {
    padding: 15px;
    height: auto;
    margin-bottom: 15px;
        height: 543px;
        padding: 0;
    border: 1px solid #f3f3f3;
    position: relative;
    h3 {
        padding: 0 0 40px;
    }
}
.frame-text5 {
    height: auto;
    padding: 15px;
    margin-bottom: 15px;
        height: 512px;
        padding: 0;
    border: 1px solid #f3f3f3;
    position: relative;
    h3 {
        padding: 0 0 40px;
    }
}
.frame-text6 {
    height: auto;
    padding: 15px;
    margin-bottom: 15px;
        height: 503px;
        padding: 0;
    border: 1px solid #f3f3f3;
    position: relative;
    h3 {
        padding: 0 0 40px;
    }
}
.text-content-box {
        display: flex;
        align-items: center;
    p {
        text-align: left;
        margin-bottom: 0;
        span {
            color: #3BE185;
        }
    }
    img {
        margin-right: 25px;
        flex: 0 0 auto;
    }
}
.text-wrap {
        max-width: 90%;
        margin: 0 auto;
    .side-padding {
            padding: 0 50px;
    }
}

.img-full {
    img {
        width: 100%;
    }

}
.company-video {
    background: url(images/office-and-infrastructure/video-bg2.jpg) no-repeat 0 0 / cover;
    padding: 50px 0;
    @include responsive(desktop){
        padding: 80px 0;
    }
}

// .o-open-office {
//     h3 {
//         font-size: 44px;
//         line-height: 62px;
//         @include tablet-lg-sm {
//             padding-top: 0;
//             font-size: 34px;
//             line-height: 50px;
//         }
//         @include desktop-sm {
//             font-size: 44px;
//             line-height: 62px;
//         }
//     }
// }

// .video-cm-box {
//     border: 8px solid #ccc;
// }

// .video-cm-box {
//     iframe {
//         height: auto;
//         @include responsive(desktop){
//             height: 400px;
//         }
//     }
// }

.hww-list{
    text-align: center;
    margin-bottom: 0;
    padding: 40px 0 0;
    @include responsive(desktop){
        margin-bottom: 10px;
        padding: 40px 0;
    }
}
.hww-list li{
    display: inline-block;
    margin: 0 0 25px;
    color: #fff;
    width: 100%;
    vertical-align: top;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    @include media-query(768px) {
        width: 110px;
        margin: 0 20px;
    }
    @include media-query(992px) {
        width: 142px;
    }
}
.hww-list li span{
    display: block;
    padding-top: 20px;
    font-family: 'Montserrat', sans-serif;
}
.hww-list li:before{
    content: "";
    background: url(/images/daas/arrow.png) no-repeat 0 0;
    width: 53px;
    height: 30px;
    position: absolute;
    right: -50px;
    top: 40px;
    display: none;
    @include responsive(desktop){
        display: block;
    }
}
.hww-list li:last-child:before{
    display: none;
}
.hww-list-1 li{
    color: #fff;
    position: relative;
    padding: 0 0 20px 28px;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    font-weight: 500;
    @include responsive(desktop){
        font-size: 19px;
        line-height: 30px;
    }
}
.hww-list-1 li:before{
    width: 5px;
    height: 5px;
    background-color: #27C070;
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.o-engagement-model__cards__top_daas1:before{
    background: url(images/daas/price-icon-1.png) no-repeat 0 0!important;
        height: 62px!important;
    width: 64px!important;
}
.o-engagement-model__cards__top_daas2:before{
    background: url(images/daas/price-icon-2.png) no-repeat 0 0!important;
        height: 62px!important;
    width: 64px!important;
}

.reasons-capital__blocks-wc1:before {
    background: url(images/daas/why-choose-1.png) no-repeat 0 0!important;
}
.reasons-capital__blocks-wc2:before {
    background: url(images/daas/why-choose-2.png) no-repeat 0 0!important;
}
.reasons-capital__blocks-wc3:before {
    background: url(images/daas/why-choose-3.png) no-repeat 0 0!important;
}
.reasons-capital__blocks-wc4:before {
    background: url(images/daas/why-choose-4.png) no-repeat 0 0!important;
}
.reasons-capital__blocks-wc5:before {
    background: url(images/daas/why-choose-5.png) no-repeat 0 0!important;
}

// .services-pan__daas-service-01:before{
//     background: url(images/daas/daas-service-1.png) no-repeat 0 0!important;
//     width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-02:before{
//      background: url(images/daas/daas-service-2.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-03:before{
//      background: url(images/daas/daas-service-3.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-04:before{
//      background: url(images/daas/daas-service-4.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-05:before{
//      background: url(images/daas/daas-service-5.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-06:before{
//      background: url(images/daas/daas-service-6.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-07:before{
//      background: url(images/daas/daas-service-7.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }
// .services-pan__daas-service-08:before{
//      background: url(images/daas/daas-service-8.png) no-repeat 0 0!important;
//      width: 63px!important;
//     height: 63px!important;
// }

.panel1 {
  padding: 0 18px;
  background-color: white;
  // max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  display: none;
  font-size: 19px;
  line-height: 30px;
  font-family: $secondary-font;
  font-weight: 500;
}
.panel1 p{
    font-size: 19px;
    line-height: 30px;
    font-family: $secondary-font;
    font-weight: 500;
}
.panel1 ul{
    padding-bottom: 20px;
    padding-left: 20px;
}
.panel1 ul li{
    list-style-type: disc;
    padding-bottom: 10px;
}

.cheaper-batter-header{
    p{
        font-size: 16px;
        line-height: 28px;
        color: #fff;
        text-align: center;
        @include responsive(desktop){
            font-size: 22px;
            line-height: 32px;
            text-align: left;
        }
    }
}
.cheaper-batter-header-left{
        background: none !important;
        padding-left: 15px;
        @include responsive(desktop){
            background: url(/images/common/cheape-heading-bg.png) no-repeat 0 0!important;
            padding-left: 40px;
        }
    .c-b-heading{
        font-size: 19px;
        line-height: 23px;
        font-weight: bold;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 50px;
        text-transform: uppercase;
        letter-spacing: 0;
        padding-top: 90px;
        position: relative;
        margin-bottom: 15px;
        @include responsive(desktop){
            padding-top: 140px;
            font-size: 36px;
            line-height: 43px;
            letter-spacing: 6px;
            margin-bottom: 0;
        }
        &:before {
            content: '';
            position: absolute;
            background: url(/images/mobile-all/cb-bnr-circle.png) no-repeat 0 0;
            width: 325px;
            height: 315px;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: block;
            @include responsive(desktop){
                display: none;
            }
        }
        span{
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 84px;
            line-height: 100px;
            letter-spacing: 0;
            @include responsive(desktop){
                font-size: 160px;
                line-height: 160px;
            }
        }
    }
}
.cheaper-batter-header-right{
    padding-top: 20px;
    padding-left: 15px;
    @include responsive(desktop){
       padding-left: 80px;
       padding-top: 80px;
    }
}

.cheaper-batter-header-right p{
    line-height: 26px;
    @include responsive(desktop){
        line-height: 36px;
    }
}
.new-cb-heading{
    margin-top: 30px;
    margin-bottom: 30px;
}
.new-cb-heading span{
    font-family: $secondary-font;
    font-weight: bold;
    width: 270px !important;
    padding: 10px 28px !important;
    @include responsive(desktop){
        width: auto !important;
    }
}
.new-cb-section{
    text-align: left;
}
.new-cb-section-pan{
    display: inline-block;
    vertical-align: top;
    font-size: 19px;
    line-height: 29px;
    font-family: $secondary-font;
    flex: 0 0 100%;
    @include responsive(desktop){
        flex: auto;
    }
    .new-cb-top {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        @include responsive(desktop){
            display: block;
        }
    }
    p {
        font-size: 16px;
        line-height: 24px;
        font-family: $main-font;
        @include responsive(desktop){
            font-size: 19px;
            line-height: 28px;
            font-family: $main-font;
        }
    }
}
.new-cb-section-pan h4{
    height: auto;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    position: relative;
    flex: 1;
    margin-bottom: 0;
    margin-left: 20px;
    @include responsive(desktop){
        height: 70px;
        line-height: 24px;
        margin-bottom: .5rem;
        margin-left: 0;
    }
}
.new-cb-section-pan h4 span{
    font-weight: 700;
    @include responsive(desktop){
        position: absolute;
        bottom: 0;
        font-weight: inherit;
    }
    strong {
        display: block;
        // display: inline;
        font-weight: 700;
        @include responsive(desktop){
            display: block;
            font-weight: inherit;
        }
    }
}
.new-cb-section-pan img{
    margin: 30px 0;
    min-height: 55px;
}
.new-cb-section-1{
    border: 1px solid #8A8A8F;
    background-color: #FAFAFA;
    border-radius: 5px;
    box-shadow: -2px 5px 20px 0px rgba(0,0,0,0.17);
    padding: 15px 35px;
    position: relative;
    @include responsive(desktop){
        padding: 68px 35px;
       width: 23%;
       min-height: 546px;
    }
}
.new-cb-section-1:after{
    content: "";
    position: absolute;
    background: url(/images/mobile-all/icon-plus.png) no-repeat 0 0 !important;
    width: 39px;
    height: 39px;
    top: inherit;
    bottom: -20px;
    right: 0;
    left: 0;
    background-size: 100% !important;
    margin: 0 auto;
    @include responsive(desktop){
        top: 95px;
        bottom: inherit;
        right: -20px;
        left: inherit;
        margin: 0;
    }
}

.new-cb-section-2{
    border-bottom: 1px solid #E7E7E7;
    padding: 0 30px 0;
    position: relative;
    @include responsive(desktop){
        position: relative;
        padding: 50px 28px 0;
        width: 18%;
        min-height: 510px;
        border-right: 1px solid #E7E7E7;
        border-bottom: 0;
        margin: 20px 0;
    }
    &-mod {
        @include responsive(desktop){
            padding: 50px 25px 0;
        }
    }
}
.new-cb-section-2:after{
    content: "";
    position: absolute;
    background: url(/images/mobile-all/icon-plus.png) no-repeat 0 0 !important;
    width: 30px;
    height: 30px;
    background-size: 100%!important;
    top: inherit;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    @include responsive(desktop){
        top: 80px;
        right: -17px;
        left: inherit;
        bottom: inherit;
        margin: 0;
    }
}
.last-child-cb{
    border: 0;
}
.last-child-cb:after{
    display: none!important;
}

.cb-wecare{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#07b260+0,00517f+29,051b39+100 */
    background: #07b260; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #07b260 0%, #00517f 29%, #051b39 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #07b260 0%,#00517f 29%,#051b39 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #07b260 0%,#00517f 29%,#051b39 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#07b260', endColorstr='#051b39',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    &-heading {
        h2 {
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            @include responsive(desktop){
                font-size: 48px;
                line-height: 58px;
                text-align: left;
            }
        }
        p {
            @include responsive(desktop){

            }
        }
    }
}
.cb-wecare-heading{
    text-align: left;
    color: #fff;
    margin-bottom: 20px;
    @include responsive(desktop){
        margin-bottom: 50px;
    }
}
.cb-wecare-heading h2{
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 10px;
    @include responsive(desktop){
        font-size: 48px;
        line-height: 58px;
        padding-bottom: 20px;
    }
}
.cb-wecare-heading p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #fff;
    @include responsive(desktop){
        font-size: 19px;
        line-height: 30px;
    }
}
.cb-wecare-heading p strong{
    font-weight: bold;
}
.cb-wecare-box1{
    background-color: #1D2942;
    padding: 50px 30px 10px;
    color: #fff;
    text-align: left;
}
.cb-wecare-box1 h3, .cb-wecare-box2 h3{
    color: #fff;
    margin-bottom: 30px;
}
.cb-wecare-box1 p, .cb-wecare-box2 p{
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 26px;
    font-family: $main-font;
    @include responsive(desktop){
        font-size: 19px;
        font-family: $main-font;
    }
}
.cb-wecare-box2{
    padding: 50px 30px 10px;
    color: #fff;
    text-align: left;
    border: 1px solid #fff;
    margin-top: 30px;
    margin-top: 50px;
}

.cb-wecare-box1 a, .cb-wecare-box2 a{
    display: inline-block;
    text-align: left;
    border-radius: 5px;
    padding: 10px 50px 10px 20px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat",sans-serif;
    border: 2px solid #fff;
    color: #fff;
    width: auto;
    position: relative;
    margin-top: 20px;
    @include responsive(desktop){
        display: inline-block;
        text-align: center;
        font-size: 18px;
    }
}
.cb-wecare-box1 a:hover, .cb-wecare-box2 a:hover{
    border: 2px solid #fff;
    border: 2px solid #14df7d;
}

.cb-wecare-box1 a:hover {
    border: 2px solid #14df7d;
}

.cb-wecare-box1 a.c-btn:after,
.cb-wecare-box2 a.c-btn:after  {
    content: '';
    background: url(/images/common/arrow-white.png) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 15px;
    z-index: 0;
    position: absolute;
    right: 15px;
    top: 28px;
    @include responsive(desktop){
        position: static;
        z-index: -1;
        top: 20px;
    }
}

.services-pan__dotnet-service-01 {
    &:before {
         background: url(images/dotnet/service-icon-1.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-02 {
    &:before {
         background: url(images/dotnet/service-icon-2.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-03 {
    &:before {
         background: url(images/dotnet/service-icon-3.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-04 {
    &:before {
         background: url(images/dotnet/service-icon-4.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-05 {
    &:before {
         background: url(images/dotnet/service-icon-5.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-06 {
    &:before {
         background: url(images/dotnet/service-icon-6.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-07 {
    &:before {
         background: url(images/dotnet/service-icon-7.png) no-repeat 0 0!important;
    }
}
.services-pan__dotnet-service-08 {
    &:before {
         background: url(images/dotnet/service-icon-8.png) no-repeat 0 0!important;
    }
}

.dotnet-industry{
    position: relative;
    &:after {
        display: none!important;
    }
    .services-pan__dotnet-industry-01 {
        &:before {
             background: url(images/dotnet/industry-icon-1.png) no-repeat 0 0!important;
        }
    }
    .services-pan__dotnet-industry-02 {
        &:before {
             background: url(images/dotnet/industry-icon-2.png) no-repeat 0 0!important;
        }
    }

}

.whydotnet{
    .fi-pan{
        border: 0;
        border-radius: 6px;
        box-shadow: 0 0 0 rgba(0,0,0,0.1);
        background-color: transparent;
        img{
            margin-bottom: 20px;
        }
    }
}
.whymagento{
    .fi-pan{
        border: 0;
        border-radius: 6px;
        box-shadow: 0 0 0 rgba(0,0,0,0.1);
        background-color: transparent;
        width: 100%;
        text-align: center;
        @include responsive(desktop){
            width: 25%;
            text-align: left;
        }
        img{
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 23px;
            padding-top: 10px;
            font-family: $main-font;
            @include responsive(desktop){
                font-size: 19px;
                line-height: 23px;
                font-family: $main-font;
            }
        }
        h3 {
            font-size: 20px;
            @include responsive(desktop){
                font-size: 26px;
            }
        }
    }
}
.hr-magento {
    font-size: 19px;
    @include responsive(desktop){
        font-size: 26px;
    }
    br {
        display: none;
        @include responsive(desktop){
            display: block;
        }
    }
}

.magento-page{
    h1{
        font-size: 55px;
        line-height: 62px;
    }
    p{
        font-size: 24px;
        line-height: 30px;
        span{
            color: #2DBE70;
        }
    }
}

.magento-section-1{

    ul{
        li{
            background: url(/images/magento/greentick.png) no-repeat 0 5px!important;
            padding: 0 0 10px 40px;
            font-size: 17px;
            line-height: 28px;
            font-family: $main-font;
            @include responsive(desktop){
                font-size: 20px;
                line-height: 32px;
                background: url(/images/magento/greentick.png) no-repeat 0 7px!important;
                font-family: $main-font;
            }
        }
    }
    h5 {
        padding-bottom: 15px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
}

// .fi-pan {
//     // @media all and (max-width: 991px) {
//     //     width: 29% !important;
//     // }
//     // @media all and (max-width: 767px) {
//     //     width: 44% !important;
//     // }
//     // @media all and (max-width: 570px) {
//     //     width: 100% !important;
//     // }
//     a {
//         @media all and (max-width: 570px) {
//             min-height: inherit !important;
//         }
//     }
//     h3 {
//         @media all and (max-width: 991px) {
//             font-size: 20px !important;
//         }
//     }
// }

// .web-app-service {
//     .cmn-heading-para {
//         @media all and (max-width: 767px) {
//             display: none;
//         }
//     }
//     p {
//         @media all and (max-width: 767px) {
//             font-size: 16px !important;
//             line-height: 23px !important;
//         }
//     }
//     @media all and (max-width: 767px) {
//         padding-bottom: 25px !important;
//     }
// }
// .services-pan {
//     @media all and (max-width: 767px) {
//         padding: 5px 15px 30px !important;
//     }
// }

// .o-industrial-experience {
//     @media all and (max-width: 767px) {
//         display: none;
//     }
// }

// .faq-section {
//     @media all and (max-width: 767px) {
//         display: none;
//     }
// }
// .insights-section {
//     // @media all and (max-width: 767px) {
//     //     display: none;
//     // }
// }
// .rm-review {
//     @media all and (max-width: 767px) {
//         display: none;
//     }
// }

// .web-app-service {
//     .service-wrap {
//         .brick.services-pan.services-pan__php {
//             @media all and (max-width: 767px) {
//                 padding: 5px 15px 30px !important;
//             }
//         }
//     }
// }


.o-services-hero__title {
    span {
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        color: #333;
        background: #14DF7D;
        display: inline-block;
        position: relative;
        font-size: 18px;
        line-height: 40px;
        padding: 0 20px;
        margin: 0 0 56px;
        padding: 0 30px;
        margin: 0 0 40px;
        font-size: 28px;
        line-height: 54px;
    }
}

.why-matters-top {
    display: flex;
    align-items: center;
    @include responsive(desktop){
    }
    span {
        display: inline-block;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 50%;
        border: 1px solid #333;
        @include responsive(desktop){
            display: inline;
            width: auto;
            height: auto;
            border-radius: 0;
            border: none;
        }
        .listing-heading-icon1 {
            width: 25px;
            @include responsive(desktop){
                width: auto;
            }
        }
        .listing-heading-icon2 {
            width: 40px;
            @include responsive(desktop){
                width: auto;
            }
        }
        .listing-heading-icon3 {
            width: 40px;
            @include responsive(desktop){
                width: auto;
            }
        }
    }
}

.why-matters {
    .listing-heading {
        padding-bottom: 15px !important;
        @include responsive(desktop){
            padding-bottom: 35px !important;
        }
    }
    &-top {
        h3 {
            font-size: 20px;
            line-height: 36px;
            flex: 1;
            padding-left: 15px !important;
            @include responsive(desktop){
                font-size: 26px;
                line-height: 1.2;
                padding-left: 30px !important;
            }
        }
    }
    .c-ticked-list {
        li {
            font-size: 14px !important;
            line-height: 24px !important;
            @include responsive(desktop){
                font-size: 17px !important;
                line-height: 28px !important;
            }
            &:before {
                background-size: 15px !important;
                @include responsive(desktop){
                    background-size: auto !important;
                }
            }
        }
    }
    p.padding-0 {
        font-size: 16px !important;
        line-height: 28px !important;
        font-family: $main-font;
        @include responsive(desktop){
            font-size: 19px !important;
            line-height: 30px !important;
            font-family: $main-font;
        }
    }
}

.AI {
    .key-benefits {
        padding: 45px 0 20px;
        background: #023e66;
        @include responsive(desktop){
            padding: 70px 0 50px;
            background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%);
        }
        h2 {
            font-size: 30px;
            color: #fff;
            @include responsive(desktop){
                font-size:48px;
                color: #fff;
            }
        }
        .cmn-heading-para-w {
            color: #fff;
            margin-bottom: 20px;
            @include responsive(desktop){
                color: #fff;
                margin-bottom: 20px;
            }
        }
        .services-pan {
            h2 {
                font-size: 20px;
                @include responsive(desktop){
                    font-size: 26px;
                }
            }
        }
    }
}

.chat-app-group-icon {
    margin-bottom: 25px;
    display: block;
}
@mixin secure-web-application-extends($value){
    padding: 35px 20px 0;
    position: relative;
    &::before{
        content: "";
        background-color: $value;
        width: 120px;
        height: 5px;
        position: absolute;
        left: 20px;
        top: 0px;
    }
}
.c-icon-desc-3-col{
    .secure-web-application-row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 50px;
        @include media-query(992px){
            column-count: 3;
            column-gap: 1rem;
            display: block;
            text-align: left;
            margin-top: 50px;
            .secure-web-application-box{
                width: 100%;
                padding: 0;
                display: inline-block;
                margin: 0;
                font-size: 1em;
                box-sizing: border-box;

            }
        }
        .secure-web-application-box{
            border: none;
        }
        .secure-web-application-box-1{
            @include secure-web-application-extends(#4A90E2);
            order: 1;
        }
        .secure-web-application-box-2{
            @include secure-web-application-extends(#49C684);
            order: 4;
        }
        .secure-web-application-box-3{
            @include secure-web-application-extends(#49C684);
            order: 2;
        }
        .secure-web-application-box-4{
            @include secure-web-application-extends(#4A90E2);
            order: 5;
        }
        .secure-web-application-box-5{
            @include secure-web-application-extends(#49C684);
            order: 7;
        }
        .secure-web-application-box-6{
            @include secure-web-application-extends(#4A90E2);
            order: 3;
        }
        .secure-web-application-box-7{
            @include secure-web-application-extends(#49C684);
            order: 6;
        }
    }
    &.c-icon-desc-3-col-new-mod{
        padding: 40px 0;
        @include media-query(992px){
            padding: 70px 0 80px;

        }
    }
}
%project-discuss-section{
    background: url(///images/web-application/Oval-copy-oval.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #204279;
    .btn-orange{
        max-width: 350px;
    }
}
.project-discuss-text{
    font-size: 18px;
    line-height: 30px;
}
.project-discuss-section{
    @extend %project-discuss-section;
}
.project-discuss-section-new{
    background-color: #F7F8FA;
    background: url(///images/web-application/oval-copy-oval-copy-mask.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @extend %project-discuss-section;
}
.dev-process.dev-process-new{
    .spiral-col-box{
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
    .spiral-col-box1{
        @include responsive(desktop){
            margin-bottom: 90px;
        }
    }
    .spiral-col-box2{
        @include responsive(desktop){
            margin-bottom: 110px;
        }
    }
    .spiral-col-box5{
        @include responsive(desktop){
            margin-top: 90px;
        }
    }
}
// .expertise-section{
//     .expertise-wrapper{
            
//             .expertise-box{
//                 .expertise-box-left.expertise-box-left-new{
//                     flex: 0 0 68px;
//                     border: none;
//                     background: transparent;
//                     box-shadow: unset;
//                     align-items: flex-start;
//                     height: auto;
//                 }
//             }
        
//     }
// }

.grid-layout{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    @include media-query(992px){
        column-count: 2;
        column-gap: 1rem;
        display: block;
        text-align: left;
        .grid-block{
            width: 100%;
            padding: 0.5em;
            display: flex;
            align-items: flex-start;
            margin: 0 0 1em;
            font-size: 1em;
            box-sizing: border-box;
            img{
                margin-right: 20px;
            }
        }
    }

    .grid-block{
        p{
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
            strong{
                font-size: 20px;
            }
            @include media-query(992px){
                font-size: 18px;
                line-height: 28px;
                margin-top: 0;
                strong{
                    font-size: 20px;
                }
            }
            a {
                display: block;
                @include media-query(992px){
                    display: inline;
                    margin-left: 10px;
                }
            }
            
        }
    }
    .grid-block-1{
        order: 1;
    }
    .grid-block-2{
        order: 3;
    }
    .grid-block-3{
        order: 5;
    }
    .grid-block-4{
        order: 7;
    }
    .grid-block-5{
        order: 9;
    }
    .grid-block-6{
        order: 11;
    }
    .grid-block-7{
        order: 2;
    }
    .grid-block-8{
        order: 4;
    }
    .grid-block-9{
        order: 6;
    }
    .grid-block-10{
        order: 8;
    }
    .grid-block-11{
        order: 10;
    }
    .grid-block-12{
        order: 12;
    }
}

%secure-web-application-extends{
    padding: 35px 20px 0;
    position: relative;
    &::before{
        content: "";
        background-color: #4A90E2;
        width: 120px;
        height: 5px;
        position: absolute;
        left: 20px;
        top: 0px;
    }
}

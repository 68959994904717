.platform-review {
    .custom-review__partner {
        display: none;
    }
    .custom-review__tittle {
        @include media-query(992px) {
            font-size: 51px;
            line-height: 55px;
            font-weight: 700;
        }
    }
    .custom-review__subtittle {
        @include media-query(992px) {
            font-size: 25px;
            line-height: 34px;
            font-weight: 600;
        }
    }
    .cs-cont.invisible {
        @include media-query(992px) {
            height: 200px;
        }
    }
    .custom-review {
        @include media-query(992px) {
            padding: 80px 0;
        }
    }
    .custom-review__spacing {
        @include media-query(992px) {
            padding: 0;
        }
    }
}
.platform-bottom-form {
    @include media-query(992px) {
        padding: 0 0 60px;
    }
    .new-bottom-form__left {
        @include media-query(992px) {
            padding-top: 80px;
        }
    }
    .new-bottom-form__email {
        @include media-query(992px) {
            padding-top: 80px;
        }
    }
    .new-bottom-form__trust {
        @include media-query(992px) {
            padding: 50px 40px 70px;
        }   
    }
}

.platform-mod {
    .banner-technologies__heading {
        @include media-query(992px) {
            line-height: 68px;
        }
    }
    .banner-technologies {
        @include media-query(992px) {
            padding: 60px 0 80px;
        }
    }
}

.platform-invite {
    @include media-query(992px) {
        padding-bottom: 80px;
        padding-top: 80px;
    }
    .btn-orange.custom-review__btn {
        @include media-query(992px) {
            margin-left: 0;
        }
    }
    .section-invite__item-title {
        font-weight: 700;
        span {
            font-weight: 600;
        }
    }
}

.o-case-studies-new {
    .faq-para {
        color: #333;
        font-size: 25px;
        line-height: 34px;
        font-weight: 600;
        text-align: center;
    }
}

.platform-work-with {
    $platform-work-with : &;
    padding: 40px 0;
    background: #F4F4F4;
    @include media-query(992px) {
        padding: 80px 0;
    }
    &__wrapper {
        margin-top: 15px;
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 30px;
        }
    }
    &__box-sub {
        background: #fff;
        position: relative;
        z-index: 9;
        padding: 20px 20px 20px 20px;
        @include media-query(992px){
            padding: 30px 35px 40px 35px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__box {
        margin-bottom: 15px;
        border: 1px solid #898989;
        display: block;
        position: relative;
        overflow: hidden;
        @include media-query(992px){
            overflow: hidden;
            padding: 2px;
            border: 0;
            margin-bottom: 25px;
            flex: 0 0 45%;
            max-width: 45%;
            margin: 20px 20px;
        }
        &:after {
            content: '';
            position: absolute;
            top: -108px;
            right: 0;
            bottom: 0;
            left: -136px;
            width: 780px;
            height: 780px;
            transition: .3s all ease;
            display: none;
            animation: rotation2 3s linear infinite;
            background: -moz-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: -webkit-linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            background: linear-gradient(180deg, rgba(136,222,177,1) 0%, rgba(26,184,100,1) 20%, rgba(35,116,212,0.62) 52%, rgba(6,87,181,0.62) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#88deb1",endColorstr="#0657b5",GradientType=1);
            display: none;
            @include media-query(992px){
                display: block;
            }
        }
    }
    &__icon {
        @include media-query(992px){
            display: inline-flex;
            height: 120px;
            align-items: center;
        }
    }
    h3 {
        font-family: 'Poppins';
        font-size: 26px;
        line-height: 31px;
        font-weight: 600;
        color: #333333;
        margin-top: 10px;
    }
    p {
        font-family: 'Poppins';
        color: #222222;
        font-size: 17px;
        line-height: 28px;
        font-weight: 400;
    }
    &__anchor {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        transition: .3s all ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        @include media-query(992px){
            margin-top: auto;
        }
        &:hover {
            color: #00A1E0;
            svg {
                transform: translateX(10px);
                path {
                    fill: #00A1E0;
                }
            }
        }
    }
    &__arrow {
        margin-left: 10px;
        path {
            fill: #333333;
        }
    }
    .btn-wrapper-v2--pair .btn-v2 {
        margin-bottom: 0;
    }
}
.platform-faq {
    @include media-query(992px){
        padding: 70px 0 90px !important;
    }
    h2 {
        @include media-query(992px){
            padding-bottom: 10px;
        }
    }
}

.platform-footer {
    @include media-query(992px){
        padding: 80px 0 50px;
    }
}
//OFFER DETAILS
.offer-details{
  $offer-details : &;

  position: relative;
  background: #f9f9f9;

  &__spacing{
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }
  &__tittle{
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 30px;
    color: $black-color;
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center;
    @include media-query(992px) {
      font-size: 34px;
      line-height: 35px;
    }
  }
  &__list{
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__margin-bottom{
    margin-bottom: 25px;
  }
  &__item{
      position: relative;
      background-color: $white-color;
      border:1px solid #DDDDDD;
      padding: 30px;
      border-radius: 5px;
      max-width: 370px;
      margin: auto;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      flex-direction: column-reverse;
      border-bottom: 4px solid #62dbbe;
      @include media-query(992px) {
        align-items: flex-start;
        box-shadow: 0px 0 18px 0 rgba(0,0,0,0.1);
      }
      // &:nth-child(3n+3){
      //   margin-right: 0;
      // }
      // &:nth-child(2n+2){
      //   border-bottom: 4px solid #4492dc;
      // }
      // &:last-child{
      //   margin-right: 0;
      // }
    }
  &__itemcount{
    background-color: #36E079;
    position: absolute;
    width: 34px;
    height: 34px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
  }
  &__itemtext{
    width: 100%;
    margin: 0;
    font-family: 'Poppins';
    font-size: 19px;
    line-height: 30px;
    color: $black-color;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
    @include media-query(1024px) {
      margin-top: 0;
      font-size: 21px;
      line-height: 31px;
      text-align: left;
      margin-top: 20px;
    }
  }
  &__btn{
    margin:47px auto 0;
    max-width: 100%;
    width: 100%;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(767px) {
      max-width: 287px;
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      g{
        fill: #ff5800;
      }
    }
  }
}

.note-text{
  font-family: 'Poppins';
  background-color: #F4F4F4;
  font-size: 18px;
  line-height: 26px;
  color: $black-color;
  text-align: center;
  padding: 24px 15px;
}
// Our Services Page
.banner-services{
  &__subtitle{
    font-family: "Poppins";
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    color: #3CC065;
    @media(min-width:767px){
      font-size: 25px;
      line-height: 40px;
      font-weight: 700;
    }
    @media(min-width:1024px){
      font-size: 31px;
      line-height: 47px;
      
    }
  }
  &__header-br{
    display: none;
    @media(min-width:1024px){
      display: block;
    }
  }
}

.services-tab{
  @include media-query(992px) {
    overflow: visible;
  }
  &__heading{
    margin-bottom: 20px;
  }
  &__description{
    font-size: 18px;
    line-height: 28px;
    font-weight: 600px;
    text-align: center;
    font-family: 'Poppins' !important;
    margin-bottom: 0px;
    
    @media(min-width:767px){
      font-size: 20px;
      line-height: 30px;
      // margin-bottom: 35px;
    }
    @media(min-width:1024px){
      font-size: 25px;
      line-height: 34px;
      // margin-bottom: 50px;
    }
  }
  .o-accordion__nav .active {
    color: #4492DC;
  }
  .o-accordion__tab {
    &:hover {
      color: #4492DC;
    }
  }
}

.o-accordion {
  $o-accordion: &;
  &__content-title--service {
    @include media-query(992px) {
      padding-top: 0;
      margin-bottom: 0;
    }
  }
  &__content-area--service {
    @include media-query(992px) {
      position: static;
    }
  }
  &__content--service {
    @include media-query(992px) {
      padding: 40px 0 55px 55px;
    }
  }
  &__content-head {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    @include media-query(992px) {
      padding-right: 50px;
      padding-bottom: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      background: #4492DC;
      width: 49px;
      height: 4px;
      bottom: 0;
      left: 0;
    }
    i {
      display: inline-block;
      margin-right: 15px;
      flex: 0 0 55px;
      height: 60px;
      width: 50px;
      display: flex;
      align-items: center;
    }
  }
  // &__content-list {

  // }
  &__content-items {
    padding-left: 40px;
    margin-bottom: 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/new-services/tick-circle.png) no-repeat 0 0;
      width: 25px;
      height: 25px;

    }
    h4 {
      font-family: "Poppins";
      font-size: 21px;
    }
    p {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 27px;
    }
  }
  &__content-btn {
    font-family: "Poppins";
    display: inline-block;
    padding: 15px 24px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    border: 1px solid #FF5800;
    transition: .3s all ease;
    position: relative;
    text-align: center;
    @include media-query(992px) {
      font-size: 15px;
    }
    svg {
      margin-left: 7px;
      width: 20px;
      @include media-query(992px) {
        width: 32px;
        margin-left: 10px;
      }
    }
    &:hover {
      color: #FF5800;
      background: transparent;
      svg {
        transform: translateX(10px);
      }
      path {
        fill: #FF5800;
      }
    }

  }
}

.engagement-model-page-bnr {
    padding-bottom: 50px;
    @include media-query(992px) {
        padding: 130px 20px 75px!important;
    }
    h1 {
        @include media-query(992px) {
            font-size: 55px;
            line-height: 64px;
            margin-bottom: 40px;
        }
        margin-bottom: 25px;
    }
    .header_right_pan {
        text-align: left;
        padding-top: 20px;
        @include media-query(992px) {
            padding-top: 40px;
        }
        p {
            @include media-query(992px) {
                font-size: 24px;
                line-height: 40px;
                text-align: left;
            }
            color: #E9F4F5;
            text-align: center;
        }
        .btn-orange {
            @include media-query(992px) {
                margin: 0;
            }
        }
        .anchor-link {
            @include media-query(992px) {
                text-align: left;
                display: block;
            }
        }
    }
}

.page-engagement-specific {
    .heading-top {
        margin-bottom: 45px;
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            font-size: 19px;
            @include media-query(992px) {
                font-size: 22px;
            }
            text-align: center;
        }
    }
    .iot-case-wrapper {
        max-width: 100%;
        &-box {
            width: 100%;
            text-align: center;
            margin: 0;
            min-height: auto;
            margin-bottom: 30px;
            border-bottom-width: 5px;
            h3 {
                font-size: 26px;
                font-weight: 600;
                margin-top: 30px;
            }
            p {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 15px;
            }
            a {
                font-size: 16px;
                text-align: center;
                border: 1px solid #D2D2D2;
                border-radius: 5px;
                color: #333333;
                display: block;
                margin: 0 auto;
                width: 162px;
                padding: 11px 0;
                font-weight: 500;
                transition: .3s all ease-in-out;
                &:hover {
                    background: #ff5800;
                    border-color: #ff5800;
                    color: #fff;
                }
            }
        }
    }
}


.engagement-models {
    .page-engagement-specific {
        .heading-top {
            @include media-query(992px) {
                margin-bottom: 40px;
            }
            h2 {
                margin-bottom: 10px;
                @include media-query(992px) {
                    font-size: 48px;
                    line-height: 68px;
                    font-weight: 600;
                }
            }
        }
        .iot-case-wrapper-box {
            border-bottom-width: 5px;
            display: block;
            h3 {
                margin-left: 0;
                margin-top: 20px;
                margin-bottom: 10px;
                @include media-query(992px) {
                    font-size: 26px;
                    line-height: 36px;
                    font-weight: 600;
                }
            }
            p {
                margin-bottom: 15px;
                display: block;
                @include media-query(992px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}

.message-section {
  $message-section: &;  
  padding: 30px 0;
    @include media-query(992px) {
        padding: 80px 0;
    }
    &__wrapper {
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            max-width: 970px;
            margin: 0 auto;
        }
        h2 {
            text-align: center;
            @include media-query(992px) {
                font-size: 30px;
                line-height: 48px;
                font-weight: 500;
                flex: 100%;
            }
            margin-bottom: 40px;
        }
    }
    &__left {
        flex: 173px;
        max-width: 173px;
        margin: 0 auto;
        @include media-query(992px) {
            margin-right: 30px;
        }
    }
    &__img {
        width: 100%;
        height: 173px;
        overflow: hidden;
        border-radius: 100%;
        border: 2px solid #B1B1B1;
    }
    &__information {
        flex: 1;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
        h3 {
            font-size: 17px;
            line-height: 23px;
            font-weight: 500;
            margin-bottom: 15px;
            margin-top: 15px;
            @include media-query(992px) {
                font-size: 19px;
                line-height: 20px;
                margin-bottom: 20px;
                margin-top: 0;
            }
        }
        h4 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        h5 {
            font-size: 19px;
            line-height: 24px;
            font-weight: 600;
        }
        h6 {
            display: block;
            font-size: 15px;
            line-height: 16px;
            font-weight: 500;
        }
    }
}
.engagement-why {
    background: linear-gradient(150deg,#051b39 0,#023e66 45%,#00517f 64%,#038070 100%) !important;
    .owl-stage {
        display: flex;
        .owl-item {
            display: flex;
        }
    }
    .details {
        @include media-query(992px) {
            margin-left: 15px;
            width: inherit;
            flex: 1;
        }
        h4 {
            @include media-query(992px) {
                font-size: 22px;
                line-height: 32px;
            }
        }
        p {
            @include media-query(992px) {
                font-size: 16px;
                line-height: 27px;
            }
        }
    }
}
// body {
//     font-family: 'Montserrat', sans-serif;
//     margin: 0;
//     padding: 0;
//     color: #333;
//     position: relative;
// }

// .container {
//     max-width: 1170px;
//     margin: 0 auto;
//     padding: 0 15px;
// }
.teamquote-body {
    position: relative;

.quote-section h2 {
    font-size: 32px;
    margin-top: 0;
    text-align: center;
}


/** This class will hide all the body be default **/
.accordion-section .panel-body{
    display : none;
 }
 
 /** This class will show the body if it has a parent with active       class
 **/

 
.accordion-section .panel{
   border : solid 1px #D6D6D6;
   border-radius : 8px;
   margin-bottom: 10px;
   background: #fff;
   position: relative;
 }
 .accordion-section .panel:before{
    content: '';
    position: absolute;
    background: url(/images/team-quote/plus.png) no-repeat 0 0;
    height: 20px;
    width: 20px;
    top: 17px;
    right: 20px;
    cursor: pointer;
 }
 .accordion-section .panel.active:before{
    content: '';
    position: absolute;
    background: url(/images/team-quote/minus.png) no-repeat 0 0;
    height: 10px;
    width: 30px;
    top: 25px;
    right: 15px;
    cursor: pointer;
 }

 .ttip {
    position: relative;
    display: inline-block;
  }
  .ttip img {
    position: relative;
    top: -1px;
    left: 5px;
}
  
    .ttip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
    .ttip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .ttip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }




.quote-section-head h4 {
    font-size: 18px;
    margin-top: 0;
    line-height: inherit;
    text-align: center;
}
.quote-select {
    width: 190px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.1);
    padding: 10px 20px;
    background-color: transparent;
    position: relative;
}
.quote-select:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 20px;
    top: 18px;
    height: 15px;
    width: 15px;
}
.quote-select select{
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 14px;
    background: none;
}
.quote-select select:focus {
    outline: none;
}
.quote-section-head-right small {
    font-style: italic;
    font-size: 12px;
    margin-top: 10px;
    display: block;
}
.accordion-head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}
.accordion-head h3{
    font-size: 24px;
    margin: 0;
}
.accordion-head-span1, .accordion-head-span2, .accordion-head-span3, .accordion-head-span4, .accordion-head-span5, .accordion-head-span6, .accordion-head-span7 {
    flex: 1;
    text-align: center;
}
.accordion-head-span1 {
    text-align: center;
}
.accordion-head-span3 {
    max-width: 167px;
    flex: 0 0 167px;
    margin-right: 35px;
}
.accordion-head-span4 {
    max-width: 122px;
    flex: 0 0 122px;
}

.body-cell1, .body-cell2, .body-cell3, .body-cell4, .body-cell5, .body-cell6, .body-cell7 {
    flex: 1;
    text-align: center;
    margin-top: 20px;
}
.body-cell1 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
.panel-header {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}
.panel-header span {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    margin-left: 7px;
}
.panel-body {
    border-top: 1px solid #e7e4e4;
    padding: 0 25px 20px;
}
.body-cell3 .body-select-wrap {
    border-radius: 5px;
    border: 1px solid #6e6d6d;
    padding: 8px 20px;
    position: relative;
    flex: 1;
}
.body-cell3 .body-select-wrap:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 10px;
    top: 15px;
    height: 15px;
    width: 15px;
}
.body-cell3 .body-select-wrap select {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 15px;
    background: none;
}
.body-cell3 .body-select-wrap select:focus {
    outline: none;
}
.body-cell4 .body-select-wrap{
    border-radius: 5px;
    border: 1px solid #6e6d6d;
    padding: 8px 20px;
    position: relative;
    flex: 1;
}
.body-cell4 .body-select-wrap:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 10px;
    top: 15px;
    height: 15px;
    width: 15px;
}
.body-cell4 .body-select-wrap select {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    font-size: 15px;
    background: none;
}
.body-cell4 .body-select-wrap select:focus {
    outline: none;
}
section.quote-section {
    position: relative;
    /* z-index: 1; */
    background: #f5f5f5;
    padding-top: 70px;
    padding-bottom: 80px;
}
.floating-section {
    position: relative;
}
.floating-section h3 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 25px;
    margin-top: 30px;
}
.developer-list {
    overflow-y: scroll;
    height: 120px;
}
.developer-list::-webkit-scrollbar-track
{
	border: 2px solid white;
	background-color: #b2bec3;
}

.developer-list::-webkit-scrollbar
{
	width: 5px;
	background-color: #dfe6e9;
}

.developer-list::-webkit-scrollbar-thumb
{
	background-color: #CCC;
	border-radius: 10px;
}

.developer-list ul {
    padding-left: 0;
    min-height: 175px;
    padding-right: 30px;
}
.developer-list ul li {
    display: flex;
    border-bottom: 1px solid #d5d1d1;
    padding: 15px 0;
}
.developer-list ul li:first-child {
    padding-top: 0;
}
.developer-list ul li:last-child {
    border-bottom: 0;
}
.developer-list-name {
    font-size: 13px;
    font-weight: 500;
    flex: 0 0 55%;
}
.developer-list-pricing {
    text-align: right;
    flex: 1;
}
span.color {
    color: #3ed13e;
    font-weight: 600;
}
.developer-list-pricing {
    text-align: right;
    flex: 1;
    color: #ccc;
    font-size: 13px;
    font-weight: 500;
}
span.price {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
}
.list-total {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.list-total p {
    font-size: 13px;
    font-weight: 500;
    flex: 0 0 50%;
    color: #ccc;
    margin-bottom: 0;
}
.list-total span {
    text-align: right;
    flex: 1;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    justify-content: flex-end;
}
.right-head {
    align-items: flex-end;
    margin-bottom: 30px;
}
.right-head p {
    margin: 0;
    color: #ada7a7;
    font-size: 13px;
    flex: 0 0 39%;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin-bottom: 15px;
}
.right-head h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    color: #000;
    font-size: 24px;
}
.right-body {
    align-items: center;
    margin-bottom: 30px;
}
.right-body p {
    margin: 0;
    font-size: 17px;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}
.right-body h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    font-size: 17px;
    color: #000;
}
.right-foot {
    align-items: center;
    margin-bottom: 30px;
}
.right-foot p {
    margin: 0;
    font-size: 24px;
    color: #3EC066;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;
}
.right-foot h4 {
    margin: 0;
    flex: 1;
    text-align: center;
    font-size: 24px;
    color: #3EC066;
    font-weight: 500;
}
.floating-section-right button {
    width: 100%;
    padding: 24px 0;
    border-radius: 8px;
    background: #FF5800;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    border: none;
    letter-spacing: 0;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}
.floating-section-right button:before {
    content: '';
    position: absolute;
    background-image: url(/images/team-quote/right-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    right: 21%;
    top: 30px;
    height: 25px;
    width: 25px;
}

input[type=range] {
    visibility: hidden;
    width: 0;
    font-size: 0;
  }
  
  button.range {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    font-size: 24px;
    border: 1px solid lightgrey;
    cursor: pointer;
    -webkit-appearance: none;
    margin: 0 10px;
  }

  .float-sec-none {
      display: none;
  }
  .floating-section {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    bottom: 0;
    box-shadow: 0 -12px 10px 0 rgba(0,0,0,0.05);
  }
  /* #float-sec {
    
  } */

  .floating-section-trigger {
      background: #fff;
      height: 35px;
      width: 70px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      position: absolute;
      top: -35px;
      right: 1%;
      cursor: pointer;
      box-shadow: 0 -6px 10px 0 rgba(0,0,0,0.05);
  }
  .floating-section-trigger.active::before {
    background: url(/images/team-quote/down-arrow-big.png) no-repeat 0 0;
    transform: rotate(0);
    top: 5px;
    top: 15px;
}
  .floating-section-trigger::before {
      content: '';
      position: absolute;
      background: url(/images/team-quote/down-arrow-big.png) no-repeat 0 0;
      left: 0;
      right: 0;
      text-align: center;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      transform: rotate(-180deg);
    top: 5px;
  }
    .d-m-none {
        display: none;
    }
    .d-d-none {
        display: block;
    }
    .accordion-section .active .panel-body {
        display : block;
        /* align-items: center; */
     }
     .ttip.d-d-none {
        margin-bottom: 15px;
    }

    .minus, .plus{
        width:20px;
        height:20px;
        background:transparent;
        border-radius:4px;
        padding:8px 5px 8px 5px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-size: 0;
    }
    
    .number input {
        width: 45px;
        border-radius: 5px;
        border: 1px solid #6e6d6d;
        text-align: center;
        font-size: 15px;
        padding: 2px 0;
    }
    .number .minus {
        position: relative;
        cursor: pointer;
    }
    .number .minus:before {
        content: '';
        position: absolute;
        background: url(/images/team-quote/c-minus.png) no-repeat 0 0;
        height: 17px;
        width: 17px;
        top: 2px;
        left: -2px;
    }
    .number .plus {
        position: relative;
        cursor: pointer;
    }
    .number .plus:before {
        content: '';
        position: absolute;
        background: url(/images/team-quote/c-plus.png) no-repeat 0 0;
        height: 17px;
        width: 17px;
        top: 2px;
        left: 4px;
    }
    .quote-section-head-right {
        text-align: center;
    }
}
  @media only screen and (min-width: 768px) {
    .teamquote-body {
        .quote-section-head {
            display: flex;
            align-items: flex-start;
        }
        .quote-section-head-right {
            flex: 1;
            text-align: right;
        }
        .quote-select {
            margin: auto 0 auto auto;
        }
        .d-d-none {
            display: none;
        }
        .d-m-none {
            display: block;
        }
        .accordion-section .active .panel-body {
            display : flex;
            /* align-items: center; */
        }
        .body-cell3 {
            max-width: 165px;
            flex: 0 0 165px;
            margin-right: 35px;
            margin-left: 35px;
        }
        .body-cell4 {
            max-width: 120px;
            flex: 0 0 120px;
        }
        .body-cell1, .body-cell2, .body-cell3, .body-cell4, .body-cell5, .body-cell6, .body-cell7 {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
        }
        .ttip.d-d-none {
            margin-bottom: 0;
        }
        .body-cell1 {
            text-align: left;
            max-width: 330px;
            flex: 0 0 330px;
            justify-content: flex-start;
        }
        .number {
            display: flex;
            align-items: center;
        }
        .floating-section-wrap {
            display: flex;
            margin-bottom: 20px;
        }
        .floating-section-left {
            flex: 0 0 50%;
            border-right: 1px solid #e7e4e4;
            padding-right: 30px;
        }
        .floating-section-right {
            flex: 1;
            padding-left: 30px;
        }
        .floating-section-right button {
            font-size: 18px;
            letter-spacing: 3px;
        }
        .floating-section-right button:before {
            right: 23%;
        }
        .developer-list {
            height: 160px;
        }
        .right-head {
            display: flex;
        }
        .right-head p {
            text-align: left;
            margin-bottom: 0;
        }
        .right-head h4 {
            text-align: right;
        }
        .right-body {
            display: flex;
        }
        .right-body p {
            text-align: left;
            margin-bottom: 0;
        }
        .right-body h4 {
            text-align: right;
        }
        .right-foot {
            display: flex;
        }
        .right-foot p {
            text-align: left;
            margin-bottom: 0;
        }
        .right-foot h4 {
            text-align: right;
        }
        .quote-section h2 {
            font-size: 42px;
            text-align: left;
        }
        .quote-section-head h4 {
            text-align: left;
        }
        .quote-section-head-right {
            text-align: right;
        }
        .quote-select {
            margin: auto 0 auto auto;
        }
        .accordion-head {
            display: flex;
            text-align: left;
            padding: 0 25px;
        }
        .accordion-head-span1 {
            max-width: 330px;
            flex: 0 0 330px;
            text-align: left;
        }
        .body-cell6 {
            background: #EEF9F1;
            font-weight: 600;
        }
        .accordion-head-span6 .ttip {
            font-weight: 600;
        }
        .panel-body {
            padding: 0 25px;
        }
        .list-total span {
            padding-right: 35px;
        }
        .accordion-head-span2.d-m-none {
            flex: 0 0 102px;
        }
        .accordion-head-span3.d-m-none {
            margin: 0 35px;
        }
    }
  }
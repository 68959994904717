.new-service-section-dita {
    $dita: &;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__heading {
        font-family: "Poppins";
        font-weight: 700;
        margin-bottom: 20px !important;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
        }
    }
    &__para {
        font-family: "Poppins";
        font-weight: 500;
        color: #333;
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 29px;
            margin-bottom: 50px;
        }
    }
    .btn-orange {
        font-size: 16px;
        @include media-query(992px) {
            width: 280px;
            max-width: 280px !important;
            margin: 20px 5px 0;
        }
    }
    .new-service-section-wrapper {
        justify-content: center;
        @include media-query(992px) {
            justify-content: inherit;
        }
    }
}

.conversion-exp-dita {
    $conversion-exp-dita: &;
    .about-us__heading {
        @include media-query(992px) {
            font-size: 51px;
            line-height: 55px;
        }
    }
    .o-why-capital__content {
        @include media-query(992px) {
            padding: 0 120px;
        }
    }
    .o-why-capital__box {
        padding: 25px 25px;
    }
    .o-why-capital__title {
        font-weight: 700;
    }
    .btn-orange {
        font-size: 16px;
        @include media-query(992px) {
            width: 280px;
        }
    }
}

.conversion-solutuion {
    $conversion-solutuion:  &;
    padding: 30px 0;
    @include media-query(992px) {
        padding: 60px 0;
    }
    &__heading {
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            margin-bottom: 40px;
        }
    }
    .btn-orange {
        text-transform: capitalize;
        font-size: 16px;
        @include media-query(992px) {
            width: 280px;
            max-width: 280px !important;
        }
    }
}

.unlock-power {
    $unlock-power: &;
    @include media-query(992px) {
        background: url(/images/dita/power-unlock-bg.jpg) no-repeat center center / cover;
    }
    &__subtitle {
        font-family: "Poppins";
        font-weight: 500;
        color: #CAE7FF;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        @include media-query(992px) {
            padding-right: 120px;
            text-align: left;
        }
    }
    .o-discuss-project__spacing {
        @include media-query(992px) {
            padding: 70px 0;
        }
    }
}
.mid-border {
    @include responsive(desktop){
        background: url(/images/staff/mid-border.png) repeat-y 50% 50%;
    }
}

.with-cn-list {
    padding: 0 20px;
    li {
        font-size: 15px;
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        line-height: 21px;
        clear: both;
        margin-bottom: 20px;
        vertical-align: top;
        span {
            float: left;
            vertical-align: top;
            width: 55px;
            margin-bottom: 30px;
        }
    }
}
.with-cn {
    padding-bottom: 20px;
    @include responsive(desktop){
        padding: 80px 0 50px;
    }
    h2 {
        line-height: 36px;
        @include responsive(desktop){
            line-height: 43px;
        }
    }
    h3 {
        font-size: 20px;
        margin-bottom: 40px;
    }
    .padding-40 {
        padding-bottom: 40px;
    }
}

.expertise-python-section {
    border-top: 2px solid #14DF7D;
    @include responsive(desktop){
        margin-bottom: 80px;
    }
}
.expertise-python {
    border-bottom: 1px solid #9AA1AC;
    padding: 10px 0;
    span {
        width: 38%;
        display: inline-block;
        font-size: 18px;
        line-height: 26px;
        font-family: "Montserrat",sans-serif;
        color: #333;
        font-weight: 600;
        vertical-align: middle;
        @include responsive(desktop){
            font-size: 24px;
            line-height: 32px;
            vertical-align: top;
            padding-top: 10px;
        }
    }
    .python-img-holder {
        display: inline-block;
        width: 60%;
        vertical-align: middle;
        @include responsive(desktop){
            vertical-align: inherit;
        }
    }
    img {
        display: inline-block;
        margin: 10px;
    }
}

.fc-acco.o-case-studies-new .faq-holder .accordion1 span{
    font-size: 16px !important;
    line-height: 28px;
    @include responsive(desktop){
        font-size: 20px !important;
        line-height: 32px;
    }
}
.fc-acco {
    .pad-80 {
        padding: 50px 0 10px;
        @include responsive(desktop){
            padding: 80px 0;
        }
    }
    .faq-pan {
        margin-top: 20px;
        @include responsive(desktop){
            margin-top: 0;
            position: relative;
        }
    }
}
.faq-section {
    padding: 50px 0 10px;
    @include responsive(desktop){
        padding: 80px 0;
    }
}

.fc-acco {
    .faq-holder {
        margin-bottom: 20px;
    }
}

.remote-dev {
    .how-it-works-holder {
        img {
            margin: 100px auto 0;
        }
        span {
            line-height: 36px;
        }
    }
}


.o-engagement-model header {
    text-align: center;
    color: #fff;
    p {
        font-family: $main-font;
    }
}

.staff-chart {
    font-family: "Montserrat",sans-serif;
}

.staff-chart .chart-head .chart-head-1:first-child {
    background-color: transparent;
}
.staff-chart .chart-head .chart-head-1 {
    width: 19%;
    display: inline-block;
    background-color: #71E19D;
    padding: 10px 15px;
    vertical-align: top;
    @include responsive(desktop){
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    font-weight: 600;
}
.staff-chart .chart-head .chart-head-1 img {
    display: inline-block;
    vertical-align: top;
}
.staff-chart .chart-head .chart-head-1 span {
    display: inline-block;
}
.staff-chart .chart-mid .chart-mid-1:first-child {
    border-bottom: none;
    background-color: #CBE3FF;
    font-size: 12px;
    @include responsive(desktop){
        border-bottom: 1px solid #474D5A;
    }
}
.staff-chart .chart-mid .chart-mid-1:first-child span {
    font-size: 18px;
    padding-bottom: 5px;
    line-height: 20px;
}

.staff-chart .chart-head .chart-head-1 span strong {
    display: block;
}
.staff-chart .chart-mid .chart-mid-1 span {
    display: block;
}
.staff-chart .chart-mid .chart-mid-1 {
    width: 100%;
    @include responsive(desktop){
        width: 19%;
    }
    display: inline-block;
    background-color: #fff;
    padding: 10px 15px;
    vertical-align: top;
    font-size: 18px;
    font-weight: 600;
    @include responsive(desktop){
        min-height: 88px;
    }
    border-bottom: 1px solid #ccc;
}
.mid-sub {
    // background-color: #71E19D;
    padding: 10px 0 0;
    span {
        font-size: 16px;
    }
}
.staff-chart .chart-mid .chart-mid-1 span.price {
    font-size: 18px;
    padding: 10px 0;
    @include responsive(desktop){
        padding: 20px 0;
    }
    display: inline-block;
}
.gst {
    color: #CBE3FF;
    font-size: 14px;
    padding: 10px;
    font-family: $main-font;
    margin-bottom: 0;
    @include responsive(desktop){
        margin-bottom: 50px !important;
    }
}
.staff-chart .chart-mid .staff-first-child {
    border-top-left-radius: 5px;
}
.staff-chart .chart-mid .staff-first-child-bottom {
    @include responsive(desktop){
        border-bottom-left-radius: 5px;
    }
}

.we-hire {
    padding: 50px 0 20px;
    @include responsive(desktop){
        padding: 80px 0 50px;
    }
}

.new-pricing {
    .cmn-heading-para {
        @include responsive(desktop){
            max-width: 1024px;
            margin: 0 auto 20px;
        }
    }
}

.cs-new-p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.cs-new-h3 {
    padding-bottom: 40px;
    font-size: 28px;
    line-height: 42px;
    @include responsive(desktop){
        font-size: 36px;
        line-height: 49px;
    }
}


.o-skills-hero__schdule .minus-schedule-box {
    margin-top: 0;
    height: 100%;
}

.last-expertise {
    margin-bottom: 25px;
    @include responsive(desktop){
        margin-bottom: 0;
    }
}

.tech-exp {
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding: 65px 0 50px;
    }
}
.fc-acco {
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding: 65px 0 50px;
    }
}

.skill-what {
    .container {
        z-index: 9;
    }
}




/*Cheaper better*/

.cb-header-left{
    text-align: left!important;
    padding-top: 10px;
    @include responsive(desktop){
        padding-top: 40px;
    }
}
.cb-header-left h1{
    font-size: 24px!important;
    line-height: 28px!important;
    text-align: center;
    @include responsive(desktop){
        line-height: 58px!important;
        font-size: 48px!important;
        text-align: left;
    }
    font-weight: bold;
    padding-bottom: 20px;
}
.cb-header-left p{
    margin-bottom: 0;
    color: #2DBE70;
    font-size: 20px;
    font-weight: 700;
    font-family: "Montserrat",sans-serif;
}

.cb-header-left ul{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
    justify-content: center;
    margin-top: 20px;
    @include responsive(desktop){
        justify-content: inherit;
        margin-top: 0;
        padding-bottom: 40px;
    }
}
.cb-header-left ul li{
    align-self: center;
    margin-right: 0;
    padding: 15px;
    flex: 0 0 25%;
    @include media-query(992px) {
        margin-right: 20px;
        padding: 0;
        flex: 1;
    }
    @include media-query(1200px) {
        flex: inherit;
    }
}

.cb-header-right{
    text-align: left!important;
    padding-top:40px;
}
.cb-header-right ul{
    display: flex;
    flex-wrap: wrap;
}
.cb-header-right ul li{
    width: 43%;
    @include media-query(992px) {
        width: 165px;
    }
    @include media-query(1200px) {
        width: 184px;
    }
    color: #fff;
    padding: 20px;
    margin: 0 10px 20px;
    border-radius: 5px;
}
.cb-header-right ul li:nth-child(1){
    background-color: #4A90E2;
    border:1px solid #4A90E2;
}
.cb-header-right ul li:nth-child(2){
    border:1px solid #4A90E2;
}
.cb-header-right ul li:nth-child(3){
    border:1px solid #009363;
}
.cb-header-right ul li:nth-child(4){
    background-color: #009363;
    border:1px solid #009363;
}
.cb-header-right img{
    margin-bottom: 10px;
    min-height: 46px;
}
.cb-header-right h4{
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    @include responsive(desktop){
        font-size: 24px;
        line-height: 29px;
    }
    margin-bottom: 0;
}
.cb-header-right h5{
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    @include responsive(desktop){
        font-size: 16px;
        line-height: 29px;
        margin-top: 0;
    }
    margin-bottom: 0;
    margin-top: 5px;
}

.or-circle-new {
    display: block;
    font-size: 12px;
    line-height: 15px;
    font-family: 'Montserrat';
    background: #ccc;
    padding: 11px 0;
    border-radius: 30px;
    font-weight: bold; 
    width: 37px;
    height: 37px;
    text-align: center;
    margin: 10px auto;
    @include responsive(desktop){
        display: inline-block;
        margin: 0 30px 0 auto;
    }
}

.why-us-page {
    background: linear-gradient(135deg, #0e4173 0%, #051423 100%);
    .btn_wrapper {
        .btn-orange {
            @include responsive(desktop){
                margin: 0;
            }
        }
        
    }
    .anchor-link {
        @include responsive(desktop){
            text-align: left;
            margin-top: 0;
        }
    }
}



/*Industry*/
.tech-list {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.tech-list div{
    @include responsive(desktop){
        float: left;
        width: 20%;
    }
    padding: 20px 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
    color: #fff;
}
.tech-list div.tect-blue-bg{
    background-color: #4A90E2;
}
.tech-list div.tect-green-bg{
    background-color: #49C684;
}
.tech-list ul{
    padding-bottom: 25px;
    @include responsive(desktop){
        padding: 20px 0 20px 20px;
        float: left;
    }
}
.tech-list ul li{
    padding: 10px 0;
    @include responsive(desktop){
        float: left;
        padding: 0 20px;
        border-right: 1px solid #DEDEDE;
    }
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat",sans-serif;
    
}
.tech-list ul li:last-child{
    border-right: 0;
}
.tech-list ul li {
    a {
        font-weight: 500;
        color: #4A90E2;
        text-decoration: underline;
        transition: .2s all ease-in-out;
        &:hover {
            color: darken(#4A90E2, 10%);
        }
    }
}
// .tech-list ul li a:hover{
//     color: #4A90E2;
//     text-decoration: underline;
// }
.font-size-48{
    font-size: 48px;
    line-height: 56px;
}
.industry-box{
    padding: 40px;
    margin-bottom: 30px;
    @include responsive(desktop){
        min-height: 500px;
    }
}
.industry-box h3{
    font-size: 22px;
    line-height: 28px;
    @include responsive(desktop){
        font-size: 30px;
        line-height: 40px;
    }
    padding-top: 20px;
}

.f-software {
    .industry-box {
        margin-bottom: 0;
        @include responsive(desktop){
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 0;
            @include responsive(desktop){
                margin-bottom: 25px;
            }
        }
    }
    .padding-bottom-40 {
        h3 {
            text-align: center;
        }
    }
    .col-md-6 {
        margin-bottom: 25px;
        @include responsive(desktop){
            margin-bottom: 0;
        }
    }
}

.industry-banner {
    padding: 50px 0 20px !important;
    @include responsive(desktop){
        padding: 115px 20px 45px!important;
    }
    h1 {
        font-size: 28px;
        line-height: 36px;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 56px;
        }
    }
    h2 {
        font-size: 18px;
        line-height: 26px;
        @include responsive(desktop){
            font-size: 24px;
            line-height: 38px;
        }
    }
    p.green {
        font-size: 17px;
        color: #14DF7D !important;
        padding-top: 20px;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
        }
    }
    .btn_wrapper {
        .btn-orange {
            @include responsive(desktop){
                margin: 0;
            }
        }
        
    }
    .anchor-link {
        @include responsive(desktop){
            text-align: left;
            margin-top: 0;
        }
    }
}

.f-software {
    @include responsive(desktop){
        font-size: 48px;
        line-height: 61px;
    }
    .cmn-heading-para {
        @include responsive(desktop){
            font-size: 20px !important;
            line-height: 32px !important;
        }
    }
    padding: 50px 0 20px;
    @include responsive(desktop){
        padding: 70px 0 50px;
    }
}

.robust {
    padding: 50px 0 20px;
    @include responsive(desktop){
        padding: 70px 0 50px;
    }
    p {
        strong {
            font-weight: 700;
        }
    }
    ul.freamwork-list {
        display: block;
        @include responsive(desktop){
            display: flex;
        }
    }
}

.industry-case {
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    br {
        display: none;
        @include responsive(desktop){
            display: block;
        }
    }
}

.we-use {
    h2 {
        padding-bottom: 30px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
}


.js-portfolio-slider {
    .slick-arrow {
        display: none !important;
        @include media-query(1200px) {
            display: block !important;
        }
    }
}

.more-class {
    &:before {
        display: none;
    }
}

.VueJS, .iOS, .Android, .Magento, .Drupal {
    .services-pan.more-class {
        padding: 0;
    }
}

.nw-wp-section {
    background: #08426f!important;
}

.odd-grey-bg {
    background: #f1f1f1;
}
.mern-stack-text-new{
    color: #333;
    @include responsive(desktop){
        color:#fff;
    }
    
}
p .anchor-link-new{
    text-align: left;
    max-width: 100%;
}
.slick-track{
    .async-image-new{
        height: 350px;
        background-position: center center;
        @include responsive(desktop){
            height: auto;
        }
    }
}
.hover-underline-new{
    font-weight: 500;
    border-bottom: 1px solid #fff;
    &:hover{
        border-bottom: 1px solid transparent;
    }
}
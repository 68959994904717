.new-faq {
    $new-faq: &;

    font-family: 'Poppins';
    font-size: 51px;
    font-weight: 700;
    color: #262E36;
    background: #DDF0F8;
    padding-bottom: 50px;
    padding-top: 30px!important;
    @include media-query(992px) {
        padding: 60px 0 60px!important;
    }
    &__wrapper {
        height: 335px;
        overflow: hidden;
        position: relative;
        max-width: 830px;
        margin: 0 auto;
        @include media-query(992px) {
            height: 275px;
        }
    }
    &__blur {
        &--active {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 50%;
            background: rgb(221,240,248);
            background: -moz-linear-gradient(180deg, rgba(221,240,248,0) 0, rgba(221,240,248,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(221,240,248,0) 0, rgba(221,240,248,1) 100%);
            background: linear-gradient(180deg, rgba(221,240,248,0) 0, rgba(221,240,248,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddf0f8",endColorstr="#ddf0f8",GradientType=1);
            z-index: 99;
            @include media-query(992px) {
                height: 52%;
            }
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(.1rem);
            }
        }
        &--active-dita {
            @include media-query(992px) {
                height: 65%;
            }
        }
    }
    .faq-holder {
        background: none;
        border-bottom: 1px solid #333333;
        box-shadow: none;
        margin-bottom: 0;
        .accordion1 {
            font-family: 'Poppins';
            font-size: 17px;
            line-height: 25px;
            color: #262E36;
            font-weight: 600;
            background: none;
            padding: 15px 50px 15px 0;
            @include media-query(992px) {
                font-size: 17px;
                line-height: 20px;
                padding: 20px 0;
            }
            &:after {
                font-size: 36px;
                position: absolute;
                right: 2px;
                width: 37px;
                height: 37px;
                line-height: 37px;
                color: #333;
                border: 1px solid #333;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
        .panel1 {
            font-family: 'Poppins';
            padding: 0;
            background: none;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            p{
                font-family: 'Poppins' !important; 
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
            }
            li{
                font-family: 'Poppins' !important; 
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;   
            }
        }
    }
    .click-to-btn {
        text-align: center;
        position: absolute;
        bottom: -60px;
        z-index: 99;
        span {
            font-family: 'Poppins';
            font-size: 17px;
            font-weight: 600;
            background: #fff;
            color: #333;
            max-width: 230px;
            display: block;
            border: 2px solid #333;
            border-radius: 5px;
            padding: 15px 0;
            margin: 0 auto;
            cursor: pointer;
        }
        svg {
            position: relative;
            top: -10px;
        }
    }
    h2 {
        font-weight: 700;
    }

    .subheading {
        font-family: 'Poppins';
        font-size: 25px;
        line-height: 34px;
        font-weight: 600;
        color: #333;
        display: block;
        max-width: 810px;
        margin: 0 auto 35px;
        text-align: center;
    }
}
.how-help-you {
    $how-help-you: &;

    background-color: #052C58;
    padding: 25px 0 40px;
    @include media-query(992px){
        padding: 60px 0 80px;
        // background-image: url(/images/how-we-help-you/how-we-help-graphic.png);
        background-repeat: no-repeat;
        background-position: top left;
    }
    &__card {
        @include media-query(992px){
            display: flex;
            justify-content: center;
            // gap: 30px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    &__card-items {
        flex: 1;
        background: #fff;
        padding: 30px 20px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        position: relative;
        flex-wrap: wrap;
        margin:0;
        @include media-query(770px){
            margin:15px 0;
            height: 380px;
        }
        @include media-query(992px){
            padding: 30px 25px 30px;
            flex-direction: column;
            flex-wrap: inherit;
            height: inherit;
            margin:0 7px !important;
        }
        a {
            svg {
                transition: .3s all ease-in-out;
            }
            &:hover {
                svg{
                    transform: translateX(10px);
                }
                path {
                    fill: #FF5800;
                }
            }
        }
    }
    &__card-items--border-blue {
        border-bottom: 8px solid #4492DC;
    }
    &__card-items--border-green {
        border-bottom: 8px solid #3CC065;
    }
    &__label {
        font-family: 'Poppins' !important;
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:hover {
            .how-help-you__radio ~ .how-help-you__checkmark {
                background-color: #333;
            }
        }
    }
    &__radio {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .how-help-you__checkmark{
            background-color: #fff;
            border: 2px solid #333;
        }
        &:checked ~ .how-help-you__checkmark:after {
            display: block;
        }
    }
    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: #fff;
        border: 2px solid #333;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #333333;
        }
    }
    &__icon {
        height: auto;
        display: flex;
        flex: 0 0 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        @include media-query(992px){
            flex: inherit;
            justify-content: inherit;
        }
    }
    &__heading {
        font-family: 'Poppins' !important;
        font-size: 25px;
        line-height: 38px;
        font-weight: 700;
        color: #fff;
        @include media-query(992px){
            font-size: 51px;
            line-height: 64px;
        }
    }
    &__sub-heading {
        font-family: 'Poppins' !important;
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
        color: #35B15C;
        margin-bottom: 35px;
        @include media-query(992px){
            font-size: 25px;
            line-height: 34px;
            margin-bottom: 25px;
        }
    }
    &__card-heading {
        font-family: 'Poppins' !important;
        font-size: 21px;
        line-height: 30px;
        font-weight: 700;
        margin: 0 0 15px;
        // flex: 1;
        flex:0 0 100%;
        @include media-query(992px){
            font-size: 31px;
            line-height: 36px;
            flex: inherit;
        }
    }
    &__card-sub-heading {
        font-weight: 600;
        color: #333333;
        font-size: 15px;
        line-height: 21px;
        margin:0;
        @include media-query(992px) {
            margin-bottom: 0;
        }
    }
    &__badge {
        font-family: 'Poppins' !important;
        position: absolute;
        top: -5px;
        right: 15px;
        background: #3CC065;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 37px;
    }
    .how-we-help-buttons,
    .let-help__btn {
        font-family: 'Poppins' !important;
        display: block;
        margin: 0 auto;
        width: 300px;
        background: #FF5800;
        text-align: center;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        padding: 16px 0;
        border-radius: 5px;
        border: 1px solid #FF5800;
        position: relative;
        z-index: 99;
        margin-top: 20px;
        &:hover {
            color: #FF5800;
            background: #fff;
        }
    }
    .owl-dots {
        display: none;
    }
    &__details {
        margin: 20px 0;
    }
    &__link {
        text-align: center;
    }
    &__anchor {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600;
        color: #1D52C7;
        display: inline-block;
        margin-top: 0;
        text-decoration: underline;
        background: none;
        width: auto;
        max-width: inherit;
        &:hover {
            color: #3CC065;
        }
    }
}

.let-help {
    $let-help: &;

    max-width: 100%;
    position: relative;
    text-align: center;
    margin-top: 25px;
    @include media-query(992px){
        max-width: 1050px;
        margin: 60px auto 0;
        padding: 0 20px 20px 20px;
    }
    &:before {
        content: '';
        position: absolute;
        border: 1px solid #5787be;
        top: 14px;
        left: 0;
        right: 0;
        height: 100%;
        border-radius: 5px;
        display: none;
        @include media-query(992px){
            display: block;
        }
    }
    &__heading {
        font-family: 'Poppins' !important;
        font-size: 23px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        display: inline-block;
        background-color: #052C58;
        @include media-query(992px){
            font-size: 25px;
            padding: 0 15px;
        }
    }
    &__sub-heading {
        font-family: 'Poppins' !important;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        @include media-query(992px){
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 30px;
        }
    }
    &__checkbox-list {
        display: flex;
        align-items: center;
        // gap: 20px;
        flex-wrap: wrap;
        @include media-query(992px){
            flex-wrap: inherit;
            justify-content: center;
        }
    }
    &__checkbox-items {
        flex: 0 47%;
        margin:10px;
        @include media-query(992px){
            flex: inherit;
            overflow: hidden;
            border-radius: 5px;
            padding: 1px;
            background: rgb(30,142,65);
            background: -moz-linear-gradient(180deg, rgba(30,142,65,1) 0%, rgba(39,77,173,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(30,142,65,1) 0%, rgba(39,77,173,1) 100%);
            background: linear-gradient(180deg, rgba(30,142,65,1) 0%, rgba(39,77,173,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e8e41",endColorstr="#274dad",GradientType=1);
        }
    }
    &__inner-wrapper {
        @include media-query(992px){
            border-radius: 5px;
            background-color: #05284D;
            padding: 20px 15px 20px 15px;
        }
    }
    &__checkbox-label {
        font-family: 'Poppins' !important;
        text-align: left;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        display: block;
        position: relative;
        padding-left: 28px;
        margin-bottom: 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include media-query(992px){
            font-size: 14px;
            line-height: 21px;
        }
        &:hover {
            &__checkbox-input ~ &__checkmark{
                background-color: #ccc;
                &:after {
                    display: block;
                }
            }
        }
    }
    &__checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .let-help__checkmark{
            background-color: transparent;
            &:after {
                content: "";
                position: absolute;
                display: block;
                left: 2px;
                top: 2px;
                width: 5px;
                height: 11px;
                border: solid #000;
                // border-width: 0 2px 2px 0;
                // -webkit-transform: rotate(45deg);
                // -ms-transform: rotate(45deg);
                // transform: rotate(45deg);
                width: 12px;
                height: 12px;
                border: 0;
                background: #3CC065;
                border-radius: 50%;
            }
        }
    }
    &__checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        height: 18px;
        width: 18px;
        border-radius: 2px;
        border: 1px solid #3CC065;
        background-color: transparent;
        border-radius: 50%;
        transform: translateY(-50%);
        @include media-query(992px){
            top: 2px;
            transform: inherit;
        }
    }
    &__btn {
        font-family: 'Poppins' !important;
        background-color: #FF5800;
        border: 1px solid #FF5800;
        border-radius: 5px;
        padding: 18px 15px;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        width: 360px;
        margin: 30px auto 0;
        transition: .3s all ease-in-out;
        text-transform: capitalize;
        margin-bottom: 0;
        svg {
            margin-left: 10px;
        }
        &:hover {
            background-color: #fff;
            color: #FF5800;
            path {
                fill: #FF5800;
            }
        }
    }
    .compare-engage-link {
        font-size: 13px;
        line-height: 20px;
        text-decoration: underline;
        position: relative;
        z-index: 99;
        margin-top: 10px;
        font-weight: 700;
        &:hover {
            color: #00b54a !important;
        }
    }
}
.link {
    background: none !important;
    padding: 0 !important;
    width: auto !important;
    border: none !important;
    display: block !important;
    margin: 10px auto;
    color: #fff;
}


.custom-dev-help {
    .how-help-you {
        background: url(/images/how-we-help-you/custom-dev-how-we-bg.jpg) no-repeat bottom right / cover;
    }
    .let-help__heading {
        background: none;
    }
    .how-help-you__heading {
        font-weight: 500;
    }
    .how-help-you__card-heading {
        @include media-query(992px){
            font-size: 24px;
            line-height: 29px;
        }
    }
    .how-help-you__card-items {
        border-bottom: none;
    }
    .how-we-help-buttons {
        background: none;
        color: #333;
        transition: .3s all ease-in-out;
        svg {
            path {
                fill: #333;
            }
        }
        &:hover {
            background: #FF5800;
            color: #fff;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    .how-we-help-knowmore {
        color: #0D65CA;
    }
    .how-help-you__card-sub-heading {
        margin:0;
        @include media-query(992px){
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            margin: 0 0 25px;
        }
    }
}
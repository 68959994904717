.o-service-banner {
  .o-skill-banner__bannertitle {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;

      @include media-query(767px) {
          font-size: 41px;
          line-height: 45px;
          text-align: left;
      }

      @include media-query(992px) {
          font-size: 50px;
          line-height: 64px;
          margin-bottom: 30px;
      }
  }
}

.o-we-develop {
  $o-we-develop : &;

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__title {
      font-family: Poppins;
      font-size: 30px;
      line-height: 35px;
      color: #262E36;
      text-align: center;
      font-weight: 700;
      margin-bottom: 15px;

      @include media-query(767px) {
          font-size: 38px;
          line-height: 46px;
      }

      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      }
      &--dita {
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
        }
      }
  }

  &__subtitle {
      font-family: Poppins;
      font-size: 21px;
      line-height: 30px;
      color: #333333;
      text-align: center;
      font-weight: 500;
      &--dita {
        margin-bottom: 20px;
        line-height: 31px;
        @include media-query(992px) {
            margin-bottom: 30px;
        }
      }
      &--merginbtm{
        margin-bottom: 20px;
        line-height: 31px;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
      }
  }

  &__box {
      background-color: #fff;
      border: 1px solid #DDDDDD;
      padding: 30px;
      box-shadow: 0 0 25px 3px rgba(0, 0, 0, .08);
      height: 100%; 
      &--color {
        position: relative;
        @include media-query(992px) {
            padding: 30px 30px 30px 30px;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 30px;
            width: 89px;
            height: 5px;
        }
      }
      &--color1 {
        &:before {
            background: #64B9F4;
        }
      }
      &--color2 {
        &:before {
            background: #FBBA14;
        }
      }
      &--color3 {
        &:before {
            background: #A15FAE;
        }
      }
      &--color4 {
        &:before {
            background: #3CC065;
        }
      }
    &--custom {
        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: 30px;
            width: 68px;
            height: 6px;
            background: #000;
        }
    }
    &--custom-color1 {
        &::before {
            background: #64B9F4;
        }
    }
    &--custom-color2 {
        &::before {
            background: #F29906;
        }
    }
    &--custom-color3 {
        &::before {
            background: #20DEA5;
        }
    }
    &--custom-color4 {
        &::before {
            background: #4FA941;
        }
    }
    &--custom-color5 {
        &::before {
            background: #FBBA14;
        }
    }
    &--custom-color6 {
        &::before {
            background: #A15FAE;
        }
    }
  }

  &__topicon {
      display: inline-flex;
      height: 70px;
      align-items: center;
      margin-bottom: 11px;
  }

  &__boxtitle {
      font-family: Poppins;
      color: #333333;
      font-size: 21px;
      line-height: 25px;
      font-weight: 700;
  }
  &__boxtitle--custom {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
          display: inline-block;
          margin-right: 10px;
      }
  }

  &__boxcontent {
      font-family: Poppins;
      color: #333333;
      font-size: 15px;
      line-height: 25px;
      margin: 0;
      &--list{
        list-style: disc;
        padding: 10px 0 0 20px;
      }
  }

  .btn {
      text-transform: capitalize;
      max-width: 352px;
      font-size: 14px;
      margin: 18px auto 0;
      padding: 16px;
      width: 100%;

      @include media-query(767px) {
          font-size: 17px;
      }
  }
  .btn-orange--dita {
    font-size: 16px;
    @include media-query(992px) {
        width: 280px;
        margin-top: 10px;
    }
  }
  .o-key-diff-box{
    h4{
        font-family: Poppins;
    }
    p{
        font-family: Poppins;
    }
  }
  &--hire-salesforce {
    .btn {
        @include media-query(992px) {
            max-width: 220px;
        }
    }
  }
}

.o-discuss-project {
  $o-discuss-project: &;
  background-color: #102355;

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__title {
      font-family: Poppins;
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      color: #FFFFFF;
      margin-bottom: 12px;
      text-align: center;
      margin-top: 20px;

      @include media-query(767px) {
          margin-top: 0;
          margin-bottom: 15px;
          font-size: 34px;
          line-height: 45px;
          text-align: left;
      }

      @include media-query(992px) {
          font-size: 41px;
          line-height: 55px;
      }
  }

  &__list {
      position: relative;
      counter-reset: counter;
  }

  &__items {
      font-family: Poppins;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 18px;
      font-weight: 500;
      color: #CAE7FF;

      &:before {
          content: counter(counter);
          counter-increment: counter;
          color: #fff;
          background-color: #4492DC;
          display: inline-flex;
          width: 27px;
          height: 27px;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          margin-top: 1px;
          border-radius: 50%;
          float: left;

          @include media-query(767px) {
              margin-top: -4px;
          }
      }

      &:last-child {
          margin-bottom: 0;
      }
  }

  .btn {
      margin: 30px 0 0;
      text-transform: capitalize;
      font-size: 14px;
      max-width: 100%;
      width: 100%;
      padding: 16px;

      @include media-query(767px) {
          font-size: 17px;
          margin: 30px 0 0;
          max-width: 350px;
      }
  }
  .btn-web-development {
    @include media-query(767px) {
        font-size: 17px;
        max-width: 480px;
    }
  }
  .btn-lets-discuss-custom {
    @include media-query(767px) {
        font-size: 17px;
        max-width: 400px;
    }
  }
  .btn-lets-discuss-salesforce {
    @include media-query(767px) {
        font-size: 17px;
        max-width: 420px;
    }
  }
  &--dita {
    .o-discuss-project__para--dita {
        font-family: "Poppins";
        font-weight: 500;
        color: #CAE7FF;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        @include media-query(992px) {
            padding-right: 120px;
            text-align: left;
        }
    }
  }
}
.o-development-process{
  $o-development-process : &;
  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &--multipleitems{
      #{$o-development-process}__list{
          @include media-query(992px) {
              justify-content: center;
          }
      }
      #{$o-development-process}__items{
          @include media-query(992px) {
              margin: 80px 28px 0;
              &:nth-child(1){
                  margin-left: 0;
              }
              &:nth-child(3n+4){
                  margin-left: 0;
              }
              &:nth-child(3n+3){
                  margin-right: 0;
              }
              &:nth-child(6){
                  &:after{
                      width: 506px;
                      @include media-query(1181px) {
                          width: 636px;
                      }
                  }
              }
          }
          @include media-query(1181px) {
              width: 29%;
          }
          @include media-query(1300px) {
              width: 30%;
          }
      }
  }

  &--multipleitemsone{
    #{$o-development-process}__list{
        @include media-query(992px) {
            justify-content: center;
        }
    }
    #{$o-development-process}__items{
        @include media-query(992px) {
            margin: 80px 28px 0;
            &:nth-child(1){
                margin-left: 0;
            }
            &:nth-child(3n+4){
                margin-left: 0;
            }
            &:nth-child(3n+3){
                margin-right: 0;
            }
            &:nth-child(6){
                &:after{
                    width: 330px;
                    @include media-query(1181px) {
                        width: 480px;
                    }
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
        @include media-query(1181px) {
            width: 29%;
        }
        @include media-query(1300px) {
            width: 30%;
        }
    }
  }

  &--process1 {
      .btn {
          max-width: 350px !important;
      }
  }

  &__title {
      font-family: Poppins;
      font-size: 30px;
      line-height: 35px;
      color: #262E36;
      font-weight: 700;
      text-align: center;

      @include media-query(767px) {
          font-size: 35px;
          line-height: 42px;
      }

      @include media-query(992px) {
          font-size: 46px;
          line-height: 60px;
      }
      &--dita {
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            margin-bottom: 40px;
        }
      }
  }

  &__subtitle {
      font-family: Poppins;
      font-size: 21px;
      line-height: 31px;
      color: #333;
      font-weight: 500;
      text-align: center;
      margin: 0;

      @include media-query(992px) {
          margin-bottom: 15px;
      }
  }

  &__list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      // align-items: center;

      @include media-query(992px) {
          justify-content: space-between;
      }
  }

  &__items {
      position: relative;
      border: 1px solid #898989;
      padding: 0 20px 15px;
      width: 100%;
      margin: 40px 0 0;

      @include media-query(767px) {
          margin: 64px 0 0;
          min-height: 193px;
      }

      @include media-query(992px) {
          width: 28%;
          margin: 80px 0 0;
      }

      @include media-query(1181px) {
          width: 30%;
      }

      &:after {
          position: absolute;
          content: "";
          width: 32px;
          height: 10px;
          background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat center;
          background-size: 100%;
          top: inherit;
          transform: rotate(90deg);
          right: 0;
          left: 0;
          bottom: -25px;
          margin: auto;

          @include media-query(767px) {
              width: 55px;
              height: 20px;
              bottom: -40px;
          }

          @include media-query(992px) {
              transform: translateY(-50%);
              left: inherit;
              top: 50%;
              bottom: inherit;
              transform: translateY(-50%);
              right: -55px;
              margin: 0;
          }
      }

      &:nth-child(3n+3) {
          &:after {
              position: absolute;
              content: "";
              width: 32px;
              height: 10px;
              background: url(/images/new-skill-section/icons/step-arrow.svg) no-repeat center;
              background-size: 100%;
              top: inherit;
              transform: rotate(90deg);
              right: 0;
              left: 0;
              bottom: -25px;
              margin: auto;

              @include media-query(767px) {
                  width: 55px;
                  height: 20px;
                  bottom: -40px;
              }

              @include media-query(992px) {
                  position: absolute;
                  content: "";
                  width: 676px;
                  height: 62px;
                  background: url(/images/new-skill-section/development-process-arrow.svg) no-repeat center;
                  background-size: 100%;
                  top: inherit;
                  transform: none;
                  right: 50%;
                  left: inherit;
                  bottom: -72px;
              }

              @include media-query(1181px) {
                  width: 836px;
              }
          }
      }

      &:last-child() {
          &:after {
              display: none;
          }
      }
      &--short-arrow {
          &:after {
              @include media-query(992px) {
                  width: 450px !important;
                  background: url(/images/new-skill-section/short-dashed-arrow.png) no-repeat center !important;
              }
          }
      }
      &--mr-0 {
          margin-right: 0 !important;
      }
      &--dita {
        padding: 1px;
        cursor: pointer;
        overflow: hidden;
        @include media-query(992px) {
            margin: 0 15px 30px;
            max-width: 360px;
            width: 32%;
        }
        &::after {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            top: -200px;
            right: 0;
            bottom: 0;
            left: -150px;
            background: #898989;
            width: 950px;
            height: 950px;
            -webkit-transition: .3s all ease;
            transition: .3s all ease;
        }
        &:hover {
            &:before {
                -webkit-animation: rotation2 3s linear infinite;
                animation: rotation2 3s linear infinite;
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, .62)), to(rgba(6, 87, 181, .62)));
                background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, .62) 52%, rgba(6, 87, 181, .62) 100%);
            }
        }
        &:nth-child(1),
        &:nth-child(4) {
            @include media-query(992px) {
                margin-left: 0;
            }
        }
        &:nth-child(3),
        &:nth-child(6) {
            @include media-query(992px) {
                margin-right: 0;
            }
        }
      }
  }

  &__box {
    background: #fff;
    height: 100%;
    position: relative;
    padding: 15px;
    @include media-query(992px) {
        padding: 20px 40px 15px 20px;
    }
  } 

  &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(53, 91, 191, .1);
      color: #fff;
      width: 73px;
      height: 73px;
      border-radius: 50%;
      margin-bottom: 16px;
      margin-top: -35px;

      strong {
          position: relative;
          width: 45px;
          height: 45px;
          background-color: #355BBF;
          border-radius: 50%;
          margin: auto;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
      }
  }

  &__titlearea {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;

      i {
          margin-right: 13px;
      }
  }

  &__itemtitle {
      font-family: Poppins;
      font-size: 20px;
      line-height: 31px;
      color: #333333;
      font-weight: 600;
      margin: 0;
  }

  &__itemcontent {
      font-family: Poppins;
      font-size: 15px;
      line-height: 22px;
      color: #333333;
      margin: 0;
  }

  .btn {
      text-transform: capitalize;
      padding: 16px;
      font-size: 14px;
      max-width: 100%;
      width: 100%;
      margin: 30px auto 0;

      @include media-query(767px) {
          max-width: 306px;
      }

      @include media-query(992px) {
          font-size: 17px;
          margin: 34px auto 0;
      }
  }

    &--servicenow {
        .btn-orange {
            @include media-query(992px) {
                max-width: 360px;
            }
        }
    }

    &--ai {
        .o-development-process__items {
            @include media-query(992px) {
                min-height: 385px;
            }

          @include media-query(1150px) {
              min-height: 380px;
          }

          @include media-query(1200px) {
              min-height: 290px;
          }
      }
  }

  &--custom-software {
      .o-development-process__items {
          @include media-query(992px) {
              min-height: 215px;
          }

          @include media-query(1150px) {
              min-height: 215px;
          }

          @include media-query(1200px) {
              min-height: 200px;
          }
      }
  }

  .btn-350 {
      @include media-query(767px) {
          max-width: 350px;
      }
  }
  &--dita {
    border-bottom: 1px solid #E6E6E6;
    .btn-orange {
        @include media-query(992px) {
            width: 280px;
            max-width: 280px !important;
            margin-top: 10px;
        }
    }
    .o-development-process__titlearea {
        i {
            width: 50px;
            flex: 0 0 50px;
            display: inline-block;
        }
    }
  }
}

.o-api-integration {
  $o-api-integration : &;
  background-color: #F9F9F9;
  border-bottom: 1px solid #E0E0E0;

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__heading {
      font-family: Poppins;
      font-size: 32px;
      line-height: 40px;
      color: #262E36;
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;

      @include media-query(767px) {
          font-size: 38px;
          line-height: 45px;
      }

      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      }
  }
  &__sub-heading {
    font-family: Poppins;
        color: #333;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 31px;
            margin-bottom: 10px;
        }
  }
  .custom-solution__sub-heading{
    margin: 0 0 5px;
  }
    &--servicenow {
        .btn-orange {
            text-transform: capitalize;
        }
        border-bottom: 0;
    }

    &__box {
        position: relative;
        margin-top: 30px;

      @include media-query(992px) {
          margin-top: 40px;
      }
  }

    &__single-icon {
        display: inline-block;
        margin-bottom: 20px;
    }

    &__imagewrap {
        background-color: #fff;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-bottom: 25px;

      @include media-query(992px) {
          min-height: 180px;
      }
  }

  &__imageitem {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      border-right: 1px solid #E1E1E1;
      width: 50%;
      max-height: 82px;
      height: auto;

      &:last-child {
          border-right: none;
      }
  }

  &__boxtitle {
      font-family: Poppins;
      font-size: 18px;
      line-height: 24px;
      color: #333333;
      font-weight: 700;
      margin-bottom: 15px;

      @include media-query(992px) {
          font-size: 21px;
          line-height: 25px;
      }

  }

  &__boxcontent {
      font-family: Poppins;
      font-size: 15px;
      line-height: 25px;
      color: #333;
      margin: 0;
  }

  .btn {
      max-width: 100%;
      padding: 16px;
      font-size: 17px;
      margin: 30px auto 0;
      width: 100%;
      text-transform: capitalize;
      @include media-query(767px) {
          max-width: 368px;
      }
  }
}

.o-api-benefit {
  $o-api-benefit : &;

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__heading {
      font-family: Poppins;
      font-size: 32px;
      line-height: 40px;
      color: #262E36;
      font-weight: 700;
      text-align: center;
      @include media-query(767px) {
          font-size: 38px;
          line-height: 45px;
      }
      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      }
  }

  &__subheading {
    font-family: Poppins;
    font-size: 18px;
    line-height: 27px;
    color: #333;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    @include media-query(767px) {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 50px;
    }
}

  &__box {
      position: relative;
      border: 1px solid #DDDDDD;
      height: 100%;
      border-radius: 5px;
      padding: 25px;

      @include media-query(992px) {
          min-height: 270px;
          padding: 28px;
      }

      &:before {
          position: absolute;
          content: "";
          width: 74px;
          height: 7px;
          left: 28px;
          top: -4px;
      }

      &--sky {
          &:before {
              background-color: #64B9F4;
          }
      }

      &--dark-blue {
        &:before {
          background-color: #052c58;
        }
      }

      &--yellow {
          &:before {
              background-color: #FBBA14;
          }
      }

      &--purple {
          &:before {
              background-color: #A15FAE;
          }
      }

      &--green {
          &:before {
              background-color: #3CC065;
          }
      }
  }

  &__boxcontent {
      font-family: Poppins;
      font-size: 19px;
      line-height: 28px;
      color: #262E36;
      font-weight: 500;
      margin: 0;

      @include media-query(992px) {
          font-size: 21px;
          line-height: 31px;
      }
  }

  .btn {
      text-transform: capitalize;
      padding: 16px;
      font-size: 14px;
      margin: 10px auto 0;
      width: 100%;
      max-width: 100%;

      @include media-query(767px) {
          font-size: 17px;
          max-width: 350px;
      }
  }
}

.o-releted-service {
  $o-api-benefit : &;
  background-color: #f6f6f6;

  &.bg-white {
      .o-releted-service__link {
          background-color: #fff;
      }
  }

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__heading {
      font-family: Poppins;
      font-size: 32px;
      line-height: 40px;
      color: #262E36;
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;

      @include media-query(767px) {
          font-size: 38px;
          line-height: 45px;
      }

      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      }
  }

  &__subheading {
      font-family: Poppins;
      font-size: 18px;
      line-height: 28px;
      color: #333333;
      font-weight: 500;
      text-align: center;
      margin-bottom: 30px;

      @include media-query(992px) {
          font-size: 21px;
          line-height: 31px;
          margin-bottom: 60px;
      }
  }

  &__gradient {
      display: block;
      position: relative;
      margin-bottom: 26px;
      cursor: pointer;
      overflow: hidden;
      padding: 2px;
      border: 0;
      border-radius: 110px;

      &:after {
          content: '';
          position: absolute;
          top: -200px;
          right: 0;
          bottom: 0;
          left: -150px;
          background: #898989;
          width: 950px;
          height: 950px;
          -webkit-transition: .3s all ease;
          transition: .3s all ease;
      }

      &--sky {
          &:after {
              background: #64B9F4;
          }
      }

      &--orange {
          &:after {
              background: #E26826;
          }
      }

      &--green {
          &:after {
              background: #8AC640;
          }
      }
      
      &--color4 {
        &:after {
            background: #A15FAE;
        }
    }

    &--color5 {
        &:after {
            background: #3B5085;
        }
    }

    &--color6 {
        &:after {
            background: #FBBA14;
        }
    }

    &--color7 {
        &:after {
            background: #00B67A;
        }
    }

      &:hover {
          &:after {
              -webkit-animation: rotation2 3s linear infinite;
              animation: rotation2 3s linear infinite;
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #88deb1), color-stop(20%, #1ab864), color-stop(52%, rgba(35, 116, 212, .62)), to(rgba(6, 87, 181, .62)));
              background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, .62) 52%, rgba(6, 87, 181, .62) 100%);
          }
      }
  }

  &__link {
      position: relative;
      background-color: #f6f6f6;
      border-radius: 110px;
      z-index: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      height: 80px;
  }

  &__linkcontent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 235px;

      i {
          margin-right: 15px;
          flex: 0 0 40px;
      }

      span {
          font-family: Poppins;
          font-size: 20px;
          line-height: 25px;
          font-weight: 600;
          color: #333333;
      }
      &--small {
        span {
            font-size: 19px;
        }
      }
  }
}

.o-latest-blog {
  $o-latest-blog : &;
  border-top: 1px solid #E8E8E8;

  &__spacing {
      padding: 30px 0;

      @include media-query(992px) {
          padding: 60px 0;
      }
  }

  &__heading {
      font-family: Poppins;
      font-size: 32px;
      line-height: 40px;
      color: #262E36;
      font-weight: 700;
      margin: 0 0 10px;
      text-align: center;

      @include media-query(767px) {
          font-size: 38px;
          line-height: 45px;
          text-align: left;
      }

      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      }
  }

  &__subheading {
      font-family: Poppins;
      font-size: 22px;
      line-height: 30px;
      color: #35B15C;
      font-weight: 600;
      text-align: center;

      @include media-query(767px) {
          text-align: left;
      }

      @include media-query(992px) {
          font-size: 25px;
          line-height: 34px;
      }
  }

  &__list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      &--three{
        #{$o-latest-blog}__item{
            @include media-query(992px) {
                width: 32%;
                margin-right: 1%;
                margin-bottom: 0;
            } 
        }
        #{$o-latest-blog}__blogtitle{
            @include media-query(767px) {
                font-size: 18px;
                line-height: 24px;
            }
      
            @include media-query(992px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
      }
  }

  &__item {
      max-width: 550px;
      width: 100%;
      margin-bottom: 20px;

      @include media-query(992px) {
          width: 47%;
          margin-right: 6%;
          margin-bottom: 0;
      }

      &:last-child {
          margin-right: 0;
      }
  }

  &__link{
    &:hover{
        #{$o-latest-blog}__blogtitle{
            color: #35b15c;
        } 
    }
  }

  &__imagewrap {
      width: 100%;
      // height: 250px;
      height: auto;
      margin-bottom: 15px;

      @include media-query(992px) {
          max-height: 450px;
          margin-bottom: 35px;
      }

      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }

  &__blogtitle {
      font-family: Poppins;
      font-size: 18px;
      line-height: 24px;
      color: #333;
      font-weight: 600;
      margin-bottom: 10px;
      transition: .3s all ease-in-out;
      @include media-query(767px) {
          font-size: 22px;
          line-height: 30px;
      }

      @include media-query(992px) {
          font-size: 31px;
          line-height: 34px;
      }
  }

  &__blogauthor {
      font-family: Poppins;
      font-size: 13px;
      line-height: 30px;
      color: #000;

      span {
          color: #898989;
      }

      strong {
          font-weight: 600;
      }
  }

  .btn {
      text-transform: capitalize;
      padding: 16px;
      font-size: 14px;
      margin: 20px auto 0;
      width: 100%;
      max-width: 100%;

      @include media-query(767px) {
          max-width: 350px;
          font-size: 17px;
      }
  }
}

// Common Style
.bg-white {
  background-color: #fff;
}

.border-bottom-gray {
  border-bottom: 1px solid #e4e4e4;
}
.btn-auto-width{
  max-width: 100% !important;
  padding: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-query(767px) {
      padding: 16px 25px !important;
      max-width: fit-content !important;
  }
  svg{
      margin-left: 7px;
  }
}


// latest service
.latest-technologies{
  $latest-technologies : &;
  &__spacing{
      padding: 30px 0;
      @include media-query(992px) {
          padding: 60px 0;
      }  
  }
  &__heading{
      font-family: Poppins;
      font-size: 30px;
      line-height: 35px;
      color: #262E36;
      text-align: center;
      font-weight: 700;
      margin-bottom: 15px;
      @include media-query(767px) {
          font-size: 38px;
          line-height: 46px;
      }
      @include media-query(992px) {
          font-size: 48px;
          line-height: 58px;
      } 
  }
  &__navbar{
      margin-top: 45px;
      .nav{
          display: flex;
          align-items: center;
          justify-content: center;
      }
      .nav-link{
          position: relative;
          display: inline-flex;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          width: 50%;
          color: #355bbf;
          padding: 15px;
          top: 1px;
          border: none;
          border-color: none;
          text-transform: capitalize;
          border-bottom: 3px solid transparent;
          &:after{
              position: absolute;
              content: "";
              width: 1px;
              height: 47%;
              background-color: #d4e0ed;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              display: none;
              @include media-query(767px) {
                  display: block;
              }
          }
          &:last-child{
              &:after{
                  display: none;
              }
          }
          @include media-query(767px) {
              width: 25%;
          }
          @include media-query(992px) {
              width: auto;
          }
          &.active{
              border: 1px solid #e0e0e0;
              background-color: #d4e0ed;
              border-radius: 5px;
              @include media-query(992px) {
                  border-radius: 0;
                  background-color: transparent;
                  border:none;
                  border-bottom: 3px solid #355bbf;
              }
          }
      }
  }
  .tab-content{
      border-top: 1px solid #d4e0ed;
      background-color: transparent;
      padding: 30px;
  }
  .tab-pane{
      i{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 70px;
      }
      h5{
          font-family: Poppins;
          font-size: 22px;
          line-height: 32px;
          color: #262e36;
      }
      p{
          font-family: Poppins;
          font-size: 18px;
          line-height: 32px;
          color: #000;
          margin: 0;
      }

  }
  .btn{
      text-transform: capitalize;
      margin:30px auto 0;
      font-size: 17px;
      padding: 16px;
      width: 100%;
      @include media-query(992px) {
          width: 100%;
      }
  }
  .tittle-bar-area{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      i{
          margin-right: 15px;
      }
  }
  
}
.btn-center-block{
  width: 100%;
  @include media-query(767px) {
      width: 310px;
  }
}
.btn_wrapper.btn-center-block{
  width: 100%;
}

.o-technologies-service{
  .experience-list{
      li{
          position: relative;
          padding: 2px;
          overflow: hidden;
          transform: translateZ(0);
          border: none;
          border-radius: 10px;
          &:before{
              display: block;
              content: "";
              position: absolute;
              top: -140px;
              right: 0;
              bottom: 0;
              left: -50px;
              background: #d6e3f0;
              width: 450px;
              height: 450px;
              -webkit-transition: .5s all ease-in-out;
              transition: .5s all ease-in-out;
          }
          &:hover{
              &:before{
                  animation: rotation2 3s linear infinite;
                  background: linear-gradient(180deg, #88deb1 0, #1ab864 20%, rgba(35, 116, 212, .62) 52%, rgba(6, 87, 181, .62) 100%);
              }
          }
          a{
              border-radius: 10px;
              background-color: #fff;
              &:before{
                  display: none;
              }
          }
      }
  }
}

.common-btn-root {
    .btn-auto {
        max-width: inherit;
        width: auto;
        padding: 16px 25px;
        flex: 0 0 auto;
        &--process {
            width: 306px;
        }
        &--expertise {
            width: 352px;
        }
    }
}

.we-serve {
    $we-serve: &;
    &__subtitle {
        font-family: Poppins;
        color: #333;
        text-align: center;
        font-weight: 500;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 30px;
            margin-bottom: 30px;
        }
    }
}



.dev-discuss {
    text-align: center;
    background: url(/images/lets-discuss/custom-software-discuss-bg.jpg) no-repeat center center / cover;
    .btn-orange {
        margin: 30px auto 0;
    }
    p {
        font-size: 21px;
        line-height: 32px;
        color: #DBE4FB;
        font-weight: 500;
    }
    .o-discuss-project__title {
        font-weight: 600;
        @include media-query(992px) {
            font-size: 35px;
        }
    }
}

.custom-dev-process {
    background: #F9F9F9;
    .o-skill-flow__title {
        font-weight: 500 !important;
        margin-bottom: 20px;
    }
    .o-development-process__subtitle {
        font-weight: 400;
    }
    .btn-orange {
        @include media-query(992px) {
            margin-top: 50px;
        }
    }
    .o-development-process__items {
        box-shadow: 0 15px 20px 0 rgba(12,40,72,.07);
        background: #fff;
        border: 1px solid #D0E3F5;
        margin-top: 50px;
        @include media-query(768px) {
            min-height: 220px;
        }
        @include media-query(992px) {
            margin: 70px 10px 0;
            padding: 0 30px 15px;
        }
        @include media-query(1300px) {
            width: 32%;
        }
    }
    .o-development-process__itemcontent {
        font-size: 16px;
        line-height: 24px;
    }
    .o-development-process__number-color1 {
        background: rgba(100, 185, 244, 0.1);
        strong {
            background: #64B9F4;
        }
    }
    .o-development-process__number-color2 {
        background: rgba(32, 222, 165, 0.1);
        strong {
            background: #20DEA5;
        }
    }
    .o-development-process__number-color3 {
        background: rgba(253, 164, 116, 0.1);
        strong {
            background: #FDA474;
        }
    }
    .o-development-process__number-color4 {
        background: rgba(161, 95, 174, 0.1);
        strong {
            background: #A15FAE;
        }
    }
    .o-development-process__items {
        border-radius: 5px;
        border: 1px solid #D0E3F5;
        @include media-query(992px) {
            margin: 100px 30px 0;
        }
        @include media-query(1300px) {
            width: 29.7%;
        }
        &:after {
            background-image: url(/images/new-skill-section/custom-software/step-arrow.svg);
            @include media-query(992px) {
                right: -51px;
                width: 41px;
            }
        }
        &:nth-child(3n+3) {
            &:after {
                @include media-query(992px) {
                    height: 66px;
                    width: 676px;
                    right: 50%;
                    background-image: url(/images/new-skill-section/custom-software/development-process-arrow.svg);
                    bottom: -85px;
                }
                @include media-query(1181px) {
                    width: 836px;
                }
            }
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 60px;
        }
    }
    .o-development-process__listmultiple{
        .o-development-process__items{
            &:nth-child(6){
                 &:after{
                    @include media-query(767px) {
                        width: 506px;
                    }
                    @include media-query(1181px) {
                        width: 636px;
                    }
                  }
            }
        }
    }
    .o-development-process__itemtitle {
        color: #0C2848;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 27px;
        }
    }
    .o-development-process__itemcontent {
        line-height: 27px;
    }
    .o-development-process__titlearea {
        margin-bottom: 15px;
    }
    .o-development-process__number {
        margin-bottom: 25px;
    }
}

.custom-dev-we-develop {
    border-bottom: 1px solid #DDDADA;
    border-top: 1px solid #DDDADA;
    background: #F9F9F9;
    .o-we-develop__box {
        position: relative;
    }
    .o-we-develop__title {
        font-weight: 500 !important;
    }
    .o-we-develop__subtitle {
        font-weight: 400;
        @include media-query(992px) {
            margin-bottom: 60px;
        }
    }
    .o-we-develop__boxtitle {
        font-weight: 600;
        color: #0C2848;
        @include media-query(992px) {
            line-height: 27px;
            margin-bottom: 15px;
        }
    }
    .o-we-develop__boxcontent {
        color: #0C2848;
        @include media-query(992px) {
            line-height: 27px;
        }
    }
}

.o-we-develop--hire-salesforce {
    .o-we-develop__title {
        @include media-query(992px) {
            font-weight: 700 !important;
        }
    }
    .o-we-develop__subtitle {
        @include media-query(992px) {
            font-weight: 500;
        }
    }
    .btn-wrapper-v2--pair {
        .btn-v2 {
            @include media-query(992px) {
                font-size: 17px;
            }
        }
    }
}

.custom-dev-related {
    .o-releted-service__gradient {
        background: #D0E3F5;
        padding: 0;
        border: 1px solid #D0E3F5;
        overflow: visible;
        height: 75px;
        box-shadow: 0 10px 15px 0 rgba(12,40,72,.06);
        &:after {
            display: none;
        }
    }
    .o-releted-service__link {
        background: #fff;
        height: 100%;
    }
    .o-releted-service__heading {
        font-weight: 500;
    }
}

.custom-dev-method {
    .o-we-develop__title {
        font-weight: 500 !important;
    }
    .custom-solution__sub-heading {
        font-weight: 400;
    }
}
.mwc-banner {
    // background: url(../../../images/mwc/mwc-banner-graphic.jpg) no-repeat center center / cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-align: left;
    padding-bottom: 50px !important;
    &.o-skills-hero {
        @include media-query(992px) {
            padding-bottom: 60px !important;
            padding-top: 115px !important;
        }
    }
    .header_left_pan {
        text-align: left;
        @include media-query(992px) {
            text-align: left;
        }
        .mwc-logo {
            width: 133px;
            @include media-query(992px) {
                width: auto;
            }
        }
        p {
            display: block !important;
        }
    }
    h1 {
        font-size: 24px;
        line-height: 34px;
        margin-top: 15px;
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 42px;
            line-height: 52px;
            margin-top: 20px;
            margin-bottom: 15px;
        }
        .spcl-text {
            color: #36E079;
            @include media-query(992px) {
                color: #3CF0A9;
                display: block;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 26px;
        @include media-query(992px) {
            font-size: 22px;
            line-height: 33px;
            font-weight: 400;
            padding-right: 30px;
        }
    }
    .banner-label-shape {
        position: relative;
        margin-bottom: 20px;
        pointer-events: none;
        @include media-query(992px) {
            margin: 35px 0 25px;
        }
        &-text {
            position: absolute;
            top: 13px;
            left: 0;
            padding-left: 15px;
            @include media-query(360px) {
                top: 11px;
            }
            @include media-query(992px) {
                padding-left: 20px;
            }
            span {
                font-size: 19px;
                font-weight: 700;
                @include media-query(360px) {
                    font-size: 22px;
                }
                @include media-query(992px) {
                    font-size: 24px;
                }
                &.white {
                    display: block;
                    padding-bottom: 23px;
                    @include media-query(360px) {
                        padding-bottom: 20px; 
                    }
                    @include media-query(992px) {
                        margin-right: 50px;
                        display: inline;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
    .btn-orange {
        @include media-query(992px) {
            margin: 0;
        }
    }
}

.page-engagement-specific {
    padding: 50px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    .heading-top {
        h2 {
            font-size: 24px;
            line-height: 35px;
            @include media-query(992px) {
                font-size: 36px;
                line-height: 50px;
                font-weight: 700;
                margin-bottom: 40px;
            }
            &.not-convinced-h2 {
                margin-bottom: 5px;
            }
        }
        p {
            font-size: 22px;
            line-height: 30px;
        }
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 4px;
        }
    }
    .iot-case-wrapper-box {
        text-align: left;
        display: flex;
        align-items: center;
        border-bottom-width: 1px;
        @include media-query(992px) {
            padding: 35px 30px 30px 30px;
            display: block;
        }
        i {
            display: block;
            width: 58px;
            @include media-query(992px) {
                height: 60px;
            }
        }
        h3 {
            font-size: 20px;
            margin-top: 0;
            margin-left: 20px;
            margin-bottom: 0;
            @include media-query(992px) {
                font-size: 24px;
                margin-top: 20px;
                margin-left: 0;
                margin-bottom: 10px;
                font-weight: 700;
            }
        }
        p {
            display: none;
            @include media-query(992px) {
                font-size: 16px;
                line-height: 27px;
                margin-bottom: 0;
                display: block;
            }
        }
    }
}

.mid-banner-sec1 {
    // background: url(../../../images/mwc/mid-banner1.png) no-repeat 0 0 / cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 50px 0;
    @include media-query(992px) {
        padding: 80px 0;
    }
    h2 {
        text-align: center;
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 0;
        @include media-query(992px) {
            font-size: 36px;
            line-height: 44px;
        }
        span {
            color: #3CF0A9;
        }
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        color: #fff;
        margin-top: 15px;
        @include media-query(992px) {
            margin: 30px 0 40px;
            font-size: 22px;
            line-height: 30px;
        }
    }
}

.not-convinced {
    .heading-top {
        margin-bottom: 15px;
        @include media-query(992px) {
            margin-bottom: 45px;
        }
        p {
            font-size: 18px;
            line-height: 27px;
            margin-top: 15px;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 30px;
                margin-top: 0;
                margin-bottom: 25px;
            }
        }
    }
    .iot-case-wrapper-box {
        position: relative;
        border: 1px solid #D2D2D2;
        display: block;
        padding: 30px 20px 30px 30px;
        @include media-query(992px) {
            padding: 30px 25px 30px 25px;
        }
        h3 {
            margin-left: 0;
            margin-bottom: 10px;
            word-break: break-word;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 27px;
                margin-top: 0;
            }
            font-weight: 700;
        }
        p {
            display: block;
            margin-bottom: 0;
            @include media-query(992px) {
                font-size: 14px;
                line-height: 23px;
            }
        }
        .non-btn {
            background: none;
            font-size: 14px;
            padding: 0;
            width: auto;
            display: inline;
            color: #4F8DE9;
            border: none;
            position: relative;
            margin-top: 5px;
            display: inline-block;
            &:hover {
                text-decoration: underline;
                background: none;
                border: none;
                color: darken(#4F8DE9, 10%);
            }
            &:before {
                content: '>>';
                position: absolute;
                top: 0;
                right: -20px;
            }
        }
        .numbr {
            position: absolute;
            top: -25px;
            left: 25px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            color: #fff;
            font-weight: 600;
            background: #4F8DE9;
            @include media-query(992px) {
                left: 25px;
            }
        }
    }
}

.mid-banner-sec2 {
    // background: url(../../../images/mwc/mid-banner2.png) no-repeat 0 0 / cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include media-query(992px) {
        padding: 90px 0 100px;
    }
    h2 {
        font-size: 24px;
        line-height: 32px;
        @include media-query(992px) {
            font-size: 36px;
            line-height: 50px;
        }
    }
    p {
        font-size: 18px;
        line-height: 27px;
        @include media-query(992px) {
            line-height: 28px;
        }
    }
}

.u-date {
    background: url(/images/common/date-icon.svg) no-repeat 0 50%, url(/images/common/bottom-arrow.svg) no-repeat 100% 50%;
    appearance: none;
    color: #3B3B3B;
}

.mwc-o-bottom-form {
    .form-left {
        padding: 30px 30px 10px;
        @include media-query(992px) {
            padding: 70px 60px 46px;
        }
        h3 {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 24px;
            line-height: 32px;
            @include media-query(992px) {
                font-size: 36px;
                line-height: 46px;
            }
        }
        p {
            font-weight: 500;
            @include media-query(992px) {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    .btwn-line {
        margin: 40px 0;
        width: 100%;
        height: 1px;
        background: #515151;
    }
    .common-input::placeholder {
        color: #3B3B3B;
        opacity: 1;
        font-weight: 400;
    }
}

.mwc {
    .top-addvertisement {
        display: none;
    }
    .o-navigation-bar-new #primaryNav {
        top: 50px;
        @include media-query(992px) {
            top: 0;
        }
    }
}

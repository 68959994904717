.free-trial-banner {
  $free-trial-banner: &;
  padding-bottom: 40px;

  @media (min-width: 767px) {
    padding-bottom: 0px;
  }
  @include media-query(992px) {
    background-image: url(/images/new-home-banner/banner-gradient.png) !important;
    background-repeat: no-repeat !important;
    background-position: 0 0 !important;
  }
  &:before {
    display: none;
  }

  &__spacing {
    @media (max-width: 767px) {
      padding-top: 80px;
    }
    @media (min-width: 992px) {
      &:after {
        content: "";
        width: 453px;
        height: 199px;
        background-image: url(/images/new-home-banner/banner-gradient2.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
      }
    }
  }
  .col-60 {
    width: 100%;
    max-width: 100%;
    @include media-query(992px) {
      flex: 0 0 auto;
      width: 60%;
    }
  }
  .col-40 {
    width: 100%;
    max-width: 100%;
    @include media-query(992px) {
      flex: 0 0 auto;
      width: 40%;
    }
  }
  &__heading {
    font-size: 30px;
    line-height: 42px;
    color: $white-color;
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
    @media (min-width: 992px) {
      text-align: left;
    }
    @media (min-width: 1024px) {
      margin-top: 0px;
      font-size: 35px;
      line-height: 48px;
    }
    @media (min-width: 1200px) {
      font-size: 45px;
      line-height: 58px;
    }
  }
  &__two-section {
    display: flex;
    align-items: center;
    gap: 0px;
    position: relative;
    z-index: 1;
    @media (min-width: 767px) {
      justify-content: space-between;
    }
    @media (min-width: 992px) {
      left: -25px;
    }
  }
  &__two-section-image {
    min-width: 180px;
    height: auto;
    display: none;
    @media (min-width: 767px) {
      min-width: 200px;
      display: block;
    }
    @media (min-width: 991px) {
      min-width: 200px;
    }
    @media (min-width: 1024px) {
      min-width: 230px;
    }
  }
  &__two-section-subheading {
    font-size: 25px;
    line-height: 32px;
    color: #fff;
    margin: 0;
    text-align: center;
    @media (min-width: 992px) {
      text-align: left;
    }
    @media (min-width: 1024px) {
      font-size: 30px;
      line-height: 38px;
      max-width: 530px;
      width: 100%;
      min-height: 180px;
      height: auto;
      display: flex;
      align-items: center;
      background: url(/images/two-for-one/free-trial-banner-background.png)
        no-repeat center;
      background-size: 100% 100%;
      left: -20px;
      padding: 10px 10px 10px 20px;
    }
  }
  &__formarea {
    &::before {
      display: none;
      @media (min-width: 1024px) {
        display: initial;
        // background: url(/images/new-free-trial/banner-gradient.png) no-repeat bottom center !important;
        z-index: 0;
      }
    }
  }
}

.how-free-trial {
  padding: 30px 0px;
  @media (min-width: 992px) {
    padding: 60px 0px;
  }
  background-color: #f6f6f6;
  &__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px;
    @media (min-width: 767px) {
      font-size: 40px;
      line-height: 45px;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 55px;
    }
  }
  &__description {
    font-family: "Poppins";
    text-align: center;
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  &__description-br {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }
  &__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 30px;
    color: #333;
    text-align: center;
    &::after {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      background: #4492dc;
      background-size: 100%;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__card-area {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 60px;
    gap: 20px;
    @media (min-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__card {
    background-color: #fff;
    border: 1px solid #dddddd;
    padding: 20px 30px;
  }
  &__card-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 15px;
  }
  &__card-description {
    font-size: 17px;
    line-height: 27px;
    color: #333;
    font-weight: 400;
    font-family: "Poppins";
  }
}

.who-can {
  padding: 30px 0px;
  @media (min-width: 992px) {
    padding: 60px 0px;
  }
  &__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 35px;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    @media (min-width: 767px) {
      font-size: 40px;
      line-height: 45px;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 55px;
    }
  }
  &__description {
    font-family: "Poppins";
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
    @media (min-width: 767px) {
      font-size: 21px;
      line-height: 30px;
    }
  }
  &__description-br {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }
  &__card-area {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
    @media (min-width: 767px) {
      gap: 40px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__big-card {
    position: relative;
    background-color: #fff;
    padding: 30px 25px;
    box-shadow: 0 0 15px 0 #e3e3e3;
    &--green {
      border-top: 6px solid #3cc065;
    }
    &--red {
      border-top: 6px solid #ff4949;
    }
  }
  &__two-section {
    display: flex;
    gap: 20px;
  }
  &__two-section-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  &__two-section-heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    margin-bottom: 24px;
    @media (min-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  &__list-li {
    font-size: 16px;
    line-height: 22px;
    margin-left: 35px;
    margin-bottom: 22px;
    font-family: "Poppins";
  }
  &__list-icon {
    font-size: 16px;
    line-height: 23px;
    margin-left: 25px;
  }
  &__list-icon {
    position: absolute;
    left: 0px;
  }
  &__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    text-align: center;
    // text-transform: capitalize;
  }
  &__subheading-br {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }
}

.section-invite {
  background-color: #355bbf;
  padding: 30px 0px;
  @media (min-width: 992px) {
    padding: 60px 0px;
  }
  &__heading {
    font-family: "Poppins";
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
    @media (min-width: 767px) {
      font-size: 41px;
      line-height: 51px;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 56px;
    }
  }
  &__subheading {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    // text-transform: capitalize;
    margin-bottom: 40px;
    @media (min-width: 767px) {
      font-size: 25px;
      line-height: 34px;
      padding-right: 80px;
    }
  }
  &__list-area {
    margin-right: 0px;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    // margin-left: -30px;
    margin-bottom: 10px;
    @media (min-width: 767px) {
      display: flex;
      gap: 0px;
    }
    @include media-query(1025px) {
      //margin-right: 80px;
    }
  }
  &__asterisktext{
    margin: 0 0 40px;
    color: #fff;
    line-height: normal;
  }
  &__list-item {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 0 0 48%;
    @media (min-width: 767px) {
      padding-left: 30px;
      padding-right: 15px;
      border-right: 1px solid #5b81e1;
      border-bottom: 1px solid #5b81e1;
      flex: 0 0 33%;
    }
    @media (min-width: 992px) {
      // flex: 1;
      flex: 0 0 25%;
    }
    &:after{
      display: block;
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #355bbf;
      bottom: -7.5px;
      right: -7.5px;
      z-index: 1;
    }
    &:first-child {
      @media (min-width: 992px) {
        padding-left: 0;
      }
    }
    &:nth-child(5) {
      @media (min-width: 992px) {
        padding-left: 0;
      }
    }
    &:nth-child(9) {
      @media (min-width: 992px) {
        padding-left: 0;
      }
    }
    &:nth-child(3n+3) {
      @media (min-width: 767px) {
        border-right: none;
      }
      @media (min-width: 992px) {
        border-right: 1px solid #5b81e1;
      }
    }
    &:nth-child(4n+4) {
      @media (min-width: 992px) {
        border-right: none;
      }
    }
    &:nth-last-child(-n+2){
      @media (min-width: 767px) {
        border-bottom: 0px;
      }
    }
    &:nth-last-child(-n+3){
      @media (min-width: 767px) {
        border-bottom: 0px;
      }
    }
    &:nth-last-child(-n+4){
      @media (min-width: 992px) {
        border-bottom: 0px;
      }
    }
    &:last-child{
      border-bottom: 0px;
    }
  }
  &__item {
    display: flex;
    gap: 15px;
    justify-content: center;
    @include media-query(992px) {
      justify-content: flex-start;
    }
  }
  &__item-icon {
    flex: 0 0 auto;
  }
  .owl-stage-outer {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 1px;
      height: 82%;
      background: #5b81e1;
    }
  }
  &__item-title {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    color: $white-color;
    margin-bottom: 0px;
    @media (min-width: 767px) {
      font-size: 27px;
      line-height: 27px;
    }
    span {
      font-size: 14px;
      line-height: 18px;
      display: block;
      @media (min-width: 767px) {
        margin-top: 6px;
      }
    }
  }
}

.terms-condition {
  background-color: #f7f7f7;
  padding: 30px 0px;
  @media (min-width: 767px) {
    padding: 60px 0px;
  }
  &__heading {
    font-family: "Poppins";
    font-size: 35px;
    line-height: 35px;
    color: #333;
    text-align: center;
    @media (min-width: 767px) {
      font-size: 51px;
      line-height: 35px;
    }
  }
  &__subheading {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  &__card {
    position: relative;
    background-color: #fff;
    padding: 20px;
    padding-top: 40px;
    display: block;
    align-items: initial;
    margin-bottom: 30px;
    box-shadow: 0 0 15px 0 #e3e3e3;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 767px) {
      padding-top: 0px;
      padding: 20px;
      align-items: center;
      display: flex;
      gap: 15px;
    }
  }
  &__card-count {
    font-family: "Poppins";
    background-color: #fff;
    width: 49px;
    height: 49px;
    border-radius: 50px;
    border: 1px solid #3cc065;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    @media (max-width: 767px) {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__card-description {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-bottom: 0px;
    text-align: center;
    @media (min-width: 767px) {
      text-align: initial;
    }
  }
}

.demand-technologies {
  padding: 30px 0px;
  @media (min-width: 992px) {
    padding: 60px 0px;
  }
  &__heading {
    font-family: "Poppins";
    font-size: 31px;
    line-height: 40px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
    @media (min-width: 767px) {
      font-size: 41px;
      line-height: 45px;
      margin-bottom: 40px;
    }
    @media (min-width: 1024px) {
      font-size: 51px;
      line-height: 55px;
    }
  }
  &__area {
    margin-bottom: 50px;
  }
  &__list {
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    @media (min-width: 767px) {
      display: flex;
      margin-bottom: 0px;
    }
  }
  &__tech-title {
    font-family: "Poppins";
    text-align: center;
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-top: 30px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    @media (min-width: 767px) {
      padding: 0;
      border-radius: 0px;
      max-width: 160px;
      margin-top: 0px;
      width: 266px;
      padding-bottom: 15px;
      text-align: initial;
    }
    &--blue {
      border: 2px solid #4492dc;
      @media (min-width: 767px) {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 5px solid #4492dc;
      }
    }
    &--green {
      border: 2px solid #3cc065;
      @media (min-width: 767px) {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 5px solid #3cc065;
      }
    }
  }
  &__tech-lists {
    border-bottom: none;
    flex-grow: 1;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    @media (min-width: 767px) {
      padding: 0;
      background: none;
      border-radius: 0;
      border-bottom: 1px solid #e1e1e1;
    }
  }
  &__tech-ul {
    display: flex;
    gap: 15px;
    padding-top: 5px;
    padding-top: 8px;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 767px) {
      padding-top: 5px;
      flex-wrap: nowrap;
      justify-content: initial;
      gap: 0;
    }
    @media (min-width: 992px) {
      gap: 15px;
    }
  }
  &__tech-list {
    font-family: "Poppins";
    border-right: 1px solid #e1e1e1;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 600;
    @media (min-width: 767px) {
      &:first-child {
        padding-left: 20px;
      }
    }
    &:last-child {
      border: none;
    }
  }
  &__subtitle {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  &__subtitle-br {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }
}

  //NEW BANNER AREA
  .new-clean-banner{
    $new-banner-area : &;
    position: relative;
    background: #052C58;
    width: 100%;
    overflow: hidden;
    padding: 120px 0px 40px;
    @media(min-width:767px){
      padding: 150px 0px 60px;
    }
    &:before{
      position: absolute;
      content: "";
      width: 410px;
      height: 245px;
      background: url(/images/two-for-one/banner-top-gradient.png) no-repeat top left;
      background-size: 100%;
      top: 65px;
      left: 0;
    }
    &__heading{
      font-size: 31px;
      line-height: 41px;
      margin-bottom: 12px;
      color: $white-color;
      margin-bottom: 20px;
      text-align: center;
      font-family: 'Poppins' !important;
      @include media-query(992px) {
        text-align: left;
      }
      @media(min-width:1024px){
        font-size: 41px;
        line-height: 51px;
      }
      @media(min-width:1200px){
        font-size: 51px;
        line-height: 62px;
      }
    }
    &__heading-span{
      color: $green-color;
    }
    &__subheading{
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      color: $white-color;
      margin-right: 0px;
      text-transform: capitalize;
      margin-bottom: 20px;
      text-align: center;
      font-family: 'Poppins' !important;
      @media(min-width:767px){
        margin-right: 140px;
        margin-bottom: 30px;
      }
      @include media-query(992px) {
        text-align: left;
      }
      @media(min-width:1200px){
        font-size: 30px;
        line-height: 40px;
      }
    }
    &__cta-area{
      display: initial;
      @media(min-width:767px){
        gap: 15px;
        display: flex;
      }
      @media(min-width:1024px){
        gap: 0px;
      }
    }
    &__bnner-image{
      margin: 0 auto 20px;
      display: block;
      @media(min-width:767px){
        margin: 20px auto 0;
      }
      @media(min-width:1024px){
        margin: 0 auto;
      }
    }
  }
  
  .highest-rated{
    $highest-rated:&;
    background-color: #081C36;
    padding: 30px 0px 20px;
    @media(min-width:1024px){
      padding: 40px 0px;
    }
    &__content-area{
      display: block;
      align-items: center;
      justify-content: center;
      @media(min-width:1024px){
        display: flex;
      }
    }
    &__bracket{
      display: none;
      @media(min-width:1024px){
        display: block;
      }
    }
    &__heading{
      font-size: 24px;
      color: $white-color;
      line-height: 31px;
      font-weight: 700;
      margin-right: 25px;
      margin-bottom: 20px;
      text-align: center;
      font-family: 'Poppins' !important;
      @media(min-width:1024px){
        margin-bottom: 0px;
        text-align: initial;
      }
    }
    &__heading-br{
      display: none;
      @media(min-width:1024px){
        display: block;
      }
    }
    &__image-area{
      display: flex;
      gap: 25px;
      justify-content: center;
      @media(min-width:1024px){
        gap: 15px;
        justify-content: initial;
      }
    }
    &__ratings{
      width: auto;
      height: 60px;
      @media(min-width:1024px){
        height: 80px;
      }
    }
  }
  
  
  .earning-commission{
    $earning-commission:&;
    padding: 30px 0px;
    background-color: #F9F9F9;
    position: relative;
    @include media-query(992px){
      padding: 60px 0px;
    }
    // &:before{
    //   position: absolute;
    //   content: "";
    //   width: 1100px;
    //   height: 1100px;
    //   background: url(/images/new-referral/earning-commission-bg.png) no-repeat top left;
    //   background-size: 100%;
    //   top: 65px;
    //   right: 0;
    // }
    &__heading{
      font-size: 31px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 20px;
      font-family: 'Poppins' !important;
      @media(min-width:767px){
        font-size: 41px;
        line-height: 55px;
        margin-bottom: 35px;
      }
      @media(min-width:1024px){
        font-size: 51px;
        line-height: 65px;
      }
    }
    &__heading-br{
      display: none;
      @media(min-width:1024px){
        display: block;
      }
    }
    &__heading-span{
      color: $font-blue;
    }
    &__card-list{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      margin-top: 20px;
      @media(min-width:767px){
        grid-template-columns: repeat(2, 1fr);
      }
      @media(min-width:1024px){
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  .commission-card{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: all .5s ease-in-out;
    padding: 20px 15px;
    @include media-query(992px){
      padding: 40px 24px;
    }
    &__icon-area{
      margin-bottom: 15px;
    }
    &__icon{
      width: 50px;
      &--lg{
        width: 50px;
        @media(min-width:767px){
          width: 70px;
        }
      }
    }
    &__heading{
      font-size: 21px;
      line-height: 28px;
      font-weight: 600;
      font-family: 'Poppins' !important;
    }
    &__heading-lg{
      font-family: 'Poppins' !important;
      font-size: 21px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 15px;
      @media(min-width:767px){
        font-size: 34px;
        line-height: 31px;
        font-weight: 700;
      }
    }
    &__description-lg{
      font-family: 'Poppins' !important;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 0;
      @media(min-width:992px){
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 25px;
      }
    }
    &__description{
      font-family: 'Poppins' !important;
      font-size: 15px;
      line-height: 24px;
    }
    &--lg{
      padding: 20px 15px;
      @include media-query(992px){
        padding: 40px 68px;
      }
    }
    @media(min-width:1024px){
      &:hover{
        -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
        box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
      }
    }
  }
  .referral-program-top {
    height: 30px;
    @include media-query(992px){
      height: 60px;
    }
  }
  .referral-program{
    background-color: #052C58;
    padding: 40px 35px;
    margin-bottom: 40px;
    @media(min-width:767px){
      padding: 55px 30px;
    }
    @media(min-width:1024px){
      padding: 55px 48px;
    }
    @media(min-width:1200px){
      padding: 75px 68px;
    }
    &__header{
      color: #fff;
      font-size: 28px;
      line-height: 34px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 50px;
      font-family: 'Poppins' !important;
      @media(min-width:767px){
        font-size: 34px;
        line-height: 38px;
        font-weight: 700;
      }
    }
    &__steps-area{
      display: block;
      @media(min-width:767px){
        display: flex;
      }
    }
    &__card{
      background-color: #fff;
      padding: 40px 20px 30px;
      text-align: center;
      flex-grow: 1;
      position: relative;
      margin-bottom: 1px;
      @media(min-width:992px){
        margin-bottom: 0px;
        padding: 40px 20px 20px;
        &:after{
          position: absolute;
          content: "";
          width: 31px;
          height: 108px;
          background: url(/images/new-referral/arrow-right.svg) no-repeat top left;
          background-size: 100%;
          top: 25px;
          right: -15px;
          z-index: 1;
        }
        &:last-child::after{
          display: none;
        }
      }
    }
    &__step-count{
      width:50px;
      height: 50px;
      border-radius: 50px;
      background-color: #052C58;
      position: absolute;
      top: -25px;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      @include media-query(992px){
        left: 42%;
        transform: none;
      }
    }
    &__circle{
      width: 40px;
      height: 40px;
      border-radius: 50px;
      border: 2px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
    }
    &__card-icon{
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
    }
    &__subtitle{
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      font-family: 'Poppins' !important;
      @media(min-width:767px){
        font-size: 20px;
        line-height: 31px;
      }
    }
  }
  
  .referral-reasons{
    padding: 30px 0px;
    @include media-query(992px){
      padding: 60px 0px;
    }
    &__heading{
      font-size: 31px;
      line-height: 38px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
      font-family: 'Poppins' !important;
      @include media-query(992px){
        font-size: 41px;
        line-height: 55px;
        margin-bottom: 40px;
      }
      @media(min-width:1024px){
        font-size: 51px;
        line-height: 65px;
      }
    }
    &__heading-br{
      display: none;
      @media(min-width:1200px){
        display: block;
      }
    }
    &__card-area{
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      margin-bottom: 40px;
      @media(min-width:767px){
        gap: 30px;
      }
      @media(min-width:1024px){
        gap: 40px;
      }
    }
    &__card{
      flex-basis: 200px;
      border: 1px solid #898989;
      padding: 15px;
      flex-grow: 1;
      @media(min-width:991px){
        flex-basis: 250px;
        min-height: 320px;
        padding: 20px;
      }
      @media(min-width:1024px){
        flex-basis: 340px;
        flex-grow: 0;
        padding: 30px;
      }
    }
    &__card-icon{
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 15px;
    }
    &__card-subtitle{
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
      font-family: 'Poppins' !important;
      @media(min-width:991px){
        font-size: 24px;
        line-height: 31px;
      }
    }
    &__card-description{
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 0px;
    }
  }
  .ready-join {
    $ready-join: &;
    padding: 30px 0;
    background: url(/images/new-referral/ready-to-join-bg.jpg) no-repeat center center / cover;
    @include media-query(992px) {
        padding: 60px 0;
    }
    h2 {
        font-family: 'Poppins';
        font-size: 30px;
        line-height: 44px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        @include media-query(992px) {
            font-size: 51px;
            line-height: 60px;
            margin-bottom: 30px;
            text-align: left;
        }
    }
    p {
        font-family: 'Poppins';
        color: #fff;
        font-size: 19px;
        line-height: 30px;
        font-weight: 600;
        text-align: center;
        @include media-query(992px) {
            text-align: left;
            font-size: 25px;
            line-height: 36px;
        }
    }

    &__btn-wrapper {
        text-align: center;
        @include media-query(992px) {
            display: flex;
            align-items: center;
            text-align: inherit;
        }
    }
    &__btn {
        font-family: 'Poppins';
        display: flex;
        background: #FF5800;
        border: 2px solid #FF5800;
        color: #fff;
        padding: 16px 40px;
        border-radius: 5px;
        font-size: 17px;
        font-weight: 600;
        align-items: center;
        text-align: center;
        justify-content: center;
        transition: .3s all ease-in-out;
        svg {
            transition: .3s all ease-in-out;
            margin-left: 15px;
        }
        &:hover {
            background: #fff;
            color: #FF5800;
            svg {
                transform: translateX(15px);
            }
            path {
                fill: #FF5800;
            }
        }
    }
    &__link {
        font-family: 'Poppins';
        margin-top: 20px;
        font-size: 17px;
        font-weight: 400;
        color: #fff;
        text-decoration: underline;
        display: inline-block;
        &:hover{
            color: #00b54a;
        }
        @include media-query(992px) {
            margin-top: 0;
            margin-left: 25px;
            display: inherit;
        }
    }
}

.referral-p {
    .nw-client {
        background-color: #F9F9F9;
        border-top: 1px solid #D6D6D6;
        border-bottom: 1px solid #D6D6D6;
    }
}
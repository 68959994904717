.legacy-software-blue-banner{
    p{
        &.legacy-software-blue-banner-text{
            max-width: 100%;
        }
    }
    .legacy-software-new-btn{
        max-width: 386px;
        @include media-query(992px){
            max-width: 46%;
        }
    }
    
}
.d-trans-pro-content-box{
    &.d-trans-pro-content-legal-spacing-new{
        margin-top: 0;
        @include media-query(992px){
            margin-top: 100px;
        }
    }
    &.d-trans-pro-content-legal-spacing-new-modify{
        margin-top: 0;
        @include media-query(992px){
            margin-top: 70px;
        }
    }
}
.legal-software-banner-cta-box{
    margin-bottom: 30px;
    @include media-query(992px){
        margin-bottom: 65px;
    }
}
.profile-our-story-text-content{
    h2{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        @include media-query(992px){
            text-align: left;
            margin-top: 0;
        }
    }
    p{
        text-align: center;
        @include media-query(992px){
            text-align: left;
        }
    }
}
.legacy-software-case-study-modify{
    .legacy-software-case-study-cta{
        margin-top: 60px;
        @include media-query(992px){
            margin-top: 0;
        }
    }
}
.d-trans-pro-wrapper-new{
    margin-bottom: 0;
    @include media-query(992px){
        margin-bottom: 50px;
    }
    &.d-trans-pro-wrapper-new-modify-cl{
        @include media-query(992px){
            margin-bottom: 0;
        }
    }
}

.other-ser-box-img-modify{
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-top-20{
    margin-top: 20px;
}
.position-up{
    position: relative;
    z-index: 99;
}
.new-service-section{
    &-new-modify{
        padding: 40px 0 10px;
        @include media-query(992px){
            padding: 70px 0;
        }
    }
}
.template-bnr {
  padding-top: 80px;
  @include responsive(desktop){
    padding-top: 100px;
  }
    background: rgb(13,173,129);
    background: -moz-linear-gradient(108deg, rgba(13,173,129,1) 0%, rgba(0,83,133,1) 30%, rgba(1,27,64,1) 65%);
    background: -webkit-linear-gradient(108deg, rgba(13,173,129,1) 0%, rgba(0,83,133,1) 30%, rgba(1,27,64,1) 65%);
    background: linear-gradient(108deg, rgba(13,173,129,1) 0%, rgba(0,83,133,1) 30%, rgba(1,27,64,1) 65%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0dad81",endColorstr="#011b40",GradientType=1);
    h1 {
        color: #fff;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        @include responsive(desktop){
            font-size: 62px;
            line-height: 75px;
            padding: 52px 0;
        }
    }
}

.logo-thumb {
  padding-top: 45px;
    @include responsive(desktop){
        padding-top: 75px;
        padding-bottom: 45px;
    }
    a{
        // background: #fff;
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 75%;
        transform: translate(-50%, 0);
        background-image: url(/images/home/home-arrow.png);
        width: 22px;
        height: 22px;
      }
      &:hover{
        transform: scale(1.05);
        box-shadow: 0px 0px 35px rgba(0,0,0,0.3);
          &:before{
            background-image: url(/images/home/home-orange-arrow.png);
          }
      }
    }
    &_service{
        a{
          &:before{
            top: 80%;
          }
      }
    }
  }
  .logo-thumb2, .logo-thumb3, .logo-thumb4, .logo-thumb5 {
    @include responsive(desktop){
      padding-top: 20px;
      padding-bottom: 45px;
    }
  }
  .logo-thumb5{
    padding-bottom: 25px;
    @include responsive(desktop){
      padding-bottom: 60px;
    }
  }
.box-bg_service {
    height: 250px;
    display: inline-block;
    width: 100%;
    box-shadow: 0px 0px 35px rgba(102, 99, 99, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
    h6 {
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -70%);
        width: 70%;
        font-size: 20px;
        line-height: 28px;
    }
    img {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -30%);
    }
}

.contact-new {
    $contact-new: &;

    background: url(/images/new-contact/mobile-bg.jpg)no-repeat center center / cover;
    @include media-query(992px) {
        background: url(/images/new-contact/banner-bg.jpg)no-repeat center center / cover;
    }
    overflow: visible;
    padding: 85px 0 0!important;
    height: 860px;
    @include media-query(992px) {
        padding: 120px 20px 0!important;
        height: auto;
    }
    .o-contact-us {
        padding-bottom: 0;
    }
    .o-contact-us-new {
        h1 {
            font-family: 'Poppins';
            font-size: 31px !important;
            line-height: 38px !important;
            font-weight: 700;
            position: relative;
            margin-bottom: 30px;
            text-align: center;
            @include media-query(992px) {
                font-size: 40px !important;
                line-height: 46px !important;
                text-align: left;
                padding-bottom: 30px;
            }
            &::before {
                content: '';
                background: #fff;
                position: absolute;
                top: inherit;
                bottom: 0;
                left: 0;
                width: 83px;
                height: 7px;
                display: none;
                @include media-query(992px) {
                    display: block;
                }
            }
            span {
                color: #3CC065;
            }
        }
        h3 {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            margin-bottom: 30px;
        }
    }
    &__banner-logo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 30px 0;
    }
    &__logo-img {
        flex: 0 0 50%;
    }
    .o-contact-form__contact-form {
        padding: 0 15px 20px!important;
        @include media-query(992px) {
            padding: 20px 40px 40px!important;
        }
        input {
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            background-image: none;
            padding: 17px 15px;
            margin-bottom: 20px;
            width: 100%;
            &[type=number] {
                -moz-appearance: textfield;
                -webkit-appearance: none;
                appearance: none; 
            }
            &::-webkit-outer-spin-button{
                -webkit-appearance: none;
                margin: 0;
            }
            &::-webkit-inner-spin-button{
                -webkit-appearance: none;
                margin: 0;
            }
            &#phoneNumber {
                background-image: none;
            }
            &::placeholder {
                font-size: 16px;
                font-weight: 400 !important;
                color: #333 !important;
            }
        }
        textarea {
            border: 1px solid #CFCFCF;
            border-radius: 3px;
            background-image: none;
            padding: 15px;
            width: 100%;
            height: 110px;
            &::placeholder {
                font-size: 16px;
                font-weight: 400 !important;
                color: #333 !important;
            }
        }
        .u-message {
            border: 0;
        }
        h2 {
            font-family: 'Poppins';
            font-size: 31px !important;
            font-weight: 600;
            color: #262E36;
            text-align: left !important;
            display: block !important;
            @include media-query(992px) {
                font-size: 51px !important;
            }
        }
        .confidential-text-wrap {
            @include media-query(992px) {
                display: flex;
                align-items: center;
            }
        }
        .u-submit.s-btn-modifier {
            font-family: 'Poppins';
            max-width: 255px;
            font-size: 17px;
            font-weight: 600;
            text-transform: capitalize;
            padding: 16px 0;
            &:hover{
                background-color: $white-color;
                color: #ff5800;
                svg{
                    path{
                        fill: #ff5800;
                    }
                }
            }
        }
    }
    &__col-left {
        @include media-query(992px){
            flex: 1;
        }
    }
    &__col-right {
        @include media-query(992px){
            width: 61%;
        }
    }
    .o-contact-form__attachement-text {
        font-family: 'Poppins';
        font-size: 15px;
        color: #333;
    }
    .attach {
        background-position: 0 0;
    }
    .u-message {
        margin-bottom: 20px;
        span#requirements_feedback {
            right: 40px;
        }
    }
    .confidential-text-wrap__inverse {
        ul {
            flex: 1;
            justify-content: flex-start;
        }
        li {
            font-family: 'Poppins';
            font-size: 16px;
            color: #333;
            &:first-child {
                padding-left: 0;
            }
            img {
                width: 15px;
                height: 15px;
            }
        }
    }
    .o-contact-form {
        overflow: visible;
    }
    .o-contact-us-new {
        .cn-form {
            overflow: visible;
            border-radius: 0;
            padding: 20px 0 15px!important;
            position: relative;
            top: -10px;
            border: none;
            @include media-query(992px){
                position: static;
                padding: 20px 0 65px!important;
                border-bottom: 1px solid #ebebeb;
            }
            &:after {
                background: url(/images/new-contact/same-day-response-badge.svg) no-repeat 0 0;
                top: -44px;
                right: 20px;
                bottom: inherit;
                left: inherit;
                width: 186px;
                height: 170px;
            }
        }
    }
    &__more {
        font-family: 'Poppins';
        margin-top: 15px;
        font-size: 16px;
        font-weight: 300;
        color: #FFFFFF;
        display: inline-block;
    }
    .contact-require-message {
        position: absolute;
        bottom: 0;
        left: 12px;
    }
    .u-message {
        .contact-require-message {
            left: 0;
            bottom: -14px;
        }
    }
}

.contact-meets {
    position: relative;
    border-bottom: 1px solid #CBCBCB;
    @include media-query(992px){
    padding-bottom: 50px;
    }
    &:before {
        display: none;
        @include media-query(992px){
            display: block;
            content: '';
            position: absolute;
            background: #355BBF;
            top: 0;
            right: 0;
            bottom: 0;
            width: 58%;
            height: 100%;
        }
    }
    .excellence-meets__tittle {
        p {
            font-family: 'Poppins';
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
            color: #333 !important;
        }
    }
    h3 {
        font-family: 'Poppins';
        color: #fff;
        font-size: 31px;
        font-weight: 700;
        @include media-query(992px){
            font-size: 51px;
        }
    }
    &__wrapper {
        position: relative;
        @include media-query(992px){
            display: flex;
        }
    }
    &__left {
        flex: 0 0 40%;
        padding-right: 0;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 20px;
        @include media-query(992px){
            padding-right: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            background: #6385DA;
            width: 100%;
            height: 1px;
            @include media-query(992px){
                content: '';
                position: absolute;
                left: inherit;
                right: 0;
                top: 0;
                bottom: inherit;
                background: #6385DA;
                width: 1px;
                height: 100%;
            }
        }
    }
    &__left-card{
        padding-right: 30px;
        &--gurgaon {
            margin-top: 20px;
            border-top: 1px solid #6385da;
            padding-top: 20px;
        }
    }
    &__left-top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            font-family: 'Poppins';
            font-size: 25px;
            color: #fff;
            font-weight: 600;
            margin-left: 15px;
            display: inline-block;
        }
    }
    &__right {
        
    }
    &__rightbox{
        p{
            margin-bottom: 7px !important;
        }
        @include media-query(992px){
            padding-left: 50px;
            padding-right: 10px;
        }
        &:first-child,
        &:nth-child(2){
            margin-bottom: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid #6385da;
            @include media-query(992px){
                margin-bottom: 18px;
                padding-bottom: 20px;
            }
        }
    }
    &__right-top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
            font-family: 'Poppins';
            font-size: 25px;
            color: #fff;
            font-weight: 600;
            margin-left: 15px;
            display: inline-block;
        }
    }
    .owl-dots {
        padding: 0px 0 20px;
        height: 30px;
        margin: 0;
    }
    .contact-location-spacing-new {
        background: #355BBF;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 45px;
        @include media-query(992px){
            background: none;
            padding-left: 60px;
            padding-top: 45px;
            padding-bottom: 0;
            margin-top: 0;
        }
        h3 {
            margin-bottom: 20px;
            font-weight: 600;
            @include media-query(992px){
                margin-bottom: 35px;
                font-size: 42px;
                line-height: 50px;
            }
        }
        p{
            font-family: 'Poppins';
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        a {
            font-family: 'Poppins';
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            text-decoration: underline;
        }
        h4 {
            font-family: 'Poppins';
            color: #fff;
            font-size: 21px;
        }
    }
    .col-lg-5 {
        padding-right: 15px;
        @include media-query(992px){
            padding-right: 50px;
            padding-top: 30px;
        }
        @include media-query(1600px){
            padding-right: 65px;
        }
    }
    .excellence-meets__clientlist {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-count: inherit;
        column-gap: inherit;
    }
    .excellence-meets__clientitem {
        display: inherit;
        flex: 0 0 28%;
        @include media-query(992px){
            margin-bottom: 30px;
            margin-right: 13px;
        }
    }
}

.team-fortune {
    background: none !important;
    padding: 40px 0 50px;
    @include media-query(992px){
        padding: 60px 0;
    }
    h2 {
        font-family: 'Poppins';
        font-size: 25px;
        line-height: 38px;
        font-weight: 600;
        color: #262E36;
        text-align: left;
        position: relative;
        text-transform: none !important;
        padding-top: 80px;
        margin-bottom: 50px;
        @include media-query(992px){
            font-size: 36px;
            line-height: 50px;
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @include media-query(992px){
            gap: 0;
        }
        li {
            display: flex;
            padding: 10px;
            flex: 0 0 48%;
            position: relative;
            @include media-query(992px){
                border-bottom: 1px solid #DBDFE7;
                border-right: 1px solid #DBDFE7;
                padding: 25px 15px ;
                flex: 0 0 25%;
            }
            @include media-query(1140px){
                flex: 0 0 20%;
            }
            &:nth-child(4n+4) {
                @include media-query(992px){
                    border-right: 0;
                }
                @include media-query(1140px){
                    border-right: 1px solid #DBDFE7;
                }
            }
            &:nth-child(5n+5) {
                @include media-query(1140px){
                    border-right: 0;
                }
            }
            &:nth-child(6) {
                //padding-top: 25px;
                .ywg__info {
                    span {
                        font-family: 'Poppins';
                        font-size: 24px;
                        line-height: 27px;
                    }
                }
            }
            &:nth-child(7) {
                //padding-top: 28px;
                .ywg__info {
                    span {
                        font-family: 'Poppins';
                        font-size: 24px;
                        line-height: 27px;
                    }
                }
            }
            &:nth-last-child(-n+4){
                @include media-query(1140px){
                    border-bottom: none;
                }
            }
            &:nth-last-child(-n+3){
                @include media-query(1140px){
                    border-bottom: none;
                }
            }
            &:nth-last-child(-n+2){
                @include media-query(992px){
                    border-bottom: none;
                }
            }
            &:last-child {
                flex: 1;
                //padding-top: 28px;
                border-right: 0;
                border-bottom: 0;
                .ywg__info {
                    span {
                        font-family: 'Poppins';
                        font-size: 24px;
                        line-height: 27px;
                    }
                }
            }
            &:before {
                @include media-query(992px){
                    content: '';
                    position: absolute;
                    bottom: -8px;
                    right: -17px;
                    background: #fff;
                    width: 32px;
                    height: 15px;
                    z-index: 9;
                }
            }
            i {
                flex: 0 0 auto;
                @include media-query(992px){

                }
            }
        }
        .ywg__info {
            margin-left: 5px;
            @include media-query(992px){
                margin-left: 15px;
            }
            span {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 23px;
                line-height: 18px;
                @include media-query(992px){
                    font-size: 26px;
                }
            } 
            small {
                font-family: 'Poppins';
                font-size: 13px;
                line-height: 16px;
                display: block;
                font-weight: 500;
                @include media-query(992px){
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
    .owl-dots {
        margin-bottom: 0;
        .owl-dot {
            &.active {
                background: #FF5800;
            }
        }
    }
    .owl-stage-outer {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            top: 0;
            background: #DBDFE7;
            height: 100%;
            width: 1px;
        }
    }
    .splide__track{
        &::before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            top: 0;
            background: #DBDFE7;
            height: 100%;
            width: 1px;
        } 
    }
    .splide__pagination{
        gap: 0;
        li{
            display:inline-block;
            padding:0;
            flex: 0 0 auto !important;
        }
    }
    &__asterisktext{
        margin: 0;
        line-height: normal;
    }
}

.email-info {
    $email-info: &;

    padding: 315px 0 50px;
    position: relative;
    border-bottom: 1px solid #CBCBCB;
    @include media-query(992px){
        padding: 60px 0;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #E0E0E0;
        height: 1px;
        width: 88%;
        margin: 0 auto;
        display: block;
        @include media-query(992px){
            display: none;
        }
    }
    &__wrapper {
        text-align: center;
        @include media-query(992px){
            display: flex;
            max-width: 960px;
            margin: 0 auto;
            text-align: left;
        }
    }
    &__left {
        margin-bottom: 25px;
        @include media-query(992px){
            margin: 0;
        }
        span {
            font-family: 'Poppins';
            font-size: 16px;
            line-height: 27px;
            color: #333;
        }
        a {
            font-family: 'Poppins';
            display: block;
            font-size: 20px;
            color: #4492DC;
            font-weight: 700;
            text-decoration: underline;
            @include media-query(992px){
                font-size: 25px;
            }
        }
    }
    &__right {
        @include media-query(992px){
            padding-left: 180px;
        }
        span {
            font-family: 'Poppins';
            font-size: 16px;
            line-height: 27px;
            color: #333;
        }
        a {
            font-family: 'Poppins';
            display: block;
            font-size: 20px;
            color: #4492DC;
            font-weight: 700;
            text-decoration: underline;
            @include media-query(992px){
                font-size: 25px;
            }
        }
    }
}

.office-inside {
    position: relative;
    margin-top: 20px;
    padding: 0 15px;
    @include media-query(992px){
        position: absolute;
        right: 0;
        bottom: 0;
        width: 58%;
        margin-top: 0;
        padding: 0;
    }
    &-images {
        height: 142px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    } 
}

.contact-dropdown {
    position: relative;
    margin-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
    &.open {
        .dropdown-label {
            background: #f5f5f5;
        }
    }
  
    .dropdown-list {
      padding: 25px 15px;
      background: #F5F5F5;
      position: absolute;
      top: 58px;
      left: 0;
      right: 0;
      border: 1px solid #CFCFCF;
      display: none;
      z-index: 10;
      @include media-query(992px){
        padding: 25px 20px 5px;
      }
    }
    
    .checkbox {
      opacity: 0;
      transition: opacity .2s;
    }
    
    .dropdown-label {
        font-family: 'Poppins';
        display: block;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
        color: #333;
        border: 1px solid #CFCFCF;
        padding: 0 40px 0 20px;
        cursor: pointer;
        position: relative;
        background: #ffffff;
        border-radius: 3px;
      
      &:before {
        content: '';
        background: url(/images/new-contact/bottom-arrow.svg) no-repeat 0 0;
        width: 15px;
        height: 8px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform .25s;
        transform-origin: center center;
      }
    }
    &.open {
      .dropdown-list {
        display: block;
        @include media-query(992px){
            display: flex;
            flex-wrap: wrap;
        }
      }
      .checkbox {
        transition: 2s opacity 2s;
        opacity: 1;
      }
      .dropdown-label:before {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }
  
  .contact-checkbox {
    margin-bottom: 20px;
    @include media-query(992px){
        margin-bottom: 17px;
        flex: 0 0 45%;
        width: 45%;
    }
    &:nth-child(odd) {
        @include media-query(992px){
            flex: auto;
        }
    }
    &:last-child {  
      margin-bottom: 0;
    }
    
    .checkbox-custom {
      display: none;
    }
  
    .checkbox-custom-label {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }
  
    .checkbox-custom + .checkbox-custom-label:before {
      content: '';
      background: transparent;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      text-align: center;
      width: 16px;
      height: 16px;
      border: 1px solid #B6B2B2;
      border-radius: 3px;
      margin-top: -2px;
    }
  
    .checkbox-custom:checked + .checkbox-custom-label:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        height: 12px;
        padding: 2px;
        transform: rotate(36deg);
        text-align: center;
        border: 1px solid #000;
        border-width: 0px 2px 2px 0;
        width: 6px;
    }
    .checkbox-custom-label {
    font-family: 'Poppins';
      line-height: 15px;
      font-size: 15px;
      margin-right: 0;
      margin-left: 0;
      color: #333;
      @include media-query(992px){
        font-size: 16px;
      }
    }
  }
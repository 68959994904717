.o-blogBttn {
  text-decoration: none;
  background: none;
  background-color: transparent;
  padding: 10px 35px;
  display: block;
  color: $black-color;
  position: relative;
  width: 90%;
  font-size: 12px;
  border:none;
  border-top: 1px solid $black-color;
  border-bottom: 1px solid $black-color;

  &.o-submitBttn{
    display: inline-block;
    margin: 0 auto;
    width: auto;
  }

  &:hover{
    color: $hover-color;
  }
}

.draw::before{
  left: 50%;
  top: -2px;
  transition-duration: 0.4s;
}
.draw::after{
  left: 50%;
  bottom: -2px;
  transition-duration: 0.4s;
}
.draw:hover::before, .draw:hover::after{
  left: 0;
}

.draw::before, .draw::after{
  content:"";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: $hover-color;
}

.draw:hover::before, .draw:hover::after{
  width: 100%;
}

.btn{
  font-size: 16px;
  font-weight: bold;
  max-width: 350px;
  padding: 12px 0px;
  display: block;
  width: 275px;
  text-transform: uppercase;
  @include responsive(desktop){
    display: block;
    text-align: center;
    border-radius: 5px;
    padding: 20px;
    font-size: 18px;
    // max-width: 350px;
    max-width: 300px;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    margin: 0 auto;
    position:relative;
    text-align: center;
    width: auto;
  }
  &-orange{
    color: #fff;
    border: 2px solid $orange-color;
    background-color: $orange-color;
    position: relative;
    margin: 0 auto;
    &:after {
      position: relative;
      content: '';
      background: url(/images/common/arrow-white.png) no-repeat;
      width: 20px;
      height: 14px;
      display: inline-block;
      margin-left: 15px;
      top: 50%;
      transform: translatey(-50%);
      position: absolute;
      display: none;
      // right: 30px;
      // @include responsive(desktop){
      //   // top: 25px;
      //   // right: 20px;
      //   top: 50%;
      //   transform: translatey(-50%);
      // }
    }
    // &:after {
    //   position: relative;
    //   content: '';
    //   background: url(../images/common/arrow-white.png) no-repeat;
    //   width: 20px;
    //   height: 14px;
    //   display: inline-block;
    //   margin-left: 15px;
    //   top: 2px;
      // right: 30px;
      // @include responsive(desktop){
        // top: 25px;
        // right: 20px;
    //     top: 0;
    //   }
    // }
    &:hover{
      background-color: $white-color;
      color: $orange-color;
      &:after {
        background: url(/images/common/arrow-orange.png) no-repeat;
      }
    }
  }
  &:focus {
    box-shadow: none;
  }
}
.home-fi {
  .btn {
    width: 270px;
    @include responsive(desktop){
      width: 350px;
    }
  }
}

a.black-btn{
    display: none;
    @include responsive(desktop){
        width: 134px;
        height: 46px;
        line-height: 42px!important;
        border: 2px solid #333333;
        border-radius: 5px;
        font-size: 18px!important;
        color: #333!important;
        text-decoration: none;
        font-weight: 400!important;
        transition: .3s all ease-in;
        display: block;
        background: transparent;
        padding: 0;
        text-transform: capitalize;
        text-decoration: none!important;
        &:after {
            display: none;
        }
        &:hover {
            border: 2px solid $orange-color;
            color: $orange-color!important;
        }
    }
}
.btn_wrapper.btn2 {
    position: relative;
    z-index: 1;
}

// .btn-orange{
//   padding-left: 10px;
// }

.masonry .brick a.service-box-link{
  border: 0!important;
  color: #4a90e2!important;
  line-height: 20px!important;
  height: auto!important;
}
.btn--transparent{
  background:transparent;
}
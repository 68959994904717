.why-us-page.big-data {
    padding-top: 88px;
    @include responsive(desktop){
        padding-top: 80px;
    }
}
.why-us{
    &__why-us-blocks{
        text-align: left;
        position: relative;
        padding-left: 80px;
        // max-height: 300px;
        @include responsive(desktop){
            padding-left: 85px;
            text-align: left;
            max-height: inherit;
        }
        h3 {
            font-size: 20px;
            @include responsive(desktop){
                font-size: 26px;
            }
        }
        p{
            font-size: 16px;
            line-height: 24px;
            font-family: $main-font;
            @include responsive(desktop){
                font-size: 19px;
                line-height: 32px;
                font-family: $main-font;
            }
        }
        &:before {
            top: 5px;
            @include responsive(desktop){
                top: 0;
            }
        }
    }
}
.skill-why {
    h2 {
        // font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 61px;
        }
    }
}

.php-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/php/php-sprite.png");
            background-repeat: no-repeat;
        }
}

.php-why-01{
    &:before{
        background-position: 0 0;
    }
}
.php-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.php-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.php-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.php-why-05{
    &:before{
        background-position: -340px 0;
    }
}
.php-why-06{
    &:before{
        background-position: -430px 0;
    }
}

.laravel-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/laravel/laravel-sprite.png");
            background-repeat: no-repeat;
        }
}

.laravel-why-01{
    &:before{
        background-position: 0 0;
    }
}
.laravel-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.laravel-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.laravel-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.laravel-why-05{
    &:before{
        background-position: -340px 0;
    }
}
.laravel-why-06{
    &:before{
        background-position: -430px 0;
    }
}

.codeigniter-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/codeigniter/codeigniter-sprite.png");
            background-repeat: no-repeat;
        }
}

.codeigniter-why-01{
    &:before{
        background-position: 0 0;
    }
}
.codeigniter-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.codeigniter-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.codeigniter-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.codeigniter-why-05{
    &:before{
        background-position: -340px 0;
    }
}
.codeigniter-why-06{
    &:before{
        background-position: -430px 0;
    }
}

.symfony-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/symfony/symfony-sprite.png");
            background-repeat: no-repeat;
        }
}

.symfony-why-01{
    &:before{
        background-position: 0 0;
    }
}
.symfony-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.symfony-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.symfony-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.symfony-why-05{
    &:before{
        background-position: -340px 0;
    }
}
.symfony-why-06{
    &:before{
        background-position: -430px 0;
    }
}

.python-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 63px;
            background-image: url("/images/python/python-sprite.png");
            background-repeat: no-repeat;
        }
}

.python-why-01{
    &:before{
        background-position: 0 0;
    }
}
.python-why-02{
    &:before{
        background-position: -66px 0;
    }
}
.python-why-03{
    &:before{
        background-position: -145px 0;
    }
}
.python-why-04{
    &:before{
        background-position: -218px 0;
    }
}
.python-why-05{
    &:before{
        background-position: -290px 0;
    }
}
.python-why-06{
    &:before{
        background-position: -360px 0;
    }
}

.django-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/django/django-sprite.png");
            background-repeat: no-repeat;
        }
}

.django-why-01{
    &:before{
        background-position: -335px 0;
    }
}
.django-why-02{
    &:before{
        background-position: -255px 0;
    }
}
.django-why-03{
    &:before{
        background-position: -85px 0;
    }
}
.django-why-04{
    &:before{
        background-position: -170px 0;
    }
}
.django-why-05{
    &:before{
        background-position: 0 0;
    }
}

.nodejs-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 60px;
            background-image: url("/images/node/nodejs-sprite.png");
            background-repeat: no-repeat;
        }
}

.nodejs-why-01{
    &:before{
        background-position: 0 0;
    }
}
.nodejs-why-02{
    &:before{
        background-position: -72px 0;
    }
}
.nodejs-why-03{
    &:before{
        background-position: -146px 0;
    }
}
.nodejs-why-04{
    &:before{
        background-position: -215px 0;
    }
}
.nodejs-why-05{
    &:before{
        background-position: -295px 0;
    }
}
.nodejs-why-06{
    &:before{
        background-position: -368px 0;
    }
}

.nodejs-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 60px;
            background-image: url("/images/node/nodejs-sprite.png");
            background-repeat: no-repeat;
        }
}

.nodejs-why-01{
    &:before{
        background-position: 0 0;
    }
}
.nodejs-why-02{
    &:before{
        background-position: -72px 0;
    }
}
.nodejs-why-03{
    &:before{
        background-position: -146px 0;
    }
}
.nodejs-why-04{
    &:before{
        background-position: -215px 0;
    }
}
.nodejs-why-05{
    &:before{
        background-position: -295px 0;
    }
}
.nodejs-why-06{
    &:before{
        background-position: -368px 0;
    }
}

.dotnet-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 63px;
            background-image: url("/images/wordpress/wordpress-sprite.png");
            background-repeat: no-repeat;
        }
}

.dotnet-why-01{
    &:before{
        background-position: 0 0;
    }
}
.dotnet-why-02{
    &:before{
        background-position: -75px 0;
    }
}
.dotnet-why-03{
    &:before{
        background-position: -150px 0;
    }
}
.dotnet-why-04{
    &:before{
        background-position: -225px 0;
    }
}
.dotnet-why-05{
    &:before{
        background-position: -300px 0;
    }
}
.dotnet-why-06{
    &:before{
        background-position: -375px 0;
    }
}

.wordpress-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 63px;
            background-image: url("/images/wordpress/wordpress-sprite.png");
            background-repeat: no-repeat;
        }
}

.dotnet-why-01{
    &:before{
        background-position: 0 0;
    }
}
.dotnet-why-02{
    &:before{
        background-position: -75px 0;
    }
}
.dotnet-why-03{
    &:before{
        background-position: -150px 0;
    }
}
.dotnet-why-04{
    &:before{
        background-position: -225px 0;
    }
}
.dotnet-why-05{
    &:before{
        background-position: -300px 0;
    }
}
.dotnet-why-06{
    &:before{
        background-position: -375px 0;
    }
}

.angular-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 63px;
            background-image: url("/images/angular/angularjs-sprite.png");
            background-repeat: no-repeat;
        }
}

.angular-why-01{
    &:before{
        background-position: 0 0;
    }
}
.angular-why-02{
    &:before{
        background-position: -66px 0;
    }
}
.angular-why-03{
    &:before{
        background-position: -145px 0;
    }
}
.angular-why-04{
    &:before{
        background-position: -218px 0;
    }
}
.angular-why-05{
    &:before{
        background-position: -290px 0;
    }
}
.angular-why-06{
    &:before{
        background-position: -360px 0;
    }
}

.reactjs-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/react/react-sprite.png");
            background-repeat: no-repeat;
        }
}

.reactjs-why-01{
    &:before{
        background-position: 0 0;
    }
}
.reactjs-why-05{
    &:before{
        background-position: -335px 0;
    }
}
.reactjs-why-03{
    &:before{
        background-position: -165px 0;
    }
}
.reactjs-why-04{
    &:before{
        background-position: -245px 0;
    }
}

.vuejs-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 79px;
            height: 63px;
            background-image: url("/images/vue/vuejs-sprite.png");
            background-repeat: no-repeat;
        }
}

.vuejs-why-01{
    &:before{
        background-position: 0 0;
    }
}
.vuejs-why-02{
    &:before{
        background-position: -89px 0;
    }
}
.vuejs-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.vuejs-why-04{
    &:before{
        background-position: -267px 0;
    }
}
.vuejs-why-05{
    &:before{
        background-position: -356px 0;
    }
}
.vuejs-why-06{
    &:before{
        background-position: 0 -73px;
    }
}
.vuejs-why-06{
    &:before{
        background-position: -89px -73px;
    }
}


.meanstack-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 63px;
            background-image: url("/images/mean/meanstackjs-sprite.png");
            background-repeat: no-repeat;
        }
}

.meanstack-why-01{
    &:before{
        background-position: 0 0;
    }
}
.meanstack-why-02{
    &:before{
        background-position: -73px 0;
    }
}
.meanstack-why-03{
    &:before{
        background-position: -147px 0;
    }
}
.meanstack-why-04{
    &:before{
        background-position: -220px 0;
    }
}
.meanstack-why-05{
    &:before{
        background-position: -293px 0;
    }
}
.meanstack-why-06{
    &:before{
        background-position: -220px 0;
    }
}

.mernstack-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 63px;
            height: 63px;
            background-image: url("/images/mern/mernstackjs-sprite.png");
            background-repeat: no-repeat;
        }
}

.mernstack-why-01{
    &:before{
        background-position: 0 0;
    }
}
.mernstack-why-02{
    &:before{
        background-position: -73px 0;
    }
}
.mernstack-why-03{
    &:before{
        background-position: -147px 0;
    }
}
.mernstack-why-04{
    &:before{
        background-position: -220px 0;
    }
}
.mernstack-why-05{
    &:before{
        background-position: -293px 0;
    }
}
.mernstack-why-06{
    &:before{
        background-position: -365px 0;
    }
}

.woocommerce-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 70px;
            height: 63px;
            background-image: url("/images/woocommerce/woocommerce-sprite.png");
            background-repeat: no-repeat;
        }
}

.woocommerce-why-01{
    &:before{
        background-position: 0 0;
    }
}
.woocommerce-why-02{
    &:before{
        background-position: -81px 0;
    }
}
.woocommerce-why-03{
    &:before{
        background-position: -160px 0;
    }
}
.woocommerce-why-04{
    &:before{
        background-position: -240px 0;
    }
}
.woocommerce-why-05{
    &:before{
        background-position: -322px 0;
    }
}
.woocommerce-why-06{
    &:before{
        background-position: -395px 0;
    }
}

.woocommerce-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 70px;
            height: 63px;
            background-image: url("/images/woocommerce/woocommerce-sprite.png");
            background-repeat: no-repeat;
        }
}

.woocommerce-why-01{
    &:before{
        background-position: 0 0;
    }
}
.woocommerce-why-02{
    &:before{
        background-position: -81px 0;
    }
}
.woocommerce-why-03{
    &:before{
        background-position: -160px 0;
    }
}
.woocommerce-why-04{
    &:before{
        background-position: -240px 0;
    }
}
.woocommerce-why-05{
    &:before{
        background-position: -322px 0;
    }
}
.woocommerce-why-06{
    &:before{
        background-position: -395px 0;
    }
}

.shopify-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 83px;
            height: 71px;
            background-image: url("/images/shopify/shopify-sprite.png");
            background-repeat: no-repeat;
        }
}

.shopify-why-01{
    &:before{
        background-position: 0 0;
    }
}
.shopify-why-02{
    &:before{
        background-position: -93px 0;
    }
}
.shopify-why-03{
    &:before{
        background-position: -187px 0;
    }
}
.shopify-why-04{
    &:before{
        background-position: -281px 0;
    }
}
.shopify-why-05{
    &:before{
        background-position: -375px 0;
    }
}
.shopify-why-06{
    &:before{
        background-position: -463px 0;
    }
}


.wordpress-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 63px;
            background-image: url("/images/wordpress/wordpress-sprite.png");
            background-repeat: no-repeat;
            top: 5px;
            @include responsive(desktop){
                top: 0;
            }
        }
}

.wordpress-why-01{
    &:before{
        background-position: 0 0;
    }
}
.wordpress-why-02{
    &:before{
        background-position: -75px 0;
    }
}
.wordpress-why-03{
    &:before{
        background-position: -150px 0;
    }
}
.wordpress-why-04{
    &:before{
        background-position: -225px 0;
    }
}
.wordpress-why-05{
    &:before{
        background-position: -300px 0;
    }
}
.wordpress-why-06{
    &:before{
        background-position: -375px 0;
    }
}

.drupal-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 63px;
            background-image: url("/images/drupal/drupal-sprite.png");
            background-repeat: no-repeat;
        }
}

.drupal-why-01{
    &:before{
        background-position: 0 0;
    }
}
.drupal-why-02{
    &:before{
        background-position: -148px 0;
    }
}
.drupal-why-03{
    &:before{
        background-position: -296px 0;
    }
}
.drupal-why-04{
    &:before{
        background-position: -74px 0;
    }
}
.drupal-why-05{
    &:before{
        background-position: -222px 0;
    }
}
.drupal-why-06{
    &:before{
        background-position: -370px 0;
    }
}


.ios-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 80px;
            height: 75px;
            background-image: url("/images/ios/ios-sprite.png");
            background-repeat: no-repeat;
        }
}

.ios-why-01{
    &:before{
        background-position: 0 0;
    }
}
.ios-why-02{
    &:before{
        background-position: -148px 0;
    }
}
.ios-why-03{
    &:before{
        background-position: -151px 0;
    }
}
.ios-why-04{
    &:before{
        background-position: -229px 0;
    }
}
.ios-why-05{
    &:before{
        background-position: -302px 0;
    }
}
.ios-why-06{
    &:before{
        background-position: 0 -75px;
    }
}

.android-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 75px;
            height: 67px;
            background-image: url("/images/android/android-sprite.png");
            background-repeat: no-repeat;
        }
}

.android-why-01{
    &:before{
        background-position: 0 0;
    }
}
.android-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.android-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.android-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.android-why-05{
    &:before{
        background-position: 0 -77px;
    }
}
.android-why-06{
    &:before{
        background-position: -86px -77px;
    }
}

.android-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 75px;
            height: 67px;
            background-image: url("/images/android/android-sprite.png");
            background-repeat: no-repeat;
        }
}

.android-why-01{
    &:before{
        background-position: 0 0;
    }
}
.android-why-02{
    &:before{
        background-position: -85px 0;
    }
}
.android-why-03{
    &:before{
        background-position: -170px 0;
    }
}
.android-why-04{
    &:before{
        background-position: -255px 0;
    }
}
.android-why-05{
    &:before{
        background-position: 0 -77px;
    }
}
.android-why-06{
    &:before{
        background-position: -86px -77px;
    }
}

.reactnative-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 64px;
            background-image: url("/images/react-native/react-native-sprite.png");
            background-repeat: no-repeat;
        }
}

.reactnative-why-01{
    &:before{
        background-position: 0 0;
    }
}
.reactnative-why-02{
    &:before{
        background-position: -74px 0;
    }
}
.reactnative-why-03{
    &:before{
        background-position: -148px 0;
    }
}
.reactnative-why-04{
    &:before{
        background-position: -222px 0;
    }
}
.reactnative-why-05{
    &:before{
        background-position: -297px 0;
    }
}
.reactnative-why-06{
    &:before{
        background-position: 0 -74px;
    }
}


.reactnative-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 64px;
            background-image: url("/images/react-native/react-native-sprite.png");
            background-repeat: no-repeat;
        }
}

.reactnative-why-01{
    &:before{
        background-position: 0 0;
    }
}
.reactnative-why-02{
    &:before{
        background-position: -74px 0;
    }
}
.reactnative-why-03{
    &:before{
        background-position: -148px 0;
    }
}
.reactnative-why-04{
    &:before{
        background-position: -222px 0;
    }
}
.reactnative-why-05{
    &:before{
        background-position: -297px 0;
    }
}
.reactnative-why-06{
    &:before{
        background-position: 0 -74px;
    }
}

.flutter-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 64px;
            height: 64px;
            background-image: url("/images/flutter/flutter-sprite.png");
            background-repeat: no-repeat;
        }
}

.flutter-why-01{
    &:before{
        background-position: 0 0;
    }
}
.flutter-why-02{
    &:before{
        background-position: -74px 0;
    }
}
.flutter-why-03{
    &:before{
        background-position: -148px 0;
    }
}
.flutter-why-04{
    &:before{
        background-position: -222px 0;
    }
}
.flutter-why-05{
    &:before{
        background-position: -297px 0;
    }
}
.flutter-why-06{
    &:before{
        background-position: 0 -74px;
    }
}

.ionic-blocks{
        &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 70px;
            height: 72px;
            background-image: url("/images/ionic/ionic-sprite.png");
            background-repeat: no-repeat;
        }
}

.ionic-why-01{
    &:before{
        background-position: 0 0;
    }
}
.ionic-why-02{
    &:before{
        background-position: -247px -74px;
    }
}
.ionic-why-03{
    &:before{
        background-position: -330px -74px;
    }
}
.ionic-why-04{
    &:before{
        background-position: 0 -148px;
    }
}
.ionic-why-05{
    &:before{
        background-position: -84px -148px;
    }
}
.ionic-why-06{
    &:before{
        background-position: -167px -148px;
    }
}
.ionic-why-07{
    &:before{
        background-position: -248px -147px;
    }
}

.swift-blocks{
    &:before {
        content: '';
        position: absolute;
        left: 0px;
        width: 64px;
        height: 64px;
        background-image: url("/images/swift/swift-sprite.png");
        background-repeat: no-repeat;
    }
}

.swift-why-01{
    &:before{
        background-position: 0 0;
    }
}
.swift-why-02{
    &:before{
        background-position: -66px 0;
    }
}
.swift-why-03{
    &:before{
        background-position: -149px 0;
    }
}
.swift-why-04{
    &:before{
        background-position: -222px 0;
    }
}
.swift-why-05{
    &:before{
        background-position: -296px 0;
    }
}
.swift-why-06{
    &:before{
        background-position: 0 -75px;
    }
}

.skill-service {
    padding: 40px 0 20px;
    @include responsive(desktop){
        padding: 65px 0 50px;
    }
    h2 {
        margin-bottom: 5px;
        color: #333;
        // font-weight: 700;
        font-size: 30px;
        @include responsive(desktop){
            margin-bottom: .5rem;
            color: #fff;
            // font-weight: 600;
            font-size: 48px;
        }
    }
    h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #333;
        @include responsive(desktop){
            font-size: 26px;
            line-height: inherit;
            font-weight: 600;
            color: #fff;
        }
        &.pad-bot-30 {
            padding-bottom: 25px;
            @include responsive(desktop){
                padding-bottom: 30px;
            }
        }
        br {
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
    }
    .services-pan {
        h3 {
            font-size: 20px;
            line-height: inherit;
            margin-bottom: 10px;
            font-weight: 600;
            color: #333;
            @include responsive(desktop){
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 20px;
            }
        }
        p {
            font-size: 16px !important;
            line-height: 24px !important;
            // margin-bottom: 5px;
            font-family: $main-font;
            @include responsive(desktop){
                font-size: 19px !important;
                line-height: 32px !important;
                font-family: $main-font;
            }
        }
    }
    .btn_wrapper {
        margin-top: 10px;
        @include responsive(desktop){
            margin-top: 20px;
        }
    }
    &.gradient-blue-green {
        @include responsive(desktop){
            background: linear-gradient(285deg,#07b260 0,#00517f 20%,#023e66 75%,#051b39 100%);
        }
    }
}

.magento-section-1 {
    h2 {
        padding-bottom: 10px;
        @include responsive(desktop){
            padding-bottom: 30px;
        }
    }
}


.why-skill-slider {
    .slick-arrow {
        display: none !important;
    }
}
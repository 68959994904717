.o-menu-wrap {
  position: static;
}
.o-header {
  background:#0D2642;
  position: fixed;
  z-index: 9999999;
  width: 100%;
  top: 0;
  padding: 10px 0;
  @include media-query(770px) {
    border-bottom: 1px solid #e0e0e0;
    background: $white-color;
  }
  @include media-query(992px) {
    min-height: 50px;
    z-index: 999999;
    padding: 0;
  }
  .navbar-brand {
    padding: 8px 0;
    margin-right: 0;
    font-size: 1.25rem;
    &.env-logo-padding-top-0{
      @include media-query(992px) {
        padding: 6px 0 0;
      }
    }
  }

  .navbar {
    padding: 0;
    position: static;
    ul {
      > li {
        a {
          color: $white-color;
          font-size: 14px;
          &.mobile-anchor-heading{
            font-size: 16px;
            margin-bottom: 8px;
          }
          @include media-query(1px) {
            padding-left: 20px;
          }
          @include media-query(992px) {
            padding: 0 6px;
          }
          @include media-query(1200px) {
            padding: 0 11px;
          }
          @include media-query(1280px) {
            padding: 0 13px;
          }
          font-weight: 500;
          line-height: 45px;
          display: block;
          @include media-query(992px) {
            color: $black-color;
          }
          &:hover {
            color: $white-color;
            @include media-query(992px) {
              color: $black-color;
            }
          }
          display: block;
          &.skill-brand-img{
              span{
                margin-left: 30px;
                color: #cdcdcd;
                @include media-query(992px){
                  margin-left: 0;
                }
              }
          }
        }
        &.active {
          a {
            color: $hover-color;
          }
        }
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
    .col-lg-2 {
      .menu-holder-pan5-pan {
        &:nth-child(2) {
          @include media-query(992px) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .dropdown {
    width: 100vw;
    position: absolute;
    height: 100vh;
    padding: 30px 50%;
    background: url(/images/home/banner-pattern.png) no-repeat 100% 100%;
    background-color: $black-color;
    color: #fff;
  }

  .menu-holder-pan3 {
    max-width: 1170px;
    margin: 0 auto;
    text-align: left;
  }

  .request-quote {
    color: $black-color;
    border: 2px solid $orange-color;
    display: block;
    text-align: center;
    @include border-radius(5px);
    @include media-query(992px) {
      padding: 8px 10px;
      font-size: 14px !important;
    }
    @include media-query(1200px) {
      padding: 8px 20px;
      font-size: 15px !important;
    }
    font-size: 15px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    // margin-left: 15px;
    margin-left: 0;
    text-align: left;
    display: none;
    text-transform: uppercase !important;
    @include responsive(desktop) {
      display: block;
      width: 125px;
      text-align: center;
    }
  }
  .hireus {
    // color: $white-color;
    color: #333;
    border: 2px solid $orange-color;
    // background-color: $orange-color;
    background-color: transparent;
    display: block;
    text-align: center;
    @include border-radius(5px);
    padding: 8px 3px;
    font-size: 13px;
    max-width: 320px;
    text-decoration: none;
    position: relative;
    margin-left: 15px;
    text-align: center;
    top: 2px;
    text-transform: uppercase;
      display: block;
      width: 135px;
      text-align: center;
      font-weight: 700;
      top: 0;
      margin: 0;
      background-size: 15px;
      // background-color: $orange-color;
      @include media-query(425px) {
        padding: 8px 7px;
        font-size: 13px;
      }
      @include media-query(768px) {
        margin: 0 0 0 15px;
      }
    @include media-query(992px) {
      padding: 8px 5px;
      font-size: 14px;
      background-size: 15px;
      text-transform: none;
    }
    @include media-query(1200px) {
      padding: 10px 6px 10px 6px;
      font-size: 15px;
    }
    .btn-arrow {
      margin-left: 5px;
      width: 18px;
      display: none;
      @include media-query(1200px) {
        display: inline;
      }
      path {
        fill: #333;
      }
    }
    &:hover {
      background-color: $orange-color;
      color: #fff;
      svg {
        transform: translate(5px, 0);
      }
      path {
        fill: #fff;
      }
    }
  }
  &.scrolled {
    .hireus {
      background-color: $orange-color;
      color: #fff;
      .btn-arrow {
        path {
          fill: #fff;
        }
      }
      &:hover {
        background-color: transparent;
        color: #333;
        .btn-arrow {
          path {
            fill: #333;
          }
        }
      }
    }
  }
}
#navbar {
  .collapse:not(.show) {
    right: -100%;
    display: block;
  }
}
.o-navigation-bar-new {
  padding: 0;
  #primaryNav {
    width: 100vw;
    position: fixed;
    top: 70px;
    right: 0;
    height: calc(100vh - 53px);
    overflow-y: scroll;
    background: $dark-blue-mega;
    @include transition(0.4s all);
    @include media-query(992px) {
      overflow-y: visible;
      position: static;
      padding: 0;
      text-align: right;
      width: auto;
      height: auto;
      background: transparent;
      justify-content: flex-end;
    }
    .request-quote {
      line-height: 19px;
      display: inline-block;
      background-color: #a9cf00;
      color: #fff;
      margin-top: 12px;
      border-radius: 0;
      border: 0;
      width: 125px;
      padding: 10px 20px;
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
      border: 1px solid transparent;
      font-weight: 500;
      &:hover {
        background-color: #fff;
        color: #fa5701;
        border: 0;
        border: 1px solid #ff5800;
        &:after {
          background: url(/images/common/arrow-orange.png) 50% 50% no-repeat;
          content: "";
          right: 20px;
          display: none;
        }
      }
      &:after {
        background: url(/images/common/arrow-white.png) 50% 50% no-repeat;
        right: 15px;
        display: none;
      }
    }
    > ul {
      overflow-x: hidden;
      @include media-query(992px) {
        overflow-x: visible;
      }
      > li {
        
        &::after {
          @include media-query(767px) {
            position: relative;
            content: "";
            // border-left: 10px solid transparent;
            // border-right: 10px solid transparent;
            // border-bottom: 10px solid #020a13;
            background-color: transparent;
            display: none;
            bottom: 2px;
            width: 80%;
            height: 2px;
            left: 50%;
            @include transform(translateX(-50%));
            margin-bottom: -10px;
            @include media-query(992px){
                background: #14df7d;
            }
          }
        }
        &.has-submenu {
          padding-right: 0;
          > a {
            position: relative;
            @include media-query(992px) {
              padding-right: 9px;
              padding-bottom: 9px;
            }
            @include media-query(1200px) {
              padding-right: 10px;
              padding-left: 10px;
              padding-bottom: 9px;
            }
            @include responsive(tab){
              padding-right: 10px;
              padding-bottom: 10px;
            }
            line-height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: none !important;
            @include media-query(992px) {
              background: url(/images/common/nav-down-arrow.svg) 100%
                calc(50% - 5px) no-repeat;
            }
            span {
              font-size: 28px;
              font-weight: 500;
              flex: 0 0 30px;
              height: 30px;
              width: 30px;
              color: #fff;
              @include border-radius(50%);
              border: $white-color 1px solid;
              line-height: 28px;
              text-align: center;
              margin-right: 15px;
              @include media-query(992px){
                margin-right: 0;
                color: #333;
              }
            }
            &.dropdown-toggle {
              &::after {
                display: none;
              }
            }
          }
          > span {
            position: relative;
            padding-right: 13px;
            @include media-query(992px) {
              background: url(/images/common/nav-down-arrow.svg) 100% 50%
                no-repeat;
            }
          }
        }
        &.active {
          span {
            line-height: 24px !important;
          }
          a {
            color: $white-color;
            &:hover {
              color: $white-color;
            }
          }
        }
        &:hover {
          &::after {
            display: block;
          }
          @include media-query(992px) {
            > ul {
              opacity: 1;
              visibility: visible;
              // height: 360px;
              height: auto;
              display: block;
              border: 1px solid #d8d8d8;
              &.nav-padding-new-30{
                padding: 20px 15px;
                
              }
            }
          }
        }
        > ul {
          width: 100vw;
          position: static;
          padding: 0;
          height: 0;
          display: none;
          overflow: hidden;
          background: $dark-blue-mega;
          border-radius: 0;
          @include transition(0.4s all);
          @include media-query(992px) {
            position: absolute;
            left: 0;
            top: auto;
            opacity: 1;
            display: block;
          }
          &.showNav {
            display: block;
            height: 100%;
          }
          li {
            &.has-submenu {
              padding-right: 0;
              & > a,
              & > span {
                position: relative;
                padding: 10px 0 10px 10px;
                @include media-query(992px) {
                  background: url("images/common/nav-right-arrow.svg") 90% 50%
                    no-repeat;
                }
              }

              &:hover > a,
              &:hover > span {
                background: url("images/common/nav-right-arrow.svg") 90% 50%
                  no-repeat #dadada;
                color: #333;
                border-left: 4px solid #14df7d;
              }
            }
            a {
              
              color: $white-color;
              line-height: 1.2;
              
            }
            ul {
              left: 100%;
              width: 100%;
              position: absolute;
              background: #fff;
              -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
              -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
              box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.37);
              padding: 0;
              li {
                a {
                  padding: 10px 0 10px 10px;
                  background-color: #fff;
                  background-image: none;
                  &:hover {
                    background-color: #dadada;
                    background-image: none;
                    color: #333;
                    border-left: 4px solid #14df7d;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .navbar-nav {
    padding-top: 10px;
  }
  .dropdown-menu {
    margin-top: 0;
    border: none;
    display: block;
    opacity: 0;
    height: 0;
  }
  li {
    ul {
      li {
        //border-bottom: 1px solid #2a2f46;
        font-size: 14px;
        a {
          border-left: 0;
          color: #fff;
          font-size: 14px;
          
          &:hover {
            background-color: transparent;
            color: #14df7e;
            span {
              background-color: transparent;
              color: #14df7e;
            }
          }
        }
        span {
          border-left: 0;
          color: $off-white;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
.o-navigation-bar {
  #primaryNav {
    li.has-submenu {
      > a {
        background-position: 100% 41%;
        padding-bottom: 9px;
      }
      > span {
        background-position: 100% 41%;
        padding-bottom: 9px;
      }
    }
    .menu-holder-pan5-pan {
      ul {
        &:before {
          display: none;
        }
        li {
          width: 100%;
          padding: 0 0 0 5px;
          margin: 0;
        }
      }
    }
    .menu-holder-pan2-pan {
      ul {
        &:before {
          display: none;
        }
        li {
          width: 100%;
          padding: 0 0 0 5px;
          margin: 0;
        }
      }
    }
  }
  img {
    margin-top: 15px;
    padding-top: 0;
    padding-left: 15px;
    @include media-query(992px) {
      padding-left: 0px;
    }
  }
  &.margin-top-0{
    img{
      margin-top:6px;
    }
  }
}

.menu-holder-pan3 {
  .menu-heading {
    font-size: 16px;
    color: #fff;
    line-height: 22px;
    margin-top: 10px;
    font-weight: 500;
    margin-bottom: 20px;
    position: relative;
    padding-left: 5px;
    display: block;
    white-space: nowrap;
    &:before {
      content: "";
      width: 30px;
      height: 2px;
      background-color: #14df7e;
      position: absolute;
      left: 5px;
      bottom: -6px;
    }
  }
  .menu-holder-pan5-pan {
    border-left: none;
    padding-left: 15px;
    height: inherit;
    @include media-query(992px) {
      border-left: 1px solid $border-blue;
    }
  }
  .menu-holder-pan3-pan {
    border-left: none;
    padding-left: 15px;
    height: inherit;
    @include media-query(992px) {
      border-left: 1px solid $border-blue;
    }
    li {
      width: auto;
      padding-left: 5px;
      a {
        &:hover {
          background-color: transparent;
          color: #14df7e;
          span {
            background-color: transparent;
            color: #14df7e;
          }
        }
      }
    }
  }
  .menu-holder-pan2-pan {
    border-left: none;
    padding-left: 15px;
    height: inherit;
    @include media-query(992px) {
      border-left: 1px solid $border-blue;
    }
    li {
      width: auto;
      //background: url(../images/common/nav-green-arrow.svg) no-repeat 0 12px;
      padding-left: 10px;
    }
  }
}

.navbar-toggler {
  position: relative;
  padding: 0;
  width: 26px;
  height: 22px;
  border: 0;
  margin-right: 15px;
  @include border-radius(0px);
  &:focus {
    border: none;
    box-shadow: none;
  }
  // &:before {
  //   content: "";
  //   position: absolute;
  //   background: url(../images/new-hamburger.png) no-repeat center center;
  //   width: 100%;
  //   height: 100%;
  //   right: 0;
  //   @include transform(translateY(-50%));
  //   @include media-query(768px) {
  //     display: none;
  //   }
  // }
  span {
    @media all and (max-width: 768px) {
      display: none;
    }
  }
}




#nav-icon4 {
  width: 26px;
  height: 18px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  right: -3px;
  top: -4px;
}

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  @include media-query(770px) {
    background: $black-color;
  }
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 8px;
  transform-origin: left center;
  width: 14px;
  right: 0;
  left: inherit;
}

#nav-icon4 span:nth-child(3) {
  top: 16px;
  transform-origin: left center;
  width: 20px;
  right: 0;
  left: inherit;
}

#nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 1px;
  left: 4px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 19px;
  left: 4px;
  width: 26px;
}

.mb-justyfy-center {
  align-items: center;
  @include responsive(desktop){
    align-items: inherit;
  }
}

// .video-pan {
//   background: url(../images/common/video-bg.png);
// }



.high {
  padding: 50px 0;
  @include responsive(desktop){
    padding: 80px 0 50px !important;
  }
  h2 {
    font-weight: 600;
  }
  p {
    font-family: $main-font !important;
  }
}

.web-app-service .service-wrap .services-pan a {
  width: 134px;
  height: 46px;
  line-height: 42px;
  border: 2px solid #333333;
  border-radius: 5px;
  font-size: 18px;
  line-height: 44px;
  color: #333;
  text-decoration: none;
  font-weight: 400;
  transition: .3s all ease-in;
  background: none;
  padding: 0;
  text-transform: capitalize;
  //   @include responsive(desktop){
  //   position: absolute;
  //   bottom: 40px;
  //   left: 50%;
  //   transform: translate(-50%, 0);
  // }
  &:after {
    display: none;
  }
  &:hover {
    border: 2px solid #FF5800;
    color: #FF5800;
  }
}

.home-build {
  padding: 50px 0 20px;
  @include responsive(desktop){
    padding: 80px 0 50px !important;
  }
}

.web-app-service-mod h2{
  margin-bottom: 30px;
}





.navbar-expand-md {

  @include media-query(768px) {

    .navbar-collapse {
      display: none !important;  // stylelint-disable-line declaration-no-important


      // Changes flex-bases to auto because of an IE10 bug
      flex-basis: auto;
      &.show {
        display: block !important;
      }
    }

    .navbar-toggler {
      display: block;
    }

  }
    @include media-query(992px) {

        .navbar-collapse {
          display: flex !important;  // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none;
        }

      }
    }

    .navbar-expand-md .navbar-nav {
      @include media-query(768px) {
        flex-direction: column;
      }
      @include media-query(992px) {
        flex-direction: row;
      }
  }


  a.hiring {
    display: none;
    @include media-query(992px) {
      padding: 3px 3px;
      margin-left: 5px;
      flex: 0 0 95px;
      font-size: 12px;
      margin-top: 6px;
      background-color: #4B8FE2;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      position: relative;
      display: block;
    }
    @include media-query(1280px) {
      flex: auto;
      padding: 3px 7px;
      font-size: 13px;
      display: block;
    }
}

span.hiring-arrow {
    position: absolute;
    left: -6px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-width: 10px 10px 0 0;
    border-color: #4b8fe2 transparent transparent transparent;
    border-style: solid;
    transform: rotate(180deg);
}
.o-header {
  .navbar {
      ul>{
          li{
              a{
                  font-size: 14px;
                  @include media-query(992px){
                      font-size: 13px;
                  }
                  @include media-query(1200px){
                    font-size: 14px;
                }
              }
          }
      }
  }
}

.contact-book-btn:disabled
{
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important;
}
.bottomSendButton:disabled
{
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important;
}
.contactFormTrigger:disabled
{
  pointer-events: none;
  filter: grayscale(70%);
  color: #ccc !important;
}
.top-addvertisement-wrapper {
  background: #3CC065;
}
.top-addvertisement {
  padding: 10px 15px;
  // background: #FF5800;
  // background: #3CC065;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none;
    @include media-query(992px){
      display: block;
      right:10px;
    }
    @include media-query(1280px){
      right: 30px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    background: url(/images/easter-offering/mcolor-egg2.png) no-repeat 0 0;
    width: 50px;
    height: 32px;
    top: 0;
    background-size: 75%;
    display: none;
    @include media-query(992px){
      display: block;
      left: 10px;
    }
    @include media-query(1280px){
      left: 30px;
    }
  }
  p {
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    a {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      padding: 0 18px 0 5px;
      margin-left: 5px;
      transition: .2s all ease-in-out;
      // color: darken(#3CC065, 30%);
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
        // color: darken(#3CC065, 40%);
      }
      &:after {
        content: ">>";
        position: absolute;
        top: 0px;
        right: 0;
      }
    }
  }
}
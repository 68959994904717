.btn-v2 {
    $btn-v2: &;

    max-width: 409px;
    height: 62px;
    margin: 0 auto;
    border-width: 2px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins' !important;
    font-size: 19px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
    &--transparent {
        background-color: transparent;
    }
    &--transparent-black {
        background-color: transparent;
        color: #333;
        border-color: #333;
        &:hover {
            background: #333;
            color: #fff;
            transition: .3s all ease-in-out;
                path {
                    fill: #fff;
                    transition: .3s all ease-in-out;
                }
        }
    }
    .btn-arrow {
        margin-left: 10px;
    }
}
.btn-orange {
    font-family: 'Poppins' !important;
    background: #FF5800;
    color: #fff;
    transition: .3s all ease-in-out;
    &:hover {
        background: #fff;
        color: #FF5800;
        path {
            fill: #FF5800;
        }
    }
}
.btn-wrapper-v2--pair {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .btn-v2 {
        flex: 0 0 360px;
        max-width: 360px;
        margin: 15px auto 0;
        @include media-query(770px){
            max-width: 49%;
        }
        @include media-query(992px){
            margin: 50px 5px 20px;
            flex: 0 0 370px;
            max-width: 370px;
        }
    }
    .btn-v3 {
        flex: 0 0 360px;
        max-width: 360px;
        margin: 15px auto 0;
        text-transform: capitalize;
        @include media-query(770px){
            max-width: 49%;
        }
        @include media-query(992px){
            margin: 50px 5px 20px;
            flex: 0 0 410px;
            max-width: 410px;
        }
    }
}

.cmn-btn-transition {
    transition: .3s all ease-in-out;
    svg {
        transition: .3s all ease-in-out;
    }
    path {
        transition: .3s all ease-in-out;
    }
    &:hover {
        svg {
            transform: translate(15px, 0);
        }
    }
}
.btn-arrow {
    margin-left: 10px;
}
.white-hover {
    &:hover {
        path {
            fill: #FF5800;
        }
    }
}
.btn--transparent{
    background: transparent;
}
.btn-center{
    margin:0 auto !important;
}
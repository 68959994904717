// body {
//   font-family
// }
.font-family-sour {
  font-family: $main-font;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.white-color {
  color: $white-color!important;
}
.black-color {
  color: $black!important;
}
.white-bg-color {
  background-color: $white-color;
}
.grey-bg-color {
  background-color: $grey-color;
}
.green-color {
  color: $green-color;
}
.blue-color {
  color: $blue-color!important;
}
.light-blue-color {
  color: $light-blue-color;
}
.pad-80 {
  padding: 50px 0;
  @include responsive(desktop) {
    padding: 80px 0;
  }

}
.padding-30{
  padding: 30px 0;
}
.pad-40 {
  padding: 40px 0;
}
.padding-50{
  padding: 50px 0;
}
.pad-top-0{
  padding-top: 0!important;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-top-30 {
  padding-top: 30px;
}
.pad-top-40 {
  padding-top: 40px;
}
.pad-top-50 {
  padding-top: 50px;
}
.pad-top-100 {
  padding-top: 100px;
}
.pad-top-120 {
  padding-top: 120px;
}
.pad-top-50 {
  padding-top: 50px;
}
.pad-bot-10{
  padding-bottom: 10px;
}
.pad-bot-20 {
  padding-bottom: 20px;
}
.pad-bot-30 {
  padding-bottom: 30px;
}
.pad-bot-40 {
  padding-bottom: 40px;
}
.pad-bot-50 {
  padding-bottom: 50px;
}
.pad-bot-80 {
  padding-bottom: 40px;
}
.pad-bot-150 {
  padding-bottom: 150px;
}
.padding-all-60{
  padding:60px;
}
.margin-bottom-0{
  margin-bottom: 0;
}

.center {
  text-align: center;
}
.pattern-br {
  &:after {
    background: url(/images/common/rb-pt.png) no-repeat bottom right;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    height: 82px;
  }
}
.pattern-tl {
  &:after {
    background: url(/images/common/lt-pt.png) no-repeat bottom right;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 160px;
    height: 60px;
  }
}

.pattern-tr {
  &:after {
    background: url(/images/common/rt-pt.png) no-repeat bottom right;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 82px;
  }
}

.pattern-bl {
  &:after {
    background: url(/images/common/rb-pt.png) no-repeat bottom right;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 100px;
  }
}

.anchor-link {
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: inline-block;
  // max-width: 100%;
  max-width: 325px;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: 600;
  @include responsive(desktop) {
    font-size: 14px;
  }
  &--white {
    color: #cbe3ff;
  }
}

.home-link {
  font-family: "Montserrat", sans-serif;
  color: #333;
  font-size: 16px;
  @include responsive(desktop) {
    font-size: 19px;
  }
  font-weight: 500;
  text-decoration: underline;
  display: block;
  &:hover {
    color: #000;
  }
}

.n-gradient-green-blue {
  background: -moz-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: -webkit-linear-gradient(-45deg, #037c71 0%, #042b4e 100%);
  background: linear-gradient(135deg, #037c71 0%, #042b4e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#037c71', endColorstr='#042b4e',GradientType=1 );
}
.gradient-green-blue {
    background: #00b35c;
    background: -moz-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #00b35c), color-stop(47%, #0b8a8a), color-stop(100%, #004475));
    background: -webkit-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -o-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: -ms-linear-gradient(-45deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    background: linear-gradient(135deg, #00b35c 0%, #0b8a8a 47%, #004475 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b35c', endColorstr='#004475', GradientType=1 );
}

.mobile-off {
  display: none;
  @include responsive(desktop) {
    display: block;
  }
}

.desktop-off {
  display: block;
  @include responsive(desktop) {
    display: none;
  }
}
.container {
  @include media-query(1280px) {
    max-width: 1170px;
  }
}

.u {
  &-border-color {
    &--secondary {
      border-color: $border-blue !important;
    }
  }
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.modal-close{
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    right: -15px;
    top: -15px;
    background: #fff;
    border: 1px solid #fff;
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.left{
  text-align: left!important;
}
.font-size-24{
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.padding-top-20{
  padding-top: 20px;
}

.font-size-42{
  font-size: 28px;
  line-height: 42px;
  @include responsive(desktop) {
    font-size: 42px;
    line-height: 52px;
  }
}


.font-size-20{
  font-size: 20px;
  line-height: 32px;
}

.office-infrastructure .lets-talk {
  margin-top: 0;
}
.office-infrastructure .o-skills-hero__schedule {
  padding-top: 150px!important;
}

.new-contact .modal-backdrop {
  display: none;
}


#cookiePopup {
  background: #fff;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px #ccc;
  z-index: 9999;
  text-align: center;
  display: none;
}
#cookiePopup img{
  display: inline-block;
  margin-right: 10px;
}
  #cookiePopup p{
    font-size: 13px;
    color: #000;
    width: 100%;
    margin: 0;
    font-weight: 500;
    padding-top: 20px;
    @include responsive(desktop) {
      padding-top: 0;
    }
}
#cookiePopup p a{
  text-decoration: underline;
  color: #000;
}
#cookiePopup button{
  font-weight: 600;
  border-radius: 5px;
  color: #000;
  border: 1px solid #000;
  width: 90px;
  padding: 0;
  margin: 10px auto 10px;
  display: block;
  @include responsive(desktop) {
    margin: 10px 0 10px 30px;
    display: inline-block;
  }
}
.cookie-close{
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}

.index-bnr-logo{
  margin-bottom: 40px;
}
.index-bnr-logo img{
  margin: 10px;
}
.capitalize{
  text-transform: capitalize!important;
}

.font-weight-700{
  font-weight: 700!important;
}
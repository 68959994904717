.react-js-development-process {
    .heading-para {
        font-size: 16px;
        line-height: 24px;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

.looking-service {
    padding: 15px 0 50px;
    @include media-query(992px) {
        padding: 65px 0 80px;
    }
    .heading-top {
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 40px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            @include media-query(992px) {
                font-size: 22px;
                line-height: 38px;
            }
        }
    }
    h2 {
        margin-top: 25px;
        text-align: center;
        @include media-query(992px) {
            margin-top: 0;
        }
    }
    .other-ser {
        &-box {
            padding: 35px 15px 30px 15px;
            background: #fff;
            border: 1px solid #E1DFE6;
            border-radius: 5px;
            box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
            text-align: center;
            margin-bottom: 15px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-wrap: wrap;
            min-height: 200px;
            display: block;
            position: relative;
            transition: .4s all ease-in-out;
            &:hover {
                box-shadow: 0px -3px 25px 1px rgba(0,0,0,0.20);
            }
            @include media-query(992px) {
                min-height: 300px;
            }
            &-img {
                width: 100%;
                @include media-query(992px) {
                    height: 103px;
                }
            }
            h4 {
                margin-top: 20px;
                font-size: 18px;
                @include media-query(992px) {
                    font-size: 22px;
                    line-height: 25px;
                    font-weight: 500;
                    min-height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &.ml-0 {
                margin-left: 0;
            }
            span {
                display: block;
                margin-top: 20px;
                @include media-query(992px) {
                    position: absolute;
                }
                left: 0;
                right: 0;
                bottom: 35px;
            }
        }
        &-right {
            text-align: center;
            @include media-query(992px) {
                padding-left: 50px;
                text-align: left;
            }
            @include media-query(1200px) {
                padding-left: 100px;
            }
        }
    }
    .new-btn {
        margin-top: 20px;
        @include media-query(992px) {
            width: 335px;
            margin-top: 40px;
        }
    }
}


.react-js-development-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box2,
        .d-trans-pro-content-box3,
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box5 {
            margin-top: 0;
        }
        .d-trans-pro-steps {
            &::before {
                @include media-query(992px) {
                    height: 85%;
                }
            }
        }
        @include media-query(992px) {
            margin-top: 50px;
        }
    }
    .heading-para {
        text-align: center;
        @include media-query(992px) {
            font-size: 18px;
            line-height: 27px;
            max-width: 950px;
            margin: 0 auto;
        }
    }
    h2 {
        @include media-query(992px) {
            margin-bottom: 15px;
        }
    }
}










.rj-dev-stack {
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 65px 0 30px;
    }
    &-top {
        text-align: center;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 25px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            @include media-query(992px) {
                font-size: 18px;
                line-height: 28px;
                margin: 0 auto;
                max-width: 940px;
            }
        }
    }
    .wrapper {
        border-radius: 5px;
        border: 1px solid #E1DFE6;
        box-shadow: 3px 7px 13px 4px rgba(0,0,0,0.05);
        @include media-query(992px) {
            margin: 40px auto 50px;
            max-width: 970px;
            min-height: 419px;
            background: #fff;
        }
    }
    
    a {
        text-decoration: none;
    }
    
    .accordion {
      width: 100%;
    //   overflow: hidden;
      position: relative;
      text-align: center;
      font-size: 0;
      @include media-query(992px) {
        display: flex;
      }
    }
    .top-left-b-r {
        @include media-query(992px) {
            border-top-left-radius: 5px;
            overflow: hidden;
        }
    }
    .accordion-title {
        vertical-align: top;
        background: #f6f6f6;
        position: relative;
        @include media-query(992px) {
            flex: 1;
            display: inline-block;
        }
        &:hover {
            @include media-query(992px) {
                a {
                    background: #fff;
                    &:before {
                        content: '';
                        position: absolute;
                        background: #3CC065;
                        height: 3px;
                        width: 100%;
                        top: inherit;
                        bottom: 0;
                        left: 0;
                        z-index: 9;
                    }
                }
            }
        }
    }
    .accordion-title a {
      display: block;
      padding: 15px;
      font-size: 80%;
      font-size: 19px;
      color: #333;
      background: #fff;
      font-weight: 500;
      position: relative;
      text-align: left;
      border-bottom: 1px solid #E1DFE6;
      cursor: pointer;
      @include media-query(992px) {
        min-height: 82px;
        padding: 16px 16px 8px;
        border-right: 1px solid #E1DFE6;
        background: #F6F6F6;
        border-bottom: 0;
        text-align: center;
        font-size: 15px;
        line-height: 15px;
      }
      span {
        margin: 0 5px;
        @include media-query(992px) {
            display: block;
            width: 100%;
            margin: 0;
        }
      }
        &:before {
            content: '+';
            position: absolute;
            top: 10px;
            right: 15px;
            display: block;
            font-size: 24px;
            font-weight: 500;
            color: #dbbb7c;
            @include media-query(992px) {
                display: none;
            }
        }
        &.no-border {
            border-right: 0;
        }
    }
    .accordion-title.active {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background: #3CC065;
            height: 3px;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 9;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
    }
    
    .accordion-title.active a {
      background-color: #fff;
      font-weight: 500;
      @include media-query(992px) {
        font-weight: 600;
      }
      &:before {
        content: '-';
        position: absolute;
        top: 0;
        right: 15px;
        left: inherit;
        display: block;
        font-size: 34px;
        font-weight: 500;
        color: #dbbb7c;
        width: auto;
        background: none;
            @include media-query(992px) {
                display: none;
            }
        }
    }
    
    
    .accordion-content {
        // float: right;
        width: 100%;
        margin-top: 0;
        padding: 20px 20px;
        text-align: center;
        // border-top:  1px solid grey;
        // border-bottom:  1px solid grey;;
        border-bottom: 1px solid #e1dfe6;
        background: #fff;
        @include media-query(992px) {
            padding: 45px 30px 45px 50px;
            border-top: 1px solid #e1dfe6;
            border-bottom: 0;
            margin-bottom: 0;
            position: absolute;
            top: 82px;
            left: 0;
            text-align: left;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: none;
        }
        h3 {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 15px;
            font-weight: 600;
            @include media-query(992px) {
                font-size: 26px;
                line-height: 36px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
            margin-bottom: 15px;
            @include media-query(992px) {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 25px;
            }
        }
    }

    .fs-0 {
        @include media-query(992px) {
            visibility: hidden;
        }
    }

    .accordion-content-img {
        img {
            width: 200px;
            @include media-query(992px) {
                width: inherit;
            }
        }
    }
}
    
    
    

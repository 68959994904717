.no-webp {
  .o-skills-benefits__contentholder .content-panel:before {
    background-image: url(/images/common/common-sprite.png);
  }
}
.webp {
  .o-skills-benefits__contentholder .content-panel:before {
    background-image: url(/images/webp/common/common-sprite.webp);
  }
}
.o-skills-benefits {
  .row {
    .col {
      padding-left: 15px;
      padding-right: 15px;
      // width: 100%;
      display: inline-block;
      margin-bottom: 20px;
      @include media-query(778px) {
        margin-bottom: 30px;
        flex: inherit;
      }
      @include media-query(992px) {
        flex: 0 0 auto;
      }
    }
  }

  &__contentholder {
    display: none;
    min-height: 100%;
    padding: 125px 30px 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    display: block;
    vertical-align: top;
    border: 1px solid #D9D9D9;
    position: relative;
    @include responsive(desktop){
       padding: 125px 50px 25px;
       min-height: 440px;
       border: none;
    }

    h4 {
      color: #333;
      font-weight: 600;
      padding: 0 0 25px 0;
      position: relative;
    }

    h3 {
      color: #333;
      font-weight: 600;
      padding: 0 0 5px 0;
      position: relative;
      font-size: 20px;
      line-height: inherit;
      @include responsive(desktop){
        font-size: 26px;
        padding: 0 0 25px 0;
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-family: $main-font;
      @include responsive(desktop){
        font-size: 19px;
        line-height: 32px;
        font-family: $main-font;
      }
    }

    .content-panel {
          &:before {
            content: '';
            position: absolute;
            width: 76px;
            height: 72px;
            top: 50px;
            left: 0;
            right: 0;
            margin: 0 auto;
            @include responsive(desktop){
              right: inherit;
              left: 50%;
              margin: 0;
              transform: translateX(-50%);
            }
        }
    }

    &__partners-01 {
      &:before {
        content: "";
        background-position: 0 -120px;
      }
    }
    &__partners-02 {
      &:before {
        content: "";
        background-position: -86px -120px;
      }
    }
    &__partners-03 {
      &:before {
        content: "";
        background-position: -172px -120px;
      }
    }
    &__partners-04 {
      &:before {
        content: "";
        background-position: -258px -120px;
      }
    }
  }

  /* backgrounds */
  .bottom_pattern {
    padding: 40px 0;
    background-size: 50%;
    text-align: center;
  }
  /* ~./backgrounds */

  h5 {
    color: $white-color;
    font-weight: 300;
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 30px;
  }

  &--social {
    h5 {
      font-size: 24px;
      line-height: 40px;
    }
  }
}

.skill-why {
  padding: 40px 0 20px;
  @include responsive(desktop){
    padding: 70px 0 50px;
  }
}

.o-skills-benefits {
  padding: 40px 0 20px;
  @include responsive(desktop){
    padding: 65px 0 50px;
  }
  h2 {
    font-size: 24px;
    line-height: inherit;
    padding-bottom: 20px;
    // font-weight: 700;
    @include responsive(desktop){
      font-size: 48px;
      line-height: 61px;
      padding-bottom: 30px;
    }
  }
  .btn_wrapper {
    margin-top: 15px;
    @include responsive(desktop){
      margin-top: 20px;
    }
  }
}

.skill-frame {
  padding: 45px 0 20px;
  @include responsive(desktop){
    padding: 75px 0 50px;
  }
  .experience-list {
    ul {
      li {
        a {
          height: 100%;
          display: flex;
          padding: 13px 15px;
          align-items: center;
          position: relative;
          justify-content: center;
          @include responsive(desktop){
            padding: 30px 15px;
            height: 115px;
          }
        }
      }
    }
  }
  h2 {
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 15px;
    // font-weight: 700;
    @include responsive(desktop){
      font-size: 30px;
      padding-bottom: 30px;
    }
  }
}
.prt-box-1 {
  padding-top: 145px;
  @include responsive(desktop){
      padding-top: 125px;
  }
}
.prt-box-3{
  padding-top: 145px;
  @include responsive(desktop){
      padding-top: 125px;
  }
}
.prt-box-4 {
  padding-top: 145px;
  @include responsive(desktop){
      padding-top: 125px;
  }
}
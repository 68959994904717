.navbar-toggler {
  display: block;
  @include media-query(992px) {
    display: none;
  }
}
.new-nav {
  $new-nav: &;
  &__wrapper {
    position: fixed;
    right: -100%;
    height: calc(100vh - 72px);
    top: 72px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.4s all ease;
    background: rgb(36, 205, 123);
    background: linear-gradient(
      280deg,
      rgba(36, 205, 123, 1) 0%,
      rgba(53, 95, 213, 1) 70%
    );
    padding: 15px 15px 0;
    @include media-query(992px) {
      display: block;
      position: static;
      left: inherit;
      right: inherit;
      width: auto;
      height: auto;
      transition: none;
      padding: 0;
      background: none;
      overflow: visible;
    }
    &.show {
      position: absolute;
      width: 100vw;
      right: 0;
    }
  }
  &__list {
    position: relative;
    display: block;
    @include media-query(992px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 35px;
    }
  }
  &__child-wrapper {
    display: none;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    @include media-query(992px) {
      position: absolute;
      top: 81px;
      left: 0;
      width: 334px;
      transition: 0.3s all ease;
      transform: translate(0px, 10px);
      visibility: hidden;
      opacity: 0;
      display: block;
      background: #fff;
      box-shadow: 1px 0 13px 7px rgba(0, 0, 0, 0.1);
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
    &.showNav {
      display: block;
    }
    &:before {
      @include media-query(992px) {
        content: "";
        position: absolute;
        height: 10px;
        top: -10px;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }
  &__items {
    padding: 10px 0;
    margin: 0;
    position: relative;
    @include media-query(992px) {
      margin: 0 10px;
      padding: 24px 0;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      background: #29ad95;
      width: 100%;
      height: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scale(1.2);
      transition: 0.3s all ease-in-out;
    }
    &:hover {
      .new-nav__child-wrapper {
        @include media-query(992px) {
          visibility: visible;
          opacity: 1;
          transform: translate(0);
        }
      }
      &:before {
        @include media-query(992px) {
          transform: scale(1);
          visibility: visible;
          opacity: 1;
        }
      }
      .new-nav__links {
        color: #fff;
        @include media-query(992px) {
          color: #35b15c;
        }
      }
      .new-nav__arrow {
        path {
          stroke: #35b15c;
        }
      }
    }
    &--left-gap {
      @include media-query(992px) {
        padding-left: 12px;
        margin-right: 10px;
      }
      @include media-query(1200px) {
        padding-left: 20px;
      }
      &::before {
        @include media-query(992px) {
          left: 11px;
          width: 79px;
        }
        @include media-query(1200px) {
          left: 11px;
          width: 75px;
        }
      }
      &:hover {
        &:before {
          display: none;
        }
        .new-nav__links--left-bar {
          @include media-query(992px) {
            background-size: 100% 100%;
          }
          //   &:after {
          //     animation: animate 8s linear infinite;
          //   }
        }
      }
    }
  }
  &__links {
    font-family: "Poppins";
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    transition: 0.2s all ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-query(992px) {
      color: #333;
      font-weight: 500;
      font-size: 13px;
      position: static;
      display: inherit;
    }
    @include media-query(1200px) {
      font-size: 16px;
    }
    span {
      font-size: 28px;
      font-weight: 300;
      flex: 0 0 30px;
      height: 30px;
      width: 30px;
      color: #fff;
      border-radius: 50%;
      border: #fff 1px solid;
      line-height: 30px;
      text-align: center;
      margin-right: 0;
    }
  }
  &__arrow {
    margin-left: 5px;
  }
  &__child-items {
    position: relative;
    @include media-query(992px) {
      padding: 0;
    }
    &:hover {
      @include media-query(992px) {
        background: rgb(36, 205, 123);
        background: linear-gradient(
          120deg,
          rgba(36, 205, 123, 1) 0%,
          rgba(53, 95, 213, 1) 60%
        );
      }
      .new-nav__child-links {
        color: #fff;
        &:after {
          @include media-query(992px) {
            border: none;
          }
        }
      }
      path {
        stroke: #fff;
      }
      .new-nav__child-wrapper-second {
        @include media-query(992px) {
          visibility: visible;
          opacity: 1;
          transform: translate(0);
        }
      }
    }
    svg {
      position: absolute;
      display: none;
      @include media-query(992px) {
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block;
      }
      &.before-link {
        @include media-query(992px) {
          position: relative;
          top: -1px;
          right: 0;
          transform: none;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    &:last-child {
      .new-nav__child-links {
        &:after {
          display: block;
          @include media-query(992px) {
            display: none;
          }
        }
      }
    }
    &--top-space {
      margin-top: 15px;
      @include media-query(992px) {
        margin-top: 0;
      }
    }
  }
  &__child-links {
    font-family: "Poppins" !important;
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding: 5px 0 5px;
    @include media-query(992px) {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      padding: 16px 30px;
      transition: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 13px;
      left: -11px;
      background: url(/images/common/white-right-bracket.svg) no-repeat 0 0;
      width: 6px;
      height: 10px;
      @include media-query(992px) {
        content: "";
        position: absolute;
        top: inherit;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 82%;
        height: 1px;
        margin: 0 auto;
        border-bottom: 1px dashed #d9d9d9;
        background: none;
      }
    }
    &--head {
      margin-bottom: 10px;
      font-weight: 500;
      @include media-query(992px) {
        margin-bottom: 0;
        font-weight: 600;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: #14df7d;
        width: 35px;
        height: 2px;
        display: block;
        @include media-query(992px) {
          display: none;
        }
      }
    }
  }
  &__child-wrapper-second {
    background: none;
    padding: 0;
    position: relative;
    @include media-query(992px) {
      position: absolute;
      top: 0;
      right: -303px;
      width: 303px;
      transition: 0.3s all ease;
      transform: translate(-30px, 0);
      visibility: hidden;
      opacity: 0;
      background: #355fd5;
      padding: 10px 0;
    }
    &--seperator {
      padding-bottom: 15px;
      @include media-query(992px) {
        padding: 10px 0;
        margin-bottom: 0;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: #fff;
        display: none;
        @include media-query(992px) {
          display: none;
        }
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 90%;
      background: #fff;
      display: block;
      @include media-query(992px) {
        display: none;
      }
    }
    &--tree1 {
      &:after {
        height: 78%;
      }
    } 
    &--tree2 {
      &:after {
        height: 74%;
      }
    } 
  }
  &__child-items-second {
    padding: 0 0 0 20px;
    position: relative;
    @include media-query(992px) {
      padding: 1px 15px;
    }
    &:hover {
      .new-nav__child-links-second {
        @include media-query(992px) {
          color: #fff;
          background: #26459b;
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      width: 15px;
      height: 1px;
      background: #fff;
      display: block;
      @include media-query(992px) {
        display: none;
      }
    }
  }
  &__child-links-second {
    font-family: "Poppins" !important;
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    position: relative;
    background: none;
    border-radius: 4px;
    padding: 5px 0;
    transition: 0.2s all linear;
    @include media-query(992px) {
      padding: 8px 15px;
      font-weight: 500;
    }
  }
}
.overflow-y-hidden {
  overflow-y: hidden;
}

@keyframes animate {
  0% {
    background-position: 400%;
  }
  100% {
    background-position: 0%;
  }
}

.dotnet-generic-section {
    h2 {
        margin-bottom: 15px;
    }
}

.new-expertise-section {
    padding: 40px 0 20px;
    @include media-query(992px){
        padding: 70px 0 80px;
    }
    h2 {
        text-align: center;
        margin-bottom: 30px;
        @include media-query(992px){
            argin-bottom: 60px;
        }
    }
    &-box {
        background: #fff;
        padding: 40px 20px 30px 25px;
        border-radius: 8px;
        border: 1px solid #C5C5C5;
        box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.05);
        margin-bottom: 30px;
        @include media-query(992px){
            margin-bottom: 0;
        }
        i {
            background: #F5F5F5;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90px;
            width: 90px;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
        }
        ul {
            margin-top: 25px;
            li {
                position: relative;
                padding: 0 0 10px 25px;
                font-size: 18px;
                line-height: 27px;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
}

.new-tech-stack {
    .tech-list {
        border: 1px solid #E1DFE6;
        box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.05);
    }
}
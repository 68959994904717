.tools-tech {
    padding: 45px 0 50px;
    @include media-query(992px) {
        padding: 70px 0 55px;
    }
    p {
        font-size: 18px;
    }
    .tnt-box {
        background: #fff;
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        height: 138px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        box-shadow: 0 1px 5px 1px rgba(0,0,0,0.05);
        i {
            display: block;
            height: 55px;
        }
        span{
            display: block;
            font-size: 16px;
            line-height: 27px;
            margin-top: 20px;
        }
    }
    .row {
        margin-bottom: 25px;
    }
    h2 {
        margin-bottom: 20px;
        br {
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        font-weight: 600;
    }
}

.step-by-step {
    .spiral-col-box {
        padding: 30px 15px 30px 15px;
        @include media-query(992px) {
            padding: 30px 30px 30px 35px;
        }
    }
    h4 {
        font-size: 16px;
        @include media-query(992px) {
            font-size: 24px;
        }
    }
    .btn_wrapper {
        margin-top: 25px;
        @include media-query(992px) {
            margin-top: 0;
        }
    }
    .spiral-col-box3 {
        margin-bottom: 60px;
    }
    h2 {
        font-weight: 600;
    }
}


/*New Mobile app design css*/

.tools-tech-section {
    h2 {
        font-weight: 600;
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 15px;
        }
    }
    .tech-list {
        border: 1px solid #E1DFE6;
        @include media-query(992px) {
            display: flex;
            align-items: center;
        }
        div {
            @include media-query(992px) {
                flex: 0 0 25%;
                align-self: stretch;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        ul {
            padding-bottom: 0;
            @include media-query(992px) {
                padding: 15px 0 15px 20px;
            }
            li {
                @include media-query(992px) {
                    padding: 5px 15px;
                    border: none;
                    position: relative;
                }
                &:before {
                    display: none;
                    @include media-query(992px) {
                        display: block;
                        content: '';
                        position: absolute;
                        background: #dedede;
                        height: 24px;
                        width: 1px;
                        left: 0px;
                        top: 6px;
                    }
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
                a {
                    font-weight: 500;
                    color: #4A90E2;
                    text-decoration: underline;
                    transition: .2s all ease-in-out;
                    &:hover {
                        color: darken(#4A90E2, 10%);
                    }
                }
            }
            .lg-no-left-bar {
                &:before {
                    @include media-query(1200px) {
                        display: none;
                    }
                }
            }
            .md-no-left-bar {
                &:before {
                    display: none;
                    @include media-query(1200px) {
                        display: block;
                    }
                }
            }
            .md-no-left-bar2 {
                &:before {
                    @include media-query(1089px) {
                        display: none;
                    }
                    @include media-query(1281px) {
                        display: block;
                    }
                }
            }
            .md-no-left-bar3 {
                &:before {
                    display: none;
                    @include media-query(1089px) {
                        display: block;
                    }
                }
            }
        }
        .text-brk {
            @include media-query(1200px) {
                padding-right: 15px;
            }
        }
        .text-brk-lg {
            @include media-query(992px) {
                margin-right: 15px;
            }
            @include media-query(1200px) {
                margin-right: 0;
            }
        }
        &-inside {
            flex: 1 !important;
            display: block !important;
            align-self: auto !important;
            padding-left: 20px !important;
            ul {
                padding: 0;
                display: block;
                width: 100%;
                li {
                    color: #333;
                    span {
                        display: block;
                        margin-bottom: 20px;
                        @include media-query(992px) {
                            display: inline;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .btn_wrapper {
        margin-top: 30px;
        @include media-query(992px) {
            margin-top: 50px;
        }
    }
}


.dev-process {
    h2 {
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 75px;
        }
        font-weight: 600;
    }
    ul {
        margin-bottom: 0;
        li {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 24px;
            padding-left: 25px;
            &:before {
                background: url(/images/mobile-app/c-right-arrow.svg) no-repeat center center;
                top: 2px;
            }
        }
    }
    .spiral-col-box1 {
        @include media-query(992px) {
            margin-bottom: 105px;
        }
    }
    .spiral-col-box5 {
        @include media-query(992px) {
            margin-top: 180px;
        }
    }
    .spiral-col-box6 {
        @include media-query(992px) {
            margin-top: 180px;
            margin-bottom: 60px;
        }
    }
}

.process-mobile-app {
    .spiral-col-mid {
        @include media-query(992px) {
            flex: 0 0 24%; 
        }
    }
    .spiral-col-box1 {
        @include media-query(992px) {
            margin-bottom: 50px;
            margin-top: 30px;
        }
        @include media-query(1200px) {
            margin-bottom: 155px;
        }
    }
    .spiral-col-box4 {
        @include media-query(992px) {
            margin-top: 210px;
        }
        @include media-query(1200px) {
            margin-top: 230px;
        }
    }
    .spiral-col-box5 {
        @include media-query(992px) {
            margin-top: 60px;
        }
        @include media-query(1200px) {
            margin-top: 120px;
        }
    }
    .spiral-col-box6 {
        @include media-query(992px) {
            margin-top: 60px;
        }
        @include media-query(1200px) {
            margin-top: 110px;
        }
    }
    .spiral-col-box2 {
        @include media-query(992px) {
            margin-bottom: 15px;
        }
        @include media-query(1200px) {
            margin-bottom: 70px;
        }
    }
    .spiral-col-box3 {
        @include media-query(992px) {
            margin-bottom: 30px;
        }
        @include media-query(1200px) {
            margin-bottom: 110px;
        }
    }
    .spiral-col-box7 {
        .color-7 {
            color: #71F99B;
        }
    }
}

.process-6-in-grid {
    .spiral-col-box {
        p {
            @include media-query(992px) {
                margin-bottom: 0;
            }
        }
    }
    .spiral-col-mid {
        @include media-query(992px) {
            flex: 0 0 24%; 
        }
    }
    .spiral-col-box1 {
        @include media-query(992px) {
            margin-bottom: 20px;
            margin-top: 0;
        }
        @include media-query(1200px) {
            margin-bottom: 75px;
            margin-top: 30px;
        }
    }
    .spiral-col-box4 {
        @include media-query(992px) {
            margin-top: 210px;
        }
        @include media-query(1200px) {
            margin-top: 230px;
        }
    }
    .spiral-col-box5 {
        @include media-query(992px) {
            margin-top: 40px;
        }
        @include media-query(1200px) {
            margin-top: 150px;
        }
    }
    .spiral-col-box6 {
        @include media-query(992px) {
            margin-top: 20px;
        }
        @include media-query(1200px) {
            margin-top: 120px;
        }
    }
    .spiral-col-box2 {
        @include media-query(992px) {
            margin-bottom: 45px;
        }
        @include media-query(1200px) {
            margin-bottom: 110px;
        }
    }
    .spiral-col-box3 {
        @include media-query(992px) {
            margin-bottom: 30px;
        }
        @include media-query(1200px) {
            margin-bottom: 110px;
        }
    }
}

.app-cost-section {
    padding: 50px 0 30px;
    @include media-query(992px) {
        padding: 70px 0 60px;
    }
    h2 {
        text-align: center;
        @include media-query(992px) {
            font-size: 48px;
            line-height: 61px;
        }
        font-weight: 600;
        margin-bottom: 20px;
    }
    p {
        font-size: 16px;
        @include media-query(992px) {
            font-size: 18px;
        }
        text-align: center;
        font-weight: 500;
    }
    .cost-tabs-new {
        font-family: 'Poppins';
        li {
            a {
                font-family: 'Poppins';  
            }
        }
    }
    #cost-tabs {
        display: flex;
        margin-top: 35px;
        margin-bottom: 25px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        @include media-query(992px) {
            overflow-x: inherit;
            margin-top: 50px;
            margin-bottom: 40px;
            align-items: center;
            justify-content: center;
        }
        &::-webkit-scrollbar {
            opacity: 0;
        }
        li {
            margin: 0 5px;
            a {
                border-radius: 18px;
                border: 1px solid #333333;
                text-align: center;
                padding: 5px 20px;
                font-size: 16px;
                cursor: pointer;
                color: #333;
                display: inline-block;
            }
            a.active {
                background: #27C070;
                border: 1px solid #27C070;
                color: #fff;
            }
        }
    }
    .app-cost-box {
        @include media-query(992px) {
            display: flex;
        }
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 14px 15px 1px rgba(0,0,0,0.03);
        margin-bottom: 50px;
        @include media-query(1200px) {
            max-width: 970px;
            margin: 0 auto 50px;
            padding: 15px;
        }
        &-left {
            padding: 30px 15px;
            @include media-query(992px) {
                padding: 40px 75px 30px 30px;
            }
            p {
                text-align: center;
                font-size: 16px;
                line-height: 24px;
                @include media-query(992px) {
                    text-align: left;
                    font-size: 18px;
                    line-height: 28px;
                }
                font-weight: 400;
                margin-bottom: 0;
                &:nth-child(2) {
                    margin-top: 15px;
                    @include media-query(992px) {
                        margin-top: 25px;
                    }
                }
            }
        }
        &-right {
            text-align: center;
            @include media-query(992px) {
                flex: 0 0 38%;
                max-width: 38%;
                text-align: left;
            }
            background: #FAFAFA;
            border-top: 5px solid #4A90E2;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            padding: 30px 15px;
            @include media-query(992px) {
                border-top: 0;
                border-left: 5px solid #4A90E2;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 0;
                padding: 50px 50px 60px 40px;
            }
            
            
            h3 {
                font-size: 22px;
                font-weight: 600;
                line-height: 20px;
                @include media-query(992px) {
                    font-size: 30px;
                    line-height: 28px;
                }
            }
            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                @include media-query(992px) {
                    text-align: left;
                    font-size: 18px;
                    line-height: 28px;
                }
                margin-bottom: 0;
            }
            &-top {
                margin-bottom: 30px;
                @include media-query(992px) {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
.o-terms-heading{
    padding: 140px 0 0;
    text-align: center;
    h1{
         position: relative;
         padding-bottom: 40px;
        &:before{
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            width: 100px;
            height: 8px;
            background-color: $black-color;
        }
    }

}
.o-terms{
    p{
        font-size: 16px!important;
        font-family: $main-font;
        @include responsive(desktop){
            font-size: 17px!important;
            line-height: 30px;
            font-family: $main-font;
        }
    }
}


.privacy-policy{
    font-family: $main-font;
    font-weight: 400;
    color: #414446;
    font-size: 15px;
    line-height: 24px;
    @include responsive(desktop){
      font-size: 16px;
      line-height: 28px;
    }

   & .c-ticked-list {
      margin: 0px 0px 25px 0;
        li {
            position: relative;
            display: block;
            padding: 0 0 10px 35px;
            line-height: 30px;
            font-family: $main-font;
            font-size: 19px;

            @include responsive(desktop){
                padding-bottom:15px;
                line-height: 24px;
                font-family: $main-font;
                font-size: 16px;
            }
            &:before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 5px;
                left: 0px;
                background: url("/images/common/icon-bullet-dark.svg") 50% 50% no-repeat;
                background-size: contain;

                @media (min-width: 1024px){
                top:1px;
                }
            }
        }
    }
    .common-bg-holder {
        h5, h6{
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        h5 {
          font-size: 24px;
          line-height: 32px;
        }
        h6 {
          font-size: 22px;
          line-height: 34px;
        }
        p{
            margin-bottom: 20px;
            @include responsive(desktop){
                margin-bottom: 25px;
            }
        }
    }
    
  }


  .privacy-policy-mod .common-bg-holder {
    color: #333 !important;
      span {
          font-size: 17px !important;
          line-height: 30px !important;
          color: #333 !important;
          span {
            a {
                font-size: 15px !important;
                line-height: 24px !important;
            }
          }
          a {
              span {
                font-size: 15px !important;
                line-height: 24px !important;
                
              }
          }
      }
      li {
          list-style:decimal;
          list-style-position: inside;
      }
  }

  .refund-policy {
    h2 {
        text-transform: uppercase;
        // color: #000 !important;
    }
    h3 {
        text-transform: uppercase;
        margin: 40px 0 15px;
        color: #000;
        font-size: 32px;
        &.mb-25 {
            margin-bottom: 25px;
        }
    }
    ol {
        li {
            color: #000;
            padding-bottom: 15px;
            font-size: 19px;
            line-height: 32px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    p {
        color: #000;
    }
  }
  .o-terms {
    .refund-policy {
        p {
            font-size: 20px !important;
        }
    }
  }

  .cookie-policy-h1,
  .disclaimer-h1,
  .privacy-policy-h1,
  .refund-policy-h1,
  .terms-condition-h1 {
    @media (min-width: 992px){
        font-size: 48px;
        line-height: 61px;
    }
  }
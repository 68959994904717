.dotnet-expertise{
    
    border: 1px solid #C5C5C5;
    padding: 26px 18px;
    border-radius: 5px;
    margin-bottom: 30px;
    min-height: 102px;
    @include responsive(desktop){
        min-height: 127px;
    }
    p{
        position: relative;
        margin-bottom: 0;
        font-size: 16px;
        padding-left: 27px;
       &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 5px;
            background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
            height: 16px;
            width: 16px;
       }
    }
}
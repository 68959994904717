.staff-chart{
    font-family: "Montserrat",sans-serif;
    .chart-head{
        font-size: 0;
        .chart-head-1{
            width: 100%;
            display: none;
            background-color: #71E19D;
            padding: 10px 15px;
            vertical-align: top;

            font-weight: 600;
            margin-right: 4px;
            @include responsive(desktop) {
                width: 19%;
                display: inline-block;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            &:first-child{
                background-color: transparent;
            }
            img{
                display: inline-block;
                vertical-align: top;
            }
            span{
                display: inline-block;
                @include responsive(desktop) {
                    font-size: 16px;
                    line-height: 32px;
                }
                strong{
                    display: block;
                }
            }
        }
    }

    .chart-mid{
        margin-bottom: 30px;
        @include responsive(desktop) {
            margin-bottom: 0;
        }
        .chart-mid-1{
            width: 100%;
            display: inline-block;
            background-color: #fff;
            padding: 10px 35px 10px 15px;
            vertical-align: top;
            font-size:18px;
            font-weight:600;
            border-bottom: 1px solid #ccc !important;
            margin-right: 4px;
            @include responsive(desktop) {
                width: 19%;
                min-height: 88px;
                padding: 10px 15px;
            }
            span.price{
                font-size: 15px;
                padding: 10px 0;
                display: inline-block;

                @include responsive(desktop){
                    padding: 20px 0;
                    font-size: 18px;
                }
            }
            span{
                display: block;
            }
            &:first-child{
                border-bottom: 1px solid 1px solid #ccc;
                background-color: #CBE3FF;
                font-size: 12px;
                @include responsive(desktop){
                    border-top-right-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom: 1px solid #474D5A;
                }
                // @include responsive(desktop){
                //     border-top-right-radius: 5px;
                // }
                span{
                    font-size: 16px;
                    padding-bottom: 5px;
                    @include media-query(992px) {
                        font-size: 14px;
                    }
                    @include media-query(1200px) {
                        font-size: 18px;
                    }
                }
                span.price {
                    font-size: 15px;
                    @include media-query(992px) {
                        font-size: 18px;
                    }
                }

            }
        }
        .staff-first-child{
            margin-top: 25px;
            @include responsive(desktop) {
                border-top-left-radius: 5px;
                margin-top: 0;
            }
        }
        .staff-first-child-bottom{
            @include responsive(desktop) {
                border-bottom-left-radius: 5px;
            }
        }
        .staff-last-child{
            @include responsive(desktop) {
                border-bottom-right-radius: 5px;
            }
            border-bottom: 1px solid #474D5A;
        }
        .last-line-min{
            @include responsive(desktop) {
                min-height: 100px;
            }
        }
        .chart-mid-wrapper {
            // display: none;
            @include media-query(992px) {
                display: inline;
            }
            .chart-mid-1 {
                &:first-child {
                    background: #fff;
                    border-radius: 0 !important;
                }
            }
        }
        .chart-mid-mod {
            display: flex;
            align-items: flex-start;
            padding: 15px 15px;
            @include media-query(992px) {
                display: inline-block;
                padding: 10px 15px;
            }
            .chart-mid-sub {
                margin-left: 7px;
                @include media-query(992px) {
                    margin-left: 0;
                }
                .mid-sub {
                    padding: 0 0 0;
                    @include media-query(992px) {
                        padding: 10px 0 0;
                    }
                }
                span.price {
                    padding: 0 0 0;
                    @include media-query(992px) {
                        padding: 10px 0;
                    }
                }
            }
        }
        .acco-head {
            @include media-query(992px) {
                border-top-left-radius: 0;
            }
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
    }
    .chart-mid {
        font-size: 0;
        &:nth-child(2) {
            .chart-mid-1 {
                &:nth-child(1) {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    @include responsive(desktop) {
                        border-top-right-radius: 0;
                    }
                }
            }
        }
        border-radius: 7px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @include responsive(desktop) {
            border-radius: 0;
        }
        overflow: hidden;
        .acco-head {
            position: relative;
            &:before {
                content: '\002B';
                position: absolute;
                top: 50%;
                transform: translate(-50% , 0);
                right: 5px;
                height: 100%;
                font-size: 28px;
                line-height: 0;
                color: #ff5800;
                @include responsive(desktop) {
                    display: none;
                }
            }
            &.open {
                &:before {
                    content: "\2212";
                }
            }
        }
    }



}

.Dedicated {
    .staff-chart .chart-mid .chart-mid-1 span.price {
        @include media-query(992px) {
            padding: 26px 0;
            font-size: 18px;
        }
        @include media-query(1200px) {
            padding: 20px 0;
        }
    }
}
ul.skill-page-mod{
    display: flex;
    // width: 80%;
    li{
        flex: 1;
        position: inherit!important;
        padding: 0 10px!important;
        &:before{
            display: none;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
}

.Careers ul.skill-page-mod{
    display: flex;
    // width: 80%;
    flex-wrap: wrap;
    @include media-query(992px) {
        flex-wrap: nowrap;
    }
    li{
        flex: 0 0 30% !important;
        @include media-query(992px) {
            flex: 0 0 auto !important;
            padding: 0 10px!important;
        }

        position: inherit!important;
        padding: 5px 10px!important;
        &:before{
            display: none;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
}
ul.skill-page-mod li:nth-child(3){
    flex: 0 0 80px;
}

.schedule-box{
    padding: 40px 30px 20px 30px;
}

.schedule-box-top{
    padding: 10px 15px 10px;
    background: url(/images/common/mid.png) repeat-y;
    background-position: 0 0;
    background-size: 100%;
}
.schedule-box-top .btn-center-block{
    width: 340px;
    margin: 0 auto;
    @include media-query(992px) {
        width: auto;
    }
    @include media-query(1200px) {
        width: 340px;
    }

}
.schedule-box-top .anchor-link{
    margin-top: 10px;
}
.schedule-box-top .btn-orange {
    padding: 14px 15px;
    max-width: 340px !important;
    font-weight: 700;

}

.schedule-box-top-text img{
    width: 69px;
    height: 69px;
}
.schdule_h_img {
    vertical-align: top;
    margin-right: 15px;
}
.schedule-box-top-text h2{
    text-align: left;
    font-size: 20px;
        padding-top: 0;
        font-weight: 600 !important;
        @include media-query(992px) {
            font-size: 20px !important;
            line-height: 28px;
        }
        @include media-query(1200px) {
            font-size: 24px !important;
            line-height: 35px;
        }
}
.schdule_h_text{
    font-size: 17px!important;
    line-height: 27px!important;
}

.schedule-box-top-img {
    background: url(/images/common/top.png) no-repeat;
    background-position: 0 0;
    background-size: 100%;
    height: 29px;
}
.schedule-box-bottom-img {
    background: url(/images/common/bottom.png) no-repeat;
    background-position: 0 0;
    background-size: 100%;
    height: 45px;
}
.with-cn-list{
    padding:0 20px;
    li{
        font-size: 15px;
        font-family: "Montserrat",sans-serif;
        font-weight: 600;
        line-height: 21px;
        clear: both;
        margin-bottom: 20px;
        vertical-align: top;
        display: flex;
        @include media-query(992px) {
            display: inline-block;
        }
        span{
            float: left;
            vertical-align: top;
            flex: 0 0 60px;
            margin-bottom: 30px;
            @include media-query(992px) {
                width: 55px;
            }
            img {
                width: 65%;
                @include media-query(992px) {
                    width: auto;
                }
            }
        }
    }
}
.mid-border{
    @include responsive(desktop) {
        background: url(/images/staff/mid-border.png) repeat-y 50% 50%;
    }
}

.staff-technology .box{
    width: 235px;
}

.staff-technology .box:nth-child(1){

}

.staff-technology .box__home-technology-01{
    border-bottom: 4px solid #00b05c;
}
.staff-technology .box__home-technology-02{
    border-bottom: 4px solid #00af93;
}
.staff-technology .box__home-technology-03{
    border-bottom: 4px solid #0095b5;
}
.staff-technology .box__home-technology-04{
    border-bottom: 4px solid #007fab;
}
.staff-technology .box{
   padding: 110px 10px 0;
}

.staff-technology .box__home-technology-01:before{
    background: url(/images/staff/icon-web.svg) repeat-y 50% 50%!important;
        width: 70px;
    height: 64px;
    top: 30px;

}

.staff-technology .box__home-technology-02:before{
    background: url(/images/staff/icon-software.svg) repeat-y 50% 50%!important;
        width: 70px;
    height: 64px;
    top: 30px;
}

.staff-technology .box__home-technology-03:before{
    background: url(/images/staff/icon-outsource.svg) repeat-y 50% 50%!important;
        width: 70px;
    height: 64px;
    top: 30px;
}

.staff-technology .box__home-technology-04:before{
    background: url(/images/staff/icon-qa.svg) repeat-y 50% 50%!important;
        width: 70px;
    height: 64px;
    top: 30px;
}

.gst{
    color: #CBE3FF;
    font-size: 14px;
    padding: 10px;
}
.expertise-python-section{
    border-top: 2px solid $green-color;
}
.expertise-python{
    border-bottom: 1px solid #9AA1AC;
    padding: 10px 0;
    span{
        width: 38%;
        display: inline-block;
        font-size: 18px;
        line-height: 24px;
        font-family: "Montserrat",sans-serif;
        color: #333;
        font-weight: 600;
        vertical-align: top;
        padding-top: 10px;
        @include responsive(desktop) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    .python-img-holder{
        display: inline-block;
        width: 60%;
    }
    img{
        display: inline-block;
        margin: 10px;
    }
}

.python-chart .last-line-min{
    min-height: auto!important;
}

.case-studies-slider .slick-list{
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.24);
}
.cs-slider div{
    padding-bottom: 0;
}

ul.skill-page-mod-pro {
    width: 100%;
}

.custom-software-development .service-wrap .services-pan__staff:before {
    width: 78px;
    height: 75px;
}

.custom-software-development .service-wrap .services-pan__staff-service-01:before {
    background: url(/images/staff/dt-icon-1.svg) no-repeat 50% 50%;
}


.services-pan__staff {
    padding-top: 30px;
    @include responsive(desktop) {
        padding-top: 50px;
    }
}


.sm-p-section {
    .staff-chart .chart-mid .chart-mid-1:first-child span {
        font-size: 15px;
        display: flex;
        align-items: center;
        height: 67px;
        padding-bottom: 1px;
    }
    .staff-chart .chart-mid .chart-mid-1 span.price {
        font-size: 15px;
        padding: 22px 0;
    }
}

.pack-name {
    font-size: 18px;
}
.chart-head-price {
    font-size: 20px;
}


#price-tabs {
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    @include media-query(992px) {
        display: inherit;
    }
}

#price-tabs li {
    list-style:none;
    margin-right:5px;
    margin-bottom:2px;
    border-radius:3px;
    outline:none;
    display: inline-block;
    flex: 1;
    font-size: 11px;
    @include media-query(992px) {
        font-size: 13px;
    }
    &:last-child {
        margin-right: 0;
        @include media-query(992px) {
            margin-right:5px;
        }
    }
}

#price-tabs li a {
    cursor: pointer;
    color: #333;
    font-family: "Montserrat",sans-serif;
    font-weight: 700;
    display:block;
    border:1px solid #FFF;
    color: #fff;
    border-radius:10px;
    text-decoration:none;
    outline:none;
    line-height: 24px;
    text-align: center;
    padding: 0 6px;
    @include media-query(992px) {
        padding: 0 30px;
        width: 98px;
        border-radius:20px;
        line-height: 36px;
    }
}

#price-tabs li a.inactive{
    color: #ffffff;
    background: #27C070;
    outline:none;
    position: relative;
    border:1px solid #27C070;
}
#price-tabs li a.inactive:before {
    background: url("/images/common/price-white-tick.png") no-repeat 20% 50% #27C070;
    content: "";
    position: absolute;
    left: 20%;
    top: 50%;
    z-index: 100000;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    display: none;
    @include media-query(992px) {
        display: block;
    }
}

#price-tabs li a:hover, #tabs li a.inactive:hover {
    color: #ffffff;
    background: #27C070;
    outline:none;
    border:1px solid #27C070;
}

.container1 {
  display: inline-block;
}
.price-pan{
    text-align: center;
    font-size: 48px;
    color: #FF5800;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
}
.price-pan strong{
    text-align: center;
    font-size: 26px;
    color: #FF5800;
}

.new-price {
    .h5 {
        margin-bottom: 30px;
        font-weight: 600;
    }
}



// .dot-elastic {
//     position: relative;
//     /*width: 10px;
//     height: 10px;
//     border-radius: 5px;
//     background-color: #9880ff;*/
//     color: #fff;
//     animation: dotElastic 1s infinite linear;
//     margin:0 auto;
//   }



//   @keyframes dotElasticBefore {
//     0% {
//       transform: scale(1, 1);
//     }
//     25% {
//       transform: scale(1, 1.5);
//     }
//     50% {
//       transform: scale(1, 0.67);
//     }
//     75% {
//       transform: scale(1, 1);
//     }
//     100% {
//       transform: scale(1, 1);
//     }
//   }


.sk-fading-circle {
    margin: 100px auto;
    width: 80px;
    height: 80px;
    position: relative;
  }

  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #27c070;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg);
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg);
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  .why-hire-java {
      .service-wrap {
          column-count: inherit;
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .services-pan {
            @include media-query(992px) {
                flex: 0 0 31.5%
            }
        }
      }
  }


  .remote-3-developer {
    padding: 40px 0 30px;
    @include media-query(992px) {
        padding: 80px 0;
    }
      h2 {
          margin-bottom: 20px;
      }
      .rd-step {
        img {
            margin: 5px 0 30px;
            @include media-query(992px) {
                margin: 30px 0 60px;
            }
        }
      }
      .rd-block {
        background: #fff;
        box-shadow: 0 0 15px 2px rgba(104, 98, 98, 0.1);
        padding: 45px 30px 45px;
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        position: relative;
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 70px;
        }
        .rd-img {
            height: 80px;
        }
        h3 {
            font-size: 24px;
            font-weight: 500;
            margin: 15px 0 10px;
        }
        p {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 0;
        }
        span {
            position: absolute;
            top: 7px;
            right: 10px;
            color: #3E93DA;
            font-size: 36px;
            font-weight: 500;
            display: block;
        }
    }
  }

  .blockchain-tevh-stack {
    .tech-list {
        div {
            @include media-query(992px) {
                width: 25%;
            }
        }
    }
  }


  #hosted {
    max-width: 100% !important;
}

.office-infrastructure .o-skills-hero__schedule {
    background: url(/images/common/schedule-talk-bg.jpg) no-repeat 0 0 / cover;
    padding-top: 100px !important;
    padding-bottom: 40px !important;
    @include media-query(992px) {
        padding-bottom: 60px !important;
        padding-top: 150px !important;
    }
}

.blog-section {
    padding: 50px 0 20px;
    @include media-query(992px) {
        padding: 75px 0 45px;
    }
    h2 {
        margin-bottom: 40px;
        font-size: 30px;
        @include media-query(992px) {
            font-size: 48px;
            line-height: 61px;
        }
    }
    .blog-box {
        background: #fff;
        display: block;
        margin-bottom: 40px;
        img {
            width: 100%;
        }
        .blog-img {
            overflow: hidden;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .blog-text {
            padding: 30px 30px;
            text-align: left;
            border: 1px solid #C8C7CC;
            border-top: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            @include media-query(992px) {
                min-height: 180px;
            }
            h5 {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                font-family: Montserrat,sans-serif;
            }
            p {
                color: #4A90E2;
                font-size: 14px;
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: 500;
            }
        }
    }
}

.bor-rad-bl {
    @include media-query(992px) {
        border-bottom-left-radius: 5px !important;
    }
}
.bor-rad-tl {
    @include media-query(992px) {
        border-top-left-radius: 5px !important;
    }
}


.beauty-section {
    .o-clients {
        border: none;
        padding: 15px 0 0;
        @include media-query(768px) {
            padding: 15px 0 25px;
        }
        h3 {
            font-size: 24px;
            margin-bottom: 25px;
        }
        ul {
            max-width: 100%;
            height: auto;
            li {
                margin: 15px 15px;
                flex: 0 0 39%;
                @include media-query(768px) {
                    flex: 0 0 120px;
                    margin: 5px 15px;
                }
                @include media-query(992px) {
                    flex: 0 0 auto;
                }
                &:first-child {
                    flex: 0 0 50px;
                    @include media-query(992px) {
                        flex: 0 0 auto;
                    }
                }
                &:nth-child(2) {
                    display: block;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
}

.pricing-btn .btn {
    @include media-query(992px) {
        max-width: 500px;
    }
    &:after {
        right: 10px;
        @include media-query(992px) {
            right: inherit;
        }
    }
}
.white{
    &.new-cta-style-fix{
        padding: 0;
        margin: 0;
        margin-top: 10px;
        text-align: center;
        background: none;
        border: none;
        font-size: 13px;
        line-height: 19px;
        text-transform: lowercase;
        @include media-query(992px) {
            text-align: left;
        }
        &:hover{
            background: none;
        }
    }
}


.no-webp {
    .o-footer .social-footer-divider--social-pan li a {
        background-image: url('/images/common/common-sprite.png');
    }
}
.webp {
    .o-footer .social-footer-divider--social-pan li a {
        background-image: url('/images/webp/common/common-sprite.webp');
    }
}
.no-webp {
    .o-footer .social-footer-divider--social-pan li.social-behance a {
        background-image: url('/images/common/behance-sprite.jpg');
        background-repeat: no-repeat;
    }
}
.webp {
    .o-footer .social-footer-divider--social-pan li.social-behance a {
        background-image: url('/images/webp/common/behance-sprite.webp');
        background-repeat: no-repeat;
    }
}
.o-footer {
    background-color: $white-color;
    padding: 40px 0;
    @include responsive(desktop){
        padding: 0 0 40px;
    }
    text-align: center;

    .social-footer-divider{
        position: relative;
        border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
        margin-bottom: 50px;
        @include responsive(desktop){
            margin-bottom: 40px;
            border-bottom: 1px solid #ccc;
            border-top: 0;
        }
        &--social-pan {
            padding: 30px 0 30px;
            @include responsive(desktop){
                padding:40px 0;
            }
        li {
            display: inline-block;
            margin: 0 5px;
            width: 44px;
            height: 44px;
            line-height: 44px;
            border-radius: 50%;
            background-size:100%;

            a{
                line-height: 44px;
                width: 44px;
                height: 44px;
                display: block;
                cursor: pointer;
                border: 1px solid #E7E7E7;
                border-radius: 50%;
                text-indent: -10000px;
                .display-vissable{
                    display: inline-block;
                }
                .display-hidden{
                    display: none;
                }
                &:hover{
                    .display-vissable{
                        display: none;
                    }
                    .display-hidden{
                        display: inline-block;
                    }
                }
            }
            &.social-1{
                    a{
                        background-position: -4px -342px;
                        &:hover{
                            background-position: -59px -342px;
                        }
                    }
                }
            &.social-2{
                    a{
                        background-position: -112px -342px;
                        &:hover{
                            background-position: -168px -342px;
                        }
                    }
                }
            &.new-twitter-icon,
            &.social-2 {
                a {
                    background-image: url(/images/common/new-twitter-icon-group.png);
                    background-position: 10px 11px;
                    background-repeat: no-repeat;
                    &:hover {
                        background-position: -32px 11px;
                    }
                }
            }
            &.social-3{
                    a{
                        background-position: -222px -342px;
                        &:hover{
                            background-position: -277px -342px;
                        }
                    }
                }
            &.social-4{
                    a{
                        background-position: -5px -377px;
                        &:hover{
                            background-position: -56px -377px;
                        }
                    }
                }
            &.social-5{
                    a{
                        background-position: -112px -376px;
                        &:hover{
                            background-position: -166px -376px;
                        }
                    }
                }
            &.social-6{
                    a{
                        background-position: -222px -376px;
                        &:hover{
                            background-position: -274px -376px;
                        }
                    }
                }
            &.social-behance{
                a{
                    background-position: 8px 12px;
                    &:hover{
                        background-position: -55px 12px;
                    }
                }
            }    
            }
        }
    }
    &--copyright {
        padding:40px 20px 5px;
        @include responsive(desktop){
            text-align: left;
            padding:0 20px;
        }
        ul{
            overflow: hidden;
            li{
                display: inline-block;
                border-left:1px solid #DFDFDF;
                padding: 0 10px;
                margin-bottom: 4px;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                @include responsive(desktop){
                    font-size: 12px;
                }
                &:first-child{
                    border-left: 0;
                    padding: 0 10px 0 0;
                }
                a{
                    color: $black-color;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }

        p {
            margin-top: 20px;
            text-align: center;
            font-size: 15px;
            font-family: "Montserrat",sans-serif;
            font-weight: 500;
            @include responsive(desktop){
                margin: 0;
                font-size: 12px;
                line-height: 20px;
                text-align: left;
            }
            span{
                display: block;
                @include responsive(desktop){
                    display: inline-block;
                }
            }
        }
    }
    .dmca-badge{
        margin-top: 2px;

    }

}

.new-footer {
    $new-footer: &;
    background: #091B38;
    padding: 35px 0 40px;
    text-align: center;
    @include media-query(992px) {
        padding: 50px 0 50px;
    }
    &__small{
        &--mobile{
            .bottom-col-wrap__left-border{
                border-top: 1px solid #194b83;
                border-left: none;
                padding-left: 5px;
                padding-right: 5px;
                margin-top: 40px;
                @include media-query(768px) {
                    border-top: none;
                    border-left: 1px solid #194b83;
                    padding-left: 20px;
                    padding-right: 0;
                    margin-top: 0;
                }
            }
        }
    }

    @include media-query(768px) {
        text-align: left;
        padding: 40px 0 50px;
    }
    h6 {
        font-size: 16px;
        line-height: 23px;
        color: #E4ECF5;
        font-weight: 500;
        display: block;
        margin-bottom: 0;
        @include media-query(768px) {
            margin-bottom: 0.5rem;
        }
    }
    h5 {
        font-size: 16px;
        line-height: 23px;
        color: #E4ECF5;
        font-weight: 500;
        display: block;
        margin-bottom: 0;
        @include media-query(768px) {
            margin-bottom: 0.5rem;
        }
    }
    &-ul {
        vertical-align: text-top;
        margin-bottom: 0;
        padding: 15px 15px 10px;
        @include media-query(768px) {
            margin-top: 25px;
            padding: 0;
            margin-bottom: 10px
        }
        
        li {
            padding: 5px 0;
            @include media-query(768px) {
                padding: 0;
            }
            a {
                color: #AEC7DA;
                font-family: $main-font;
                font-size: 15px;
                line-height: 25px;
                @include media-query(768px) {
                    font-size: 13px;
                }
                &:hover {
                    color: #2dbe70;
                }
            }
        }
        &__link{
            @include media-query(768px) {
                margin-top: 0!important;
                margin-bottom: 0!important;
                color: $white-color;
            }
            li{
                a{
                    color: $white-color;
                }
            }
            
        }
        &-mr {
            @include responsive(desktop){
                margin-right: 50px;
            }
        }
        // display: none;
        // @include media-query(768px) {
        //     display: block;
        // }
        &:first-child {
            margin-bottom: 0;
            @include media-query(768px) {
                margin-bottom: 25px;
            }
        }
    }

    &__small{
        @include media-query(768px) {
            padding: 25px 0;
        }
    }
}

.col-wrap {
    p {
        color: #AEC7DA;
        font-family: $main-font;
        font-size: 16px;
        line-height: 27px;
        margin-top: 25px;
        @include media-query(992px) {
            font-size: 13px;
            line-height: 25px;
            margin-top: 35px;
        }
    }
    
}

.bottom-col-wrap {
    &__left-border{
        border-left:1px solid $blue-color2;
        padding-left:20px;
    }
    ul {
        margin-bottom: 10px;
        margin-top: 13px;
        li {
            display: inline-block;
            a {
                position: relative;
                padding: 0 10px;
                &:before {
                    content: '';
                    position: absolute;
                    background: #aec7da;
                    height: 12px;
                    width: 1px;
                    top: 3px;
                    right: -2px;
                }
            }
            &:first-child {
                a {
                    padding-left: 0;
                }
            }
            &:last-child {
                a {
                    &:before {
                        display: none;
                    }
                }
            }

        }
    }
    p {
        color: #AEC7DA;
        font-family: $main-font;
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 0;
        @include media-query(992px) {
            font-size: 13px;
            line-height: 25px;
        }
    }
}

.new-social-links {
    display: flex;
    margin-bottom: 40px;
    @include media-query(768px) {
         margin-bottom: 25px;
    }
    @include responsive(desktop){
        justify-content: space-between;
        margin-bottom: 0;
        max-width: 86%;
    }
    align-items: center;
    margin-top: 35px;
    justify-content: space-evenly;
    li {
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        a {
            &:hover {
                svg {
                    path, circle {
                        stroke: #2dbe70;
                    }
                }
            }
            svg {
                transition: .3s all ease-in-out;
            }
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(61%) sepia(10%) saturate(2494%) hue-rotate(95deg) brightness(97%) contrast(96%);
                    transition: .5 all ease-out;
                }
            }
            img {
                transition: .5 all ease-in;
            }
        }
    }
}

.dmca {
    @include responsive(desktop){
        text-align: right;
    }
    text-align: center;
    padding-top: 40px;
}

.footer-btm-line {
    margin: 20px 0 40px;
    @include responsive(desktop){
        margin: 60px 0 40px;
    }
    padding: 0;
    background: #979797;
    height: 1px;
    opacity: .3;
}


.new-footer-ul-mod {
    @include media-query(992px) {
        margin-top: 15px;
    }
}

.ol-text {
    margin-top: 25px;
}
.footer-address-box {
    background: #122A52;
    padding: 40px 40px 40px;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    @include media-query(992px) {
        margin-bottom: 75px;   
    }
    &-top {
        // align-items: flex-start;
        align-items: center;
        margin-bottom: 10px;
        min-height: 35px;
        @include media-query(768px) {
            display: flex;
        }
        img {
            width: 23px;
            height: 23px;
            margin: 0 auto 15px;
            display: block;
            @include media-query(768px) {
                display: inherit;
                margin-right: 10px;
                margin-bottom: 0;
            }
        }
        h4 {
            flex: 1;
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 0;
            span {
                display: block;
                font-size: 13px;
                color: #AEC7DA;
                text-transform: capitalize;
            }
        }
    }
    p {
        font-size: 13px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 20px;
        a {
            color: #AEC7DA;
        }
    }
    h5 {
        font-size: 16px;
        margin-bottom: 0;
        a {
            color: #36E079;
        }
    }
}

.ft-menu {
    background: #223a63;
    padding: 10px 15px;
    @include media-query(768px) {
        background: none;
        padding: 0;
        margin-bottom: 0;
    }
    &:before {
        display: block;
        content: '+';
        // background: url(../images/home/w-trio.png) no-repeat 0 0;
        // background-size: 50%;
        width: 18px;
        height: 15px;
        position: absolute;
        top: 10px;
        right: 8px;
        font-size: 26px;
        @include media-query(768px) {
            display: none;
        }
    }
}
.ft-menu.ft-menu-mod {
    &:before {
        display: block;
        content: '-';
        // background: url(../images/home/w-trio-r.png) no-repeat 0 0 !important;
        // background-size: 50% !important;
        width: 18px;
        height: 15px;
        position: absolute;
        top: 9px;
        right: 5px;
        @include media-query(768px) {
            display: none;
        }
    }
}

.cd {
    display: none;
    @include media-query(768px) {
        display: block;
    }
}

.ft-menu-bg {
    background-color: #122a52;
    text-align: left;
    padding: 0;
    @include media-query(768px) {
        background: none;
        padding: 0;
        text-align: inherit;
        margin-bottom: 0;
        border-radius: 0;
    }
    ul {
        position: relative;
        &:before {
        content: '';
        position: absolute;
        background: #223a63;
        height: 15px;
        width: 15px;
        top: -7px;
        left: 18px;
        transform: rotate(45deg);
            @include media-query(768px) {
                display: none;
            }
        }
    }
}

.ft-menu-bg-one {
    margin-bottom: 1px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    @include media-query(768px) {
        margin-bottom: 0;
    }
}
.ft-menu-bg-two {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

/*--------------New Footer Location------------------*/

.new-location-heading{
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
}

.new-location-area-row{
    margin-bottom: 35px;
    @include media-query(992px) {
        margin-bottom: 58px;
    }
    .padding-25{
        padding: 25px 30px;
    }
    .footer-location-table{
        //display: table;
        // margin-top: 30px;
        @include media-query(992px) {
            display: flex;
            
        }
        .new-location-border-divider{
            border-bottom: 1px solid #244375;
            border-right: 0;
            @include media-query(992px) {
                border-right: 1px solid #244375;
                border-bottom: 0;
            }
        }
        
        .footer-location{
            text-align: center;
            border: 1px solid #244375;
            border-radius: 5px;
            //display: table-cell;
            overflow: hidden;
            margin-top: 20px;
            @include media-query(992px) {
                margin-top: 20px;
            }
        }
            .location-top-heading{
                text-transform: uppercase;
                font-weight: 700;
                color: #fff;
                margin-bottom: 0;
                padding: 20px;
                border-bottom: 1px solid #244375;
                font-size: 18px;
                @include media-query(992px) {
                    font-size: 16px;
                }
            }
            .footer-location-body{
                p{
                    color: #AEC7DA;
                    font-size: 16px;
                    line-height: 27px;
                    margin-bottom: 0;
                    @include media-query(992px) {
                        font-size: 13px;
                        line-height: 22px;
                    }
                    &.margin-bottom-15{
                        margin-bottom: 15px;
                    }
                }
                .location-heading-area{
                    color: #fff;
                    margin-bottom: 15px;
                    font-size: 18px;
                    font-weight: 600;
                    @include media-query(992px) {
                        font-size: 16px;
                    }
                }
                .location-icon{
                    margin-bottom: 20px;
                    min-height: 64px;
                }
            }
            .new-location-tel{
                color: #00B54A;
                font-size: 16px;
                font-weight: 700;
                border-bottom: 1px solid transparent;
                &:hover{
                    border-bottom: 1px solid #00B54A;
                }
            }
        }
    }

.footer-divider-role{
    color: #4166A1;
    margin: 10px 0 30px;
    @include media-query(992px) {
        margin: 25px 0 30px;
    }
}

/*----------------Contact Page New Location----------------------*/

.contact-new-location{
    background-color: #F1F1F1;
    border-top: #979797;
    position: relative;
    z-index: 0;
    .contact-new-location-box-spacing-right{
        padding-right: 40px;
    }
    .contact-location-spacing-new{
        padding: 40px 15px;
        position: relative;
        z-index: 99;
        @include media-query(992px) {
            padding: 90px 15px;
        }
    }
    .contact-new-location-top-heading{
        font-size: 28px;
        line-height: 28px;
        color: #333333;
        font-weight: 600;
    }
    .contact-new-location-country{
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 0;
        @include media-query(992px) {
            margin-top: 50px;
        }
        .contact-country-flag-icon{
            margin-right: 12px;
        }
        .contact-new-location-heading{
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 700;
            line-height: 18px;
        }
    }
    .contact-new-location-box{
        &.contact-new-location-box-spacing{
            margin-left: 48px;
        }
        &.contact-new-location-box-spacing-mobile{
            margin-left: 48px;
            @include media-query(992px) {
                margin-left: 0;
            }
            &.margin-top-30{
                margin-top: 30px;
                @include media-query(992px) {
                    margin-top: 0;
                }
            }
        }
        .contact-new-location-area{
            font-size: 16px;
            font-weight: 600;
            color: #292929;
            line-height: 16px;
            margin-bottom: 12px;
            margin-top: 15px;
        }
        p{
            font-size: 13px;
            line-height: 22px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 0;
            &.margin-bottom-20{
                margin-bottom: 15px;
                @include media-query(992px){
                    margin-bottom: 20px;
                }
            }
        }
        .contact-new-location-tel{
            font-size: 16px;
            font-weight: 700;
            color: #00C04C;
            line-height: 16px;
            border-bottom: 1px solid transparent;
            &:hover{
                border-bottom: 1px solid #00B54A;
            }
        }
    }
    .contact-main-office-img-container{
        @include media-query(1921px){
            padding-right: 0;
        }
    }
    .new-location-main-office-building-img{
        position: absolute;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
        object-position: left center;
        left: 0;
        z-index: 0;
        background: #ededed;
        opacity: 0.2;
        @include media-query(992px) {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50vw;
            object-fit: cover;
            object-position: center bottom;
            max-height: 100%;
            height: 100%;
            left: auto;
            opacity: 1;
        }
        @include media-query(1921px){
            position: relative;
        }
    }
}
.new-contact-location{
    .new-location-heading,
    .new-location-area-row{
        display: none;
    }
}

.footer-location {
    width: 100%;
}

.col-md-4-text {
    .col-wrap {
        @include media-query(992px){
            padding-right: 25px;
        }
        @include media-query(1280px){
            padding-right: 35px;
        }
    }
}

.fixed-cost-banner {
  $fixed-cost-banner: &;
  text-align: center;
  padding-bottom: 15px;
  @include media-query(992px) {
    text-align: inherit;
    padding-bottom: 0;
  }
  &:before {
    display: none;
    @include media-query(992px) {
      display: block;
    }
  }
  &__spacing {
    @include media-query(992px) {
      padding: 120px 0 40px;
    }
  }
  &__subtitle {
    @include media-query(992px) {
      line-height: 37px;
      color: #fff;
    }
    .highlight {
      @include media-query(992px) {
        color: #53d094;
      }
    }
  }
  &__btn {
    padding: 15px 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    transition: 0.3s all ease;
    margin-top: 30px;
    display: inline-block;
    @include media-query(992px) {
      padding: 15px 30px;
    }
    svg {
      margin-left: 5px;
      transition: 0.3s all ease;
    }
    &:hover {
      svg {
        transform: translateX(10px);
      }
      path {
        fill: #ff5800;
      }
    }
  }
  .o-engagement-banner__bannerimage {
    margin-bottom: 0;
    @include media-query(992px) {
      margin-bottom: 0;
    }
  }
}

.fixed-core-engagement {
  $fixed-core-engagement: &;
  // border-bottom: 1px solid #E6E6E6;
  padding-bottom: 0;
  @include media-query(992px) {
    padding-bottom: 0;
  }
  &:before {
    height: 20%;
  }
  &__wrapper {
    padding: 25px 10px 0;
    @include media-query(1180px) {
      padding: 65px 80px 0;
    }
    &:before,
    &:after {
      display: none;
    }
  }
  &__title {
    margin-bottom: 10px;
  }
  &__sub-heading {
    text-align: center;
    color: #474747;
    font-weight: 600;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 35px;
    }
  }
  &__corewrapper {
    padding: 20px;
    text-align: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include media-query(992px) {
      padding: 50px 70px;
    }
    i {
      display: block;
      margin: 0 auto;
      height: 50px;
    }
    h3 {
      font-family: "Poppins";
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: #333333;
      margin-top: 15px;
      margin-bottom: 10px;
      @include media-query(992px) {
        font-size: 31px;
        line-height: 38px;
        margin-top: 25px;
        margin-bottom: 15px;
      }
    }
    p {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 15px;
      @include media-query(992px) {
        font-size: 21px;
        line-height: 31px;
        margin-bottom: 30px;
      }
    }
    a {
      font-family: "Poppins";
      background: #ff5800;
      color: #fff;
      border: 2px solid #ff5800;
      padding: 15px 30px;
      border-radius: 5px;
      font-size: 17px;
      font-weight: 600;
      transition: 0.3s all ease;
      display: block;
      max-width: 250px;
      margin: 0 auto;
      svg {
        margin-left: 5px;
        transition: 0.3s all ease;
      }
      &:hover {
        background: transparent;
        color: #ff5800;
        svg {
          transform: translateX(10px);
        }
        path {
          fill: #ff5800;
        }
      }
    }
    &--blue {
      border-bottom: 8px solid #4492dc;
    }
    &--green {
      border-bottom: 8px solid #3cc065;
    }
  }
  &__need-help {
    text-align: center;
    h4 {
      font-family: "Poppins";
      margin-bottom: 15px;
      @include media-query(992px) {
        margin-top: 50px;
        font-size: 31px;
      }
    }
    a {
      font-family: "Poppins";
      font-size: 17px;
      font-weight: 600;
      color: #75a3f0;
      svg {
        margin-left: 10px;
      }
      &:hover {
        color: #3cc065;
        svg {
          transform: translateX(10px);
        }
        path {
          fill: #3cc065;
        }
        span {
          &:before {
            background: #3cc065;
          }
        }
      }
      span {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #75a3f0;
        }
      }
    }
  }
}

.fixed-cost-model {
  $fixed-cost-model: &;
  padding: 0;
  background: #f9f9f9;
  @include media-query(992px) {
    padding: 0;
  }
  &__heading {
    font-family: "Poppins";
    text-align: center;
    color: #262e36;
    @include media-query(992px) {
      font-size: 44px;
      line-height: 60px;
    }
  }
  &__subheading {
    font-family: "Poppins";
    text-align: center;
    color: #262e36;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 40px;
      line-height: 58px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &:before {
    //   @include media-query(992px) {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: 201px;
        height: 4px;
        background: #3cc065;
      }
    // }
  }
  &__subtitle {
    font-family: "Poppins";
    text-align: center;
    color: #474747;
    font-weight: 600;
    position: relative;
    @include media-query(992px) {
      font-size: 22px;
      line-height: 34px;
      
    }
  }
  &__sub-heading {
    font-family: "Poppins";
    color: #262e36;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @include media-query(992px) {
      font-size: 31px;
    }
  }
  &__wrapper {
    position: relative;
    @include media-query(992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 2px;
      width: 100%;
      background: url(/images/fixed-cost/dashed-img.png) repeat-x 0 0;
      display: none;
      @include media-query(992px) {
        display: block;
        width: 87%;
      }
      @include media-query(1200px) {
        width: 87%;
      }
      @include media-query(1280px) {
        width: 100%;
      }
    }
  }
  &__card {
    border: 1px solid #898989;
    padding: 30px 30px 30px 25px;
    position: relative;
    margin-bottom: 20px;
    @include media-query(992px) {
      margin: 33px 0;
      padding: 30px 30px 30px 25px;
      flex: 0 0 24.4%;
    }
    @include media-query(1200px) {
      flex: 0 0 25.4%;
    }
    @include media-query(1280px) {
      flex: 0 0 29.4%;
    }
    &:nth-child(2),
    &:nth-child(5) {
      @include media-query(992px) {
        margin: 33px 66px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &--right-arrow {
      &::before {
        @include media-query(992px) {
          content: "";
          position: absolute;
          background: url(/images/fixed-cost/arrow-right.svg) no-repeat 0 0;
          width: 57px;
          height: 17px;
          right: -59px;
          top: 94px;
        }
      }
    }
  }
  &__card-icon {
    display: inline-block;
    height: 50px;
    margin-bottom: 15px;
  }
  &__card-heading {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  &__card-para {
    font-family: "Poppins";
    font-size: 14px;
    line-height: 22px;
    color: #333;
    margin-bottom: 0;
  }
  &__numerics {
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #355bbf;
    color: #fff;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    border: 11px solid #ebeffa;
  }
  &__curve-arrow-right {
    position: absolute;
    top: 141px;
    right: -48px;
    display: none;
    @include media-query(992px) {
      top: 163px;
      right: 10px;
      display: block;
    }
    @include media-query(1200px) {
      top: 141px;
      right: 17px;
    }
    @include media-query(1280px) {
      right: -48px;
    }
  }
  &__curve-arrow-left {
    position: absolute;
    left: -49px;
    bottom: 128px;
    display: none;
    @include media-query(992px) {
      left: 10px;
      bottom: 150px;
      display: block;
    }
    @include media-query(1200px) {
      bottom: 128px;
      left: 17px;
    }
    @include media-query(1280px) {
      left: -49px;
    }
  }
}

.fixed-cost-benefits {
  $fixed-cost-benefits: &;
  margin-top: 20px;
  @include media-query(992px) {
    margin-top: 33px;
  }
  h4 {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    color: #262e36;
    text-align: center;
    margin-bottom: 20px;
    @include media-query(992px) {
      font-size: 31px;
      margin-bottom: 35px;
    }
  }
  &__card {
    padding: 30px 30px 30px 25px;
    border: 1px solid #dddddd;
    margin-bottom: 20px;
    @include media-query(992px) {
      padding: 30px 30px 30px 25px;
      height: 100%;
      margin-bottom: 0;
    }
  }
  &__card-icon {
    display: inline-block;
    height: 50px;
    margin-bottom: 15px;
  }
  &__card-heading {
    font-family: "Poppins";
    font-size: 21px;
    line-height: 32px;
    font-weight: 600;
    color: #333;
  }
  &__card-para {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #333;
    margin-bottom: 0;
  }
  &__btn {
    font-family: "Poppins";
    background: #ff5800;
    color: #fff;
    border: 2px solid #ff5800;
    padding: 15px 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 600;
    transition: 0.3s all ease;
    display: inline-block;
    margin: 15px 0 0;
    @include media-query(992px) {
      margin: 35px 0 0;
      padding: 15px 30px;
    }
    svg {
      margin-left: 5px;
      transition: 0.3s all ease;
    }
    &:hover {
      background: transparent;
      color: #ff5800;
      svg {
        transform: translateX(10px);
      }
      path {
        fill: #ff5800;
      }
    }
  }
}
.v-gap {
  @include media-query(992px) {
    height: 60px;
  }
}
.custom-solution--fixed {
  @include media-query(992px) {
    padding: 60px 0 60px !important;
  }
  .custom-solution__heading {
    @include media-query(992px) {
      margin-bottom: 40px;
    }
  }
  .btn-wrapper-v2--pair .btn-v2 {
    @include media-query(992px) {
      margin: 25px 5px 20px;
    }
  }
}
.new-faq--fixed {
  .faq-sub-title {
    font-family: "Poppins";
    font-size: 19px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 25px;
    @include media-query(992px) {
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 30px;
      @include media-query(992px) {
        margin-bottom: 50px;
      }
    }
  }
  h2 {
    font-weight: 700;
    @include media-query(992px) {
      padding-bottom: 15px;
    }
  }
}
.agile-process {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 0 40px;
  @include media-query(992px) {
    padding: 0 0 60px;
  }
  // .fixed-cost-model__subtitle {
  //     &:before {
  //         display: none;
  //     }
  // }
  h3 {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
  }
  .fixed-cost-model__card {
    border: 1px solid #dddddd;
    @include media-query(992px) {
      flex: 0 0 28%;
      padding: 30px 25px 30px 25px;
      box-shadow: 5px -4px 20px -7px rgba(0, 0, 0, 0.1);
    }
    @include media-query(1200px) {
      flex: 0 0 24%;
    }
  }
  .fixed-cost-model__wrapper {
    &:before {
      width: 68%;
      top: 49.5%;
      right: inherit;
      left: 23.5%;
      @include media-query(992px) {
        width: 80%;
        top: 49.5%;
        right: inherit;
        left: 19%;
      }
      @include media-query(1200px) {
        width: 69%;
        top: 49.5%;
        right: inherit;
        left: 23%;
      }
      @include media-query(1280px) {
        width: 68%;
        top: 49.5%;
        right: inherit;
        left: 23.5%;
      }
    }
  }
  .fixed-cost-model__curve-arrow-right {
    right: 46px;
    top: 137px;
    @include media-query(992px) {
      right: -41px;
      top: 135px;
    }
    @include media-query(1200px) {
      right: 40px;
      top: 137px;
    }
    @include media-query(1280px) {
      right: 46px;
      top: 137px;
    }
  }
  .fixed-cost-model__curve-arrow-left {
    left: 215px;
    bottom: 134px;
    @include media-query(992px) {
      left: 122px;
      bottom: 132px;
    }
    @include media-query(1200px) {
      left: 207px;
      bottom: 133px;
    }
    @include media-query(1280px) {
      left: 215px;
      bottom: 134px;
    }
  }
  .fixed-cost-model__card--mr0 {
    margin-right: 0;
  }
  .fixed-cost-model__card-para {
    font-size: 16px;
  }
  .fixed-cost-model__card-heading {
    font-size: 18px;
    @include media-query(1200px) {
      font-size: 19px;
    }
  }
  .fixed-cost-model__card--right-arrow {
    &::before {
      @include media-query(992px) {
        top: 100px;
      }
    }
  }
}
.fixed-cost {
  .custom-review {
    background: #f9f9f9;
    border-top: 1px solid #e0e0e0;
  }
}

.scroll-to-box {
  height: 40px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  background: #fff;
  @include media-query(992px) {
    height: 72px;
    margin-bottom: 60px;
  }
}
.scroll-to-box-agile {
  height: 40px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  background: #f9f9f9;
  @include media-query(992px) {
    height: 71px;
    margin-bottom: 60px;
  }
}

body[data-page="referral-program"] .o-services-hero {
    background-image: none;
    text-align: left;
    background-color: #F1F4F8;
    padding: 0 0 50px;
    @include responsive(desktop){
        padding: 10px 0px 70px 0;
        background: url(/images/common/header-pattern-r.png) 100% 0 no-repeat #F1F4F8;
        background-size: 30%;
    }
    position: relative;
    .logo {
        display: block;
        margin: 0 auto 30px;
        @include responsive(desktop){
            margin: 0 0 70px 0;
            display: inline;
        }
    }
    p.med {
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 28px;
        font-family: $main-font;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
        }
    }
    h1 {
        font-size: 38px;
        line-height: 50px;
        color: #333;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
        }
    }
    p.r-text1 {
        font-size: 24px;
        font-weight: 500;
        color: #25c351;
        line-height: 34px;
        font-family: $main-font;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
        }
    }
    p.r-text2 {
        font-size: 12px;
        line-height: 17px;
        font-family: $main-font;
        text-align: center;
        @include responsive(desktop){
            text-align: left;
        }
    }
}
body[data-page="referral-program"] {
    .o-services-hero__background-1 {
        background: url(/images/common/header-bottom-bg.png) 0 100% no-repeat;
        background-size: 40%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 150px;
    }
}
body[data-page="referral-program"] {
    .container {
        padding: 40px 20px;
    }
}
body[data-page="referral-program"] .o-services-hero {
    .refer-client-form {
        background: url(/images/contact-us/dashed-line.png) 0 0 repeat-x #fff;
        padding: 40px;
        border-radius: 10px;
        margin-top: 50px;
        h2 {
            font-size: 28px;
            line-height: 32px;
            font-weight: 600;
            padding: 0;
            margin-bottom: 0;
            color: #333;
        }
        h3 {
            font-size: 16px;
            line-height: 32px;
            font-weight: 400;
        }
        input,
        textarea {
            outline: none;
        }
        .u-name {
            // background: url(/images/common/icon-name.svg) 0 50% no-repeat;
            border-bottom: 1px solid #C9C8C8;
            padding: 20px 20px 20px 40px;
            color: #333;
            width: 100%;
            margin-bottom: 10px;
            font-weight: 400;
        }
        .u-email {
            // background: url(/images/common/icon-email.svg) 0 50% no-repeat;
            border-bottom: 1px solid #C9C8C8;
            padding: 20px 20px 20px 40px;
            color: #333;
            width: 100%;
            margin-bottom: 10px;
            font-weight: 400;
        }
        .u-name {
            // background: url(/images/common/icon-name.svg) 0 50% no-repeat;
            border-bottom: 1px solid #C9C8C8;
            padding: 20px 20px 20px 40px;
            color: #333;
            width: 100%;
            margin-bottom: 10px;
            font-weight: 400;
        }
        .phone {
            border-bottom: 1px solid #C9C8C8;
            padding: 20px 20px 35px 0;
            color: #333;
            width: 100%;
            margin-bottom: 0;
            font-weight: 400;
            position: relative;
            input {
                width: 75%;
                border-bottom: 0;
            }
            #r-phoneNumber {
                position: absolute;
                top: 19px;
                left: 60px;
            }
        }
        .terms {
            margin: 20px 0;
            display: block;
            font-size: 16px;
            input {
                margin-right: 10px;
            }
        }
        .r-submit {
            display: inline-block;
            outline: none;
            cursor: pointer;
            font-family: "Montserrat",sans-serif;
            font-weight: 600;
            color: #fff;
            background: #FF5800;
            // font-size: 16px;
            // line-height: 43px;
            text-transform: uppercase;
            border: 2px solid #FF5800;
            padding: 0 27px;
            border-radius: 10px;
            margin-bottom: 20px;
            line-height: 60px;
            font-size: 20px;
            max-width: 250px;
            &:after {
                content: '';
                display: inline-block;
                width: 7px;
                height: 13px;
                background: url(/images/common/icon-arrow-next-small.svg) 50% 50% no-repeat;
                background-size: 7px auto;
                margin-left: 15px;
            }
        }
        input {
            margin-bottom: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }
        p {
            font-size: 13px;
            line-height: 18px;
            font-family: $main-font;
            i {
                float: left;
                padding: 0 10px 20px 0;
            }
        }
        .niceCountryInputMenuFilter input {
            width: 100%;
            width: calc(100% - 10px);
            margin: 5px;
            padding: 5px;
        }
        .niceCountryInputMenuDropdownContent {
            border: 1px solid #a8a8a8;
            border-top: 0;
            max-height: 200px;
            overflow-y: scroll;
            overflow-x: hidden;
            position: absolute;
            top: 96px;
            background: #fff;
            width: 100%;
            z-index: 9;
            a {
                height: 25px;
                line-height: 25px;
                display: block;
                width: 100%;
                color: black !important;
                overflow: hidden;
                text-decoration: none;
                margin-bottom: 5px;
                font-weight: normal;
                padding: 0 10px;
                &:hover {
                    background-color: gray !important;
                    color: white !important;
                    text-decoration: none;
                }
                img {
                    margin-right: 10px;
                }
                span {
                    font-weight: 300 !important;
                }
            }
        }
        .niceCountryInputMenu {
            background: white !important;
            color: black !important;
            border: 0;
            cursor: pointer;
            float: left;
            margin-right: 10px;
            .niceCountryInputMenuDefaultText {
                width: 35px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // a {
                //     display: none;
                // }
            }
            a {
                color: black !important;
            }
            .niceCountryInputMenuCountryFlag {
                border: 1px solid #d3d3d3;
                width: 30px;
                height: auto;
                margin-left: 5px;
                margin-right: 5px;
            }
            .niceCountryInputMenuDropdown {
                height: 25px;
                width: 21px;
                float: right;
                line-height: 25px;
                text-align: center;
                position: relative;
                right: 0;
                color: black;
            }
        }
    }
}

.o-what-you-get-from-us--hit {
    text-align: center;
    padding: 40px 0;
    @include responsive(desktop){

    }
    h2 {
        font-size: 38px;
        line-height: 48px;
        font-weight: 600;
        color: #333;
        margin: 0px 0px 50px 0px;
        text-transform: uppercase;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 58px;
        }
    }
    h3 {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 44px;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        margin: 0px 0px 25px 0px;
        font-family: $main-font;
    }
}
.srvy-thanku-ref {
    margin-bottom: 50px;
}
.recaptcha-style
{
    color: red;
    font-size: 14px;
    margin-top: 5px;
}
/* Contact Form Modal */
.contact-modal{
    .modal-content{
      padding: 30px;
      background-color: #fff;
      .modal-header {

        button.close {
          img {
            width: 20px;
          }
        }

        figure {
            width: 70px;
            height: 70px;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 25px;

            img {
                display: block;
                position: absolute;
                z-index: 10;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%!important;
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
                animation: (spin, 10s, linear, 0s, infinite);
            }
        }
        .close{
          position: absolute;
          right: 30px;
          top: 30px;
          background-color:  transparent;
          width: auto;
        }
        h5{
          font-size: 18px;
          padding-bottom: 20px;
          padding-top: 20px;
          text-align: left;
        }

        p{
          font-size: 20px;
          line-height: 33px;
          font-weight: 600;
          font-family: "Montserrat",sans-serif;
        }
      }
      input.form-control{
      width: 100%;
      }
      .btn{
          display: inline-block;
          text-align: center;
          border-radius: 5px;
          padding: 10px 0px;
          font-size: 18px;
          text-transform: uppercase;
          max-width: 158px;
          text-decoration: none;
          position: relative;
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          color: #FFFFFF;
          border: 2px solid #FF5800;
          background-color: #FF5800;
          width: 250px;
      }
    }
  }
  /* ~./Contact Form Modal */

  #attachedFiles {
    margin-top: 20px;
    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 8px 15px;
      border-radius: 30px;
      background: #fafafa;
      input{
          line-height: 20px;
          width: 87%;
          font-size: 14px;
      }
      button{
        background: url('/images/common/icon-delete.svg') 0 0 no-repeat;
        width:14px;
        height: 19px;
        margin-right: 10px;
        vertical-align: middle;
      }

    }
  }


  .u-name{
      background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
      border-bottom: 1px solid #ccc;
  }
  .u-email{
      background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
      border-bottom: 1px solid #ccc;
  }
  .u-phone{
      background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
      border-bottom: 1px solid #ccc;
  }
  .u-company{
      background: url(/images/common/form-icon-4.png) 0 50% no-repeat;
      border-bottom: 1px solid #ccc;
  }
  .u-message{
      background: url(/images/common/form-icon-5.png) 0 20px no-repeat;
      border-bottom: 1px solid #ccc;
  }
  .attach{
      background: url(/images/common/attach-icon.svg) no-repeat;
      font-family: $main-font;
      padding: 10px 20px 0 40px;
      background-position: 0 15px;
      @include responsive(desktop) {
        background-position: 0 30px;
        padding: 20px 20px 0 40px;
      }

      .attach-inputfile{
          display: none;
      }
      label{
          color: blue;
          cursor: pointer;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
      }
  }
  .common-input{
    border-bottom: 1px solid #000;
    padding: 20px 20px 20px 40px;
    color: #000;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-family: $main-font;
    font-size: 16px;
    @include responsive(desktop) {
      padding: 20px 20px 20px 40px;
    }
  }

  .u-submit{
    display: block;
    text-align: center;
    border-radius: 5px;
    padding: 10px 0px;
    @include responsive(desktop) {
      padding: 19px 0px;
      font-size: 18px;
      width: 250px;
    }
    font-size: 15px;
    text-transform: uppercase;
    max-width: 350px;
    text-decoration: none;
    position: relative;
    font-family: $main-font;
    font-weight: 600;
    color: #fff;
    // border: 2px solid $orangebtn;
    // background-color: $orangebtn;
    width: 250px;
    margin: 0 auto;
    &:after {
        content: '';
        background: url(/images/common/arrow-white.png) no-repeat;
        width: 20px;
        height: 14px;
        display: inline-block;
        margin-left: 15px;
        z-index: -1;
        display: none;
    }
  }

  /* placeholder color */

  ::-webkit-input-placeholder {
    //color: $placeholder-color;
    font-weight: 300;
  }
  ::-moz-placeholder {
    //color: $placeholder-color;
    font-weight: 300;
  }
  :-ms-input-placeholder {
    //color: $placeholder-color;
    font-weight: 300;
  }
  :-moz-placeholder {
   // color: $placeholder-color;
    font-weight: 300;
  }


  label.error {
      color: red;
      text-align: left;
      display: block;
      font-weight: 300;
  }




  .mm-slideout {
      transition: transform .4s ease;
      z-index: 100;
  }






  body[data-page="contact-us"] {
      .o-contact-form{
          &--mobile-top {
              background: url('images/contact-us/contact-form-top-border-mobile.png') 0 0 repeat-x;
          }

          /* media query for tablet landscape */
          @include responsive(desktop){
              //background:url("images/m-contact-form/dashed-line.png") 100% 0 no-repeat #24C783;
              position: relative;
              box-shadow: 0px, 10px, 35px, rgba(0,0,0,0.1);
              padding: 0;
              background: transparent;
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
          }

          &--mobile {
              text-align: center;
              display: block;
              padding-top: 66px;
              padding: 60px 0 40px 0;

              /* media query for tablet landscape */
              @include responsive(desktop){
                  padding: 76px 0 40px 0;
              }

              h2 {
                  font-size: 36px;
                  line-height: 54px;

                  /* media query for tablet landscape */
                  @include responsive(desktop){
                      font-size: 48px;
                      line-height: 58px;
                  }
              }

              h3 {
                  font-size: 16px;
                  font-weight: 400;

                  /* media query for tablet landscape */
                  @include responsive(desktop){
                      font-size: 28px;
                      line-height: 35px;
                      font-weight: 500;
                  }
              }

              h5 {
                  font-size: 16px;
                  font-weight: 400;

                  /* media query for tablet landscape */
                  @include responsive(desktop){
                      font-size: 28px;
                      line-height: 35px;
                      font-weight: 500;
                  }
              }

              h6, .contact-linkedin, .attach, .u-company{
                  display: none;

                  /* media query for tablet landscape */
                  @include responsive(desktop){
                       display: block;
                  }
              }

              .group {

                  .choosecountry {
                      display: none;

                      /* media query for tablet landscape */
                      @include responsive(desktop){
                           display: block;
                      }
                  }

                  input[type='text']{
                      padding:20px 20px 20px 0;
                      background: url("/assets/images/common/icon-phone.svg") 0 50% no-repeat;

                      /* media query for tablet landscape */
                      @include responsive(desktop){
                           padding: 20px 20px 20px 40px;
                           background: none;
                      }
                  }
              }
          }

          &--contact-form-footer {
            //   background: $light-grey;
              padding: 20px 0;
              margin-top: 30px;
              text-align: left;
              /*mobile first for tablet landscape & small desktop*/
              @include responsive(desktop){
                text-align: center;
              }

              ul{
                  li{
                      margin: 0 15px;
                  }
              }
          }
          &__attachement-text{
              padding-left: 18px;
          }
      }

      .contact-mobile{
          display: none;
      }

  }

  .o-contact-form {

      /*mobile first for tablet landscape & small desktop*/
      @include responsive(desktop){
          background-size: 30%;
          color:#000;
          overflow:hidden;

          text-align: center;
          position: relative;
      }

      h2{
          margin-bottom: 15px;

          /*mobile first for tablet landscape & small desktop*/
          @include responsive(desktop){
            font-size: 60px;
            letter-spacing: 2.5px;
            text-shadow:1px 1px 0px #333, -1px 1px 0px #333, 1px -1px 0px #333, -1px -1px 0px #333;
          }
      }

      h3 {

        @include responsive(desktop){
              font-size: 28px;
              line-height: 35px;
              font-weight: 500;
          }
         br{
            display:  none;

            /* mobile first for small desktop */
            @include responsive(desktop){
              display: block;
            }
          }
      }

      h5 {

        @include responsive(desktop){
              font-size: 28px;
              line-height: 35px;
              font-weight: 500;
          }
         br{
            display:  none;

            /* mobile first for small desktop */
            @include responsive(desktop){
              display: block;
            }
          }
      }

      h4{
          font-size: 18px;
          padding-bottom: 20px;
          padding-top: 20px;
          text-align: left;
      }

      h6{
          font-size: 18px;
          padding-bottom: 20px;
          padding-top: 20px;
          text-align: left;
      }

      /* Contact Form Background  */
      &--white-bg {
          //display: none;

          /*mobile first for tablet landscape & small desktop*/
          @include responsive(desktop){
            background-color:#fff;
              //background: url('../../assets/images/m-contact-form/contact-form-top-border.png') 0 0 repeat-x $white;
              box-shadow: 0px, 0px, 20px, rgba(0,0,0,0.2);
              padding: 80px 0 0;
              border-radius: 3px;
              overflow: hidden;
              display: block;


          }
      }
      /* ~./Contact Form Background */

      .form-animate{
          transition: (0.1s, ease-out);
          animation: (formanimation, 0.3s, ease-in-out, 0s, 2);
      }

      /* Heart Animation */
      &__heart {
          display: inline-block;
          margin-left: -4px;
          line-height: 49px;
          vertical-align: top;
          animation: (pulse, 1.5s, ease-out, 0s, infinite);
          padding-left: 10px;
          padding-right: 10px;
          /* mobile first for mobiles */
          @include responsive(desktop){
            padding-left: 20px;
            padding-right: 20px;
          }
          img {

            @include responsive(desktop){
                  height: 58px;
              }
          }
      }
      /* ~./Heart Animation */


      /* Privacy Text  */
      &__privacy{
          margin:20px 0 20px 0;
          font-size: 15px;
          text-align: center;
          i{
              margin-right: 10px;
          }
      }
      /* ~./Privacy Text */

      /* Attachement Text  */
      &__attachement-text{
          // font-size: 16px;
          // display: block;
          // color: #878787;
          a{
              color: #4492DC;
              text-decoration: underline;
              &:hover{
                  text-decoration: none;
              }
          }
          label{
              color: #4492DC;
              text-decoration: underline;
              cursor: pointer;
              &:hover{
                  text-decoration: none;
              }
          }
      }
      /* ~./Attachement Text */

      /* Contact Form Footer  */
      &--contact-form-footer {
          //background: $light-grey;
          background-color: #f2f2f2;
          padding: 40px 0;
          margin-top: 60px;

          ul {

            li {
              display: inline-block;
              font-size:16px;
              color:#000;
              margin:0 30px;
              i {
                  margin-right: 20px;
              }
              strong {
                font-weight: 700;
              }
            }
          }

          a{
              color: #000;
              &:hover{
                  text-decoration: underline;
              }
          }
          .o-clients{
            padding: 30px 0 0 0;
          }
          // i {
          //     width: 68px;
          //     height: 68px;
          //     position: relative;
          //     display: inline-block;
          //     vertical-align: middle;
          //     margin-right: 20px;
          //     img {
          //         display: block;
          //         position: absolute;
          //         z-index: 10;
          //         top: 50%;
          //         left: 50%;
          //         @include prefixed-transform (translate(-50%, -50%));
          //     }
          //     &:before {
          //         content: '';
          //         display: block;
          //         width: 100%;
          //         height: 100%;
          //         background-size: contain;
          //         background-repeat: no-repeat;
          //         background-position: 50% 50%;
          //         position: absolute;
          //         top: 0;
          //         left: 0;
          //         z-index: 5;
          //         background-image: url('../../assets/images/m-contact-form/icon-circle.svg');
          //         @include prefixed-animation (spin, 10s, linear, 0s, infinite);
          //     }
          // }
      }
      /* ~./Contact Form Footer */

      /* PSD to HTML Place your order form */

      &--place-order{
          .tagline{
            font-size: 12px;
          }

          .attach {
              span{
                 vertical-align:top;
              }
          }
      }

      /* ~./ PSD to HTML Place your order form */
  }

  .common-input.u-message {
      background: url(/images/common/email-icon.png) 0 20px no-repeat;
  }

  .form-inside {
    padding-top: 0;
    @include responsive(desktop){
      padding-top: 30px;
    }
  p {
      font-size: 16px;
      color: #333333;
      padding-bottom: 5px !important;
      line-height: 28px;
      margin-top: 15px;
      @include responsive(desktop){
          margin-top: 0;
          font-size: 18px;
          line-height: 30px;
      }
          a {
              color: #4a90e2;
          }
      }
  }
  .bnr-form {
      .group input[type="text"] {
      border-bottom: 1px solid #C9C8C8;
      padding: 20px 20px 20px 40px;
      color: #333;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;
  }
  input {
    border: none;
    border-bottom: 1px solid #000 !important;
    @include responsive(desktop){
      border-bottom: 1px solid #C9C8C8;
    }
  }
  .u-name {
      border-bottom: 1px solid #C9C8C8;
      padding: 20px 20px 20px 40px;
      color: #333;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;
  }
  .u-email {
      // border-bottom: 1px solid #C9C8C8;
      padding: 20px 20px 20px 40px;
      color: #333;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;
  }
  .u-company {
      // border-bottom: 1px solid #C9C8C8;
      padding: 20px 20px 20px 40px;
      color: #333;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;
  }
  .u-message.bnr-msg {
      border-bottom: 1px solid #000;
      margin-bottom: 10px;
      position: relative;
      font-weight: 400;
      @include responsive(desktop){
        border-bottom: 1px solid #C9C8C8;
      }
  }
  .u-message.bnr-msg textarea {
      background: url("/images/common/form-icon-5.png") 0 20px no-repeat;
      padding: 20px 20px 20px 40px;
      color: #333;
      min-height: 100px;
      width: 100%;
      margin-bottom: 30px;
      border: none;
  }
  .attach {
      margin-top: 5px;
      margin-bottom: 15px;
      text-align: left;
  }
  .attach span {
      margin-left: 10px;
      display: inline-block;
  }
  .o-contact-form__attachement-text {
      font-size: 16px;
      color: #878787;
  }
  .attach span {
      margin-left: 10px;
      display: inline-block;
      font-size: 15px;
      margin-bottom: 5px;
      line-height: 20px;
      margin-top: 5px;
  }
  .attach {
      padding: 20px 25px 5px 25px;
  }
  }

  .o-contact-form__privacy {
      margin: 20px 0 20px 0;
      font-size: 15px;
      text-align: center;
      font-family: "Montserrat",sans-serif;
      font-weight: 400;
      color:#414446 !important;
      @include responsive(desktop){
        font-size: 17px;
      }
  }
  .u-message span#requirements_feedback {
      position: absolute;
      right: 25px;
      bottom: 12px;
      font-size: 14px;
      color: #333;
      font-weight: 300;
  }

  .small-heading {
      text-align: left;
      display: block;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 35px;
      padding-left: 15px;
      display: none;
      @include responsive(desktop){
        padding: 0;
        display: block;
      }
  }
  input, textarea {
      font-size: 16px;
  }
  .s-btn-modifier {
      width: 265px;
      @include responsive(desktop){
          width: 478px;
      }
  }
  .cntct-pg-section {
      background: #fff;
      padding-top: 20px !important;
      @include responsive(desktop){
        padding: 100px 20px 60px!important
      }
  }
  .new-contact {
    .o-contact-us {
      .col-md-6 {
        padding: 0;
        @include responsive(desktop){
          padding: 0 15px;
        }
      }
    }
    .u-submit.s-btn-modifier {
      max-width: 260px;
      @include responsive(desktop){
        max-width: 350px;
      }
    }
    .o-footer {
      padding: 0 0 40px;
      @include responsive(desktop){
        padding: 0 0 40px;
      }
    }
  }
  .cn-form {
      position: relative;
      background: #fff;
      &:before {
          content: '';
          position: absolute;
          background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
          height: 5px;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
      }
      &:after {
          content: '';
          position: absolute;
          background: url(/images/contact-us/dashed-line.png) 0 0 repeat;
          height: 5px;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          display: none;
          @include responsive(desktop){
            display: block;
          }
      }
      .row {
        .col-md-10 {
          margin: 0 auto;
        }
      }
  }


  .o-contact-us {
      padding-top: 0 !important;
      @include responsive(desktop){
        padding-top: 0 !important;
      }

  }
  .contact-addr .office ul{
      background: #fff;
  }

  .o-contact-form__contact-form {
      padding: 0 15px;
      @include responsive(desktop){
          padding: 0;
      }
  }
  .opt-phn {
      position: relative;
      input {
          // padding: 10px 0;
          background: transparent;
          width: 100%;
          font-size: 14px;
      }
  }
  .url-list {
      label {
          text-decoration: none;
          color: #333;
          font-size: 16px;
          cursor: default;
      }
  }
  .url-input {
      padding: 0;
      font-size: 16px;
      display: initial;
      display: initial;
  }
  .contact-modal .modal-content button#cloudFileAdd {
      background-color: #FD5900;
      color: #ffffff;
      font-size: 17px;
      line-height: 16px;
      height: 42px;
      padding: 10px 20px;
      text-transform: uppercase;
      display: block !important;
      width: 150px;
      margin: 0 0 0 !important;
      border-radius: 4px;
      cursor: pointer;
      float: right;
      font-weight: 600;
      text-align: left;
      &:after {
          content: '';
          display: inline-block;
          width: 15px;
          height: 13px;
          background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
          background-size: auto;
          background-size: 15px;
          margin-left: 10px;
          position: absolute;
          right: 10%;
          // top: 40%;
          top: 50%;
          transform: translateY(-50%);
      }
  }
  .contact-modal .modal-content button#cloudFileAdd, 
  .contact-modal .modal-content .cloudModalFooter button {
      background-color: #FD5900;
      color: #ffffff;
      font-size: 17px;
      line-height: 16px;
      height: 42px;
      padding: 10px 20px;
      text-transform: uppercase;
      display: block !important;
      width: 150px;
      margin: 0 0 0 !important;
      border-radius: 4px;
      cursor: pointer;
      float: right;
      font-weight: 600;
      text-align: left;
      &:after {
          content: '';
          display: inline-block;
          width: 18px;
          height: 13px;
          background: url("/images/common/arrow-white.png") 50% 50% no-repeat;
          background-size: auto;
          background-size: 15px;
          margin-left: 10px;
          position: absolute;
          right: 10%;
          // top: 40%;
          top: 50%;
          transform: translateY(-50%);
      }
  }

  .contact-h1 {
      padding-left: 0;
      padding-top: 0;
      position: relative;
      font-weight: 900;
      text-transform: none;
      // background: url(../../assets/images/contact-us/c-icon1.png) 0 0 no-repeat;
      padding-left: 40px;
      font-size: 42px !important;
      line-height: 61px;
      color: #333 !important;
      display: inline-block;
      letter-spacing: 2.5px;
      text-shadow: none;
      margin-bottom: 15px;
      .white-text {
          position: relative;
          color: #fff !important;
      }
      &:before {
          content: '';
          position: absolute;
          height: 70px;
          width: 105px;
          right: inherit;
          left: 105px;
          top: 0;
          @include responsive(desktop){
              right: 0;
              top: 0;
              left: inherit;
          }
      }
  }
  .drop-p {
      font-size: 18px !important;
      line-height: 33px;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: left;
      color: #333 !important;
      font-family: "Montserrat",sans-serif;
      font-weight: 600;
      margin-bottom: 0;
  }

.new-contact {
    .o-contact-us {

      /* media query for tablet landscape */
      @include responsive(desktop){
          padding-top: 50px;
          padding-bottom:20px;
      }

      .subheading{
        font-weight:600;
      }

      .office {
        margin-bottom: 30px;

        .main-img {
          position: relative;
          vertical-align: top;
          margin-bottom:20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include responsive(desktop){
              height: auto;
            }
          }

          h3{
            font-family: 'Montserrat', sans-serif;
            font-weight:700;
            font-size:24px;
            position:absolute;
            left:50%;
            top:56%;
            transform:translate(-50%, -50%);
            height:48px;
            color:#fff;
            /*text-shadow: 2px 2px #3e3d3d;*/
          }

          .location-icon {
            width:50px;
            height:50px;
            border-radius:50%;
            position:absolute;
            top:20px;
            right:20px;
            margin:auto;
            z-index:99;
            text-align:center;
            cursor:pointer;
            background:rgba(0,0,0,0.2);

            img{
              width: 16px;
              height: auto;
              position:absolute;
              left:50%;
              top:50%;
              transform:translate(-50%, -50%);
            }
            &:hover{
              background:rgba(255,88,0,0.2);
            }
          }

        }

        ul {
          padding-bottom: 30px;

          li {
            color: #000;
            font-size: 15px;
            font-weight:300;
            margin-bottom: 0;
            @include responsive(desktop){
              margin-bottom: 15px;
              font-size: 16px;
              margin: 0 0 25px 0;
            }
            img{
              margin-right: 10px;
              display: none;
              vertical-align: top;
              margin-top: 0;
              @include responsive(desktop){
                display: inline-block;
              }
            }
            .mailicon{
              width: 15px;
              padding-top: 3px;
              opacity: 0.5;
            }

            span{
              display: inline-block;
              width: 88%;
            }

            a {
              color: #4492DC;
              cursor: pointer;
              font-family: $main-font;
              @include responsive(desktop){
                color: #333;
              }
              &.email{
                color: #4492DC;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

      }



    }

    .o-contact-form--white-bg{
      input#phoneNumber{
        background: url(/images/common/icon-phone.png) 0 50% no-repeat;
        // padding-left: 0;
      }
    }

    .s-f-right{
      input#phoneNumber{
        background-image: none;
      }
    }

    .o-contact-form--white-bg {
      input.pc-phone {
        padding-left: 40px!important;
      }
    }

    .o-contact-us-new{
      h2{
        text-transform: none;
        // background: url(../../assets/images/contact-us/c-icon1.png) 0 0 no-repeat;
        padding-left: 40px;
        font-size: 42px !important;
        display: inline-block;
        padding-top: 30px;
      }
      .subheading{
        font-size: 22px;
        line-height: 38px;
        padding-top: 30px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        padding-right: 0;
        padding-bottom: 75px;
        @include responsive(desktop){
          padding-bottom: 120px;
          padding-right: 125px;
        }
        strong{
          font-weight: 700;
          color: #27c070;

        }
      }

      .o-contact-form--white-bg{
        background: url(/images/contact-us/dashed-line.png) 0 0 repeat-x;
        padding: 30px 0 50px!important;
        position: relative;
        background-color: #fff;
        box-shadow: none;
        border-bottom: 1px solid #EBEBEB;
        @include responsive(desktop){
          box-shadow: 0 0 20px rgba(0,0,0,0.2);
          border-bottom: 0;
          padding: 20px 0 40px!important;
        }
        .form-text{
          position: relative;
          padding: 0 15px;
          text-align: left;
          margin-bottom: 0;
          @include responsive(desktop){
            text-align: center;
            padding: 0 0 20px 0;
            margin-bottom: 25px;
          }
          &:after{
            content:"";
            position: absolute;
            left: 50%;
            bottom: 0;
            border-bottom: 1px solid #4A90E2;
            width: 200px;
            transform: translate(-50%, 0);
            width: 240px;
            display: none;
            @include responsive(desktop){
              display: block;
            }
          }
        }
      }



    }
    .ywg{
      @include responsive(desktop){
        padding: 70px 0 20px;
      }
        h2{
          text-transform: uppercase;
          font-size: 40px;
        }
        ul{
          li{
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            margin: 0 9px 55px 9px;
            vertical-align: top;
            font-weight:500;
            /* media query for tablet landscape */
            @include media-query(992px){
                 width: 22%;
                 display: inline-block;
                 text-align: center;
            }
            @include media-query(1200px){
              width: 23%;
            }
            img{
              float: none;
              margin: 0 auto;
              display: block;
              width: 50px;
              @include responsive(desktop){
                width: auto;
              }
            }
          }
        }
      }
    .addr-section {
      padding: 50px 0 0;
      @include responsive(desktop){
        padding: 75px 0 50px;
      }
    }
      .contact-addr{
        padding-bottom: 0;
        @include responsive(desktop){
          padding-top: 60px;
          padding-bottom: 40px;
        }
        h2{
          text-transform: inherit;
          font-size: 30px;
          font-weight: 900;
          margin-bottom: 35px;
          font-family: $main-font;
          position: relative;
          text-align: left;
          @include responsive(desktop){
            font-size: 48px;
            text-align: center;
            margin-bottom: 50px;
          }
          &:before {
            content: '';
            position: absolute;
            background: #49C684;
            height: 2px;
            width: 50px;
            bottom: -15px;
            left: 0;
            display: block;
            @include responsive(desktop){
              display: none;
              bottom: 0;
            }
          }
        }
        .office{
          box-shadow: none;
          overflow: hidden;
          margin-bottom: 40px;
          display: block;
          padding-bottom: 15px;
          margin-bottom: 15px;
          // border-bottom: 1px dashed #ccc;
          @include responsive(desktop){
            display: block;
            padding: 0;
            margin: 0 0 40px;
            box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.10);
            border-bottom: 0;
          }
          .main-img{
            margin-bottom: 0;
            width: 100%;
            height: auto;
            // flex: 0 0 85px;
            border-radius: 0;
            overflow: hidden;
            @include responsive(desktop){
              width: auto;
              height: auto;
              border-radius: 0;
            }
            a {
                width: 100%;
                height: 100%;
                display: block;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
          }
          ul{
            padding-bottom: 10px;
            padding-top: 15px;
            @include media-query(992px) {
              padding: 15px 15px 10px;
            }
            @include media-query(1200px) {
              min-height: 241px;
            }
            li{
              padding-left: 0;
              font-weight: 500;
            }
          }
          .office-left{
            float: left;
            width: 100%;
            // flex: 0 0 85px;
            // height: 85px;
            // border-radius: 50%;
            overflow: hidden;
            /* media query for tablet landscape */
            @include responsive(desktop){
                width: 32%;
                height: auto;
                border-radius: 0;
            }
          }
          .office-right{
            float: right;
            width: 100%;
            // padding: 0 0 0 25px;
            /* media query for tablet landscape */
            margin-top: 20px;
            @include responsive(desktop){
                width: 64%;
                padding: 30px 0 0 0;
                margin-top: 0;
            }
            .c-name {
              color: #000;
              font-weight: 500;
              font-size: 16px;
            }
            // h4{
            //   font-size: 30px;
            //   margin-bottom: 30px;
            //   font-family: $main-font;
            //   font-weight: 700;
            // }
            p{
              font-size: 17px;
              font-weight: bold;
              margin-bottom: 15px;
            }
            .addr {
                font-family: $main-font;
              font-size: 15px;
              line-height: 24px;
              font-weight: 500;
              float: left;
              width: 100%;
              background: none;
              padding-left: 0;
              /* media query for tablet landscape */
              margin-bottom: 0;
              @include media-query(992px){
                  width: 46%;
                  background: url(/images/contact-us/map.png) 0 5px no-repeat;
                  padding-left: 40px;
                  font-size: 14px;
                  line-height: 24px;
                  margin-bottom: 15px;
              }
              @include media-query(1200px){
                width: 54%;
                font-size: 17px;
                line-height: 27px;
              }
            }
            .contact-new {
                float: none;
                /* media query for tablet landscape */
                @include responsive(desktop){
                    margin-right: 50px;
                    float: right;
                }
                ul{
                  padding: 0;
                  li{
                    list-style-type: none;
                    padding-left: 0;
                    vertical-align: top;
                    font-size: 15px;
                    /* media query for tablet landscape */
                    @include responsive(desktop){
                      font-size: 16px;
                    }
                    // img{

                    // }
                  }
                }
            }
          }
        }
      }

      .blue-bg {
        background-color: #132a49;
        // background-image: url(images/contact-us/pattern.png);
        background-repeat: no-repeat;
        background-position: 0 100%;

        h2 {
          color: #fff;
          text-transform: inherit;
          font-weight: 600;
          font-family: $main-font;
          font-size: 24px;
          margin-bottom: 60px;
          @include responsive(desktop){
            font-size: 48px;
          }
       }
       li {
        span {
           color: #fff;
           font-family: $main-font;
           font-size: 16px;
           margin-top: 0;
           display: flex;
           flex: 1;
           justify-content: flex-start;
           line-height: 24px;
           padding-left: 20px;
           @include responsive(desktop){
            font-size: 18px;
            margin-top: 25px;
            display: block;
            padding-left: 0;
           }
        }
       }

      }
      .office-right {
       p {
        font-family: $main-font;
        font-size: 14px;
        &:before {
          display: none;
        }
       }
      }
      .office {
        h4 {
          font-size: 15px;
          margin-bottom: 5px;
          @include responsive(desktop){
            font-size: 17px;
            margin-bottom: 15px;
          }
          line-height: 27px;

        }
        p {
          position: relative;
          padding-left: 0;
          font-family: $main-font;
          margin: 0 0 0 0;
          @include responsive(desktop){
            padding-left: 35px;
            margin: 0 0 25px 0;
          }
          &:before {
            content: '';
            background: url(/images/contact-us/map.png) no-repeat 0 0;
            height: 50px;
            width: 30px;
            position: absolute;
            left: 0;
            top: 3px;
            background-size: 15px;
            display: none;
            @include responsive(desktop){
              display: block;
            }
          }
          br {
            display: none;
            @include responsive(desktop){
              display: block;
            }
          }
        }
        img {
          width: 22px;
          margin-right: 5px;
        }
        a:hover {
          text-decoration: none;
        }
      }

      .o-contact-us-new {
        h2 {
            padding-left: 0;
            padding-top: 0;
            position: relative;
            font-weight: 900;
            font-size: 40px;
            &:before {
              content: '';
              position: absolute;
              height: 70px;
              width: 105px;
              right: -6px;
              top: -7px;
              @include responsive(desktop){
                right: 0;
                top: 0;
              }
            }
             span {
              position: relative;
            }
            .white-text {
              color: #fff;
              text-shadow: none;
            }
        }
    }
    // .grp-logo {

    // }
    .o-contact-form {
      box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
      text-align: center;
      margin-top: 0;
    }
    .o-skills-hero__background.cntct-bnr {
      display: none;
      @include responsive(desktop){
        display: block;
      }
    }
    .gd-compny {
      text-align: center !important;
      color: #9B9B9B !important;
      font-size: 16px !important;
      text-transform: uppercase;
    }
    .o-contact-form__contact-form {
        @include responsive(desktop){
          text-align: center;
        }
    }
    .o-contact-us {
      position: relative;
      top: 0;
      z-index: 9;
      @include responsive(desktop){
        top: 0;
      }
    }
    .grp-logo {
      display: block;
      margin: 0 auto;
      @include responsive(desktop){
        display: inherit;
      }
    }

    // a.request-quote {

    // }

    .o-navigation-bar{
      #primaryNav{
        .request-quote{
             display: none!important;
          }
        }
      }
  }



  .logo-group {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: #f9f5f5;
      height: 100%;
      width: 1px;
      left: 50%;
    }
    &-sub {
      display: flex;
      border-bottom: 1px solid #f9f5f5;
      align-items: center;
      &-box {
        flex: 1;
        text-align: center;
        padding: 35px 15px;
      }
    }
    .no-border {
      border-bottom: 0;
    }
  }

  .cntct-bnr {
    position: absolute;
    height: 340px;
    display: none;
    @include media-query(992px){
      height: 390px;
      display: block;
    }
    @include media-query(1200px) {
      height: 340px;
    }
}

  .contact-h1 {
    padding-left: 0;
    padding-top: 0;
    font-size: 36px !important;
    line-height: 61px;
    color: #333 !important;
    display: block;
    text-shadow: none;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: $main-font;
    text-align: left;
    @include responsive(desktop){
        display: inline-block;
        text-transform: capitalize;
        padding-top: 0;
        position: relative;
        font-weight: 900;
        // background: url(/images/contact-us/c-icon1.png) 0 0 no-repeat;
        font-size: 42px !important;
        letter-spacing: 2.5px;
    }
  }

  .u-align-center {
    text-align: center;
  }
  .cntct-pg-section {
    @include responsive(desktop){
      padding: 100px 20px 60px!important;
    }
  }

  .u-submit.s-btn-modifier {
    display: block;
    text-align: center;
    border-radius: 5px;
    padding: 13px 0px;
    font-size: 14px;
    text-transform: uppercase;
    max-width: 350px;
    text-decoration: none;
    position: relative;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    border: 2px solid #FF5800;
    background-color: #FF5800;
    width: 100%;
    margin: 25px auto 0;
    @include responsive(desktop){
      width: 478px;
      font-size: 18px;
      padding: 19px 0px;
      margin: 0 auto;
    }
}
.modal-dialog {
    max-width: 600px!important;
}

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg {
    padding: 0 20px 7px 20px;
    background-color: #fff;
}

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group{
  position: relative;
}

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un:after,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue:after{
    border-bottom: 1px solid #C9C8C8;
    content: "";
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 5px;
  }
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .un input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .ue input,
.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .group input {
    padding: 10px 0;
    background: transparent;
    width: 100%;
    border: 0;
}

.quickform-holder .quickform .o-contact-form .o-contact-form--white-bg .c-btn--lg {
    margin: 0;
    border-radius: 5px;
    display: block;
    font-weight: 400;
    background: url(/images/common/contact-form-button.png) no-repeat 0 0;
    // background: #ff5800;
    border: 0;
    padding-left: 60px;
    padding-bottom: 10px;
    background-size: 100%;
    padding: 2px 0 6px 35px;
    line-height: 0;
    font-size: 15px;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive(desktop){
      height: 80px;
      font-size: 18px;
    }
}

.o-thank-you__top {
    max-width: 850px;
    margin: 0 auto;
    padding: 50px 0px 30px;
    border-bottom: 1px solid #E9E9E9;
    text-align: center;
}

body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg {
    max-width: 790px;
    margin: 60px auto;
    text-align: left;
    display: block;
    padding: 40px;
}
body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content {
    margin-bottom: 20px;
    padding: 10px;
}
body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label {
    display: block;
    text-align: left;
    font-family: "Montserrat",sans-serif;
    font-weight: 300;
    color: #414446;
    font-size: 16px;
    line-height: 28px;
}
body[data-page="thank-you"] .o-contact-form .o-contact-form--white-bg .fieldset-content label input {
    margin-right: 10px;
}
body[data-page="thank-you"] .attach{
  background-image: none;
  padding: 0;
}

body[data-page="thank-you"] .o-contact-form__attachement-text {
    font-size: 16px;
    display: block;
    color: #878787;
}
.o-thank-you__top h3 {
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 44px;
}

.o-contact-form h5 {
    font-size: 26px;
    line-height: 35px;
    font-weight: 500;
}

body[data-page="thank-you"] .u-name {
    background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
    border:0;
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
}
body[data-page="thank-you"] .u-email {
    background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
    border:0;
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
}
body[data-page="thank-you"] .u-phone{
    background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
    border:0;
    border-bottom: 1px solid #C9C8C8;
    padding: 20px 20px 20px 40px;
    color: #333;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
}
body[data-page="thank-you"] .u-message textarea {
    background: url(/images/common/icon-message.svg) 0 20px no-repeat;
    padding: 20px 20px 20px 40px;
    color: #333;
    min-height: 130px;
    width: 100%;
    margin-bottom: 15px;
    border:0;
}
body[data-page="thank-you"] .group select{
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  height: 50px;
  background-color: transparent;
  border:0;
  border-bottom: 1px solid #C9C8C8;
}

body[data-page="thank-you"] .c-btn{
    display: block;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat",sans-serif;
    font-weight: 600;
    color: #fff;
    background: #FF5800;
    border-radius: 4px;
    text-transform: uppercase;
    border: 2px solid #FF5800;
    line-height: 60px;
    font-size: 20px;
    padding: 0 24px;
    border-radius: 10px;
    width: auto;
    margin: 0 auto;
}

input {
  outline: none;
}

.mobile-off {
  display: none;
  @include responsive(desktop){
    display: block;
  }
}

.cu-lets-talk {
  background: #091B38;
  padding: 96px 25px 45px;
  @include media-query(992px) {
    padding: 45px 25px;
  }
  position: relative;
  text-align: left;
  &:before {
    content: '';
    position: absolute;
    top: 80px;
    right: 5px;
    background: url(/images/common/b-form-envelop.webp) no-repeat center center / 100%;
    width: 159px;
    height: 159px;
  }
  &:after {
    content: '';
    background: #091B38;
    width: 25px;
    height: 25px;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -12px;
    left: 35px;
    z-index: 9;
  }
  h3 {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    font-family: $main-font;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
    font-family: $main-font;
    padding-right: 40%;
    margin-bottom: 0;
    a {
      color: #36E079;
    }
  }
}

.row.bnr-form {
  padding: 0 25px;
  @include responsive(desktop){
    padding: 0;
  }
}

.msgg-colo {
  padding: 0;
  @include responsive(desktop){
    padding: 0 15px;
  }
}
.mobile-off-inline {
  display: none;
  @include responsive(desktop){
    display: inline-block;
  }
}
    .good-sec {
      padding: 30px 0 35px;
      @include responsive(desktop){
        padding: 20px 0 35px;
      }
      border-bottom: 1px solid #dedede;
      h5 {
        text-align: center;
        font-size: 14px;
        font-family: $main-font;
        font-weight: 600;
        margin-bottom: 20px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 360px;
        margin: 0 auto;
        li {
          padding: 10px;
        }
      }
      ul {
        li {
          img {
            filter: grayscale(0.9);
          }
        }
      }
    }

    .ind-ofc {
      @include media-query(992px) {
        height: auto;
      }
      @include media-query(1200px) {
        height: 282px;
      }
      min-height: auto;
    }

    .or-hd {
      font-size: 30px;
    margin-bottom: 30px;
    font-family: $main-font;
    font-weight: 700;
    }

    .logo-group-sub-box {
      img, svg {
        height: 45px;
        filter: grayscale(0.9);
      }
      .st-logo {
        height: 35px;
      }
    }
.fieldset-content
{
  label{
    cursor: pointer;
    margin-bottom: 4px;
    input{
      position: relative;
      top: 1px;
    }
  }
}

.attachment-list
{
  li{
    margin-right: 0px !important;
    width: 100%;
    
    input{
      border-radius: 0px;
      width: calc(100% - 25px) !important;
    }
    label{
      white-space: nowrap;
      width: calc(100% - 28px);
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 17px;
    }
  }
  // li.cloud-attach{
  //   display: flex !important;
  //   align-items: center;
  //   padding: 12px 15px !important;
  // }
}
.contact-thankyou-attachment
{
  li{
    border-radius: 30px;
    background: #fafafa;
    margin-bottom: 10px;
    padding: 8px 15px;
  }
}
.attach-icon
{
  display: inline-block;
  background: url('/images/common/icon-attachment.svg') 0 0 no-repeat;
  width:18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}

.cloudModalFooter
{
  button{
    border: none !important;
  }
}

.disabledAttachButton
{
  button{
    pointer-events: none;
    background-color: #ebb79b !important;
    opacity: .9;
  }
}

.new-quickform-holder {
  .new-slide-form {
    .o-contact-form {
      .o-contact-form--white-bg {
        input, 
        textarea {
          border-bottom: 1px solid #aaa !important;
          &::placeholder {
            color: #000;
            opacity: 1;
          }
        }
        .un {
            &:after {
              display: none;
            }
            .u-name {
              background: url(/images/common/form-icon-1.png) 0 50% no-repeat;
            }
          }
          .ue {
            &:after {
              display: none;
            }
            .u-email {
              background: url(/images/common/form-icon-2.png) 0 50% no-repeat;
            }
          }
          .new-b-a-call {
            background-image: none;
            background-color: #FC591F;
            border-radius: 11px;
            font-size: 14px;
            font-weight: 700;
            height: auto;
            line-height: 14px;
            padding: 13px 0;
            @include media-query(992px){
              padding: 20px 0;
              font-size: 17px;
            }
          }
          .cmn-left-pd-wrp {
            input {
              padding: 12px 15px 12px 30px;
              @include media-query(1280px){
                padding: 15px 15px 15px 30px;
              }
              @include media-query(1400px){
                padding: 20px 15px 20px 30px;
              }
              &.u-phone {
                background: url(/images/common/form-icon-3.png) 0 50% no-repeat;
              }
            }
          }
          .attach {
            background-position: 0 10px;
            padding: 0 20px 5px 30px;
            text-align: left;
            margin-bottom: 10px;
            margin-top: 5px;
          }
          .url-list {
            .comp-attach, 
            .cloud-attach {
              margin-bottom: 10px;
              // input, 
              // .attachedFileUrl {
              //   font-size: 12px;
              //   height: 25px;
              //   line-height: 12px;
              // }
              .attachedFileUrl {
                height: auto;
              }
              .attachedFile {
                border-bottom: none !important;
              }
            }
            li.cloud-attach,
            li.styled-list {
              // padding: 3px 15px !important;
              background: #f8f8f8;
              max-width: 100%;
              border-radius: 5px;
              position: relative;
              text-align: left;
              display: flex;
              align-items: center;
              overflow: hidden;
              height: 30px;
              border: 1px solid #d1d1d1;
              .removeAttachedFile2 {
                background: #f5f5f5;
                border: 0;
                margin-right: 0;
                padding: 0 0;
                position: absolute;
                right: 0;
                height: 100%;
                width: 50px;
                border-left: 1px solid #d1d1d1;
                img {
                  position: relative;
                  top: 0;
                  width: 15px;
                }
                &:hover {
                  background: #ff1c31;
                  img {
                    filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%);
                  }
                }
              }
              .attachedFileUrl {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 84%;
              }
              span {
                color: #333;
                font-weight: 400;
                font-size: 14px;
                flex: 0 0 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              i {
                margin-right: 10px;
                background: #f5f5f5;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #d1d1d1;
                height: 100%;
                img {
                  width: 20px;
                }
              }
            }
            .inp-wrapper {
              width: 0;
              height: 0;
              background: none;
              visibility: hidden;
              opacity: 0;
              padding: 0 !important;
              margin: 0 !important;
              .cloned {
                width: 0;
                height: 0;
                background: none;
                visibility: hidden;
                opacity: 0;
                padding: 0 !important;
                margin: 0 !important;
              }
            }
          }
          textarea {
            padding: 12px 20px 15px 30px;
            @include media-query(1280px){
              padding: 15px 20px 20px 30px;
            }
          }
          padding: 0 15px 7px 15px;
          @include media-query(992px){
            padding: 0 30px 7px 30px;
          }
        }
      }
  }

}

.new-quickform-holder {
  .big-girl {
    width: 377px;
    @include media-query(992px){
      left: 0;
      top: 43px;
    }
  }
  .o-contact-form--white-bg {
    box-shadow: none;
  }
  .s-f-left {
    padding: 30px 0 15px;
    @include media-query(992px){
      padding: 0;
    }
  }
  .add-computer-file-new {
    .comp-file {
      position: absolute;
      right: -9999px;
      visibility: hidden;
      opacity: 0;
    }
  }
}
.o-bottom-form,
.o-contact-form-contact-us {
  .url-list {
    li {
      text-align: left;
    }
    label {
      color: #000;  
      font-size: 13px !important;
    }
    &.add-height {
      @include media-query(992px){
        height: 65px;
      }
      @include media-query(1280px){
        height: 81px;
      }
      @include media-query(1400px){
        height: 115px;
      }
      @include media-query(1800px){
        height: 115px;
      }
      overflow-y: auto;
      scroll-behavior: smooth;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .attach {
    background-position: 0 10px;
    padding: 0 20px 5px 30px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .url-list {
    .comp-attach, 
    .cloud-attach {
      margin-bottom: 10px;
      // input, 
      // .attachedFileUrl {
      //   font-size: 12px;
      //   height: 25px;
      //   line-height: 12px;
      // }
      .attachedFileUrl {
        height: auto;
      }
      .attachedFile {
        border-bottom: none !important;
      }
    }
    li.cloud-attach,
    li.styled-list {
      // padding: 3px 15px !important;
      background: #f8f8f8;
      max-width: 100%;
      border-radius: 5px;
      position: relative;
      text-align: left;
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 35px;
      border: 1px solid #d1d1d1;
      .removeAttachedFile2 {
        background: #f5f5f5;
        border: 0;
        margin-right: 0;
        padding: 0 0;
        position: absolute;
        right: 0;
        height: 100%;
        width: 50px;
        border-left: 1px solid #d1d1d1;
        img {
          position: relative;
          top: 0;
          width: 15px;
        }
        &:hover {
          background: #ff1c31;
          img {
            filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2410%) hue-rotate(122deg) brightness(117%) contrast(101%);
          }
        }
      }
      .attachedFileUrl {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 84%;
      }
      span {
        color: #333;
        font-weight: 400;
        font-size: 14px;
        flex: 0 0 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      i {
        margin-right: 10px;
        background: #f5f5f5;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #d1d1d1;
        height: 100%;
        img {
          width: 20px;
        }
      }
    }
    .inp-wrapper {
      width: 0;
      height: 0;
      background: none;
      visibility: hidden;
      opacity: 0;
      padding: 0 !important;
      margin: 0 !important;
      .cloned {
        width: 0;
        height: 0;
        background: none;
        visibility: hidden;
        opacity: 0;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}
.o-contact-form-contact-us .url-list li.cloud-attach span {
  flex: 0 0 70%;
}

.attachedFile {
  width: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  right: -999999px;
}
.cmn-bnr-mod {
    padding-top: 102px !important;
    @include media-query(992px) {
        padding: 65px 20px 0!important;
    }
    h1 {
        font-size: 30px !important;
        line-height: 40px !important;
        margin-bottom: 15px !important;
        margin-top: 0 !important;
        @include media-query(992px) {
            font-size: 47px !important;
            line-height: 61px !important;
        }
    }
    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500 !important;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 36px !important;
            font-weight: 600 !important;
        }
    }
    .skill-page-mod {
        li {
            img {
                @include media-query(992px) {
                    padding: 20px 0 20px;
                }
            }
        }
    }
    .header_left_pan {
        @include media-query(992px) {
            padding-right: 20px !important;
            padding-top: 50px;
        }
        picture {
            display: inline-block;
            img {
                padding-bottom: 10px !important;
                margin-bottom: 20px !important;
                padding-top: 0 !important;
                height: 65px;
                width: auto;
                @include media-query(992px) {
                    margin-bottom: 0 !important;
                    margin-top: 0 !important;
                    padding-bottom: 25px !important;
                    height: 85px;
                }
            }
        }
    }
    .skill-page-mod {
        li {
            display: flex;
            justify-content: center;
            flex: 1 !important;
            &:first-child {
                @include media-query(992px) {
                    padding-left: 0 !important;
                }
            }
        }
    }
    .schedule-box {
        margin-top: 0;
        height: 100%;
    }
    .schedule-box-top {
        padding-bottom: 0;
    }
    .schedule-box-top-text {
        h2 {
            @include media-query(1200px) {
                font-size: 23px!important;
                line-height: 35px;
            }
        }   
    }
    .header_right_pan {
        background: none;
        padding: 0 15px;
        margin-top: 0;
        h3 {
            @include media-query(992px) {
                font-size: 21px!important;
                line-height: 35px;
                padding-bottom: 0;
                font-weight: 600;
            }
        }
    }
}
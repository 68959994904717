.choose-offshore {
    h2 {
        @include media-query(992px) {
            margin-bottom: 30px;
        }
    }
    .col-lg-3 {
        margin-bottom: 30px;
    }
    .o-key-diff-box {
        border: none;
        margin: 20px 0 0 0;
        position: relative;
        .choose-num {
            display: inline-block;
            @include media-query(992px) {
                position: absolute;
            }
            left: 25px;
            top: -30px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #4F8DE9;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        h4 {
            font-size: 20px;
            line-height: 27px;
            font-weight: 600;
        }
    }
}

.fixed-engagement-model-dev {
    .profile-tabs {
        h6 {
            font-size: 14px;
            color: #CBE3FF;
            font-weight: 300;
            position: relative;
            top: -15px;
            @include media-query(992px) {
                padding: 30px 0 20px;
            }
        }
        .tab-profile-content {
            div {
                opacity: 1;
            }
        }
        .tab-profile-header {
            display: none;
            @include media-query(992px) {
                display: block;
            }
            ul {
                position: relative;
                @include media-query(1200px) {
                    max-width: 825px;
                    margin: 0 auto;
                }
                &::before {
                    content: '';
                    position: absolute;
                    background: #4F8DE9;
                    opacity: .6;
                    width: 100%;
                    height: 3px;
                    bottom: 0;
                }   
                li {
                    padding: 0;
                    align-items: flex-start;
                    img {
                        position: relative;
                        top: 5px;
                    }
                    span{
                        @include media-query(992px) {
                            padding: 0 15px 20px 60px;
                        }
                        display: inline-block;
                        width: 100%;
                        color: #61F38E;
                        font-size: 20px;
                        &:before {
                            background: #3CC065;
                            width: 100%;
                            bottom: -1px;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            top: 5px;
                            left: 20px;
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-size: contain;
                            width: 30px;
                            height: 50px;
                            display: none;
                            @include media-query(992px) {
                                display: block;
                            }
                        }
                        &.flag1 {
                            &:after {
                                background-image: url(images/offshore-web-development/flag1.png);
                            }
                        }
                        &.flag2 {
                            &:after {
                                background-image: url(images/offshore-web-development/flag2.png);
                            }
                        }
                        &.flag3 {
                            &:after {
                                background-image: url(images/offshore-web-development/flag3.png);
                            }
                        }
                        &.flag4 {
                            &:after {
                                background-image: url(images/offshore-web-development/flag4.png);
                            }
                        }
                        small {
                            display: block;
                            color: #fff;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        border-radius: 15px;
        padding: 0 0 0;
        background: none;
        @include media-query(992px) {
            padding: 30px 20px 0;
            background: rgba(0,0,0,0.1);
        }
    }
    &.dapp-tech-stack {
        padding: 40px 0 20px;
        @include media-query(992px) {
            padding: 65px 0 40px;
        }
        .fixed-engagement {
            max-width: 100%;
            margin-bottom: 0;
        }
        .tab-profile-content-area {
            margin-top: 0;
            &.active {
                padding-bottom: 0;
                @include media-query(992px) {
                    margin-top: 30px;
                }
                @include media-query(1200px) {
                    
                    padding: 20px 0 0;
                }
            }
        }
        .fixed-engagement-row {
            &-left {
                @include media-query(992px) {
                    padding: 32px 10px 32px 25px;
                }
            }
        }
        .fixed-engagement-row-top {
            .fixed-engagement-right {
                margin-bottom: 0;
                .fixed-engagement-col {
                    h5 {
                        font-size: 16px;
                        padding: 10px 12px;
                        @include media-query(992px) {
                            font-size: 18px;
                        }
                    }
                }
                .col-content-box {
                    pointer-events: none;
                    &-top {
                        h5 {
                            &::before {
                                background-image: none;
                                background-color: #333;
                                width: 15px;
                                height: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left.fixed-engagement-row-left-new-bg {
    background: rgba(203, 227, 255, 0.1);
    h5 {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
    }
}

.fixed-engagement-model.new-fixed-engagement-model.fixed-engagement-model-dev.dapp-tech-stack .fixed-engagement-row-left {
    flex: 0 0 19%;
    background: #CBE3FF;
    border-bottom: 1px solid #474D5A;
    h5 {
        color: #333333;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.pricing-new-table {
    background: #fff;
    tr {
        th {
            &:first-child {
                background: #CBE3FF;
            }
            border-bottom: 1px solid #ccc;
            padding: 15px;
            background: rgba(0,0,0,.1);
        }
        td {
            padding: 15px;
            vertical-align: middle;
            border-right: 1px solid #ccc;
            &:last-child {
                border-right: 0;
            }
        }
    }
    thead {
        tr {
            th {
                border-bottom: 1px solid #ccc !important;
                border-right: 1px solid #ccc;
                &:last-child {
                    border-right: 0;
                }
                &:first-child {
                    background: rgba(0,0,0,.1);
                }
            }
        }
    }
}
.no-border-mod {
    .o-key-diff-box {
        border-width: 0;
        @include media-query(992px) {
            border-left-width: 5px;
            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
        }
    }
}

/* width */
.table-responsive::-webkit-scrollbar {
    width: 1px;
  }
  
  /* Track */
  .table-responsive::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .table-responsive::-webkit-scrollbar-thumb {
    background: transparent; 
  }
  
  /* Handle on hover */
  .table-responsive::-webkit-scrollbar-thumb:hover {
    background: transparent; 
  }

  .th-flex {
    display: flex;
    align-items: center;
    i {
        display: inline-block;
        height: 27px;
        width: 27px;
        margin-right: 5px;
        position: relative;
        top: 2px;
        img {
            height: 100%;
            width: 100%;
        }
    }
  }
  .role-class {
    position: relative;
    top: -3px;
  }

  .experts-mod {
    .o-key-diff-box {
        background: #fff;
        @include media-query(992px) {
            padding: 35px 85px 35px 35px;
        }
        p {
            @include media-query(992px) {
                font-size: 24px;
                line-height: 40px;
            }
        }
    }
  }

  .hire-web-developers-ser {
    // .new-service-section-box {
    //     page-break-inside: inherit;
    // }
    .new-service-section-wrapper {
        @include media-query(992px) {
            margin-bottom: 30px;
        }
    }
  }
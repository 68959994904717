.django-tech-stack {
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 70px 0 80px;
    }
    h2 {
        font-weight: 600;
        @include media-query(992px) {
            margin-bottom: 20px;
        }
    }
    &-wrapper {
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                text-align: center;
                @include media-query(992px) {
                    width: 12.5%;
                }
                padding: 0 10px;
                margin-top: 40px;
                span {
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 10px;
                    display: block;
                }
                i {
                    display: inline-block;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    width: 100px;
                    margin: 0 auto;
                    @include media-query(992px) {
                        height: 60px;
                        width: 80px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    &.stack-img-mod {
                        width: 100px;
                    }
                    &.open-api {
                        position: relative;
                        left: 5px;
                    }
                }
            }
            .slick-dots {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                li {
                    padding: 0;
                    margin-top: 0;
                }
            }
        }
    }
}


/*For contact us page*/
// .new-contact {
//     .col-4.col-lg-2.order-lg-3.p-0.justify-content-end.d-flex.align-items-center {
//         display: none !important;
//       }
//     .col-2.col-lg-7.px-0.order-lg-2.o-menu-wrap.justify-content-end {
//         flex: 1;
//     }
//     #nav-icon4 {
//         margin-right: 15px;
//     }
// }
/*For contact us page*/


.django-tech-stack-slider {
    .owl-dots {
        margin-bottom: 0;
    }
}

.tech-stack-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 
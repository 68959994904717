.at-pan-1{
    background-color: #fff;
    padding: 40px;
    border: 1px solid #C5C5C5;
    box-shadow: 0px 0px 34px 0px #0000001c;
    margin-bottom: 30px;
    border-radius: 10px;
    text-align: left;
    vertical-align: top;
    @include responsive(desktop){
        display: flex;
        align-items: flex-start;
    }
    h3{
        text-align: left;
    }
    p{
        text-align: left;
    }
    img{
        margin-right: 20px;
        margin-bottom: 20px;

    }
    ul{
        li{
            width: 100%;
            background: url(/images/common/green-tick.svg) no-repeat 0 5px;
            padding: 0 0 10px 30px;
            font-size: 18px;
            margin-right: 20px;
            @include responsive(desktop){
                width: 30%;
                float: left;
            }
        }
    }
}

.testing-tool-pan{
    border-top: 1px solid #ccc;
    padding: 20px 0;
    font-size: 18px;
    p{
        font-weight: 500;
    }
    ul{
        display: flex;
        flex-wrap:wrap;
        li{
            display: flex;
            margin-right: 20px;
            vertical-align: middle;
            font-size: 30px;
            align-self: center;
        }
    }
}
.testing-process{
    display: flex;
    flex-wrap:wrap;
    margin-bottom: 40px;
    overflow: hidden;


    .arrow_box:after, .arrow_box:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .tp-pan-left{
        display: flex;
        flex:100%;
        @include responsive(desktop){
             flex:45%;
        }
        div{
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            padding: 20px 40px;
            background: #fff;
            border: 1px solid #E3E3E3;
            border-radius: 10px;
            min-height: 100px;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            align-self: flex-start;
            margin-top: 20px;
            @include responsive(desktop){
                width: 80%;
                margin-top: 0;
            }
            &:after,
            &:before {
                left: 100%;
                top: 30px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after {
                border-color: rgba(136, 183, 213, 0);
                border-left-color: #fff;
                border-width: 15px;
                margin-top: -15px;
            }
            &:before {
                border-color: rgba(194, 225, 245, 0);
                border-left-color: #E3E3E3;
                border-width: 16px;
                margin-top: -16px;
            }
             span{
                background-color: #036497;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                left: 10px;
                top: 10px;
                position: absolute;
                content: "";
                text-align: center;
                color: #fff;
                line-height: 30px;
                font-size: 12px;
                @include responsive(desktop){
                    left: 454px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 17px;

                }
            }

            img{
                margin-right: 10px;
                margin-left: 20px;
                width: 43px;
                @include responsive(desktop){
                    margin-right: 30px;
                    margin-left: 0
                }
            }
            p{
                text-align: left;
                flex:1;
                margin-bottom: 0;
            }
        }
    }
    .tp-pan-right{
        display: flex;
        flex:100%;
        justify-content: flex-end;
        @include responsive(desktop){
            flex:45%;
        }
        div{
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            padding: 20px 40px;
            background-color: #fff;
            border: 1px solid #C5C5C5;
            border-radius: 10px;
            min-height: 100px;
            position: relative;
            margin-top: 20px;
            text-align: right;
            width: 100%;
            display: flex;
            align-items: center;
            align-self: flex-start;
            @include responsive(desktop){
                width: 80%;
                margin-top: 80px;
            }
            &:after,
            &:before {
                right: 100%;
                top: 30px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after {
                border-color: rgba(136, 183, 213, 0);
                border-right-color: #fff;
                border-width: 15px;
                margin-top: -15px;
            }
            &:before {
                border-color: rgba(194, 225, 245, 0);
                border-right-color: #E3E3E3;
                border-width: 17px;
                margin-top: -17px;
            }
            span{
                background-color: #036497;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                left: 10px;
                top: 10px;
                position: absolute;
                content: "";
                text-align: center;
                color: #fff;
                line-height: 30px;
                font-size: 12px;
                @include responsive(desktop){
                    left: -114px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 17px;

                }
            }
            img{
                margin-right: 10px;
                margin-left: 20px;
                width: 43px;
                @include responsive(desktop){
                    margin-right: 30px;
                    margin-left: 0
                }
            }
            p{
                text-align: left;
                flex:1;
                margin: 0;
            }
        }
    }
    .tp-middle-line{
        background-color: #ccc;
        width: 4px;
        height: 58%;
        position: absolute;
        left: 50%;
        display: none;
        @include responsive(desktop){
            display: block;
        }
    }
}


.software-test{
    ul{
        li{
            background: url(/images/common/green-tick.svg) no-repeat 0 5px;
            padding: 0 0 10px 30px;
            font-size: 18px;
            margin-right: 20px;
        }
    }
}

.o-why-choose-cn {
    background: none;
    padding-top: 50px;
    @include responsive(desktop){
        background: url(/images/home/why-choose-cn.jpg) no-repeat 50% 50%;
        color: $white-color;
        padding-top: 0;
        background-size: cover;
    }
    &_left{
        padding: 30px 15px 40px 25px;
        border-radius: 5px;
        margin: 0 12px;
        background-color: $dark-blue-color;
        width: auto;
        flex: 1;
        position: relative;
        @include responsive(desktop){
            width: 40%;
            float: left;
            min-height: 200px;
            padding: 60px 25px 65px 40px;
            margin: 0 0 0 15px;
            border-radius: 5px 0 0 5px;
            flex: inherit;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 25px;
            margin: 0 auto;
            display: block;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            background: #1d2942;
            @include responsive(desktop){
                display: none;
            }
        }
        h2, 
        h3{
            font-size: 24px;
            line-height: 30px;
            text-align: left;
            text-transform: uppercase;
            padding: 0 0 0 0;
            @include media-query(992px) {
                font-size: 48px;
                line-height: 60px;
            }
            @include media-query(1200px) {
                font-size: 56px;
                padding: 0 30px 0 15px;
            }
            span{
                font-size: 20px;
                text-transform: capitalize;
                line-height: 24px;
                display: block;
                padding-bottom: 5px;
                @include responsive(desktop){
                    font-size: 34px;
                    padding-bottom: 30px;
                    display: block;
                    line-height: 14px;
                }
            }
            &:before{
                right: inherit;
                left: 0;
                bottom: -15px;
                width: 90px;
                height: 2px;
                content: "";
                position: absolute;
                background-color: #14DF7D;
                @include responsive(desktop){
                    right: inherit;
                    left: 20px;
                    bottom: -25px;
                    width: 175px;
                }
            }
        }
    }
    .o-home-cn-details_box1 {
        width: auto;
        flex: 1;
        margin-left: 5px;
        margin-right: 15px;
        margin-bottom: 0;
    }
    &_right{
        display: none;
        @include responsive(desktop){
            display: block;
            width: 58%;
            float: left;
            background-color: #075091;
            padding: 65px 135px 65px 100px;
            h3, 
            p{
                color: $white-color;
                font-weight: 600;
                @include responsive(desktop){
                    font-size: 24px;
                    line-height: 40px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
            .btn{
                margin: 0;
            }
            .anchor-link{
                text-align: left;
                color: $white-color;
            }
        }
    }

}

.o-home-cn-details_details-box{
    @include border-radius(5px);
    margin-bottom: 1%;
    margin-right: 1%;
    // background-size: cover !important;
    float: left;
    position: relative;
    overflow: hidden;
    a{
        display: block;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        position: relative;
        @include responsive(desktop){
            min-height: 225px;
        }
        &:hover{
            transform: scale(1.1);
        }
    }
}
.o-home-cn-details_box1 {
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    @include responsive(desktop){
        width: 40%;
        margin-bottom: 1%;
    }
    a{
        text-align: center;
        // padding: 33px 0 33px 100px;
        padding: 100px 0 20px 0;
        @include responsive(desktop){
            padding: 70px 0 0 160px;
            min-height: 225px;
            text-align: left;
        }
        &:before{
            // position: absolute;
            // content: "";
            // background: url(/images/home/home-sprite.png) no-repeat;
            // width: 74px;
            // height: 76px;
            // transform: translate(0);
            // left: 15px;
            // top: 25px;
            // margin: 0;
            // background-size: 73%;
            position: absolute;
            content: "";
            background: url(/images/home/details-icon-1.png) no-repeat 50% 50%;
            width: 74px;
            height: 76px;
            transform: translate(0, -50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 15px;
            transform: translate(0);
            @include responsive(desktop){
                left: 50px;
                top: 65px;
                content: "";
                // background: url(/images/home/details-icon-1.png) no-repeat 50% 50%;
                width: 74px;
                height: 76px;
                margin: 0;
            }
        }
        h4{
            font-family: $main-font;
            @include responsive(desktop){
                font-family: $main-font;
            }
        }
    }
}
.o-home-cn-details_box2{
    min-height: 155px;
    width: 100%;
    background: url(/images/home/details-bg-1.jpg) no-repeat;
    margin-bottom: 10px;
    background-size: cover !important;
    @include responsive(desktop){
        width: 38%;
        min-height: 225px;
        margin-bottom: 1%;
    }
    a{
        padding: 100px 0 20px 0;
        color: $white-color;
        font-family: $main-font;
        @include responsive(desktop){
            font-family: $main-font;
            padding-top: 140px;
            font-size: 16px;
        }

        span{
            // width: 55px;
            // height: 55px;
            // position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            // background: url(/images/home/home-sprite.png) no-repeat;
            // background-position: -817px -306px;
            // }
            width: 40px;
            height: 40px;
            position: absolute;
            left: 50%;
            top: 50%;
            @include responsive(desktop){
                width: 55px;
                height: 55px;
                top: 47%;
                background-size: 100%;
            }
            transform: translate(-50%, -50%);
            background: url(/images/home/play-button.png) no-repeat 50% 50%;
            background-size: 40px;
            &:hover {
                background: url(/images/home/play-button-hover.png) no-repeat 50% 50%;
            }
    }
}
}

.o-home-cn-details_box3 {
    width: 48%;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #009C52;
    @include responsive(desktop){
        width: 19%;
        min-height: 225px;
        margin-bottom: 15px;
    }
    a{
        text-align: left;
        padding: 80px 15px 20px 15px;
        color: $white-color;
        @include responsive(desktop){
            padding: 80px 0 0 50px;
        }
        &:before{
            position: absolute;
            content: "";
            background: url(/images/home/details-icon-3.png) no-repeat 50% 50%;
            width: 74px;
            height: 76px;
            transform: translate(0);
            left: 5px;
            right: inherit;
            top: 10px;
            margin: 0;
            background-size: 62%;
            @include responsive(desktop){
                width: 38px;
                height: 38px;
                left: 50px;
                top: 40px;
                background-size: 100%;
            }
        }
        h4{
            font-family: $main-font;
        }
    }
}

.o-home-cn-details_box4 {
    width: 48%;
    margin-right: 0;
    margin-bottom: 25px;
    background-color: #009C52;
    @include responsive(desktop){
        width: 19%;
        min-height: 225px;
        margin-right: 10px;
    }
    a{
        text-align: left;
        // padding: 70px 15px 20px 15px;
        padding: 83px 15px 20px 15px;
        @include responsive(desktop){
            padding: 90px 0 0 30px;
        }
        &:before{
            position: absolute;
            content: "";
            background: url(/images/home/details-icon-4.png) no-repeat 50% 50%;
            width: 74px;
            height: 76px;
            transform: translate(0);
            left: 15px;
            right: inherit;
            top: 20px;
            margin: 0;
            background-size: 73%;
            @include responsive(desktop){
                left: 30px;
                top: 23px;
                background-size: 100%;
            }
        }
    }
}

.o-home-cn-details_box5 {
    width: 100%;
    background: url(/images/home/details-bg-2.jpg) no-repeat;
    text-align: center;
    margin-bottom: 10px;
    background-size: cover !important;
    @include responsive(desktop){
        width: 38%;
        min-height: 225px;
        margin-bottom: 1%;
        // background-size: inherit;
    }
    a{
        padding: 90px 0 30px 0;
        @include responsive(desktop){
            padding: 85px 0 0 110px;
        }
        &:before{
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 40px;
            transform: translate(0);
            content: "";
            background: url(/images/home/details-icon-8.png) no-repeat 0 0;
            width: 41px;
            height: 41px;
            @include responsive(desktop){
                width: 41px;
                height: 41px;
                left: 45px;
                top: 93px;
                margin: 0
            }
        }
        h5{
            font-size: 18px;
            @include responsive(desktop){
                text-align: left;
                font-size: 20px;
            }
            span {
                display: block;
                @include responsive(desktop){
                    display: inline;
                }
            }
        }
    }
}

.o-home-cn-details_box6{
    background-color: #4A90E2;
    width: 49%;
    margin-right: 0;
    @include responsive(desktop){
        width: 20%;
        min-height: 225px;
        margin-right: 1%;
    }
    a{
        text-align: left;
        // padding: 70px 15px 20px 15px;
        padding: 80px 15px 20px 15px;
        @include responsive(desktop){
            padding: 90px 0 0 50px;
        }
        &:before{
            content: "";
            background: url(/images/home/details-icon-6.png) no-repeat 0 0;
            width: 56px;
            height: 53px;
            left: 15px;
            right: inherit;
            top: 25px;
            position: absolute;
            @include responsive(desktop){
                left: 50px;
                top: 40px;
            }
        }
    }
}

.o-home-cn-details_box7{
    width: 49%;
    border: 1px solid #ccc;
    margin-right: 10px;
    @include responsive(desktop){
        width: 19%;
        min-height: 225px;
        margin-right: 1%;
    }
    a{
        text-align: left;
        padding: 92px 15px 20px 15px;
        @include responsive(desktop){
            padding: 95px 15px 20px 35px;
        }
        &:before{
            position: absolute;
            left: 15px;
            right: inherit;
            top: 25px;
            content: "";
            background: url(/images/home/details-icon-7.png) no-repeat 0 0;
            width: 140px;
            height: 65px;
            @include responsive(desktop){
                left: 35px;
            }
        }
    }
    h3 {
        margin-top: 7px;
        @include responsive(desktop){
            margin-top: 0;
        }
    }
}
.o-home-cn-details_details-box {
    h4 {
        font-size: 15px;
        font-family: $main-font;
        @include responsive(desktop){
            font-family: $main-font;
            font-size: 22px;
        }
    }
}
.o-home-cn-details {
    @include responsive(desktop){
        padding-top: 50px;
    }
    .btn-center-block {
        padding-top: 15px;
        @include responsive(desktop){
            padding-top: 15px;
        }
    }
}



.why-details {
    margin-bottom: 10px;
    @include responsive(desktop){
        margin-bottom: 32px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include responsive(desktop){
            justify-content: center;
        }
        li {
            flex: 1;
            max-width: inherit;
            margin: 6px 0;
            flex: 0 0 48%;
            max-width: 48%;
            // pointer-events: none;
            @include media-query(992px) {
                flex: 0 0 18.4%;
                max-width: 18.4%;
                padding: 30px 15px 0 15px;
                height: 225px;
                margin: 3px 3px;
            }
            @include media-query(1200px) {
                flex: 0 0 19.4%;
                max-width: 19.4%;
                padding: 30px 25px 0 35px;
            }
            padding: 17px 10px 20px 10px;
            border-radius: 5px;
            height: 150px;
            a {
                svg, img {
                    height: 35px;
                    width: auto;
                    @include responsive(desktop){
                        height: 50px;
                        width: auto;
                    }
                }
                i {
                    display: block;
                    height: 45px;
                    width: auto;
                    @include responsive(desktop){
                        height: 65px;
                        width: auto;
                    }
                }
            }
            svg, img {
                height: 35px;
                width: auto;
                @include responsive(desktop){
                    height: 50px;
                    width: auto;
                }
            }
            i {
                display: block;
                height: 45px;
                width: auto;
                @include responsive(desktop){
                    height: 65px;
                    width: auto;
                }
            }
            &:nth-child(1) {
                border: 1px solid #49C684;
                order: 1;
                @include responsive(desktop){
                    order: inherit;
                }
            }
            &:nth-child(2) {
                background: #4A90E2;
                order: 2;
                @include responsive(desktop){
                    order: inherit;
                }
            }
            &:nth-child(3) {
                background: #009C52;
                order: 3;
                @include responsive(desktop){
                    order: inherit;
                }
            }
            &:nth-child(4) {
                border: 1px solid #4A90E2;
                order: 4;
                @include responsive(desktop){
                    order: inherit;
                }
                a {
                    svg {
                        height: 30px;
                        position: relative;
                        top: 5px;
                        @include responsive(desktop){
                            height: 40px;
                            top: 8px;
                        }
                    }
                }
            }
            &:nth-child(5) {
                background: #4A90E2;
                order: 6;
                @include responsive(desktop){
                    order: inherit;
                }
                svg {
                    position: relative;
                    top: 5px;
                    @include responsive(desktop){
                        top: 0;
                    }
                }
            }
            &:nth-child(6) {
                flex: 1;
                max-width: inherit;
                background: #fff;
                padding-top: 25px;
                order: 9;
                border: 1px solid #4A90E2;
                @include media-query(992px) {
                    order: 7;
                    flex: 0 0 37.2%;
                    max-width: 37.2%;
                    padding-top: 25px;
                    padding-left: 30px;
                }
                @include media-query(1200px) {
                    flex: 0 0 39.3%;
                    max-width: 39.3%;
                }
                i {
                    height: auto;
                }
                a {
                    display: flex;
                    align-items: center;
                    svg, img {

                        // margin-top: 20px;
                        width: auto;
                        height: 85px;
                        padding-right: 20px;
                        @include responsive(desktop){
                            // margin-top: 45px;
                            width: 220px;
                            height: auto;
                            margin-right: 20px;
                        }
                    }
                    div {
                        &:nth-child(1) {
                            flex-shrink: 0;
                            @include media-query(520px) {
                                flex-shrink: inherit;
                            }

                        }
                    }
                }
                h5 {
                    padding: 0;
                    color: #333;
                }
                ul.inner-icon {
                    li {
                        flex: 25px!important;
                        max-width: 25px!important;
                        @include responsive(desktop){
                            flex: 35px!important;
                            max-width: 35px!important;
                        }
                        svg {
                            width: 20px;
                            @include responsive(desktop){
                                width: 30px;
                            }
                        }
                    }
                }
                h4 {
                    font-size: 22px;
                    line-height: 26px;
                    color: #333;
                    @include responsive(desktop){
                        font-size: 30px;
                        line-height: 40px;
                    }
                }
            }
            &:nth-child(7) {
                border: 1px solid #49C684;
                order: 5;
                @include responsive(desktop){
                    order: 9;
                }
                a {
                    svg {
                        height: 40px;
                        @include responsive(desktop){
                            height: 65px;
                        }
                    }
                }
            }
            &:nth-child(8) {
                background: #1D2942;
                order: 7;
                @include responsive(desktop){
                    order: 8;
                }
                a {
                    svg {
                        height: 40px;
                        position: relative;
                        top: 2px;
                        @include responsive(desktop){
                            height: 65px;
                            top: 0;
                        }
                    }
                }
            }
            &:nth-child(9) {
                order: 8;
                @include responsive(desktop){
                    order: 6;
                }
                border: 1px solid #009C52;
                a {
                    svg {
                        height: 40px;
                        @include responsive(desktop){
                            height: 65px;
                        }
                    }
                }
                h4 {
                    font-size: 15px;
                    @include media-query(992px) {
                        font-size: 18px;
                    }
                    @include media-query(1200px) {
                        font-size: 23px;
                    }
                }
                background: #009C52;
            }
            h4 {
                font-size: 24px;
                line-height: 30px;
                @include responsive(desktop){
                    font-size: 36px;
                    line-height: 36px;
                    padding: 5px 0 5px;
                }
                font-weight: 700;
                font-family: $main-font;
                margin-bottom: 2px;
                color: #fff;
                &.black-text {
                    color: #333;
                }
            }
            h5 {
                margin-bottom: 0;
                font-family: $main-font;
                font-size: 15px;
                @include responsive(desktop){
                    font-size: 18px;
                    font-family: $main-font;
                    font-weight: 600;
                }
                font-weight: 500;
                color: #fff;
                &.black-text {
                    color: #333;
                }
            }
            ul {
                justify-content: flex-start;
                @include responsive(desktop){
                    position: relative;
                    top: -8px;
                }
                li {
                    border: none !important;
                    padding: 0 !important;
                    background: none !important;
                    height: auto !important;
                    flex: 35px !important;
                    max-width: 35px !important;
                    margin: 3px !important;
                    a {
                        svg {
                            margin: 0 !important;
                        }
                    }
                }
            }
            .inner-icon {
                li {
                    svg {
                        margin: 0 !important;
                    }
                }
            }
            // &:nth-child(2n+1) {
            //     margin-left: 0;
            // }
            // &:nth-child(2n) {
            //     margin-right: 0;
            // }
        }
    }
}

.fact-section {
    // .mobile-container {
    //     padding: 0 20px !important;
    //     @include responsive(desktop){
    //         padding: 0 15px !important;
    //     }
    // }
    .why-details  {
        ul {
            li {
                &:nth-child(1) {
                    order: 1;
                    @include responsive(desktop){
                        order: inherit;
                    }
                }
                &:nth-child(2) {
                    order: 2;
                    @include responsive(desktop){
                        order: inherit;
                    }
                }
                &:nth-child(3) {
                    background: transparent;
                    border: 1px solid #4a90e2;
                    order: 4;
                    @include responsive(desktop){
                        order: inherit;
                    }
                }
                &:nth-child(4) {
                    border: 0;
                    background: #009c52;
                    order: 3;
                    @include responsive(desktop){
                        order: inherit;
                    }
                    svg {
                        position: relative;
                        top: 5px;
                        @include responsive(desktop){
                            top: 9px;
                        }
                    }
                }
                &:nth-child(5) {
                    order: 6;
                    @include responsive(desktop){
                        order: inherit;
                    }
                    svg {
                        top: 3px;
                        position: relative;
                        @include responsive(desktop){
                            top: 0;
                        }
                    }
                }
                &:nth-child(6) {
                    @include responsive(desktop){
                        order: 9;
                    }
                }
                &:nth-child(8) {
                    @include responsive(desktop){
                        order: 7;
                    }
                }
                &:nth-child(7) {
                    @include responsive(desktop){
                        order: 6;
                    }
                }
                &:nth-child(9) {
                    background: #009C52;
                    @include responsive(desktop){
                        order: 8;
                    }
                }
                a {
                    i {

                    }
                }
            }
        }
    }
}

.top-dev {
    .o-skills-hero {
        @include responsive(desktop){
            padding-bottom: 80px!important;
        }
    }
}

.section-footer {
  background-color: #04254a;
  padding: 40px 0px 30px;
  overflow: hidden;
  position: relative;
  @include media-query(992px) {
    // background-image: url(/images/new-home-banner/banner-gradient.png);
    // background-repeat: no-repeat;
    // background-position: 0 0;
    padding: 50px 0 30px;

    // &:after {
    //   content: "";
    //   width: 453px;
    //   height: 199px;
    //   background-image: url(/images/new-home-banner/banner-gradient2.png);
    //   background-repeat: no-repeat;
    //   background-position: 0 0;
    //   position: absolute;
    //   bottom: 0;
    //   right: -100px;
    //   z-index: 0;
    // }
  }

  &__navarea {
    padding-bottom: 30px;
    @media (min-width: 767px) {
      padding-bottom: 40px;
    }
  }
  &__navbar-heading {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 15px;
    &::after {
      content: " ";
      display: block;
      background: #3cc065;
      height: 2px;
      width: 31px;
      position: relative;
      margin-top: 5px;
    }
  }
  &__two-navbar-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__a-tag {
    color: #c4e0f5;
    font-size: 15px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 28px;
    transition: 0.3s all ease-in-out;
    &:hover {
      color: #fff;
    }
  }
  &__divider {
    background-color: #15467e;
    height: 1px;
    width: 100%;
    margin-bottom: 35px;
  }
  &__copy-right-details {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #aec7da;
    font-family: "Poppins";
    margin-bottom: 0px;
    margin-bottom: 15px;
    @media (min-width: 767px) {
      margin-bottom: 0px;
    }
    @include media-query(992px) {
      margin-bottom: 30px;
    }
  }
  &__social-list {
    display: flex;
    gap: 5px;
    position: relative;
    z-index: 1;
  }
  &__align-cener {
    display: flex;
    align-items: center;
  }
}

.c-core-element-wrap{
    border-bottom: 1px solid #e0e0e0;
    &__subheading{
        font-family: Poppins;
        font-size: 18px;
        line-height: 27px;
        color: #333;
        font-weight: 500;
        text-align: center;
        margin: 0 0 15px;
        @include media-query(992px) {
            font-size: 21px;
            line-height: 31px;
        }
        &--stext{
            font-size: 15px;
            margin: 0;
            line-height: normal;
            text-align: left;
        }
    }
   &__list{
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
   }
   &__item{
        width: 100%;
        padding:25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        border:1px solid #ddd;
        margin-bottom: 20px;
      
        
        @include media-query(992px) {
           width: 20%;

           border:none;
           margin-bottom: 30px;
           justify-content: center;

    }
   
}
&__border-bottom{
    position: relative;
    @include media-query(992px) {
        &:after{
           content:"";
           position: absolute;
           width: 80%;
           height:1px;
           background-color: #ddd;
           bottom:-15px;
           left: 0;
           right: 0;
           margin: 0 auto;
        }
        
    }
}
&__border-right{
    @include media-query(992px) {
        border-right:1px solid #ddd;
    }
}
}

.sample-work-wrap{
    h2{
        font-family: Poppins;
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
        color: #262e36;
        margin-bottom: 15px; 
        @include media-query(992px) {
            font-size: 48px;
            line-height: 58px;
            margin-bottom:40px;
     }
    }
    h5{
        font-family: Poppins; 
    }
}
.o-development-process{
    &.o-skill-service{
        .btn-auto-width {
            @include media-query(767px) {
                max-width: -webkit-fit-content;
                max-width: -moz-fit-content;
                max-width: fit-content
         }  
    }
    }
}
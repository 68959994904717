.iot-how-we-work {
    .d-trans-pro-wrapper {
        .d-trans-pro-steps {
            &:before {
                @include media-query(992px) {
                    height: 90%;
                }
            }
        }
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box5,
        .d-trans-pro-content-box6,
        .d-trans-pro-content-box7 {
            @include media-query(992px) {
                margin-top: 220px;
            }
        }
    } 
}


.leverage-section {
    padding: 50px 0 50px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    background: linear-gradient(150deg, rgba(3,128,112,1) 0%, rgba(0,81,127,1) 45%, rgba(2,62,102,1) 64%, rgba(5,27,57,1) 100%);
    h2 {
        margin-bottom: 25px;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
    }
    .o-key-diff-left {
        h3 {
            &:before {
                background-image: url(/images/iot/green-right-arrow.svg);
            }
        }
    } 
    .o-key-diff-box {
        width: 100%;
        background: #fff;
        ul {
            @include media-query(992px) {
                margin-top: 10px;
                padding-right: 15px;
            }
            li {
                font-size: 16px;
                line-height: 27px;
                padding: 0 0 5px 25px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
    .lts-discuss {
        text-align: center;
        @include media-query(992px) {
            margin-top: 50px;
        }
        h2 {
            font-size: 28px;
            line-height: 38px;
            @include media-query(992px) {
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 5px;
            }
        }
        p {
            font-size: 18px;
            line-height: 28px;
            max-width: 680px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}


.iot-case {
    padding: 40px 0 50px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    &-top {
        margin-bottom: 25px;
        text-align: center;
        @include media-query(992px) {
            margin-bottom: 50px;
        }
        h2 {
            text-align: center;
        }
        p {
            font-size: 20px;
            margin: 15px 0 0;
        }
    }
    &-wrapper {
        @include media-query(992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 805px;
            margin: 0 auto;
        }
        &-box {
            margin-bottom: 20px;
            @include media-query(992px) {
                width: 46%;
                margin: 0 15px 30px;
            }
            background: #fff;
            padding: 20px 15px;
            border-radius: 8px;
            border: 1px solid #E3E3E3;
            border-bottom-width: 8px;
            box-shadow: 0 5px 10px 5px rgba(0,0,0,0.05);
            @include media-query(992px) {
                padding: 35px 35px 30px 40px;
                min-height: 435px;
            }
            h3 {
                font-size: 20px;
                margin-top: 15px;
                margin-bottom: 10px;
                font-weight: 500;
                @include media-query(992px) {
                    font-size: 24px;
                }
            }
            ul {
                li {
                    font-size: 16px;
                    line-height: 27px;
                    padding: 0 0 10px 25px;
                    position: relative;
                    @include media-query(992px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 7px;
                        background: url(/images/dotnet/green-tick.svg) no-repeat 0 0;
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
        .border-color1 {
            border-bottom-color: #4A90E2;
        }
        .border-color2 {
            border-bottom-color: #49C684;
        }
        .border-color3 {
            border-bottom-color: #4A90E2;
            @include media-query(992px) {
                border-bottom-color: #49C684;
            }
        }
        .border-color4 {
            border-bottom-color: #49C684;
            @include media-query(992px) {
               border-bottom-color: #4A90E2;
            }
        }
    }
}













.tt-section {
    @include media-query(992px) {
        padding: 70px 0 50px;
    }
    .profile-tabs{
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        overflow: hidden;
        margin: 40px auto;
        border-radius: 5px;
        border: none;
        @include responsive(desktop){
            width: 93%;
            border: 1px solid #ddd;
        }
        .tab-profile-header{
            display: none;
            @include responsive(desktop){
                width: 28%;
                border-right: 1px solid #ddd;
                display: block;
            }
            ul{
    
                li{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 25px 15px;
                    border-left: 3px solid transparent;
                    font-size: 16px;
                    cursor: pointer;
                    font-weight: 500;
                    transition: .4s all ease-in-out;
                    @include media-query(992px) {
                        font-size: 18px;
                    }
                    &.active{
                        border-left: 3px solid #2DBE70;
                        background-color: #ECF9F9;
                    }
                    &:hover {
                        @include media-query(992px) {
                            background-color: #ECF9F9;
                            border-left: 3px solid #2DBE70;
                        }
                    }
                }
            }
        }
        .tab-profile-content{
            background-color: #fff;
            flex: 1;
            position: relative;
            width: 100%;
            @include responsive(desktop){
                width: 75%;
                background-color: #f2f2f2;
            }
            div{
                position: relative;
                opacity: 1;
                top: auto;
                left: auto;
                @include responsive(desktop){
                    position: absolute;
                    top: -50vh;
                    left: 100px;
                    opacity: 0;
                }
                
                
            }
            .tab-profile-content-area{
                width: 100%;
                .tab-profile-content-wrap, 
                .tab-profile-content-title{
                    position: static;
                    opacity: 1;
                }
                .tab-profile-content-title{
                    display: block;
                    background: #ecfaf9;
                    padding: 15px 20px;
                    margin-bottom: 10px;
                    @include responsive(desktop){
                        display: none;
                        padding: 10px 20px;
                    }
                    h3{
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        padding-right: 15px;
                        @include responsive(992px){
                            display: inherit;
                            padding-right: 0;
                        }
                        &::after{
                            content: "\f107";
                            font-family: 'Font Awesome 5 Free';
                            position: absolute;
                            font-weight: 600;
                            right: 0;
                        }
                    }
                }
                .tab-profile-content-wrap{
                    display: none;
                    @include responsive(992px){
                        display: block;
                    }
                }
                
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 10px;
                    @include media-query(992px){
                        margin-bottom: 0;
                    }
                    li{
                        width: 33.33%;
                        @include media-query(768px){
                            width: 20%;
                        }
                        @include media-query(992px){
                            width: 16.6%;
                        }
                        
                        a{
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            background: #fff;
                            padding: 10px;
                            border-radius: 5px;
                            border: 1px solid #e1dfe6;
                            max-width: 100%;
                            margin: 10px;
                            height: 75px;
                            -webkit-transition: all .4s linear;
                            transition: all .4s linear;
                            img{
                                width: 100%;
                                margin: 0 auto;
                                height: 100%;
                                object-fit: contain;
                                // &.img-resize{
                                //     width: 40%;
                                // }
                                // &.img-resize-new{
                                //     width: 50%;
                                // }
                                
                                // &.resize-img-new{
                                //     width: 60%;
                                // }
                                // &.resize-img{
                                //     width: 100%;
                                // }
                            }
                            &:hover{
                                box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
                            }
                        }
                    }
                }
                p{
                    margin-bottom: 0;
                    padding: 0 11px;
                    font-size: 16px;
                }
                &.active{
                    top: 0;
                    left: 0;
                    opacity: 1;
                    padding: 0;
                    background: #f2f2f2;
                    margin-bottom: 10px;
                    @include responsive(desktop){
                        padding: 20px;
                    }
                    .tab-profile-content-wrap{
                        display: block;
                    }
                    h3{
                        &::after{
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }
    }
}

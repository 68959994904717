//OFFER DETAILS
.custom-review{
  $custom-review : &;

  position: relative;

  &__spacing{
    padding: 30px 0 35px;
    @include media-query(992px) {
      padding: 60px 0 50px;
    }
    &--light{
      padding: 35px 0;
      @include media-query(992px) {
        padding: 50px 0;
      }
    }
  }
  &__tittleimg{
    display: block;
    text-align: center;

    @include media-query(992px) {
      text-align: left;
    }
  }
  &__tittle{
    position: relative;
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 35px;
    color: #262E36;
    padding-bottom: 20px;
    margin-bottom: 22px;
    font-weight: 700;
    text-align: center;
    @include media-query(770px) {
      margin-top: 10px;
    }
    @include media-query(992px) {
      text-align: left;
      font-size: 34px;
      line-height: 46px;
      // font-size: 51px;
      // line-height: 55px;
      margin-top: 25px;
      padding-bottom: 34px;
    }
    &:after{
      position: absolute;
      content: "";
      width: 83px;
      height: 7px;
      background-color: #498DF9;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      @include media-query(992px) {
        right: inherit;
        margin: 0;
      }
    }
  }
  &__subtittle{
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 27px;
    color: $black-color;
    font-weight: 600;
    margin: 0 0 15px;
    text-align: left;
    @include media-query(992px) {
      font-size: 22px;
      line-height: 32px;
      text-align: left;
      padding: 0 20px 0 0;
    }
  }
  &__reviewarea{
    position: relative;
    .owl-nav{
      display: block !important;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      @include media-query(992px) {
        float: right;
        margin-bottom: 0;
        margin-top: 10px;
        text-align: center;
      }
    }
    .splide__arrow--prev{
      font-size: 45px!important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin:0 5px;
      font-weight: 300 !important;
      background: none;
      @include media-query(992px) {
        width: 50px;
        height: 50px;
      }
      &:hover{
        color: $white-color !important;
        background-color: #3cc065 !important;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      span{
        position: relative;
        top: -3px;
        left: -1px;
        height: 50px;
        line-height: 50px;
      }
    }
    .splide__arrow--next{
      font-size: 45px!important;
      color: #3cc065 !important;
      width: 30px;
      height: 30px;
      border: 1px solid #3cc065 !important;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin:0 5px;
      font-weight: 300 !important;
      background: none;
      @include media-query(992px) {
        width: 50px;
        height: 50px;
      }
      &:hover{
        color: $white-color !important;
        background-color: #3cc065 !important;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      span{
        position: relative;
        top: -3px;
        right: -1px;
        height: 50px;
        line-height: 50px;
      }
    }
  }
  &__reviewitem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
  }
  &__reviewitems{
    max-width: 100%;
    width: 100%;
    min-height: 300px;
    height: auto;
    border:1px solid #ddd;
    padding: 25px;
    margin-bottom: 5px;
    transition: all .5s ease-in-out;
    display: flex;
    flex-direction: column;
    @include media-query(1024px) {
      max-width: 277px;
      margin-bottom: 15px;
    }
    @include media-query(1120px) {
      max-width: 330px;
    }
    @include media-query(1300px) {
      max-width: 345px;
    }
    &:hover{
      border:1px solid #fff;
      box-shadow: 0px 15px 20px 0px rgba(0,0,0,0.2);
    }
  }
  &__quoteimg{
    display: block;
    margin-bottom: 10px;
  }
  &__quotecontent{
    font-family: 'Poppins';
    color: $black-color;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }
  &__authorarea{
    margin-top: 30px;
    display: flex;
    @include media-query(992px) {
      margin-top: auto;
    }
  }
  &__authorimage{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__authordetails{
    margin-left: 16px;
  }
  &__authorname{
    font-family: 'Poppins';
    font-size: 18px;
    line-height: normal;
    color: $black-color;
    font-weight: 600;
    margin:0;
  }
  &__authordesignation{
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: $black-color;
    margin:0;
    font-weight: 600;
  }
  &__authorcompany{
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    color: #898989;
    margin:0;
    font-weight: 500;
  }
  &__btn{
    margin:0 auto;
    max-width: 360px;
    width: auto;
    font-family: 'Poppins';
    font-size: 17px;
    font-weight: 600;
    padding: 17px 25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-query(992px) {
      max-width: 333px;
      margin-left: 15px;
      width: 100%;
      margin: 0 0 0 14px;
    }
    svg{
      margin-left: 10px;
    }
    &:hover{
      g{
        fill: #ff5800;
      }
    }
  }
  &__partner{
    column-count: 2;
    margin:18px 0 25px;
  }
  &__partnerlist{
    min-height: 120px;
    margin: 0 20px 7px 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-query(992px) {
      margin: 0 30px 10px 0;
    }
    &:nth-last-child(-n+2){
      margin:0 30px 0 0;
    }
  }
  &__rankribbon{
    position: relative;
    font-family: 'Poppins';
    color: $white-color;
    font-size: 21px;
    line-height: 34px;
    font-weight: 700;
    max-width: 318px;
    width: 100%;
    text-align: center;
    margin: auto;
    background-color: #3CC065;
    padding: 5.5px;
    margin-bottom: 30px;
    @include media-query(992px) {
      margin-bottom: 0;
    }
    &:after{
      position: absolute;
      content: "";
      right: 0;
      bottom: -16px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 16px 30px 0 0;
      border-color: #4E6454 transparent transparent transparent;
      transform: rotate(0deg);
    }
  }
  .col-lg-8-rel {
    position: relative;
  }
  .sk-fading-circle {
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 120px;
    left: 0;
    right: 0;
    @include media-query(992px) {
      top: 239px;
    }
  }
  .cs-cont {
    &.invisible {
        height: 324px;
        @include media-query(992px) {
          height: 648px;
        }
    }
  }
  .splide__arrows {
    display: flex;
    position: absolute;
    bottom: -6px;
    right: 40px;
    @include media-query(992px) {
      bottom: -30px;
      right: 53px;
    }
  }
  .splide__arrow--prev {
    right: 8px;
    left: inherit;
    @include media-query(992px) {
      right: 20px;
    }
    svg {
      width: 18px;
    }
  }
  .splide__arrow--next {
    right: -40px;
    left: inherit;
    svg {
      width: 18px;
    }
  }
}
.trustpilot-widget-mod {
  top: 20px;
}


.custom-review__reviewitem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .slide {
    @include media-query(992px) {
      padding: 0 15px 0 15px;
    }
  }
}
.grt-slider-light{
  .splide__arrows{
    display: none;
  }
}

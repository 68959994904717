.dapp-blockchn {
    &.o-key-diff {
        .bord-col-1 {
            border-bottom-color: #4a90e2;
        }
        .bord-col-2 {
            border-bottom-color: #49c684;
        }
        .o-key-diff-box {
            border-left: none;
            border-bottom-width:8px;
            background: #fff;
            @include media-query(992px) {
                padding: 30px 15px 30px 25px;
            }
            img {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
            h4 {
                margin-top: 15px;
                @include media-query(992px) {
                    font-size: 22px;
                }
            }
        }
        .col-lg-3 {
            display: flex;
        }
    }
}

.dapp-tech-stack {
    .profile-tabs {
        overflow: inherit;
        display: block;
        width: 100%;
        border: none;
        margin-bottom: 0;
        .tab-profile-header {
            width: 100%;
            border-right: none;
            ul {
                display: flex;
                li {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    color: #969696;
                    padding: 0px 15px 5px;
                    border: none;
                    @include media-query(992px) {
                        font-size: 26px;
                    }
                    &.active {
                        background: none;
                        border-left: none;
                        border-left-width: 0;
                        color: #333333;
                        span {
                            &::before {
                                height: 5px;
                            }
                        }
                    }
                    span {
                        position: relative;
                        padding-bottom: 10px;
                        &:before {
                            content: '';
                            position: absolute;
                            background: #FF5800;
                            height: 0;
                            width: 148px;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                    &:hover {
                        background: none;
                        border-left: none;
                        color: #333333;
                        border-left-width: 0;
                    }
                }
            }
        }
        .tab-profile-content {
            background: none;
            width: 100%;
            .tab-profile-content-area {
                ul {
                    justify-content: center;
                    li {
                        margin-top: 15px;
                        @include media-query(992px) {
                            width: 20%;
                            margin-top: 30px;
                        }
                        a {
                            background: none;
                            padding: 0;
                            border: none;
                            &:hover{
                                background: none;
                                border: none;
                                box-shadow: none;
                            }
                            img {
                                height: 100%;
                            }
                        }
                        span {
                            display: block;
                            margin: 25px auto 0;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                }
                &.active {
                    background: none;
                }
            }
            div {
                position: inherit;
            }
        }
    }
}

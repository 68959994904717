// GENERAL
.scrollable-wrapper {
  margin-top: 70px;

  &__flickbutton {
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 9999;
    background-color: #fff;
    width: 69px;
    height: 69px;
    border: 1px solid #ff5826;
    border-radius: 50%;
    font-weight: 600;
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    @include media-query(992px) {
      display: none;
    }
    &.rotate {
      background-color: #ff5826;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      .icon-fill {
        fill: #fff;
      }
    }
  }
}
body.case-studies-details {
  #myModal1 {
    .modal-dialog {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
// regular
.regular {
  $regular: &;
  position: relative;
  &__spacing {
    padding: 20px 0;
    @include media-query(992px) {
      padding: 40px 0;
    }
  }
  &__regular-tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
    padding-bottom: 10px;
    color: #262e36;
    @include media-query(992px) {
      font-size: 41px;
      line-height: 55px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
    }
    &--has-secondary-heading {
      margin-bottom: 25px;
    }
  }
  &__deliverable-list {
    li {
      font-family: "Poppins";
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
      strong {
        font-weight: 600;
      }
    }
  }
  &__deliverable-child-strong {
    display: inline-block;
    margin-bottom: 15px;
  }
  &__deliverable-child-list {
    li {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 9px;
        left: -15px;
        background: #24cd7c;
        width: 7px;
        height: 7px;
        border-radius: 100%;
      }
    }
  }
  &__child-title {
    font-family: "Poppins";
    font-size: 20px;
  }
  &__deliverable-child-list {
    padding-left: 25px;
  }
  &__regular-content {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #333333;
    &:last-child {
      margin: 0;
    }
    &--child-content {
      position: relative;
      padding-left: 20px;
      margin-bottom: 15px;
      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 7px;
        left: 0px;
        background: url(/images/new-free-trial/list-icon-small.svg) no-repeat;
      }
    }
    &--has-top-list-content {
      margin-top: 20px !important;
    }
  }
  &__regular-list {
    list-style: disc;
    padding-left: 20px;
  }
  &__regular-item {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #333333;
  }
  &__img {
    margin: 30px 0;
    border-radius: 15px;
  }
  &__slider {
    position: relative;
    margin-top: 35px;
    .owl-nav {
      position: absolute;
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      top: -85px;
      right: 0;
    }
    .owl-prev {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #e1e1e1 !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: -1px;
      }
      &:hover {
        background-color: #7feba1 !important;
      }
      &.disabled {
        cursor: auto;
        color: #fff !important;
        background: #f2f2f2 !important;
      }
    }
    .owl-next {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #e1e1e1 !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: 2px;
      }
      &:hover {
        background-color: #7feba1 !important;
      }
      &.disabled {
        cursor: auto;
        color: #fff !important;
        background: #f2f2f2 !important;
      }
    }
    .splide__arrows {
      display: block;
      position: absolute;
      top: -60px;
      right: 40px;
      @include media-query(992px) {
        top: -70px;
      }
    }
    .splide__arrow {
      position: absolute;
      right: 0;
      width: 45px;
      height: 45px;
    }
    .splide__arrow--prev {
      right: 15px;
      left: inherit;
    }
    .splide__arrow--next {
      right: -40px;
      left: inherit;
    }
  }
  &__slider-mod {
    position: relative;
    margin-top: 35px;
    .splide__arrows {
      display: block;
      position: absolute;
      top: -60px;
      right: 40px;
      @include media-query(992px) {
        top: -70px;
      }
    }
    .splide__arrow {
      position: absolute;
      right: 0;
      width: 45px;
      height: 45px;
    }
    .splide__arrow--prev {
      right: 15px;
      left: inherit;
    }
    .splide__arrow--next {
      right: -40px;
      left: inherit;
    }
  }
}
// BANNER
.case-study-banner {
  $case-study-banner: &;
  background-color: #05264a;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    width: 178px;
    height: 148px;
    background-image: url(/images/case-studies-details/case-study-common/banner-left-circle.png);
    background-repeat: no-repeat;
    background-position: 100%;
    left: 0;
    bottom: 0;
  }
  &:after {
    position: absolute;
    content: "";
    width: 214px;
    height: 185px;
    background-image: url(/images/case-studies-details/case-study-common/banner-right-circle.png);
    background-repeat: no-repeat;
    background-position: 100%;
    right: 0;
    top: 0;
  }
  &__container {
    position: relative;
  }
  &__wrapper {
    position: absolute;
    top: 120px;
    width: 320px;
  }
  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 57px 0 38px;
    }
  }
  &__bannertag {
    font-family: "Poppins";
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 34px;
    text-indent: -5px;
    font-weight: 600;
    background: url(/images/case-studies-details/case-study-common/ribbon.svg);
    text-transform: uppercase;
    position: relative;
    display: block;
    width: 149px;
    height: 34px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    @include media-query(992px) {
      margin: 0;
      font-size: 16px;
    }
    @include media-query(992px) {
      font-size: 18px;
    }
  }
  &__bannertittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin: 15px 0 0;
    text-align: center;
    @include media-query(992px) {
      text-align: left;
      font-size: 40px;
      line-height: 50px;
    }
  }
  &__btnarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @include media-query(1590px) {
      display: none;
    }
    .case-study-banner__stickybtn {
      color: #fff;
      width: 100%;
      display: flex;
      margin: 0 0 15px;
      background-color: #ff5800;
      @include media-query(767px) {
        width: 48%;
        margin: 0;
      }
      &:hover {
        background-color: #fff;
      }
    }
    .case-study-banner__discussbtn {
      max-width: 100%;
      font-size: 16px;
      padding: 15px;
      @include media-query(767px) {
        max-width: 48%;
        font-size: 18px;
      }
    }
  }
  &__sticky {
    position: absolute;
    max-width: 320px;
    width: 100%;
    background: #fff;
    padding: 12px 10px 12px;
    border-radius: 10px;
    border: 1px solid #a2c3ee;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.01);
    z-index: 99;
  }
  &__stickylist {
    position: relative;
  }
  &__stickylistpadding {
    width: 100%;
    padding: 0 26px 0;
  }
  &__listtittle {
    font-family: "Poppins";
    color: #252424;
    font-size: 21px;
    line-height: 50px;
    font-weight: 600;
    border-bottom: 1px solid #bebebe;
  }
  &__listul {
    list-style: disc;
    padding-left: 20px;
    margin-top: 17px;
  }
  &__listitem {
    margin-bottom: 10px;
    line-height: 20px;
    &::marker {
      color: #4492dc;
      font-size: 22px;
    }
  }
  &__stickyitem {
    font-family: "Poppins";
    color: #333333;
    font-size: 15px;
    line-height: 25px;
    position: relative;
    top: -3px;
    &:hover {
      color: #4492dc;
      font-weight: 600;
    }
    &.active {
      color: #4492dc;
      font-weight: 600;
    }
  }
  &__stickybtn {
    border-radius: 5px;
    border: 1.5px solid #ff5800;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    display: none;
    margin: 0;
    @include media-query(1590px) {
      display: block;
    }
    i {
      margin-right: 7px;
    }
    &:hover {
      color: #ff5800;
    }
  }
  &__stickydiscuss {
    background: #15a241;
    border-radius: 8px;
    padding: 30px 27px;
    position: relative;
    display: none;
    margin-top: 15px;
    @include media-query(1590px) {
      display: block;
    }
    &:after {
      position: absolute;
      content: "";
      width: 93px;
      height: 93px;
      background-image: url(/images/case-studies-details/case-study-common/require-circle.png);
      background-repeat: no-repeat;
      background-position: 100%;
      right: 0;
      bottom: 0;
    }
  }
  &__discusstittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__discussubtittle {
    font-family: "Poppins";
    color: #fff;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  &__discussbtn {
    font-family: "Plus Jakarta Sans", sans-serif;
    display: block;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    border: 2px solid;
    padding: 7px;
    max-width: 210px;
    width: 100%;
    &:hover {
      color: #ff5800;
      background-color: #fff;
      path {
        fill: #ff5800;
      }
    }
  }
}
// CLIENT
.case-study-client {
  $case-study-client: &;
  background-color: #e3efff;
  &__spacing {
    padding: 30px 0;
    margin-bottom: 20px;
    @include media-query(992px) {
      padding: 60px 0 28px;
      margin-bottom: 0;
    }
  }
  &__tittle {
    font-family: "Poppins";
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
    color: #262e36;
    padding-bottom: 10px;
    @include media-query(992px) {
      font-size: 35px;
      line-height: 55px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
    }
  }
  &__content {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262e36;
  }
  &__techbox {
    margin-bottom: 20px;
  }
  &__techtittle {
    position: relative;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: #262e36;
    &:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 2px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
    }
  }
  &__techcontent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #262e36;
    margin: 0;
  }
}
// SERVICE
.service-section {
  $service-section: &;
  background-color: #3766b0;
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  @include media-query(767px) {
    padding: 20px 30px;
    border-radius: 40px;
  }
  @include media-query(992px) {
    padding: 40px 60px;
  }
  &__tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px;
    @include media-query(992px) {
      font-size: 30px;
      line-height: 55px;
    }
    &:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
    }
  }
  &__anchor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    i {
      margin-right: 10px;
    }
    a {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      text-decoration: underline;
      color: #fff;
      @include media-query(992px) {
        font-size: 24px;
        line-height: 25px;
      }
      &:hover {
        color: #24cd7c;
      }
    }
  }
}
// TECHNOLOGIES LIST
.technology-list {
  $technology-list: &;
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  &__items {
    margin: 10px;
    text-align: center;
    max-width: 150px;
    @include media-query(767px) {
      margin-right: 25px;
      margin-bottom: 12px;
    }
    span {
      font-family: "Poppins";
      font-size: 15px;
      line-height: 22px;
      color: #333333;
    }
    &:hover {
      #{$technology-list}__item {
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
  &__item {
    width: 150px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6e3f0;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.07);
    transition: all 0.5s;
  }
}
// RESULT TICK BLOCK
.result-block {
  $result-block: &;
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  &:last-child {
    margin: 0;
  }
  i {
    max-width: 43px;
    margin-right: 17px;
    width: 100%;
  }
  h4 {
    font-family: "Poppins";
    font-size: 24px;
    line-height: 30px;
    color: #333333;
  }
  p {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 27px;
    color: #333333;
    margin: 0;
  }
}
// DOWNLOAD BOTTOM
.bottom-download {
  $bottom-download: &;
  background-color: #f3f6fa;
  text-align: center;
  @include media-query(767px) {
    text-align: left;
  }
  &__spacing {
    padding: 42px 0;
  }
  h4 {
    font-family: "Poppins";
    font-size: 25px;
    line-height: 35px;
    color: #262e36;
    font-weight: 700;
    margin: 15px 0;
    display: block;
    @include media-query(767px) {
      font-size: 31px;
      line-height: 44px;
      margin: 0;
    }
  }
  &__btn {
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff5800;
    width: 252px;
    height: 60px;
    border: 1px solid #ff5800;
    border-radius: 5px;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    margin: auto;
    @include media-query(767px) {
      margin: 0;
    }
    svg {
      margin-left: 10px;
    }
    &:hover {
      color: #ff5800;
      background-color: #f3f6fa;
      path {
        fill: #ff5800;
      }
    }
  }
}
// MORE CASE STUDY
.more-case-study {
  $more-case-study: &;
  background-color: #0a1d4d;
  &__spacing {
    padding: 30px 0;
    @include media-query(992px) {
      padding: 60px 0;
    }
  }
  &__tittle {
    position: relative;
    font-family: "Poppins";
    font-size: 41px;
    line-height: 55px;
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 50px;
    color: #ffffff;
    text-align: center;
    &:after {
      position: absolute;
      content: "";
      width: 113px;
      height: 4px;
      background-color: #24cd7c;
      border-radius: 4px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &__list {
    margin-bottom: 20px;
    .owl-nav {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 42px;
    }
    .owl-prev {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #ffffff !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: -1px;
      }
      &:hover {
        background-color: #3cc065 !important;
        color: #fff !important;
      }
    }
    .owl-next {
      font-size: 45px !important;
      color: #1c2958 !important;
      background-color: #ffffff !important;
      width: 45px;
      height: 45px;
      line-height: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: 300 !important;
      span {
        position: relative;
        height: 45px;
        line-height: 35px;
        left: 2px;
      }
      &:hover {
        background-color: #3cc065 !important;
        color: #fff !important;
      }
    }
  }
  &__item {
    background: #fff;
    a {
      img {
        height: 259px;
        @include media-query(992px) {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &__itemcontent {
    position: relative;
    padding: 20px 25px 35px 25px;
    min-height: 250px;
    background-color: #fff;
  }
  &__itemtitle {
    font-family: "Poppins";
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
    min-height: 81px;
  }
  &__itemtech {
    font-family: "Poppins";
    font-size: 15px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: 400;
    strong {
      color: #4492dc;
      font-weight: 700;
    }
  }
  &__link {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    svg {
      margin-left: 5px;
    }
  }
  &__more {
    font-family: "Poppins";
    color: #84a5f9;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 25px;
    display: inline-block;
    @include media-query(992px) {
      margin-top: 100px;
    }
  }
  .splide__arrows {
    display: none;
    justify-content: center;
    position: absolute;
    bottom: -105px;
    width: 100%;
    @include media-query(992px) {
      display: flex;
    }
  }
  .splide__arrow {
    position: static;
    margin: 0 5px;
    width: 45px;
    height: 45px;
    opacity: 1;
    background: #fff;
  }
}
.scrollable-wrapper {
  $scrollable-wrapper: &;
  &__sidebar {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0%;
    right: -120%;
    transition: all 0.5s;
    @include media-query(992px) {
      position: inherit;
      right: inherit;
      top: inherit;
    }
    &.fly-open {
      right: 0;
      transition: all 0.5s;
      z-index: 999;
    }
  }
}

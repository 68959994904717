.o-awards{
    color: $black-color;
    position: relative;
    padding: 70px 0 50px;
    display: none;
    background-color: $white-color;
    position: relative;
    text-align: center;
    @include responsive(desktop){
        display: block;
        padding: 70px 0 15px;
    }
    .awards-list{
        li{
                display: inline-block;
                padding: 20px 20px;
                vertical-align: middle;
                // width: 16%;

            a{
                display: block;
                // height: 100px;
            }

        }
        &--pos {
            position: relative;
            left: -5px;
        }
    }

}

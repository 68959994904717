.service-box-cmn {
    .services-pan {
        padding: 50px 25px 30px;
        @include media-query(992px) {
            padding: 50px 25px 30px;
        }
    }
}

.expertise-section {
    padding: 40px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    h2 {
        margin-bottom: 30px;
        @include media-query(992px) {
            margin-bottom: 70px;
        }
    }
    .expertise-wrapper {
        text-align: center;
        @include media-query(992px) {
            display: flex;
            text-align: inherit;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &-left {
            @include media-query(992px) {
                flex: 0 0 48%;
            }
            @include media-query(1200px) {
                flex: 0 0 49%;
            }
        }
        &-right {
            @include media-query(992px) {
                flex: 0 0 48%;
                margin-left: 20px;
            }
            @include media-query(1200px) {
                flex: 0 0 49%;
            }
        }
        .expertise-box {
            margin-bottom: 30px;
            @include media-query(992px) {
                flex: 0 0 50%;
                margin-bottom: 70px;
                display: flex;
            }
            
            &-left {
                width: 170px;
                height: 143px;
                flex: 0 0 170px;
                border-radius: 8px;
                background: #fff;
                border: 1px solid #C5C5C5;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 25px 17px rgba(0,0,0,0.05);
                margin: 0 auto 20px;
                @include media-query(992px) {
                    margin: 0;
                }
            }
            p {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 0;
            }
            h3 {
                font-size: 20px;
                @include media-query(992px) {
                    font-size: 24px;
                }
                margin-bottom: 10px;
            }
            &-right {
                @include media-query(992px) {
                    margin-left: 20px;
                }
            }
        }
    }
}


.blk-dev-pr-section {
    padding: 40px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    h2 {
        @include media-query(992px) {
            margin-bottom: 50px;
        }
    }
    .blk-dev-pr-left {
        margin-bottom: 20px;
        position: relative;
        margin-top: 25px;
        @include media-query(992px) {
            margin-top: 0;
            margin-bottom: 60px;
        }
        &:before {
            content: '';
            height: 50px;
            width: 50px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: -73px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 21px;
        }
        &-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
                width: 50px;
                @include media-query(992px) {
                    width: inherit;
                }
            }
            h3 {
                margin-bottom: 0;
                width: 100%;
                padding-left: 20px;
                font-size: 18px;
                @include media-query(992px) {
                    font-size: 24px;
                    width: 70%;
                }
            }
        }
    }
    .blk-dev-pr-left-c1 {
        &::before {
            content: '01';
            background: #2C4D82;
        }
    }
    .blk-dev-pr-left-c2 {
        @include media-query(992px) {
            margin-bottom: 90px;
        }
        &::before {
            content: '02';
            background: #036497;
        }
    }
    .blk-dev-pr-left-c3 {
        &::before {
            content: '03';
            background: #1B89AF;
        }
    }
    .blk-dev-pr-left-c4 {
        &::before {
            content: '04';
            background: #2BBDC3;
        }
    }
    .blk-dev-pr-left-c5 {
        &::before {
            content: '05';
            background: #28C89A;
        }
    }
    .blk-dev-pr-left-c6 {
        &::before {
            content: '06';
            background: #3BE06E;
        }
    }
    ul {
        
        li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding-left: 23px;
            &:before {
                content: '';
                position: absolute;
                background: url(/images/ledger/point-circle.svg) no-repeat  0 0;
                width: 15px;
                height: 15px;
                top: 5px;
                left: 0;
            }
            margin-bottom: 15px;
        }
    }
    .blk-dev-pr-box {
        background: #fff;
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        box-shadow: 0 0 25px 10px rgba(0,0,0,0.05);
        padding: 25px 15px 25px 15px;
        margin-bottom: 25px;
        @include media-query(992px) {
            padding: 40px 25px 35px 30px;
            margin-bottom: 0;
        }
        h4 {
            margin-bottom: 15px;
            font-size: 20px;
        }
        ul {
            li {
                margin-bottom: 7px;
            }
        }
    }
    .blk-dev-pr-box-hight1 {
        @include media-query(992px) {
            min-height: 320px;
        }
        @include media-query(1200px) {
            min-height: 300px;
        }
    }
    .blk-dev-pr-box-hight2 {
        @include media-query(992px) {
            min-height: 305px;
        }
        @include media-query(1200px) {
            min-height: 260px;
        }
    }
    .blk-dev-pr-box-hight3 {
        @include media-query(992px) {
            min-height: 305px;
        }
    }
    .blk-dev-pr-box-hight4 {
        @include media-query(992px) {
            min-height: 272px;
        }
    }
    .blk-dev-pr-box-hight5 {
        @include media-query(992px) {
            min-height: 315px;
        }
        @include media-query(1200px) {
            min-height: 290px;
        }
    }
    .blk-dev-pr-box-hight6 {
        @include media-query(992px) {
            min-height: 320px;
        }
        @include media-query(1200px) {
            min-height: 295px;
        }
    }
    .blk-dev-pr-wrapper {
        padding-left: 75px;
        position: relative;
        margin-bottom: 25px;
        margin-top: 50px;
        @include media-query(992px) {
            margin-top: 0;
            margin-bottom: 50px;
        }
        &:before {
            content: '';
            position: absolute;
            background: #DFDFDF;
            width: 6px;
            height: 86%;
            @include media-query(992px) {
                height: 88%;
            }
            top: 0;
            left: 25px;
        }
    }
}

.remote-dev h2 {
    @include media-query(992px) {
        padding: 0 20px;
    }
}

.price-btn-mod {
    width: 100%;
    @include media-query(992px) {
        width: inherit;
    }
}



/*Job description*/
.jd-pr-mngr {
    @include media-query(992px) {
        height: 660px !important;
    }
    @include media-query(1200px) {
        height: 575px !important;
    }
}
.jd-pr-mngr2 {
    @include media-query(992px) {
        height: 590px !important;
    }
    @include media-query(1200px) {
        height: 525px !important;
    }
}

.intmdt {
   iframe {
        @include media-query(768px) {
            // margin-top: -40px;
        }
    }
    .o-skills-hero {
        @include media-query(992px) {
            padding: 100px 20px 45px!important;
        }
        h1 {
            margin: 25px 0 20px;
            @include media-query(992px) {
                margin-bottom: 0.5rem;
                margin-top: 0;
                font-size: 38px;
                line-height: 38px;
            }
        }
    }
    .intermediate-loader {
        padding-top: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #091b38;
        height: 100vh;
        #intermediate-loader-p {
            margin-bottom: 0;
            line-height: inherit;
            font-size: 22px;
            color: #fff;
            font-weight: 500;
            margin-top: 15px;
            @include media-query(992px) {
                font-size: 30px;
                margin-top: 20px;
            }
            @include media-query(1800px) {
                font-size: 36px;
                margin-top: 30px;
            }
        }
    }
    /*Progress bar css*/
    // #progressBar {
    //     max-width: 220px;
    //     margin: 30px auto 10px;
    //     height: 15px;
    //     background-color: #cccccc2e;
    //     border: 1px solid #fff;
    //     border-radius: 0;
    //     overflow: hidden;
    //     @include media-query(992px) {
    //         max-width: 280px;
    //         margin: 40px auto 10px;
    //         height: 15px;
    //     }
    //   }
      
    //   #progressBar div {
    //     height: 100%;
    //     text-align: right;
    //     padding: 0 10px;
    //     line-height: 22px; /* same as #progressBar height if we want text middle aligned */
    //     width: 0;
    //     // background-color: #0d6efd;
    //     background-color: #fff;
    //     border: 1px solid #fff;
    //     box-sizing: border-box;
    //   }
      h4.wait-text {
        font-size: 30px;
        line-height: 44px;
        @include media-query(992px) {
            font-size: 40px;
            line-height: inherit;
        }
        color: #fff;
        animation: beat 1.2s infinite alternate;
        transform-origin: center;
        }
      @keyframes beat{
        to { 
        opacity: .8;
      }
    }
      
}
@media only screen and (max-width: 780px) {
    .calendly-inline-widget {
        min-height: 960px;
    }
}


.container-ledger {
    max-width: 220px;
    margin: 30px auto 0;
    height: 15px;
    background-color: #cccccc2e;
    border: 1px solid #fff;
    color: #000;
    font-size: 13px;
    text-align: center;
    transition-delay: 3s;
    position: relative;
    overflow: hidden;
    @include media-query(992px) {
        max-width: 280px;
        margin: 40px auto 10px;
        height: 15px;
    }
  }
  
  #inner-container {
    width: 280px;
  height: 100%;
  margin-top: 0;
  background-color: #fff;
  border: 1px solid #fff;
}



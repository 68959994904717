.grey-mod-class {
    .d-trans-pro-wrapper {
        .d-trans-pro-content-box {
            background: #fff;
            box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
        }
        .d-trans-pro-tab-btn {
            border: 1px solid #f5f5f5;
            &.active {
                border-color: #e3e3e3;
            }
        }
    }
    .heading-para {
        @include media-query(992px) {
            max-width: 875px;
        }
    }
}
.blue-bnr-section-mod {
    .btn_wrapper {
        margin-top: 25px;
    }
}


.bnr-contact-mod {
    h2.big-h2 {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .bnr-contact {
        display: inline-block;
        margin-bottom: 25px;
        margin-top: 15px;
        font-size: 16px;
        line-height: 24px;
        width: 155px;
        text-transform: uppercase;
        color: #55C77C;
        position: relative;
        font-weight: 600;
        @include media-query(992px) {
            font-size: 19px;
            line-height: 30px;
        }
        &::before {
            content: '';
            position: absolute;
            background: url(/images/vuejs-development/green-arrow.svg) no-repeat;
            top: 4px;
            right: 0;
            width: 20px;
            height: 14px;
            @include media-query(992px) {
                top: 7px;
            }
        }
    }
}


/*Nodejs development page process section*/
.node-js-development-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-steps {
            &:before {
                @include media-query(992px) {
                    height: 90%;
                }
            }
        }
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box5,
        .d-trans-pro-content-box6 {
            @include media-query(992px) {
                margin-top: 180px;
            }
        }
    }
}

/*ios development page process section*/
.ios-app-dev-process {
    .d-trans-pro-wrapper {
        .d-trans-pro-steps {
            &:before {
                @include media-query(992px) {
                    height: 90%;
                }
            }
        }
        .d-trans-pro-content-box4,
        .d-trans-pro-content-box5,
        .d-trans-pro-content-box6 {
            @include media-query(992px) {
                margin-top: 180px;
            }
        }
    }
}
.portfolio2-view {
  .portfolio-view__rightittle {
    align-items: flex-start;
  }
  .portfolio-view__rightittlearea {
    flex: 1;
    display: flex;
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  //   .portfolio-view__rightittlearea {
  //     @include media-query(992px) {
  //         margin-bottom: 20px;
  //     }
  //   }
  .portfolio-view__filterlist {
    max-height: 0;
    width: 235px;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 9;
    top: 60px;
  }

  .portfolio-view__filterlist.show {
    // max-height: 1000px;
    max-height: 400px;
    transition: max-height 0.5s ease-in;
    background: #fbfbfb;
    opacity: 1;
    visibility: visible;
    padding: 5px 10px;
    border: 1px solid #ccc;
  }
  .filter-arrow {
    transition: transform 0.5s;
    margin-left: 10px;
  }

  .filter-arrow.down {
    transform: rotate(180deg);
  }

  .filter-arrow.up {
    transform: rotate(0deg);
  }
  .portfolio-view__filtertittle {
    cursor: pointer;
    border-radius: 25px;
    margin-bottom: 0;
    background: none;
    padding: 10px 20px;
    &--blue {
      border: 1px solid #85a7f9;
    }
    &--green {
      border: 1px solid #39c165;
    }
    &--orange {
      border: 1px solid #ff7d34;
    }
    h4 {
      color: #333;
      margin-right: 10px;
    }
  }
  .portfolio-view__rightpart {
    position: relative;
  }
  .portfolio-view__spacing {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    @include media-query(992px) {
      padding: 20px 0 0;
      margin-bottom: 40px;
    }
    &.fixed {
      position: sticky;
      top: -10px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 9;
      background: #f6f3f3;
      border-top: 1px solid #ccc;
      margin-bottom: 0;
    }
  }
  .no-data {
    @include media-query(992px) {
      position: absolute;
      top: -15px;
      height: auto;
      font-size: 24px;
      margin-top: 0;
    }
  }
  .portfolio-view__filterwrapper {
    padding-left: 0;
    margin-left: 40px;
    border-bottom: 0;
    margin-bottom: 0;
    padding-right: 10px;
    &:before {
      content: "Or";
      bottom: inherit;
      left: inherit;
      right: -30px;
      top: 10px;
      background: none;
      border: 1px solid #c8d3ef;
      color: #7a89af;
    }
    &--industry {
      margin-left: 0;
    }
  }
  .portfolio-view__masonry {
    @include media-query(992px) {
      column-count: 3;
    }
  }
  .portfolio-view__filteritem {
    label {
      font-size: 14px;
      line-height: 31px;
    }
  }
  .portfolio-view__titlelink {
    // margin-right: 12px;
    .portfolio-tittle {
      font-size: 20px;
      line-height: 30px;
      padding-right: 3px;
      //margin: 0 10px 0 0;
      margin: 0;
    }
    .portfolio-link {
      img {
        margin-right: 5px;
      }
    }
    .portfolio-view__itemlink {
      width: auto;
      //margin-left: 10px;
    }
  }
  .portfolio-view__masonry {
    &.orphan3 {
      orphans: 1;
    }
  }
  .pagination-container {
    margin-top: 20px;
    justify-content: center;
    span{
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
      @include media-query(992px) {
        margin-right: 13px;
        font-size: 20px;
      }
    }
    .pagination-next {
      margin-right: 0;
    }
  }
}

.portfolio-banner2 {
  padding: 30px 0;
  @include media-query(992px) {
    padding: 100px 0;
  }
  .portfolio-banner__assets {
    &:before {
      display: none;
    }
  }
  .portfolio-banner__heading-text {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 15px;
    @include media-query(992px) {
      font-size: 48px;
      line-height: 50px;
    }
  }
  .portfolio-banner__middle {
    max-width: 100%;
    position: relative;
    padding: 10px 0 0;
    @include media-query(992px) {
      padding: 40px 25px 30px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 100%;
      background: url(/images/new-portfolio/banner-rating/top-bracket.png)
        no-repeat 0 0 / 100%;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 22px;
      width: 100%;
      background: url(/images/new-portfolio/banner-rating/bottom-bracket.png)
        no-repeat 0 0 / 100%;
      display: none;
      @include media-query(992px) {
        display: block;
      }
    }
  }
  .portfolio-banner__asset-heading {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    width: auto;
    margin-bottom: 0;
    display: inline-block;
    margin-bottom: 0;
    @include media-query(992px) {
      position: absolute;
      top: -14px;
      left: 39px;
    }
  }
  .portfolio-banner__sub-heading {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    display: inline-block;
    @include media-query(992px) {
      font-size: 32px;
      line-height: 40px;
      padding-right: 60px;
    }
  }
  .portfolio-banner__rating-items {
    display: flex;
    flex: 0 0 50%;
    margin: 20px 0;
    justify-content: center;
    @include media-query(992px) {
      flex: inherit;
      margin: 0;
      justify-content: inherit;
    }
    &:nth-child(3) {
      .portfolio-banner__rating-right {
        margin-left: 10px;
      }
    }
    .rating-img{
      width: 75%;
      @include media-query(992px) {
        width: 85%;
      }
    }
  }
  .portfolio-banner__rating-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .portfolio-banner__rating-left {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .portfolio-banner__rating-icon-name {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .portfolio-banner__rating-right {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .portfolio-banner__rating-number {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
    }
  }
}
.service-rating-img{
  width: 75%;
  @include media-query(992px) {
    width: 100%;
  }
}
.easter-offering-banner {
    background: #D9EDE3;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 50px !important;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        background: url(/images/easter-offering/banner-hanging-egg.png) no-repeat 0 0;
        width: 370px;
        height: 230px;
        top: 65px;
        right: 0px;
        z-index: 99;
        background-size: contain;
        display: none;
        @include media-query(992px) {
            display: block;
        }
    }
    h1 {
        color: #000;
        text-align: center;
        @include media-query(992px) {
            font-size: 45px;
            line-height: 52px;
            margin-bottom: 20px;
            padding-top: 30px;
            text-align: left;
        }
    }

    .project-based-banner-right-p {
        color: #000;
        text-align: center;
        font-size: 16px;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 35px;
            text-align: left;
        }

        .under-line {
            text-decoration: underline;
        }
    }

    .new-btn {
        @include media-query(992px) {
            margin: 0;
            max-width: 300px;
        }
    }
    .paroject-based-banner-graphic-mod {
        @include media-query(992px) {
            padding-top: 90px;
        }
    }
    .cstm-link-p {
        text-align: center;
        @include media-query(992px) {
            text-align: left;
        }
    }
    &-pt {
        &::before {
            content: '';
            position: absolute;
            background: url(/images/easter-offering/bg-pattern-lft.png) no-repeat 0 0;
            width: 222px;
            height: 55px;
            bottom: 0;
            left: 0;
            background-size: 40%;
            @include media-query(992px) {
                height: 140px;
                background-size: 100%;
            }
        }
        &::after {
            content: '';
            position: absolute;
            background: url(/images/easter-offering/bg-pattern-rt.png) no-repeat 0 0;
            width: 105px;
            height: 216px;
            bottom: 0;
            right: 0;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
    }
}

.bottom-terms-text {
    background: #28345A;
    padding: 13px 0;

    p {
        color: #fff;
        margin-bottom: 0;
        font-size: 16px;

        .light-text {
            font-weight: 300;
        }
        img {
            margin-right: 5px;
        }
    }
}

.egstra-support {
    padding: 40px 0 15px;
    @include media-query(992px) {
        padding: 65px 0 50px;
    }

    h2 {
        font-weight: 600;

        @include media-query(992px) {
            font-size: 36px;
            line-height: 45px;
        }
    }

    &-top {
        @include media-query(992px) {
            font-size: 30px;
            line-height: 50px;
            font-weight: 500;
        }
    }

    &-blue {
        color: #4A90E2;
    }

    p {
        @include media-query(992px) {
            font-size: 22px;
            line-height: 35px;
            margin-top: 25px;
        }
    }

    .cstm-link-p {
        margin-top: 0;
    }
}

.easter-why-choose {
    padding: 40px 0 20px;
    @include media-query(992px) {
        padding: 70px 0 70px;
    }
    .heading-top {
        .cmn-heading-para {
            @include media-query(992px) {
                max-width: 960px;
                margin: 0 auto;
                font-size: 22px;
                line-height: 35px;
            }
        }
    }

    h3 {
        margin-bottom: 20px;
        @include media-query(992px) {
            font-size: 24px;
            line-height: 50px;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }

    .o-key-diff-box {
        border-left-width: 5px;
        background: #fff;
        @include media-query(992px) {
            padding: 30px 25px;
        }

        h4 {
            margin-top: 15px;
            @include media-query(992px) {
                font-size: 22px;
                font-weight: 700;
                margin-top: 25px;
            }
        }
    }
    .ml-cases-img i {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .cmn-heading-para {
        margin-bottom: 0;
        @include media-query(992px) {
            margin-bottom: 20px;
        }
    }
}

.easter-how-does-work {
    background: #28345A;
    padding: 40px 0 20px;
    @include media-query(992px) {
        padding: 75px 0 45px;
    }
    p {
        color: #fff;
        @include media-query(992px) {
            font-size: 22px;
            line-height: 35px;
            padding-left: 50px;
        }
    }
    .btn_wrapper {
        margin: 0;
        margin-top: 25px;
        @include media-query(992px) {
            padding-left: 50px;
        }
        a {
            margin: 0;
        }
    }
    .cstm-link-p {
        text-align: left;
    }
    .bordered-box {
        @include media-query(992px) {
            padding: 70px 40px 0;
            height: 100%;
        }
        position: relative;
        &::before {
            content: '';
            position: absolute;
            // background: url(../../../images/easter-offering/borderd-img.png) no-repeat 0 0;
            // width: 451px;
            // height: 281px;
            @include media-query(992px) {
                width: 451px;
                height: 369px;
            }
            border: 6px solid #67BC93;
            top: 0;
            left: 0;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        &:after {
            content: '';
            position: absolute;
            background: url(/images/easter-offering/mid-egg-img.png) no-repeat 0 0;
            width: 280px;
            height: 350px;
            bottom: 45px;
            right: 30px;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        h2 {
            @include media-query(992px) {
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 0;
            }
            font-weight: 500;
        }
    }
}

.easter-bottom-form-section {
    background: #AFD0C1;
    h3 {
        font-weight: 700;
        position: relative;
        &::before {
            content: '';
            background: #2B3446;
            height: 2px;
            width: 90%;
            position: absolute;
            bottom: 25px;
            left: 0;
            display: none;
            @include media-query(992px) {
                display: block;
            }
        }
        @include media-query(992px) {
            font-size: 36px;
            line-height: 46px;
            padding-bottom: 60px;
        }
        span {
            color: #FFE175;
            // @include media-query(992px) {
            //     display: block;
            // }
        }
    }
    .form-left {
        @include media-query(992px) {
            padding: 70px 45px 46px;
        }
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 206px;
            height: 151px;
            background: url(/images/easter-offering/form-egg-img.png) no-repeat 0 0;
            bottom: 0;
            left: 30px;
            height: 50px;
            background-size: 33%;
            @include media-query(992px) {
                height: 150px;
                background-size: 100%;
            }
        }
    }
}

.white-bnr-support-setcion {
    background-color: #F0F3F8;
    h2 {
        font-weight: 500;
        @include media-query(992px) {
            font-size: 30px;
            line-height: 37px;
        }
        .text-blue {
            color: #4A90E2;
        }
    }
    p {
        @include media-query(992px) {
            font-size: 22px;
            line-height: 32px;
            margin-top: 20px;
        }
        max-width: 100%;
        small {
            font-size: 16px;
        }
    }
    .cstm-link-p {
        margin-top: 0;
    }
}

.easter-offering {
    .top-addvertisement {
        display: none;
    }
    .o-skills-hero {
        padding-top: 102px;
        @include media-query(992px) {
            padding: 115px 20px 45px!important;
        }
    }
}
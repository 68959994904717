.real-google-reviews {
  position: relative;
  // background: #fff;
  background: #fbfbfb;
  padding: 50px 0 45px;
  @include responsive(desktop) {
    padding: 80px 0 50px;
  }
  .review-top {
    padding-bottom: 5px;
    h2 {
      font-size: 30px;
      margin-top: 15px;
      @include responsive(desktop) {
        font-size: 36px;
        margin-top: 0;
      }
    }
    h3 {
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      max-width: 530px;
      margin: 0 auto 20px;
      @include responsive(desktop) {
        font-size: 20px;
        line-height: 32px;
      }
      &.review-new-sub-heading {
        max-width: 100%;
      }
    }
    @include responsive(desktop) {
      padding-bottom: 0;
    }
  }
  h5.pad-top-50 {
    padding-top: 0;
    @include responsive(desktop) {
      padding-top: 50px;
    }
  }
  ul.slick-dots {
    margin-top: 0;
    @include responsive(desktop) {
      margin-top: 30px;
    }
  }
}
.new-review-box {
  background-color: $white-color;
  border: 2px solid #c8c7cc;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 30px 30px 30px 30px;
  text-align: center;
  @include responsive(desktop) {
    text-align: left;
    padding: 50px 30px 30px 30px;
  }
  h3 {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 26px;
    margin-top: 20px;
    font-weight: 500;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    max-width: 530px;
    margin: 0 auto 20px;
  }
}
.new-review-box {
  background-color: $white-color;
  border: 2px solid #c8c7cc;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 35px 15px 30px 15px;
  text-align: center;
  @include responsive(desktop) {
    text-align: left;
    padding: 35px 30px 30px 30px;
  }
  h3 {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
    @include responsive(desktop) {
      margin-top: 20px;
    }
    font-weight: 500;
  }
  .clint-details {
    font-family: $main-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
    @include responsive(desktop) {
      font-size: 14px;
    }
    strong {
      color: $black-color;
    }
  }

  h4 {
    color: $light-blue-color;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: $main-font;
    @include responsive(desktop) {
      font-family: $main-font;
      font-size: 23px;
      line-height: 32px;
    }
  }
  i {
    display: block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -40px;
    @include responsive(desktop) {
      left: 25px;
      right: inherit;
   }
    border: 5px solid #fbfbfb;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }
  
}
.i-new-review-box {
  padding: 45px 30px 30px 30px;
  @include responsive(desktop) {
    margin-top: 40px;
  }
}
.review-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  @include responsive(desktop) {
    width: 80%;
    margin: 20px auto 0;
  }
  li {
    flex: 1;
    display: inline-block;
    margin: 20px 10px 0 !important;
    @include responsive(desktop) {
      margin: 0 20px 30px !important;
      // height: 150px;
    }
    // background: url(/images/common/award-sprite.png) no-repeat 0 0;
    font-size: 0;
    line-height: 0;

    a {
      display: block;
      height: auto;
      // @include responsive(desktop) {
      //   height: 100px;
      // }
    }

    &:nth-child(1) {
      // width: 100px;
      // @include responsive(desktop) {
      //   width: 139px;
      // }
      a {
        svg {
          overflow: inherit;
        }
      }
    }
    &:nth-child(2) {
      // width: 100px;
      // @include responsive(desktop) {
      //   width: 139px;
      // }
    }
    &:nth-child(3) {
      // width: 100px;
      // @include responsive(desktop) {
      //   width: 150px;
      // }
    }
    &:nth-child(4) {
      display: none;
      // @include responsive(desktop) {
      //   display: inline-block;
      //   width: 180px;
      // }
      @include responsive(desktop) {
        display: block;
      }
    }
  }

  &:nth-child(1) {
    background-position: 0 -200px;
    width: 139px;
  }
  &:nth-child(2) {
    background-position: -160px -200px;
    width: 139px;
  }
}
.no-webp {
  .grt-slider-box:before {
    background-image: url(/images/common/common-sprite.png);
  }
}
.webp {
  .grt-slider-box:before {
    background-image: url(/images/webp/common/common-sprite.webp);
  }
}
.grt-slider-box {
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    content: "";
    width: 73px;
    height: 73px;
    background-color: #ccc;
    z-index: 1;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: 0 0;
    @include responsive(desktop) {
      left: 50px;
      right: inherit;
      margin: 0;
    }
  }
  // &:nth-child(4) {
  //   background-position: -545px -200px;
  //   width: 180px;
  // }
}

.star {
  li {
    display: inline-block;
    padding: 0 2px;
  }
}
.grt-slider {
  .slick-list {
    padding-top: 10px;
    @include responsive(desktop){
      padding-top: 0;
    }
  }
  .splide__pagination {
    position: static;
    transform: translate(0);
    .splide__pagination__page.is-active {
      transform: scale(1.4);
      background: #27c070;
    }
  }
}
// .no-webp {
//   .grt-slider-box:before {
//     background-image: url(/images/common/common-sprite.png);
//   }
// }
// .webp {
//   .grt-slider-box:before {
//     background-image: url(/images/webp/common/common-sprite.webp);
//   }
// }
.client-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -40px;
  @include responsive(desktop){
    left: 25px;
    right: inherit;
    margin: 0;
  }
}
.grt-slider-box {
  &:before {
    position: absolute;
    left: 0;
    top: -30px;
    content: "";
    width: 73px;
    height: 73px;
    background-color: #fff;
    z-index: 1;
    background-position: 0 0;
    background-repeat: no-repeat;
    @include responsive(desktop){
      left: 50px;
    }
    display: none;
  }
  &:nth-child(1) {
    &:before {
      background-position: 0 -680px;
    }
  }
  &:nth-child(2) {
    &:before {
      background-position: -107px -680px;
    }
  }
  &:nth-child(3) {
    &:before {
      background-position: -214px -680px;
    }
  }
  &:nth-child(4) {
    &:before {
      background-position: -107px -680px;
    }
  }
  &:nth-child(5) {
    &:before {
      background-position: -213px -680px;
    }
  }

    &:after {
      position: absolute;
      right: 50px;
      bottom: 6px;
      content: "";
      width: 41px;
      height: 31px;
      z-index: 1;
      background: url(/images/common/quote.png) no-repeat 0 0;
      display: none;
      @include responsive(desktop) {
        display: block;
      }
    }
  }

  // &:after {
  //   position: absolute;
  //   right: 50px;
  //   bottom: 6px;
  //   content: "";
  //   width: 41px;
  //   height: 31px;
  //   z-index: 1;
  //   background: url(/images/common/quote.png) no-repeat 0 0;
  // }
.col-md-4 {
  &.grt-slider-box {
    position: relative;
    padding: 0 15px;
    margin: 34px 0 0;
  }
}
//Update slider depends on option
.grt-slider {
  .splide__list {
    width: auto;
    .grt-slider-box {
      display: flex;
    }
  }
  &.is-active {
    .splide__list {
      // width: -webkit-max-content;
      // width: max-content;
      .grt-slider-box {
        display: block;
      }
      .grt-slider-mobile-box{
        // max-width: 316px;
        // width: 100%;
        margin: 40px 0 0;
      }
    }
  }
  .old-review-pagination{
    display: block !important;
    text-align: center;
    margin: 0 0 20px;
  }
}

.new-grt-slider {
  .owl-dots {
      text-align: center;
      margin-bottom: 30px;
      .owl-dot {
          width: 7px;
          height: 7px;
          display: inline-block;
          vertical-align: middle;
          background: #9b9b9b;
          margin: 0 5px;
          border-radius: 50%;
          cursor: pointer;
          &.active {
              background: #49c684;
              width: 10px;
              height: 10px;
          }
      }
  }
}

.trust-pilot-widget-wrapper{
  .trustpilot-widget-mod {
    top: 32px;
    left: 10px;
  }
}
.trust-pilot-widget-wrapper-industry {
  .trustpilot-widget-mod {
    top: 17px;
    left: 10px;
  }
}

.bottom-trustpilot-widget-wrapper {
  .span#reviews-summary {
    color: #fff;
  } 
  .custom-trustpilot {
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark text color */
}

/* Example of more specific styling */
.custom-trustpilot a {
    color: #0073e6; /* Link color */
}
}
.follow-pr {
    padding: 45px 0 20px;
    @include responsive(desktop){
        padding: 70px 0 50px;
    }
}
.o-services-intro-2 {
    .text-bg {
        border-radius: 8px;
        box-shadow: 0px 0px 27px 0px #e3e3e3;
        padding: 30px 30px 10px;
        margin-bottom: 25px;
        position: relative;
        text-align: left;
        max-width: none;
        border: 1px solid #E0E0E0;
        border-radius: 6px;
        box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
        @include responsive(desktop){
            padding: 50px 60px 30px;
            margin-bottom: 60px;
            max-width: 515px;
        }
    }
}
.how-it-works-holder {
    text-align: center;
    margin: 20px 0 5px;
    @include media-query(992px) {
        background: none;
        text-align: left;
        margin: 50px 0;
    }
    @include media-query(1200px) {
        background: #fff url(/images/common/divider.png) 50% 0 repeat-y;
        &.holder-bg-grey{
            background: transparent url(/images/common/w-divider.png) 50% 0 repeat-y;
            .text-bg{
                background: #fff;
            }
        }
    }
    .custom-right {
        @include responsive(desktop){
            float: right;
        }
    }
    .text-bg {
        position: relative;
        &:before {
            position: absolute;
            top: 50px;
            width: 30px;
            height: 30px;
            content: "";
            border-radius: 100%;
            background-color: #fff;
            display: none !important;
            @include media-query(992px) {
                display: none !important;
            }
            @include media-query(1200px) {
                display: block !important;
            }
            
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 20px;
            right: 20px;
            width: 90px;
            height: 90px;
            display: block;
            background-size: 100% !important;
            @include responsive(desktop){
                display: none;
            }
        }
    }
    .text-bg-1 {
        &:after {
            background: url(/images/service/how-it-works-icon-1.png) no-repeat 0 0;
        }
    }
    .text-bg-2 {
        &:after {
            background: url(/images/service/how-it-works-icon-2.png) no-repeat 0 0;
        }
    }
    .text-bg-3 {
        &:after {
            background: url(/images/service/how-it-works-icon-3.png) no-repeat 0 0;
        }
    }
    .text-bg-4 {
        &:after {
            background: url(/images/service/how-it-works-icon-4.png) no-repeat 0 0;
        }
        h3 {
            width: 118px;
            margin-top: 10px;
            @include responsive(desktop){
                width: 100%;
                margin-top: 0;
            }
        }
    }
    .text-bg-5 {
        &:after {
            background: url(/images/service/how-it-works-icon-5.png) no-repeat 0 0;
        }
        h3 {
            width: 115px;
            margin-top: 10px;
            @include responsive(desktop){
                width: 100%;
                margin-top: 0;
            }
        }
    }
    .text-bg-1 {
        &:before {
            left: -70px;
            display: block;
            border: 4px solid #3E93DA;
            top: 0;
        }
    }
    .text-bg-2 {
        &:before {
            right: -70px;
            @include responsive(desktop){
                display: block;
            }
            border: 4px solid #24C683;
        }
    }
    .text-bg-3 {
        &:before {
            left: -70px;
            display: block;
            border: 4px solid #FA8B00;
        }
    }
    .text-bg-4 {
        &:before {
            right: -70px;
            @include responsive(desktop){
                display: block;
            }
            border: 4px solid #5EACF9;
        }
    }
    .text-bg-5 {
        &:before {
            left: -70px;
            @include responsive(desktop){
                display: block;
            }
            border: 4px solid #14df7d;
        }
    }
    span {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
        display: inline-block;
        @include responsive(desktop){
            font-size: 36px;
            // margin-bottom: 15px;
        }
    }
    span.blue {
        color: #5eacf9;
    }
    span.green {
        color: #14DF7D;
    }
    span.orange {
        color: #E76C00;
    }
    span.light-blue {
        color: #5EACF9;
    }
    h3 {
        font-size: 20px;
        width: 150px;
        line-height: 24px;
        margin-top: 10px;
        @include responsive(desktop){
            font-size: 24px;
            line-height: 32px;
            width: 100%;
            margin-top: 0;
        }
    }
    p {
        font-size: 16px;
        line-height: 28px;
        font-family: $main-font;
        font-weight: 500;
        @include responsive(desktop){
            font-size: 19px;
            line-height: 30px;
            font-family: $main-font;
            font-weight: 400;
        }
        span.anchor-link {
            max-width: 100%;
            font-size: 13px;
            line-height: 22px;
            color: #333;
            text-decoration: none;
            text-align: left;
            margin-top: 20px;
        }
        a.anchor-link {
            text-align: left;
            display: block;
            max-width: 100%;
            margin-top: 0;
            @include media-query(992px){
                display: inherit;
                max-width: 325px;
            }
        }
    }
    img {
        @include responsive(desktop){
            margin: 50px auto 0;
            display: block;
        }
    }
    picture {
        display: none;
        @include responsive(desktop){
            display: block;
        }
    }
}
.w-divider {
    @include media-query(1200px) {
        background: url(/images/common/w-divider.png) 50% 0 repeat-y;
    }
}
.how-we-work-bnr {
    @include responsive(desktop){
        padding-bottom: 300px !important;
    }
    h1 {
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        padding-bottom: 0;
        margin-bottom: 0;
        @include responsive(desktop){
            font-size: 48px;
            line-height: 56px;
            padding-bottom: 0;
            margin-bottom: .5rem;
        }
    }
    h2 {
        font-size: 16px;
        line-height: 28px;
        color: #36E079;
        font-family: $main-font;
        margin-top: 20px!important;
        @include responsive(desktop){
            font-size: 20px;
            line-height: 33px;
            margin-top: 25px!important;
        }
    }
    .pm-img {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        @include responsive(desktop){
            display: none;
        }
        li {
            &:before {
                display: none;
            }
            font-size: 16px;
            padding-left: 28px;
            padding-bottom: 15px;
            padding-left: 0;
            img {
                display: block;
                margin: 0 auto 10px;
                padding-bottom: 0;
                padding-top: 0;
            }
            h5 {
                color: #fff;
                font-size: 15px;
                font-weight: 600 !important;
                line-height: 15px;
            }
            small {
                font-size: 10px;
                color: #CBE3FF;
                font-weight: 500 !important;
            }
        }
    }
    .header_left_pan {
        ul {
            li {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.work-bnr {
    h1 {
        @include responsive(desktop){
            text-align: left;
        }
    }
    .btn_wrapper {
        a {
            @include responsive(desktop){
                margin: 0;
            }
        }
    }
    .anchor-link {
        @include responsive(desktop){
        text-align: left;
        }
    }
}

.o-two-col-section__intro {
    .col-md-10 {
        margin: 0 auto !important;
    }
}
@media(hover: hover) and (pointer: fine) {
    .o-two-col-section__key-traits ul li .style_prevu_kit:hover .icon-text-hover{
        text-align: left;
        color: #fff;
        padding: 20px;
        h3 {
            text-align: left;
            color: #fff;
        }
        p {
            text-align: left;
            color: #fff;
            margin-top: 10px;
        }
    }
}
.o-two-col-section__choosing-steps {
    h2 {
        text-align: center;
    }
}


.o-skill-intro--cn-uses-ai {
    .col-md-10 {
        margin: 0 auto;
    }
}
.project-management {
    .col-md-10 {
        margin: 0 auto;
    }
}
.u-align-left {
    @include responsive(desktop){
        text-align: left;
    }
}

.o-skill-intro--conclusion {
    .col-md-10 {
        margin: 0 auto;
    }
}
.o-skills-hero--cn-uses-ai {
    .btn_wrapper {
        a {
            @include responsive(desktop){
                margin:  0;
            }
        }
    }
    .anchor-link {
        @include responsive(desktop){
            text-align: left;
        }
    }
}
.benefit-slider {
    .slick-dots {
        text-align: center;
        margin: 15px 0 30px;
        li {
            display: inline-block;
            border-radius: 50%;
            text-indent: -10000px;
            width: 7px;
            height: 7px;
            background-color: #9B9B9B;
            vertical-align: middle;
            margin: 0 6px;
            &.slick-active {
                width: 10px;
                height: 10px;
                background: #49C684;
            }
        }
    }
}
.cheaper-batter-header {
    position: relative;
    .o-services-hero__background-1 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .c-b-heading {
        text-align: center;
    }
}

.why-matters {
    .col-md-8 {
        margin: 0 auto;
    }
    .extra-h4 {
        font-weight: 400;
        margin: 0px 0px 15px 0px;
        font-family: $main-font;
        font-size: 16px;
        line-height: 28px;
        @include responsive(desktop){
            font-weight: 300;
            font-size: 22px;
            line-height: 28px;
            font-family: $main-font;
            margin: 0px 0px 35px 0px;
        }
    }
    .padding-0 {
        padding: 0;
    }
}


.cb-wecare {
    .row {
        padding: 0 15px;
        @include responsive(desktop){
            padding: 0;
        }
        &.cb-wecare-box1,
        &.cb-wecare-box2 {
            padding: 50px 15px 10px;
            border-radius: 5px;
            margin: 15px 0;
            @include responsive(desktop){
                margin: 0 -15px;
                border-radius: 0;
                padding: 50px 30px 10px;
            }
        }
        &.cb-wecare-box2 {
            @include responsive(desktop){
                margin-top: 50px;
            }
        }
    }

    .cb-wecare-heading {
        p {
            text-align: center;
            font-family: $main-font;
            @include responsive(desktop){
                text-align: left;
                font-family: $main-font;
            }
            strong {
                font-weight: 400;
                @include responsive(desktop){
                    font-weight: 700;
                }
            }
        }
        &-f-p {
            color: #fff;
            display: none;
            @include responsive(desktop){
                display: block;
            }
        }
    }
    .small-wrap-top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        align-items: flex-start;
        @include responsive(desktop){
            margin-bottom: 0;
            align-items: inherit;
        }
        &-img {
            flex: 0 0 50px;
            max-width: 50px;
            display: flex;
            justify-content: center;
            @include responsive(desktop){
                flex: 0 0 200px;
                max-width: 200px;
            }
        }
        h3 {
            flex: 1;
            font-size: 20px;
            line-height: 26px;
            padding-left: 20px;
            @include responsive(desktop){
                font-size: 36px;
                line-height: 49px;
                padding-left: 0;
            }
        }
    }
    .small-wrap-text {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        top: -42px;
        &-left {
            flex: 0 0 200px;
            max-width: 200px;
        }
        &-right {
            flex: 1;
        }
    }
}

.How-we-work {
    .o-skills-hero {
        padding-bottom: 20px;
        @include responsive(desktop){
            padding-bottom: 300px !important;
            padding-top: 135px !important;
            padding-bottom: 50px;
        }
        h2 {
            font-family: $main-font;
            @include responsive(desktop){
                font-family: $main-font;
                margin-bottom: 40px;
            }
        }
        .cstm-link-p {
            display: block;
        }
    }
}
.o-skill-intro--listing {
    .btn_wrapper {
        margin-top: 0 !important;
        @include responsive(desktop){
            margin-top: 50px!important;
        }
    }
}

.Office {
    .o-skills-hero {
        padding: 100px 0 20px!important;
        @include responsive(desktop){
            padding: 140px 20px 45px!important;
        }
        h1 {
            margin-bottom: 10px;
            @include responsive(desktop){
                margin-bottom: 25px;
            }
        }
        // p {
        //     margin-bottom: 20px;
        //     @include responsive(desktop){
        //         margin-bottom: 0;
        //     }
        // }
    }
    .green-head-ribbon span {
        width: 92%;
        @include responsive(desktop){
            width: auto;
        }
    }
}

.how-it-works-holder-mod .text-bg-1:after {
    background: url(/images/common/3step-icon-1.svg) no-repeat 0 0;
    @include media-query(992px) {
        background: none;
    }
}
.how-it-works-holder-mod .text-bg-2:after {
    background: url(/images/common/3step-icon-2.svg) no-repeat 0 0;
    @include media-query(992px) {
        background: none;
    }
}
.how-it-works-holder-mod .text-bg-3:after {
    background: url(/images/common/3step-icon-3.svg) no-repeat 0 0;
    @include media-query(992px) {
        background: none;
    }
}

.rm-bnr {
    padding-bottom: 60px !important;
}


.how-it-works-holder-mod p {
    @include media-query(992px) {
        padding-left: 50px;
    }
   padding-top: 15px;
}

.bg-grey-mod {
    .how-it-works-holder {
        background-color: #f5f5f5;
    }
    .text-bg {
        background: #fff;
    }
}
p.office-pra-text {
    color: $white-color;
    @include responsive(desktop) {
        font-size: 21px!important;
        line-height: 36px;
        margin-bottom: 0;
        font-family: $main-font;
    }
    font-size: 16px!important;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: $main-font;
    font-weight: 500;
    a {
        color: $white-color;
        @include responsive(desktop) {
            font-family: $main-font;
            text-decoration: underline;
        }
    }
}

.o-office-desk {
    color:$white-color;
    position: relative;
    padding: 60px 0;
    overflow:hidden;
    text-align: center;



    &__content{
        margin-bottom: 20px;
        text-align: left;

        p{
            font-size: 18px;
            line-height: 38px;
            color: $black-color;
            padding-top: 40px;
        }
        .img-holder{
            position: relative;
            text-align: center;

            img{
                z-index: 1;
                position: relative;
                width: 100%;

            }
            &:after{
                position: absolute;
                content: "";
                left: 280px;
                top: -50px;
                width: 208px;
                height: 208px;
                background-color: #FFA56D;
                display: none;
            }
            &--m-even{
                text-align: center;

                &:after{
                    left: 100px;
                    top: -50px;
                    background-color: #B8E986;
                }
            }
        }
    }

}

.o-office-design {
    color:$white-color;
    position: relative;
    @include responsive(desktop){
        padding: 70px 0 80px;
    }
    overflow:hidden;
    background: url(/images/office-and-infrastructure/office-design-bg.jpg) 0% 50% no-repeat;
    background-size: cover;
    text-align: center;


    h2{
        color: $white-color;
        font-size: 30px;
        // font-weight: 700;
        @include responsive(desktop){
            font-size: 42px;
        }
        text-transform: capitalize;

    }

    p{
        font-size: 18px;
        line-height: 28px;
        color: $white-color;
    }
    &--m-infrastructure{
        background: url(/images/office-and-infrastructure/infrastructure-bg.jpg) 50% 50% no-repeat;
        background-size: cover;
        .text-content{
            background-color: rgba(0, 0, 0, 0.3);
            border-bottom: 3px solid #B8E986;
            padding: 30px;
            margin-bottom: 40px;
            font-size: 18px;
            line-height: 28px;
        }
        h2 {
            @include responsive(desktop){
                font-size: 48px;
                line-height: 61px;
                margin-bottom: 35px;
            }
        }
    }

}

.o-office-video {
    color:$white-color;
    position: relative;
    padding: 40px 0;
    overflow:hidden;
    background: url(/images/office-and-infrastructure/video-bg.jpg) 50% 50% no-repeat;
    background-size: cover;
    text-align: center;

    iframe{
        border: 10px solid #D6D6D6;
    }

}


.o-open-office {
    position: relative;
    border-bottom: 1px solid #dedede;
    padding: 50px 0 50px 0 !important;
    @include responsive(desktop){
        padding: 70px 0 50px 0 !important;
        border-top: 0;
    }
    overflow:hidden;
    // background: url(../images/common/bottom-pattern-r.png) 100% 100% no-repeat;
    text-align: center;
    background-size: 40%;

    p{
        font-size: 24px;
        font-weight: 600;
    }
    h3{
        text-align: center;
        position: relative;
        padding-top: 0;
        padding-bottom: 40px;
        text-align: left;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        line-height: 42px;
        @include media-query(1200px) {
            font-size: 44px;
            line-height: 62px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: #333;
            width: 80px;
            margin-bottom: 0;

        }
    }
    .frame-text6 {
        h3 {

        }
    }
    &--description{
        position:relative;
        margin-bottom: 30px;
        img{
            height: auto;

        }
        span{
            background-color: $white-color;
            position: relative;
            border-bottom: 6px solid #B8E986;
            color:$black-color;
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            @include responsive(desktop){
                font-size: 18px;
                line-height: 28px;
                font-family: $main-font;
            }
            padding: 30px 20px;
            text-align: left;
            min-height: auto;
            font-family: $main-font;
            position: absolute;
            max-width: 230px;
            bottom: 50px;
            // @include responsive(desktop){
            //   position: absolute;
            //   max-width: 230px;
            //   bottom: 50px;
            // }

            &.m-left{
                left:12px;


            }
            &.m-right{
                left: 0;
                @include responsive(desktop){
                    right:12px;
                    left: inherit;
                }


            }
            &.m-left-top{
                left: 3px;
                @include responsive(desktop){
                    left:0;
                }
                bottom: 20px;
            }
            &.m-right-bot{
                left: 3px;
                @include responsive(desktop){
                    right:0;
                    left: inherit;
                }
                bottom: 20px;
            }
            &.org-border{
                border-bottom: 6px solid #FF8438;
            }
        }
    }
    .btn_wrapper {
        padding-top: 5px;
        @include responsive(desktop){
            padding-top: 40px;
        }
    }
}
.description-box-img {
    position: relative;
    height: 500px!important;
}

// .o-open-office--description {
//     .description-box-img {
//         position: relative;
//         span {
//             bottom: 0;
//             padding: 20px 15px;

//         }
//         .m-left {
//             left: 15px;

//         }
//         .m-right {
//             right: 15px;

//         }
//     }
// }

.o-security {
    position: relative;
    padding: 50px 0 20px!important;
    @include responsive(desktop){
        padding: 70px 0 50px 0!important;
    }
    overflow:hidden;
    // background: url(../images/common/bottom-pattern-r.png) 100% 100% no-repeat;
    text-align: center;
    background-size: 40%;

    .security-sub-heading{
        font-size: 24px;
        font-weight: 600;
        color: $black-color;
    }
    h2{
        font-size: 30px;
        line-height: 38px;
        // font-weight: 700;
        @include responsive(desktop){
        font-size: 48px;
        line-height: 61px;
        margin-bottom: 40px;
        }
        color: $black-color;
        margin-bottom: 20px;
        text-transform: capitalize;

    }
    &--m-greybg{
        background-color: #FAFAFA;
        padding: 40px 0;

        h2{
            margin-bottom: 30px;
        }
        p{
            font-size: 18px;
            color:$black-color;
        }
    }
    .security-holder{
        margin-bottom: 40px;

        &:last-child{
            margin-bottom: 0;
        }
        &:after{
            clear: both;
            content: "";
        }
        .security-holder-img{
            width: 100%;

            img{
                width: 100%;
            }
        }
        .security-holder-txt{
            width: 100%;
            position: relative;
            text-align: left;


            .white-bg{
                background-color: $white-color;
                z-index: 1;
                position: relative;
                padding: 20px 0;

                &--m-greybg{
                    background-color: #FAFAFA;
                }
                h3{
                    font-size: 20px;
                    font-weight: 500;
                    padding-bottom: 40px;
                    line-height: 40px;


                }
                p{
                    font-size: 18px;
                    font-weight: 300;
                }
                ul{
                    li{
                        line-height: 35px;
                        padding-bottom: 5px;
                        padding-left: 30px;
                        background: url(/images/office-and-infrastructure/list-bullet.png) 0 9px no-repeat;
                    }
                }
            }
            &.left-text-content{
                &:before{
                    position: absolute;
                    content: "";
                    right: -50px;
                    top: -50px;
                    width: 208px;
                    height: 208px;
                    background-color: #B8E986;
                    display: none;

                }
            }

            &.right-text-content{
                &:before{
                    position: absolute;
                    content: "";
                    left: -50px;
                    top: -50px;
                    width: 208px;
                    height: 208px;
                    background-color: #FFA56D;
                    display: none;

                }
            }
        }

        .m-left{
            float: left;
        }
        .m-right{
            float: right;
        }
    }

}

.o-photo-gallery {
    position: relative;
    padding: 40px 0;
    text-align: center;
    position: relative;
    background-color: $white-color;



    /* heading */
    h2 {
        margin: 0 0 50px;
    }
    /* ~./heading */

    /* list */
    &__list {
        position: relative;
        margin-top: 18px;


        .slick-dots{
            li{
                width: 10px;
                height: 10px;
                &.slick-active{
                    width: 15px;
                    height: 15px;
                }
            }
        }
        button.slick-prev{
            position: absolute;
            left: -40px;
            top: 40%;
            width: 21px;
            height: 34px;
            text-indent: -10000px;
            border-radius: 50%;
            z-index: 100;
            background: url(/images/office-and-infrastructure/left-arrow.png) no-repeat 0 0;
        }
        button.slick-next{
            position: absolute;
            right: -40px;
            top: 40%;
            width: 21px;
            height: 34px;
            text-indent: -10000px;
            border-radius: 50%;
            z-index: 100;
            background: url(/images/office-and-infrastructure/right-arrow.png) no-repeat 0 0;
        }
        .single-slide {
            float: left;
            position: relative;
            margin: 0 10px;
            .gal-img-holder{
                background-color:$white-color;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(112,112,112,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(112,112,112,1);
                box-shadow: 0px 0px 5px 0px rgba(112,112,112,1);
                position: relative;
                margin-bottom: 20px;
                span{
                    font-size: 15px;
                    color:$black-color;
                    font-weight: 600;
                    padding: 15px;
                    display: block;
                    text-align: left;
                }
                i{
                    position: absolute;
                    right: 25px;
                    top: 25px;
                }
            }
        }

    }
    /* ~./list */

    /* The Modal (background) */

    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //overflow: auto;
        background-color: rgba(0, 0, 0, 0.6);
    }


    /* Modal Content */

    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 3px;
        width: 65%;
        //max-width: 1200px;
    }


    /* The Close Button */

    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 35px;
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        width: 30px;
        height: 30px;
    }

    .close:hover,
    .close:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
    }

    .mySlides {
        display: none;
        img{
            width: 100%;
        }
    }

    .cursor {
        cursor: pointer
    }


    /* Next & previous buttons */

    .prev{
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: normal;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: normal;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }


    /* Position the "next button" to the right */

    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }


    /* On hover, add a black background color with a little bit see-through */

    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }


    /* Number text (1/3 etc) */

    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    img {
        /*margin-bottom: -4px;*/
    }

    .caption-container {
        text-align: center;
        background-color: $white-color;
        padding: 20px 20px 15px 20px;
        color: white;
        #caption{
            text-align: left;
            padding: 10px 0 0 0;
            margin: 0;
            font-weight: 500;
            float: left;
        }
        a{
            display: none;

        }
    }

    .demo {
        opacity: 0.6;
    }

    .active,
    .demo:hover {
        opacity: 1;
    }

    img.hover-shadow {
        transition: 0.3s
    }

    .hover-shadow:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    }

    #video-gallery{
        .mySlides{
            display: block!important;
        }
    }
}

.company-video {
    background: url(/images/office-and-infrastructure/video-bg2.jpg) no-repeat 0 0 / cover!important;
    @include responsive(desktop){
        padding: 80px 0 75px;
    }
}

.vw-gl {
    @include responsive(desktop){
        padding: 70px 0 80px 0!important;
    }
    .btn_wrapper {
        @include responsive(desktop){
            padding-top: 10px;
        }
    }
}

.pond-rout {
    padding: 45px 0 20px!important;
    @include responsive(desktop){
        padding: 70px 0 50px 0!important;
    }
}
.here-details {
    border-top: 1px solid #dedede;
    @include responsive(desktop){
        border-top: 0;
    }
    .btn_wrapper-mod {
        margin-bottom: 50px !important;
        @include responsive(desktop){
            margin-bottom: 0 !important;
        }
    }
}
.m-right-mod {
    left: 12px !important;
    @include responsive(desktop){
        right: 12px !important;
        left: inherit !important;
    }
}
.split-section {
    padding-bottom: 20px;
    padding-top: 50px !important;
    @include responsive(desktop){
        padding-bottom: 0;
        padding-top: 80px !important;
    }
    .cstm-link-p {
        text-align: center;
        margin-bottom: 15px;
        @include responsive(desktop){
            margin-bottom: 25px;
        }
    }
}
.compliant {
    font-size: 14px;
    line-height: 19px;
}

.secur {
    padding: 50px 0 20px!important;
    @include responsive(desktop){
        padding: 75px 0 50px 0!important;
    }
}